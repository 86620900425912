import "./index.less";

import { Col, Row, Space, Typography } from "antd";
import React from "react";

import { primaryColor } from "../../assets/style/variables";

const { Title } = Typography;

const InfoGrid = ({
  title,
  data,
  extra,
  rowGap,
  span,
}: {
  title?: string;
  data?: any[];
  extra?: any;
  rowGap?: number;
  span?: number;
}) => {
  return (
    <>
      {title && (
        <>
          <div className="flex justify-between items-center mb-2">
            <Title
              level={5}
              style={{ color: primaryColor, fontWeight: "bold", fontSize: 18 }}
            >
              {title}
            </Title>
            <Space>{extra}</Space>
          </div>
        </>
      )}
      <Row gutter={[16, rowGap ?? 32]}>
        {Array.isArray(data) &&
          data?.map((item, index) => (
            <Col
              key={index}
              xs={{ span: span ?? 24 }}
              sm={{ span: span ?? 12 }}
              md={{ span: span ?? 8 }}
              lg={{ span: span ?? 8 }}
              xl={{ span: span ?? 6 }}
              xxl={{ span: span ?? 6 }}
            >
              <div className="info-title">{item.title}</div>
              <div className="info-value break-words">{item.value ?? "-"}</div>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default InfoGrid;
