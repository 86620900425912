import { createSlice } from "@reduxjs/toolkit";

export type fetchParameterState = {
  fetchDisbursementBatchListingParams: {
    pagination: {
      current: number;
      pageSize: number;
    };
    sortOrder: string;
    sortField: string;
    search: any;
    filters: any;
  };
};

const initialState: fetchParameterState = {
  fetchDisbursementBatchListingParams: {
    pagination: {
      current: 1,
      pageSize: 20,
    },
    sortOrder: "desc",
    sortField: "createdAt",
    search: {},
    filters: {},
  },
};

export const fetchParameterSlice = createSlice({
  name: "fetchParameter",
  initialState,
  reducers: {
    setFetchDisbursementBatchListingParams: (state, action) => {
      state.fetchDisbursementBatchListingParams = action.payload;
    },
    fetchParameterStateReset: () => initialState,
  },
});

export const {
  setFetchDisbursementBatchListingParams,
  fetchParameterStateReset,
} = fetchParameterSlice.actions;

export default fetchParameterSlice.reducer;
