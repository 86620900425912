import "./index.less";

import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Switch,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FieldVisitCompany } from "../../../../../features/fieldVisit/fieldVisitSlice";
import { AppDispatch, RootState } from "../../../../../store/store";
import { STATE_ENUM } from "../../../../../enum/state";
import {
  createFieldVisitCompanyThunk,
  updateFieldVisitCompanyThunk,
} from "../../../../../services/fieldVisitService/fieldVisitThunk";

const FieldVisitCompanyFormModal: React.FC<{
  data?: FieldVisitCompany;
  callback?: () => void;
}> = ({ data, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const updateMode = data != null;

  const labelClassName = "w-[140px]";

  const { currentUser } = useSelector((state: RootState) => state.user);
  const stateOptions = Object.values(STATE_ENUM).map((value) => ({
    label: `${value.replace(/[_]/g, " ")}`,
    value,
  }));

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        let location: { state: string }[] = [];
        const deletedLocation: { state: string }[] = [];
        const newAddedLocation: { state: string }[] = [];
        if (updateMode) {
          values?.companyLocation?.map((item: string) => {
            if (data?.companyLocation?.includes(item)) {
              location.push({ state: item });
            } else {
              newAddedLocation.push({ state: item });
            }
          });
          data?.companyLocation?.map((item: string) => {
            if (!values?.companyLocation?.includes(item)) {
              deletedLocation.push({ state: item });
            }
          });
        } else {
          location = values?.companyLocation.map((item: any) => ({
            state: item,
          }));
        }

        const payload = {
          ...values,
          city: values.companyCity,
          state: values.companyState,
          address2: values.address2 ?? "",
          address3: values.address3 ?? "",
          location: location,
          deletedLocation: deletedLocation,
          newAddedLocation: newAddedLocation,
          isActive: values.isActive != null ? values.isActive : false,
          createdBy: data?.createdBy ?? currentUser?.data?.id,
          updatedBy: currentUser?.data?.id,
        };

        if (updateMode) {
          dispatch(
            updateFieldVisitCompanyThunk({
              id: data.id,
              payload,
            }),
          )
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Update Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        } else {
          dispatch(createFieldVisitCompanyThunk(payload))
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Create Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        }
      })
      .catch((error) => {
        message.error("Something went wrong. Please try again later.");
        setLoading(false);
      });
  };

  return (
    <>
      <Button onClick={showModal}>
        {data != null ? "Update Company Details" : "Create Company"}
      </Button>
      <Modal
        title={data != null ? "Update Company" : "Create Company"}
        okText={data != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={500}
      >
        <Form
          form={form}
          initialValues={data}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Company Code</span>}
            name="companyCode"
            rules={[
              { required: true, message: "Please enter the Company Code." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Company Name</span>}
            name="companyName"
            rules={[
              { required: true, message: "Please enter the Company Name." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Location</span>}
            name="companyLocation"
            rules={[{ required: true, message: "Please select the state." }]}
          >
            <Select
              placeholder="Please Select State"
              allowClear
              mode="multiple"
              options={stateOptions}
            />
          </Form.Item>

          <Form.Item
            label={<span className={labelClassName}>Address</span>}
            className="mb-4"
          >
            <Input.Group>
              <Form.Item
                name={"address1"}
                className="mb-2"
                validateFirst={true}
                rules={[{ required: true, message: "Address is required" }]}
              >
                <Input placeholder="Address line 1" />
              </Form.Item>
              <Form.Item name={"address2"} className="mb-2">
                <Input placeholder="Address line 2" />
              </Form.Item>
              <Form.Item name={"address3"} className="mb-2">
                <Input placeholder="Address line 3" />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item
            name={"postCode"}
            label={<span className={labelClassName}>Postcode</span>}
            className="mb-2"
            rules={[{ required: true, message: "Postcode is required" }]}
          >
            <Input placeholder="Postcode" />
          </Form.Item>
          <Form.Item
            name={"companyCity"}
            label={<span className={labelClassName}>City</span>}
            className="mb-2"
            rules={[{ required: true, message: "City is required" }]}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            name={"companyState"}
            label={<span className={labelClassName}>State</span>}
            rules={[{ required: true, message: "State is required" }]}
          >
            <Select
              placeholder="Please Select State"
              allowClear
              options={stateOptions}
            />
          </Form.Item>

          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Office Number</span>}
            name="officeNumber"
            rules={[
              { required: true, message: "Please enter the Officer Number." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Email</span>}
            name="companyEmail"
            rules={[
              { required: true, message: "Please enter the Email" },
              { type: "email", message: "Please insert valid email" },
            ]}
          >
            <Input
              onInput={(e) =>
                ((e.target as HTMLInputElement).value = (
                  e.target as HTMLInputElement
                ).value.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Flat Fee (RM)</span>}
            name="flatFee"
            rules={[{ required: true, message: "Please enter the Flat Fee." }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              inputMode="numeric"
              type={"number"}
            />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Fee Per Mileage (RM)</span>}
            name="feePerMileage"
            rules={[
              {
                required: true,
                message: "Please enter the Fee per mileage.",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              inputMode="numeric"
              type={"number"}
            />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Status</span>}
            name="isActive"
            valuePropName="checked"
          >
            <Switch defaultChecked={false} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FieldVisitCompanyFormModal;
