export enum STATE_ENUM {
  SELANGOR = "SELANGOR",
  SABAH = "SABAH",
  SARAWAK = "SARAWAK",
  KELANTAN = "KELANTAN",
  PAHANG = "PAHANG",
  TERENGGANU = "TERENGGANU",
  KEDAH = "KEDAH",
  NEGERI_SEMBILAN = "NEGERI_SEMBILAN",
  PERAK = "PERAK",
  JOHOR = "JOHOR",
  PENANG = "PENANG",
  PERLIS = "PERLIS",
  MELAKA = "MELAKA",
  LABUAN = "LABUAN",
  PUTRAJAYA = "PUTRAJAYA",
  KUALA_LUMPUR = "KUALA_LUMPUR",
}
