import { Collapse, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { displayDateAndTime } from "../../../../utils/datetime.util";
import { SmeApplicationRemarks } from "../../../../features/smeApplication/smeApplicationSlice";
import { getSmeApplicationRemarksThunk } from "../../../../services/smeApplicationService/smeApplicationThunk";
import { Role } from "../../../../enum/roles";
import jwt_decode from "jwt-decode";

const { Panel } = Collapse;

export const InternalRemarksPanel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { smeApplicationRemarks, smeApplicationDetails } = useSelector((state: RootState) => state.smeApplication);

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: smeApplicationRemarks?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchInternalRemarks({ pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const fetchInternalRemarks = (params?: any) => {
    if(smeApplicationDetails?.data?.id) {
      dispatch(getSmeApplicationRemarksThunk({
        id: smeApplicationDetails?.data?.id,
        ...initialParams,
        ...params
      }));
    }
  }
  useEffect(() => {
    fetchInternalRemarks()
  }, [smeApplicationDetails]);

  const internalRemarksColumns: ColumnsType<SmeApplicationRemarks> = [
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "12rem",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Description",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text: string) => (text ?? "-"),
    },
  ];

  return (
    <Collapse ghost expandIconPosition="end" defaultActiveKey={currentRole === Role.APPROVER_2 ? ['internalRemark'] : []}>
      <Panel
        key="internalRemark"
        className="application-details-section-container"
        header={
          <h3 className="application-details-section-title">
            Internal Remark
          </h3>
        }
      >
        <Table
          rowKey="id"
          className="min-w-fit"
          dataSource={smeApplicationRemarks?.data?.data}
          loading={smeApplicationRemarks?.isLoading}
          columns={internalRemarksColumns}
          bordered={true}
          pagination={tablePagination}
          onChange={handleTableChange}
        />
      </Panel>
    </Collapse>
  );
};