import "../index.less";

import { Divider, Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";

import InfoGrid from "../../../components/InfoGrid";
import { Application } from "../../../features/application/applicationSlice";
import { AppDispatch, RootState } from "../../../store/store";

import {
  getAccountDetailsThunk,
  getAccountTenureDetailsThunk,
  getCollectionHistoryLogThunk,
  getDueAccountTenureDetailsThunk,
} from "../../../services/collectionService/collectionThunk";

import DirectDebitFormModal from "../../../components/DirectDebitFormModal";
import { disableDirectDebitThunk } from "../../../services/agreementService/agreementThunk";
import { getDunningHistoryListingThunk } from "../../../services/dunningService/dunningThunk";
import { useState } from "react";

type DirectDebitProps = {
  searchAgreementNo: string;
};

const DirectDebit: React.FC<DirectDebitProps> = (props) => {
  const { searchAgreementNo } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const { creditRecords } = useSelector(
    (state: RootState) => state.application,
  );
  const { raceState } = useSelector(
    (state: RootState) => state.dropdownParameter,
  );
  const { language } = useSelector((state: RootState) => state.config);

  const [application, setApplication] = useState<any>({});
  const [form] = Form.useForm();

  const bankInformation = [
    {
      title: "Bank",
      value: accountEnquiry.data.agreementDetails?.cardBrand,
    },
    {
      title: "Account Type",
      value: accountEnquiry.data.agreementDetails?.cardType,
    },
    {
      title: "Account No",
      value: accountEnquiry.data.agreementDetails?.cardLastFourDigit,
    },
    {
      title: "Token No",
      value: accountEnquiry.data.agreementDetails?.directDebitToken,
    },
  ];
  return (
    <div className="account-enquiry-container">
      {accountEnquiry.data.agreementDetails?.directDebitToken && (
        <div className="account-enquiry-section-container">
          <div className="flex justify-between items-end">
            <h3 className="account-enquiry-section-subtitle mb-0">
              Bank Information [Direct Debit]
            </h3>
            <DirectDebitFormModal
              searchAgreementNo={searchAgreementNo}
              applicationDetails={application}
            />
          </div>

          <Divider style={{ margin: 0, marginTop: 10 }} />
          <div className="info-container">
            <InfoGrid data={bankInformation} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DirectDebit;
