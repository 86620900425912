import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTransactionHistory } from "./transactionHistoryService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getTransactionHistoryThunk = createAsyncThunk(
  "transactionHistory/get",
  async (requestData: any, thunkAPI) => {
    try {
      return await getTransactionHistory(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
