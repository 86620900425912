import "../index.less";

import { Table } from "antd";

import { displayDateAndTime } from "../../../utils/datetime.util";

const LegalHistory = () => {
  // const { accountDetails } = useSelector(
  //   (state: RootState) => state.accountDetails,
  // );

  const columns = [
    {
      title: "No.",
      key: "id",
      render: (text: string) => text,
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Action Code",
      dataIndex: "actionCode",
      key: "actionCode",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

  const data: any[] = [];

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  );
};

export default LegalHistory;
