import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";
import { RescheduleTypeEnum, RnRType } from "../../enum/reschedule";
import { AxiosRequestConfig } from "axios";

const blobConfig: AxiosRequestConfig = { responseType: "blob" };

export const createRescheduleCase = async (requestData: any) => {
  const url = `/redCash/api/v1.0/reschedule/create`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const getRescheduleCase = async (requestData: any) => {
  const url = `/redCash/api/v1.0/reschedule/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const updateRescheduleCase = async (requestData: any) => {
  const url = `/redCash/api/v1.0/reschedule/update/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const updateRescheduleCaseStatus = async (requestData: any) => {
  const url = `/redCash/api/v1.0/reschedule/updateStatus/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const getRescheduleListing = async (requestData: any) => {
  const params = {
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
    filters: { ...requestData.filters, type: RnRType.Reschedule },
    search: requestData.search,
  };

  const url = `/redCash/api/v1.0/reschedule-listing`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params,
  });
  return res.data;
};

export const getRescheduleQuotation = async (requestData: any) => {
  const url = `/redCash/api/v1.0/reschedule/quotation/rescheduleID/${requestData.rescheduleId}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);

  return res.data;
};
