import { Tabs, TabsProps, Tag } from "antd";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import { AppDispatch, RootState } from "../../store/store";
import InsuranceFirstBatchPage from "./insuranceFirstBatch";
import InsuranceSecondBatchPage from "./insuranceSecondBatch";
import {
  getLoanInsuranceBatchThunk,
  getLoanInsuranceBatch2Thunk,
} from "../../services/loanInsuranceService/loanInsuranceThunk";

const LoanInsuranceListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { loanInsuranceBatch1, loanInsuranceBatch2 } = useSelector(
    (state: RootState) => state.loanInsurance,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const items: TabsProps["items"] = [
    {
      key: "pending",
      label: (
        <span>
          Download Insurance Declaration{" "}
          {loanInsuranceBatch1.data?.fixTotal != null && (
            <Tag>{loanInsuranceBatch1.data?.fixTotal}</Tag>
          )}
        </span>
      ),
      children: <InsuranceFirstBatchPage />,
    },
    {
      key: "created",
      label: (
        <span>
          Upload Insurance Declaration{" "}
          {loanInsuranceBatch2?.data?.fixTotal != null && (
            <Tag>{loanInsuranceBatch2?.data?.fixTotal}</Tag>
          )}
        </span>
      ),
      children: <InsuranceSecondBatchPage />,
    },
  ];

  const fetchInsuranceFirstTabBatch = async (params?: any) => {
    await dispatch(getLoanInsuranceBatchThunk(params));
  };

  const fetchInsuranceSecondBatch = async (params?: any) => {
    await dispatch(getLoanInsuranceBatch2Thunk(params));
  };

  const onTabChange = (key: string) => {
    if (key === "pending") {
      fetchInsuranceFirstTabBatch(initialParams);
    } else if (key === "created") {
      fetchInsuranceSecondBatch(initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useEffect(() => {
    const params = {
      ...initialParams,
      pagination: {
        currentPage: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
      currentPage: pageNum ? +pageNum : initialParams.pagination.current,
      pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
    };

    fetchInsuranceFirstTabBatch(params);
    fetchInsuranceSecondBatch(params);
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Loan Insurance - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Insurance Batch Listing" />
      <Tabs
        defaultActiveKey={activeTab ?? "pending"}
        items={items}
        onChange={onTabChange}
      />
    </>
  );
};

export default LoanInsuranceListingPage;
