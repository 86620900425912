import {
  Button,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "@ant-design/pro-components";

import {
  deleteProductThunk,
  getProductsThunk,
} from "../../services/productService/productThunk";
import { AppDispatch, RootState } from "../../store/store";
import ProductFormModal from "./ProductFormModal";

import type { ColumnsType } from "antd/es/table";
import type { TablePaginationConfig } from "antd/es/table";

const { Option } = Select;
const { Search } = Input;

const ProductMaintenancePage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { products } = useSelector((state: RootState) => state.product);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("code");

  const tablePagination = {
    total: products.data.length,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    // {
    //   title: "Min Age Checking",
    //   dataIndex: "minAgeChecking",
    //   key: "minAgeChecking",
    // },
    // {
    //   title: "Max Age Checking",
    //   dataIndex: "maxAgeChecking",
    //   key: "maxAgeChecking",
    // },
    // {
    //   title: "Interest Rate",
    //   dataIndex: "interestRate",
    //   key: "interestRate",
    // },
    // {
    //   title: "Min Tenure",
    //   dataIndex: "minTenure",
    //   key: "minTenure",
    // },
    // {
    //   title: "Interval Tenure",
    //   dataIndex: "intervalTenure",
    //   key: "intervalTenure",
    // },
    // {
    //   title: "Stamping Fee",
    //   dataIndex: "stampingFee",
    //   key: "stampingFee",
    // },
    // {
    //   title: "Attestation Fee",
    //   dataIndex: "attestationFee",
    //   key: "attestationFee",
    // },
    // {
    //   title: "Processing Fee",
    //   dataIndex: "processingFee",
    //   key: "processingFee",
    // },
    {
      title: "Referral Code",
      dataIndex: "referralCode",
      key: "referralCode",
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space>
          {!products.isLoading && (
            <ProductFormModal
              key={record?.id}
              product={record}
              callback={() => fetchProducts(initialParams)}
            />
          )}
          <Popconfirm
            title="Do you want to cancel this product?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" loading={deleteLoading} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const fetchProducts = async (params?: any) => {
    await dispatch(getProductsThunk({ params }));
  };

  const onSearch = (value: string) => {
    fetchProducts({
      ...initialParams,
      // filters: { ...filterState, [searchFiled]: value },
      search: { [searchFiled]: value },
    });
    // setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchProducts({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const onDelete = async (productId: string) => {
    setDeleteLoading(true);
    await dispatch(deleteProductThunk({ productId }))
      .unwrap()
      .then(() => {
        message.success("Deleted");
        fetchProducts(initialParams);
      })
      .finally(() => setDeleteLoading(false));
  };

  useEffect(() => {
    fetchProducts(initialParams);
  }, [initialParams]);

  return (
    <>
      <Helmet>
        <title>Product Config - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Product Config" />
      <Row justify="space-between">
        <Col>
          <Input.Group compact>
            <Select
              defaultValue="code"
              style={{ width: 200 }}
              onChange={(value) => setSearchFiled(value)}
            >
              {Array.isArray(columns) &&
                columns
                  .filter((col: any) => col.key !== "action")
                  .map((col: any) => (
                    <Option key={col.key} value={col.dataIndex}>
                      {col.title?.toString()}
                    </Option>
                  ))}
            </Select>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </Input.Group>
        </Col>
        <Col>
          <ProductFormModal callback={() => fetchProducts(initialParams)} />
        </Col>
      </Row>

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={products.data}
        loading={products.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ProductMaintenancePage;
