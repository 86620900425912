import "../index.less";

import { Button, Popover, Table, Tag } from "antd";
import { useSelector } from "react-redux";

import InfoGrid from "../../../components/InfoGrid";
import { numberWithCommas } from "../../../helpers/number";
import { RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";

const AccountDetails = () => {
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const accountDetails = accountEnquiry?.data?.accountDetails;
  const agreementDetails = accountEnquiry?.data?.agreementDetails;

  const accountDetailsInfo1 = [
    {
      title: "Agreement No",
      value: agreementDetails?.agreementNo,
    },
    // if customer not yet sign, Agreement date & First instalment date & Maturity date just show blank
    {
      title: "Agreement Date",
      value: agreementDetails?.signDate
        ? displayDate(new Date(agreementDetails?.signDate))
        : "-",
    },
    {
      title: "First Instalment Date",
      value:
        agreementDetails?.signDate && accountDetails?.firstDueDate
          ? displayDate(accountDetails?.firstDueDate)
          : "-",
    },
    {
      title: "Maturity Date",
      value:
        agreementDetails?.signDate && agreementDetails?.lastInstalmentDate
          ? displayDate(agreementDetails?.lastInstalmentDate)
          : "-",
    },
    {
      title: "Finance Amount",
      value:
        accountDetails?.totalFinanceAmount != null
          ? `RM ${numberWithCommas(accountDetails?.totalFinanceAmount)}`
          : "-",
    },
    {
      title: "Interest Amount",
      value:
        accountDetails?.totalInterestAmount != null
          ? `RM ${numberWithCommas(accountDetails?.totalInterestAmount)}`
          : "-",
    },
    {
      title: "Interest Rate",
      value:
        agreementDetails?.loanInterestRate != null
          ? `${numberWithCommas(agreementDetails?.loanInterestRate)} %`
          : "-",
    },
    {
      title: "Instalment Period",
      value: agreementDetails?.loanTenure ?? "-",
    },
    {
      title: "Total Instalment Amount",
      value:
        accountDetails?.totalPayableAmount != null ? (
          <Popover
            className="p-0"
            content={
              <Table
                className="max-h-96 overflow-auto"
                size="small"
                dataSource={accountEnquiry.data?.totalTenure ?? []}
                pagination={false}
                columns={[
                  {
                    title: "Due Date",
                    dataIndex: "dueDate",
                    key: "dueDate",
                    align: "center",
                    render: (text) =>
                      text != null ? `${displayDate(text)}` : "-",
                  },
                  {
                    title: "Instalment Amount",
                    dataIndex: "instalmentAmount",
                    key: "instalmentAmount",
                    render: (text: string | number | null | undefined) =>
                      text != null ? `RM ${numberWithCommas(text)}` : "-",
                    align: "center",
                  },
                ]}
              />
            }
          >
            <Button type="link">
              {`RM ${numberWithCommas(accountDetails?.totalPayableAmount)}`}
            </Button>
          </Popover>
        ) : (
          "-"
        ),
    },
    {
      title: "Monthly Instalment Amount",
      value:
        accountDetails?.monthlyInstalmentAmount != null
          ? `RM ${numberWithCommas(accountDetails?.monthlyInstalmentAmount)}`
          : "-",
    },
    {
      title: "Final Instalment Amount",
      value:
        accountDetails?.lastInstalmentAmount != null
          ? `RM ${numberWithCommas(accountDetails?.lastInstalmentAmount)}`
          : "-",
    },
  ];

  const accountDetailsInfo2 = [
    {
      title: "Disbursement Date",
      value: agreementDetails?.disbursementPostedDate
        ? displayDate(agreementDetails?.disbursementPostedDate)
        : "-",
    },
    {
      title: "Disbursement Amount",
      value:
        accountDetails?.disbursementAmount != null
          ? `RM ${numberWithCommas(accountDetails?.disbursementAmount)}`
          : "-",
    },
    {
      title: "Processing Fee",
      value:
        agreementDetails?.application?.productProcessingFee != null
          ? `RM ${numberWithCommas(
              agreementDetails?.application?.productProcessingFee,
            )}`
          : accountDetails?.adminFee != null
            ? `RM ${numberWithCommas(
              accountDetails?.adminFee,
            )}`
            : "-",
    },
    {
      title: "Stamp Duty Fee",
      value:
        accountDetails?.stampingDutyFee != null
          ? `RM ${numberWithCommas(accountDetails?.stampingDutyFee)}`
          : "-",
    },
  ];

  const accountDetailsInfo3 = [
    {
      title: "Campaign Code",
      value: agreementDetails?.application?.productReferralCode ?? "-",
    },
    {
      title: "Discount Amount",
      value: agreementDetails?.application?.productProcessingFeeToDiscount
        ? `RM ${numberWithCommas(
            agreementDetails?.application?.productProcessingFeeToDiscount,
          )}`
        : "-",
    },
    {
      title: "Partner ID No",
      value: agreementDetails?.application?.user?.partnerId ?? accountDetails?.applicantPartnerId ?? "-",
    },
    {
      title: "Payment Method",
      value: accountEnquiry.data.accountDetails?.paymentMethod ?? "-",
    },
  ];

  const accountDetailsInfo4 = [
    {
      title: "Policy Status",
      value: agreementDetails?.policyStatus ?? "-",
    },
    {
      title: "Policy Number",
      value: agreementDetails?.policyNumber ?? "-",
    },
    {
      title: "Policy Insurance Date",
      value: agreementDetails?.policyIssuanceDate
        ? agreementDetails.policyIssuanceDate
        : "-",
    },
    {
      title: "Loan Insurance Amount",
      value: agreementDetails?.application?.loanTotalPremium
        ? `RM ${numberWithCommas(
            agreementDetails?.application?.loanTotalPremium,
          )}`
        : "-",
    },
  ];

  const accountDetailsInfo5 = [
    {
      title: "Old Agreement No",
      value: accountDetails?.smeApplication?.oldAgreementNo ?? "-",
    },
  ]

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="flex items-center mb-3">
          <h3 className="account-enquiry-section-title mb-0 mr-2">
            Account Details
          </h3>
          {<Tag>{accountDetails?.status}</Tag>}
        </div>
        <div className="info-container">
          <InfoGrid data={accountDetailsInfo1} />
          <div className="p-4" />
          <InfoGrid data={accountDetailsInfo2} />
          <div className="p-4" />
          <InfoGrid data={accountDetailsInfo3} />
          <div className="p-4" />
          {!accountDetails?.smeApplicationId ? <InfoGrid data={accountDetailsInfo4} /> : <InfoGrid data={accountDetailsInfo5} />}
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
