import { createSlice } from "@reduxjs/toolkit";
import {
  getPreRecordedVideoBMThunk,
  getPreRecordedVideoENThunk,
} from "../../services/preRecordedVideoService/preRecordedVideoThunk";

export type PreRecordedVideo = {
  id: string;
  companyId: string;
  title: string;
  createdAt?: string;
  updatedAt?: string;
};

export type PreRecordedVideoState = {
  preRecordedVideoEN: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: PreRecordedVideo[];
  };
  preRecordedVideoBM: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: PreRecordedVideo[];
  };
};

const initialState: PreRecordedVideoState = {
  preRecordedVideoEN: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  preRecordedVideoBM: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const preRecordedVideoSlice = createSlice({
  name: "preRecordedVideo",
  initialState,
  reducers: {
    preRecordedVideoStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreRecordedVideoENThunk.pending, (state) => {
        state.preRecordedVideoEN.isLoading = true;
      })
      .addCase(getPreRecordedVideoENThunk.fulfilled, (state, action) => {
        state.preRecordedVideoEN.isLoading = false;
        state.preRecordedVideoEN.data = action?.payload ?? [];
      })
      .addCase(getPreRecordedVideoENThunk.rejected, (state, action) => {
        state.preRecordedVideoEN.isLoading = false;
        state.preRecordedVideoEN.isError = true;
        state.preRecordedVideoEN.errorMessage = action.payload;
      });
    builder
      .addCase(getPreRecordedVideoBMThunk.pending, (state) => {
        state.preRecordedVideoBM.isLoading = true;
      })
      .addCase(getPreRecordedVideoBMThunk.fulfilled, (state, action) => {
        state.preRecordedVideoBM.isLoading = false;
        state.preRecordedVideoBM.data = action?.payload ?? [];
      })
      .addCase(getPreRecordedVideoBMThunk.rejected, (state, action) => {
        state.preRecordedVideoBM.isLoading = false;
        state.preRecordedVideoBM.isError = true;
        state.preRecordedVideoBM.errorMessage = action.payload;
      });
  },
});

export const { preRecordedVideoStateReset } = preRecordedVideoSlice.actions;

export default preRecordedVideoSlice.reducer;
