import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../../helpers/errorMessage";
import teamService from "./teamService";

export const getAllTeamThunk = createAsyncThunk(
  "team/getAll",
  async (requestData: any, thunkAPI) => {
    try {
      return await teamService.getAllTeam(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createTeamThunk = createAsyncThunk(
  "team/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await teamService.createTeam(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateTeamThunk = createAsyncThunk(
  "team/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await teamService.updateTeam(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteTeamThunk = createAsyncThunk(
  "team/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await teamService.deleteTeam(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
