import React from "react";
import { Collapse, Table } from "antd";
import { displayDate } from "../../../../utils/datetime.util";
import { numberWithCommas } from "../../../../helpers/number";
import { ColumnsType } from "antd/es/table";

const { Panel } = Collapse;

// TODO: Add type for data
// TODO: Integrate with collection module account details, since total loan outstanding is needed in this panel
export const ExistingLoanPanel = () => {
  const existingLoanColumns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (text: string, record: any, index: number) => index + 1,
    },
    {
      title: "Agreement No.",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text: string) => text ?? "-",
    },
    {
      title: "Maturity Date",
      dataIndex: "maturedDate",
      key: "maturedDate",
      render: (text: string) => displayDate(text) || "-",
    },
    {
      title: "Monthly Instalment",
      dataIndex: "monthlyInstalment",
      key: "monthlyInstalment",
      render: (text: string) => numberWithCommas(text) || "-",
    },
    {
      title: "Total Loan Outstanding",
      dataIndex: "totalOutstanding",
      key: "totalOutstanding",
      render: (text: string) => numberWithCommas(text) || "-",
    },
  ];

  return (
    <Collapse
      ghost
      expandIconPosition="end"
    >
      <Panel
        key="loanApplication"
        className="application-details-section-container"
        header={
          <h3 className="application-details-section-title">
            Current Existing Loan Application
          </h3>
        }
      >
        <Table
          rowKey="id"
          columns={existingLoanColumns}
          dataSource={[]}
        />
      </Panel>
    </Collapse>
  );
};