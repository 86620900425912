import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
} from "antd";
import { useDispatch } from "react-redux";

import {
  createSmeProductThunk,
  updateProductThunk,
  updateSmeProductThunk,
} from "../../../services/productService/productThunk";
import { Product } from "../../../features/application/applicationSlice";
import { AppDispatch } from "../../../store/store";

const SmeProductFormModal: React.FC<{
  product?: Product;
  callback?: () => void;
}> = ({ product, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [isReferralCodeEmpty, setIsReferralCodeEmpty] = useState(
    product?.referralCode === "",
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        if (product != null) {
          dispatch(
            updateSmeProductThunk({
              productId: product.id,
              payload: {
                ...values,
              },
            }),
          )
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Update Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        } else {
          dispatch(
            createSmeProductThunk({
              ...values,
            }),
          )
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Create Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong. Please try again later.");
      });
  };

  const onReferralCodeChange = (e: any) => {
    if (e.target.value === "") {
      setIsReferralCodeEmpty(true);
    } else {
      setIsReferralCodeEmpty(false);
    }
  };

  return (
    <>
      <Button onClick={showModal}>
        {product != null ? "Update" : "Create Product"}
      </Button>
      <Modal
        title={product != null ? "Update Product" : "Create Product"}
        okText={product != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={700}
        destroyOnClose
      >
        <Form
          form={form}
          initialValues={{
            ...product,
          }}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Code"
                name="code"
                rules={[{ required: true, message: "Please enter the code!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Min Active Line"
                name="minActiveLine"
                rules={[
                  {
                    required: true,
                    message: "Please enter the min active line!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Max Active Line"
                name="maxActiveLine"
                rules={[
                  {
                    required: true,
                    message: "Please enter the max active line!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Min Avg Recurring Amount"
                name="minAvgRecurringAmount"
                rules={[
                  {
                    required: true,
                    message: "Please enter the min avg recurring amount!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Max Avg Recurring Amount"
                name="maxAvgRecurringAmount"
                rules={[
                  {
                    required: true,
                    message: "Please enter the max avg recurring amount!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Interest Rate"
                name="interestRate"
                rules={[
                  { required: true, message: "Please enter interest rate!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Min Tenure"
                name="minTenure"
                rules={[
                  { required: true, message: "Please enter min tenure!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Max Tenure"
                name="maxTenure"
                rules={[
                  { required: true, message: "Please enter max tenure!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Interval Tenure"
                name="intervalTenure"
                rules={[
                  { required: true, message: "Please enter interval tenure!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            {!isReferralCodeEmpty && (
              <Col span={8}>
                <Form.Item
                  label="Processing Fee to Discount"
                  name="processingFeeToDiscount"
                  rules={[
                    {
                      required: true,
                      message: "Please enter processing fee to discount!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Min Loan Amount"
                name="minLoanAmount"
                rules={[
                  { required: true, message: "Please enter min loan amount!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Max Loan Amount"
                name="maxLoanAmount"
                rules={[
                  { required: true, message: "Please enter max loan amount!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Interval Loan Amount"
                name="intervalLoanAmount"
                rules={[
                  {
                    required: true,
                    message: "Please enter interval loan amount!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Processing Fee"
                name="processingFee"
                rules={[
                  { required: true, message: "Please enter processing Fee!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Referral Code"
                name="referralCode"
                // rules={[
                //   { required: true, message: "Please enter referral code!" },
                // ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Stamping Duty Percentage"
                name="stampingDutyPercentage"
                rules={[
                  {
                    required: true,
                    message: "Please enter stamping duty percentage",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SmeProductFormModal;
