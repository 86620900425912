import { Button, message, Space, Table, Tag } from "antd";
import { startCase } from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";

import UpdateWaiverAmountModal from "../../../components/UpdateWaiverAmountModal";
import { collectionStateReset } from "../../../features/collection/collectionSlice";
import { waiverStateReset } from "../../../features/waiver/waiverSlice";
import { numberWithCommas } from "../../../helpers/number";
import { getAccountDetailsThunk } from "../../../services/collectionService/collectionThunk";
import {
  editWaiverAgreementNoThunk,
  getWaiverChargesThunk,
} from "../../../services/waiverService/waiverThunk";
import { AppDispatch, RootState } from "../../../store/store";

import type { ColumnsType } from "antd/es/table";
import { getTaxThunk } from "../../../services/taxService/taxThunk";
import { WAIVER_BATCH_STATUS } from "../../../enum/waiverBatchStatus";

const EditWaiverChargesPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { waiverId, accountDetailsId, agreementNo, status } = useParams();
  const [chargesData, setChargesData] = useState([{}]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [resultNotFound, setResultNotFound] = useState(true);
  const [searchResultData, setSearchResultData] = useState({
    status: "",
    batchNo: "",
    id: "",
    totalNoOfAccount: 0,
    createdAt: "",
    createdBy: "",
    verifier: "",
    verifyDate: "",
    decider: "",
    decidedDate: "",
  });
  const { tax } = useSelector((state: RootState) => state.tax);
  const taxToDivided = 1 + tax.data.tax / 100;
  const fetchTax = async (params?: any) => {
    await dispatch(getTaxThunk(params));
  };
  const [batchStatus, setBatchStatus] = useState<any>(null);
  const chargesColumn: ColumnsType<any> = [
    {
      title: "Charges Type",
      dataIndex: "chargesType",
      key: "chargesType",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: number) =>
        `RM ${numberWithCommas(text / taxToDivided)}` ?? "-",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (text: number, record: any) =>
        `RM ${numberWithCommas(
          record.amount - record.amount / taxToDivided,
        )}` ?? "-",
    },
    {
      title: "Amount After Tax",
      dataIndex: "amountTotal",
      key: "amountTotal",
      render: (text: number, record: any) =>
        `RM ${numberWithCommas(record.amount)}` ?? "-",
    },
    {
      title: "Waiver Amount",
      dataIndex: "waiverAmount",
      key: "waiverAmount",
      render: (text: number) => `RM ${numberWithCommas(text)}` ?? "-",
    },
    {
      title: "Waiver Tax",
      dataIndex: "waiverTax",
      key: "waiverTax",
      render: (text: number, record: any) =>
        `RM ${numberWithCommas(record.waiverTax)}` ?? "-",
    },
    {
      title: "Waiver After Tax",
      dataIndex: "total",
      key: "total",
      render: (text: number, record: any) =>
        `RM ${numberWithCommas(record.total)}` ?? "-",
    },
    {
      title: "Action",
      dataIndex: "waiverAmount",
      key: "waiverAmount",
      // render: (text, record) => (
      //   <Space size="middle">
      //     <UpdateWaiverAmountModal
      //       chargeDetails={record}
      //       chargesArray={chargesData}
      //       tax={tax.data.tax}
      //       // callback={
      //       //     setPayload
      //       // }
      //     />
      //   </Space>
      // ),
      render: (text: any, record: any) => {
        if (
          status === WAIVER_BATCH_STATUS.OPEN ||
          status === WAIVER_BATCH_STATUS.REFERBACK
        ) {
          return (
            <>
              <Space size="middle">
                <UpdateWaiverAmountModal
                  chargeDetails={record}
                  chargesArray={chargesData}
                  tax={tax.data.tax}
                  // callback={
                  //     setPayload
                  // }
                />
              </Space>
            </>
          );
        }
      },
    },
  ];

  const fetchWaiverCharges = async (
    waiverId: string,
    accountDetailsId: string,
    agreementNo: string,
  ) => {
    const payload = {
      waiverId,
      accountDetailsId,
    };

    let waiveCharges: any[] = [];

    await dispatch(getWaiverChargesThunk(payload)).then((res) => {
      if (res.payload.data.length > 0) {
        waiveCharges = res.payload.data;
        setResultNotFound(false);
      }
    });

    if (waiveCharges) {
      if (
        status === WAIVER_BATCH_STATUS.APPROVED ||
        status === WAIVER_BATCH_STATUS.VOID ||
        status === WAIVER_BATCH_STATUS.PENDING
      ) {
        console.log(waiveCharges);
        const filteredWaiverCharges = waiveCharges.filter(
          (item: any) => item.waiverAmount > 0,
        );
        setChargesData(filteredWaiverCharges);
      } else {
        const filteredWaiverCharges = waiveCharges.filter(
          (item: any) => item.amountTotal > 0,
        );
        setChargesData(filteredWaiverCharges);
      }
    } else {
      message.error("Result not found");
    }
  };

  const updateWaiver = () => {
    setUpdateLoading(true);
    const payload = {
      charges: chargesData.filter((item: any) => item.waiverAmount > 0),
      accountDetailsId: accountDetailsId,
      waiverId: waiverId,
      username: localStorage.getItem("username"),
    };

    dispatch(editWaiverAgreementNoThunk(payload))
      .then(() => {
        setUpdateLoading(false);
        message.success("Updated waiver amount");
      })
      .catch(() => {
        message.error("Failed to insert waiver record");
        setUpdateLoading(false);
      });
  };

  const initialCallbackState = { fetchWaiverCharges };
  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    if (waiverId != null && accountDetailsId != null && agreementNo != null)
      callbackState.fetchWaiverCharges(waiverId, accountDetailsId, agreementNo);
    fetchTax();
  }, [accountDetailsId, agreementNo, callbackState, waiverId]);

  const navigate = useNavigate();

  const onBack = () => {
    dispatch(waiverStateReset());
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Waiver Batch Details - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title={
          <Space align="center">
            <h3 className="m-0">Waiver Details</h3>

            <Tag>{searchResultData.status}</Tag>
          </Space>
        }
        onBack={onBack}
      />
      {!resultNotFound && (
        <div className="edit-waiver-section-container">
          <div className="info-container">
            <Table
              rowKey="id"
              className="min-w-fit"
              columns={chargesColumn}
              dataSource={chargesData}
              //   pagination={tablePagination}
              //   loading={waiver.isLoading}
              //   onChange={handleTableChange}
            />
          </div>
          <br />

          <div className="w-full flex justify-end gap-x-1.5 mb-8">
            <Button
              loading={updateLoading}
              onClick={() => updateWaiver()}
              type="primary"
              disabled={
                status === WAIVER_BATCH_STATUS.APPROVED ||
                status === WAIVER_BATCH_STATUS.PENDING ||
                status === WAIVER_BATCH_STATUS.VOID
              }
            >
              Update
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditWaiverChargesPage;
