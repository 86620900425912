import { message, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

import { Pie } from "@ant-design/charts";

import {
  downloadAgreementStatusReportThunk,
  downloadApplicationStatusReportThunk,
} from "../../../services/dashboardService/dashboardThunk";
import { AppDispatch } from "../../../store/store";

const { Text } = Typography;

const CasePieChart: React.FC<{
  status: string;
  dayData: Record<string, any>[];
  countData: Record<string, any>[];
  percentage: string;
  totalCases: number;
}> = ({ status, dayData, countData, percentage, totalCases }) => {
  const dispatch = useDispatch<AppDispatch>();

  let statusTextColor = "#d9d9d9";
  if (dayData.find((item) => item?.day === ">7")?.value > 0) {
    statusTextColor = "#ff3131";
  } else if (dayData.find((item) => item?.day === "4-6")?.value > 0) {
    statusTextColor = "#fd8b06";
  } else if (dayData.find((item) => item?.day === "1-3")?.value > 0) {
    statusTextColor = "#ADD9B5";
  }

  const applicationStatusReportDictMap: Record<string, string> = {
    VERIFYING: "VERIFYING",
    FOLLOWED_UP: "FOLLOWED_UP",
    REFERBACK: "REFERBACK",
    REFERBACK_FROM_DISBURSEMENT: "REFERBACK_FROM_PENDING_DISBURSEMENT",
    APPROVAL: "SUBMITTED_FOR_APPROVAL",
    APPROVAL_FROM_PENDING_DISBURSEMENT:
      "SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT",
    PENDING_FOR_ATTESTATION: "PENDING_FOR_ATTESTATION",
  };
  const agreementStatusReportDictMap: Record<string, string> = {
    LIVE_ATTESTATION: "REQUESTED_FOR_LIVE_ATTESTATION",
    PENDING_FOR_DISBURSEMENT: "PENDING_DISBURSEMENT",
    PENDING_FOR_ESIGN: "PENDING_SIGNING",
    PENDING_FOR_ESTAMP: "PENDING_ESTAMP",
  };

  // handle download in progress status report
  const handleDownloadFile = (res: any) => {
    const url = window.URL.createObjectURL(new Blob([res.content]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", res.filename ?? "");
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }, 100);
  };

  const onDownloadStatusReport = (status: string) => {
    if (Object.keys(applicationStatusReportDictMap).includes(status)) {
      dispatch(
        downloadApplicationStatusReportThunk({
          applicationstatus: applicationStatusReportDictMap[status],
        }),
      )
        .unwrap()
        .then((res) => {
          handleDownloadFile(res);
          message.success(`Successfully download ${status} report.`);
        })
        .catch((error) =>
          message.error(
            `Failed to download ${status} report. Please try again later.`,
          ),
        );
    } else if (Object.keys(agreementStatusReportDictMap).includes(status)) {
      dispatch(
        downloadAgreementStatusReportThunk({
          agreementstatus: agreementStatusReportDictMap[status],
        }),
      )
        .unwrap()
        .then((res) => {
          handleDownloadFile(res);
          message.success(`Successfully download ${status} report.`);
        })
        .catch((error) =>
          message.error(
            `Failed to download ${status} report. Please try again later.`,
          ),
        );
    } else {
      message.error(
        `Failed to download ${status} report. Please try again later.`,
      );
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-52">
      <Pie
        data={dayData}
        angleField="value"
        colorField="day"
        radius={1}
        innerRadius={0.7}
        appendPadding={0}
        style={{ fontSize: 30, height: 200, width: 200 }}
        label={false}
        legend={false}
        pieStyle={({ day }) => {
          if (day === "1-3") return { fill: "#ADD9B5" };
          if (day === "4-6") return { fill: "#fd8b06" };
          if (day === ">7") return { fill: "#ff3131" };
          return { fill: "#d9d9d9" };
        }}
        color={["#ADD9B5", "#fd8b06", "#ff3131"]}
        statistic={{
          title: {
            customHtml: (container, view, datum, data) => {
              return "";
            },
          },
          content: {
            customHtml: (container, view, datum, data) => {
              const text = `${data?.reduce((r, d) => r + d.value, 0)}`;
              return "";
            },
          },
        }}
      />
      <Pie
        data={countData}
        angleField="value"
        colorField="type"
        radius={1}
        innerRadius={0.6}
        appendPadding={17}
        style={{ fontSize: 30, height: 150, width: 150, marginTop: -175 }}
        label={false}
        legend={false}
        pieStyle={({ type }) => {
          if (type === "Count") return { fill: "#ffbaba" };
          if (type === "Others") return { fill: "#e7f3ff" };
          return { fill: "#d9d9d9" };
        }}
        color={["#ffbaba", "#e7f3ff"]}
        statistic={{
          content: {
            customHtml: (container, view, datum, data) => {
              return `
                  <div style="font-size: 25px"> 
                    ${data?.find((item) => item.type === "Count")?.value}
                  </div>`;
            },
          },
        }}
      />
      <div className="mt-8 text-center">
        <Text
          underline
          strong
          style={{ color: statusTextColor, cursor: "pointer" }}
          onClick={() => onDownloadStatusReport(status)}
        >
          {status.replaceAll("_", " ")}
        </Text>
        <p className="mb-0">{percentage} %</p>
        <p className="mb-0">{totalCases} Cases</p>
      </div>
    </div>
  );
};

export default CasePieChart;
