import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getManualPayment = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/manual-payment/list`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const manualPaymentService = {
  getManualPayment,
};

export default manualPaymentService;
