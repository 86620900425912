import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  TimeRangePickerProps,
} from "antd";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import { WAIVER_BATCH_STATUS } from "../../../enum/waiverBatchStatus";
import { numberWithCommas } from "../../../helpers/number";
import { getAocBatchThunk } from "../../../services/aocService/aocThunk";
import { checkAgreement } from "../../../services/waiverService/waiverService";
import { getBatchNoThunk } from "../../../services/waiverService/waiverThunk";
import { getWriteOffMonitorListingThunk } from "../../../services/writeOffService/writeOffThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import AccountEnquiryModal from "../../AccountEnquiry/AccountEnquiryModal";

import type { ColumnsType } from "antd/es/table";
import type { TablePaginationConfig } from "antd/es/table";

const { RangePicker } = DatePicker;
const { Search } = Input;

const WriteOffMonitorPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { writeOffMonitorListing } = useSelector(
    (state: RootState) => state.writeOffMonitor,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {
        // startRangeDate: dayjs().subtract(1, "month").format(),
        // endRangeDate: dayjs().format(),
      },
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchField, setSearchField] = useState("agreementNo");
  // const [selectedDate, setSelectedDate] = useState<any>([
  //   dayjs(initialParams.filters.startRangeDate),
  //   dayjs(initialParams.filters.endRangeDate),
  // ]);

  const tablePagination = {
    total: writeOffMonitorListing?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Agreement No.",
      dataIndex: ["accountdetails", "agreementNo"],
      render: (text, record) => (
        <AccountEnquiryModal
          key={record.id}
          agreementNo={record.accountdetails.agreementNo}
        />
      ),
    },
    {
      title: "Write Off Date",
      dataIndex: ["writeOffBatch", "approvedDate"],
      key: "writeOffDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Last Payment Date",
      dataIndex: ["accountdetails", "lastPaymentDate"],
      key: "lastPaymentDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Write Off Amount (RM)",
      dataIndex: "writeOffAmount",
      key: "writeOffAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Add-on Charges (RM)",
      dataIndex: "totalAOC",
      key: "addOnCharges",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Waiver (RM)",
      dataIndex: "totalWaiver",
      key: "waiver",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Payment Made (RM)",
      dataIndex: "paymentAfterWriteOff",
      key: "paymentAfterWriteOff",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Write Off Outstanding Balance (RM)",
      dataIndex: ["accountdetails", "totalLoanOutstanding"],
      key: "writeOffOutstanding",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Action",
      key: "action",
      width: 260,
      render: (_, record) => (
        <Space wrap={false}>
          <Button onClick={() => requestForAoc(record)}>Add-on Charges</Button>
          <Button onClick={() => requestForWaiver(record)}>Waiver</Button>
        </Space>
      ),
    },
  ];

  const requestForAoc = async (record: any) => {
    const isAgreementApproved = await checkAgreement(
      record?.accountdetails.agreementNo,
    );

    if (!isAgreementApproved) {
      message.error(
        `This agreement: ${record.accountdetails.agreementNo} is still in progress under Waiver/Add-On Charges, please either approve or void it first`,
      );
    } else {
      await dispatch(getAocBatchThunk(initialParams));
      queryParams.set("agreementNo", `${record.accountdetails.agreementNo}`);
      const newSearch = queryParams.toString();
      navigate({
        pathname: "/add-on-charges/request",
        search: newSearch,
      });
    }
  };

  const requestForWaiver = async (record: any) => {
    const isAgreementApproved = await checkAgreement(
      record?.accountdetails.agreementNo,
    );

    if (!isAgreementApproved) {
      message.error(
        `This agreement: ${record.accountdetails.agreementNo} is still in progress under Waiver/Add-On Charges, please either approve or void it first`,
      );
    } else {
      await dispatch(
        getBatchNoThunk({
          ...initialParams,
          filters: { status: WAIVER_BATCH_STATUS.OPEN },
        }),
      );
      queryParams.set("agreementNo", `${record.accountdetails.agreementNo}`);
      const newSearch = queryParams.toString();
      navigate({
        pathname: "/waiver/create-waiver",
        search: newSearch,
      });
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  const fetchWriteOffMonitorListing = async (params?: any) => {
    await dispatch(getWriteOffMonitorListingThunk(params));
  };

  const onSearch = (value: string) => {
    if (!value) {
      fetchWriteOffMonitorListing(initialParams);
      return;
    }
    fetchWriteOffMonitorListing({
      ...initialParams,
      filters: { [searchField]: value },
    });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchWriteOffMonitorListing({
      ...initialParams,
      ...filterState,
      pagination,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const handleDateChange = async (date: any) => {
    const selectedDate_ = {
      startDate: date[0].startOf("days").format(),
      endDate: date[1].endOf("days").format(),
    };

    fetchWriteOffMonitorListing({
      ...initialParams,
      filters: {
        startRangeDate: selectedDate_.startDate,
        endRangeDate: selectedDate_.endDate,
      },
    });

    // setSelectedDate([
    //   dayjs(selectedDate_.startDate),
    //   dayjs(selectedDate_.endDate),
    // ]);
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };
    fetchWriteOffMonitorListing(params);
  });

  return (
    <>
      <Helmet>
        <title>Write Off Monitor - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Write Off Monitor" />
      <Row gutter={16}>
        <Col>
          <Input.Group compact>
            <Select
              defaultValue={"agreementNo"}
              style={{ width: 200 }}
              options={[
                { value: "agreementNo", label: "Agreement No" },
                { value: "applicantNric", label: "Applicant NRIC" },
                { value: "applicantFirstName", label: "Applicant Name" },
                { value: "applicantPhoneNo", label: "Phone No" },
                { value: "applicantEmail", label: "Email" },
              ]}
              onChange={(value) => setSearchField(value)}
            />
            <Search onSearch={onSearch} style={{ width: 200 }} />
          </Input.Group>
        </Col>
        <Col>
          <RangePicker
            presets={rangePresets}
            onChange={handleDateChange}
            style={{ width: 250 }}
            // value={selectedDate}
          />
        </Col>
      </Row>

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={writeOffMonitorListing?.data?.data ?? []}
        loading={writeOffMonitorListing?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default WriteOffMonitorPage;
