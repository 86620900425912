import "../index.less";

import { Button, message, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useEffect, useMemo, useState } from "react";
import { displayDateAndTime } from "../../../utils/datetime.util";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { getSelfBilledEInvoiceSubmissionHistoryLogThunk } from "../../../services/collectionService/collectionThunk";
import { downloadEInvoiceThunk } from "../../../services/fileService/fileThunk";
import { downloadFile } from "../../../utils/file.util";

const SelfBilledEInvoiceSubmissionHistory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accountEnquiry, selfBilledEInvoiceSubmissionHistory } = useSelector(
    (state: RootState) => state.collection,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [downloading, setDownloading] = useState(false);

  const tablePagination = {
    total: selfBilledEInvoiceSubmissionHistory.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const onFileListItemClick = (fileId: string) =>
    window.open(
      `${process.env.REACT_APP_COLLECTION_URL}/redCash/api/v1.0/eInvoice/file/${fileId}`,
      "_blank",
    );

  const onDownloadEInvoice = async (fileId: string) => {
    setDownloading(true);
    await dispatch(downloadEInvoiceThunk({ fileId }))
      .unwrap()
      .then((res) => {
        console.log(res);
        downloadFile(res, `${res.name}`, "pdf");
      })
      .catch((error) => {
        console.log(error);
        message.error("Failed to download e-invoice. Please try again later.");
      })
      .finally(() => setDownloading(false));
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Document Code",
      dataIndex: "documentCode",
      key: "documentCode",
      render: (text: string) => (text ? text : "01"),
    },
    {
      title: "Document No",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text: string, record: any) => (
        <Button
          type="link"
          onClick={() => onFileListItemClick(record.invoiceFileId)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => text ?? "-",
    },
    {
      title: "Action",
      dataIndex: "download",
      key: "download",
      render: (_: any, record: any, index: number) => (
        <Button
          onClick={() => onDownloadEInvoice(record.invoiceFileId)}
          loading={downloading}
        >
          Download
        </Button>
      ),
    },
  ];

  const fetchHistory = async (params?: any) => {
    await dispatch(
      getSelfBilledEInvoiceSubmissionHistoryLogThunk({
        ...params,
        accountDetailsId: accountEnquiry.data.accountDetails?.id,
      }),
    );
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchHistory({
      ...initialParams,
      ...filterState,
      pagination,
      agreementId: accountEnquiry.data?.accountDetails?.agreementId,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    if (accountEnquiry.data?.accountDetails?.agreementId) {
      fetchHistory({
        ...initialParams,
        agreementId: accountEnquiry.data?.accountDetails?.agreementId,
      });
    }
  }, [initialParams, accountEnquiry.data?.accountDetails?.agreementId]);

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <Table
            columns={columns}
            dataSource={selfBilledEInvoiceSubmissionHistory.data?.data}
            pagination={tablePagination}
            loading={selfBilledEInvoiceSubmissionHistory.isLoading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SelfBilledEInvoiceSubmissionHistory;
