import { Button, Input, Table, TablePaginationConfig } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { WAIVER_BATCH_STATUS } from "../../../../enum/waiverBatchStatus";
import { getReferbackWaiverThunk } from "../../../../services/waiverService/waiverThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";
import { numberWithCommas } from "../../../../helpers/number";

const { Search } = Input;

const ReferbackWaiverListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { referbackWaiver } = useSelector((state: RootState) => state.waiver);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {
        status: WAIVER_BATCH_STATUS.REFERBACK,
      },
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("batchNo");

  const tablePagination = {
    total: referbackWaiver.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (text: string, record: any, index: number) => index + 1,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => displayDate(record.createdAt),
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
      key: "batchNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/waiver/details/${record.id}`)}
        >
          <span style={{ userSelect: "text", textDecoration: "underline" }}>
            {text}
          </span>
        </Button>
      ),
    },
    {
      title: "Total No. of Account",
      dataIndex: "totalNoOfAccount",
      key: "totalNoOfAccount",
    },
    {
      title: "Total Waiver After Tax",
      dataIndex: "totalWaiverAfterTax",
      key: "totalWaiverAfterTax",
      render: (_, record) =>
        record?.totalWaiverAfterTax != null
          ? `RM ${numberWithCommas(record?.totalWaiverAfterTax)}`
          : "-",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
  ];

  const fetchApplications = async (params?: any) => {
    await dispatch(getReferbackWaiverThunk(params));
  };

  const onSearch = (value: string) => {
    const filters = {
      ...filterState,
      batchNo: value === "" ? undefined : value,
    };

    const params = {
      ...initialParams,
      filters,
    };

    fetchApplications({
      ...initialParams,
      filters: { ...filterState, [searchFiled]: value },
    });

    setFilterState({ ...initialParams.filters, [searchFiled]: value });
    console.log(filterState);
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchApplications({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    // resetApplicationState();
    fetchApplications({ ...initialParams, ...filterState });
  }, [initialParams]);

  return (
    <>
      {/* <Helmet>
        <title>Pending Application - RedCash CEP</title>
      </Helmet>
      {/*<Radio.Group defaultValue="a" buttonStyle="solid" optionType="button">*/}
      {/*  <Radio.Button value="a">All</Radio.Button>*/}
      {/*  <Radio.Button value="b">Credit Assessment (Approval)</Radio.Button>*/}
      {/*  <Radio.Button value="c">Credit Assessment (KIV Approval)</Radio.Button>*/}
      {/*  <Radio.Button value="d">System Rejected</Radio.Button>*/}
      {/*</Radio.Group>*/}
      <Input.Group compact>
        {/* <Select
          defaultValue="Btach No."
          style={{ width: 200 }}
          onChange={(value) => setSearchFiled(value)}
        >
          {Array.isArray(columns) &&
            columns.map((col: any) => (
              <Option key={col.key} value={col.dataIndex}>
                {col.title?.toString()}
              </Option>
            ))}
        </Select> */}
        <Search
          placeholder="Batch No."
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={referbackWaiver?.data?.data}
        loading={referbackWaiver.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ReferbackWaiverListing;
