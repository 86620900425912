import { Radio } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";

import {
  getApprovedRefundListThunk,
  getOpenRefundListThunk,
  getPendingRefundListThunk,
  getReferbackRefundListThunk,
  getRefundedRefundThunk,
  getVoidRefundListThunk,
} from "../../../services/refundService/refundThunk";
import {
  getApprovedUnmatchedRefundListThunk,
  getOpenUnmatchedRefundListThunk,
  getPendingUnmatchedRefundListThunk,
  getReferbackUnmatchedRefundListThunk,
  getUnmatchedRefundedRefundThunk,
  getVoidUnmatchedRefundListThunk,
} from "../../../services/unmatchedService/unmatchedThunk";
import { AppDispatch } from "../../../store/store";
import RefundListingTabs from "../components/refund-listing-tabs";

import type { RadioChangeEvent } from "antd";

const RefundListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [unmatch, setUnmatch] = useState(type === "unmatched");

  const onListTypeChange = (e: RadioChangeEvent) => {
    setUnmatch(e.target.value === "unmatched");

    queryParams.set("type", e.target.value);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const fetchOpenRefundList = async (params?: any) => {
    const param = { ...params, filters: { ...params.filters, status: "OPEN" } };
    await dispatch(getOpenRefundListThunk(param));
  };

  const fetchPendingRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "PENDING" },
    };
    await dispatch(getPendingRefundListThunk(param));
  };

  const fetchReferbackRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "REFERBACK" },
    };
    await dispatch(getReferbackRefundListThunk(param));
  };

  const fetchVoidRefundList = async (params?: any) => {
    const param = { ...params, filters: { ...params.filters, status: "VOID" } };
    await dispatch(getVoidRefundListThunk(param));
  };

  const fetchApprovedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "APPROVED" },
    };
    await dispatch(getApprovedRefundListThunk(param));
  };

  const fetchRefundedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "REFUNDED" },
    };
    await dispatch(getRefundedRefundThunk(param));
  };

  const fetchOpenUnmatchedRefundList = async (params?: any) => {
    const param = { ...params, filters: { ...params.filters, status: "OPEN" } };
    await dispatch(getOpenUnmatchedRefundListThunk(param));
  };

  const fetchPendingUnmatchedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "PENDING" },
    };
    await dispatch(getPendingUnmatchedRefundListThunk(param));
  };

  const fetchReferbackUnmatchedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "REFERBACK" },
    };
    await dispatch(getReferbackUnmatchedRefundListThunk(param));
  };

  const fetchVoidUnmatchedRefundList = async (params?: any) => {
    const param = { ...params, filters: { ...params.filters, status: "VOID" } };
    await dispatch(getVoidUnmatchedRefundListThunk(param));
  };

  const fetchApprovedUnmatchedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "APPROVED" },
    };
    await dispatch(getApprovedUnmatchedRefundListThunk(param));
  };

  const fetchRefundedUnmatchedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "REFUNDED" },
    };
    await dispatch(getUnmatchedRefundedRefundThunk(param));
  };

  const initialRefundCallbackState = {
    fetchOpenRefundList,
    fetchPendingRefundList,
    fetchReferbackRefundList,
    fetchVoidRefundList,
    fetchApprovedRefundList,
    fetchRefundedRefundList,
  };

  const initialUnmatchedCallbackState = {
    fetchOpenUnmatchedRefundList,
    fetchPendingUnmatchedRefundList,
    fetchReferbackUnmatchedRefundList,
    fetchVoidUnmatchedRefundList,
    fetchApprovedUnmatchedRefundList,
    fetchRefundedUnmatchedRefundList,
  };

  const [refundCallbackState] = useState(initialRefundCallbackState);
  const [unmatchedCallbackState] = useState(initialUnmatchedCallbackState);

  useEffect(() => {
    if (unmatch) {
      unmatchedCallbackState.fetchOpenUnmatchedRefundList(initialParams);
      unmatchedCallbackState.fetchPendingUnmatchedRefundList(initialParams);
      unmatchedCallbackState.fetchReferbackUnmatchedRefundList(initialParams);
      unmatchedCallbackState.fetchVoidUnmatchedRefundList(initialParams);
      unmatchedCallbackState.fetchApprovedUnmatchedRefundList(initialParams);
      unmatchedCallbackState.fetchRefundedUnmatchedRefundList(initialParams);
    } else {
      refundCallbackState.fetchOpenRefundList(initialParams);
      refundCallbackState.fetchPendingRefundList(initialParams);
      refundCallbackState.fetchReferbackRefundList(initialParams);
      refundCallbackState.fetchVoidRefundList(initialParams);
      refundCallbackState.fetchApprovedRefundList(initialParams);
      refundCallbackState.fetchRefundedRefundList(initialParams);
    }
  }, [refundCallbackState, unmatchedCallbackState, initialParams, unmatch]);

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Refund Listing" />
      <Radio.Group onChange={onListTypeChange} defaultValue={type ?? "refund"}>
        <Radio.Button value="refund">Refund</Radio.Button>
        <Radio.Button value="unmatched">Unmatched</Radio.Button>
      </Radio.Group>
      <RefundListingTabs isUnmatched={unmatch ? true : false} />
    </>
  );
};

export default RefundListing;
