import groupBy from "lodash.groupby";

import { createSlice } from "@reduxjs/toolkit";

import { getAgreementDetailsThunk } from "../../services/agreementService/agreementThunk";
import { getFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import { UploadedFile } from "../application/applicationSlice";
import { Agreement } from "../agreement/agreementSlice";
import {
  getDisbursementAgreementsByBatchIdTrunk,
  getDisbursementBatchDetailsTrunk,
  getDisbursementBatchListingThunk,
  getPendingDisbursementAgreementsThunk,
} from "../../services/disbursementService/disbursementTrunk";

export type DisbursementBatch = {
  id: string;
  completedDate: string | null;
  batchNo: string;
  status: "OPEN" | "IN_PROCESS" | "COMPLETED" | "IN_COMPLETE";
  totalApprovedLoanAmount: number;
  totalNetDisbursementAmount: number;
  createdAt: string;
};

export type DisbursementBatchList = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: DisbursementBatch[];
};

export type DisbursementAgreementList = {
  total: number;
  currentPage: number;
  pageSize: number;
  searchTotal?: number;
  data: Agreement[];
};

export type DisbursementState = {
  disbursementBatchDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: DisbursementBatch;
  };
  disbursementBatchListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: DisbursementBatchList;
  };
  pendingDisbursementAgreements: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: DisbursementAgreementList;
  };
  disbursementAgreements: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: DisbursementAgreementList;
  };
  agreementDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: Agreement;
  };
  agreementFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      protectedsignedagreementpdf?: UploadedFile[];
      signedagreementpdf?: UploadedFile[];
    };
  };
};

const initialState: DisbursementState = {
  disbursementBatchDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  disbursementBatchListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  pendingDisbursementAgreements: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      searchTotal: 0,
      data: [],
    },
  },
  disbursementAgreements: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  agreementDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  agreementFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      protectedsignedagreementpdf: [],
      signedagreementpdf: [],
    },
  },
};

export const disbursementSlice = createSlice({
  name: "disbursement",
  initialState,
  reducers: {
    setAgreementDetails: (state, action) => {
      state.agreementDetails.data = action.payload;
    },
    disbursementStateReset: () => initialState,
    disbursementAgreementsStateReset: (state) => {
      state.disbursementAgreements = initialState.disbursementAgreements;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDisbursementBatchDetailsTrunk.pending, (state) => {
        state.disbursementBatchDetails.isLoading = true;
      })
      .addCase(getDisbursementBatchDetailsTrunk.fulfilled, (state, action) => {
        state.disbursementBatchDetails.isLoading = false;
        state.disbursementBatchDetails.data = action.payload;
      })
      .addCase(getDisbursementBatchDetailsTrunk.rejected, (state, action) => {
        state.disbursementBatchDetails.isLoading = false;
        state.disbursementBatchDetails.isError = true;
        state.disbursementBatchDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getDisbursementAgreementsByBatchIdTrunk.pending, (state) => {
        state.disbursementAgreements.isLoading = true;
      })
      .addCase(
        getDisbursementAgreementsByBatchIdTrunk.fulfilled,
        (state, action) => {
          state.disbursementAgreements.isLoading = false;
          state.disbursementAgreements.data = action.payload;
        },
      )
      .addCase(
        getDisbursementAgreementsByBatchIdTrunk.rejected,
        (state, action) => {
          state.disbursementAgreements.isLoading = false;
          state.disbursementAgreements.isError = true;
          state.disbursementAgreements.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getDisbursementBatchListingThunk.pending, (state) => {
        state.disbursementBatchListing.isLoading = true;
      })
      .addCase(getDisbursementBatchListingThunk.fulfilled, (state, action) => {
        state.disbursementBatchListing.isLoading = false;
        state.disbursementBatchListing.data = action.payload;
      })
      .addCase(getDisbursementBatchListingThunk.rejected, (state, action) => {
        state.disbursementBatchListing.isLoading = false;
        state.disbursementBatchListing.isError = true;
        state.disbursementBatchListing.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingDisbursementAgreementsThunk.pending, (state) => {
        state.pendingDisbursementAgreements.isLoading = true;
      })
      .addCase(
        getPendingDisbursementAgreementsThunk.fulfilled,
        (state, action) => {
          state.pendingDisbursementAgreements.isLoading = false;
          state.pendingDisbursementAgreements.data = action.payload;
        },
      )
      .addCase(
        getPendingDisbursementAgreementsThunk.rejected,
        (state, action) => {
          state.pendingDisbursementAgreements.isLoading = false;
          state.pendingDisbursementAgreements.isError = true;
          state.pendingDisbursementAgreements.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getAgreementDetailsThunk.pending, (state) => {
        state.agreementDetails.isLoading = true;
      })
      .addCase(getAgreementDetailsThunk.fulfilled, (state, action) => {
        state.agreementDetails.isLoading = false;
        state.agreementDetails.data = action.payload;
      })
      .addCase(getAgreementDetailsThunk.rejected, (state, action) => {
        state.agreementDetails.isLoading = false;
        state.agreementDetails.isError = true;
        state.agreementDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getFilesByEntityIdThunk.pending, (state) => {
        state.agreementFiles.isLoading = true;
      })
      .addCase(getFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.agreementFiles.isLoading = false;
        state.agreementFiles.data = groupBy(
          action.payload,
          (file: UploadedFile) => file.fileType.name.toLowerCase(),
        );
      })
      .addCase(getFilesByEntityIdThunk.rejected, (state, action) => {
        state.agreementFiles.isLoading = false;
        state.agreementFiles.isError = true;
        state.agreementFiles.errorMessage = action.payload;
      });
  },
});

export const {
  disbursementStateReset,
  setAgreementDetails,
  disbursementAgreementsStateReset,
} = disbursementSlice.actions;

export default disbursementSlice.reducer;
