export enum SME_APPLICATION_STATUS {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  SUBMITTED_FOR_VERIFIER_2 = "SUBMITTED_FOR_VERIFIER_2",
  SUBMITTED_FOR_APPROVAL_1 = "SUBMITTED_FOR_APPROVAL_1",
  SUBMITTED_FOR_APPROVAL_2 = "SUBMITTED_FOR_APPROVAL_2",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  REFERBACK = "REFERBACK",
  CANCELED = "CANCELED",
  FOLLOWED_UP = "FOLLOWED_UP",
}

export enum SME_AGREEMENT_STATUS {
  PENDING_ATTESTATION = "PENDING_ATTESTATION",
  PENDING_SIGNING = "PENDING_SIGNING",
  REQUESTED_FOR_LIVE_ATTESTATION = "REQUESTED_FOR_LIVE_ATTESTATION",
  PENDING_DISBURSEMENT = "PENDING_DISBURSEMENT",
  DISBURSED = "DISBURSED",
  CANCELED = "CANCELED"
}

export enum SME_DISBURSEMENT_BATCH_STATUS {
  OPEN = "OPEN",
  IN_PROCESS = "IN_PROCESS",
  COMPLETED = "COMPLETED",
  INCOMPLETE = "IN_COMPLETE",
}