import { createAsyncThunk } from "@reduxjs/toolkit";

import adminService from "./adminService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getAdminsThunk = createAsyncThunk(
  "admin/get",
  async (requestData: any, thunkAPI) => {
    try {
      return await adminService.getAdmins(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createAdminThunk = createAsyncThunk(
  "admin/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await adminService.createAdmin(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateAdminThunk = createAsyncThunk(
  "admin/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await adminService.updateAdmin(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteAdminThunk = createAsyncThunk(
  "admin/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await adminService.deleteAdmin(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAdminByIdThunk = createAsyncThunk(
  "admin/getById",
  async (requestData: any, thunkAPI) => {
    try {
      return await adminService.getAdminById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
