import "./index.less";

import { Input, message, Select, Space, Table, Tag } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import InfoGrid from "../../../../../components/InfoGrid";
import {
  getAllFieldVisitOfficerThunk,
  getFieldVisitCompanyThunk,
} from "../../../../../services/fieldVisitService/fieldVisitThunk";
import { AppDispatch, RootState } from "../../../../../store/store";
import FieldVisitOfficerFormModal from "../../Officer/FieldVisitOfficerFormModal";
import FieldVisitCompanyFormModal from "../FieldVisitCompanyFormModal";

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";

const { Search } = Input;

const FVCompanyDetailsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { fieldVisitCompanyDetailsState, fieldVisitOfficerState } = useSelector(
    (state: RootState) => state.fieldVisit,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchField, setSearchFiled] = useState("nric");
  const searchFields = ["firstName", "lastName", "nric"];

  const tablePagination = {
    total: fieldVisitOfficerState.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const companyDetails = [
    {
      title: "Company Code",
      value: fieldVisitCompanyDetailsState?.data?.companyCode ?? "-",
    },
    {
      title: "Company Name",
      value: fieldVisitCompanyDetailsState?.data?.companyName ?? "-",
    },
    {
      title: "Location",
      value:
        fieldVisitCompanyDetailsState.data?.companyLocation
          ?.map((item: any) => item)
          .join(", ") ?? "-",
    },
    {
      title: "Company Address",
      value: `
      ${fieldVisitCompanyDetailsState.data?.address1}
      ${
        fieldVisitCompanyDetailsState.data?.address2?.trim().length != 0
          ? ", " + fieldVisitCompanyDetailsState.data?.address2
          : ""
      }
      ${
        fieldVisitCompanyDetailsState.data?.address3?.trim().length != 0
          ? ", " + fieldVisitCompanyDetailsState.data?.address3
          : ""
      }`,
    },
    {
      title: "Company Post Code",
      value: fieldVisitCompanyDetailsState?.data?.postCode ?? "-",
    },
    {
      title: "Company City",
      value: fieldVisitCompanyDetailsState?.data?.companyCity ?? "-",
    },
    {
      title: "Company State",
      value: fieldVisitCompanyDetailsState?.data?.companyState ?? "-",
    },
    {
      title: "Office Number",
      value: fieldVisitCompanyDetailsState?.data?.officeNumber ?? "-",
    },
    {
      title: "Email",
      value: fieldVisitCompanyDetailsState?.data?.companyEmail ?? "-",
    },
    {
      title: "Flat Fee (RM)",
      value: fieldVisitCompanyDetailsState?.data?.flatFee ?? "-",
    },
    {
      title: "Fee per mileage (RM)",
      value: fieldVisitCompanyDetailsState?.data?.feePerMileage ?? "-",
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "NRIC",
      dataIndex: "nric",
      key: "nric",
    },
    {
      title: "Office Number",
      dataIndex: "officerNumber",
      key: "officerNumber",
    },
    {
      title: "Email",
      dataIndex: "officerEmail",
      key: "officerEmail",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text, record) => (text ? "Active" : "Not Active"),
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => {
        return (
          <Space>
            {!fieldVisitOfficerState.isLoading && (
              <>
                <FieldVisitOfficerFormModal
                  key={record.id + "0"}
                  data={record}
                  viewMode={true}
                />
                <FieldVisitOfficerFormModal
                  key={record.id + "1"}
                  data={record}
                  callback={() => fetchAllFieldVisitOfficer(initialParams)}
                  viewMode={false}
                />
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const onBack = () => {
    navigate(-1);
  };

  const fetchFieldVisitCompany = async () => {
    if (id) {
      dispatch(getFieldVisitCompanyThunk({ id }))
        .unwrap()
        .then(() => {})
        .catch((error: any) => {
          message.error("Fail to get company details");
        });
    }
  };

  const fetchAllFieldVisitOfficer = async (params?: any) => {
    dispatch(getAllFieldVisitOfficerThunk({ params, id }));
  };

  const onSearch = (value: string) => {
    fetchAllFieldVisitOfficer({
      ...initialParams,
      filters: { ...filterState, [searchField]: value },
    });
    setFilterState({ ...initialParams.filters, [searchField]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchAllFieldVisitOfficer({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useLifecycles(() => {
    // get company details
    fetchFieldVisitCompany();

    // get officer listing
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };
    fetchAllFieldVisitOfficer(params);
  });

  return (
    <>
      <Helmet>
        <title>Field Visit Company Details - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0"
        title={
          <div className="flex items-center gap-2">
            {`${fieldVisitCompanyDetailsState.data?.companyName} Details`}
            <Tag>
              {fieldVisitCompanyDetailsState.data?.isActive
                ? "Active"
                : "Not Active"}
            </Tag>
          </div>
        }
        onBack={onBack}
      />
      <div className="application-details-container">
        <div className="application-details-section-container">
          <div className="flex justify-between items-end mb-2">
            <h3 className="application-details-section-title mb-0">
              Company Details
            </h3>
            {!fieldVisitCompanyDetailsState.isLoading && (
              // Update button
              <FieldVisitCompanyFormModal
                key={fieldVisitCompanyDetailsState.data?.id}
                data={fieldVisitCompanyDetailsState.data}
                callback={() => fetchFieldVisitCompany()}
              />
            )}
          </div>

          <div className="info-container">
            <InfoGrid data={companyDetails} />
          </div>
        </div>
      </div>

      <div className="application-details-container">
        <h3 className="application-details-section-title">Officer</h3>
        <div className="flex justify-between items-center mb-2">
          <Input.Group compact>
            <Select
              defaultValue="nric"
              style={{ width: 200 }}
              onChange={(value) => setSearchFiled(value)}
              options={
                Array.isArray(columns)
                  ? columns
                      .filter(
                        (col: any) =>
                          col.key !== "action" &&
                          searchFields.includes(col.key),
                      )
                      .map((col: any) => ({
                        value: col.dataIndex,
                        label: `${col.title}`,
                      }))
                  : []
              }
            />

            <Search
              placeholder="Input search text"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </Input.Group>

          <FieldVisitOfficerFormModal
            callback={() => fetchAllFieldVisitOfficer(initialParams)}
          />
        </div>

        <Table
          rowKey="id"
          className="min-w-fit"
          columns={columns}
          pagination={tablePagination}
          dataSource={fieldVisitOfficerState?.data ?? []}
          loading={fieldVisitOfficerState?.isLoading}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default FVCompanyDetailsPage;
