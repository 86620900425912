import React from "react";
import { Col, Row } from "antd";

import "./index.less";

const InfoListRow = ({ title, value }: { title?: string; value?: any }) => {
  return (
    <Row className="info-title-row">
      <Col span={12}>
        <div className="info-title">{title}</div>
      </Col>
      <Col span={12}>
        <div className="info-value">{value ?? "-"}</div>
      </Col>
    </Row>
  );
};

export default InfoListRow;
