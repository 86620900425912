import { Card, Col, Row } from "antd";
import startCase from "lodash.startcase";

import { numberWithCommas } from "../../../helpers/number";

type CasesCardProps = {
  status?: string | null | undefined;
  totalLoanAmount?: number | undefined | null | string;
  totalCases?: number | undefined | null | string;
  totalInterestAmount?: number | undefined | null | string;
};

const CasesCard: React.FC<CasesCardProps> = (props) => {
  const { status, totalLoanAmount, totalCases, totalInterestAmount } = props;

  return (
    <Card
      title={
        <h4 className="mb-0">
          Total {status ? startCase(status) : status} Cases
        </h4>
      }
      size="small"
      className="mb-0"
    >
      <Row>
        <Col span={12}>Loan Amount (RM)</Col>
        <Col span={1}>:</Col>
        <Col>{numberWithCommas(totalLoanAmount)}</Col>
      </Row>
      <Row>
        <Col span={12}>Interest Amount (RM)</Col>
        <Col span={1}>:</Col>
        <Col>{numberWithCommas(totalInterestAmount)}</Col>
      </Row>

      <Row>
        <Col span={12}> Number of Cases</Col>
        <Col span={1}>:</Col>
        <Col>{totalCases}</Col>
      </Row>
    </Card>
  );
};

CasesCard.defaultProps = {
  status: "",
  totalLoanAmount: 0,
  totalCases: 0,
};

export default CasesCard;
