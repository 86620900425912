import {
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Agreement } from "../../../../../features/agreement/agreementSlice";
import { AppDispatch, RootState } from "../../../../../store/store";
import {
  patchPendingDisbursementAgreementToReferbackTrunk,
  repostAgreementTrunk,
} from "../../../../../services/disbursementService/disbursementTrunk";
import { getUnsuccessfulReasonTrunk } from "../../../../../services/dropdownParameterService/dropdownParameterThunk";
import { primaryColor } from "../../../../../assets/style/variables";
import { REFUND_FINANCE_STATUS } from "../../../../../enum/refund";
import {
  unPostRefundFinanceByIdThunk,
  updateRefundFinanceStatusThunk,
} from "../../../../../services/refundService/refundThunk";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const UpdateRefundFinanceModal: React.FC<{
  refundFinanceStatus: string;
  refundFinanceId: string;
  actionType: string;
  callback?: any;
}> = ({ refundFinanceId, refundFinanceStatus, actionType, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { disbursementUnsuccessfulReasonState } = useSelector(
    (state: RootState) => state.dropdownParameter,
  );

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const actionValue = Form.useWatch("action", form);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    const requestData: any = {
      id: refundFinanceId,
      status: REFUND_FINANCE_STATUS.REFERBACK,
    };

    const payload: any = [];

    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        payload.push({
          id: refundFinanceId,
          remark: values.remarks,
          unsuccessfulReason: values.reason,
        });

        requestData.payload = {
          remark: values.remarks,
          unsuccessfulReason: values.reason,
        };

        actionType === "Unpost"
          ? await dispatch(unPostRefundFinanceByIdThunk(payload))
              .unwrap()
              .then(() => {
                if (callback) callback();
                message.success("Update Successfully");
                navigate({
                  pathname: `/refund/finance-listing`,
                  search: `?type=${type}&tab=created`,
                });
              })
          : await dispatch(updateRefundFinanceStatusThunk(requestData))
              .unwrap()
              .then(() => {
                if (callback) callback();
                message.success("Update Successfully");
                navigate({
                  pathname: `/refund/finance-listing`,
                  search: `?type=${type}&tab=created`,
                });
              });
      })
      .catch((reason) => {
        message.error(reason);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(getUnsuccessfulReasonTrunk());
  }, []);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: primaryColor } }}>
      <Button type="primary" danger onClick={showModal}>
        {refundFinanceStatus === REFUND_FINANCE_STATUS.REFUNDED
          ? "Unpost"
          : "Referback"}
      </Button>
      <Modal
        title="Refund Finance Update"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={360}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          initialValues={{ action: "Unpost" }}
        >
          <Form.Item
            label="Unsuccessful Reason"
            name="reason"
            rules={[{ required: true, message: "Reason is required" }]}
          >
            <Select
              placeholder="Please Select"
              loading={disbursementUnsuccessfulReasonState.isLoading}
              allowClear
              options={
                disbursementUnsuccessfulReasonState?.data?.map((item) => ({
                  value: item.name,
                })) ?? []
              }
            />
          </Form.Item>
          <Form.Item
            label="Remarks"
            name="remarks"
            rules={[{ required: true, message: "Remarks is required" }]}
          >
            <Input.TextArea rows={3} placeholder="Please input remarks" />
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export default UpdateRefundFinanceModal;
