import "./MainLayout.less";

import { Button, Layout, Menu, message } from "antd";
import jwt_decode from "jwt-decode";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";

import { primaryColor } from "../assets/style/variables";
import { Role } from "../enum/roles";
import { userStateReset } from "../features/user/userSlice";
import { authRoutes } from "../routes";
import { logoutThunk } from "../services/userService/userThunk";
import { AppDispatch, RootState } from "../store/store";

import type { MenuProps } from "antd";
import {
  getFollowUpApplicationsThunk,
  getPendingApplicationsThunk,
  getReferbackApplicationsThunk,
  getReferbackFromPendingDisbursementApplicationsThunk,
  getSubmitForApprovalApplicationsThunk,
} from "../services/applicationService/applicationThunk";
import { ApplicationStatusEnum } from "../enum/applicationStepStatus";
import { on } from "events";
import { REFUND_FINANCE_STATUS, REFUND_STATUS } from "../enum/refund";
import {
  getApprovedRefundListThunk,
  getOpenRefundListThunk,
  getPendingRefundListThunk,
  getReferbackRefundListThunk,
  getRefundFinanceListThunk,
  getRefundedRefundThunk,
} from "../services/refundService/refundThunk";
import {
  getClearSpecialTaggingListThunk,
  getUnclearSpecialTaggingListThunk,
} from "../services/specialTaggingService/specialTaggingThunk";
import { SPECIAL_TAGGING_STATUS } from "../enum/specialTagging";
import {
  getConsolidatedEInvoiceListThunk,
  getEInvoiceTotalThunk,
  getSelfBilledEInvoiceListThunk,
} from "../services/collectionService/collectionThunk";
import { getSmeApplicationCountThunk } from "../services/smeApplicationService/smeApplicationThunk";

const useBreakpoint = createBreakpoint();
const { Content, Footer } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const menuWidth = 340;

export const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const {
    submitForApprovalApplicationList,
    pendingApplicationList,
    followUpApplicationList,
    referBackApplicationList,
    referBackFromPendingDisbursementApplicationList,
  } = useSelector((state: RootState) => state.application);

  const { smeApplicationCount } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const { eInvoiceTotal } = useSelector((state: RootState) => state.collection);
  const { clearSpecialTaggingList, unclearSpecialTaggingList } = useSelector(
    (state: RootState) => state.specialTagging,
  );

  const { refundListing, refundFinance } = useSelector(
    (state: RootState) => state.refund,
  );

  const breakpoint = useBreakpoint();
  // const contentRef = useRef<any>(null);
  // const [contentHeight, setContentHeight] = useState(300);
  // let height = 300;

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const isMobile = breakpoint === "tablet";
  const [openMenu, setOpenMenu] = useState(false);

  function checkDefaultOpenKeys(role: string): string[] {
    switch (role) {
      case Role.APPROVER:
        return ["loan-creation", "/application-listing"];
      case Role.FINANCE:
        return ["loan-creation"];
      case Role.CUSTOMER_SERVICE:
        return ["loan-creation"];
      default:
        return [];
    }
  }

  const defaultOpenKeys = useMemo(
    () => checkDefaultOpenKeys(currentRole),
    [currentRole],
  );

  const setTotalCountToRouteName = (routeId: string, routeName: string) => {
    if (routeId === "sme-application-listing-verifying") {
      const count =
        smeApplicationCount?.data?.pending +
        smeApplicationCount?.data?.followUp +
        smeApplicationCount?.data?.referBack +
        smeApplicationCount?.data?.referbackFromPendingDisbursement;
      return `${routeName} (${count})`;
    } else if (routeId === "first-approval-review") {
      return `${routeName} (${
        submitForApprovalApplicationList?.data?.total ?? 0
      })`;
    } else if (routeId === "application-listing-verifying") {
      const verifyingTotal =
        pendingApplicationList?.data?.total +
        followUpApplicationList?.data?.total +
        referBackApplicationList?.data?.total +
        referBackFromPendingDisbursementApplicationList?.data?.total;
      return `${routeName} (${verifyingTotal ?? 0})`;
    } else if (routeId === "refund-listing") {
      const refundTotal =
        refundListing.openRefund.data?.total +
        refundListing.pendingRefund.data?.total +
        refundListing.referbackRefund.data?.total;
      return `${routeName} (${refundTotal ?? 0})`;
    } else if (routeId === "refund-finance-listing") {
      const refundFinanceTotal = refundFinance.data.total;
      return `${routeName} (${refundFinanceTotal ?? 0})`;
    } else if (routeId === "special-tagging-listing") {
      const specialTaggingTotal =
        clearSpecialTaggingList?.data?.total +
        unclearSpecialTaggingList?.data?.total;
      return `${routeName} (${specialTaggingTotal ?? 0})`;
    } else if (routeId === "e-Invoice") {
      const total = eInvoiceTotal.data?.total;
      return `${routeName} (${total})`;
    } else if (routeId === "application-approval-review") {
      return `${routeName} (${
        submitForApprovalApplicationList?.data?.total ?? 0
      })`;
    }
    else if (routeId === "sme-application-listing-second-verifying") {
      return `${routeName} (${
        smeApplicationCount?.data?.submittedForVerifier2 ?? 0
      })`;
    }
    else if (routeId === "sme-application-second-approval-review") {
      return `${routeName} (${
        smeApplicationCount?.data?.submittedForApproval2 ?? 0
      })`;
    }
    else if (routeId === "sme-application-first-approval-review") {
      return `${routeName} (${
        smeApplicationCount?.data?.submittedForApproval1 ?? 0
      })`;
    }
    else {
      return routeName;
    }
  };

  const setRoutes: any = (route: any, routePath: any) =>
    route?.routes?.map(
      (subRoute: any) =>
        subRoute?.showInSiderMenu &&
        subRoute?.access.some((access: Role) => {
          return access.valueOf() === currentRole;
        }) &&
        getItem(
          setTotalCountToRouteName(subRoute.id, subRoute.name),
          (routePath ?? "") + "/" + (subRoute.path ?? setRoutes.id),
          subRoute.icon,
          setRoutes(subRoute, (route.path ?? "") + "/" + subRoute.path),
        ),
    );

  const items: MenuItem[] = useMemo(
    () =>
      authRoutes.map((route: any) =>
        route?.showInSiderMenu &&
        route?.access.some(
          (access: Role) => access.valueOf() === currentRole,
        ) &&
        route?.showInSiderMenu
          ? getItem(
              setTotalCountToRouteName(route.id, route.name),
              route.path ?? route.id,
              route.icon,
              route.routes && setRoutes(route, route.path),
            )
          : null,
      ),
    [
      currentRole,
      submitForApprovalApplicationList?.data?.total,
      pendingApplicationList.data.total,
      followUpApplicationList.data.total,
      referBackApplicationList.data.total,
      referBackFromPendingDisbursementApplicationList.data.total,
      smeApplicationCount?.data
    ],
  );

  const handleLogout = async () => {
    await dispatch(logoutThunk())
      .unwrap()
      .then(() => {
        dispatch(userStateReset());
        navigate(`/login`);
      });
  };

  // useEffect(() => {
  // setReloading(true);
  // const height = contentRef?.current?.clientHeight ?? 300;
  // console.log(height);
  // if (contentRef?.current?.clientHeight) {
  // setContentHeight(contentRef?.current?.firstChild?.clientHeight);
  // }
  // console.log(contentRef?.current?.firstChild?.clientHeight);
  // console.log(contentHeight);
  // setReloading(false);
  // }, [contentRef?.current?.firstChild?.clientHeight]);

  const onFetchApplications = (status: ApplicationStatusEnum, params: any) => {
    if (status === ApplicationStatusEnum.PENDING) {
      dispatch(getPendingApplicationsThunk(params));
    }
    if (status === ApplicationStatusEnum.REFERBACK) {
      dispatch(getReferbackApplicationsThunk(params));
    }
    if (status === ApplicationStatusEnum.FOLLOWED_UP) {
      dispatch(getFollowUpApplicationsThunk(params));
    }
    if (status === ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT) {
      dispatch(getReferbackFromPendingDisbursementApplicationsThunk(params));
    }
  };

  const onFetchRefundCase = (status: REFUND_STATUS, params: any) => {
    if (status === REFUND_STATUS.PENDING) {
      dispatch(getPendingRefundListThunk(params));
    }

    if (status === REFUND_STATUS.OPEN) {
      dispatch(getOpenRefundListThunk(params));
    }

    if (status === REFUND_STATUS.REFERBACK) {
      dispatch(getReferbackRefundListThunk(params));
    }
  };

  const onFetchRefundFinanceCase = (params: any) => {
    dispatch(getRefundFinanceListThunk(params));
  };

  const onFetchSpecialTagging = (params: any) => {
    const paramForUnclear = {
      ...params,
      filters: { status: SPECIAL_TAGGING_STATUS.UNCLEAR },
    };

    const paramForClear = {
      ...params,
      filters: { status: SPECIAL_TAGGING_STATUS.CLEAR },
    };

    dispatch(getUnclearSpecialTaggingListThunk(paramForUnclear));
    dispatch(getClearSpecialTaggingListThunk(paramForClear));
  };

  useEffect(() => {
    // fetch sme application count
    if (process.env.REACT_APP_SME_ENABLED === "YES") {
      dispatch(getSmeApplicationCountThunk());
    }

    dispatch(
      getSubmitForApprovalApplicationsThunk({
        pagination: {
          current: 1,
          pageSize: 20,
        },
      }),
    );

    onFetchApplications(ApplicationStatusEnum.PENDING, {
      pagination: {
        current: 1,
        pageSize: 20,
      },
    });

    onFetchApplications(ApplicationStatusEnum.REFERBACK, {
      pagination: {
        current: 1,
        pageSize: 20,
      },
    });

    onFetchApplications(ApplicationStatusEnum.FOLLOWED_UP, {
      pagination: {
        current: 1,
        pageSize: 20,
      },
    });

    onFetchApplications(
      ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT,
      {
        pagination: {
          current: 1,
          pageSize: 20,
        },
      },
    );

    onFetchRefundCase(REFUND_STATUS.OPEN, {
      pagination: {
        current: 1,
        pageSize: 20,
      },
      filters: {
        status: "OPEN",
      },
    });

    onFetchRefundCase(REFUND_STATUS.PENDING, {
      pagination: {
        current: 1,
        pageSize: 20,
      },
      filters: {
        status: "PENDING",
      },
    });

    onFetchRefundCase(REFUND_STATUS.REFERBACK, {
      pagination: {
        current: 1,
        pageSize: 20,
      },
      filters: {
        status: "REFERBACK",
      },
    });

    onFetchRefundFinanceCase({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      type: "refund",
      filters: { status: "PENDING", refundFinanceBatchId: "null" },
    });

    onFetchSpecialTagging({
      pagination: {
        current: 1,
        pageSize: 20,
      },
    });

    dispatch(getEInvoiceTotalThunk({}));

    if (token == null || currentRole == null) {
      message.error("Error: Role access not found");
      navigate("/logout");
    }
  }, [token, currentRole, dispatch, navigate]);

  return (
    <div className="site-layout-background" style={{ minHeight: "100vh" }}>
      <div className="site-layout-background">
        {/* menu */}
        <div
          className={`flex flex-col justify-between h-screen fixed bg-white
           ${isMobile ? "side-menu-mobile" : "side-menu"}
           ${openMenu ? "open" : ""}`}
          style={{ width: menuWidth }}
        >
          <div>
            {isMobile && (
              <div className="flex items-center gap-2">
                <Button
                  type="text"
                  icon={<MenuOutlined />}
                  onClick={() => setOpenMenu(!openMenu)}
                  style={{
                    fontSize: 16,
                    width: 60,
                    height: 60,
                    padding: 16,
                  }}
                />

                <div className="pl-6">
                  <h1 className="text-2xl mb-0 font-poppins">
                    <span className="font-thin">red</span>
                    <span className="font-bold" style={{ color: primaryColor }}>
                      CASH
                    </span>
                    <span className="ml-2 font-bold">CEP</span>
                  </h1>
                </div>
              </div>
            )}

            {!isMobile && (
              <div className="pl-6 pt-4 ">
                <h1 className="text-2xl font-poppins">
                  <span className="font-thin">red</span>
                  <span className="font-bold" style={{ color: primaryColor }}>
                    CASH
                  </span>
                  <span className="ml-2 font-bold">CEP</span>
                </h1>
              </div>
            )}

            <Menu
              defaultSelectedKeys={[location.pathname]}
              mode="inline"
              defaultOpenKeys={defaultOpenKeys}
              items={items}
              onClick={(menuItem) => {
                setOpenMenu(false);
                navigate(menuItem.key);
              }}
            />
          </div>
          <Button
            className="text-left m-2 font-bold"
            danger
            type="text"
            onClick={() => handleLogout()}
            icon={<LogoutOutlined />}
          >
            Log Out ({currentUser?.data?.username})
          </Button>
        </div>
        {openMenu && (
          <div
            className="fixed inset-0 bg-black opacity-20 transition-opacity duration-300 z-30"
            onClick={() => setOpenMenu(false)}
          ></div>
        )}
      </div>
      <Layout
        className="site-layout overflow-auto min-h-screen"
        style={isMobile ? { marginLeft: 0 } : { marginLeft: menuWidth }}
      >
        {isMobile && (
          <div
            className="flex justify-between items-center"
            style={{
              backgroundColor: "white",
            }}
          >
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={() => setOpenMenu(!openMenu)}
              style={{
                fontSize: 16,
                width: 60,
                height: 60,
              }}
            />
            <h1 className="text-center mb-0 font-poppins">
              <span className="font-thin">red</span>
              <span className="font-bold" style={{ color: primaryColor }}>
                CASH
              </span>
              <span className="ml-2 font-bold">CEP</span>
            </h1>
            <div className="invisible">
              <LogoutOutlined
                style={{
                  width: 60,
                  height: 60,
                }}
              />
            </div>
          </div>
        )}
        <Content className="p-4 overflow-auto">
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          © 2023 redONE Network Sdn Bhd (619094-D)
        </Footer>
      </Layout>
    </div>
  );
};
