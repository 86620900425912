import { Button, Divider, message, Popconfirm, Space, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import VideoModal from "../../components/VideoModal";
import {
  deletePreRecordedVideoThunk,
  getPreRecordedVideoBMThunk,
  getPreRecordedVideoENThunk,
} from "../../services/preRecordedVideoService/preRecordedVideoThunk";
import { AppDispatch, RootState } from "../../store/store";
import { displayDate } from "../../utils/datetime.util";
import PreRecordedVideoFormModal from "./PreRecordedVideoFormModal";

import type { ColumnsType } from "antd/es/table";

const PreRecordedVideoListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { preRecordedVideoEN, preRecordedVideoBM } = useSelector(
    (state: RootState) => state.preRecordedVideo,
  );
  const [deleteLoading, setDeleteLoading] = useState(false);
  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 1,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  function getColumns(language: "BM" | "EN"): ColumnsType<any> {
    const columns: ColumnsType<any> = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Video",
        key: "video",
        width: 120,
        render: (_, record) => (
          <VideoModal key={record.id} preRecordedVideo={record} />
        ),
      },
      {
        title: "Uploaded Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 180,
        render: (text) => (text ? displayDate(text) : "-"),
      },
      {
        title: "Action",
        key: "action",
        width: 324,
        render: (_, record) => (
          <Space>
            <PreRecordedVideoFormModal
              key={record.id}
              preRecordedVideo={record}
              videoLanguageVersion={language}
              callback={() => fetchPreRecordedVideos(initialParams)}
            />
            <Popconfirm
              title="Do you want to delete this pre recorded video?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return columns;
  }

  const fetchPreRecordedVideos = async (params?: any) => {
    await dispatch(getPreRecordedVideoENThunk({ params }));
    await dispatch(getPreRecordedVideoBMThunk({ params }));
  };

  const onDelete = async (preRecordedVideoId: string) => {
    setDeleteLoading(true);
    await dispatch(deletePreRecordedVideoThunk({ preRecordedVideoId }))
      .unwrap()
      .then(() => {
        message.success("Deleted");
        fetchPreRecordedVideos(initialParams);
      })
      .finally(() => setDeleteLoading(false));
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchPreRecordedVideos(params);
  });

  return (
    <>
      <Helmet>
        <title>Pre Recorded Video Config - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Pre Recorded Video Config" />

      {/* EN */}
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-md font-medium mb-0">EN version</h3>
        {preRecordedVideoEN?.data?.length < 1 && (
          <PreRecordedVideoFormModal
            key="createVideoEN"
            videoLanguageVersion="EN"
            callback={() => fetchPreRecordedVideos(initialParams)}
          />
        )}
      </div>
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={getColumns("EN")}
        pagination={false}
        dataSource={preRecordedVideoEN?.data || []}
        loading={preRecordedVideoEN?.isLoading}
      />

      <Divider />

      {/* BM */}
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-md font-medium mb-0">BM version</h3>
        {preRecordedVideoBM?.data?.length < 1 && (
          <PreRecordedVideoFormModal
            key="createVideoBM"
            videoLanguageVersion="BM"
            callback={() => fetchPreRecordedVideos(initialParams)}
          />
        )}
      </div>
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={getColumns("BM")}
        pagination={false}
        dataSource={preRecordedVideoBM?.data || []}
        loading={preRecordedVideoBM?.isLoading}
      />
    </>
  );
};

export default PreRecordedVideoListingPage;
