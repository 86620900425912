import "./index.less";

import {
  Button,
  Divider,
  message,
  Popconfirm,
  Result,
  Skeleton,
  Space,
  Tag,
} from "antd";
import startCase from "lodash.startcase";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import InfoGrid from "../../../components/InfoGrid";
import LoadingParagraph from "../../../components/LoadingParagraphLong";
import UploadedDocument from "../../../components/UploadedDocument";
import { AgreementStatusEnum } from "../../../enum/agreementStepStatus";
import { FileTypeEnum } from "../../../enum/fileType";
import { agreementDetailsStateReset } from "../../../features/agreement/agreementSlice";
import { applicationDetailsStateReset } from "../../../features/application/applicationSlice";
import { numberWithCommas } from "../../../helpers/number";
import { approveEStampingThunk } from "../../../services/agreementService/agreementThunk";
import { getFilesByEntityIdThunk } from "../../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../../store/store";
import {
  onCalculateNetDisbursementAmount,
  onCalculateStampingDutyFee,
  onCalculateTotalInstalmentAmount,
  onCalculateTotalInterest,
} from "../../../utils/calculation.util";
import { displayDate } from "../../../utils/datetime.util";
import { getSmeAgreementDetailsThunk } from "../../../services/smeAgreementService/smeAgreementThunk";
import { setSmeApplicationDetails } from "../../../features/smeApplication/smeApplicationSlice";
import { SME_ORGANIZATION_TYPE } from "../../../enum/smeApplication";

const SmeAgreementDetailsContent: React.FC<{
  title?: string;
  agreementId: string;
  modalView?: boolean;
  callback?: () => void;
}> = ({ title, agreementId, modalView, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const readOnly = queryParams.get("readOnly") === "true";

  const [approveLoading, setApproveLoading] = useState(false);

  const { agreementDetails, agreementFiles } = useSelector(
    (state: RootState) => state.smeAgreement,
  );

  const isPendingOrDisbursement =
    agreementDetails?.data?.status ===
      AgreementStatusEnum.PENDING_DISBURSEMENT ||
    agreementDetails?.data?.status === AgreementStatusEnum.DISBURSED;

  const isPendingEStampAgreement =
    isPendingOrDisbursement &&
    agreementDetails?.data?.clickedDownloadEStampingCert === false;

  const approvedEStampAgreement =
    isPendingOrDisbursement &&
    agreementDetails.data?.approveUploadedEStampingCert &&
    agreementDetails.data?.eStampingCertificateFileId != null;

  const uploadedEStamp =
    isPendingOrDisbursement &&
    agreementDetails.data?.eStampingCertificateFileId != null;

  const agreementInfo1 = [
    {
      title: "Agreement No",
      value: agreementDetails?.data?.agreementNo,
    },
    // if customer not yet sign, Agreement date & First instalment date & Maturity date just show blank
    {
      title: "Agreement Date",
      value: agreementDetails?.data?.signDate
        ? displayDate(new Date(agreementDetails?.data?.signDate))
        : "-",
    },
    {
      title: "First Instalment Date",
      value:
        agreementDetails?.data?.signDate &&
        agreementDetails?.data?.firstInstalmentDate
          ? displayDate(agreementDetails?.data?.firstInstalmentDate)
          : "-",
    },
    {
      title: "Maturity Date",
      value:
        agreementDetails?.data?.signDate &&
        agreementDetails?.data?.lastInstalmentDate
          ? displayDate(agreementDetails?.data?.lastInstalmentDate)
          : "-",
    },
    {
      title: "Finance Amount",
      value:
        agreementDetails?.data?.smeApplication?.financeAmount
          ? `RM ${numberWithCommas(+agreementDetails?.data?.smeApplication?.financeAmount)}`
          : "-",
    },
    {
      title: "Interest Amount",
      value: `RM ${numberWithCommas(
        onCalculateTotalInterest(
          agreementDetails?.data?.smeApplication?.financeAmount ? +agreementDetails?.data?.smeApplication?.financeAmount : 0,
          agreementDetails?.data?.loanInterestRate ? +agreementDetails?.data?.loanInterestRate : 0,
          agreementDetails?.data?.loanTenure || 0,
        ),
      )}`,
    },
    {
      title: "Interest Rate",
      value: agreementDetails?.data?.loanInterestRate ? `${numberWithCommas(+agreementDetails?.data?.loanInterestRate)} %` : '-',
    },
    {
      title: "Instalment Period",
      value: agreementDetails?.data?.loanTenure ?? "-",
    },
    {
      title: "Total Instalment Amount",
      value: `RM ${numberWithCommas(
        onCalculateTotalInstalmentAmount(
          agreementDetails?.data?.smeApplication?.financeAmount ? +agreementDetails?.data?.smeApplication?.financeAmount : 0,
          agreementDetails?.data?.loanInterestRate ? +agreementDetails?.data?.loanInterestRate : 0,
          agreementDetails?.data?.loanTenure || 0,
        ),
      )}`,
    },
    {
      title: "Monthly Instalment Amount",
      value:
        agreementDetails?.data?.monthlyInstalmentAmount != null
          ? `RM ${numberWithCommas(
              +agreementDetails?.data?.monthlyInstalmentAmount,
            )}`
          : "-",
    },
    {
      title: "Final Instalment Amount",
      value:
        agreementDetails?.data?.lastInstalmentAmount != null
          ? `RM ${numberWithCommas(
              +agreementDetails?.data?.lastInstalmentAmount,
            )}`
          : "-",
    },
    {
      title: "Discount Amount",
      value:
        agreementDetails?.data?.smeApplication?.processingFeeToDiscount
          ? `RM ${numberWithCommas(
              +agreementDetails?.data?.smeApplication?.processingFeeToDiscount,
            )}`
          : "-",
    },
  ];

  const agreementInfo2 = [
    {
      title: "Disbursement Date",
      value: agreementDetails?.data?.disbursementPostedDate
        ? displayDate(agreementDetails?.data?.disbursementPostedDate)
        : "-",
    },
    {
      title: "Disbursement Amount",
      value: `RM ${numberWithCommas(
        onCalculateNetDisbursementAmount(
          agreementDetails?.data?.smeApplication?.financeAmount ? +agreementDetails?.data?.smeApplication?.financeAmount : 0,
          agreementDetails?.data?.smeApplication?.processingFee ? +agreementDetails?.data?.smeApplication?.processingFee : 0,
          agreementDetails?.data?.smeApplication?.stampingDutyPercentage ? +agreementDetails?.data?.smeApplication?.stampingDutyPercentage : 0,
          agreementDetails?.data?.smeApplication?.processingFeeToDiscount ? +agreementDetails?.data?.smeApplication?.processingFeeToDiscount : 0,
          0,
          agreementDetails?.data?.smeApplication?.status,
        ),
      )}`,
    },
    {
      title: "Processing Fee",
      value:
        agreementDetails.data?.smeApplication?.processingFee
          ? `RM ${numberWithCommas(
              +agreementDetails.data?.smeApplication?.processingFee,
            )}`
          : "-",
    },
    {
      title: "Stamp Duty Fee",
      value: onCalculateStampingDutyFee(
        agreementDetails?.data?.smeApplication?.financeAmount ? +agreementDetails?.data?.smeApplication?.financeAmount : 0,
        agreementDetails?.data?.smeApplication?.stampingDutyPercentage ? +agreementDetails?.data?.smeApplication?.stampingDutyPercentage : 0,
      )
        ? `RM ${numberWithCommas(
            onCalculateStampingDutyFee(
              agreementDetails?.data?.smeApplication?.financeAmount ? +agreementDetails?.data?.smeApplication?.financeAmount : 0,
              agreementDetails?.data?.smeApplication?.stampingDutyPercentage ? +agreementDetails?.data?.smeApplication?.stampingDutyPercentage : 0,
            ),
          )}`
        : "-",
    },
  ];

  const applicantDisbursementData = [
    {
      title: "Applicant Name",
      value: agreementDetails?.data?.smeApplication?.smeIndividualApplication
        ? agreementDetails?.data?.smeApplication?.smeIndividualApplication?.name
        : agreementDetails?.data?.smeApplication?.smeSolePropApplication
          ? agreementDetails?.data?.smeApplication?.smeSolePropApplication?.name
          : agreementDetails?.data?.smeApplication?.smePartnershipApplication
            ? agreementDetails?.data?.smeApplication.smePartnershipApplication?.name
            : "-",
    },
    {
      title: "Applicant NRIC",
      value: agreementDetails?.data?.smeApplication?.smeIndividualApplication
        ? agreementDetails?.data?.smeApplication?.smeIndividualApplication?.nric
        : agreementDetails?.data?.smeApplication?.smeSolePropApplication
          ? agreementDetails?.data?.smeApplication?.smeSolePropApplication?.nric
          : agreementDetails?.data?.smeApplication?.smePartnershipApplication
            ? agreementDetails?.data?.smeApplication.smePartnershipApplication?.nric
            : "-",
    },
  ]

  const companyDisbursementData = [
    {
      title: "Company Name",
      value: agreementDetails?.data?.smeApplication?.smeCompanyApplication?.companyName || "-",
    },
    {
      title: "Business Registration No.",
      value: agreementDetails?.data?.smeApplication?.smeCompanyApplication?.businessOldRegistrationNo || agreementDetails?.data?.smeApplication?.smeCompanyApplication?.businessOldRegistrationNo || "-",
    },
  ]

  const disbursementData = [
    ...(agreementDetails?.data?.smeApplication?.organizationType === SME_ORGANIZATION_TYPE.COMPANY ? companyDisbursementData : applicantDisbursementData),
    {
      title: "Bank Account No.",
      value: agreementDetails?.data?.smeApplication?.smeIndividualApplication
        ? agreementDetails?.data?.smeApplication?.smeIndividualApplication
            ?.personalBankAccountNo
        : agreementDetails?.data?.smeApplication?.smeSolePropApplication
        ? agreementDetails?.data?.smeApplication?.smeSolePropApplication
            ?.personalBankAccountNo
        : agreementDetails?.data?.smeApplication?.smePartnershipApplication
        ? agreementDetails?.data?.smeApplication.smePartnershipApplication
            .personalBankAccountNo
        : agreementDetails?.data?.smeApplication?.smeCompanyApplication
        ? agreementDetails?.data?.smeApplication?.smeCompanyApplication.bankAccountNo
        : "-",
    },
    {
      title: "Bank Name",
      value: agreementDetails?.data?.smeApplication?.smeIndividualApplication
        ? agreementDetails?.data?.smeApplication?.smeIndividualApplication
            ?.personalBankName
        : agreementDetails?.data?.smeApplication?.smeSolePropApplication
        ? agreementDetails?.data?.smeApplication?.smeSolePropApplication
            ?.personalBankName
        : agreementDetails?.data?.smeApplication?.smePartnershipApplication
        ? agreementDetails?.data?.smeApplication.smePartnershipApplication
            .personalBankName
        : agreementDetails?.data?.smeApplication?.smeCompanyApplication
        ? agreementDetails?.data?.smeApplication?.smeCompanyApplication.bankName
        : "-",
    },
    {
      title: agreementDetails?.data?.smeApplication?.smeCompanyApplication ? "Business Contact No." : "Contact No.",
      value: agreementDetails?.data?.smeApplication?.smeIndividualApplication
        ? agreementDetails?.data?.smeApplication?.smeIndividualApplication
          ?.applicantPhoneNo
        : agreementDetails?.data?.smeApplication?.smeSolePropApplication
          ? agreementDetails?.data?.smeApplication?.smeSolePropApplication
            ?.applicantPhoneNo
          : agreementDetails?.data?.smeApplication?.smePartnershipApplication
            ? agreementDetails?.data?.smeApplication.smePartnershipApplication
              .applicantPhoneNo
            : agreementDetails?.data?.smeApplication?.smeCompanyApplication
              ? agreementDetails?.data?.smeApplication?.smeCompanyApplication.businessContactNo
              : "-",
    }
  ];

  const onBack = () => {
    navigate(-1);
  };

  const fetchFileList = () => {
    dispatch(getFilesByEntityIdThunk({ entityId: agreementDetails?.data?.id }));
  };

  const initProcess = async () => {
    await dispatch(getSmeAgreementDetailsThunk({ agreementId }))
    await dispatch(getFilesByEntityIdThunk({ entityId: agreementId }));
  };

  const onApprove = async () => {
    setApproveLoading(true);
    await dispatch(approveEStampingThunk({ agreementId }))
      .unwrap()
      .then(() => {
        message.success("Approved");
        initProcess();
      })
      .finally(() => setApproveLoading(false));
  };

  const resetDetailsState = () => {
    dispatch(applicationDetailsStateReset());
    dispatch(agreementDetailsStateReset());
  };

  useLifecycles(
    () => agreementId && initProcess(),
    () => resetDetailsState(),
  );

  return (
    <div>
      <PageHeader
        className="p-0 mb-4"
        onBack={modalView ? undefined : onBack}
        title={
          <Space align="center">
            <h3 className="m-0">{title ?? "Agreement Details"}</h3>
            {!agreementDetails.isLoading &&
              !agreementDetails.isError &&
              agreementDetails.data?.status && (
                <Tag>{startCase(agreementDetails.data?.status)}</Tag>
              )}
          </Space>
        }
      />

      {agreementDetails.isLoading ? (
        <LoadingParagraph />
      ) : agreementDetails.data && !agreementDetails.isError ? (
        <>
          <div className="agreement-details-section-container">
            <h3 className="agreement-details-section-title">Loan Agreement</h3>
            <InfoGrid data={agreementInfo1} />
            <div className="p-4" />
            <InfoGrid data={agreementInfo2} />
          </div>
          <div className="agreement-details-section-container">
            <h3 className="agreement-details-section-title">
              Disbursement Info
            </h3>
            <InfoGrid data={disbursementData} />
          </div>
          <br />
          <div className="agreement-details-section-container">
            <h3 className="agreement-details-section-title">
              Signed Agreement
            </h3>
            {agreementFiles.isLoading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : (
              <UploadedDocument
                key={"1"}
                readOnly={true}
                entityId={agreementDetails.data.id}
                fileType={FileTypeEnum.SIGNEDAGREEMENTPDF}
                defaultFileList={agreementFiles?.data?.signedagreementpdf}
                callback={fetchFileList}
                agreementType="SME"
              />
            )}
          </div>
          <br />
          {!isPendingEStampAgreement && (
            <div className="agreement-details-section-container">
              <h3 className="agreement-details-section-title">
                E-Stamping Certificate
              </h3>
              {agreementFiles.isLoading ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : (
                <UploadedDocument
                  key={"1"}
                  readOnly={readOnly}
                  entityId={agreementDetails.data.id}
                  fileType={FileTypeEnum.SME_E_STAMPING}
                  defaultFileList={agreementFiles?.data?.sme_e_stamping}
                  callback={fetchFileList}
                />
              )}
              {!readOnly && uploadedEStamp && (
                <>
                  <div className="flex justify-end">
                    <Popconfirm
                      title="Approve this e-stamp?"
                      onConfirm={() => onApprove()}
                      okText="Yes"
                      cancelText="No"
                      disabled={approvedEStampAgreement}
                    >
                      <Button
                        loading={approveLoading}
                        type="primary"
                        disabled={approvedEStampAgreement}
                      >
                        {approvedEStampAgreement ? "Approved" : "Approve"}
                      </Button>
                    </Popconfirm>
                  </div>
                  <Divider />
                </>
              )}
            </div>
          )}
          {/* {(agreementDetails?.data?.status ===
            SmeAgreementStatusEnum.PENDING_ATTESTATION ||
            agreementDetails?.data?.status ===
              SmeAgreementStatusEnum.PENDING_SIGNING ||
            agreementDetails?.data?.status ===
              SmeAgreementStatusEnum.REQUESTED_FOR_LIVE_ATTESTATION) &&
            process.env.REACT_APP_AGREEMENT_CANCELED_ENABLED !== "NO" && (
              <ConfirmCancelApplication
                key={agreementDetails?.data?.smeApplication?.id}
                callback={initProcess}
                agreementType="SME"
              />
            )} */}
        </>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the agreement does not exist."
        />
      )}
    </div>
  );
};

export default SmeAgreementDetailsContent;
