import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const createWriteOffBatch = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.post<any>(
    `/redCash/api/v1.0/writeOff/generateWriteOffBatch`,
    requestData,
  );
  return result.data;
};

const getAllWriteOffListing = async (requestData: any) => {
  const params = {
    ...requestData,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/listing`,
    { params },
  );

  return result.data;
};

const getOpenWriteOffListing = async (requestData: any) => {
  const params = {
    ...requestData,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/listing`,
    { params },
  );
  return result.data;
};

const getInProcessWriteOffListing = async (requestData: any) => {
  const params = {
    ...requestData,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/listing`,
    { params },
  );
  return result.data;
};

const getPendingWriteOffListing = async (requestData: any) => {
  const params = {
    ...requestData,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/listing`,
    { params },
  );
  return result.data;
};

const getReferbackWriteOffListing = async (requestData: any) => {
  const params = {
    ...requestData,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/listing`,
    { params },
  );
  return result.data;
};

const getVoidWriteOffListing = async (requestData: any) => {
  const params = {
    ...requestData,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/listing`,
    { params },
  );
  return result.data;
};

const getApprovedWriteOffListing = async (requestData: any) => {
  const params = {
    ...requestData,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/listing`,
    { params },
  );
  return result.data;
};

const getWriteOffByBatchNo = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/listing`,
    { params: requestData },
  );
  return result.data;
};

const getWriteOffSummaryByBatchId = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/detailSummary/batchId/${requestData.id}`,
  );
  return result.data;
};

const getWriteOffDetailsByBatchId = async (requestData: any) => {
  const { pagination } = requestData;
  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    // currentPage: requestData.pagination.current,
    // pageSize: requestData.pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/details/batchId/${requestData.id}/status/${requestData?.status}`,
    { params },
  );

  return result.data;
};

const getWriteOffRemarkListingByBatchId = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/getRemark/batchId/${requestData.id}?sortOrder=desc&sortField=createdAt`,
  );
  return result.data;
};

const addWriteOffRemark = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.post(
    `/redCash/api/v1.0/writeOff/batch/insertRemark`,
    requestData,
  );
  return result.data;
};

const addAgreementNoToWriteOffBatch = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.post(
    `/redCash/api/v1.0/writeOff/batch/addNewAccount`,
    requestData,
  );
  return result.data;
};

const selectAgreementNoInWriteOffBatch = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch(
    `/redCash/api/v1.0/writeOff/batch/selectAgreement`,
    requestData,
  );
  return result.data;
};

const updateWriteOffStatus = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch(
    `/redCash/api/v1.0/writeOff/batch/id/${requestData.id}/status/${requestData.status}`,
  );
  return result.data;
};

const downloadWriteOffApprovalList = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/generateApprovalList/batchId/${requestData.id}`,
    { responseType: "blob" },
  );
  return {
    filename: result.headers["content-disposition"]?.split('"')[1],
    content: result.data,
  };
};

const uploadWriteOffApprovalList = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.post(
    `/redCash/api/v1.0/writeOff/batch/uploadApprovalList/batchId/${requestData.id}`,
    requestData.file,
  );
  return result.data;
};

const uploadWriteOffApprovalForm = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.post(
    `/redCash/api/v1.0/writeOff/batch/uploadApprovalDocuments/batchId/${requestData.id}`,
    requestData.file,
  );
  return result.data;
};

const getWriteOffFileByFileId = async (requestData: any) => {
  const url = `/redCash/api/v1.0/waiver/file/${requestData.fileId}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    responseType: "blob",
  });
  return res.data;
};

const getWriteOffMonitorListing = async (requestData: any) => {
  const params = {
    ...requestData,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };

  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOff/batch/getApprovedAccount`,
    { params },
  );

  return result.data;
};

const writeOffService = {
  createWriteOffBatch,

  getAllWriteOffListing,
  getOpenWriteOffListing,
  getInProcessWriteOffListing,
  getPendingWriteOffListing,
  getReferbackWriteOffListing,
  getVoidWriteOffListing,
  getApprovedWriteOffListing,

  getWriteOffByBatchNo,
  getWriteOffSummaryByBatchId,
  getWriteOffDetailsByBatchId,
  addWriteOffRemark,
  getWriteOffRemarkListingByBatchId,
  addAgreementNoToWriteOffBatch,
  selectAgreementNoInWriteOffBatch,
  updateWriteOffStatus,
  downloadWriteOffApprovalList,
  uploadWriteOffApprovalList,
  uploadWriteOffApprovalForm,
  getWriteOffFileByFileId,

  getWriteOffMonitorListing,
};

export default writeOffService;
