import { Button, Input, Select, Table, TablePaginationConfig } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { getVideoAttestationsThunk } from "../../../../services/attestationService/attestationThunk";
import { AppDispatch, RootState } from "../../../../store/store";

import type { ColumnsType } from "antd/es/table";

const { Option } = Select;
const { Search } = Input;

const VideoAttestationPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { videoAttestationList } = useSelector(
    (state: RootState) => state.attestation,
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };

  const tablePagination = {
    total: videoAttestationList?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/agreement/details/${record.agreement?.id}`)}
        >
          <span style={{ userSelect: "text" }}>
            {record.agreement?.agreementNo}
          </span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "applicantFirstName",
      key: "applicantFirstName",
      render: (text, record) =>
        record?.agreement.application?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "applicantNric",
      key: "applicantNric",
      render: (text, record) =>
        record?.agreement.application?.applicantNric ?? "-",
    },
  ];

  const fetchAttestations = async (params?: any) => {
    await dispatch(getVideoAttestationsThunk(params));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchAttestations({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      fetchAttestations({
        ...initialParams,
        // filters: filterState,
      });
      setSearchValue("");
    }

    setFilterState({
      ...initialParams.filters,
    });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    const param =
      searchValue.length === 0
        ? {
            ...initialParams,
            ...filterState,
            pagination,
            sortOrder: "desc",
            sortField: "createdAt",
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortOrder: "desc",
            sortField: "createdAt",
            search: {
              [searchFiled]: searchValue,
            },
          };

    fetchAttestations(param);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>Video Attestation - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "agreementNo", label: "Agreement No." },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={videoAttestationList?.data?.data ?? []}
        loading={videoAttestationList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default VideoAttestationPage;
