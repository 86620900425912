import { request } from "http";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

export const getSpecialTaggingList = async (requestData: any) => {
  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
  };
  const url = `/redCash/api/v1.0/special-tagging/list`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params,
  });
  return res.data;
};

export const clearPaymentSpecialTagging = async (requestData: any) => {
  const url = `/redCash/api/v1.0/special-tagging/clear-payment`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const requestRefundSpecialTagging = async (requestData: any) => {
  const url = `/redCash/api/v1.0/special-tagging/request-refund`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};
