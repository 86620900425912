import "./index.less";

import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import AgreementDetailsContent from "../AgreementDetailsContent";
import { Result } from "antd";

const SmeAgreementDetailsPage = () => {
  const { agreementId } = useParams();

  return (
    <>
      <Helmet>
        <title>Agreement Details - redCASH CEP</title>
      </Helmet>
      {agreementId ? (
        <AgreementDetailsContent agreementId={agreementId} />
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, agreement not found."
        />
      )}
    </>
  );
};

export default SmeAgreementDetailsPage;
