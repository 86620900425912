import React from "react";
import { List } from "antd";

import "./index.less";
import InfoListRow from "./InfoListRow";

const InfoList = ({ title, data }: { title?: string; data?: any[] }) => {
  return (
    <List
      className="info-container"
      style={{ padding: 0 }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <InfoListRow title={item.title} value={item.value} />
        </List.Item>
      )}
    />
  );
};

export default InfoList;
