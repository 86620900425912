import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  TimePicker,
  TimePickerProps,
  Upload,
  message,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "@ant-design/pro-components";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { AppDispatch, RootState } from "../../store/store";
import { FileTypeEnum } from "../../enum/fileType";
import { uploadManualPaymentFileThunk } from "../../services/paymentService/paymentThunk";
import Dragger from "antd/es/upload/Dragger";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import { displayDateAndTime } from "../../utils/datetime.util";
import { ManualPaymentData } from "../../features/payment/paymentSlice";
import { getProductCodeByCompanyId } from "../../services/masterService/masterService";
import {
  deleteWhitelistMemberThunk,
  downloadWhitelistTemplateThunk,
  getWhitelistMemberThunk,
  uploadWhitelistThunk,
} from "../../services/userService/userThunk";
import moment from "moment";
import { deleteWhitelistedMemberById } from "../../services/userService/userService";

const InterestDiscount = () => {
  const timeFormat = "HH:mm";
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch<AppDispatch>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [productCode, setProductCode] = useState<any>(null);
  const [uploading, setUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [productList, setProductList] = useState<any>([]);
  const { whitelistMember } = useSelector((state: RootState) => state.user);
  const [expiredDate, setExpiredDate] = useState<any>(null);
  const [downloading, setDownloading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  const [form] = Form.useForm();

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);

  const tablePagination = {
    total: whitelistMember?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: whitelistMember.currentPage ?? 1,
    pageSize: whitelistMember.pageSize ?? 10,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Phone No",
      dataIndex: "phoneNo",
      key: "phoneNo",
    },
    {
      title: "Started At",
      dataIndex: "startDate",
      key: "startDate",
      render: (tex, record) => displayDateAndTime(record.startDate),
    },
    {
      title: "Expired Date",
      dataIndex: "expiredAt",
      render: (tex, record) => displayDateAndTime(record.expiredAt),
    },
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (tex, record) => displayDateAndTime(record.createdAt),
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            <Space>
              <Button
                type="primary"
                onClick={() => deleteWhitelistMember(record.id)}
              >
                Delete
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  const deleteWhitelistMember = async (id: string) => {
    await deleteWhitelistedMemberById(id)
      .then(() => {
        message.success("Delete Successfully");
        fetchWhitelistMember(initialParams);
      })
      .catch(() => {
        message.error("Delete Failed");
      });
  };

  const uploadProps: UploadProps = {
    maxCount: 1,
    fileList,
    beforeUpload: (file: RcFile) => {
      if (fileList.length > 0) {
        message.error("Only allowed 1 file");
        return Upload.LIST_IGNORE;
      }
      const correctFileType =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!correctFileType) {
        message.error("Only allowed xlsx");
        return Upload.LIST_IGNORE;
      }

      fileList.push(file);
      return false;
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  const handleOk = async () => {
    setUploading(true);
    setOpen(false);
    if (fileList.length > 2) {
      setUploading(false);
      setFileList([]);
      return message.error("Limit 1 files only");
    } else if (fileList.length == 0) {
      setUploading(false);
      setFileList([]);
      return message.error("Please upload file");
    } else if (!productCode) {
      setUploading(false);
      setFileList([]);
      return message.error("Please select  product code");
    } else {
      await form
        .validateFields()
        .then(async (values) => {
          const formData = new FormData();
          const expiredAt = moment(
            expiredDate !== null ? expiredDate : new Date(),
          );
          formData.append("expiredAt", expiredAt.toISOString());
          formData.append("productCode", productCode ?? "");
          formData.append("file", fileList[0] as RcFile);

          dispatch(uploadWhitelistThunk(formData))
            .unwrap()
            .then(async (res) => {
              message.success("Submit Successfully");

              fetchWhitelistMember(initialParams);
              handleCancel();
              setUploading(false);
              setFileList([]);
            })
            .catch(() => {
              setUploading(false);
              setFileList([]);
              handleCancel();
            });
        })
        .catch(() => {
          message.error("Something went wrong. Please try again later.");
          setFileList([]);
          setUploading(false);
          handleCancel();
        });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onDownloadFile = async () => {
    setDownloading(true);
    setIsLoadingExcel(true);

    await dispatch(downloadWhitelistTemplateThunk())
      .unwrap()
      .then((res) => {
        downloadFile(res, `WhitelistedTemplate`, "xlsx");
      })
      .catch((error) => {
        console.log(error);
        message.error("Failed to download template. Please try again later.");
      })
      .finally(() => setDownloading(false));
  };

  const downloadFile = (csvContent: any, fileName: any, filetype: any) => {
    const blob = new Blob([csvContent], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    const _win = window.navigator as any;
    if (_win && _win.msSaveBlob) {
      // For IE browser
      _win.msSaveBlob(blob, fileName);
      setIsLoadingExcel(false);
    } else {
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
      setIsLoadingExcel(false);
    }
  };

  const onDeleteWhitelistMember = async (id: string) => {
    dispatch(deleteWhitelistMemberThunk(id))
      .unwrap()
      .then((res) => {
        message.success("Delete Successfully");
        fetchWhitelistMember(initialParams);
      });
  };

  const getProductList = async () => {
    await getProductCodeByCompanyId().then((res) => {
      setProductList(res);
    });
  };

  const fetchWhitelistMember = async (params?: any) => {
    await dispatch(getWhitelistMemberThunk(params));
  };

  useEffect(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: tablePagination.current,
        pageSize: tablePagination.pageSize,
      },
    };

    getProductList();
    fetchWhitelistMember(params);
  }, []);

  const handleTableChange: TableProps<ManualPaymentData>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      sorter?.order == null
        ? {
            ...initialParams,
            ...filterState,
            pagination,
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          };

    fetchWhitelistMember(param);
  };

  const productOnChange = (value: string) => {
    setProductCode(value.split(" ")[0]);
    setExpiredDate(value.split(" ")[1]);
  };

  return (
    <>
      <Helmet>
        <title>Interest Discount</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Interest Discount" />
      <Row justify="start" className="mb-2" align="bottom">
        <Space>
          <Input.Group compact>
            <Select
              placeholder="Product Code"
              style={{ width: 200 }}
              onChange={(value) => productOnChange(value)}
              options={
                productList?.map((item: any) => ({
                  label: item.code,
                  value: `${item.code} ${item.expiredDate}`,
                })) ?? []
              }
            />
          </Input.Group>
          <Button
            type="primary"
            onClick={() => onDownloadFile()}
            loading={downloading}
          >
            Download File
          </Button>
          <Button
            type="primary"
            onClick={() => setOpen(true)}
            loading={uploading}
          >
            Upload File
          </Button>
        </Space>
      </Row>
      <br />

      <Table
        rowKey="id"
        className="min-w-fit"
        dataSource={whitelistMember.data ?? []}
        columns={columns}
        pagination={tablePagination}
        onChange={handleTableChange}
      />

      <Modal
        open={open}
        title="Upload File"
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
      >
        <Form
          form={form}
          name="uploadInterestDiscountFile"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item label="Interest Discount File">
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default InterestDiscount;
