import { createSlice } from "@reduxjs/toolkit";

import {
  getBankListThunk,
  getEmployeeTypeListThunk,
  getGrossSalaryRangeListThunk,
  getLoanReasonListThunk,
  getRaceListThunk,
  getRelationshipListThunk,
  getUnsuccessfulReasonTrunk,
} from "../../services/dropdownParameterService/dropdownParameterThunk";

export type Race = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  malayName?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type GrossSalary = {
  id: string;
  adminId: string;
  minSalary: number;
  maxSalary: number;
  createdAt?: string;
  updatedAt?: string;
};

export type LoanReason = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type EmployeeType = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  malayName?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Bank = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  malayName?: string;
  ibgRoutingNumber: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Relationship = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  malayName?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type DisbursementUnsuccessfulReason = {
  id: string;
  companyId: string;
  code: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type DropdownParameterState = {
  raceState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Race[];
  };
  grossSalaryRangeState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: GrossSalary[];
  };
  loanReasonState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: LoanReason[];
  };
  employeeTypeState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: EmployeeType[];
  };
  bankState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Bank[];
  };
  relationshipState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Relationship[];
  };
  disbursementUnsuccessfulReasonState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: DisbursementUnsuccessfulReason[];
  };
};

const initialState: DropdownParameterState = {
  raceState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  grossSalaryRangeState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  loanReasonState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  employeeTypeState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  bankState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  relationshipState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  disbursementUnsuccessfulReasonState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const dropdownParameterSlice = createSlice({
  name: "dropdownParameter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRaceListThunk.pending, (state) => {
        state.raceState.isLoading = true;
      })
      .addCase(getRaceListThunk.fulfilled, (state, action) => {
        state.raceState.isLoading = false;
        state.raceState.data =
          action.payload.length > 1
            ? action.payload.sort((a: Race, b: Race) => +a.code - +b.code)
            : action.payload;
      })
      .addCase(getRaceListThunk.rejected, (state, action) => {
        state.raceState.isLoading = false;
        state.raceState.isError = true;
        state.raceState.errorMessage = action.payload;
      });

    builder
      .addCase(getGrossSalaryRangeListThunk.pending, (state) => {
        state.grossSalaryRangeState.isLoading = true;
      })
      .addCase(getGrossSalaryRangeListThunk.fulfilled, (state, action) => {
        state.grossSalaryRangeState.isLoading = false;
        state.grossSalaryRangeState.data = action.payload;
      })
      .addCase(getGrossSalaryRangeListThunk.rejected, (state, action) => {
        state.grossSalaryRangeState.isLoading = false;
        state.grossSalaryRangeState.isError = true;
        state.grossSalaryRangeState.errorMessage = action.payload;
      });

    builder
      .addCase(getLoanReasonListThunk.pending, (state) => {
        state.loanReasonState.isLoading = true;
      })
      .addCase(getLoanReasonListThunk.fulfilled, (state, action) => {
        state.loanReasonState.isLoading = false;
        state.loanReasonState.data =
          action.payload.length > 1
            ? action.payload.sort(
                (a: LoanReason, b: LoanReason) => +a.code - +b.code,
              )
            : action.payload;
      })
      .addCase(getLoanReasonListThunk.rejected, (state, action) => {
        state.loanReasonState.isLoading = false;
        state.loanReasonState.isError = true;
        state.loanReasonState.errorMessage = action.payload;
      });

    builder
      .addCase(getEmployeeTypeListThunk.pending, (state) => {
        state.employeeTypeState.isLoading = true;
      })
      .addCase(getEmployeeTypeListThunk.fulfilled, (state, action) => {
        state.employeeTypeState.isLoading = false;
        state.employeeTypeState.data =
          action.payload.length > 1
            ? action.payload.sort(
                (a: EmployeeType, b: EmployeeType) => +a.code - +b.code,
              )
            : action.payload;
      })
      .addCase(getEmployeeTypeListThunk.rejected, (state, action) => {
        state.employeeTypeState.isLoading = false;
        state.employeeTypeState.isError = true;
        state.employeeTypeState.errorMessage = action.payload;
      });

    builder
      .addCase(getBankListThunk.pending, (state) => {
        state.bankState.isLoading = true;
      })
      .addCase(getBankListThunk.fulfilled, (state, action) => {
        state.bankState.isLoading = false;
        state.bankState.data =
          action.payload.length > 1
            ? action.payload.sort((a: Bank, b: Bank) => +a.code - +b.code)
            : action.payload;
      })
      .addCase(getBankListThunk.rejected, (state, action) => {
        state.bankState.isLoading = false;
        state.bankState.isError = true;
        state.bankState.errorMessage = action.payload;
      });

    builder
      .addCase(getRelationshipListThunk.pending, (state) => {
        state.relationshipState.isLoading = true;
      })
      .addCase(getRelationshipListThunk.fulfilled, (state, action) => {
        state.relationshipState.isLoading = false;
        state.relationshipState.data = action.payload;
      })
      .addCase(getRelationshipListThunk.rejected, (state, action) => {
        state.relationshipState.isLoading = false;
        state.relationshipState.isError = true;
        state.relationshipState.errorMessage = action.payload;
      });

    builder
      .addCase(getUnsuccessfulReasonTrunk.pending, (state) => {
        state.disbursementUnsuccessfulReasonState.isLoading = true;
      })
      .addCase(getUnsuccessfulReasonTrunk.fulfilled, (state, action) => {
        state.disbursementUnsuccessfulReasonState.isLoading = false;
        state.disbursementUnsuccessfulReasonState.data = action.payload;
      })
      .addCase(getUnsuccessfulReasonTrunk.rejected, (state, action) => {
        state.disbursementUnsuccessfulReasonState.isLoading = false;
        state.disbursementUnsuccessfulReasonState.isError = true;
        state.disbursementUnsuccessfulReasonState.errorMessage = action.payload;
      });
  },
});

// export const {} = dropdownParameterSlice.actions;

export default dropdownParameterSlice.reducer;
