import "../index.less";

import { Table, Typography } from "antd";
import startCase from "lodash.startcase";
import { useSelector } from "react-redux";

import { numberWithCommas } from "../../../helpers/number";
import { RootState } from "../../../store/store";
import {
  displayDate,
  displayDateAndTime,
  displayTime,
} from "../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";

const { Paragraph } = Typography;

const DunningHistory = () => {
  const { dunningHistoryListingState } = useSelector(
    (state: RootState) => state.dunningHistory,
  );

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      key: "index",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Action Code",
      dataIndex: "actionCode",
      key: "actionCode",
      render: (_, record) =>
        record?.actionCode != null
          ? `${record?.actionCode.code} -${record.actionCode.description}`
          : "-",
    },
    {
      title: "Contact Mode",
      dataIndex: "contactMode",
      key: "contactMode",
      // render: (text, record) => {
      //   return record?.contactMode.replace(/[_]/g, " ");
      // },
      render: (text: string) => startCase(text) ?? "-",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson",
      render: (text: string) => (text != null ? startCase(text) : "-"),
    },
    {
      title: "Contact No.",
      dataIndex: "contactNo",
      key: "contactNo",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      width: 240,
      render: (text: string) =>
        text ? (
          <Paragraph ellipsis={{ rows: 2, expandable: true }}>{text}</Paragraph>
        ) : (
          "-"
        ),
    },
    {
      title: "Detail",
      render: (_, record) => {
        return (
          <>
            {record.actionCode.code === "NS"
              ? `-`
              : record.actionCode.code === "CNA"
              ? `${displayDate(record?.nextCallDate)}; ${displayTime(
                  record?.nextCallTime,
                )} `
              : record.ptpDate
              ? `${displayDate(record?.ptpDate)}; RM${numberWithCommas(
                  record?.ptpAmount,
                )} `
              : record.nextCallDate
              ? `${displayDate(record?.nextCallDate)}; ${displayTime(
                  record?.nextCallTime,
                )} `
              : `-`}
          </>
        );
      },
    },
    {
      title: "User ID",
      dataIndex: "createdBy",
      key: "createdBy",
    },
  ];

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <Table
            columns={columns}
            dataSource={dunningHistoryListingState?.data}
          />
        </div>
      </div>
    </div>
  );
};

export default DunningHistory;
