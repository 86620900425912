import { createSlice } from "@reduxjs/toolkit";
import { UploadedFile } from "../application/applicationSlice";
import {
  createRestructureCaseThunk,
  getApprovedRestructureListingThunk,
  getOpenRestructureListingThunk,
  getPendingRestructureListingThunk,
  getReferbackRestructureListingThunk,
  getRestructureCaseThunk,
  getVoidRestructureListingThunk,
} from "../../services/restructureService/restructureThunk";

export type RestructureListing = {
  id: string;
  accountDetailsId: string;
  type: string;
  status: string;
  instalmentPeriod?: number;
  rescheduleDate?: string;
  rescheduleFee?: number;
  remark?: string;

  // values below will be retrieved from account details
  principalAmount?: number;
  rescheduleAmount?: number; //check
  applicantName?: string;
  agingType?: string;
  billingOutstanding?: number;
  netOustanding?: number;
  totalInterestAmount?: number;
  totalLoanOutstanding?: number;
  profitRate?: number;

  // restructure calculation at backend
  instalmentBalance?: number;
  lastInstalmentAmount?: number;
  monthlyInstalmentAmount?: number;
  estimatedFirstDueDate?: number;
  totalFinancePayableAmount?: number;

  createdAt: string;
  updatedAt: string;

  dataNo: string;
};

export type RestructureListingResponse = {
  total?: number;
  currentPage?: number;
  pageSize?: number;
  data: RestructureListing[];
};

export type RestructureListingState = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: any;
  data: RestructureListingResponse;
};

export type restructureState = {
  openRestructureListing: RestructureListingState;
  pendingRestructureListing: RestructureListingState;
  voidRestructureListing: RestructureListingState;
  approvedRestructureListing: RestructureListingState;
  referbackRestructureListing: RestructureListingState;

  restructureDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: RestructureListing;
  };
};

const initialState: restructureState = {
  openRestructureListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },
  pendingRestructureListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },
  voidRestructureListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },
  approvedRestructureListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },
  referbackRestructureListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },

  restructureDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
};

const restructureSlice = createSlice({
  name: "restructure",
  initialState,
  reducers: { restructureReset: () => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(createRestructureCaseThunk.pending, (state) => {
        state.restructureDetails.isLoading = true;
      })
      .addCase(createRestructureCaseThunk.fulfilled, (state, action) => {
        state.restructureDetails.isLoading = false;
        state.restructureDetails.data = action.payload.data;
      })
      .addCase(createRestructureCaseThunk.rejected, (state, action) => {
        state.restructureDetails.isLoading = false;
        state.restructureDetails.isError = true;
        state.restructureDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getRestructureCaseThunk.pending, (state) => {
        state.restructureDetails.isLoading = true;
      })
      .addCase(getRestructureCaseThunk.fulfilled, (state, action) => {
        state.restructureDetails.isLoading = false;
        state.restructureDetails.data = action.payload.data;
      })
      .addCase(getRestructureCaseThunk.rejected, (state, action) => {
        state.restructureDetails.isLoading = false;
        state.restructureDetails.isError = true;
        state.restructureDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getOpenRestructureListingThunk.pending, (state) => {
        state.openRestructureListing.isLoading = true;
      })
      .addCase(getOpenRestructureListingThunk.fulfilled, (state, action) => {
        state.openRestructureListing.isLoading = false;
        state.openRestructureListing.data = action.payload;
      })
      .addCase(getOpenRestructureListingThunk.rejected, (state, action) => {
        state.openRestructureListing.isLoading = false;
        state.openRestructureListing.isError = true;
        state.openRestructureListing.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingRestructureListingThunk.pending, (state) => {
        state.pendingRestructureListing.isLoading = true;
      })
      .addCase(getPendingRestructureListingThunk.fulfilled, (state, action) => {
        state.pendingRestructureListing.isLoading = false;
        state.pendingRestructureListing.data = action.payload;
      })
      .addCase(getPendingRestructureListingThunk.rejected, (state, action) => {
        state.pendingRestructureListing.isLoading = false;
        state.pendingRestructureListing.isError = true;
        state.pendingRestructureListing.errorMessage = action.payload;
      });

    builder
      .addCase(getVoidRestructureListingThunk.pending, (state) => {
        state.voidRestructureListing.isLoading = true;
      })
      .addCase(getVoidRestructureListingThunk.fulfilled, (state, action) => {
        state.voidRestructureListing.isLoading = false;
        state.voidRestructureListing.data = action.payload;
      })
      .addCase(getVoidRestructureListingThunk.rejected, (state, action) => {
        state.voidRestructureListing.isLoading = false;
        state.voidRestructureListing.isError = true;
        state.voidRestructureListing.errorMessage = action.payload;
      });

    builder
      .addCase(getApprovedRestructureListingThunk.pending, (state) => {
        state.approvedRestructureListing.isLoading = true;
      })
      .addCase(
        getApprovedRestructureListingThunk.fulfilled,
        (state, action) => {
          state.approvedRestructureListing.isLoading = false;
          state.approvedRestructureListing.data = action.payload;
        },
      )
      .addCase(getApprovedRestructureListingThunk.rejected, (state, action) => {
        state.approvedRestructureListing.isLoading = false;
        state.approvedRestructureListing.isError = true;
        state.approvedRestructureListing.errorMessage = action.payload;
      });

    builder
      .addCase(getReferbackRestructureListingThunk.pending, (state) => {
        state.referbackRestructureListing.isLoading = true;
      })
      .addCase(
        getReferbackRestructureListingThunk.fulfilled,
        (state, action) => {
          state.referbackRestructureListing.isLoading = false;
          state.referbackRestructureListing.data = action.payload;
        },
      )
      .addCase(
        getReferbackRestructureListingThunk.rejected,
        (state, action) => {
          state.referbackRestructureListing.isLoading = false;
          state.referbackRestructureListing.isError = true;
          state.referbackRestructureListing.errorMessage = action.payload;
        },
      );
  },
});

export const { restructureReset } = restructureSlice.actions;

export default restructureSlice.reducer;
