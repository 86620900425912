import {
  getAllWriteOffThunk,
  getApprovedWriteOffThunk,
  getInProcessWriteOffThunk,
  getOpenWriteOffThunk,
  getPendingWriteOffThunk,
  getReferbackWriteOffThunk,
  getVoidWriteOffThunk,
  getWriteOffByBatchNoThunk,
} from "../../services/writeOffService/writeOffThunk";

import { createSlice } from "@reduxjs/toolkit";

export type writeOffListing = {
  id: string;
  batchNumber: string;
  status: string;
  totalRecords: number;
  totalWriteOffAmount: number;
  totalFinanceAmount: number;
  totalLoanOutstanding: number;
  createdBy: string;
  createdDate?: string;
  approveFileId?: string;
  approvalListingFileId?: string;
};

export type writeOffListingResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: writeOffListing[];
};

export type writeOffState = {
  allWriteOffListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffListingResponse;
  };
  openWriteOffListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffListingResponse;
  };
  draftWriteOffListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffListingResponse;
  };
  processingWriteOffListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffListingResponse;
  };
  pendingWriteOffListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffListingResponse;
  };
  referbackWriteOffListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffListingResponse;
  };
  voidWriteOffListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffListingResponse;
  };
  approvedWriteOffListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffListingResponse;
  };
  writeOffBatch: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffListing;
  };
};

const initialState: writeOffState = {
  allWriteOffListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  openWriteOffListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  draftWriteOffListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  processingWriteOffListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  pendingWriteOffListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  referbackWriteOffListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  voidWriteOffListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  approvedWriteOffListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  writeOffBatch: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      id: "",
      batchNumber: "",
      status: "",
      totalRecords: 0,
      totalWriteOffAmount: 0,
      totalFinanceAmount: 0,
      totalLoanOutstanding: 0,
      createdBy: "",
    },
  },
};

export const writeOffSlice = createSlice({
  name: "writeOff",
  initialState,
  reducers: {
    writeOffStateReset: () => initialState,
    setWriteOffBatch: (state, action) => {
      state.writeOffBatch.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWriteOffByBatchNoThunk.pending, (state) => {
        state.writeOffBatch.isLoading = true;
      })
      .addCase(getWriteOffByBatchNoThunk.fulfilled, (state, action) => {
        state.writeOffBatch.isLoading = false;
        state.writeOffBatch.data = action.payload.data[0];
      })
      .addCase(getWriteOffByBatchNoThunk.rejected, (state, action) => {
        state.writeOffBatch.isLoading = false;
        state.writeOffBatch.isError = true;
        state.writeOffBatch.errorMessage = action.payload;
      });

    builder
      .addCase(getAllWriteOffThunk.pending, (state) => {
        state.allWriteOffListing.isLoading = true;
      })
      .addCase(getAllWriteOffThunk.fulfilled, (state, action) => {
        state.allWriteOffListing.isLoading = false;
        state.allWriteOffListing.data = action.payload;
      })
      .addCase(getAllWriteOffThunk.rejected, (state, action) => {
        state.allWriteOffListing.isLoading = false;
        state.allWriteOffListing.isError = true;
        state.allWriteOffListing.errorMessage = action.payload;
      });

    builder
      .addCase(getOpenWriteOffThunk.pending, (state) => {
        state.openWriteOffListing.isLoading = true;
      })
      .addCase(getOpenWriteOffThunk.fulfilled, (state, action) => {
        state.openWriteOffListing.isLoading = false;
        state.openWriteOffListing.data = action.payload;
      })
      .addCase(getOpenWriteOffThunk.rejected, (state, action) => {
        state.openWriteOffListing.isLoading = false;
        state.openWriteOffListing.isError = true;
        state.openWriteOffListing.errorMessage = action.payload;
      });

    builder
      .addCase(getInProcessWriteOffThunk.pending, (state) => {
        state.processingWriteOffListing.isLoading = true;
      })
      .addCase(getInProcessWriteOffThunk.fulfilled, (state, action) => {
        state.processingWriteOffListing.isLoading = false;
        state.processingWriteOffListing.data = action.payload;
      })
      .addCase(getInProcessWriteOffThunk.rejected, (state, action) => {
        state.processingWriteOffListing.isLoading = false;
        state.processingWriteOffListing.isError = true;
        state.processingWriteOffListing.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingWriteOffThunk.pending, (state) => {
        state.pendingWriteOffListing.isLoading = true;
      })
      .addCase(getPendingWriteOffThunk.fulfilled, (state, action) => {
        state.pendingWriteOffListing.isLoading = false;
        state.pendingWriteOffListing.data = action.payload;
      })
      .addCase(getPendingWriteOffThunk.rejected, (state, action) => {
        state.pendingWriteOffListing.isLoading = false;
        state.pendingWriteOffListing.isError = true;
        state.pendingWriteOffListing.errorMessage = action.payload;
      });

    builder
      .addCase(getReferbackWriteOffThunk.pending, (state) => {
        state.referbackWriteOffListing.isLoading = true;
      })
      .addCase(getReferbackWriteOffThunk.fulfilled, (state, action) => {
        state.referbackWriteOffListing.isLoading = false;
        state.referbackWriteOffListing.data = action.payload;
      })
      .addCase(getReferbackWriteOffThunk.rejected, (state, action) => {
        state.referbackWriteOffListing.isLoading = false;
        state.referbackWriteOffListing.isError = true;
        state.referbackWriteOffListing.errorMessage = action.payload;
      });

    builder
      .addCase(getVoidWriteOffThunk.pending, (state) => {
        state.voidWriteOffListing.isLoading = true;
      })
      .addCase(getVoidWriteOffThunk.fulfilled, (state, action) => {
        state.voidWriteOffListing.isLoading = false;
        state.voidWriteOffListing.data = action.payload;
      })
      .addCase(getVoidWriteOffThunk.rejected, (state, action) => {
        state.voidWriteOffListing.isLoading = false;
        state.voidWriteOffListing.isError = true;
        state.voidWriteOffListing.errorMessage = action.payload;
      });

    builder
      .addCase(getApprovedWriteOffThunk.pending, (state) => {
        state.approvedWriteOffListing.isLoading = true;
      })
      .addCase(getApprovedWriteOffThunk.fulfilled, (state, action) => {
        state.approvedWriteOffListing.isLoading = false;
        state.approvedWriteOffListing.data = action.payload;
      })
      .addCase(getApprovedWriteOffThunk.rejected, (state, action) => {
        state.approvedWriteOffListing.isLoading = false;
        state.approvedWriteOffListing.isError = true;
        state.approvedWriteOffListing.errorMessage = action.payload;
      });
  },
});

export const { writeOffStateReset, setWriteOffBatch } = writeOffSlice.actions;

export default writeOffSlice.reducer;
