import "./index.less";

import {
  Button,
  Divider,
  message,
  Popconfirm,
  Result,
  Skeleton,
  Space,
  Tag,
} from "antd";
import startCase from "lodash.startcase";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import InfoGrid from "../../../components/InfoGrid";
import LoadingParagraph from "../../../components/LoadingParagraphLong";
import UploadedDocument from "../../../components/UploadedDocument";
import { AgreementStatusEnum } from "../../../enum/agreementStepStatus";
import { FileTypeEnum } from "../../../enum/fileType";
import { agreementDetailsStateReset } from "../../../features/agreement/agreementSlice";
import {
  applicationDetailsStateReset,
  setApplicationDetails,
} from "../../../features/application/applicationSlice";
import { collectionStateReset } from "../../../features/collection/collectionSlice";
import { numberWithCommas } from "../../../helpers/number";
import {
  approveEStampingThunk,
  getAgreementDetailsThunk,
} from "../../../services/agreementService/agreementThunk";
import { getAccountDetailsThunk } from "../../../services/collectionService/collectionThunk";
import { getFilesByEntityIdThunk } from "../../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../../store/store";
import {
  onCalculateNetDisbursementAmount,
  onCalculateStampingDutyFee,
  onCalculateTotalInstalmentAmount,
  onCalculateTotalInterest,
} from "../../../utils/calculation.util";
import { displayDate } from "../../../utils/datetime.util";
import ConfirmCancelApplication from "../../../components/ConfirmCancelApplication";

const AgreementDetailsContent: React.FC<{
  title?: string;
  agreementId: string;
  modalView?: boolean;
  callback?: () => void;
}> = ({ title, agreementId, modalView, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const readOnly = queryParams.get("readOnly") === "true";

  const [approveLoading, setApproveLoading] = useState(false);

  const { agreementDetails, agreementFiles } = useSelector(
    (state: RootState) => state.agreement,
  );

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );

  const isPendingOrDisbursement =
    agreementDetails?.data?.agreementStatus ===
      AgreementStatusEnum.PENDING_DISBURSEMENT ||
    agreementDetails?.data?.agreementStatus === AgreementStatusEnum.DISBURSED;

  const isPendingEStampAgreement =
    isPendingOrDisbursement &&
    agreementDetails?.data?.clickedDownloadEStampingCert === false;

  const approvedEStampAgreement =
    isPendingOrDisbursement &&
    agreementDetails.data?.approveUploadedEStampingCert &&
    agreementDetails.data?.eStampingCertificateFileId != null;

  const uploadedEStamp =
    isPendingOrDisbursement &&
    agreementDetails.data?.eStampingCertificateFileId != null;

  const agreementInfo1 = [
    {
      title: "Agreement No",
      value: agreementDetails?.data?.agreementNo,
    },
    // if customer not yet sign, Agreement date & First instalment date & Maturity date just show blank
    {
      title: "Agreement Date",
      value: agreementDetails?.data?.signDate
        ? displayDate(new Date(agreementDetails?.data?.signDate))
        : "-",
    },
    {
      title: "First Instalment Date",
      value:
        agreementDetails?.data?.signDate &&
        agreementDetails?.data?.firstInstalmentDate
          ? displayDate(agreementDetails?.data?.firstInstalmentDate)
          : "-",
    },
    {
      title: "Maturity Date",
      value:
        agreementDetails?.data?.signDate &&
        agreementDetails?.data?.lastInstalmentDate
          ? displayDate(agreementDetails?.data?.lastInstalmentDate)
          : "-",
    },
    {
      title: "Finance Amount",
      value:
        applicationDetails?.data?.financeAmount != null
          ? `RM ${numberWithCommas(applicationDetails?.data?.financeAmount)}`
          : "-",
    },
    {
      title: "Interest Amount",
      value: `RM ${numberWithCommas(
        onCalculateTotalInterest(
          applicationDetails?.data?.financeAmount ?? 0,
          applicationDetails?.data?.loanInterestRate ?? 0,
          applicationDetails?.data?.loanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Interest Rate",
      value: `${numberWithCommas(agreementDetails?.data?.loanInterestRate)} %`,
    },
    {
      title: "Instalment Period",
      value: applicationDetails?.data?.loanTenure ?? "-",
    },
    {
      title: "Total Instalment Amount",
      value: `RM ${numberWithCommas(
        onCalculateTotalInstalmentAmount(
          applicationDetails?.data?.financeAmount ?? 0,
          applicationDetails?.data?.loanInterestRate ?? 0,
          applicationDetails?.data?.loanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Monthly Instalment Amount",
      value:
        agreementDetails?.data?.monthlyInstalmentAmount != null
          ? `RM ${numberWithCommas(
              agreementDetails?.data?.monthlyInstalmentAmount,
            )}`
          : "-",
    },
    {
      title: "Final Instalment Amount",
      value:
        agreementDetails?.data?.lastInstalmentAmount != null
          ? `RM ${numberWithCommas(
              agreementDetails?.data?.lastInstalmentAmount,
            )}`
          : "-",
    },
    {
      title: "Discount Amount",
      value:
        applicationDetails?.data?.productProcessingFeeToDiscount != null
          ? `RM ${numberWithCommas(
              applicationDetails?.data?.productProcessingFeeToDiscount,
            )}`
          : "-",
    },
  ];

  const agreementInfo2 = [
    {
      title: "Disbursement Date",
      value: agreementDetails?.data?.disbursementPostedDate
        ? displayDate(agreementDetails?.data?.disbursementPostedDate)
        : "-",
    },
    {
      title: "Disbursement Amount",
      value: `RM ${numberWithCommas(
        onCalculateNetDisbursementAmount(
          applicationDetails?.data?.financeAmount ?? 0,
          applicationDetails?.data?.productProcessingFee ?? 0,
          applicationDetails?.data?.productStampingDutyPercentage ?? 0,
          applicationDetails?.data?.productProcessingFeeToDiscount ?? 0,
          applicationDetails.data?.loanTotalPremium ?? 0,
          applicationDetails.data?.applicationStatus,
        ),
      )}`,
    },
    {
      title: "Processing Fee",
      value:
        agreementDetails.data?.application?.productProcessingFee != null
          ? `RM ${numberWithCommas(
              agreementDetails.data?.application?.productProcessingFee,
            )}`
          : "-",
    },
    {
      title: "Stamp Duty Fee",
      value: onCalculateStampingDutyFee(
        applicationDetails?.data?.financeAmount ?? 0,
        applicationDetails?.data?.productStampingDutyPercentage ?? 0,
      )
        ? `RM ${numberWithCommas(
            onCalculateStampingDutyFee(
              applicationDetails?.data?.financeAmount ?? 0,
              applicationDetails?.data?.productStampingDutyPercentage ?? 0,
            ),
          )}`
        : "-",
    },
    {
      title: "Loan Insurance Amount",
      value: applicationDetails.data?.loanTotalPremium
        ? `RM ${numberWithCommas(applicationDetails.data?.loanTotalPremium)}`
        : "-",
    },
  ];

  const disbursementData = [
    {
      title: "Applicant Name",
      value: applicationDetails?.data?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      value: applicationDetails?.data?.applicantNric ?? "-",
    },
    {
      title: "Bank Account No.",
      value: applicationDetails?.data?.applicantPersonalBankNo ?? "-",
    },
    {
      title: "Bank Name",
      value: applicationDetails?.data?.applicantBankName ?? "-",
    },
  ];

  const onBack = () => {
    navigate(-1);
  };

  const fetchFileList = () => {
    dispatch(getFilesByEntityIdThunk({ entityId: agreementDetails?.data?.id }));
  };

  const initProcess = async () => {
    await dispatch(getAgreementDetailsThunk({ agreementId }))
      .unwrap()
      .then((res) => {
        dispatch(setApplicationDetails(res.application));
        if (res.agreementStatus === AgreementStatusEnum.DISBURSED) {
          dispatch(collectionStateReset());
          dispatch(getAccountDetailsThunk({ agreementNo: res?.agreementNo }));
        }
      });
    await dispatch(getFilesByEntityIdThunk({ entityId: agreementId }));
  };

  const onApprove = async () => {
    setApproveLoading(true);
    await dispatch(approveEStampingThunk({ agreementId }))
      .unwrap()
      .then(() => {
        message.success("Approved");
        initProcess();
      })
      .finally(() => setApproveLoading(false));
  };

  const resetDetailsState = () => {
    dispatch(applicationDetailsStateReset());
    dispatch(agreementDetailsStateReset());
  };

  useLifecycles(
    () => agreementId && initProcess(),
    () => resetDetailsState(),
  );

  return (
    <div>
      <PageHeader
        className="p-0 mb-4"
        onBack={modalView ? undefined : onBack}
        title={
          <Space align="center">
            <h3 className="m-0">{title ?? "Agreement Details"}</h3>
            {!agreementDetails.isLoading &&
              !agreementDetails.isError &&
              agreementDetails.data?.agreementStatus && (
                <Tag>{startCase(agreementDetails.data?.agreementStatus)}</Tag>
              )}
          </Space>
        }
      />

      {agreementDetails.isLoading ? (
        <LoadingParagraph />
      ) : agreementDetails.data && !agreementDetails.isError ? (
        <>
          <div className="agreement-details-section-container">
            <h3 className="agreement-details-section-title">Loan Agreement</h3>
            <InfoGrid data={agreementInfo1} />
            <div className="p-4" />
            <InfoGrid data={agreementInfo2} />
          </div>
          <div className="agreement-details-section-container">
            <h3 className="agreement-details-section-title">
              Disbursement Info
            </h3>
            <InfoGrid data={disbursementData} />
          </div>
          <br />
          <div className="agreement-details-section-container">
            <h3 className="agreement-details-section-title">
              Signed Agreement
            </h3>
            {agreementFiles.isLoading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : (
              <UploadedDocument
                key={"1"}
                readOnly={readOnly}
                entityId={agreementDetails.data.id}
                fileType={FileTypeEnum.SIGNEDAGREEMENTPDF}
                defaultFileList={agreementFiles?.data?.signedagreementpdf}
                callback={fetchFileList}
              />
            )}
          </div>
          <br />
          {!isPendingEStampAgreement && (
            <div className="agreement-details-section-container">
              <h3 className="agreement-details-section-title">
                E-Stamping Certificate
              </h3>
              {agreementFiles.isLoading ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : (
                <UploadedDocument
                  key={"1"}
                  readOnly={readOnly}
                  entityId={agreementDetails.data.id}
                  fileType={FileTypeEnum.ESTAMPING}
                  defaultFileList={agreementFiles?.data?.estamping}
                  callback={fetchFileList}
                />
              )}
              {!readOnly && uploadedEStamp && (
                <>
                  <div className="flex justify-end">
                    <Popconfirm
                      title="Approve this e-stamp?"
                      onConfirm={() => onApprove()}
                      okText="Yes"
                      cancelText="No"
                      disabled={approvedEStampAgreement}
                    >
                      <Button
                        loading={approveLoading}
                        type="primary"
                        disabled={approvedEStampAgreement}
                      >
                        {approvedEStampAgreement ? "Approved" : "Approve"}
                      </Button>
                    </Popconfirm>
                  </div>
                  <Divider />
                </>
              )}
            </div>
          )}
          {(agreementDetails?.data?.agreementStatus ===
            AgreementStatusEnum.PENDING_FOR_ATTESTATION ||
            agreementDetails?.data?.agreementStatus ===
              AgreementStatusEnum.PENDING_SIGNING ||
            agreementDetails?.data?.agreementStatus ===
              AgreementStatusEnum.REQUESTED_FOR_LIVE_ATTESTATION ||
            agreementDetails?.data?.agreementStatus ===
              AgreementStatusEnum.DIRECT_DEBIT_ENROLLMENT) &&
            process.env.REACT_APP_AGREEMENT_CANCELED_ENABLED !== "NO" && (
              <ConfirmCancelApplication
                key={agreementDetails?.data?.application?.id}
                callback={initProcess}
              />
            )}
        </>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the agreement does not exist."
        />
      )}
    </div>
  );
};

export default AgreementDetailsContent;
