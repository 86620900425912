export enum WriteOffStatusEnum {
  NEW = "NEW",
  DRAFT = "DRAFT",
  PROCESSING = "PROCESSING",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  VOID = "VOID",
  REFERBACK = "REFERBACK",
}

export enum WriteOffConfigTypeEnum {
  DPD = "DPD",
  BATCH_BOUNDARY = "BATCH_BOUNDARY",
  DUNNING_ACTION_CODE = "DUNNING_ACTION_CODE",
}
