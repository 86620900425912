import { SmeAgreement, SmeAgreementListing } from "../smeAgreement/smeAgreementSlice";
import { createSlice } from "@reduxjs/toolkit";
import {
  getSmeDisbursementBatchAgreementsThunk,
  getSmeDisbursementBatchByIdThunk,
  getSmeDisbursementBatchListingThunk,
  getSmePendingDisbursementAgreementsListingThunk,
} from "../../services/smeDisbursementService/smeDisbursementThunk";

export type SmeDisbursementBatch = {
  id: string
  batchNo: string
  completedDate: string
  status: string
  totalApprovedLoanAmount: number
  totalDisbursementAmount: number
  smeAgreement: SmeAgreement[]
}

export type SmeDisbursementBatchList = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: SmeDisbursementBatch[];
};

export type SmeDisbursementState = {
  smeDisbursementBatchDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeDisbursementBatch;
  };
  smeDisbursementBatchListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: SmeDisbursementBatchList;
  };
  pendingDisbursementSmeAgreements: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: SmeAgreementListing;
  };
  smeDisbursementBatchAgreements: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeAgreementListing;
  };
};

const initialState: SmeDisbursementState = {
  smeDisbursementBatchDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {} as SmeDisbursementBatch,
  },
  smeDisbursementBatchListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  pendingDisbursementSmeAgreements: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  smeDisbursementBatchAgreements: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
};

export const smeDisbursementSlice = createSlice({
  name: "smeDisbursement",
  initialState,
  reducers: {
    smeDisbursementStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmeDisbursementBatchByIdThunk.pending, (state) => {
        state.smeDisbursementBatchDetails.isLoading = true;
      })
      .addCase(
        getSmeDisbursementBatchByIdThunk.fulfilled,
        (state, action) => {
          state.smeDisbursementBatchDetails.isLoading = false;
          state.smeDisbursementBatchDetails.data = action.payload;
        },
      )
      .addCase(
        getSmeDisbursementBatchByIdThunk.rejected,
        (state, action) => {
          state.smeDisbursementBatchDetails.isLoading = false;
          state.smeDisbursementBatchDetails.isError = true;
          state.smeDisbursementBatchDetails.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getSmeDisbursementBatchListingThunk.pending, (state) => {
        state.smeDisbursementBatchListing.isLoading = true;
      })
      .addCase(getSmeDisbursementBatchListingThunk.fulfilled, (state, action) => {
        state.smeDisbursementBatchListing.isLoading = false;
        state.smeDisbursementBatchListing.data = action.payload;
      })
      .addCase(getSmeDisbursementBatchListingThunk.rejected, (state, action) => {
        state.smeDisbursementBatchListing.isLoading = false;
        state.smeDisbursementBatchListing.isError = true;
        state.smeDisbursementBatchListing.errorMessage = action.payload;
      });

    builder
      .addCase(getSmePendingDisbursementAgreementsListingThunk.pending, (state) => {
        state.pendingDisbursementSmeAgreements.isLoading = true;
      })
      .addCase(
        getSmePendingDisbursementAgreementsListingThunk.fulfilled,
        (state, action) => {
          state.pendingDisbursementSmeAgreements.isLoading = false;
          state.pendingDisbursementSmeAgreements.data = action.payload;
        },
      )
      .addCase(
        getSmePendingDisbursementAgreementsListingThunk.rejected,
        (state, action) => {
          state.pendingDisbursementSmeAgreements.isLoading = false;
          state.pendingDisbursementSmeAgreements.isError = true;
          state.pendingDisbursementSmeAgreements.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getSmeDisbursementBatchAgreementsThunk.pending, (state) => {
        state.smeDisbursementBatchAgreements.isLoading = true;
      })
      .addCase(
        getSmeDisbursementBatchAgreementsThunk.fulfilled,
        (state, action) => {
          state.smeDisbursementBatchAgreements.isLoading = false;
          state.smeDisbursementBatchAgreements.data = action.payload;
        },
      )
      .addCase(
        getSmeDisbursementBatchAgreementsThunk.rejected,
        (state, action) => {
          state.smeDisbursementBatchAgreements.isLoading = false;
          state.smeDisbursementBatchAgreements.isError = true;
          state.smeDisbursementBatchAgreements.errorMessage = action.payload;
        },
      );
  },
});

export const { smeDisbursementStateReset } = smeDisbursementSlice.actions;

export default smeDisbursementSlice.reducer;
