export const phoneNumberValidator = (value: string) => {
  if (value) {
    if (value.indexOf("-") > -1) {
      return Promise.reject(new Error("Do not enter dashes"));
    }
    if (value.indexOf("6") === 0) {
      return Promise.reject(new Error("Do not include country code"));
    }
    if (value.indexOf("1") !== 0) {
      return Promise.reject(new Error("Should start from '1'"));
    }
  }
  return Promise.resolve();
};

export const ageValidator = (value: number) => {
  if (value < 18) {
    return Promise.reject(new Error("Applicant must be at least 18 years old"));
  }
  if (value > 60) {
    return Promise.reject(new Error("Applicant cannot be over 60 years old."));
  }
  return Promise.resolve();
};

export const nricFirst6NumRegexPattern =
  /(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))/;

export const nricRegexPattern =
  /(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))-([0-9]{2})-([0-9]{4})/;

export const phoneRegexPattern = /^(\+[0-9]{1,3})?[0-9]{8,14}$/;

export const numberRegexPattern = /^\d+$/;

export const phoneRegexPatternFieldVisit = /^\+?[1-9][0-9]{7,14}$/;

export const emailRegexPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const formatMalaysianPhoneNumber = (phoneNumber: string) => {
  // Remove any non-numeric characters from the input
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Check if the phone number is of valid length (10 digits)

  // Format the number as per the standard format
  return numericPhoneNumber.slice(1);
};

export const emailValidator = (email: string) => {
  if (!emailRegexPattern.test(email)) {
    return Promise.reject(new Error("Invalid Email"));
  }
  return Promise.resolve();
};
