export enum ApplicationStepStatusEnum {
  APPLICATIONFORMCOMPLETED = "APPLICATIONFORMCOMPLETED",
  DOCUMENTUPLOADCOMPLETED = "DOCUMENTUPLOADCOMPLETED",
  EKYCCOMPLETED = "EKYCCOMPLETED",
}

export enum ApplicationStatusEnum {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  SUBMITTED_FOR_APPROVAL = "SUBMITTED_FOR_APPROVAL",
  SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT = "SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT",
  REFERBACK = "REFERBACK",
  REFERBACK_FROM_PENDING_DISBURSEMENT = "REFERBACK_FROM_PENDING_DISBURSEMENT",
  FOLLOWED_UP = "FOLLOWED_UP",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
}

export enum ApplicationBucketEnum {
  PENDING = "PENDING",
  SYSTEM_REJECTED = "SYSTEM_REJECTED",
  CREDIT_APPROVED = "CREDIT_APPROVED",
}

export enum CollectionActivityStatusEnum {
  MIA1 = "MIA1",
  MIA2 = "MIA2",
  MIA3 = "MIA3",
  NPL = "NPL",
  BP = "BP",
  PTP = "PTP",
}
