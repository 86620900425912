import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import ekycService from "./ekycService";

export const getWiseAiResultByUserIdThunk = createAsyncThunk(
  "ekyc/getWiseAiResultByUserId",
  async (requestData: any, thunkAPI) => {
    try {
      return await ekycService.getWiseAiResultByUserId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWiseAiRawDataBySessionIdThunk = createAsyncThunk(
  "ekyc/getWiseAiRawDataBySessionId",
  async (requestData: any, thunkAPI) => {
    try {
      return await ekycService.getWiseAiRawDataBySessionId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWiseAiResultListingThunk = createAsyncThunk(
  "ekyc/getEkycResultListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await ekycService.getWiseAiResultListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const uploadeKYCFileThunk = createAsyncThunk(
  "ekyc/uploadeKYCFile",
  async (requestData: any, thunkAPI) => {
    try {
      return await ekycService.uploadeKYCFile(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWiseAiResultByApplicationIdThunk = createAsyncThunk(
  "ekyc/getWiseAiResultByApplicationId",
  async (requestData: any, thunkAPI) => {
    try {
      return await ekycService.getWiseAiResultByApplicationId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeEkycFileEntityIdThunk = createAsyncThunk(
  "ekyc/getSmeEkycFileEntityId",
  async (username: string, thunkAPI) => {
    try {
      return await ekycService.getSmeEkycFileEntityId(username);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
