import { createAsyncThunk } from "@reduxjs/toolkit";

import preRecordedVideoService from "./preRecordedVideoService";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import fileService from "../fileService/fileService";

export const getPreRecordedVideoENThunk = createAsyncThunk(
  "preRecordedVideo/getEN",
  async (requestData: any, thunkAPI) => {
    try {
      return await preRecordedVideoService.getPreRecordedVideoEN(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPreRecordedVideoBMThunk = createAsyncThunk(
  "preRecordedVideo/getBM",
  async (requestData: any, thunkAPI) => {
    try {
      return await preRecordedVideoService.getPreRecordedVideoBM(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPreRecordedVideosBmThunk = createAsyncThunk(
  "preRecordedVideoBm/getBm",
  async (requestData: any, thunkAPI) => {
    try {
      return await preRecordedVideoService.getPreRecordedVideosBm(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createPreRecordedVideoThunk = createAsyncThunk(
  "preRecordedVideo/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await preRecordedVideoService.createPreRecordedVideo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updatePreRecordedVideoThunk = createAsyncThunk(
  "preRecordedVideo/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await preRecordedVideoService.updatePreRecordedVideo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deletePreRecordedVideoThunk = createAsyncThunk(
  "preRecordedVideo/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await preRecordedVideoService.deletePreRecordedVideo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPreRecordedVideoFilesThunk = createAsyncThunk(
  "preRecordedVideo/getPreRecordedVideoFiles",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getMasterSetupFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
