import "./style.less";

import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Result,
  Select,
  Skeleton,
  Space,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  CheckCircleOutlined,
  CloseSquareOutlined,
  PrinterOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import InfoList from "../../../../components/InfoList";
import UploadedDocument from "../../../../components/UploadedDocument";
import {
  fileDeleteByTypeThunk,
  getFileByEntityIdByTypeThunk,
} from "../../../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";
import { numberWithCommas } from "../../../../helpers/number";

import dayjs from "dayjs";
import { FileTypeEnum } from "../../../../enum/fileType";
import UploadedDocumentByType from "../../../../components/UploadedDocumentByType";
import RestructureDateModal from "./restructure-date-modal";
import { RestructureTypeEnum } from "../../../../enum/restructure";
import {
  getRestructureCaseThunk,
  getRestructureQuotationThunk,
  updateRestructureCaseStatusThunk,
  updateRestructureCaseThunk,
} from "../../../../services/restructureService/restructureThunk";
import { RescheduleTypeEnum, RnRType } from "../../../../enum/reschedule";
import productService from "../../../../services/productService/productService";
import { saveBlobAsPDF } from "../../../../utils/file";

const { TextArea } = Input;

const RestructureDetailsContent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const { restructureId } = useParams();

  const queryParams = new URLSearchParams(location.search);
  const readOnly = queryParams.get("readOnly") === "true";

  const { restructureDetails } = useSelector(
    (state: RootState) => state.restructure,
  );
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const accountDetails = accountEnquiry?.data?.accountDetails;
  const viewMode =
    restructureDetails?.data?.status !== RestructureTypeEnum.OPEN &&
    restructureDetails?.data?.status !== RestructureTypeEnum.REFERBACK;

  const [rescheduleDateVisible, setRestructureDateVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [tenureRange, setTenureRage] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voidRemark, setVoidRemark] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [remark, setRemark] = useState("");

  const updateRestructureDetails = (rescheduleDate?: any) => {
    // triggered when new instalment period or new profit rate is inserted or reschedule date is edited
    // to run the calculation
    const instalmentPeriod = form.getFieldValue("instalmentPeriod");
    const profitRate = form.getFieldValue("profitRate");
    if (!instalmentPeriod || !profitRate) return;

    const payload: any = {
      id: restructureId,
      newInstalmentPeriod: instalmentPeriod,
      type: "Restructure",
      newInterestRate: Number(profitRate),
      rescheduleDate: rescheduleDate
        ? dayjs(rescheduleDate).startOf("day")
        : restructureDetails?.data?.rescheduleDate,
      rescheduleFee: Number(form.getFieldValue("rescheduleFee")),
      remark: form.getFieldValue("remark"),
    };
    dispatch(updateRestructureCaseThunk(payload))
      .unwrap()
      .then(async () => {
        dispatch(getRestructureCaseThunk({ id: restructureId }));
      });
  };

  const saveRestructureDetails = () => {
    form.validateFields().then((values) => {
      const payload: any = {
        id: restructureId,
        newInstalmentPeriod: Number(values.instalmentPeriod),
        type: "Restructure",
        newInterestRate: values.profitRate,
        rescheduleDate: restructureDetails?.data?.rescheduleDate,
        rescheduleFee: Number(values.rescheduleFee),
        remark: values.remark,
      };

      dispatch(updateRestructureCaseThunk(payload))
        .unwrap()
        .then(async () => {
          message.success("Restructure details saved successfully");
          dispatch(getRestructureCaseThunk({ id: restructureId }))
            .unwrap()
            .then((res) => {});
        });
    });
  };

  const onUpdateStatus = (status: RestructureTypeEnum) => {
    if (remark.length == 0) {
      message.error("Please provide remark.");
      return;
    } else if (status === RestructureTypeEnum.PENDING && fileList.length == 0) {
      message.error("Please upload the required documents");
      return;
    } else {
      dispatch(
        updateRestructureCaseStatusThunk({
          id: restructureId,
          rescheduleStatus: status,
          type: "Restructure",
          remark,
        }),
      )
        .unwrap()
        .then(() => {
          message.success(
            `Restructure status updated to ${status} successfully`,
          );
          navigate(`/restructure/listing?tab=${status}`);
        });
    }
  };

  const restructureDetailsInfo = [
    {
      title: "Restructure Date",
      value: (
        <Space>
          {restructureDetails?.data?.rescheduleDate != null
            ? `${displayDate(restructureDetails?.data?.rescheduleDate)}`
            : "-"}

          <Button
            onClick={() => setRestructureDateVisible(true)}
            hidden={viewMode}
            disabled={
              restructureDetails?.data?.status !== RestructureTypeEnum.REFERBACK
            }
          >
            Update
          </Button>
          {rescheduleDateVisible && (
            <RestructureDateModal
              isModalVisible={rescheduleDateVisible}
              setIsModalVisible={setRestructureDateVisible}
              callback={(rescheduleDate: any) => {
                // setRestructureDate(rescheduleDate);
                updateRestructureDetails(rescheduleDate);
                setRestructureDateVisible(false);
              }}
            />
          )}
        </Space>
      ),
    },
    {
      title: "Restructure (Finance) Amount",
      value:
        accountDetails?.totalLoanOutstanding != null
          ? `RM ${numberWithCommas(accountDetails?.totalLoanOutstanding)}`
          : "-",
    },
    {
      title: "Instalment Period",
      value: viewMode ? (
        restructureDetails?.data?.instalmentPeriod != null ? (
          `${restructureDetails?.data?.instalmentPeriod}`
        ) : (
          "-"
        )
      ) : (
        <Form.Item
          name="instalmentPeriod"
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: true,
              message: "Please input instalment period",
            },
          ]}
        >
          <Select
            style={{ width: 150 }}
            placeholder="Select"
            disabled={viewMode}
            onChange={() => {
              updateRestructureDetails();
            }}
          >
            {tenureRange.map((item: any) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Interest/Profit Rate",
      value: viewMode ? (
        restructureDetails?.data?.profitRate != null ? (
          `${restructureDetails?.data?.profitRate} %`
        ) : (
          "-"
        )
      ) : (
        <Form.Item
          name="profitRate"
          rules={[
            {
              required: true,
              message: "Please input interest rate",
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <InputNumber
            min={1}
            max={100}
            precision={2}
            controls={false}
            onBlur={() => updateRestructureDetails()}
            style={{ width: 150 }}
          />
        </Form.Item>
      ),
    },

    {
      title: "Total Finance Payable Amount",
      value:
        restructureDetails?.data?.totalFinancePayableAmount != null
          ? `RM ${numberWithCommas(
              restructureDetails?.data?.totalFinancePayableAmount,
            )}`
          : "-",
    },
    {
      title: "Monthly Instalment Amount",
      value:
        restructureDetails?.data?.monthlyInstalmentAmount != null
          ? `RM ${numberWithCommas(
              restructureDetails?.data?.monthlyInstalmentAmount,
            )}`
          : "-",
    },
    {
      title: "Last Instalment Amount",
      value:
        restructureDetails?.data?.lastInstalmentAmount != null
          ? `RM ${numberWithCommas(
              restructureDetails?.data?.lastInstalmentAmount,
            )}`
          : "-",
    },
    {
      title: "Misc Charges (Restructure Fees)",
      value: viewMode ? (
        restructureDetails?.data?.rescheduleFee != null ? (
          `RM ${numberWithCommas(restructureDetails?.data?.rescheduleFee)}`
        ) : (
          "-"
        )
      ) : (
        <Form.Item
          name="rescheduleFee"
          rules={[
            {
              required: true,
              message: "Please input reschedule fee",
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <InputNumber
            precision={2}
            defaultValue={0}
            controls={false}
            style={{ width: 150 }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Estimated 1st Due Date",
      value: restructureDetails?.data?.estimatedFirstDueDate
        ? displayDate(restructureDetails?.data?.estimatedFirstDueDate)
        : "-",
    },
  ];

  const fetchFileList = async () => {
    setFileLoading(true);
    await dispatch(
      getFileByEntityIdByTypeThunk({
        type: "rescheduleAndRestructure",
        entityId: restructureId,
      }),
    )
      .unwrap()
      .then((res) => {
        setFileList(res);
      })
      .finally(() => {
        setFileLoading(false);
      });
  };

  useEffect(() => {
    fetchFileList();
    setRemark(restructureDetails?.data?.remark ?? "");
  }, [restructureId]);

  useEffect(() => {
    productService
      .getProductById(
        accountEnquiry.data?.agreementDetails?.application?.productId ?? "",
      )
      .then((res) => {
        const minTenure = res.minTenure;
        const maxTenure = res.maxTenure;

        // Create an array start from min tenure and end with max tenure
        const tenureArray = Array.from(
          { length: maxTenure - minTenure + 1 },
          (_, i) => minTenure + i,
        );

        setTenureRage(tenureArray);
      });
  }, [accountEnquiry]);

  const onVoid = async () => {
    if (voidRemark.length == 0) {
      message.error("Please provide remark.");
      return;
    }

    dispatch(
      updateRestructureCaseStatusThunk({
        id: restructureId,
        rescheduleStatus: RestructureTypeEnum.VOID,
        type: "Restructure",
        remark: voidRemark,
      }),
    )
      .unwrap()
      .then(() => {
        message.success(
          `Reschedule status updated to ${RescheduleTypeEnum.VOID} successfully`,
        );
        navigate(`/restructure/listing?tab=${RescheduleTypeEnum.VOID}`);
      });
  };

  const printRescheduleQuotation = () => {
    setDownloading(true);
    dispatch(
      getRestructureQuotationThunk({
        rescheduleId: restructureId,
      }),
    )
      .unwrap()
      .then((response) => {
        const filename = `Restructure_Quotation_${restructureDetails.data?.dataNo}.pdf`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => setDownloading(false));
  };

  return (
    <>
      {restructureId ? (
        <Form
          form={form}
          initialValues={{
            rescheduleDate: restructureDetails?.data?.rescheduleDate,
            rescheduleFee: restructureDetails?.data?.rescheduleFee,
            instalmentPeriod: restructureDetails?.data?.instalmentPeriod,
            profitRate: restructureDetails?.data?.profitRate,
            remark: restructureDetails?.data?.remark,
          }}
        >
          <div className="reschedule-details-section-container">
            <InfoList data={restructureDetailsInfo} />
          </div>
          <div className="reschedule-details-section-container">
            <h3 className="reschedule-details-section-title">
              Customer Documents
            </h3>
            {fileLoading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : (
              <UploadedDocumentByType
                key={`uploaded-document-reschedule-${restructureDetails?.data?.id}`}
                type="rescheduleAndRestructure"
                entityId={restructureDetails?.data?.id}
                fileType={FileTypeEnum.RESCHEDULE_AND_RESTRUCTURE}
                defaultFileList={fileList}
                handleDeleteFile={async (payload: any) =>
                  await dispatch(
                    fileDeleteByTypeThunk({
                      ...payload,
                      type: "rescheduleAndRestructure",
                    }),
                  )
                    .unwrap()
                    .then(() => {
                      message.success("File deleted successfully");
                    })
                }
                callback={fetchFileList}
                disabled={viewMode}
              />
            )}
          </div>
          <div className="reschedule-details-section-container">
            <h3 className="reschedule-details-section-title">Remarks</h3>
            <Form.Item name="remark" style={{ marginBottom: 0 }}>
              <TextArea
                rows={4}
                placeholder="Remarks"
                disabled={viewMode}
                onChange={(e) => {
                  setRemark(e.target.value);
                }}
              />
            </Form.Item>
          </div>
          <div className="flex gap-2 justify-end mt-2">
            {!(
              restructureDetails?.data?.status === RestructureTypeEnum.VOID ||
              restructureDetails?.data?.status === RestructureTypeEnum.APPROVED
            ) && (
              <>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  icon={<CloseSquareOutlined />}
                >
                  Void
                </Button>
              </>
            )}
            {restructureDetails?.data?.status === RestructureTypeEnum.OPEN && (
              <>
                <Button
                  icon={<SaveOutlined />}
                  onClick={() => saveRestructureDetails()}
                >
                  Save
                </Button>
                <Button
                  type="primary"
                  onClick={() => onUpdateStatus(RestructureTypeEnum.PENDING)}
                  icon={<CheckCircleOutlined />}
                >
                  Submit
                </Button>
              </>
            )}

            {restructureDetails?.data?.status ===
              RestructureTypeEnum.REFERBACK && (
              <>
                <Button
                  type="primary"
                  onClick={() => onUpdateStatus(RestructureTypeEnum.PENDING)}
                  icon={<CheckCircleOutlined />}
                >
                  Submit
                </Button>
              </>
            )}
            {restructureDetails?.data?.status ===
              RestructureTypeEnum.PENDING && (
              <Button
                type="primary"
                onClick={() => onUpdateStatus(RestructureTypeEnum.REFERBACK)}
                icon={<CheckCircleOutlined />}
              >
                Referback
              </Button>
            )}
            {restructureDetails?.data?.status ===
              RestructureTypeEnum.PENDING && (
              <Button
                type="primary"
                onClick={() => onUpdateStatus(RestructureTypeEnum.APPROVED)}
                icon={<CheckCircleOutlined />}
              >
                Approve
              </Button>
            )}

            <Button
              type="primary"
              onClick={() => printRescheduleQuotation()}
              icon={<CheckCircleOutlined />}
              loading={downloading}
            >
              Print Quotation
            </Button>
          </div>
        </Form>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, reschedule not found."
        />
      )}

      <Modal
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setVoidRemark("");
        }}
        footer={null}
        width={800}
        onOk={onVoid}
        destroyOnClose
      >
        <Form>
          <br />
          <Form.Item label="Remark">
            <Input onChange={(e) => setVoidRemark(e.target.value)} />
          </Form.Item>
        </Form>

        <Button type="primary" onClick={onVoid}>
          Void
        </Button>
      </Modal>
    </>
  );
};

export default RestructureDetailsContent;
