import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import smeApplicationService from "./smeApplicationService";

export const getSmeApplicationCountThunk = createAsyncThunk(
  "smeApplication/getSmeApplicationCount",
  async (_, thunkAPI) => {
    try {
      return await smeApplicationService.getSmeApplicationCount();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingSmeApplicationsListingThunk = createAsyncThunk(
  "smeApplication/getPendingSmeApplicationsListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getPendingSmeApplicationsListing(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferBackSmeApplicationsListingThunk = createAsyncThunk(
  "smeApplication/getReferBackSmeApplicationsListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getReferBackSmeApplicationsListing(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeApplicationsListingThunk = createAsyncThunk(
  "smeApplication/getSmeApplicationsListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getSmeApplicationsListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeApplicationDetailsThunk = createAsyncThunk(
  "smeApplication/getSmeApplicationDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getSmeApplicationDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeApplicationRemarksThunk = createAsyncThunk(
  "smeApplication/getSmeApplicationRemarks",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getSmeApplicationRemarks(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSmeApplicationStatusThunk = createAsyncThunk(
  "smeApplication/updateSmeApplicationStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateSmeApplicationStatus(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const approveSmeApplicationThunk = createAsyncThunk(
  "smeApplication/approveSmeApplication",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.approveSmeApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const cancelSmeApplicationThunk = createAsyncThunk(
  "smeApplication/cancelSmeApplication",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.cancelSmeApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSmeApplicationFinanceAmountThunk = createAsyncThunk(
  "smeApplication/updateFinanceAmount",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateSmeApplicationFinanceAmount(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSmeApplicationLoanTenureThunk = createAsyncThunk(
  "smeApplication/updateLoanTenure",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateSmeApplicationLoanTenure(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getHistoryLogsBySmeApplicationIdThunk = createAsyncThunk(
  "smeApplication/getHistoryLogs",
  async (id: string, thunkAPI) => {
    try {
      return await smeApplicationService.getHistoryLogsBySmeApplicationId(id);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmePartnershipApplicationInfoByIdThunk = createAsyncThunk(
  "smePartnership/patchSmePartnershipApplicationInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmePartnershipApplicationInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmeIndividualApplicationInfoByIdThunk = createAsyncThunk(
  "smePartnership/patchSmeIndividualApplicationInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmeIndividualApplicationInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmeSolePropsApplicationInfoByIdThunk = createAsyncThunk(
  "smePartnership/patchSmeSolePropsApplicationInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmeSolePropsApplicationInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmePartnershipCompanyInfoByIdThunk = createAsyncThunk(
  "smePartnership/patchSmePartnershipCompanyInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmePartnershipCompanyInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmeSolePropsCompanyInfoByIdThunk = createAsyncThunk(
  "smePartnership/patchSmeSolePropsCompanyInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmeSolePropsCompanyInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmePartnershipDirectorByIdThunk = createAsyncThunk(
  "smePartnership/patchSmePartnershipDirectorById",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmePartnershipDirectorById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmeCompanyDirectorByIdThunk = createAsyncThunk(
  "smePartnership/patchSmeCompanyDirectorById",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmeCompanyDirectorById(payload);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmePartnershipDirectorThunk = createAsyncThunk(
  "smePartnership/createSmePartnershipDirector",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.createSmePartnershipDirector(payload);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSmePartnershipDisbursementInfoByIdThunk = createAsyncThunk(
  "smePartnership/updateSmePartnershipDisbursementInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateSmePartnershipDisbursementInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSmeSolePropsDisbursementInfoByIdThunk = createAsyncThunk(
  "smePartnership/updateSmeSolePropsDisbursementInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateSmeSolePropsDisbursementInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSmeIndividualDisbursementInfoByIdThunk = createAsyncThunk(
  "smePartnership/updateSmeIndividualDisbursementInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateSmeIndividualDisbursementInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSmeCompanyDisbursementInfoByIdThunk = createAsyncThunk(
  "smePartnership/updateSmeCompanyDisbursementInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateSmeCompanyDisbursementInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmeCompanyCompanyInfoByIdThunk = createAsyncThunk(
  "smePartnership/patchSmeCompanyCompanyInfo",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmeCompanyCompanyInfoById(
        payload,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmePartnerCommissionResultsThunk = createAsyncThunk(
  "smeApplication/getSmePartnerCommissionResults",
  async (id: string, thunkAPI) => {
    try {
      return await smeApplicationService.getSmePartnerCommissionResults(id);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchCreditRecordBySmeApplicationIdThunk = createAsyncThunk(
  "smeApplication/updateSmeApplicationCreditRecord",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchCreditRecordBySmeApplicationId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmeCompanyDirectorThunk = createAsyncThunk(
  "smeCompany/createSmeCompanyDirector",
  async (payload: any, thunkAPI) => {
    try {
      return await smeApplicationService.createSmeCompanyDirector(payload);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteSmeCompanyDirectorByIdThunk = createAsyncThunk(
  "smeCompany/deleteSmeCompanyDirectorById",
  async (id: string, thunkAPI) => {
    try {
      return await smeApplicationService.deleteSmeCompanyDirectorById(id);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteSmePartnershipDirectorByIdThunk = createAsyncThunk(
  "smePartnership/deleteSmePartnershipDirectorById",
  async (id: string, thunkAPI) => {
    try {
      return await smeApplicationService.deleteSmePartnershipDirectorById(id);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSmeApplicationInterestRateThunk = createAsyncThunk(
  "smeApplication/updateInterestRate",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateSmeApplicationInterestRate(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmeCreditRecordStatusBySmeApplicationIdAndTypeThunk =
  createAsyncThunk(
    "smeApplication/patchSmeCreditRecordStatusBySmeApplicationIdAndType",
    async (requestData: any, thunkAPI) => {
      try {
        return await smeApplicationService.patchSmeCreditRecordStatusBySmeApplicationIdAndType(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const regenerateSmeApplicationFormThunk = createAsyncThunk(
  "smeApplication/regenerateSmeApplicationForm",
  async (id: string, thunkAPI) => {
    try {
      return await smeApplicationService.regenerateSmeApplicationForm(id);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const smeGlobalSearchThunk = createAsyncThunk(
  "smeApplication/getSmeApplicationForm",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.smeApplicationGlobalSearch(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
