import groupBy from "lodash.groupby";

import { createSlice } from "@reduxjs/toolkit";

import { ApplicationStepStatusEnum } from "../../enum/applicationStepStatus";
import {
  getApplicantFilesThunk,
  getApplicationCreditRecordThunk,
  getApplicationDetailsThunk,
  getApplicationsThunk,
  getApprovedApplicationsThunk,
  getCancelledApplicationsThunk,
  getDraftApplicationsThunk,
  getFollowUpApplicationsThunk,
  getHistoryLogsByApplicationIDAndAgreementIDThunk,
  getHistoryLogsByApplicationIDThunk,
  getInternalRemarksByApplicationIDThunk,
  getPendingApplicationsThunk,
  getReferbackApplicationsThunk,
  getReferbackFromPendingDisbursementApplicationsThunk,
  getRejectedApplicationsThunk,
  getSubmitForApprovalApplicationsThunk,
  globalSearchThunk,
} from "../../services/applicationService/applicationThunk";
import {
  getEkycFilesByEntityIdThunk,
  getFilesByEntityIdThunk,
} from "../../services/fileService/fileThunk";
import { Agreement } from "../agreement/agreementSlice";
import { User } from "../user/userSlice";

export type LoanReason = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type EmployeeType = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Bank = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Relationship = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Product = {
  id: string;
  adminId: string;
  name: string;
  code: string;
  minAgeChecking: number;
  maxAgeChecking: number;
  minGrossIncomeChecking: number;
  interestRate: number;
  minTenure: number;
  maxTenure: number;
  intervalTenure: number;
  referralCode: string;
  createdAt?: string;
  updatedAt?: string;
  startDate?: string;
  expiredDate?: string;
};

export type BankruptcyCheck = { bankruptcyCheck: string };
export type Amla = {
  id: string;
  proId: string;
  gdr: string;
  matSco: string;
  matNam: string;
  cty: string;
  tit: string;
  cddiId: string;
  createdAt: string;
  updatedAt: string;
};
export type IScore = { iScore: string };
export type SpecialAttentionFlag = { specialAttentionAccountCount: string };
export type CcrisStatusCode = { ccrisStatusCode: string };
export type CcrisLegal = { ccrisLegal: string };
export type TradeReference = {
  id: string;
  subject: string;
  newIc: string;
  creditorName: string;
  creditorTelNo: string;
  refNo: string;
  status: string;
  debtType: string;
  paymentAging: string;
  remark: string;
  statusDate: string;
  amount: string;
  spkccsId: string;
  createdAt: string;
  updatedAt: string;
};

export type Application = {
  applicationFormFileId?: string;
  applicantAcceptTncAndPp?: boolean;
  applicantAcceptMarketing?: boolean;
  applicantAcceptSharePersonalInfo?: boolean;
  applicantEmergencyContactRelationship?: string;
  applicantEmploymentType?: string;

  productName?: string;
  productCode?: string;
  productMinAgeChecking?: number;
  productMaxAgeChecking?: number;
  productMinGrossIncomeChecking?: number;
  productInterestRate?: number;
  productMinTenure?: number;
  productMaxTenure?: number;
  productIntervalTenure?: number;
  productStampingDutyPercentage?: number;
  productStampingFee?: number;
  productProcessingFee?: number;
  productReferralCode?: string;
  productProcessingFeeToDiscount?: number;

  numberOfCcrisReport?: number;

  applicantBankName?: string;
  applicantPersonalBankNo?: string;
  applicantFirstName?: string;
  applicantNric?: string;
  applicantPhoneNo?: string;
  applicantAddress1?: string;
  applicantAddress2?: string;
  applicantAddress3?: string;
  applicantCity?: string;
  applicantPostcode?: string;
  applicantState?: string;
  applicantEmail?: string;
  applicantGrossSalaryRange?: string;
  applicantRace?: string;
  applicantPartnerId?: string;
  experianSpkccsFileId?: string;
  experianIrissFileId?: string;
  experianNvrbFileId?: string;
  experianAmlaFileId?: string;
  customerType: string;
  loanType: string;
  id?: string;
  applicationNo?: string;
  userId?: string;
  adminId: string;
  agreementId?: string;
  productId?: string;
  applicationStatus?: string;
  remarks?: string;
  loanReasonId?: string;
  employmentTypeId?: string;
  personalBankId?: string;
  personalBankAccountNo?: string;
  actualMonthlySalary?: number;
  employerName?: string;
  employerContactNo?: string;
  jobTitle?: string;
  emergencyContactName?: string;
  emergencyContactNo?: string;
  emergencyContactRelationshipId?: string;
  emergencyContactPersonEmail?: string;
  secondEmergencyContactName?: string;
  secondEmergencyContactNo?: string;
  secondEmergencyContactRelationshipId?: string;
  secondEmergencyContactPersonEmail?: string;
  applicantSecondEmergencyContactRelationship?: string;
  employmentStartingFrom?: string;
  loanApplied: number;
  eligibleLoanAmount?: number;
  financeAmount?: number;
  loanTenure: number;
  loanInterestRate: number;
  submissionDate: string;
  dsr?: number;
  bucket?: string;
  createdAt?: string;
  updatedAt?: string;
  user?: User;
  agreement?: Agreement;
  admin?: { username?: string };
  product?: Product;
  loanReason?: LoanReason;
  employmentType?: EmployeeType;
  personalBank?: Bank;
  emergencyContactRelationship?: Relationship;
  bankruptcyCheck?: BankruptcyCheck[];
  amla?: Amla[];
  iScore?: IScore[];
  specialAttentionFlag?: SpecialAttentionFlag[];
  ccrisStatusCode?: CcrisStatusCode[];
  ccrisLegal?: CcrisLegal[];
  ccrisMia?: any;
  tradeReference?: TradeReference[];
  applicantBankIbgRoutingNumber?: string;
  applicantLoanReason?: string;
  isCustomReason: boolean;
  customReason?: string;
  language?: string;
  verifierSubmitDate?: string;
  ApplicationStepStatus?: ApplicationStepStatus[];
  ocrTaskRef?: string;
  detailedStatus?: string;
  currentEmploymentDuration?: string;
  loanTotalPremium?: number;
};

export type CreditRecord = {
  id: string;
  companyId: string;
  applicationId: string;
  age: string;
  gross_income: string;
  bankruptcy_check: string;
  amla: string;
  iscore: string;
  iscore_value: number;
  dsr_value: number;
  risk_profile: string;
  special_attention_flag: string;
  ccris_status_code: string;
  ccris_legal: string;
  mia_in_ccris_two_times: string;
  mia_in_ccris_three_times: string;
  trade_reference_amount: string;
  experian_legal: string;
  createdAt: string;
  updatedAt: string;
};

export type UploadedFile = {
  id: string;
  entityId: string;
  filename: string;
  originalName: string;
  mimeType: string;
  fileTypeId: string;
  fileType: {
    id: string;
    name: string;
    description?: string;
    fileSizeLimit?: number;
    createdAt?: string;
    updatedAt?: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type HistoryLog = {
  id: string;
  userId: string;
  user?: User;
  description?: string;
  adminId: string;
  applicationId?: string;
  application?: Application;
  agreementId?: string;
  agreement?: Agreement;
  createdAt: string;
  updatedAt: string;
};

export type InternalRemark = {
  id: string;
  userId: string;
  user: User;
  applicationId?: string;
  remarks: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type ApplicationStepStatus = {
  id: string;
  name: ApplicationStepStatusEnum;
  status: boolean;
  applicationId: string;
  createdAt: string;
  updatedAt: string;
};

export type ApplicationListing = {
  searchTotal: number;
  total: number;
  currentPage: number;
  pageSize: number;
  data: Application[];
};

export type ApplicationState = {
  applicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  submitForApprovalApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  pendingApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  approvedApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  followUpApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  rejectApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  cancelledApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  draftApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  referBackApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  referBackFromPendingDisbursementApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationListing;
  };
  creditRecords: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: CreditRecord | undefined;
  };
  applicationDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: Application;
  };
  applicationFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      salaryslip?: UploadedFile[];
      bankstatement?: UploadedFile[];
      othersrelevantdocuments?: UploadedFile[];
    };
  };
  ekycFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: UploadedFile[];
  };
  applicantFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: UploadedFile[];
  };
  historyLogs: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: HistoryLog[];
  };
  internalRemarks: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: InternalRemark[];
  };
  globalSearchApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: ApplicationListing;
  };
  globalSearchValue: {
    searchType: string;
    value: string;
  };
  accountStatementSearchValue: {
    searchType: string;
    value: string;
  };
};

const initialState: ApplicationState = {
  applicationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  submitForApprovalApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  creditRecords: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  applicationDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  applicationFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      salaryslip: [],
      bankstatement: [],
      othersrelevantdocuments: [],
    },
  },
  applicantFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  ekycFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  historyLogs: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  internalRemarks: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  pendingApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  followUpApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  referBackFromPendingDisbursementApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  approvedApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  rejectApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  cancelledApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  draftApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  referBackApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  globalSearchApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },

  globalSearchValue: {
    searchType: "applicationNo",
    value: "",
  },
  accountStatementSearchValue: {
    searchType: "CUSTOMER_NRIC",
    value: "",
  },
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    applicationStateReset: () => initialState,
    applicationDetailsStateReset: (state) => {
      state.applicationDetails = initialState.applicationDetails;
      state.ekycFiles = initialState.ekycFiles;
      state.applicationFiles = initialState.applicationFiles;
      state.historyLogs = initialState.historyLogs;
      state.internalRemarks = initialState.internalRemarks;
    },
    setApplicationDetails: (state, action) => {
      state.applicationDetails.data = action.payload;
    },
    setGlobalSearchValue: (state, action) => {
      state.globalSearchValue = action.payload;
    },
    setAccountStatementSearchValue: (state, action) => {
      state.accountStatementSearchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationsThunk.pending, (state) => {
        state.applicationList.isLoading = true;
      })
      .addCase(getApplicationsThunk.fulfilled, (state, action) => {
        state.applicationList.isLoading = false;
        state.applicationList.data = action.payload;
      })
      .addCase(getApplicationsThunk.rejected, (state, action) => {
        state.applicationList.isLoading = false;
        state.applicationList.isError = true;
        state.applicationList.errorMessage = action.payload;
        state.applicationList.data.data = [];
      });

    builder
      .addCase(getSubmitForApprovalApplicationsThunk.pending, (state) => {
        state.submitForApprovalApplicationList.isLoading = true;
      })
      .addCase(
        getSubmitForApprovalApplicationsThunk.fulfilled,
        (state, action) => {
          state.submitForApprovalApplicationList.isLoading = false;
          state.submitForApprovalApplicationList.data = action.payload;
        },
      )
      .addCase(
        getSubmitForApprovalApplicationsThunk.rejected,
        (state, action) => {
          state.submitForApprovalApplicationList.isLoading = false;
          state.submitForApprovalApplicationList.isError = true;
          state.submitForApprovalApplicationList.errorMessage = action.payload;
          state.submitForApprovalApplicationList.data.data = [];
        },
      );

    builder
      .addCase(getPendingApplicationsThunk.pending, (state) => {
        state.pendingApplicationList.isLoading = true;
      })
      .addCase(getPendingApplicationsThunk.fulfilled, (state, action) => {
        state.pendingApplicationList.isLoading = false;
        state.pendingApplicationList.data = action.payload;
      })
      .addCase(getPendingApplicationsThunk.rejected, (state, action) => {
        state.pendingApplicationList.isLoading = false;
        state.pendingApplicationList.isError = true;
        state.pendingApplicationList.errorMessage = action.payload;
        state.pendingApplicationList.data.data = [];
      });

    builder
      .addCase(getApprovedApplicationsThunk.pending, (state) => {
        state.approvedApplicationList.isLoading = true;
      })
      .addCase(getApprovedApplicationsThunk.fulfilled, (state, action) => {
        state.approvedApplicationList.isLoading = false;
        state.approvedApplicationList.data = action.payload;
      })
      .addCase(getApprovedApplicationsThunk.rejected, (state, action) => {
        state.approvedApplicationList.isLoading = false;
        state.approvedApplicationList.isError = true;
        state.approvedApplicationList.errorMessage = action.payload;
        state.approvedApplicationList.data.data = [];
      });

    builder
      .addCase(getReferbackApplicationsThunk.pending, (state) => {
        state.referBackApplicationList.isLoading = true;
      })
      .addCase(getReferbackApplicationsThunk.fulfilled, (state, action) => {
        state.referBackApplicationList.isLoading = false;
        state.referBackApplicationList.data = action.payload;
      })
      .addCase(getReferbackApplicationsThunk.rejected, (state, action) => {
        state.referBackApplicationList.isLoading = false;
        state.referBackApplicationList.isError = true;
        state.referBackApplicationList.errorMessage = action.payload;
        state.referBackApplicationList.data.data = [];
      });

    builder
      .addCase(getFollowUpApplicationsThunk.pending, (state) => {
        state.followUpApplicationList.isLoading = true;
      })
      .addCase(getFollowUpApplicationsThunk.fulfilled, (state, action) => {
        state.followUpApplicationList.isLoading = false;
        state.followUpApplicationList.data = action.payload;
      })
      .addCase(getFollowUpApplicationsThunk.rejected, (state, action) => {
        state.followUpApplicationList.isLoading = false;
        state.followUpApplicationList.isError = true;
        state.followUpApplicationList.errorMessage = action.payload;
        state.followUpApplicationList.data.data = [];
      });

    builder
      .addCase(getRejectedApplicationsThunk.pending, (state) => {
        state.rejectApplicationList.isLoading = true;
      })
      .addCase(getRejectedApplicationsThunk.fulfilled, (state, action) => {
        state.rejectApplicationList.isLoading = false;
        state.rejectApplicationList.data = action.payload;
      })
      .addCase(getRejectedApplicationsThunk.rejected, (state, action) => {
        state.rejectApplicationList.isLoading = false;
        state.rejectApplicationList.isError = true;
        state.rejectApplicationList.errorMessage = action.payload;
        state.rejectApplicationList.data.data = [];
      });

    builder
      .addCase(getCancelledApplicationsThunk.pending, (state) => {
        state.cancelledApplicationList.isLoading = true;
      })
      .addCase(getCancelledApplicationsThunk.fulfilled, (state, action) => {
        state.cancelledApplicationList.isLoading = false;
        state.cancelledApplicationList.data = action.payload;
      })
      .addCase(getCancelledApplicationsThunk.rejected, (state, action) => {
        state.cancelledApplicationList.isLoading = false;
        state.cancelledApplicationList.isError = true;
        state.cancelledApplicationList.errorMessage = action.payload;
        state.cancelledApplicationList.data.data = [];
      });

    builder
      .addCase(getDraftApplicationsThunk.pending, (state) => {
        state.draftApplicationList.isLoading = true;
      })
      .addCase(getDraftApplicationsThunk.fulfilled, (state, action) => {
        state.draftApplicationList.isLoading = false;
        state.draftApplicationList.data = action.payload;
      })
      .addCase(getDraftApplicationsThunk.rejected, (state, action) => {
        state.draftApplicationList.isLoading = false;
        state.draftApplicationList.isError = true;
        state.draftApplicationList.errorMessage = action.payload;
        state.draftApplicationList.data.data = [];
      });

    builder
      .addCase(
        getReferbackFromPendingDisbursementApplicationsThunk.pending,
        (state) => {
          state.referBackFromPendingDisbursementApplicationList.isLoading =
            true;
        },
      )
      .addCase(
        getReferbackFromPendingDisbursementApplicationsThunk.fulfilled,
        (state, action) => {
          state.referBackFromPendingDisbursementApplicationList.isLoading =
            false;
          state.referBackFromPendingDisbursementApplicationList.data =
            action.payload;
        },
      )
      .addCase(
        getReferbackFromPendingDisbursementApplicationsThunk.rejected,
        (state, action) => {
          state.referBackFromPendingDisbursementApplicationList.isLoading =
            false;
          state.referBackFromPendingDisbursementApplicationList.isError = true;
          state.referBackFromPendingDisbursementApplicationList.errorMessage =
            action.payload;
          state.referBackFromPendingDisbursementApplicationList.data.data = [];
        },
      );

    builder
      .addCase(getApplicationDetailsThunk.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(getApplicationDetailsThunk.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
      })
      .addCase(getApplicationDetailsThunk.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getFilesByEntityIdThunk.pending, (state) => {
        state.applicationFiles.isLoading = true;
      })
      .addCase(getFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.applicationFiles.isLoading = false;
        state.applicationFiles.data = groupBy(
          action.payload,
          (file: UploadedFile) => file.fileType.name.toLowerCase(),
        );
      })
      .addCase(getFilesByEntityIdThunk.rejected, (state, action) => {
        state.applicationFiles.isLoading = false;
        state.applicationFiles.isError = true;
        state.applicationFiles.errorMessage = action.payload;
      });

    builder
      .addCase(getInternalRemarksByApplicationIDThunk.pending, (state) => {
        state.internalRemarks.isLoading = true;
      })
      .addCase(
        getInternalRemarksByApplicationIDThunk.fulfilled,
        (state, action) => {
          state.internalRemarks.isLoading = false;
          state.internalRemarks.data = action.payload;
        },
      )
      .addCase(
        getInternalRemarksByApplicationIDThunk.rejected,
        (state, action) => {
          state.internalRemarks.isLoading = false;
          state.internalRemarks.isError = true;
          state.internalRemarks.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getHistoryLogsByApplicationIDThunk.pending, (state) => {
        state.historyLogs.isLoading = true;
      })
      .addCase(
        getHistoryLogsByApplicationIDThunk.fulfilled,
        (state, action) => {
          state.historyLogs.isLoading = false;
          state.historyLogs.data = action.payload;
        },
      )
      .addCase(getHistoryLogsByApplicationIDThunk.rejected, (state, action) => {
        state.historyLogs.isLoading = false;
        state.historyLogs.isError = true;
        state.historyLogs.errorMessage = action.payload;
      });

    builder
      .addCase(
        getHistoryLogsByApplicationIDAndAgreementIDThunk.pending,
        (state) => {
          state.historyLogs.isLoading = true;
        },
      )
      .addCase(
        getHistoryLogsByApplicationIDAndAgreementIDThunk.fulfilled,
        (state, action) => {
          state.historyLogs.isLoading = false;
          state.historyLogs.data = action.payload;
        },
      )
      .addCase(
        getHistoryLogsByApplicationIDAndAgreementIDThunk.rejected,
        (state, action) => {
          state.historyLogs.isLoading = false;
          state.historyLogs.isError = true;
          state.historyLogs.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getApplicantFilesThunk.pending, (state) => {
        state.applicantFiles.isLoading = true;
      })
      .addCase(getApplicantFilesThunk.fulfilled, (state, action) => {
        state.applicantFiles.isLoading = false;
        state.applicantFiles.data = action.payload;
      })
      .addCase(getApplicantFilesThunk.rejected, (state, action) => {
        state.applicantFiles.isLoading = false;
        state.applicantFiles.isError = true;
        state.applicantFiles.errorMessage = action.payload;
      });

    builder
      .addCase(getEkycFilesByEntityIdThunk.pending, (state) => {
        state.ekycFiles.isLoading = true;
      })
      .addCase(getEkycFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.ekycFiles.isLoading = false;
        state.ekycFiles.data = action.payload;
      })
      .addCase(getEkycFilesByEntityIdThunk.rejected, (state, action) => {
        state.ekycFiles.isLoading = false;
        state.ekycFiles.isError = true;
        state.ekycFiles.errorMessage = action.payload;
      });

    builder
      .addCase(getApplicationCreditRecordThunk.pending, (state) => {
        state.creditRecords.isLoading = true;
      })
      .addCase(getApplicationCreditRecordThunk.fulfilled, (state, action) => {
        state.creditRecords.isLoading = false;
        state.creditRecords.data = action.payload;
      })
      .addCase(getApplicationCreditRecordThunk.rejected, (state, action) => {
        state.creditRecords.isLoading = false;
        state.creditRecords.isError = true;
        state.creditRecords.errorMessage = action.payload;
      });

    builder
      .addCase(globalSearchThunk.pending, (state) => {
        state.globalSearchApplicationList.isLoading = true;
      })
      .addCase(globalSearchThunk.fulfilled, (state, action) => {
        state.globalSearchApplicationList.isLoading = false;
        state.globalSearchApplicationList.data = action.payload;
      })
      .addCase(globalSearchThunk.rejected, (state, action) => {
        state.globalSearchApplicationList.isLoading = false;
        state.globalSearchApplicationList.isError = true;
        state.globalSearchApplicationList.errorMessage = action.payload;
      });
  },
});

export const {
  applicationStateReset,
  applicationDetailsStateReset,
  setApplicationDetails,
  setGlobalSearchValue,
  setAccountStatementSearchValue,
} = applicationSlice.actions;

export default applicationSlice.reducer;
