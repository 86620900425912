import UploadedDocument from "../../../../../components/UploadedDocument";
import { FileTypeEnum } from "../../../../../enum/fileType";
import { Button, Divider, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import {
  getEkycFilesByEntityIdThunk,
  getFilesByEntityIdThunk,
} from "../../../../../services/fileService/fileThunk";
import { getSmeEkycFileEntityIdThunk } from "../../../../../services/ekycService/ekycThunk";
import LoadingParagraph from "../../../../../components/LoadingParagraphLong";
import { UpdateCreditRecordModal } from "./UpdateCreditRecordModal";
import { regenerateSmeApplicationFormThunk } from "../../../../../services/smeApplicationService/smeApplicationThunk";

export const AttachmentTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { smeApplicationDetails, ekycFiles } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const { smeFilesByEntityId } = useSelector(
    (state: RootState) => state.smeFile,
  );

  const handleRegenerateApplicationForm = () => {
    dispatch(regenerateSmeApplicationFormThunk(smeApplicationDetails.data?.id))
      .unwrap()
      .then(() => {
        message.success("Application form regenerated successfully");
      })
      .catch((err) => {
        console.log(err)
        message.error(err || "Failed to regenerate application form");
      });
  };

  const fetchSmeFilesByEntityId = () => {
    dispatch(
      getFilesByEntityIdThunk({ entityId: smeApplicationDetails.data?.id }),
    );
  };

  const fetchEkycFiles = () => {
    dispatch(
      getSmeEkycFileEntityIdThunk(smeApplicationDetails?.data?.redOneUsername),
    )
      .unwrap()
      .then((res) => {
        if (res?.length > 0 && res[0] != null) {
          dispatch(getEkycFilesByEntityIdThunk({ entityId: res[0]?.id }));
        }
      })
      .catch((err) => {
        message.error("Failed to fetch eKYC files");
        console.log(err);
      });
  };

  const initialCallbackState = {
    fetchSmeFilesByEntityId,
    fetchEkycFiles,
  };
  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    callbackState.fetchSmeFilesByEntityId();
    callbackState.fetchEkycFiles();
  }, [callbackState]);

  return ekycFiles?.isLoading || smeFilesByEntityId?.isLoading ? (
    <LoadingParagraph />
  ) : (
    <div className="info-container">
      <>
        <UploadedDocument
          key={"smeNric"}
          description="NRIC Front & Back"
          defaultFileList={ekycFiles?.data || []}
          readOnly
          fileType={FileTypeEnum.EKYCFILES}
        />
        <Divider className="my-4" />
      </>
      <>
        <UploadedDocument
          key={"smeApplicationForm"}
          description="Application Form"
          defaultFileList={smeFilesByEntityId?.data?.APPLICATIONFORM}
          readOnly
          fileType={FileTypeEnum.APPLICATIONFORM}
        />

        <Button className="my-3" onClick={handleRegenerateApplicationForm}>
          Regenerate Application Form
        </Button>

        <Divider className="my-4" />
      </>

      <>
        <UploadedDocument
          key={"cddi"}
          description="Customer Due-D Individual (CDDI)"
          defaultFileList={smeFilesByEntityId?.data?.CDDI}
          fileType={FileTypeEnum.CDDI}
          entityId={smeApplicationDetails.data?.id}
          callback={fetchSmeFilesByEntityId}
          extraActions={
            <UpdateCreditRecordModal
              inputKey={"CDDI_STATUS"}
              title={"Customer Due-D Individual (CDDI)"}
              value={
                smeApplicationDetails?.data?.smeCreditRecords?.cddiStatus ?? ""
              }
            />
          }
        />
        <Divider className="my-4" />
      </>

      <>
        <UploadedDocument
          key={"nrvb"}
          description="Bankruptcy Check (NRVB) Report"
          defaultFileList={smeFilesByEntityId?.data?.NRVB}
          fileType={FileTypeEnum.NRVB}
          entityId={smeApplicationDetails.data?.id}
          callback={fetchSmeFilesByEntityId}
          isSmeType={true}
          extraActions={
            <UpdateCreditRecordModal
              inputKey={"NRVB_STATUS"}
              title={"Bankruptcy Check (NRVB) Report"}
              value={
                smeApplicationDetails?.data?.smeCreditRecords?.nrvbStatus ?? ""
              }
            />
          }
        />

        <Divider className="my-4" />
      </>

      <>
        <UploadedDocument
          key={"cddc"}
          description="Customer Due-D Company (CDDC)"
          defaultFileList={smeFilesByEntityId?.data?.CDDC}
          fileType={FileTypeEnum.CDDC}
          entityId={smeApplicationDetails.data?.id}
          callback={fetchSmeFilesByEntityId}
          extraActions={
            <UpdateCreditRecordModal
              inputKey={"CDDC_STATUS"}
              title={"Customer Due-D Company (CDDC)"}
              value={
                smeApplicationDetails?.data?.smeCreditRecords?.cddcStatus ?? ""
              }
            />
          }
        />
        <Divider className="my-4" />
      </>

      <>
        <UploadedDocument
          key={"or"}
          description="Official Liquidated Or Wound Up Companies Report (OR)"
          defaultFileList={smeFilesByEntityId?.data?.LIQUIDATED_REPORT}
          fileType={FileTypeEnum.LIQUIDATED_REPORT}
          entityId={smeApplicationDetails.data?.id}
          callback={fetchSmeFilesByEntityId}
          extraActions={
            <UpdateCreditRecordModal
              inputKey={"OR_STATUS"}
              title={"Official Liquidated Or Wound Up Companies Report (OR)"}
              value={
                smeApplicationDetails?.data?.smeCreditRecords?.orStatus ?? ""
              }
            />
          }
        />
        <Divider className="my-4" />
      </>

      <>
        <UploadedDocument
          key={"eccris"}
          description="Enhanced C-Risk Score Intelligence (ECCRIS) with SSM"
          defaultFileList={smeFilesByEntityId?.data?.ECCRIS}
          fileType={FileTypeEnum.ECCRIS}
          entityId={smeApplicationDetails.data?.id}
          callback={fetchSmeFilesByEntityId}
          extraActions={
            <UpdateCreditRecordModal
              inputKey={"E_CCRIS_STATUS"}
              title={"Enhanced C-Risk Score Intelligence (ECCRIS) with SSM"}
              value={
                smeApplicationDetails?.data?.smeCreditRecords?.eCcrisStatus ??
                ""
              }
            />
          }
        />
        <Divider className="my-4" />
      </>

      <>
        <UploadedDocument
          key={"ci"}
          description="Company Information (CI)"
          defaultFileList={smeFilesByEntityId?.data?.COMPANY_INFORMATION}
          fileType={FileTypeEnum.COMPANY_INFORMATION}
          entityId={smeApplicationDetails.data?.id}
          callback={fetchSmeFilesByEntityId}
          extraActions={
            <UpdateCreditRecordModal
              inputKey={"CI_STATUS"}
              title={"Company Information (CI)"}
              value={
                smeApplicationDetails?.data?.smeCreditRecords?.ciStatus ?? ""
              }
            />
          }
        />
        <Divider className="my-4" />
      </>

      <>
        <UploadedDocument
          key={"bi"}
          description="Business Intelligence (BI) - SSM"
          defaultFileList={smeFilesByEntityId?.data?.BUSINESS_INTELLIGENCE}
          fileType={FileTypeEnum.BUSINESS_INTELLIGENCE}
          entityId={smeApplicationDetails.data?.id}
          callback={fetchSmeFilesByEntityId}
          extraActions={
            <UpdateCreditRecordModal
              inputKey={"SSM_STATUS"}
              title={"Business Intelligence (BI) - SSM"}
              value={
                smeApplicationDetails?.data?.smeCreditRecords?.ssmStatus ?? ""
              }
            />
          }
        />
        <Divider className="my-4" />
      </>
    </div>
  );
};
