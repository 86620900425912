import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { getFormattedAddress } from "../../../../../utils/string.util";
import InfoGrid from "../../../../../components/InfoGrid";
import React from "react";
import { EditCompanyDetailsModal } from "./EditCompanyDetails";
import { displayDate } from "../../../../../utils/datetime.util";
import { SME_ORGANIZATION_TYPE } from "../../../../../enum/smeApplication";
import { SmeCompanyDirector } from "../../../../../features/smeApplication/smeApplicationDirectorsSlice";

export const CompanyDetailsTab = () => {
  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const data: any = smeApplicationDetails?.data?.smeSolePropApplication || smeApplicationDetails?.data?.smePartnershipApplication || smeApplicationDetails?.data?.smeCompanyApplication;
  let authorizedDetails: any[] = [];
  const companyDetails = [
    {
      title: "Company Name",
      value: data?.companyName,
    },
    {
      title: "Company Email",
      value: data?.companyEmail,
    },
    {
      title: "Business Old Registration No.",
      value: data?.businessOldRegistrationNo,
    },
    {
      title: "Business New Registration No.",
      value: data?.businessNewRegistrationNo,
    },
    {
      title: "Country of Incorporation/Registration",
      value: data?.countryOfIncorporation,
    },
    {
      title: "Business Contact No.",
      value: data?.businessContactNo,
    },
    {
      title: "SSM Expiry Date",
      value: displayDate(smeApplicationDetails?.data?.smePartner?.ssmExpiryDate),
    },
  ];

  const shareholdingStatus = [
    {
      title: "Shareholding Status",
      value: data?.shareholdingStatus,
    }
  ]

  const managementInfo = [
    {
      title: "Name of Senior Management",
      value: data?.nameOfSeniorManagement,
    },
    {
      title: "Nature of Business",
      value: data?.natureOfBusiness,
    },
    ...(smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.COMPANY ? shareholdingStatus : [])
  ];

  const businessAddress = [
    {
      title: "Business Address",
      value: getFormattedAddress(data?.businessAddress1, data?.businessAddress2, data?.businessAddress3),
    },
    {
      title: "Business Address Postcode",
      value: data?.businessPostcode,
    },
    {
      title: "Business Address City",
      value: data?.businessCity,
    },
    {
      title: "Business Address State",
      value: data?.businessState,
    },
  ];

  const address = [
    {
      title: "Registered Address (SSM)",
      value: getFormattedAddress(data?.registeredSsmAddress1, data?.registeredSsmAddress2, data?.registeredSsmAddress3),
    },
    {
      title: "Registered Address (SSM) Postcode",
      value: data?.registeredSsmPostcode,
    },
    {
      title: "Registered Address (SSM) City",
      value: data?.registeredSsmCity,
    },
    {
      title: "Registered Address (SSM) State",
      value: data?.registeredSsmState,
    },
    {
      title: "Business Authorized Address",
      value: smeApplicationDetails?.data?.smeSolePropApplication ? getFormattedAddress(data?.businessAddress1, data?.businessAddress2, data?.businessAddress3) : getFormattedAddress(data?.businessAuthorisedAddress1, data?.businessAuthorisedAddress2, data?.businessAuthorisedAddress3),
    },
    {
      title: "Business Authorized Address Postcode",
      value: smeApplicationDetails?.data?.smeSolePropApplication ? data?.businessPostcode : data?.businessAuthorisedPostcode,
    },
    {
      title: "Business Authorized Address City",
      value: smeApplicationDetails?.data?.smeSolePropApplication ? data?.businessCity : data?.businessAuthorisedCity,
    },
    {
      title: "Business Authorized Address State",
      value: smeApplicationDetails?.data?.smeSolePropApplication ? data?.businessState : data?.businessAuthorisedState,
    },
    ...(smeApplicationDetails?.data?.smeCompanyApplication ? businessAddress : []),
  ];

  if (data && smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.COMPANY) {
    const authorized = data?.smeCompanyDirectors?.find((director: SmeCompanyDirector) => director.isAuthorizedPerson);
    authorizedDetails = [
      {
        title: "Authorised Contact Person",
        value: authorized?.directorName,
      },
      {
        title: "Authorised Contact Person's NRIC",
        value: authorized?.nric,
      },
      {
        title: "Authorised Contact Person's Email",
        value: authorized?.personalEmail,
      },
      {
        title: "Authorised Contact Number",
        value: authorized?.phoneNo,
      },
    ];
  }

  return (
    <div className="info-container">
      <InfoGrid title="Company Details" data={companyDetails} extra={
        <EditCompanyDetailsModal />
      } />
      <br />
      <br />
      <InfoGrid data={managementInfo} />
      <br />
      <br />
      <InfoGrid data={address} />
      {(data && smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.COMPANY) && (
        <>
          <br />
          <br />
          <InfoGrid data={authorizedDetails} />
        </>
      )}
    </div>
  );
};