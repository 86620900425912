import "../index.less";

import { Table } from "antd";

import { numberWithCommas } from "../../../helpers/number";
import { displayDateAndTime } from "../../../utils/datetime.util";

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { specialTaggingDescription } from "../../../utils/specialTaggingDesc.util";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { getTaggingHistoryByAccountDetailsIdThunk } from "../../../services/collectionService/collectionThunk";

const SpecialTaggingHistory = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { taggingHistory } = useSelector(
    (state: RootState) => state.collection,
  );

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: taggingHistory.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      key: "id",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) =>
        text ? displayDateAndTime(text) : "-",
    },
    {
      title: "Special Tagging Code",
      dataIndex: "taggingName",
      key: "taggingName",
    },
    {
      title: "Special Tagging Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "History / Remarks",
      dataIndex: "historyRemarks",
      key: "historyRemarks",
      render: (text: string, record: any) => (text ? text : "-"),
    },
    {
      title: "Documents",
      dataIndex: "documents",
      key: "documents",
      render: (text: string, record: any) => (text ? text : "-"),
    },
  ];

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    await dispatch(
      getTaggingHistoryByAccountDetailsIdThunk({
        ...initialParams,
        pagination,
        accountDetailsId: accountEnquiry.data?.accountDetails?.id,
      }),
    );
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    dispatch(
      getTaggingHistoryByAccountDetailsIdThunk({
        ...initialParams,
        accountDetailsId: accountEnquiry.data?.accountDetails?.id,
      }),
    );
  }, [accountEnquiry.data.accountDetails?.id]);

  useEffect(() => {
    console.log(taggingHistory.data?.data);
  }, [taggingHistory.data?.data]);

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <Table
            columns={columns}
            dataSource={taggingHistory.data?.data}
            pagination={tablePagination}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SpecialTaggingHistory;
