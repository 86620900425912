import { createSlice } from "@reduxjs/toolkit";
import { getAllBucketThunk } from "../../services/caseAssignmentService/bucketService/bucketThunk";

type GroupBucket = {
  id: string;
  groupId: string;
  bucketId: string;
  isActive?: boolean;
  createdAt: string;
  updatedAt: string;
};

export type Bucket = {
  id: string;
  bucketCode: string;
  bucketName: string;
  priority: number;
  regionId?: string;
  ruleId: string;
  GroupBucket?: GroupBucket[];
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
};

export type BucketState = {
  bucketsState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Bucket[];
  };
};

const initialState: BucketState = {
  bucketsState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const bucketSlice = createSlice({
  name: "bucket",
  initialState,
  reducers: {
    bucketReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBucketThunk.pending, (state) => {
        state.bucketsState.isLoading = true;
      })
      .addCase(getAllBucketThunk.fulfilled, (state, action) => {
        state.bucketsState.isLoading = false;
        state.bucketsState.data = action.payload.data;
      })
      .addCase(getAllBucketThunk.rejected, (state, action) => {
        state.bucketsState.isLoading = false;
        state.bucketsState.isError = true;
        state.bucketsState.errorMessage = action.payload;
      });
  },
});

export const { bucketReset } = bucketSlice.actions;

export default bucketSlice.reducer;
