import { createAsyncThunk } from "@reduxjs/toolkit";
import applicationService from "./applicationService";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import fileService from "../fileService/fileService";

export const getApplicationsThunk = createAsyncThunk(
  "application/getList",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getApplications(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSubmitForApprovalApplicationsThunk = createAsyncThunk(
  "application/getSubmitForApprovalList",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getSubmitForApprovalApplication(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingApplicationsThunk = createAsyncThunk(
  "application/getPendingApplicationList",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getPendingApplications(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferbackApplicationsThunk = createAsyncThunk(
  "application/getReferbackApplicationList",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getReferbackApplications(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferbackFromPendingDisbursementApplicationsThunk =
  createAsyncThunk(
    "application/getReferbackFromPendingDisbursementApplicationList",
    async (requestData: any, thunkAPI) => {
      try {
        return await applicationService.getReferbackFromPendingDisbursementApplications(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const getFollowUpApplicationsThunk = createAsyncThunk(
  "application/getFollowUpApplicationList",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getFollowUpApplications(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApprovedApplicationsThunk = createAsyncThunk(
  "application/getApprovedApplicationsList",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getApprovedApplications(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRejectedApplicationsThunk = createAsyncThunk(
  "application/getRejectedApplicationList",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getRejectedApplications(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getCancelledApplicationsThunk = createAsyncThunk(
  "application/getCancelledApplicationList",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getCancelledApplications(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDraftApplicationsThunk = createAsyncThunk(
  "application/getDraftApplications",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getDraftApplications(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApplicationDetailsThunk = createAsyncThunk(
  "application/getDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getApplicationDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getInternalRemarksByApplicationIDThunk = createAsyncThunk(
  "application/getInternalRemarks",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getInternalRemarksByApplicationID(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getHistoryLogsByApplicationIDThunk = createAsyncThunk(
  "application/getHistoryLogs",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getHistoryLogsByApplicationID(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getHistoryLogsByApplicationIDAndAgreementIDThunk =
  createAsyncThunk(
    "application/getHistoryLogs/combine",
    async (requestData: any, thunkAPI) => {
      try {
        return await applicationService.getHistoryLogsByApplicationIDAndAgreementID(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const getApplicantFilesThunk = createAsyncThunk(
  "application/getApplicantFiles",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createInternalRemarkThunk = createAsyncThunk(
  "application/createInternalRemark",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.createInternalRemark(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const referBackApplicationThunk = createAsyncThunk(
  "application/referBack",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.referBackApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const referBackApplicationFromPendingDisbursementThunk =
  createAsyncThunk(
    "application/referBackFromPendingDisbursement",
    async (requestData: any, thunkAPI) => {
      try {
        return await applicationService.referBackApplicationFromPendingDisbursement(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const updateApplicationStatusToFollowedUpThunk = createAsyncThunk(
  "application/updateStatusToFollowedUp",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.updateApplicationStatusToFollowedUp(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateApplicationStatusToCaseSettledFromFollowedUpThunk =
  createAsyncThunk(
    "application/updateStatusToCaseSettledFromFollowedUp",
    async (requestData: any, thunkAPI) => {
      try {
        return await applicationService.updateApplicationStatusToCaseSettledFromFollowedUp(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const updateApplicantUserDetailsThunk = createAsyncThunk(
  "application/updateApplicantUserDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.updateApplicantUserDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const cancelApplicationThunk = createAsyncThunk(
  "application/cancel",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.cancelApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const submitForApprovalThunk = createAsyncThunk(
  "application/submitForApproval",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.submitForApproval(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const approveApplicationThunk = createAsyncThunk(
  "application/approve",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.approveApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const rejectApplicationThunk = createAsyncThunk(
  "application/reject",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.rejectApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteApplicationThunk = createAsyncThunk(
  "application/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.deleteApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApplicationCreditRecordThunk = createAsyncThunk(
  "application/credit-record",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getApplicationCreditRecord(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateApplicationFinanceAmountThunk = createAsyncThunk(
  "application/updateFinanceAmount",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.updateApplicationFinanceAmount(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateApplicantThunk = createAsyncThunk(
  "application/updateApplicant",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.updateApplicant(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateApplicationNetIncomeThunk = createAsyncThunk(
  "application/updateApplicationNetIncome",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.updateApplicationNetIncome(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const globalSearchThunk = createAsyncThunk(
  "application/globalSearch",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.globalSearch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApplicationExperianReportThunk = createAsyncThunk(
  "application/getApplicationExperianReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getApplicationExperianReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const regenerateApplicationFormThunk = createAsyncThunk(
  "application/regenerateApplicationForm",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.regenerateApplicationForm(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const onReRunApplicationCreditEvaluationThunk = createAsyncThunk(
  "application/re-run-application-credit-evaluation-engine",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.reRunApplicationCreditEvaluationEngine(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
