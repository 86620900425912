import { Tabs, TabsProps, Tag } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { REFUND_STATUS } from "../../../enum/refund";
import {
  getApprovedRefundListThunk,
  getOpenRefundListThunk,
  getPendingRefundListThunk,
  getReferbackRefundListThunk,
  getRefundedRefundThunk,
  getVoidRefundListThunk,
} from "../../../services/refundService/refundThunk";
import {
  getApprovedUnmatchedRefundListThunk,
  getOpenUnmatchedRefundListThunk,
  getPendingUnmatchedRefundListThunk,
  getReferbackUnmatchedRefundListThunk,
  getUnmatchedRefundedRefundThunk,
  getVoidUnmatchedRefundListThunk,
} from "../../../services/unmatchedService/unmatchedThunk";
import { AppDispatch, RootState } from "../../../store/store";
import RefundTable from "./refundTable";

const RefundListingTabs: React.FC<any> = (props) => {
  const { isUnmatched } = props;
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const activeTab = queryParams.get("tab");

  const { refundListing, unmatchRefundListing } = useSelector(
    (state: RootState) => state.refund,
  );

  const fetchAll = async (params?: any) => {
    if (type === "unmatched") {
      await fetchOpenUnmatchedRefundList({
        ...params,
        filters: { ...params.filters, status: "OPEN" },
      });
      await fetchPendingUnmatchedRefundList({
        ...params,
        filters: { ...params.filters, status: "PENDING" },
      });
      await fetchReferbackUnmatchedRefundList({
        ...params,
        filters: { ...params.filters, status: "REFERBACK" },
      });
      await fetchVoidUnmatchedRefundList({
        ...params,
        filters: { ...params.filters, status: "VOID" },
      });
      await fetchApprovedUnmatchedRefundList({
        ...params,
        filters: { ...params.filters, status: "APPROVED" },
      });
      await fetchRefundedUnmatchedRefundList({
        ...params,
        filters: { ...params.filters, status: "REFUNDED" },
      });
    } else {
      await fetchOpenRefundList({
        ...params,
        filters: { ...params.filters, status: "OPEN" },
      });
      await fetchPendingRefundList({
        ...params,
        filters: { ...params.filters, status: "PENDING" },
      });
      await fetchReferbackRefundList({
        ...params,
        filters: { ...params.filters, status: "REFERBACK" },
      });
      await fetchVoidRefundList({
        ...params,
        filters: { ...params.filters, status: "VOID" },
      });
      await fetchApprovedRefundList({
        ...params,
        filters: { ...params.filters, status: "APPROVED" },
      });
      await fetchRefundedRefundList({
        ...params,
        filters: { ...params.filters, status: "REFUNDED" },
      });
    }
  };
  const fetchOpenRefundList = async (params?: any) => {
    const param = { ...params, filters: { ...params.filters, status: "OPEN" } };
    await dispatch(getOpenRefundListThunk(param));
    await dispatch(getOpenUnmatchedRefundListThunk(param));
  };

  const fetchPendingRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "PENDING" },
    };
    await dispatch(getPendingRefundListThunk(param));
    await dispatch(getPendingUnmatchedRefundListThunk(param));
  };

  const fetchReferbackRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "REFERBACK" },
    };

    const pendingParam = {
      ...params,
      filters: { ...params.filters, status: "PENDING" },
    };
    await dispatch(getReferbackRefundListThunk(param));
    await dispatch(getReferbackUnmatchedRefundListThunk(param));
  };

  const fetchVoidRefundList = async (params?: any) => {
    const param = { ...params, filters: { ...params.filters, status: "VOID" } };
    await dispatch(getVoidRefundListThunk(param));
    await dispatch(getVoidUnmatchedRefundListThunk(param));
  };

  const fetchApprovedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "APPROVED" },
    };
    await dispatch(getApprovedRefundListThunk(param));
    await dispatch(getApprovedUnmatchedRefundListThunk(param));
  };

  const fetchRefundedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "REFUNDED" },
    };
    await dispatch(getRefundedRefundThunk(param));
    await dispatch(getUnmatchedRefundedRefundThunk(param));
  };

  const fetchOpenUnmatchedRefundList = async (params?: any) => {
    const param = { ...params, filters: { ...params.filters, status: "OPEN" } };
    await dispatch(getOpenUnmatchedRefundListThunk(param));
  };

  const fetchPendingUnmatchedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "PENDING" },
    };
    await dispatch(getPendingUnmatchedRefundListThunk(param));
  };

  const fetchReferbackUnmatchedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "REFERBACK" },
    };
    await dispatch(getReferbackUnmatchedRefundListThunk(param));
  };

  const fetchVoidUnmatchedRefundList = async (params?: any) => {
    const param = { ...params, filters: { ...params.filters, status: "VOID" } };
    await dispatch(getVoidUnmatchedRefundListThunk(param));
  };

  const fetchApprovedUnmatchedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "APPROVED" },
    };
    await dispatch(getApprovedUnmatchedRefundListThunk(param));
  };

  const fetchRefundedUnmatchedRefundList = async (params?: any) => {
    const param = {
      ...params,
      filters: { ...params.filters, status: "REFUNDED" },
    };
    await dispatch(getUnmatchedRefundedRefundThunk(param));
  };

  const refundItems: TabsProps["items"] = [
    {
      key: "open",
      label: (
        <span>
          Open{" "}
          {refundListing?.openRefund?.data?.data != null && (
            <Tag>{refundListing?.openRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={refundListing?.openRefund}
          status={REFUND_STATUS.OPEN}
          callback={fetchAll}
          isUnmatched={isUnmatched}
        />
      ),
    },
    {
      key: "pending",
      label: (
        <span>
          Pending{" "}
          {refundListing?.pendingRefund?.data?.data != null && (
            <Tag>{refundListing?.pendingRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={refundListing?.pendingRefund}
          status={REFUND_STATUS.PENDING}
          viewMode={true}
          callback={fetchAll}
          isUnmatched={isUnmatched}
        />
      ),
    },
    {
      key: "referback",
      label: (
        <span>
          Referback{" "}
          {refundListing?.referbackRefund?.data?.data != null && (
            <Tag>{refundListing?.referbackRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={refundListing?.referbackRefund}
          status={REFUND_STATUS.REFERBACK}
          callback={fetchAll}
          isUnmatched={isUnmatched}
        />
      ),
    },
    {
      key: "void",
      label: (
        <span>
          Void{" "}
          {refundListing?.voidRefund?.data?.data != null && (
            <Tag>{refundListing?.voidRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={refundListing?.voidRefund}
          status={REFUND_STATUS.VOID}
          viewMode={true}
          callback={fetchAll}
          isUnmatched={isUnmatched}
        />
      ),
    },
    {
      key: "approved",
      label: (
        <span>
          In Process{" "}
          {refundListing?.approvedRefund?.data?.data != null && (
            <Tag>{refundListing?.approvedRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={refundListing?.approvedRefund}
          status={REFUND_STATUS.APPROVED}
          viewMode={true}
          callback={fetchAll}
          isUnmatched={isUnmatched}
        />
      ),
    },
    {
      key: "refunded",
      label: (
        <span>
          Completed{" "}
          {refundListing?.refundedRefund?.data?.data != null && (
            <Tag>{refundListing?.refundedRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={refundListing?.refundedRefund}
          status={REFUND_STATUS.REFUNDED}
          viewMode={true}
          callback={fetchAll}
          isUnmatched={isUnmatched}
        />
      ),
    },
  ];

  const unmatchedItems: TabsProps["items"] = [
    {
      key: "open",
      label: (
        <span>
          Open{" "}
          {unmatchRefundListing?.openRefund?.data?.data != null && (
            <Tag>{unmatchRefundListing?.openRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={unmatchRefundListing?.openRefund}
          status={REFUND_STATUS.OPEN}
          callback={fetchAll}
          isUnmatched={isUnmatched}
        />
      ),
    },
    {
      key: "pending",
      label: (
        <span>
          Pending{" "}
          {unmatchRefundListing?.pendingRefund?.data?.data != null && (
            <Tag>{unmatchRefundListing?.pendingRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={unmatchRefundListing?.pendingRefund}
          status={REFUND_STATUS.PENDING}
          viewMode={true}
          callback={fetchAll}
        />
      ),
    },
    {
      key: "referback",
      label: (
        <span>
          Referback{" "}
          {unmatchRefundListing?.referbackRefund?.data?.data != null && (
            <Tag>{unmatchRefundListing?.referbackRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={unmatchRefundListing?.referbackRefund}
          status={REFUND_STATUS.REFERBACK}
          callback={fetchAll}
        />
      ),
    },
    {
      key: "void",
      label: (
        <span>
          Void{" "}
          {unmatchRefundListing?.voidRefund?.data?.data != null && (
            <Tag>{unmatchRefundListing?.voidRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={unmatchRefundListing?.voidRefund}
          status={REFUND_STATUS.VOID}
          viewMode={true}
          callback={fetchAll}
        />
      ),
    },
    {
      key: "approved",
      label: (
        <span>
          In Process{" "}
          {unmatchRefundListing?.approvedRefund?.data?.data != null && (
            <Tag>{unmatchRefundListing?.approvedRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={unmatchRefundListing?.approvedRefund}
          status={REFUND_STATUS.APPROVED}
          viewMode={true}
          callback={fetchAll}
        />
      ),
    },
    {
      key: "refunded",
      label: (
        <span>
          Completed{" "}
          {unmatchRefundListing?.refundedRefund?.data?.data != null && (
            <Tag>{unmatchRefundListing?.refundedRefund?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RefundTable
          dataSource={unmatchRefundListing?.refundedRefund}
          status={REFUND_STATUS.REFUNDED}
          viewMode={true}
          callback={fetchAll}
        />
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey={activeTab ? activeTab : "open"}
      items={type === "unmatched" ? unmatchedItems : refundItems}
      onChange={(key) => {
        queryParams.set("tab", key);
        const newSearch = queryParams.toString();
        navigate({
          pathname: location.pathname,
          search: newSearch,
        });
      }}
      destroyInactiveTabPane
    />
  );
};

export default RefundListingTabs;
