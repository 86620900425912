import { Input, Select, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../../../store/store";

import { searchDisbursedAgreementThunk } from "../../../../services/agreementService/agreementThunk";
import { collectionStateReset } from "../../../../features/collection/collectionSlice";
import SearchAgreementResult from "../../../AccountEnquiry/SearchAgreementResult";
import { getAccountDetailsThunk } from "../../../../services/collectionService/collectionThunk";
import RescheduleDateModal from "../../RescheduleDetailsPage/components/reschedule-date-modal";
import { useNavigate } from "react-router-dom";
import {
  createRescheduleCaseThunk,
  getRescheduleCaseThunk,
} from "../../../../services/rescheduleService/rescheduleThunk";
import { Dayjs } from "dayjs";
import { SPECIAL_TAGGING_TYPE } from "../../../../enum/specialTagging";

const { Search } = Input;

const RescheduleRequestTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [resultNotFound, setResultNotFound] = useState(false);
  const [searchType, setSearchType] = useState("AGREEMENT_NO");
  const [searchResultVisible, setSearchResultVisible] = useState(false);
  const [searchResultData, setSearchResultData] = useState([]);
  const [agreementNoSelected, setAgreementNoSelected] = useState("");
  const [rescheduleDateVisible, setRescheduleDateVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSearch = async (value: string) => {
    if (value) {
      setLoadingSearch(true);
      await dispatch(
        searchDisbursedAgreementThunk({ searchType: value })
      )
        .then((res) => {
          setSearchResultData(res.payload);
          if (res.payload && res.payload.length > 0) {
            setSearchResultVisible(true);
          } else {
            message.error("Result Not Found");
            setResultNotFound(true);
          }
        })
        .finally(() => {
          setLoadingSearch(false);
        });
    } else {
      dispatch(collectionStateReset());
    }
  };

  const handleSearchResultSelect = async (agreementNo: string) => {
    setSearchResultVisible(false);

    // check if account contains special tagging ES and FS
    await dispatch(getAccountDetailsThunk({ agreementNo }))
      // To unwrap the return data
      .unwrap()
      .then((res) => {
        // prompt error message if account is under ES or FS
        if (
          res.accountDetails.specialTaggingAuto ===
            SPECIAL_TAGGING_TYPE.FULL_SETTLEMENT ||
          res.accountDetails.specialTaggingManual ===
            SPECIAL_TAGGING_TYPE.FULL_SETTLEMENT ||
          res.accountDetails.specialTaggingAuto ===
            SPECIAL_TAGGING_TYPE.EARLY_SETTLEMENT ||
          res.accountDetails.specialTaggingManual ===
            SPECIAL_TAGGING_TYPE.EARLY_SETTLEMENT
        ) {
          message.error(
            "This account is under Full Settlement or Early Settlement",
          );
        } else {
          // prompt to select reschedule date (date request to reschedule by customer)
          setAgreementNoSelected(agreementNo);
          setRescheduleDateVisible(true);
        }
      });
  };

  const handleRescheduleDateSelect = async (rescheduleDate: Dayjs) => {
    setLoading(true);
    await dispatch(getAccountDetailsThunk({ agreementNo: agreementNoSelected }))
      .unwrap()
      .then(async (res) => {
        await dispatch(
          createRescheduleCaseThunk({
            accountDetailsId: res.accountDetails.id,
            rescheduleDate,
          }),
        )
          .unwrap()
          .then((res) => {
            navigate("/reschedule/details/" + res.data.id);
          })
          .finally(() => {
            setLoading(false);
            setRescheduleDateVisible(false);
          });

        // await dispatch(
        //   getRescheduleCaseThunk({
        //     id: "ed666cba-78f1-4a62-af1e-5a7e24a9e4ee",
        //   }),
        // )
        //   .unwrap()
        //   .then((res) => {
        //     navigate("/reschedule/details/" + res.data.id);
        //   });
      });
  };

  const handleSearchResultCancel = () => {
    setSearchResultVisible(false);
  };

  return (
    <>
      <Input.Group compact>
        <Select
          className="w-[10rem]"
          value={searchType}
          onChange={(value) => {
            setResultNotFound(false);
            setSearchType(value);
          }}
          options={[
            { value: "AGREEMENT_NO", label: "Agreement No" },
            { value: "CUSTOMER_NRIC", label: "Applicant NRIC" },
            { value: "CUSTOMER_NAME", label: "Applicant Name" },
            { value: "PHONE_NO", label: "Phone No" },
            { value: "EMAIL", label: "Email" },
          ]}
        />
        <Search
          className="w-[20rem]"
          placeholder="Input search text"
          enterButton="Search"
          loading={loadingSearch}
          onSearch={onSearch}
          status={resultNotFound ? "error" : ""}
          onChange={() => setResultNotFound(false)}
        />
      </Input.Group>
      <br />
      {searchResultVisible && (
        <SearchAgreementResult
          visible={searchResultVisible}
          data={searchResultData}
          onOk={handleSearchResultSelect}
          onCancel={handleSearchResultCancel}
        />
      )}
      <br />
      {rescheduleDateVisible && (
        <RescheduleDateModal
          isModalVisible={rescheduleDateVisible}
          setIsModalVisible={setRescheduleDateVisible}
          callback={handleRescheduleDateSelect}
          loading={loading}
        />
      )}
    </>
  );
};

export default RescheduleRequestTable;
