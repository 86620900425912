import { createSlice } from "@reduxjs/toolkit";
import { getReportListingThunk } from "../../services/reportService/reportThunk";

export type reportListing = {
  id: number;
  fileName: string;
  fileId: string;
  reportDate: string;
  reportType: string;
  createdAt: string;
  updatedAt: string;
};

export type reportListingResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: reportListing[];
};

export type reportListingState = {
  reportListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: reportListing[];
  };
};

const initialState: reportListingState = {
  reportListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: { reportReset: () => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(getReportListingThunk.pending, (state) => {
        state.reportListing.isLoading = true;
      })
      .addCase(getReportListingThunk.fulfilled, (state, action) => {
        state.reportListing.isLoading = false;
        state.reportListing.data = action.payload?.list;
      })
      .addCase(getReportListingThunk.rejected, (state, action) => {
        state.reportListing.isLoading = false;
        state.reportListing.isError = true;
        state.reportListing.errorMessage = action.payload;
      });
  },
});

export const { reportReset } = reportSlice.actions;

export default reportSlice.reducer;
