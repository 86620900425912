import "./index.less";

import { Button, Form, InputNumber, message, Modal } from "antd";
import startCase from "lodash.startcase";
import React, { useState } from "react";

type UpdateAocAmountModalProps = {
  chargeDetails?: any;
  callback?: any;
  chargesArray?: any;
  tax?: any;
  selectedCharges?: any;
  amountForAllCharges?: any;
};

const UpdateAocAmountModal: React.FC<UpdateAocAmountModalProps> = (props) => {
  const { chargeDetails, tax, callback, selectedCharges, amountForAllCharges } =
    props;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const amount = values.amount;
        const payload = {
          charges: selectedCharges,
          chargeType: values.chargeType,
          amount: parseFloat(amount.toFixed(2)),
          tax: parseFloat(((amount * tax) / 100).toFixed(2)),
          amountAfterTax: parseFloat(
            (amount + (amount * tax) / 100).toFixed(2),
          ),
        };

        // Callback method
        if (callback) callback(payload);

        setIsModalOpen(false);
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        // disabled={
        //     chargeDetails?.applicationStatus ===
        //     ApplicationStatusEnum.APPROVED ||
        //     chargeDetails?.applicationStatus ===
        //     ApplicationStatusEnum.REJECTED
        // }
        ghost
      >
        Edit
      </Button>
      <Modal
        title="Update Add On Charges Amount"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={360}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          initialValues={{
            chargeType:
              selectedCharges === undefined || selectedCharges?.length < 1
                ? startCase(chargeDetails?.type)
                : selectedCharges
                    ?.map((charge: any) => startCase(charge))
                    .join(", "),
            amount:
              selectedCharges === undefined || selectedCharges?.length < 1
                ? chargeDetails?.amount
                : amountForAllCharges,
          }}
        >
          <Form.Item label="Charges Type" name="chargeType">
            <InputNumber style={{ width: "100%" }} controls={false} disabled />
          </Form.Item>
          <Form.Item
            label="Add On Charges Amount"
            name="amount"
            rules={[
              { required: true, message: "Add on charges amount is required" },
            ]}
          >
            <InputNumber
              placeholder="Please input"
              style={{ width: "100%" }}
              controls={false}
              inputMode="numeric"
              type={"number"}
              prefix="RM"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateAocAmountModal;
