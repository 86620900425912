import { createSlice } from "@reduxjs/toolkit";

import {
  getProductsThunk,
  getSmeProductsThunk,
} from "../../services/productService/productThunk";

export type Product = {
  id: string;
  adminId: string;
  name: string;
  code: string;
  minAgeChecking: number;
  maxAgeChecking: number;
  minGrossIncomeChecking: number;
  interestRate: number;
  minTenure: number;
  maxTenure: number;
  intervalTenure: number;
  stampingFee: number;
  attestationFee: number;
  processingFee: number;
  referralCode: string;
  createdAt?: string;
  updatedAt?: string;
};

export type SmeProduct = {
  id: string;
  code: string;
  minActiveLine: number;
  maxActiveLine: number;
  minAvgRecurringAmount: number;
  maxAvgRecurringAmount: number;
  interestRate: number;
  minTenure: number;
  maxTenure: number;
  intervalTenure: number;
  minLoanAmount: number;
  maxLoanAmount: number;
  intervalLoanAmount: number;
  processingFee: number;
  stampingDutyPercentage: number;
  referralCode?: string;
  processingFeeToDiscount: number;
};

export type ProductState = {
  products: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Product[];
  };
  smeProducts: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: SmeProduct[];
    };
  };
};

const initialState: ProductState = {
  products: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  smeProducts: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    productStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductsThunk.pending, (state) => {
        state.products.isLoading = true;
      })
      .addCase(getProductsThunk.fulfilled, (state, action) => {
        state.products.isLoading = false;
        state.products.data = action.payload;
      })
      .addCase(getProductsThunk.rejected, (state, action) => {
        state.products.isLoading = false;
        state.products.isError = true;
        state.products.errorMessage = action.payload;
      });

    builder
      .addCase(getSmeProductsThunk.pending, (state) => {
        state.smeProducts.isLoading = true;
      })
      .addCase(getSmeProductsThunk.fulfilled, (state, action) => {
        state.smeProducts.isLoading = false;
        state.smeProducts.data = action.payload;
      })
      .addCase(getSmeProductsThunk.rejected, (state, action) => {
        state.smeProducts.isLoading = false;
        state.smeProducts.isError = true;
        state.smeProducts.errorMessage = action.payload;
      });
  },
});

export const { productStateReset } = productSlice.actions;

export default productSlice.reducer;
