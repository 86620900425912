import { Button, Form, message, Modal, Upload } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { InboxOutlined } from "@ant-design/icons";

import { FileTypeEnum } from "../../../enum/fileType";
import { waiverFileUploadThunk } from "../../../services/fileService/fileThunk";
import { AppDispatch } from "../../../store/store";

import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
type UpdateFieldVisitModalProps = {
  callback?: any;
  entityId: any;
};

const FieldVisitFileModal: React.FC<UpdateFieldVisitModalProps> = (props) => {
  const { entityId, callback } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    setFileList([]);
    form.resetFields();
  };

  const onFinish = async () => {
    if (fileList.length < 1) {
      return message.error("No file selected");
    }

    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            const formData = new FormData();
            formData.append("entityId", entityId);
            formData.append("fileType", FileTypeEnum.FIELD_VISIT);
            formData.append("file", fileList[i] as RcFile);
            dispatch(waiverFileUploadThunk(formData))
              .unwrap()
              .then(async (res) => {
                if (callback) callback();
                message.success("Submit Successfully");
                handleCancel();
              })
              .finally(() => handleCancel());
          }
        }
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
      });
  };

  const uploadProps: UploadProps = {
    multiple: true,
    fileList,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: RcFile) => {
      fileList.push(file);
      // setFileList([...fileList, file]);
      return false;
    },
  };

  return (
    <>
      <Button onClick={showModal}>Upload</Button>
      <Modal
        title="Upload File"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading || uploading}
      >
        <Form
          form={form}
          name="uploadFieldVisitFile"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item label="Field Visit File">
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FieldVisitFileModal;
