import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import collectionService from "./collectionService";

export const getAccountDetailsThunk = createAsyncThunk(
  "collection/accountEnquiry",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAccountDetailsByIdThunk = createAsyncThunk(
  "collection/accountEnquiryById",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountDetailsById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEnglishAccountStatementbyAgreementIdThunk = createAsyncThunk(
  "collection/getEnglishAccountStatementbyAgreementId",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getEnglishAccountStatementbyAgreementId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getMalayAccountStatementbyAgreementIdThunk = createAsyncThunk(
  "collection/getMalayAccountStatementbyAgreementId",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getMalayAccountStatementbyAgreementId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAccountStatementThunk = createAsyncThunk(
  "collection/getAccountStatement",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountStatement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getMIA1ListingThunk = createAsyncThunk(
  "collection/getMIA1Listing",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getMIA1Listing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getMIA2ListingThunk = createAsyncThunk(
  "collection/getMIA2Listing",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getMIA2Listing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getMIA3ListingThunk = createAsyncThunk(
  "collection/getMIA3Listing",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getMIA3Listing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getNPLListingThunk = createAsyncThunk(
  "collection/getNPLListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getNPLListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getBPListingThunk = createAsyncThunk(
  "collection/getBPListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getBPListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPTPListingThunk = createAsyncThunk(
  "collection/getPTPListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getPTPListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDueAccountTenureDetailsThunk = createAsyncThunk(
  "collection/getAccountDueTenureDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getDueAccountTenureDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAccountTenureDetailsThunk = createAsyncThunk(
  "collection/getAccountTenureDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountTenureDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getTaggingHistoryByAccountDetailsIdThunk = createAsyncThunk(
  "collection/getTaggingHistoryByAccountDetailsId",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getTaggingHistoryByAccountDetailsId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAccountSmsHistoryThunk = createAsyncThunk(
  "collection/getAccountSmsHistory",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountSmsHistory(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAccountEmailHistoryThunk = createAsyncThunk(
  "collection/getAccountEmailHistory",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountEmailHistory(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getCollectionHistoryLogThunk = createAsyncThunk(
  "collection/getCollectionHistoryLog",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getCollectionHistoryLog(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAccountDetailsCollectionSystemLogThunk = createAsyncThunk(
  "collection/getAccountDetailsCollectionSystemLog",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountDetailsCollectionSystemLog(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRefundChargeConfigThunk = createAsyncThunk(
  "collection/getRefundChargeConfig",
  async (requestData, thunkAPI) => {
    try {
      return await collectionService.getRefundChargeConfig();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRefundChargeConfigThunk = createAsyncThunk(
  "collection/updateRefundChargeConfig",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.updateRefundChargeConfig(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSelfBilledEInvoiceSubmissionHistoryLogThunk = createAsyncThunk(
  "collection/getSelfBilledEInvoiceSubmissionHistoryLog",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getSelfBilledEInvoiceSubmissionHistoryLog(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSelfBilledEInvoiceListThunk = createAsyncThunk(
  "collection/getSelfBilledEInvoiceList",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getSelfBilledEInvoiceListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getConsolidatedEInvoiceListThunk = createAsyncThunk(
  "collection/getConsolidatedEInvoiceList",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getConsolidatedEInvoiceListing(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getIndividualCreditNoteListThunk = createAsyncThunk(
  "collection/getIndividualCreditNoteList",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getIndividualCreditNoteListing(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getConsolidatedCreditNoteListThunk = createAsyncThunk(
  "collection/getConsolidatedCreditNoteList",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getConsolidatedCreditNoteListing(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEInvoiceTotalThunk = createAsyncThunk(
  "collection/getEInvoiceTotal",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getEInvoiceTotal();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getCollectionActivitiesFixTotalThunk = createAsyncThunk(
  "collection/getCollectionActivitiesFixTotal",
  async (requestData, thunkAPI) => {
    try {
      return await collectionService.getCollectionActivitiesFixTotal();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
