import { Button, Input, Select, Table } from "antd";
import startCase from "lodash.startcase";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import { Agreement } from "../../../features/agreement/agreementSlice";
import { numberWithCommas } from "../../../helpers/number";
import { getAgreementsThunk } from "../../../services/agreementService/agreementThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import { cleanObject } from "../../../utils/object";

import type { TableProps } from "antd/es/table";
import type { ColumnsType } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";

const { Search } = Input;

const AgreementListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { agreementList } = useSelector((state: RootState) => state.agreement);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {
        // agreementStatus:  AgreementStatusEnum.PENDING,
      },
    }),
    [],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<Agreement>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");

  const tablePagination = {
    total: agreementList?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Approval Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      key: "signDate",
      title: "Agreement Signed Date",
      dataIndex: "signDate",
      sorter: (a, b) => a.signDate - b.signDate,
      sortOrder: sortedInfo.columnKey === "signDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      key: "agreementNo",
      title: "Agreement No",
      dataIndex: "agreementNo",
      // sorter: (a, b) => a.agreementNo - b.agreementNo,
      // sortOrder:
      //   sortedInfo.columnKey === "agreementNo" ? sortedInfo.order : null,
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/agreement/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      render: (_, record) => record?.application?.applicantFirstName ?? "-",
    },
    {
      key: "applicantNric",
      title: "Applicant NRIC",
      render: (_, record) => record?.application?.applicantNric ?? "-",
    },
    {
      title: "Finance Amount",
      key: "financeAmount",
      render: (_, record) =>
        record.application?.financeAmount != null
          ? `RM ${numberWithCommas(record.application?.financeAmount ?? 0)}`
          : "-",
    },
    {
      title: "Status",
      dataIndex: "agreementStatus",
      key: "agreementStatus",
      render: (text) => startCase(text),
    },
    // {
    //   title: "Net Disbursement Amount",
    //   dataIndex: "disbursementAmount",
    //   key: "disbursementAmount",
    //   render: (text, record) => (record.disbursementAmount ?? "-"),
    // },
  ];

  const fetchAgreements = async (params?: any) => {
    await dispatch(getAgreementsThunk(params));
  };

  const onSearch = (value: string) => {
    let payload;
    if (value) {
      payload = cleanObject({
        ...initialParams,
        filters: cleanObject({
          ...filterState,
        }),
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
      setSearchValue(value.toUpperCase());
    } else {
      payload = cleanObject({
        ...initialParams,
        filters: cleanObject({
          ...filterState,
        }),
      });
    }
    fetchAgreements(payload);

    setFilterState({ ...initialParams.filters });
  };

  const handleTableChange: TableProps<Agreement>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
            search: {
              [searchFiled]: searchValue.toUpperCase(),
            },
          };

    fetchAgreements(param);
    setSortedInfo(sorter as SorterResult<Agreement>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchAgreements(params);
  });

  return (
    <>
      <Helmet>
        <title>Search Agreement - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Search Agreement" />
      <Input.Group compact>
        <Select
          defaultValue={searchFiled}
          style={{ width: 200 }}
          onChange={setSearchFiled}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={agreementList?.data?.data ?? []}
        loading={agreementList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AgreementListingPage;
