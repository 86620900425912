import { createSlice } from "@reduxjs/toolkit";
import {
  getApprovedRefundListThunk,
  getOpenRefundListThunk,
  getPendingRefundListThunk,
  getReferbackRefundListThunk,
  getRefundFinanceBatchByIdThunk,
  getRefundFinanceBatchThunk,
  getRefundFinanceListThunk,
  getRefundedRefundThunk,
  getVoidRefundListThunk,
} from "../../services/refundService/refundThunk";
import {
  getOpenUnmatchedRefundListThunk,
  getPendingUnmatchedRefundListThunk,
  getReferbackUnmatchedRefundListThunk,
  getVoidUnmatchedRefundListThunk,
  getApprovedUnmatchedRefundListThunk,
  getUnmatchedRefundedRefundThunk,
} from "../../services/unmatchedService/unmatchedThunk";

export type Refund = {
  id: string;
  agreementNo: string;
  contactPhoneNo?: string;
  applicantNric?: string;
  bank?: string;
  bankAccountNo?: string;
  paymentMethod?: string;
  extraPaymentAmount?: number;
  refundAdminCharge?: number;
  refundAmount?: number;
  remark?: string;
  status?: string;
  accountDetailsId?: string;
  createdAt: Date;
  updatedAt: Date;
};

export type RefundFinance = {
  id: string;
  postDate?: Date;
  refundId?: string;
  applicantNric?: string;
  agreementNo?: string;
  accountDetailsId?: string;
  payAmount?: number;
  status?: string;
  refund: Refund;
  unmatchedRefundId?: string;
};

export type refundDataResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: Refund[];
  searchTotal: number;
};

export type refundFinanceDataResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: RefundFinance[];
  searchTotal: number;
};

export type refundDataListingContent = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: any;
  data: refundDataResponse;
};

export type refundDataListing = {
  openRefund: refundDataListingContent;
  pendingRefund: refundDataListingContent;
  referbackRefund: refundDataListingContent;
  voidRefund: refundDataListingContent;
  approvedRefund: refundDataListingContent;
  refundedRefund: refundDataListingContent;
};

export type RefundFinanceBatch = {
  id: string;
  createdAt: string;
  status: string;
};

export type refundFinanceBatchResponse = {
  total: number;
  searchTotal: number;
  currentPage: number;
  pageSize: number;
  data: RefundFinanceBatch[];
};
export type refundFinanceBatchDetails = {
  id: string;
  createdAt: string;
  status: string;
  batchNo: string;
  isDownloaded?: boolean;
  refundFinance: RefundFinance[];
  unmatchedRefundFinance?: RefundFinance[];
};

export type refundState = {
  refundListing: refundDataListing;
  unmatchRefundListing: refundDataListing;
  refundFinance: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: refundFinanceDataResponse;
  };
  refundFinanceBatch: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: refundFinanceBatchResponse;
  };
  refundFinanceBatchDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: refundFinanceBatchDetails;
  };
};

const initialRefundDataListingContent: refundDataListingContent = {
  isLoading: false,
  isError: false,
  errorMessage: "",
  data: {
    total: 0,
    currentPage: 0,
    pageSize: 0,
    data: [],
    searchTotal: 0,
  },
};

const initialRefundDataListing: refundDataListing = {
  openRefund: initialRefundDataListingContent,
  pendingRefund: initialRefundDataListingContent,
  referbackRefund: initialRefundDataListingContent,
  voidRefund: initialRefundDataListingContent,
  approvedRefund: initialRefundDataListingContent,
  refundedRefund: initialRefundDataListingContent,
};

const initialState: refundState = {
  refundListing: initialRefundDataListing,
  unmatchRefundListing: initialRefundDataListing,
  refundFinance: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
  refundFinanceBatch: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      searchTotal: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  refundFinanceBatchDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      id: "",
      createdAt: "",
      status: "",
      batchNo: "",
      refundFinance: [],
    },
  },
};

export const refundSlice = createSlice({
  name: "refund",
  initialState,
  reducers: {
    refundStateReset: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOpenRefundListThunk.pending, (state) => {
        state.refundListing.openRefund.isLoading = true;
      })
      .addCase(getOpenRefundListThunk.fulfilled, (state, action) => {
        state.refundListing.openRefund.isLoading = false;
        state.refundListing.openRefund.data = action.payload;
      })
      .addCase(getOpenRefundListThunk.rejected, (state, action) => {
        state.refundListing.openRefund.isLoading = false;
        state.refundListing.openRefund.isError = true;
        state.refundListing.openRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingRefundListThunk.pending, (state) => {
        state.refundListing.pendingRefund.isLoading = true;
      })
      .addCase(getPendingRefundListThunk.fulfilled, (state, action) => {
        state.refundListing.pendingRefund.isLoading = false;
        state.refundListing.pendingRefund.data = action.payload;
      })
      .addCase(getPendingRefundListThunk.rejected, (state, action) => {
        state.refundListing.pendingRefund.isLoading = false;
        state.refundListing.pendingRefund.isError = true;
        state.refundListing.pendingRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getReferbackRefundListThunk.pending, (state) => {
        state.refundListing.referbackRefund.isLoading = true;
      })
      .addCase(getReferbackRefundListThunk.fulfilled, (state, action) => {
        state.refundListing.referbackRefund.isLoading = false;
        state.refundListing.referbackRefund.data = action.payload;
      })
      .addCase(getReferbackRefundListThunk.rejected, (state, action) => {
        state.refundListing.referbackRefund.isLoading = false;
        state.refundListing.referbackRefund.isError = true;
        state.refundListing.referbackRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getVoidRefundListThunk.pending, (state) => {
        state.refundListing.voidRefund.isLoading = true;
      })
      .addCase(getVoidRefundListThunk.fulfilled, (state, action) => {
        state.refundListing.voidRefund.isLoading = false;
        state.refundListing.voidRefund.data = action.payload;
      })
      .addCase(getVoidRefundListThunk.rejected, (state, action) => {
        state.refundListing.voidRefund.isLoading = false;
        state.refundListing.voidRefund.isError = true;
        state.refundListing.voidRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getApprovedRefundListThunk.pending, (state) => {
        state.refundListing.approvedRefund.isLoading = true;
      })
      .addCase(getApprovedRefundListThunk.fulfilled, (state, action) => {
        state.refundListing.approvedRefund.isLoading = false;
        state.refundListing.approvedRefund.data = action.payload;
      })
      .addCase(getApprovedRefundListThunk.rejected, (state, action) => {
        state.refundListing.approvedRefund.isLoading = false;
        state.refundListing.approvedRefund.isError = true;
        state.refundListing.approvedRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getRefundedRefundThunk.pending, (state) => {
        state.refundListing.refundedRefund.isLoading = true;
      })
      .addCase(getRefundedRefundThunk.fulfilled, (state, action) => {
        state.refundListing.refundedRefund.isLoading = false;
        state.refundListing.refundedRefund.data = action.payload;
      })
      .addCase(getRefundedRefundThunk.rejected, (state, action) => {
        state.refundListing.refundedRefund.isLoading = false;
        state.refundListing.refundedRefund.isError = true;
        state.refundListing.refundedRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getOpenUnmatchedRefundListThunk.pending, (state) => {
        state.unmatchRefundListing.openRefund.isLoading = true;
      })
      .addCase(getOpenUnmatchedRefundListThunk.fulfilled, (state, action) => {
        state.unmatchRefundListing.openRefund.isLoading = false;
        state.unmatchRefundListing.openRefund.data = action.payload;
      })
      .addCase(getOpenUnmatchedRefundListThunk.rejected, (state, action) => {
        state.unmatchRefundListing.openRefund.isLoading = false;
        state.unmatchRefundListing.openRefund.isError = true;
        state.unmatchRefundListing.openRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingUnmatchedRefundListThunk.pending, (state) => {
        state.unmatchRefundListing.pendingRefund.isLoading = true;
      })
      .addCase(
        getPendingUnmatchedRefundListThunk.fulfilled,
        (state, action) => {
          state.unmatchRefundListing.pendingRefund.isLoading = false;
          state.unmatchRefundListing.pendingRefund.data = action.payload;
        },
      )
      .addCase(getPendingUnmatchedRefundListThunk.rejected, (state, action) => {
        state.unmatchRefundListing.pendingRefund.isLoading = false;
        state.unmatchRefundListing.pendingRefund.isError = true;
        state.unmatchRefundListing.pendingRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getReferbackUnmatchedRefundListThunk.pending, (state) => {
        state.unmatchRefundListing.referbackRefund.isLoading = true;
      })
      .addCase(
        getReferbackUnmatchedRefundListThunk.fulfilled,
        (state, action) => {
          state.unmatchRefundListing.referbackRefund.isLoading = false;
          state.unmatchRefundListing.referbackRefund.data = action.payload;
        },
      )
      .addCase(
        getReferbackUnmatchedRefundListThunk.rejected,
        (state, action) => {
          state.unmatchRefundListing.referbackRefund.isLoading = false;
          state.unmatchRefundListing.referbackRefund.isError = true;
          state.unmatchRefundListing.referbackRefund.errorMessage =
            action.payload;
        },
      );

    builder
      .addCase(getVoidUnmatchedRefundListThunk.pending, (state) => {
        state.unmatchRefundListing.voidRefund.isLoading = true;
      })
      .addCase(getVoidUnmatchedRefundListThunk.fulfilled, (state, action) => {
        state.unmatchRefundListing.voidRefund.isLoading = false;
        state.unmatchRefundListing.voidRefund.data = action.payload;
      })
      .addCase(getVoidUnmatchedRefundListThunk.rejected, (state, action) => {
        state.unmatchRefundListing.voidRefund.isLoading = false;
        state.unmatchRefundListing.voidRefund.isError = true;
        state.unmatchRefundListing.voidRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getApprovedUnmatchedRefundListThunk.pending, (state) => {
        state.unmatchRefundListing.approvedRefund.isLoading = true;
      })
      .addCase(
        getApprovedUnmatchedRefundListThunk.fulfilled,
        (state, action) => {
          state.unmatchRefundListing.approvedRefund.isLoading = false;
          state.unmatchRefundListing.approvedRefund.data = action.payload;
        },
      )
      .addCase(
        getApprovedUnmatchedRefundListThunk.rejected,
        (state, action) => {
          state.unmatchRefundListing.approvedRefund.isLoading = false;
          state.unmatchRefundListing.approvedRefund.isError = true;
          state.unmatchRefundListing.approvedRefund.errorMessage =
            action.payload;
        },
      );

    builder
      .addCase(getUnmatchedRefundedRefundThunk.pending, (state) => {
        state.unmatchRefundListing.refundedRefund.isLoading = true;
      })
      .addCase(getUnmatchedRefundedRefundThunk.fulfilled, (state, action) => {
        state.unmatchRefundListing.refundedRefund.isLoading = false;
        state.unmatchRefundListing.refundedRefund.data = action.payload;
      })
      .addCase(getUnmatchedRefundedRefundThunk.rejected, (state, action) => {
        state.unmatchRefundListing.refundedRefund.isLoading = false;
        state.unmatchRefundListing.refundedRefund.isError = true;
        state.unmatchRefundListing.refundedRefund.errorMessage = action.payload;
      });

    builder
      .addCase(getRefundFinanceListThunk.pending, (state) => {
        state.refundFinance.isLoading = true;
      })
      .addCase(getRefundFinanceListThunk.fulfilled, (state, action) => {
        state.refundFinance.isLoading = false;
        state.refundFinance.data = action.payload;
      })
      .addCase(getRefundFinanceListThunk.rejected, (state, action) => {
        state.refundFinance.isLoading = false;
        state.refundFinance.isError = true;
        state.refundFinance.errorMessage = action.payload;
      });

    builder
      .addCase(getRefundFinanceBatchThunk.pending, (state) => {
        state.refundFinanceBatch.isLoading = true;
      })
      .addCase(getRefundFinanceBatchThunk.fulfilled, (state, action) => {
        state.refundFinanceBatch.isLoading = false;
        state.refundFinanceBatch.data = action.payload;
      })
      .addCase(getRefundFinanceBatchThunk.rejected, (state, action) => {
        state.refundFinanceBatch.isLoading = false;
        state.refundFinanceBatch.isError = true;
      });

    builder
      .addCase(getRefundFinanceBatchByIdThunk.pending, (state) => {
        state.refundFinanceBatchDetails.isLoading = true;
      })
      .addCase(getRefundFinanceBatchByIdThunk.fulfilled, (state, action) => {
        state.refundFinanceBatchDetails.isLoading = false;
        state.refundFinanceBatchDetails.data = action.payload;
      })
      .addCase(getRefundFinanceBatchByIdThunk.rejected, (state, action) => {
        state.refundFinanceBatchDetails.isLoading = false;
        state.refundFinanceBatchDetails.isError = true;
      });
  },
});

export const { refundStateReset } = refundSlice.actions;

export default refundSlice.reducer;
