import "./index.less";

import { Button, Divider, message } from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DownloadOutlined } from "@ant-design/icons";

import { FileTypeEnum } from "../../../enum/fileType";
import {
  getApplicationExperianReportThunk,
  regenerateApplicationFormThunk,
} from "../../../services/applicationService/applicationThunk";
import {
  getWiseAiResultByApplicationIdThunk,
  getWiseAiResultByUserIdThunk,
} from "../../../services/ekycService/ekycThunk";
import { getEkycFilesByEntityIdThunk } from "../../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../../store/store";
import UploadedDocument from "../../UploadedDocument";
import { ApplicationStatusEnum } from "../../../enum/applicationStepStatus";

const AttachmentTab = () => {
  const [experianReportType, setExperianReportType] = useState<string>("");
  const [applicationFormFileList, setApplicationFormFileList] = useState<
    {
      id: string | null;
      filename: string;
    }[]
  >([]);
  const [experianReportFileList, setExperianReportFileList] = useState<
    {
      id: string | null;
      filename: string;
    }[]
  >([]);
  const dispatch = useDispatch<AppDispatch>();
  const { applicationDetails, ekycFiles } = useSelector(
    (state: RootState) => state.application,
  );

  const getExperianHTMLReport = (
    type: "IRISS" | "SPKCCS" | "NRVB" | "AMLA",
    name: string,
  ) => {
    const fileId =
      type === "NRVB"
        ? applicationDetails.data?.experianNvrbFileId
        : type === "AMLA"
        ? applicationDetails.data?.experianAmlaFileId
        : type === "IRISS"
        ? applicationDetails.data?.experianIrissFileId
        : applicationDetails.data?.experianSpkccsFileId;

    dispatch(
      getApplicationExperianReportThunk({
        fileId: fileId,
        newIc: applicationDetails.data?.applicantNric,
        params: { type },
      }),
    )
      .unwrap()
      .then((res) => {
        // const htmlReport = parse(res);
        const newWindow = window.open("", "_blank");
        if (newWindow != null) {
          newWindow.document.title = name;
          newWindow.document.write(res);
          newWindow.document.close();
        }
      });
  };

  const onGenerateApplicationForm = () => {
    dispatch(
      regenerateApplicationFormThunk({
        applicationId: applicationDetails.data?.id,
      }),
    )
      .unwrap()
      .then((res) => {
        // todo check if response return file id
        // now dont have response yet
        // for now need refresh after 10 seconds to get the new application form
        // const data = {
        //   id: res?.id,
        //   filename: "Application Form",
        // };
        // setApplicationFormFileList([data]);
        message.success("Application form generated successfully");
      });
  };

  useEffect(() => {
    // ekyc files
    dispatch(
      getWiseAiResultByApplicationIdThunk({
        applicationId: applicationDetails?.data?.id,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res?.length > 0 && res[0] != null) {
          dispatch(getEkycFilesByEntityIdThunk({ entityId: res[0]?.id }));
        }
      });

    // application form
    if (applicationDetails?.data?.applicationFormFileId) {
      const data = {
        id: applicationDetails?.data?.applicationFormFileId,
        filename: "Application Form",
      };
      setApplicationFormFileList([data]);
    }

    // experian report
    if (
      applicationDetails?.data?.experianIrissFileId ||
      applicationDetails?.data?.experianSpkccsFileId
    ) {
      const reportId =
        applicationDetails?.data?.experianIrissFileId ||
        applicationDetails?.data?.experianSpkccsFileId;

      const data = {
        id: applicationDetails.data?.id ?? "",
        filename: applicationDetails?.data?.experianIrissFileId
          ? "Experian IRISS Report"
          : "Experian SPKCCS Report",
      };
      setExperianReportFileList([data]);

      setExperianReportType(
        applicationDetails?.data?.experianIrissFileId ? "IRISS" : "SPKCCS",
      );
    }
  }, [applicationDetails?.data?.userId]);

  return (
    <div className="info-container">
      {ekycFiles?.data?.length > 0 && (
        <>
          <UploadedDocument
            key={"1"}
            description="NRIC Front & Back"
            defaultFileList={ekycFiles?.data}
            loading={ekycFiles?.isLoading}
            readOnly
            fileType={FileTypeEnum.EKYCFILES}
          />
          <Divider className="my-4" />
        </>
      )}

      <>
        <UploadedDocument
          key={"2"}
          description="NRVB Report"
          defaultFileList={[
            {
              id: applicationDetails.data?.id ?? "",
              filename: "Experian NRVB Report",
            },
          ]}
          readOnly
          fileType={FileTypeEnum.NRVB}
          customDownloadButton={
            <Button
              key={nanoid()}
              icon={<DownloadOutlined />}
              onClick={() =>
                getExperianHTMLReport("NRVB", "Experian NRVB Report")
              }
            >
              Download
            </Button>
          }
          fileId={applicationDetails.data?.experianNvrbFileId}
          newIc={applicationDetails.data?.applicantNric}
        />

        <Divider className="my-4" />
      </>

      {applicationDetails?.data?.experianAmlaFileId && (
        <>
          <UploadedDocument
            key={"2"}
            description="AMLA Report"
            defaultFileList={[
              {
                id: applicationDetails.data?.id ?? "",
                filename: "Experian AMLA Report",
              },
            ]}
            readOnly
            fileType={FileTypeEnum.AMLA}
            customDownloadButton={
              <Button
                key={nanoid()}
                icon={<DownloadOutlined />}
                onClick={() =>
                  getExperianHTMLReport("AMLA", "Experian AMLA Report")
                }
              >
                Download
              </Button>
            }
            fileId={applicationDetails.data?.experianAmlaFileId}
            newIc={applicationDetails.data?.applicantNric}
          />
          <Divider className="my-4" />
        </>
      )}

      {experianReportType === "IRISS" && (
        <>
          <UploadedDocument
            key={"2"}
            description={`IRISS Report (${applicationDetails?.data?.numberOfCcrisReport})`}
            defaultFileList={experianReportFileList}
            readOnly
            fileType={FileTypeEnum.IRISS}
            customDownloadButton={
              <Button
                key={nanoid()}
                icon={<DownloadOutlined />}
                onClick={() =>
                  getExperianHTMLReport("IRISS", "Experian IRISS Report")
                }
              >
                Download
              </Button>
            }
            fileId={applicationDetails.data?.experianIrissFileId}
            newIc={applicationDetails.data?.applicantNric}
          />
          <Divider className="my-4" />
        </>
      )}

      {experianReportType === "SPKCCS" && (
        <>
          <UploadedDocument
            key={"3"}
            description={`SPKCCS Report (${applicationDetails?.data?.numberOfCcrisReport})`}
            defaultFileList={experianReportFileList}
            readOnly
            fileType={FileTypeEnum.SPKCCS}
            fileId={applicationDetails.data?.experianSpkccsFileId}
            newIc={applicationDetails.data?.applicantNric}
          />
          <Divider className="my-4" />
        </>
      )}

      {applicationFormFileList.length > 0 && (
        <>
          <UploadedDocument
            key={"4"}
            description="Application Form"
            defaultFileList={applicationFormFileList}
            readOnly
            fileType={FileTypeEnum.APPLICATIONFILES}
          />

          <Button
            key={nanoid()}
            className="my-3"
            onClick={() => onGenerateApplicationForm()}
          >
            Regenerate Application Form
          </Button>
        </>
      )}
      {applicationFormFileList.length <= 0 &&
        applicationDetails?.data?.applicationStatus !==
          ApplicationStatusEnum.DRAFT && (
          <>
            <h4>Application Form</h4>
            <Button
              key={nanoid()}
              className="my-3"
              onClick={() => onGenerateApplicationForm()}
            >
              Generate Application Form
            </Button>
          </>
        )}
    </div>
  );
};

export default AttachmentTab;
