import { createSlice } from "@reduxjs/toolkit";
import {
  getHistoryLogsBySmeApplicationIdThunk,
  getPendingSmeApplicationsListingThunk,
  getReferBackSmeApplicationsListingThunk,
  getSmeApplicationCountThunk,
  getSmeApplicationDetailsThunk,
  getSmeApplicationRemarksThunk,
  getSmeApplicationsListingThunk,
  getSmePartnerCommissionResultsThunk,
  smeGlobalSearchThunk,
} from "../../services/smeApplicationService/smeApplicationThunk";
import {
  SmeCompanyDirector,
  SmePartnershipDirector,
} from "./smeApplicationDirectorsSlice";
import { SmeAgreement } from "../smeAgreement/smeAgreementSlice";
import {
  SmePartner,
  SmePartnerCommissionResultResponse,
} from "../smePartner/smePartnerSlice";
import { SME_ORGANIZATION_TYPE } from "../../enum/smeApplication";
import { getEkycFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import { UploadedFile } from "../waiver/waiverSlice";
import { HistoryLog } from "../application/applicationSlice";
import { getHistoryLogsByApplicationIDAndAgreementIDThunk } from "../../services/applicationService/applicationThunk";
import { SME_APPLICATION_STATUS } from "../../enum/smeApplicationStatus";

export type SmeCreditRecord = {
  id: string;
  smeApplicationId: string;
  cddiStatus: string;
  nrvbStatus: string;
  ssmStatus: string;
  cddcStatus: string;
  orStatus: string;
  eCcrisStatus: string;
  ciStatus: string;
};

export type SmeApplication = {
  id: string;
  smePartnerId: string;
  status: SME_APPLICATION_STATUS;
  redOneUsername: string;
  applicationNo: string;
  organizationType: SME_ORGANIZATION_TYPE;
  appliedLoanInterestRate: number;
  appliedLoanTenure: number;
  appliedLoanAmount: number;
  loanReason: string;
  financeAmount: number;
  submissionDate?: string;
  processingFee: number;
  processingFeeToDiscount: number;
  productProcessingFee?: number;
  stampingFee: number;
  stampingDutyPercentage: number;
  promoCode?: string;
  dsr?: number;
  smeIndividualApplicationId?: string;
  smeSolePropApplicationId?: string;
  smePartnershipApplicationId?: string;
  smeCompanyApplicationId?: string;
  smeAgreementId?: string;
  smeIndividualApplication: SmeIndividualApplication;
  smeSolePropApplication: SmeSolePropApplication;
  smePartnershipApplication: SmePartnershipApplication;
  smeCompanyApplication: SmeCompanyApplication;
  createdAt: string;
  updatedAt: string;
  acceptMarketingConsent: boolean;
  smeAgreement?: SmeAgreement;
  smePartner: SmePartner;
  smeCreditRecords?: SmeCreditRecord;
};

export type SmeIndividualApplication = {
  id: string;
  smeApplicationId: string;
  smeApplication: SmeApplication;
  personalBankName: string;
  personalBankAccountName: string;
  personalBankAccountNo: string;
  name: string;
  nric: string;
  icType: string;
  emailAddress: string;
  homeAddress1: string;
  homeAddress2: string;
  homeAddress3: string;
  homePostcode: string;
  homeCity: string;
  homeState: string;
  nricAddress1: string;
  nricAddress2: string;
  nricAddress3: string;
  nricPostcode: string;
  nricCity: string;
  nricState: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingAddress3: string;
  mailingPostcode: string;
  mailingCity: string;
  mailingState: string;
  dateOfBirth: string;
  nationality: string;
  occupationType: string;
  employerName: string;
  applicantPhoneNo: string;
  applicantRace: string;
  applicantEmploymentType: string;
  emergencyContactPersonName: string;
  emergencyContactPersonPhoneNo: string;
  emergencyContactEmail: string;
  emergencyContactRelationship: string;
  createdAt: string;
  updatedAt: string;
};

export type SmeSolePropApplication = {
  id: string;
  smeApplicationId: string;
  smeApplication: SmeApplication;
  companyName: string;
  companyEmail: string;
  businessOldRegistrationNo: string;
  businessNewRegistrationNo: string;
  natureOfBusiness: string;
  businessContactNo: string;
  businessAddress1: string;
  businessAddress2: string;
  businessAddress3: string;
  businessPostcode: string;
  businessCity: string;
  businessState: string;
  registeredSsmAddress1: string;
  registeredSsmAddress2: string;
  registeredSsmAddress3: string;
  registeredSsmPostcode: string;
  registeredSsmCity: string;
  registeredSsmState: string;
  personalBankName: string;
  personalBankAccountName: string;
  personalBankAccountNo: string;
  name: string;
  nric: string;
  icType: string;
  emailAddress: string;
  homeAddress1: string;
  homeAddress2: string;
  homeAddress3: string;
  homePostcode: string;
  homeCity: string;
  homeState: string;
  nricAddress1: string;
  nricAddress2: string;
  nricAddress3: string;
  nricPostcode: string;
  nricCity: string;
  nricState: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingAddress3: string;
  mailingPostcode: string;
  mailingCity: string;
  mailingState: string;
  dateOfBirth: string;
  nationality: string;
  occupationType: string;
  employerName: string;
  countryOfIncorporation: string;
  nameOfSeniorManagement: string;
  applicantPhoneNo: string;
  applicantRace: string;
  applicantEmploymentType: string;
  emergencyContactPersonName: string;
  emergencyContactPersonPhoneNo: string;
  emergencyContactEmail: string;
  emergencyContactRelationship: string;
  createdAt: string;
  updatedAt: string;
};

export type SmePartnershipApplication = {
  id: string;
  smeApplicationId: string;
  smeApplication: SmeApplication;
  smePartnershipAgreementId: string;
  companyName: string;
  companyEmail: string;
  businessOldRegistrationNo: string;
  businessNewRegistrationNo: string;
  natureOfBusiness: string;
  businessContactNo: string;
  businessAuthorisedAddress1: string;
  businessAuthorisedAddress2: string;
  businessAuthorisedAddress3: string;
  businessAuthorisedPostcode: string;
  businessAuthorisedCity: string;
  businessAuthorisedState: string;
  personalBankName: string;
  personalBankAccountName: string;
  personalBankAccountNo: string;
  name: string;
  nric: string;
  icType: string;
  emailAddress: string;
  homeAddress1: string;
  homeAddress2: string;
  homeAddress3: string;
  homePostcode: string;
  homeCity: string;
  homeState: string;
  nricAddress1: string;
  nricAddress2: string;
  nricAddress3: string;
  nricPostcode: string;
  nricCity: string;
  nricState: string;
  registeredSsmAddress1: string;
  registeredSsmAddress2: string;
  registeredSsmAddress3: string;
  registeredSsmPostcode: string;
  registeredSsmCity: string;
  registeredSsmState: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingAddress3: string;
  mailingPostcode: string;
  mailingCity: string;
  mailingState: string;
  dateOfBirth: string;
  nationality: string;
  occupationType: string;
  employerName: string;
  countryOfIncorporation: string;
  nameOfSeniorManagement: string;
  applicantPhoneNo: string;
  applicantRace: string;
  applicantEmploymentType: string;
  emergencyContactPersonName: string;
  emergencyContactPersonPhoneNo: string;
  emergencyContactEmail: string;
  emergencyContactRelationship: string;
  smePartnershipDirectors: SmePartnershipDirector[];
};

export type SmeCompanyApplication = {
  id: string;
  smeApplicationId: string;
  smeApplication: SmeApplication;
  companyName: string;
  companyEmail: string;
  shareholdingStatus: string;
  businessOldRegistrationNo: string;
  businessNewRegistrationNo: string;
  natureOfBusiness: string;
  businessContactNo: string;
  businessAuthorisedAddress1: string;
  businessAuthorisedAddress2: string;
  businessAuthorisedAddress3: string;
  businessAuthorisedPostcode: string;
  businessAuthorisedCity: string;
  businessAuthorisedState: string;
  businessAddress1: string;
  businessAddress2: string;
  businessAddress3: string;
  businessPostcode: string;
  businessCity: string;
  businessState: string;
  registeredSsmAddress1: string;
  registeredSsmAddress2: string;
  registeredSsmAddress3: string;
  registeredSsmPostcode: string;
  registeredSsmCity: string;
  registeredSsmState: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNo: string;
  smeCompanyDirectors: SmeCompanyDirector[];
};

export type SmeApplicationRemarks = {
  id: string;
  smeApplicationId: string;
  remarks: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
};

export type SmeApplicationRemarksListing = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: SmeApplicationRemarks[];
};

export type SmeApplicationListing = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: SmeApplication[];
};

export type SmeApplicationCount = {
  pending: number;
  submittedForVerifier2: number;
  submittedForApproval1: number;
  submittedForApproval2: number;
  approved: number;
  referBack: number;
  rejected: number;
  canceled: number;
  referbackFromPendingDisbursement: number;
  followUp: number;
};

export type globalSearchApplicationList = {
  data: SmeApplicationListing;
  isLoading: boolean;
  isError: boolean;
  errorMessage: any;
};

export type SmeApplicationState = {
  smeApplicationCount: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeApplicationCount;
  };
  pendingSmeApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeApplicationListing;
  };
  referbackSmeApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeApplicationListing;
  };
  smeApplicationsList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeApplicationListing;
  };
  smeApplicationDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeApplication;
  };
  smeApplicationRemarks: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeApplicationRemarksListing;
  };
  ekycFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: UploadedFile[];
  };
  smeHistoryLogs: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: HistoryLog[];
  };
  smePartnerCommissionResult: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: SmePartnerCommissionResultResponse[];
  };
  globalSearchApplicationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: SmeApplicationListing;
  };
  globalSearchValue: {
    searchType: string;
    value: string;
  };
};

const initialState: SmeApplicationState = {
  smeApplicationCount: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      pending: 0,
      submittedForApproval1: 0,
      submittedForApproval2: 0,
      approved: 0,
      referBack: 0,
      rejected: 0,
      canceled: 0,
      referbackFromPendingDisbursement: 0,
      followUp: 0,
    } as SmeApplicationCount,
  },
  pendingSmeApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  referbackSmeApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  smeApplicationsList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  smeApplicationDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {} as SmeApplication,
  },
  smeApplicationRemarks: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  ekycFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  smeHistoryLogs: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  smePartnerCommissionResult: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  globalSearchApplicationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  globalSearchValue: {
    searchType: "applicationNo",
    value: "",
  },
};

export const smeApplicationSlice = createSlice({
  name: "smeApplication",
  initialState,
  reducers: {
    smeApplicationStateReset: () => initialState,
    smeApplicationDetailsStateReset: (state) => {
      state.smeApplicationDetails = initialState.smeApplicationDetails;
    },
    setSmeApplicationDetails: (state, action) => {
      state.smeApplicationDetails.data = {
        ...action.payload,
        smeCreditRecords: action.payload.smeCreditRecords[0],
      };
    },
    setGlobalSearchValue: (state, action) => {
      state.globalSearchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmeApplicationCountThunk.pending, (state) => {
        state.smeApplicationCount.isLoading = true;
      })
      .addCase(getSmeApplicationCountThunk.fulfilled, (state, action) => {
        state.smeApplicationCount.isLoading = false;
        state.smeApplicationCount.data = action.payload;
      })
      .addCase(getSmeApplicationCountThunk.rejected, (state, action) => {
        state.smeApplicationCount.isLoading = false;
        state.smeApplicationCount.isError = true;
        state.smeApplicationCount.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingSmeApplicationsListingThunk.pending, (state) => {
        state.pendingSmeApplicationList.isLoading = true;
      })
      .addCase(
        getPendingSmeApplicationsListingThunk.fulfilled,
        (state, action) => {
          state.pendingSmeApplicationList.isLoading = false;
          state.pendingSmeApplicationList.data = action.payload;
        },
      )
      .addCase(
        getPendingSmeApplicationsListingThunk.rejected,
        (state, action) => {
          state.pendingSmeApplicationList.isLoading = false;
          state.pendingSmeApplicationList.isError = true;
          state.pendingSmeApplicationList.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getReferBackSmeApplicationsListingThunk.pending, (state) => {
        state.referbackSmeApplicationList.isLoading = true;
      })
      .addCase(
        getReferBackSmeApplicationsListingThunk.fulfilled,
        (state, action) => {
          state.referbackSmeApplicationList.isLoading = false;
          state.referbackSmeApplicationList.data = action.payload;
        },
      )
      .addCase(
        getReferBackSmeApplicationsListingThunk.rejected,
        (state, action) => {
          state.referbackSmeApplicationList.isLoading = false;
          state.referbackSmeApplicationList.isError = true;
          state.referbackSmeApplicationList.errorMessage = action.payload;
        },
      );
    builder
      .addCase(getSmeApplicationsListingThunk.pending, (state) => {
        state.smeApplicationsList.isLoading = true;
      })
      .addCase(getSmeApplicationsListingThunk.fulfilled, (state, action) => {
        state.smeApplicationsList.isLoading = false;
        state.smeApplicationsList.data = action.payload;
      })
      .addCase(getSmeApplicationsListingThunk.rejected, (state, action) => {
        state.smeApplicationsList.isLoading = false;
        state.smeApplicationsList.isError = true;
        state.smeApplicationsList.errorMessage = action.payload;
      });
    builder
      .addCase(getSmeApplicationDetailsThunk.pending, (state) => {
        state.smeApplicationDetails.isLoading = true;
      })
      .addCase(getSmeApplicationDetailsThunk.fulfilled, (state, action) => {
        state.smeApplicationDetails.isLoading = false;
        state.smeApplicationDetails.data = {
          ...action.payload,
          smeCreditRecords: action.payload.smeCreditRecords[0],
        };
      })
      .addCase(getSmeApplicationDetailsThunk.rejected, (state, action) => {
        state.smeApplicationDetails.isLoading = false;
        state.smeApplicationDetails.isError = true;
        state.smeApplicationDetails.errorMessage = action.payload;
      });
    builder
      .addCase(getSmeApplicationRemarksThunk.pending, (state) => {
        state.smeApplicationRemarks.isLoading = true;
      })
      .addCase(getSmeApplicationRemarksThunk.fulfilled, (state, action) => {
        state.smeApplicationRemarks.isLoading = false;
        state.smeApplicationRemarks.data = action.payload;
      })
      .addCase(getSmeApplicationRemarksThunk.rejected, (state, action) => {
        state.smeApplicationRemarks.isLoading = false;
        state.smeApplicationRemarks.isError = true;
        state.smeApplicationRemarks.errorMessage = action.payload;
      });
    builder
      .addCase(getEkycFilesByEntityIdThunk.pending, (state) => {
        state.ekycFiles.isLoading = true;
      })
      .addCase(getEkycFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.ekycFiles.isLoading = false;
        state.ekycFiles.data = action.payload;
      })
      .addCase(getEkycFilesByEntityIdThunk.rejected, (state, action) => {
        state.ekycFiles.isLoading = false;
        state.ekycFiles.isError = true;
        state.ekycFiles.errorMessage = action.payload;
      });
    builder
      .addCase(getHistoryLogsBySmeApplicationIdThunk.pending, (state) => {
        state.smeHistoryLogs.isLoading = true;
      })
      .addCase(
        getHistoryLogsBySmeApplicationIdThunk.fulfilled,
        (state, action) => {
          state.smeHistoryLogs.isLoading = false;
          state.smeHistoryLogs.data = action.payload;
        },
      )
      .addCase(
        getHistoryLogsBySmeApplicationIdThunk.rejected,
        (state, action) => {
          state.smeHistoryLogs.isLoading = false;
          state.smeHistoryLogs.isError = true;
          state.smeHistoryLogs.errorMessage = action.payload;
        },
      );

    builder
      .addCase(
        getHistoryLogsByApplicationIDAndAgreementIDThunk.pending,
        (state) => {
          state.smeHistoryLogs.isLoading = true;
        },
      )
      .addCase(
        getHistoryLogsByApplicationIDAndAgreementIDThunk.fulfilled,
        (state, action) => {
          state.smeHistoryLogs.isLoading = false;
          state.smeHistoryLogs.data = action.payload;
        },
      )
      .addCase(
        getHistoryLogsByApplicationIDAndAgreementIDThunk.rejected,
        (state, action) => {
          state.smeHistoryLogs.isLoading = false;
          state.smeHistoryLogs.isError = true;
          state.smeHistoryLogs.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getSmePartnerCommissionResultsThunk.pending, (state) => {
        state.smePartnerCommissionResult.isLoading = true;
      })
      .addCase(
        getSmePartnerCommissionResultsThunk.fulfilled,
        (state, action) => {
          state.smePartnerCommissionResult.isLoading = false;
          state.smePartnerCommissionResult.data = action.payload;
        },
      )
      .addCase(
        getSmePartnerCommissionResultsThunk.rejected,
        (state, action) => {
          state.smePartnerCommissionResult.isLoading = false;
          state.smePartnerCommissionResult.isError = true;
          state.smePartnerCommissionResult.errorMessage = action.payload;
        },
      );

    builder
      .addCase(smeGlobalSearchThunk.pending, (state) => {
        state.globalSearchApplicationList.isLoading = true;
      })
      .addCase(smeGlobalSearchThunk.fulfilled, (state, action) => {
        state.globalSearchApplicationList.isLoading = false;
        state.globalSearchApplicationList.data = action.payload;
      })
      .addCase(smeGlobalSearchThunk.rejected, (state, action) => {
        state.globalSearchApplicationList.isLoading = false;
        state.globalSearchApplicationList.isError = true;
        state.globalSearchApplicationList.errorMessage = action.payload;
      });
  },
});

export const {
  smeApplicationStateReset,
  smeApplicationDetailsStateReset,
  setSmeApplicationDetails,
} = smeApplicationSlice.actions;
export default smeApplicationSlice.reducer;
