import { UploadFile } from "antd";

import { FileTypeEnum } from "../enum/fileType";
import { downloadLink, previewNewPage } from "../helpers/downloadLink";
import {
  getApplicationFormByIdThunk,
  getEkycFileByIdThunk,
  getExperianReportByIdThunk,
  getFileByIdThunk,
  getWaiverFileByIdThunk,
} from "../services/fileService/fileThunk";
import { getApplicationExperianReportThunk } from "../services/applicationService/applicationThunk";

export const handlePreview = async (
  file: UploadFile,
  setDownloading: (value: boolean) => void,
  dispatch: any,
  fileType?: FileTypeEnum,
  fileID?: string,
  newIc?: string,
  isSmeType?: boolean
) => {
  setDownloading(true);

  if (fileType === FileTypeEnum.EKYCFILES) {
    await dispatch(getEkycFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then((res: any) =>
        previewNewPage({
          ...res,
          fileId: file.uid,
          defaultUrl:
            process.env.REACT_APP_EKYC_SETUP_URL +
            "/redCash/api/v1.0/previewFile",
        }),
      )
      .finally(() => setDownloading(false));
  } else if (fileType === FileTypeEnum.APPLICATIONFILES) {
    await dispatch(getApplicationFormByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then((res: any) =>
        previewNewPage({
          ...res,
          fileId: file.uid,
          defaultUrl:
            process.env.REACT_APP_BE_SETUP_URL +
            "/redCash/api/v1.0/previewFile",
        }),
      )
      .finally(() => setDownloading(false));
  } else if (
    fileType === FileTypeEnum.IRISS ||
    (fileType === FileTypeEnum.NRVB && !isSmeType) ||
    fileType === FileTypeEnum.SPKCCS ||
    fileType === FileTypeEnum.AMLA
  ) {
    await dispatch(
      getApplicationExperianReportThunk({
        fileId: fileID,
        newIc,
        params: { type: fileType },
      }),
    )
      .unwrap()
      .then((res: any) => {
        const newWindow = window.open("", "_blank");
        if (newWindow != null) {
          newWindow.document.write(res);
          newWindow.document.close();
        }
      })
      .finally(() => setDownloading(false));
  } else if (fileType === FileTypeEnum.WAIVER_CHARGE) {
    await dispatch(getWaiverFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then((res: any) =>
        previewNewPage({
          ...res,
          fileId: file.uid,
          defaultUrl:
            process.env.REACT_APP_COLLECTION_URL +
            "/redCash/api/v1.0/previewFile",
        }),
      )
      .finally(() => setDownloading(false));
  } else if (fileType === FileTypeEnum.AOC_CHARGE) {
    await dispatch(getWaiverFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then((res: any) =>
        previewNewPage({
          ...res,
          fileId: file.uid,
          defaultUrl:
            process.env.REACT_APP_COLLECTION_URL +
            "/redCash/api/v1.0/previewFile",
        }),
      )
      .finally(() => setDownloading(false));
  } else {
    await dispatch(getFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then((res: any) =>
        previewNewPage({
          ...res,
          fileId: file.uid,
          defaultUrl:
            process.env.REACT_APP_BE_SETUP_URL +
            "/redCash/api/v1.0/previewFile",
        }),
      )
      .finally(() => setDownloading(false));
  }
};

export const handleDownload = async (
  file: UploadFile,
  setDownloading: (value: boolean) => void,
  dispatch: any,
  fileType?: FileTypeEnum,
  isSmeType?: boolean
) => {
  setDownloading(true);

  if (fileType === FileTypeEnum.EKYCFILES) {
    await dispatch(getEkycFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then(downloadLink)
      .finally(() => setDownloading(false));
  } else if (fileType === FileTypeEnum.APPLICATIONFILES) {
    await dispatch(getApplicationFormByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then(downloadLink)
      .finally(() => setDownloading(false));
  } else if (
    fileType === FileTypeEnum.IRISS ||
    fileType === FileTypeEnum.SPKCCS ||
    (fileType === FileTypeEnum.NRVB && !isSmeType) ||
    fileType === FileTypeEnum.AMLA
  ) {
    await dispatch(getExperianReportByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then(downloadLink)
      .finally(() => setDownloading(false));
  } else if (fileType === FileTypeEnum.WAIVER_CHARGE) {
    await dispatch(getWaiverFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then(downloadLink)
      .finally(() => setDownloading(false));
  } else if (fileType === FileTypeEnum.AOC_CHARGE) {
    console.log(file);
    await dispatch(getWaiverFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then(downloadLink)
      .finally(() => setDownloading(false));
  } else if (fileType === FileTypeEnum.FIELD_VISIT) {
    console.log(file);
    await dispatch(getWaiverFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then(downloadLink)
      .finally(() => setDownloading(false));
  } else if (fileType === FileTypeEnum.REFUND) {
    console.log(file);
    await dispatch(getWaiverFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then(downloadLink)
      .finally(() => setDownloading(false));
  } else {
    await dispatch(getFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then(downloadLink)
      .finally(() => setDownloading(false));
  }
};
