import { Button, DatePicker, Input, message, Space, Table } from "antd";
import startCase from "lodash.startcase";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import {
  dangerIconRed,
  successTextGreen,
} from "../../../assets/style/variables";
import { downloadLink } from "../../../helpers/downloadLink";
import { numberWithCommas } from "../../../helpers/number";

import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";
import type { DatePickerProps } from "antd";
import type { TablePaginationConfig } from "antd/es/table";
import {
  getLoanInsuranceBatch2Thunk,
  getLoanInsuranceReportThunk,
} from "../../../services/loanInsuranceService/loanInsuranceThunk";
import { LoanInsuranceBatch } from "../../../features/loanInsurance/loanInsuranceSlice";
import DateModal from "../DateModal";
import UploadReportModal from "../UploadReportModal";

const { Search } = Input;

const InsuranceSecondBatchPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { loanInsuranceBatch2 } = useSelector(
    (state: RootState) => state.loanInsurance,
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        currentPage: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [filterDate, setFilterDate] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: loanInsuranceBatch2?.data?.searchTotal,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.currentPage,
    pageSize: paginationState.pageSize,
  };

  const downloadFile = (csvContent: any, fileName: any, filetype: any) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const _win = window.navigator as any;
    if (_win && _win.msSaveBlob) {
      // For IE browser
      _win.msSaveBlob(blob, fileName);
      setIsLoadingExcel(false);
    } else {
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
      setIsLoadingExcel(false);
    }
  };

  const onDownloadInsuranceReport = async (batch: LoanInsuranceBatch) => {
    setDownloading(true);
    message.loading("Downloading");
    await dispatch(
      getLoanInsuranceReportThunk({
        id: batch.id,
        downloadBy: localStorage.getItem("username"),
      }),
    )
      .unwrap()
      .then((res) => {
        downloadFile(res, `${batch.batchNo}`, "csv");

        fetchLoanInsuranceBatch2List({
          ...initialParams,
          pagination: {
            current: pageNum ? +pageNum : 1,
            pageSize: pageSize ? +pageSize : 10,
          },
        });
      })
      .finally(() => setDownloading(false));
  };

  const onSelectPostDate = () => {};

  const createdBatchTableColumns: ColumnsType<any> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Completed Date",
      dataIndex: "completedDate",
      key: "completedDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
      key: "batchNo",
      // render: (text, record) => (
      //   <Button
      //     type="link"
      //     onClick={() => navigate(`/disbursement-details/batch/${record.id}`)}
      //   >
      //     <span style={{ userSelect: "text" }}>{text}</span>
      //   </Button>
      // ),
    },
    {
      title: "Total No Of Account",
      dataIndex: "totalNoOfAccount",
      key: "totalNoOfAccount",
    },
    {
      title: "Total Insurance Amount",
      dataIndex: "totalPremium",
      key: "totalPremium",
      render: (text) => `RM ${numberWithCommas(text)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          style={{
            color:
              text === "IN_COMPLETE"
                ? dangerIconRed
                : text === "COMPLETED"
                ? successTextGreen
                : "black",
          }}
        >
          {startCase(
            text === "IN_COMPLETE"
              ? "INCOMPLETE"
              : text === "REQUEST_SUBMIT_FOR_ZURICH"
              ? "NEW"
              : text ?? "???",
          )}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {record?.status === "SUBMIT_FOR_ZURICH" && (
            <UploadReportModal
              batch={record}
              callback={() =>
                fetchLoanInsuranceBatch2List({
                  ...initialParams,
                  pagination: {
                    current: pageNum ? +pageNum : 1,
                    pageSize: pageSize ? +pageSize : 10,
                  },
                })
              }
            />
          )}

          {record?.status === "UPLOAD_ZURICH_REPORT_SUCCESSFUL" && (
            <DateModal
              batch={record}
              callback={() =>
                fetchLoanInsuranceBatch2List({
                  ...initialParams,
                  pagination: {
                    current: pageNum ? +pageNum : 1,
                    pageSize: pageSize ? +pageSize : 10,
                  },
                })
              }
            />
          )}

          <Button
            key={record.id}
            onClick={() => onDownloadInsuranceReport(record)}
            // disabled={downloading}
          >
            Download
          </Button>
        </>
      ),
    },
  ];

  const fetchLoanInsuranceBatch2List = async (params?: any) => {
    await dispatch(getLoanInsuranceBatch2Thunk(params));
  };

  const onSearch = async (value: string) => {
    if (value.length > 0) {
      const filters = {
        ...filterState,
      };

      const params = filterDate
        ? {
            ...initialParams,
            search: {
              batchNo: value,
            },
            filters: {
              createdAt: filterDate ? filterDate : "",
            },
          }
        : {
            ...initialParams,
            search: {
              batchNo: value,
            },
          };
      fetchLoanInsuranceBatch2List(params);
      setFilterState(filters);
    }
  };

  const onFilterDateChange: DatePickerProps["onChange"] = (
    date,
    dateString,
  ) => {
    setFilterDate(dateString);

    const params =
      searchValue.length > 0
        ? {
            ...initialParams,
            search: {
              batchNo: searchValue,
            },
            filters: {
              loanInsuranceBatchCreatedAt: dateString,
            },
          }
        : {
            ...initialParams,
            filters: {
              loanInsuranceBatchCreatedAt: dateString,
            },
          };

    fetchLoanInsuranceBatch2List(params);
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    const params = {
      ...initialParams,
      ...filterState,
      pagination,
      currentPage: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    };
    setPaginationState({
      currentPage: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
    fetchLoanInsuranceBatch2List(params);
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.currentPage,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchLoanInsuranceBatch2List(params);
  });

  return (
    <>
      <Helmet>
        <title>Loan Insurance Listing - redCASH CEP</title>
      </Helmet>

      <Space className="mb-4">
        <Search
          placeholder="Batch No."
          onSearch={onSearch}
          style={{ width: 200 }}
        />
        <Space className="ml-4">
          <span>Filter By Created Date:</span>
          <DatePicker onChange={onFilterDateChange} />
        </Space>
      </Space>

      <Table
        rowKey="id"
        className="min-w-fit"
        columns={createdBatchTableColumns}
        pagination={tablePagination}
        dataSource={loanInsuranceBatch2?.data?.data}
        loading={loanInsuranceBatch2?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default InsuranceSecondBatchPage;
