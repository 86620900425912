import { createSlice } from "@reduxjs/toolkit";
import { getAllWriteOffConfigThunk } from "../../services/writeOffConfigService/writeOffConfigThunk";

export type writeOffConfig = {
  id: string;
  configValue: string;
  configType: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export type writeOffConfigResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: writeOffConfig[];
};

export type writeOffState = {
  writeOffConfigListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffConfigResponse;
  };
};

const initialState: writeOffState = {
  writeOffConfigListing: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
};

export const writeOffConfigSlice = createSlice({
  name: "writeOffConfig",
  initialState,
  reducers: {
    writeOffConfigStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWriteOffConfigThunk.pending, (state) => {
        state.writeOffConfigListing.isLoading = true;
      })
      .addCase(getAllWriteOffConfigThunk.fulfilled, (state, action) => {
        state.writeOffConfigListing.isLoading = false;
        state.writeOffConfigListing.data = action.payload;
      })
      .addCase(getAllWriteOffConfigThunk.rejected, (state, action) => {
        state.writeOffConfigListing.isLoading = false;
        state.writeOffConfigListing.isError = true;
        state.writeOffConfigListing.errorMessage = action.payload;
      });
  },
});

export const { writeOffConfigStateReset } = writeOffConfigSlice.actions;

export default writeOffConfigSlice.reducer;
