import { createAsyncThunk } from "@reduxjs/toolkit";

import smePreRecordedVideoService from "./smePreRecordedVideoService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getSmePreRecordedVideoENThunk = createAsyncThunk(
  "smePreRecordedVideo/getEN",
  async (requestData: any, thunkAPI) => {
    try {
      return await smePreRecordedVideoService.getSmePreRecordedVideoEN();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmePreRecordedVideoBMThunk = createAsyncThunk(
  "smePreRecordedVideo/getBM",
  async (requestData: any, thunkAPI) => {
    try {
      return await smePreRecordedVideoService.getSmePreRecordedVideoBM();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
