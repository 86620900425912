import {
  ApartmentOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BarsOutlined,
  ContainerOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FormOutlined,
  ForwardOutlined,
  GlobalOutlined,
  HddOutlined,
  MonitorOutlined,
  SearchOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
  ControlOutlined,
  TagOutlined,
} from "@ant-design/icons";

import { Role } from "./enum/roles";
import { lazy } from "react";
import { lazyRetry } from "./utils/routing.util";
import EInvoiceListingPage from "./pages/EInvoice";
import SmeGlobalSearchPage from "./pages/SmeGlobalSearch";
import SmeSearchApplicationPage from "./pages/SmeApplication/SearchApplication";
import SmeCustomerServiceSearchApplicationPage from "./pages/SmeCustomerService/SearchApplication";
import SmeVerifyingApplicationListingPage from "./pages/SmeApplication/VerifyingListing";
import SmeApplicationApprovedReviewPage from "./pages/SmeApplication/ApplicationApprovedReview";
import SmeApplicationDetailsPage from "./pages/SmeApplication/ApplicationDetails";
import SmeSearchAgreementPage from "./pages/SmeAgreement/SearchAgreement";
import SmeAttestationListingPage from "./pages/SmeAgreement/AttestationListing";
import SmeAgreementSigningListingPage from "./pages/SmeAgreement/AgreementSigningListing";
import SmeAgreementDetailsPage from "./pages/SmeAgreement/AgreementDetailsPage";
import SmeEStampingListingPage from "./pages/SmeEStampListing";
import SmeDisbursementListingPage from "./pages/SmeDisbursementListing";
import SmeProductMaintenancePage from "./pages/SmeProductMaintenance";
import SmeFirstApprovalReviewPage from "./pages/SmeApplication/FirstApprovalReview";
import SmeSecondApprovalReviewPage from "./pages/SmeApplication/SecondApprovalReview";
import SmeSecondVerifyingListingPage from "./pages/SmeApplication/SecondVerifyingListing";
import SmeBatchDetailsPage from "./pages/SmeDisbursementListing/CreatedDisbursementBatchListing/BatchDetails";

import LoginPage from "./pages/Auth/Login";
import DashboardPage from "./pages/Dashboard";
import GlobalSearchPage from "./pages/GlobalSearch";
import ApprovalReviewPage from "./pages/Application/ApprovalReview";
import VerifyingApplicationListingPage from "./pages/Application/VerifyingListing";
import ApplicationDetailsPage from "./pages/Application/ApplicationDetails";
import ApplicationApprovedReviewPage from "./pages/Application/ApplicationApprovedReview";
import CaseAssignmentPage from "./pages/Application/CaseAssignment";
import SearchAgreementPage from "./pages/Agreement/SearchAgreement";
import AgreementDetailsPage from "./pages/Agreement/AgreementDetailsPage";
import PreRecordedVideoListingPage from "./pages/PreRecordedVideoListing";
import DisbursementListingPage from "./pages/DisbursementListing";
import EarlySettlementListing from "./pages/EarlySettlement/EarlySettlementListing";
import RequestEarlySettlement from "./pages/EarlySettlement/RequestEarlySettlement";
import BatchDetailsPage from "./pages/DisbursementListing/CreatedDisbursementBatchListing/BatchDetails";
import AttestationListingPage from "./pages/Agreement/AttestationListing";
import ActionCodeManagementPage from "./pages/ActionCodeManagement";
import ProductMaintenancePage from "./pages/ProductMaintenance";
import EStampListingPage from "./pages/EStampListing";
import SearchApplicationPage from "./pages/Application/SearchApplication";
import TrustSignerListingPage from "./pages/TrustSignerListing";
import AdminManagementPage from "./pages/AdminManagement";
import AgreementSigningListingPage from "./pages/Agreement/AgreementSigningListing";
import AccountEnquiryPage from "./pages/AccountEnquiry";
import AccountStatementPage from "./pages/AccountStatement";
import CreateWaiverPage from "./pages/Waiver/CreateWaiver";
import WaiverPage from "./pages/Waiver/WaiverListing";
import WaiverDetailsPage from "./pages/Waiver/WaiverDetails";
import EditWaiverChargesPage from "./pages/Waiver/EditWaiverCharges";
import EarlySettlementDetailsPage from "./pages/EarlySettlement/EarlySettlementDetails";
import AddOnChargesListing from "./pages/AddOnCharges/AddOnChargesListing";
import AddOnChargesBatchDetails from "./pages/AddOnCharges/AddOnChargesListing/AddOnChargesBatchDetails";
import AddAccountCharges from "./pages/AddOnCharges/AddAccountCharges";
import EditAocBatch from "./pages/AddOnCharges/EditAoc";
import NoticesGenerationConfigPages from "./pages/NoticesGenerationConfig/ListingNoticesGenerationConfig";
import ReminderLetter1Pages from "./pages/ReminderLetter/ReminderLetter1";
import ReminderLetter2Pages from "./pages/ReminderLetter/ReminderLetter2";
import ReminderLetter3Pages from "./pages/ReminderLetter/ReminderLetter3";
import PaymentNoticeConfigPages from "./pages/PaymentNoticeConfig";
import PaymentNoticeListingPages from "./pages/PaymentNotice/PaymentNoticeListing";
import CollectionListingPage from "./pages/CollectionListing";
import ReportListingPage from "./pages/Report";
import FieldVisitOfficerManagementPage from "./pages/FieldVisit/FieldVisitOfficerManagement";
import FieldVisitOfficerManagementCompanyDetailsPage from "./pages/FieldVisit/FieldVisitOfficerManagement/Company/FieldVisitCompanyDetails";
import FieldVisitListingPage from "./pages/FieldVisit/FieldVisitListing";
import FieldVisitDetailsPage from "./pages/FieldVisit/FieldVisitDetailsPage";
import LoanInsuranceConfigDetailsPage from "./pages/LoanInsuranceConfig/LoanInsuranceDetails";
import CreateWriteOffPage from "./pages/WriteOff/CreateWriteOff";
import WriteOffListingPage from "./pages/WriteOff/WriteOffListing";
import WriteOffProposalPage from "./pages/WriteOff/WriteOffProposal";
import WriteOffMonitorPage from "./pages/WriteOff/WriteOffMonitor";
import WriteOffConfigPage from "./pages/WriteOff/WriteOffConfig";
import EditAocByAgreementNoPage from "./pages/WriteOff/WriteOffMonitor/EditAoc";
import EditWaiverChargesByAgreementNoPage from "./pages/WriteOff/WriteOffMonitor/EditWaiverCharges";
import ManualPaymentPage from "./pages/ManualPayment/ManualPaymentList";
import JomPayPage from "./pages/ManualPayment/JomPay";
import EkycReportPage from "./pages/Ekyc/EkycReport";
import EkycUploadImage from "./pages/Ekyc/EkycUploadImage";
import TeamManagementPage from "./pages/CaseAssignment/Team";
import GroupManagementPage from "./pages/CaseAssignment/Group";
import BucketManagementPage from "./pages/CaseAssignment/Bucket";
import RegionManagementPage from "./pages/CaseAssignment/Region";
import RuleManagementPage from "./pages/CaseAssignment/Rule";
import RuleParameterManagementPage from "./pages/CaseAssignment/RuleParameter";
import RefundListing from "./pages/Refund/RefundListing";
import RefundFinanceListing from "./pages/Refund/RefundFinanceListing";
import SpecialTaggingListing from "./pages/SpecialTagging";
import RescheduleRequestListingPage from "./pages/Reschedule/RescheduleRequest/index";
import RescheduleListingPage from "./pages/Reschedule/RescheduleListing/index";
import RescheduleDetailsPage from "./pages/Reschedule/RescheduleDetailsPage/index";
import RestructureRequestListingPage from "./pages/Restructure/RestructureRequest/index";
import RestructureListingPage from "./pages/Restructure/RestructureListing/index";
import RestructureDetailsPage from "./pages/Restructure/RestructureDetailsPage/index";
import InterestDiscount from "./pages/InterestDiscount";
import RefundFinanceBatchDetails from "./pages/Refund/components/refundBatchDetails";
import CustomerServiceSearchApplicationPage from "./pages/CustomerService/SearchApplication";
import LoanInsuranceConfigPage from "./pages/LoanInsuranceConfig";
import STRDashboard from "./pages/STR";
import LoanInsuranceListingPage from "./pages/LoanInsurance";
import RefundChargeConfig from "./pages/RefundChargeConfig";
import SmePreRecordedVideoListingPage from "./pages/SmePreRecordedVideoListing";

export const notAuthRoutes = [
  // not auth routes
  {
    id: "login",
    path: "login",
    name: "Login",
    component: <LoginPage />,
  },
];

export const authRoutes = [
  {
    id: "dashboard",
    path: "dashboard",
    name: "Dashboard",
    icon: <AppstoreOutlined />,
    component: <DashboardPage />,
    access: [
      Role.SUPER_ADMIN,
      Role.APPROVER,
      Role.VERIFIER,
      Role.ATTESTATOR,
      Role.VERIFIER_2,
      Role.APPROVER_2,
      Role.WRITE_OFF_BATCH_TWO_APPROVER,
    ],
    showInSiderMenu: true,
  },
  {
    id: "str",
    path: "str",
    name: "STR Dashboard",
    icon: <AppstoreOutlined />,
    component: <STRDashboard />,
    access: [
      Role.SUPER_ADMIN
    ],
    showInSiderMenu: true,
  },
  {
    id: "loan-creation",
    path: null,
    name: "Loan Creation",
    icon: <FileTextOutlined />,
    access: [
      Role.SUPER_ADMIN,
      Role.APPROVER,
      Role.VERIFIER,
      Role.APPROVER_2,
      Role.VERIFIER_2,
      Role.ATTESTATOR,
      Role.FINANCE,
      Role.CUSTOMER_SERVICE,
    ],
    showInSiderMenu: true,
    routes: [
      {
        id: "global-search",
        path: "global-search",
        name: "Global Search",
        icon: <SearchOutlined />,
        component: <GlobalSearchPage />,
        access: [
          Role.SUPER_ADMIN,
          Role.APPROVER,
          Role.VERIFIER,
          Role.VERIFIER_2,
        ],
        showInSiderMenu: true,
      },
      {
        id: "application-listing",
        path: "application-listing",
        name: "Application Listing",
        icon: <FileTextOutlined />,
        access: [
          Role.SUPER_ADMIN,
          Role.VERIFIER,
          Role.APPROVER,
          Role.APPROVER_2,
          Role.VERIFIER_2,
          Role.CUSTOMER_SERVICE,
        ],
        showInSiderMenu: true,
        routes: [
          {
            id: "application-listing-search",
            path: "search",
            name: "Search Application",
            icon: <SearchOutlined />,
            component: <SearchApplicationPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.VERIFIER,
              Role.APPROVER,
              Role.APPROVER_2,
              Role.VERIFIER_2,
            ],
            showInSiderMenu: true,
          },
          {
            id: "customer-service",
            path: "customer-service-search",
            name: "Customer Service Search",
            component: <CustomerServiceSearchApplicationPage />,
            access: [Role.SUPER_ADMIN, Role.CUSTOMER_SERVICE],
            showInSiderMenu: true,
          },
          {
            id: "application-listing-verifying",
            path: "verifying",
            name: "Verifying Listing",
            icon: <FileTextOutlined />,
            component: <VerifyingApplicationListingPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "application-approval-review",
            path: "approval-review",
            name: "Approval Review",
            icon: <FileTextOutlined />,
            component: <ApprovalReviewPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER_2, Role.APPROVER],
            showInSiderMenu: true,
          },
          {
            id: "application-approved-review",
            path: "approved-review",
            name: "Application Approved Review",
            icon: <FileTextOutlined />,
            component: <ApplicationApprovedReviewPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.VERIFIER_2,
              Role.VERIFIER,
              Role.APPROVER,
            ],
            showInSiderMenu: true,
          },
        ],
      },
      {
        id: "application-details",
        path: "application/details/:applicationId",
        name: "Application Details",
        component: <ApplicationDetailsPage />,
        access: [
          Role.SUPER_ADMIN,
          Role.APPROVER,
          Role.VERIFIER,
          Role.APPROVER_2,
          Role.APPROVER_2,
          Role.FINANCE,
          Role.CUSTOMER_SERVICE,
        ],
      },
      {
        id: "agreement-listing",
        path: "agreement-listing",
        name: "Agreement Listing",
        icon: <AuditOutlined />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER, Role.ATTESTATOR],
        showInSiderMenu: true,
        routes: [
          {
            id: "agreement-listing-search",
            path: "search",
            name: "Search Agreement",
            icon: <SearchOutlined />,
            component: <SearchAgreementPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.APPROVER,
              Role.FINANCE,
              Role.VERIFIER,
            ],
            showInSiderMenu: true,
          },
          {
            id: "agreement-attestation-listing",
            path: "attestation-listing",
            name: "Attestation Listing",
            icon: <SolutionOutlined />,
            component: <AttestationListingPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER, Role.ATTESTATOR],
            showInSiderMenu: true,
          },
          {
            id: "agreement-signing-listing",
            path: "signing-listing",
            name: "Agreement Signing",
            icon: <SolutionOutlined />,
            component: <AgreementSigningListingPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER],
            showInSiderMenu: true,
          },
        ],
      },
      {
        id: "agreement-details",
        path: "agreement/details/:agreementId",
        name: "Agreement Details",
        component: <AgreementDetailsPage />,
        access: [
          Role.SUPER_ADMIN,
          Role.APPROVER,
          Role.VERIFIER,
          Role.FINANCE,
          Role.VERIFIER_2,
          Role.APPROVER_2,
        ],
      },
      {
        id: "e-stamp",
        path: "e-stamp",
        name: "E-Stamp",
        icon: <FileDoneOutlined />,
        component: <EStampListingPage />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER],
        showInSiderMenu: true,
      },
      {
        id: "disbursement-listing",
        path: "disbursement-listing",
        name: "Disbursement Listing",
        icon: <FileTextOutlined />,
        component: <DisbursementListingPage />,
        access: [Role.SUPER_ADMIN, Role.FINANCE],
        showInSiderMenu: true,
      },
      {
        id: "loan-insurance-batch-listing",
        path: "loanInsuranceBatch-listing",
        name: "Loan Insurance Batch Listing",
        icon: <FileTextOutlined />,
        component: <LoanInsuranceListingPage />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER],
        showInSiderMenu: true,
      },
    ],
  },
  {
    id: "disbursement-details-batch",
    path: "disbursement-details/batch/:batchId",
    name: "Disbursement Details",
    component: <BatchDetailsPage />,
    access: [Role.SUPER_ADMIN, Role.FINANCE],
    showInSiderMenu: false,
  },
  {
    id: "refund-finance-details-batch",
    path: "refund-finance-batch/:batchId",
    name: "Refund Finance Batch Details",
    component: <RefundFinanceBatchDetails />,
    access: [Role.SUPER_ADMIN, Role.FINANCE],
    showInSiderMenu: false,
  },
  {
    id: "sme-loan-creation",
    path: null,
    name: "SME Loan Creation",
    icon: <FileTextOutlined />,
    access: [
      Role.SUPER_ADMIN,
      Role.APPROVER,
      Role.VERIFIER,
      Role.APPROVER_2,
      Role.VERIFIER_2,
      Role.ATTESTATOR,
      Role.FINANCE,
    ],
    showInSiderMenu: true,
    routes: [
      {
        id: "sme-global-search",
        path: "sme-global-search",
        name: "Global Search",
        icon: <SearchOutlined />,
        component: <SmeGlobalSearchPage />,
        access: [
          Role.SUPER_ADMIN,
          Role.APPROVER,
          Role.VERIFIER,
          Role.APPROVER_2,
          Role.VERIFIER_2,
        ],
        showInSiderMenu: true,
      },
      {
        id: "sme-application-listing",
        path: "sme-application-listing",
        name: "Application Listing",
        icon: <FileTextOutlined />,
        access: [
          Role.SUPER_ADMIN,
          Role.VERIFIER,
          Role.APPROVER,
          Role.VERIFIER_2,
          Role.APPROVER_2,
        ],
        showInSiderMenu: true,
        routes: [
          {
            id: "sme-application-listing-search",
            path: "search",
            name: "Search Application",
            icon: <SearchOutlined />,
            component: <SmeSearchApplicationPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.APPROVER,
              Role.VERIFIER,
              Role.VERIFIER_2,
            ],
            showInSiderMenu: true,
          },
          // {
          //   id: "sme-customer-service",
          //   path: "customer-service-search",
          //   name: "Customer Service Search",
          //   component: <SmeCustomerServiceSearchApplicationPage />,
          //   access: [Role.SUPER_ADMIN, Role.CUSTOMER_SERVICE],
          //   showInSiderMenu: true,
          // },
          {
            id: "sme-application-listing-verifying",
            path: "verifying",
            name: "Verifying Listing",
            icon: <FileTextOutlined />,
            component: <SmeVerifyingApplicationListingPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "sme-application-listing-second-verifying",
            path: "second-verifying",
            name: "Second Verification Listing",
            icon: <FileTextOutlined />,
            component: <SmeSecondVerifyingListingPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER_2],
            showInSiderMenu: true,
          },
          {
            id: "sme-application-first-approval-review",
            path: "first-approval-review",
            name: "First Approval Review",
            icon: <FileTextOutlined />,
            component: <SmeFirstApprovalReviewPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER],
            showInSiderMenu: true,
          },
          {
            id: "sme-application-second-approval-review",
            path: "second-approval-review",
            name: "Second Approval Review",
            icon: <FileTextOutlined />,
            component: <SmeSecondApprovalReviewPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER_2],
            showInSiderMenu: true,
          },
          {
            id: "sme-application-approved-review",
            path: "approved-review",
            name: "Application Approved Review",
            icon: <FileTextOutlined />,
            component: <SmeApplicationApprovedReviewPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER_2],
            showInSiderMenu: true,
          },
        ],
      },
      {
        id: "sme-application-details",
        path: "/sme/application/details/:applicationId",
        name: "Application Details",
        component: <SmeApplicationDetailsPage />,
        access: [
          Role.SUPER_ADMIN,
          Role.APPROVER,
          Role.APPROVER_2,
          Role.VERIFIER_2,
          Role.VERIFIER,
          Role.FINANCE,
          Role.CUSTOMER_SERVICE,
        ],
      },
      {
        id: "sme-agreement-listing",
        path: "sme-agreement-listing",
        name: "Agreement Listing",
        icon: <AuditOutlined />,
        access: [
          Role.SUPER_ADMIN,
          Role.ATTESTATOR,
          Role.VERIFIER,
          Role.VERIFIER_2,
        ],
        showInSiderMenu: true,
        routes: [
          {
            id: "agreement-listing-search",
            path: "search",
            name: "Search Agreement",
            icon: <SearchOutlined />,
            component: <SmeSearchAgreementPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.FINANCE,
              Role.VERIFIER,
              Role.VERIFIER_2,
            ],
            showInSiderMenu: true,
          },
          {
            id: "agreement-attestation-listing",
            path: "attestation-listing",
            name: "Attestation Listing",
            icon: <SolutionOutlined />,
            component: <SmeAttestationListingPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.ATTESTATOR,
              Role.VERIFIER,
              Role.VERIFIER_2,
            ],
            showInSiderMenu: true,
          },
          {
            id: "agreement-signing-listing",
            path: "signing-listing",
            name: "Agreement Signing",
            icon: <SolutionOutlined />,
            component: <SmeAgreementSigningListingPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.ATTESTATOR,
              Role.VERIFIER,
              Role.VERIFIER_2,
            ],
            showInSiderMenu: true,
          },
        ],
      },
      {
        id: "sme-agreement-details",
        path: "/sme/agreement/details/:agreementId",
        name: "Agreement Details",
        component: <SmeAgreementDetailsPage />,
        access: [
          Role.SUPER_ADMIN,
          Role.FINANCE,
          Role.VERIFIER,
          Role.VERIFIER_2,
        ],
      },
      {
        id: "sme-e-stamp",
        path: "sme-stamp",
        name: "E-Stamp",
        icon: <FileDoneOutlined />,
        component: <SmeEStampingListingPage />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER, Role.VERIFIER_2],
        showInSiderMenu: true,
      },
      {
        id: "sme-disbursement-listing",
        path: "sme-disbursement-listing",
        name: "Disbursement Listing",
        icon: <FileTextOutlined />,
        component: <SmeDisbursementListingPage />,
        access: [Role.SUPER_ADMIN, Role.FINANCE],
        showInSiderMenu: true,
      },
    ],
  },
  {
    id: "sme-disbursement-details",
    path: "sme-disbursement-details/:batchId",
    name: "Disbursement Details",
    component: <SmeBatchDetailsPage />,
    access: [Role.SUPER_ADMIN, Role.FINANCE],
    showInSiderMenu: false,
  },
  {
    id: "loan-enquiry",
    path: null,
    name: "Loan Enquiry",
    icon: <FileTextOutlined />,
    access: [
      Role.SUPER_ADMIN,
      Role.APPROVER,
      Role.VERIFIER,
      Role.FINANCE,
      Role.WRITE_OFF_BATCH_TWO_APPROVER,
      Role.CUSTOMER_SERVICE,
    ],
    showInSiderMenu: true,
    routes: [
      {
        id: "account-enquiry",
        path: "account-enquiry",
        name: "Account Enquiry",
        icon: <AppstoreOutlined />,
        component: <AccountEnquiryPage />,
        access: [
          Role.SUPER_ADMIN,
          Role.APPROVER,
          Role.VERIFIER,
          Role.FINANCE,
          Role.CUSTOMER_SERVICE,
        ],
        showInSiderMenu: true,
      },
      {
        id: "account-statement",
        path: "account-statement",
        name: "Account Statement",
        icon: <UserOutlined />,
        component: <AccountStatementPage />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER],
        showInSiderMenu: true,
      },
      {
        id: "collection-listing",
        path: "collection-listing",
        name: "Collection Listing",
        icon: <FileDoneOutlined />,
        component: <CollectionListingPage />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER, Role.CUSTOMER_SERVICE],
        showInSiderMenu: true,
      },
      {
        id: "waiver",
        path: "waiver",
        name: "Waiver",
        icon: <FileTextOutlined />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER],
        showInSiderMenu: true,
        routes: [
          {
            id: "create-waiver",
            path: "create-waiver",
            name: "Create Waiver",
            icon: <FileTextOutlined />,
            component: <CreateWaiverPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "waiver-listing",
            path: "waiver-listing",
            name: "Waiver Listing",
            icon: <FileTextOutlined />,
            component: <WaiverPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "waiver-details",
            path: "details/:waiverId",
            name: "Waiver Details",
            component: <WaiverDetailsPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: false,
          },
          {
            id: "edit-waiver",
            path: "edit-waiver/waiverId/:waiverId/accountDetails/:accountDetailsId/agreementNo/:agreementNo/status/:status",
            name: "Edit waiver",
            component: <EditWaiverChargesPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: false,
          },
        ],
      },
      {
        id: "add-on-charges",
        path: "add-on-charges",
        name: "Add-on Charges",
        icon: <DollarOutlined />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER],
        showInSiderMenu: true,
        routes: [
          {
            id: "add-account-charges",
            path: "request",
            name: "Add Account Charges",
            icon: <FileAddOutlined />,
            component: <AddAccountCharges />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "add-on-charges-listing",
            path: "listing",
            name: "Add-on Charges Listing",
            icon: <UnorderedListOutlined />,
            component: <AddOnChargesListing />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "add-on-charges-batch-details",
            path: "batch/details/:batchId",
            name: "Add-on Charges Batch Details",
            component: <AddOnChargesBatchDetails />,
            access: [Role.SUPER_ADMIN, Role.APPROVER],
            showInSiderMenu: false,
          },
          {
            id: "edit-add-on-charges-batch",
            path: "edit-aoc-batch/batchId/:batchId/agreementNo/:agreementNo/accountDetailsId/:accountDetailsId/status/:status",
            name: "Edit Aoc Batch",
            component: <EditAocBatch />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: false,
          },
        ],
      },
      {
        id: "early-settlement",
        path: "early-settlement",
        name: "Early Settlement",
        icon: <ForwardOutlined />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER],
        showInSiderMenu: true,
        routes: [
          {
            id: "request-early-settlement",
            path: "request",
            name: "Request Early Settlement",
            icon: <FormOutlined />,
            component: <RequestEarlySettlement />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "early-settlement-listing",
            path: "listing",
            name: "Early Settlement Listing",
            icon: <UnorderedListOutlined />,
            component: <EarlySettlementListing />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "early-settlement-details",
            path: "details/:agreementId",
            name: "Early Settlement Details",
            component: <EarlySettlementDetailsPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: false,
          },
        ],
      },
      {
        id: "reminder-letter",
        path: "reminder-letter",
        name: "Reminder Letter",
        icon: <FileTextOutlined />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER],
        showInSiderMenu: true,
        routes: [
          {
            id: "reminder-letter-1",
            path: "reminder-letter-1-listing",
            name: "Reminder Letter 1",
            icon: <FormOutlined />,
            component: <ReminderLetter1Pages />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "reminder-letter-2",
            path: "reminder-letter-2-listing",
            name: "Reminder Letter 2",
            icon: <FormOutlined />,
            component: <ReminderLetter2Pages />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "reminder-letter-3",
            path: "reminder-letter-3-listing",
            name: "Reminder Letter 3",
            icon: <FormOutlined />,
            component: <ReminderLetter3Pages />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
        ],
      },
      {
        id: "payment-notice",
        path: "payment-notice-listing",
        name: "Payment Notice",
        icon: <FormOutlined />,
        component: <PaymentNoticeListingPages />,
        access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
        showInSiderMenu: true,
      },
      {
        id: "field-visit",
        path: "field-visit",
        name: "Field Visit",
        icon: <MonitorOutlined />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER],
        showInSiderMenu: true,
        routes: [
          {
            id: "field-visit-listing",
            path: "listing",
            name: "Field Visit Listing",
            icon: <BarsOutlined />,
            component: <FieldVisitListingPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "field-visit-details",
            path: "details/:fieldVisitId",
            name: "Field Visit Details",
            component: <FieldVisitDetailsPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER],
            showInSiderMenu: false,
          },
        ],
      },
      {
        id: "manual-payment",
        path: "manual-payment",
        name: "Manual Payment",
        icon: <DollarOutlined />,
        access: [
          Role.SUPER_ADMIN,
          Role.APPROVER,
          Role.ATTESTATOR,
          Role.VERIFIER,
          Role.WRITE_OFF_BATCH_TWO_APPROVER,
        ],
        showInSiderMenu: true,
        routes: [
          {
            id: "manual-payment-listing",
            path: "listing",
            name: "Manual Payment Listing",
            icon: <BarsOutlined />,
            access: [
              Role.SUPER_ADMIN,
              Role.APPROVER,
              Role.ATTESTATOR,
              Role.VERIFIER,
              Role.WRITE_OFF_BATCH_TWO_APPROVER,
            ],
            component: <ManualPaymentPage />,
            showInSiderMenu: true,
          },
          {
            id: "jomPay",
            path: "jomPay",
            name: " JomPay",
            icon: <DollarOutlined />,
            component: <JomPayPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.APPROVER,
              Role.ATTESTATOR,
              Role.VERIFIER,
              Role.WRITE_OFF_BATCH_TWO_APPROVER,
            ],
            showInSiderMenu: true,
          },
        ],
      },
      {
        id: "report",
        path: "report",
        name: "Report",
        icon: <FileTextOutlined />,
        access: [
          Role.SUPER_ADMIN,
          Role.APPROVER,
          Role.VERIFIER,
          Role.ATTESTATOR,
          Role.FINANCE,
          Role.WRITE_OFF_BATCH_TWO_APPROVER,
        ],
        showInSiderMenu: true,
        component: <ReportListingPage />,
      },
      {
        id: "write-off",
        path: "write-off",
        name: "Write Off",
        icon: <FileTextOutlined />,
        access: [
          Role.SUPER_ADMIN,
          Role.VERIFIER,
          Role.APPROVER,
          Role.WRITE_OFF_BATCH_TWO_APPROVER,
        ],
        showInSiderMenu: true,
        routes: [
          {
            id: "create-write-off",
            path: "create-write-off",
            name: "Create Write Off",
            icon: <FileTextOutlined />,
            component: <CreateWriteOffPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "write-off-listing",
            path: "write-off-listing",
            name: "Write Off Listing",
            icon: <FileTextOutlined />,
            component: <WriteOffListingPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.VERIFIER,
              Role.APPROVER,
              Role.WRITE_OFF_BATCH_TWO_APPROVER,
            ],
            showInSiderMenu: true,
          },
          {
            id: "write-off-details",
            path: "proposal/:batchNo",
            name: "Write Off Proposal",
            component: <WriteOffProposalPage />,
            access: [
              Role.SUPER_ADMIN,
              Role.VERIFIER,
              Role.APPROVER,
              Role.WRITE_OFF_BATCH_TWO_APPROVER,
            ],
            showInSiderMenu: false,
          },
          {
            id: "write-off-monitor",
            path: "monitor",
            name: "Write Off Monitor",
            icon: <FileTextOutlined />,
            component: <WriteOffMonitorPage />,
            access: [Role.SUPER_ADMIN, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "write-off-monitor-edit-aoc",
            path: "edit-aoc/agreementNo/:agreementNo/accountDetailsId/:accountDetailsId",
            component: <EditAocByAgreementNoPage />,
            access: [Role.SUPER_ADMIN],
            showInSiderMenu: false,
          },
          {
            id: "write-off-monitor-edit-waiver",
            path: "edit-waiver/agreementNo/:agreementNo/accountDetailsId/:accountDetailsId",
            component: <EditWaiverChargesByAgreementNoPage />,
            access: [Role.SUPER_ADMIN],
            showInSiderMenu: false,
          },
        ],
      },
      {
        id: "refund",
        path: "refund",
        name: "Refund",
        icon: <DollarOutlined />,
        access: [Role.SUPER_ADMIN, Role.FINANCE, Role.VERIFIER, Role.APPROVER],
        showInSiderMenu: true,
        routes: [
          {
            id: "refund-listing",
            path: "listing",
            name: "Refund Listing",
            icon: <UnorderedListOutlined />,
            component: <RefundListing />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "refund-finance-listing",
            path: "finance-listing",
            name: "Refund Finance Listing",
            icon: <UnorderedListOutlined />,
            component: <RefundFinanceListing />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.FINANCE],
            showInSiderMenu: true,
          },
        ],
      },
      {
        id: "special-tagging",
        path: "special-tagging",
        name: "Special Tagging",
        icon: <AuditOutlined />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER, Role.APPROVER],
        showInSiderMenu: true,
        routes: [
          {
            id: "special-tagging-listing",
            path: "listing",
            name: "Special Tagging Listing",
            icon: <UnorderedListOutlined />,
            component: <SpecialTaggingListing />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
        ],
      },
      {
        id: "reschedule",
        path: "reschedule",
        name: "Reschedule",
        icon: <ClockCircleOutlined />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER, Role.APPROVER],
        showInSiderMenu: true,
        routes: [
          {
            id: "reschedule-request",
            path: "request",
            name: "Reschedule Request",
            icon: <UnorderedListOutlined />,
            component: <RescheduleRequestListingPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "reschedule-listing",
            path: "listing",
            name: "Reschedule Listing",
            icon: <UnorderedListOutlined />,
            component: <RescheduleListingPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "reschedule-request-details",
            path: "details/:rescheduleId",
            name: "Reschedule Details",
            component: <RescheduleDetailsPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: false,
          },
        ],
      },
      {
        id: "restructure",
        path: "restructure",
        name: "Restructure",
        icon: <ControlOutlined />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER, Role.APPROVER],
        showInSiderMenu: true,
        routes: [
          {
            id: "restructure-request",
            path: "request",
            name: "Restructure Request",
            icon: <UnorderedListOutlined />,
            component: <RestructureRequestListingPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "restructure-listing",
            path: "listing",
            name: "Restructure Listing",
            icon: <UnorderedListOutlined />,
            component: <RestructureListingPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: true,
          },
          {
            id: "restructure-request-details",
            path: "details/:restructureId",
            name: "Restructure Details",
            component: <RestructureDetailsPage />,
            access: [Role.SUPER_ADMIN, Role.APPROVER, Role.VERIFIER],
            showInSiderMenu: false,
          },
        ],
      },
      {
        id: "e-invoice",
        path: "e-invoice",
        name: "e-Invoice",
        icon: <AppstoreOutlined />,
        component: <EInvoiceListingPage />,
        access: [
          Role.SUPER_ADMIN,
          Role.APPROVER,
          Role.VERIFIER,
          Role.CUSTOMER_SERVICE,
          Role.FINANCE,
        ],
        showInSiderMenu: true,
      },
    ],
  },
  {
    id: "configurations",
    path: null,
    name: "Configurations",
    icon: <FileTextOutlined />,
    access: [Role.SUPER_ADMIN],
    showInSiderMenu: true,
    routes: [
      {
        id: "action-code-config",
        path: "action-code-config",
        name: "Action Code",
        icon: <SettingOutlined />,
        component: <ActionCodeManagementPage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "notices-generation-config",
        path: "notices-generation-config",
        name: "Notices Generation",
        icon: <SettingOutlined />,
        component: <NoticesGenerationConfigPages />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "payment-notice-config",
        path: "payment-notice-config",
        name: "Payment Notice",
        icon: <SettingOutlined />,
        component: <PaymentNoticeConfigPages />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "product-config",
        path: "product-config",
        name: "Product",
        icon: <SettingOutlined />,
        component: <ProductMaintenancePage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "sme-product-config",
        path: "sme-product-config",
        name: "SME Product",
        icon: <SettingOutlined />,
        component: <SmeProductMaintenancePage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "pre-recorded-video",
        path: "pre-recorded-video",
        name: "Pre Recorded Video",
        icon: <VideoCameraOutlined />,
        component: <PreRecordedVideoListingPage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "sme-pre-recorded-video",
        path: "sme-pre-recorded-video",
        name: "SME Pre Recorded Video",
        icon: <VideoCameraOutlined />,
        component: <SmePreRecordedVideoListingPage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "trust-signer-config",
        path: "trust-signer-config",
        name: "Trust Signer",
        icon: <UserOutlined />,
        component: <TrustSignerListingPage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "admin-config",
        path: "admin-config",
        name: "Admin",
        icon: <UserOutlined />,
        component: <AdminManagementPage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "write-off",
        path: "write-off-configuration",
        name: "Write Off",
        icon: <FileTextOutlined />,
        component: <WriteOffConfigPage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "field-visit-officer-management",
        path: "field-visit-officer-management",
        name: "Field Visit Officer",
        icon: <TeamOutlined />,
        component: <FieldVisitOfficerManagementPage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "field-visit-officer-management-company-details",
        path: "field-visit-officer-management/company-details/:id",
        name: "Field Visit Company Details",
        component: <FieldVisitOfficerManagementCompanyDetailsPage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: false,
      },
      {
        id: "ekyc",
        path: "ekyc",
        name: "eKYC",
        icon: <UserOutlined />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
        routes: [
          {
            id: "ekyc-report",
            path: "report",
            name: "eKYC Report",
            icon: <UserOutlined />,
            component: <EkycReportPage />,
            access: [Role.SUPER_ADMIN],
            showInSiderMenu: true,
          },
          {
            id: "upload-image",
            path: "upload-image",
            name: "Upload Image",
            icon: <UploadOutlined />,
            component: <EkycUploadImage />,
            access: [Role.SUPER_ADMIN],
            showInSiderMenu: true,
          },
        ],
      },
      // {
      //   id: "case-assignment",
      //   path: "case-assignment",
      //   name: "Case Assignment",
      //   icon: <ApartmentOutlined />,
      //   access: [Role.SUPER_ADMIN],
      //   showInSiderMenu: true,
      //   routes: [
      //     {
      //       id: "team",
      //       path: "team",
      //       name: "Team",
      //       icon: <TeamOutlined />,
      //       component: <TeamManagementPage />,
      //       access: [Role.SUPER_ADMIN],
      //       showInSiderMenu: true,
      //     },
      //     {
      //       id: "group",
      //       path: "group",
      //       name: "Group",
      //       icon: <UsergroupAddOutlined />,
      //       component: <GroupManagementPage />,
      //       access: [Role.SUPER_ADMIN],
      //       showInSiderMenu: true,
      //     },
      //     {
      //       id: "bucket",
      //       path: "bucket",
      //       name: "Bucket",
      //       icon: <ContainerOutlined />,
      //       component: <BucketManagementPage />,
      //       access: [Role.SUPER_ADMIN],
      //       showInSiderMenu: true,
      //     },
      //     {
      //       id: "region",
      //       path: "region",
      //       name: "Region",
      //       icon: <GlobalOutlined />,
      //       component: <RegionManagementPage />,
      //       access: [Role.SUPER_ADMIN],
      //       showInSiderMenu: true,
      //     },
      //     {
      //       id: "rule",
      //       path: "rule",
      //       name: "Rule",
      //       icon: <ToolOutlined />,
      //       component: <RuleManagementPage />,
      //       access: [Role.SUPER_ADMIN],
      //       showInSiderMenu: true,
      //     },
      //     {
      //       id: "rule-parameter",
      //       path: "rule-parameter",
      //       name: "Rule Parameter",
      //       icon: <HddOutlined />,
      //       component: <RuleParameterManagementPage />,
      //       access: [Role.SUPER_ADMIN],
      //       showInSiderMenu: true,
      //     },
      //   ],
      // },
      {
        id: "interest-discount",
        path: "interest-discount",
        name: "Interest Discount",
        icon: <DollarOutlined />,
        component: <InterestDiscount />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "refund-charge-config",
        path: "refund-charge-config",
        name: "Refund Charge Config",
        icon: <SettingOutlined />,
        component: <RefundChargeConfig />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "loan-insurance-config",
        path: "loan-insurance-config",
        name: "Loan Insurance Config",
        icon: <SettingOutlined />,
        component: <LoanInsuranceConfigPage />,
        access: [Role.SUPER_ADMIN],
        showInSiderMenu: true,
      },
      {
        id: "loan-insurance-config-details",
        path: "loan-insurance-config-details/id/:id",
        name: "Loan Insurance Details",
        component: <LoanInsuranceConfigDetailsPage />,
        access: [Role.SUPER_ADMIN, Role.VERIFIER],
        showInSiderMenu: false,
      },
    ],
  },
];
