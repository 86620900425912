import { request } from "http";
import { authSetupAxiosInstance } from "../../axios/authSetupAxios";
import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { LoginPayload, User } from "../../features/user/userSlice";

const login = async (requestData: LoginPayload) => {
  const url = `/redCash/admin/auth/api/v1.0/login`;
  const res = await authSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const getCurrentUser = async (requestData: any) => {
  const { userId } = requestData;
  const url = `/redCash/auth/api/v1.0/user/${userId}`;
  const res = await authSetupAxiosInstance.get(url);
  return res.data;
};

const createUser = async (requestData: User) => {
  const url = `/redCash/auth/api/v1.0/user`;

  const res = await authSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updateUser = async (requestData: any) => {
  const { userId, payload } = requestData;
  const url = `/redCash/admin/auth/api/v1.0/user/${userId}`;

  const res = await authSetupAxiosInstance.patch(url, payload);
  return res.data;
};
const resetPassword = async (requestData: any) => {
  const { callerId } = requestData;
  const url = `/redCash/api/v1.0/redone/resetPassword`;

  const res = await beSetupAxiosInstance.post(url, {
    callerId,
  });
  return res.data;
};

const getWhitelistMember = async (requestData: any) => {
  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/whitelistedMember/listing`;

  const res = await beSetupAxiosInstance.get(url, { params });

  return res.data;
};

export const uploadWhitelisted = async (requestData: any) => {
  const url = `/redCash/api/v1.0/whitelistedMember/csv/upload`;

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const res = await beSetupAxiosInstance.post(url, requestData, config);

  return res.data;
};

export const downloadWhitelistTemplate = async (requestData: any) => {
  const result = await beSetupAxiosInstance.get(
    `/redCash/api/v1.0/whitelisted/downloadTemplate`,
    { responseType: "blob" },
  );

  return result.data;
};

export const deleteWhitelistedMemberById = async (id: string) => {
  const result = await beSetupAxiosInstance.delete(
    `/redCash/api/v1.0/whitelistedMember/id/${id}`,
  );

  return result.data;
};

export const deleteWhitelistMember = async (id: any) => {
  const result = await beSetupAxiosInstance.delete(
    `/redCash/api/v1.0/whitelistedMember/id/${id}`,
  );

  return result.data;
};

const userService = {
  login,
  getCurrentUser,
  createUser,
  updateUser,
  resetPassword,
  getWhitelistMember,
  uploadWhitelisted,
  downloadWhitelistTemplate,
  deleteWhitelistedMemberById,
  deleteWhitelistMember,
};

export default userService;
