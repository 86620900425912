import { Button, Form, Input, InputNumber, message, Modal, Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { PaymentNoticeConfigType } from "../../../features/paymentNotice/paymentNoticeConfigSlice";
import { AppDispatch } from "../../../store/store";
import { createLoanInsuranceThunk } from "../../../services/masterService/masterThunk";

const LoanInsuranceConfigModal: React.FC<{
  data?: PaymentNoticeConfigType;
  callback?: () => void;
}> = ({ data, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const updateMode = data != null;

  const labelClassName = "w-[100px]";

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        dispatch(
          createLoanInsuranceThunk({
            ...values,
          }),
        )
          .unwrap()
          .then(() => {
            form.resetFields();
            if (callback) callback();
            message.success("Update Successfully");
            handleCancel();
          })
          .catch(() => message.error(`Error save loan insurance product`))
          .finally(() => setLoading(false));
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
        setLoading(false);
      });
  };

  return (
    <>
      {updateMode ? (
        <Button icon={<EditOutlined />} onClick={showModal}>
          Update
        </Button>
      ) : (
        <Button icon={<PlusOutlined />} onClick={showModal}>
          Create New
        </Button>
      )}

      <Modal
        title={"Create Loan Insurance"}
        okText={"Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={450}
      >
        <Form
          className="pt-4 pb-2"
          form={form}
          initialValues={data}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Option Code</span>}
            name="code"
            rules={[{ required: true, message: "Please enter coverage name." }]}
          >
            <Input style={{ width: "100%" }} placeholder="Please Input" />
          </Form.Item>

          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Option Name</span>}
            name="name"
            rules={[{ required: true, message: "Please enter coverage name." }]}
          >
            <Input style={{ width: "100%" }} placeholder="Please Input" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LoanInsuranceConfigModal;
