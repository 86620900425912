import { Tabs, Tag } from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import {
  getInProgressEStampAgreementsThunk,
  getPendingEStampAgreementsThunk,
  getUploadedEStampAgreementsThunk,
} from "../../services/agreementService/agreementThunk";
import { AppDispatch, RootState } from "../../store/store";
import InProgressEStampTab from "./InProgressEStampTab";
import PendingEStampTab from "./PendingEStampTab";
import UploadedEStampTab from "./UploadedEStampTab";

import type { TabsProps } from "antd";
import {
  getCompletedSmeEStampListingThunk,
  getInProgressSmeEStampListingThunk,
  getPendingSmeEStampListingThunk,
} from "../../services/smeAgreementService/smeAgreementThunk";

const SmeEStampingListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const {
    pendingSmeEStampList,
    inProgressSmeEStampList,
    completedSmeEStampList,
  } = useSelector((state: RootState) => state.smeAgreement);

  const items: TabsProps["items"] = [
    {
      key: "pending",
      label: (
        <span>
          Pending E-Stamp{" "}
          {pendingSmeEStampList?.data?.total != null && (
            <Tag>{pendingSmeEStampList.data.total}</Tag>
          )}
        </span>
      ),
      children: <PendingEStampTab />,
    },
    {
      key: "in_progress",
      label: (
        <span>
          In Progress{" "}
          {inProgressSmeEStampList?.data?.total != null && (
            <Tag>{inProgressSmeEStampList.data.total}</Tag>
          )}
        </span>
      ),
      children: <InProgressEStampTab />,
    },
    {
      key: "completed",
      label: (
        <span>
          Uploaded E-stamp{" "}
          {completedSmeEStampList?.data?.total != null && (
            <Tag>{completedSmeEStampList.data.total}</Tag>
          )}
        </span>
      ),
      children: <UploadedEStampTab />,
    },
  ];

  const fetchPendingAgreements = async (params?: any) => {
    await dispatch(
      getPendingSmeEStampListingThunk({
        query: params,
        status: "pending",
      }),
    );
  };

  const fetchInProgressAgreements = async (params?: any) => {
    await dispatch(
      getInProgressSmeEStampListingThunk({
        query: params,
        status: "in_progress",
      }),
    );
  };

  const fetchSignedAgreements = async (params?: any) => {
    await dispatch(
      getCompletedSmeEStampListingThunk({
        query: params,
        status: "completed",
      }),
    );
  };

  const onTabChange = (key: string) => {
    if (key === "pending") {
      fetchPendingAgreements(initialParams);
    } else if (key === "in_progress") {
      fetchInProgressAgreements(initialParams);
    } else if (key === "completed") {
      fetchSignedAgreements(initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchPendingAgreements(params);
    fetchInProgressAgreements(params);
    fetchSignedAgreements(params);
  });

  return (
    <>
      <PageHeader className="px-0 mb-2" title="E-Stamp Listing" />
      <Tabs
        defaultActiveKey={activeTab ?? "pending"}
        items={items}
        onChange={(key) => onTabChange(key)}
        destroyInactiveTabPane
      />
    </>
  );
};

export default SmeEStampingListingPage;
