import { Button, message, Popconfirm, Space, Table } from "antd";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "@ant-design/pro-components";

import {
  deletePaymentNoticeConfigThunk,
  getPaymentNoticeConfigThunk,
} from "../../services/paymentNoticeService/paymentNoticeThunk";
import { AppDispatch, RootState } from "../../store/store";

import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";
import RefundChargeConfigModal from "./RefundChargeConfigModal";
import { getRefundChargeConfigThunk } from "../../services/collectionService/collectionThunk";
import { displayDateAndTime } from "../../utils/datetime.util";

const RefundChargeConfig = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { refundChargeConfig } = useSelector(
    (state: RootState) => state.collection,
  );

  const handleDelete = (record: any) => {
    dispatch(deletePaymentNoticeConfigThunk({ id: record.id }))
      .unwrap()
      .then(() => dispatch(getPaymentNoticeConfigThunk()))
      .catch(() =>
        message.error(`Error remove payment notice config ${record.DPD}`),
      );
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "Refund Charge",
      dataIndex: "refundCharge",
      key: "refundCharge",
      render: (text, record) => {
        return `RM ${text}`;
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdBy",
      render: (text, record) => {
        return displayDateAndTime(record.createdAt);
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space>
          {!refundChargeConfig.isLoading && (
            <RefundChargeConfigModal
              key={record.id}
              data={record}
              callback={() => dispatch(getRefundChargeConfigThunk())}
            />
          )}
          {/* <Popconfirm
            title={`Do you want to delete this payment notice config ${record.DPD}?`}
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger>
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getRefundChargeConfigThunk());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Refund Charge Config - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title="Refund Charge Config"
        // extra={[
        //   <RefundChargeConfigModal
        //     key="createPNC"
        //     callback={() => dispatch(getPaymentNoticeConfigThunk())}
        //   />,
        // ]}
      />

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        dataSource={refundChargeConfig.data?.data}
        loading={refundChargeConfig.isLoading}
        rowClassName={() => "editable-row"}
        pagination={false}
      />
    </>
  );
};
export default RefundChargeConfig;
