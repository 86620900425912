import { Button, Input, Select, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import { applicationStateReset } from "../../../features/application/applicationSlice";
import { numberWithCommas } from "../../../helpers/number";
import { getApplicationsThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate, displayTime } from "../../../utils/datetime.util";

import type { Application } from "../../../features/application/applicationSlice";
import type { ColumnsType } from "antd/es/table";
import type { TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { getAdminsThunk } from "../../../services/adminService/adminThunk";
import { AgreementStatusEnum } from "../../../enum/agreementStepStatus";
import { onCalculateNetDisbursementAmount } from "../../../utils/calculation.util";
import { showFinalStatus } from "../../../utils/string.util";

const { Search } = Input;

const CustomerServiceSearchApplicationPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { applicationList } = useSelector(
    (state: RootState) => state.application,
  );
  const { admins } = useSelector((state: RootState) => state.admin);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<Application>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [searchState, setSearchState] = useState(initialParams.search);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("applicationNo");
  const [searchValue, setSearchValue] = useState("");

  const tablePagination = {
    total: applicationList.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Application Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
      sorter: (a, b) => a.submissionDate - b.submissionDate,
      sortOrder:
        sortedInfo.columnKey === "submissionDate" ? sortedInfo.order : null,
      render: (text, record) => displayDate(text ?? record.createdAt) ?? "-",
    },
    {
      title: "Time",
      dataIndex: "submissionDate",
      key: "submissionDate",
      width: 120,
      render: (text, record) => displayTime(text ?? record.createdAt) ?? "-",
    },
    {
      title: "Application No.",
      dataIndex: "applicationNo",
      key: "applicationNo",
      render: (text, record) => (text ? text : "-"),
    },
    {
      title: "Applicant Name",
      dataIndex: "applicantFirstName",
      key: "applicantFirstName",
      render: (text, record) =>
        record.applicantFirstName ?? record.user.firstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "applicantNric",
      key: "applicantNric",
      render: (text, record) => record.applicantNric ?? record.user.nric ?? "-",
    },
    {
      title: "Status",
      dataIndex: "detailedStatus",
      key: "detailedStatus",
      render: (text, record) =>
        record.applicationStatus === "DRAFT"
          ? record.detailedStatus
          : Array(record.Agreement) && record.Agreement.length > 0
          ? showFinalStatus(
              record.applicationStatus,
              record.Agreement[0].agreementStatus,
            )
          : showFinalStatus(record.applicationStatus, null),
    },
    {
      title: "E-Stamp",
      dataIndex: "eStamp",
      key: "eStamp",
      render: (text, record) => {
        if (
          record?.Agreement[0]?.agreementStatus ===
            AgreementStatusEnum.PENDING_DISBURSEMENT ||
          record?.Agreement[0]?.agreementStatus ===
            AgreementStatusEnum.DISBURSED
        ) {
          if (record?.Agreement[0]?.eStampingCertificateFileId === null) {
            return "Pending";
          }
          if (record?.Agreement[0]?.clickedDownloadEStampingCert === true) {
            if (record?.Agreement[0]?.approveUploadedEStampingCert === true) {
              return "Approved";
            }
            return "Uploaded";
          }
        } else {
          return "-";
        }
      },
    },
    {
      title: "Applied Loan Amount",
      dataIndex: "loanApplied",
      key: "loanApplied",
      render: (text) => (text ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Approved Loan Amount",
      dataIndex: "financeAmount",
      key: "financeAmount",
      render: (text, record) =>
        record.applicationStatus === "APPROVED"
          ? text != null
            ? `RM ${numberWithCommas(text)}`
            : "-"
          : "-",
    },
  ];

  const fetchApplications = async (params?: any) => {
    await dispatch(getApplicationsThunk(params));
  };

  const fetchAdmins = async (params?: any) => {
    await dispatch(getAdminsThunk({ params }));
  };

  const resetApplicationState = async () => {
    await dispatch(applicationStateReset());
  };

  const onSearch = (value: string) => {
    fetchApplications({
      ...initialParams,
      filters: filterState,
      search: { [searchFiled]: value.toUpperCase() },
    });
    setSearchState({ [searchFiled]: value });
    setSearchValue(value);
  };

  const handleTableChange: TableProps<Application>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
            search: {
              [searchFiled]: "",
            },
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
            search: {
              [searchFiled]: searchValue,
            },
          };

    fetchApplications(param);
    setSortedInfo(sorter as SorterResult<Application>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useLifecycles(() => {
    resetApplicationState();

    const params = {
      ...initialParams,
      filters: filterState,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
      search: {
        applicationNo: "",
      },
    };
    console.log(params);
    fetchApplications(params);
    fetchAdmins({
      pagination: {
        current: 1,
        pageSize: 10000, // get all admins
      },
    });
  });

  return (
    <>
      <Helmet>
        <title>Search Application - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Search Application" />
      <Input.Group compact>
        <Select
          defaultValue={searchFiled}
          style={{ width: 200 }}
          onChange={(value) => setSearchFiled(value)}
          options={[
            { value: "applicationNo", label: "Application No" },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="Input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={applicationList.data.data}
        loading={applicationList.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default CustomerServiceSearchApplicationPage;
