import "./index.less";

import { Button, Col, Form, Input, InputNumber, Modal, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createAgreementThunk,
  getAgreementDetailsThunk,
} from "../../services/agreementService/agreementThunk";
import { AppDispatch, RootState } from "../../store/store";
import { getApplicationDetailsThunk } from "../../services/applicationService/applicationThunk";
import { ApplicationStatusEnum } from "../../enum/applicationStepStatus";
import {
  onCalculateEstimatedMonthlyAmount,
  onCalculateLoanPayableAmount,
  onGetFirstInstalmentDate,
  onGetMonthlyInstalmentDay,
} from "../../utils/calculation.util";

const CreateAgreementModal: React.FC<{ modalOpen: boolean }> = ({
  modalOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onFinish = async () => {
    await form.validateFields().then((values) => {
      setLoading(true);

      dispatch(
        createAgreementThunk({
          productCode: applicationDetails?.data?.productCode,
          payload: {
            ...values,
            systemLoanAmountSuggested: values.userLoanAmountApplied,
            applicationId: applicationDetails?.data?.id,
            companyId: process.env.REACT_APP_COMPANY_ID,
            firstInstalmentDate: onGetFirstInstalmentDate().toISOString(),
            monthlyInstalmentDay: onGetMonthlyInstalmentDay(),
            monthlyInstalmentAmount: onCalculateEstimatedMonthlyAmount(
              applicationDetails?.data?.financeAmount ?? 0,
              applicationDetails?.data?.loanInterestRate ?? 0,
              applicationDetails?.data?.loanTenure ?? 0,
            ),
            loanPayableAmount: onCalculateLoanPayableAmount(),
            lastInstalmentAmount: onCalculateEstimatedMonthlyAmount(
              applicationDetails?.data?.financeAmount ?? 0,
              applicationDetails?.data?.loanInterestRate ?? 0,
              applicationDetails?.data?.loanTenure ?? 0,
            ),
            // signDate: values.signDate.toISOString(),
          },
        }),
      )
        .unwrap()
        .then((res) => {
          dispatch(
            getApplicationDetailsThunk({
              applicationId: applicationDetails?.data?.id,
            }),
          );
          dispatch(
            getAgreementDetailsThunk({
              agreementId: res?.id,
            }),
          );
          handleCancel();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  return (
    <>
      {applicationDetails?.data?.applicationStatus ===
        ApplicationStatusEnum.APPROVED &&
        applicationDetails.data?.agreementId == null && (
          <Button onClick={showModal}>Create Agreement</Button>
        )}
      <Modal
        centered
        title="Create Agreement"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={600}
        destroyOnClose
        okText="Create Agreement"
        confirmLoading={loading}
      >
        <Form
          form={form}
          name="createAgreement"
          layout="vertical"
          scrollToFirstError
          initialValues={{
            userLoanAmountApplied: applicationDetails?.data?.financeAmount,
            loanTenure: applicationDetails?.data?.loanTenure,
            loanInterestRate: applicationDetails?.data?.loanInterestRate,
            repaymentOption: "SELFPAY",
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="userLoanAmountApplied"
                label="User Loan Amount Applied"
                rules={[
                  {
                    required: true,
                    message: "User loan amount applied is required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                name="loanTenure"
                label="Loan Tenure"
                rules={[{ required: true, message: "Loan tenure is required" }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="loanInterestRate"
                label="Loan Interest Rate"
                rules={[
                  { required: true, message: "Loan interest rate is required" },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name="repaymentOption"
                label="Repayment Option"
                rules={[
                  { required: true, message: "Repayment option is required" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateAgreementModal;
