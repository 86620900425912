import "./index.less";

import { Button, Modal } from "antd";
import { useState } from "react";

import AccountEnquiryContent from "../AccountEnquiryContent";

export type AccountEnquiryModalProps = {
  callback?: any;
  agreementNo: string;
};

const AccountEnquiryModal: React.FC<AccountEnquiryModalProps> = (
  props: AccountEnquiryModalProps,
) => {
  const { callback, agreementNo } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = async () => {
    setIsModalOpen(false);
    if (callback) {
      callback();
    }
  };

  return (
    <>
      <Button type="link" onClick={showModal}>
        <span style={{ userSelect: "text" }}>{agreementNo}</span>
      </Button>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1500}
        footer={null}
        destroyOnClose
      >
        <AccountEnquiryContent
          searchAgreementNo={agreementNo}
          viewAccountDetailsOnly={true}
          modalView={true}
        />
      </Modal>
    </>
  );
};

export default AccountEnquiryModal;
