// import { encryptTransform } from "redux-persist-transform-encrypt";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import ekycReducer from "../features/ekyc/ekycSlice";
import agreementReducer from "../features/agreement/agreementSlice";
import applicationReducer from "../features/application/applicationSlice";
import attestationReducer from "../features/attestation/attestationSlice";
import documentUploadReducer from "../features/documentUpload/documentUploadSlice";
import preRecordedVideoReducer from "../features/preRecordedVideo/preRecordedVideoSlice";
import productReducer from "../features/product/productSlice";
import trustSignerReducer from "../features/trustSigner/trustSignerSlice";
import reportReducer from "../features/report/reportSlice";
import transactionHistoryReducer from "../features/transactionHistory/transactionHistorySlice";
import adminReducer from "../features/admin/adminSlice";
import userReducer from "../features/user/userSlice";
import disbursementReducer from "../features/disbursement/disbursementSlice";
import dropdownParameterReducer from "../features/dropdownParameter/dropdownParameterSlice";
import fetchParameterReducer from "../features/fetchParameter/fetchParameterSlice";
import collectionReducer from "../features/collection/collectionSlice";
import earlySettlementReducer from "../features/earlySettlement/earlySettlementSlice";
import paymentReducer from "../features/payment/paymentSlice";
import waiverReducer from "../features/waiver/waiverSlice";
import aocReducer from "../features/aoc/aocSlice";
import dunningHistoryReducer from "../features/dunning/dunningSlice";
import actionCodeReducer from "../features/actionCode/actionCodeSlice";
import noticesGenerationConfigReducer from "../features/noticesGenerationConfig/noticesGenerationConfigSlice";
import paymentNoticeReducer from "../features/paymentNotice/paymentNoticeSlice";
import paymentNoticeConfigReducer from "../features/paymentNotice/paymentNoticeConfigSlice";
import fieldVisitReducer from "../features/fieldVisit/fieldVisitSlice";
import noticesHistoryReducer from "../features/noticesHistory/noticesHistorySlice";
import writeOffConfigReducer from "../features/writeOff/writeOffConfigSlice";
import writeOffMonitorReducer from "../features/writeOff/writeOffMonitorSlice";
import writeOffProposalReducer from "../features/writeOff/writeOffProposalSlice";
import writeOffReducer from "../features/writeOff/writeOffSlice";
import configReducer from "../features/config/configSlice";
import refundReducer from "../features/refund/refundSlice";
import specialTaggingReducer from "../features/specialTagging/specialTaggingSlice";
import ruleParameterReducer from "../features/caseAssignment/ruleParameterSlice";
import ruleReducer from "../features/caseAssignment/ruleSlice";
import regionReducer from "../features/caseAssignment/regionSlice";
import groupReducer from "../features/caseAssignment/groupSlice";
import teamReducer from "../features/caseAssignment/teamSlice";
import bucketReducer from "../features/caseAssignment/bucketSlice";
import caseAssignmentReducer from "../features/caseAssignment/caseAssignmentSlice";
import rescheduleReducer from "../features/reschedule/rescheduleSlice";
import restructureReducer from "../features/restructure/restructureSlice";
import loanInsuranceReducer from "../features/loanInsurance/loanInsuranceSlice";
import smeApplicationReducer from "../features/smeApplication/smeApplicationSlice";
import smeAgreementReducer from "../features/smeAgreement/smeAgreementSlice";
import smeFileReducer from "../features/smeFile/smeFileSlice";
import smePreRecordedVideoReducer from "../features/smePreRecordedVideo/smePreRecordedVideoSlice";
import smeDisbursementReducer from "../features/smeDisbursement/smeDisbursementSlice";

// create a reducers for tax
import taxReducer from "../features/tax/taxSlice";
const combinedReducer = combineReducers({
  user: userReducer,
  application: applicationReducer,
  agreement: agreementReducer,
  attestation: attestationReducer,
  documentUpload: documentUploadReducer,
  product: productReducer,
  preRecordedVideo: preRecordedVideoReducer,
  trustSigner: trustSignerReducer,
  admin: adminReducer,
  disbursement: disbursementReducer,
  dropdownParameter: dropdownParameterReducer,
  fetchParameter: fetchParameterReducer,
  collection: collectionReducer,
  earlySettlement: earlySettlementReducer,
  transactionHistory: transactionHistoryReducer,
  payment: paymentReducer,
  waiver: waiverReducer,
  aoc: aocReducer,
  actionCode: actionCodeReducer,
  dunningHistory: dunningHistoryReducer,
  noticesConfig: noticesGenerationConfigReducer,
  paymentNotice: paymentNoticeReducer,
  paymentNoticeConfig: paymentNoticeConfigReducer,
  tax: taxReducer,
  fieldVisit: fieldVisitReducer,
  noticesHistory: noticesHistoryReducer,
  report: reportReducer,
  reschedule: rescheduleReducer,
  restructure: restructureReducer,
  ekyc: ekycReducer,
  writeOff: writeOffReducer,
  writeOffProposal: writeOffProposalReducer,
  writeOffMonitor: writeOffMonitorReducer,
  writeOffConfig: writeOffConfigReducer,
  config: configReducer,
  refund: refundReducer,
  specialTagging: specialTaggingReducer,
  ruleParameter: ruleParameterReducer,
  rule: ruleReducer,
  region: regionReducer,
  group: groupReducer,
  team: teamReducer,
  bucket: bucketReducer,
  caseAssignment: caseAssignmentReducer,
  loanInsurance: loanInsuranceReducer,
  smeApplication: smeApplicationReducer,
  smeAgreement: smeAgreementReducer,
  smeFile: smeFileReducer,
  smePreRecordedVideo: smePreRecordedVideoReducer,
  smeDisbursement: smeDisbursementReducer,
});

const rootReducer = (state: any, action: any) => {
  if (
    action.type === "user/logout/fulfilled" ||
    action.type === "user/login/fulfilled"
  ) {
    state = undefined;
    storage.removeItem("persist:root");
    localStorage.clear();
    console.log("Logout success! All data clear!!");
  }
  return combinedReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  // transforms: [
  //   encryptTransform({
  //     secretKey: process.env.REACT_APP_REDUX_PERSIST_STORE_KEY!,
  //     onError: function (error) {
  //       console.log(error);
  //     },
  //   }),
  // ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
