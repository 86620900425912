import "./index.less";

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import LoadingSpinner from "../../../components/LoadingSpinner";
import { earlySettlementDetailsStateReset } from "../../../features/earlySettlement/earlySettlementSlice";
import { numberWithCommas, toNumber } from "../../../helpers/number";
import {
  getEarlySettlementByIdThunk,
  getEstimateLatePaymentChargeThunk,
} from "../../../services/earlySettlementService/earlySettlementThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { roundToDecimal } from "../../../utils/number.util";
import EarlySettlementForm from "../RequestEarlySettlement/EarlySettlementForm";

const EarlySettlementDetails = () => {
  const defaultTax = 6;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { agreementId } = useParams();
  const [formInitialValues, setFormInitialValues] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { earlySettlementDetails } = useSelector(
    (state: RootState) => state.earlySettlement,
  );

  const fetchEarlySettlementDetailsById = async () => {
    dispatch(getEarlySettlementByIdThunk({ earlySettlementId: agreementId }));
  };

  const updateFormValues = async () => {
    setLoading(true);
    setFormInitialValues(null);
    await dispatch(
      getEstimateLatePaymentChargeThunk({
        agreementId: earlySettlementDetails.data?.accountDetails?.agreementId,
        settlementDate: earlySettlementDetails.data?.earlySettlementDate
          ? dayjs(earlySettlementDetails.data?.earlySettlementDate).format(
              "DD-MM-YYYY",
            )
          : dayjs().format("DD-MM-YYYY"),
      }),
    )
      .unwrap()
      .then(async (res) => {
        const totalLoanOutstandingRounded =
          toNumber(res?.totalLoanOutstanding?.amount) ?? 0;
        const latePaymentChargeRounded = res?.latePaymentCharge?.amount ?? 0;
        const earlySettlementChargeWithTax: number = +(
          earlySettlementDetails.data?.earlySettlementChargeAfterTax ?? 0
        );
        const earlySettlementTaxAmount: number = +(
          earlySettlementDetails.data?.earlySettlementTaxAmount ?? 0
        );

        const earlySettlementCharge =
          earlySettlementChargeWithTax - earlySettlementTaxAmount;

        const earlySettlementTaxPercentage: number =
          (earlySettlementTaxAmount / earlySettlementCharge) * 100;

        const miscChargeWithTax: number = +(
          earlySettlementDetails.data?.miscChargeAfterTax ?? 0
        );

        const miscTaxAmount: number = +(
          earlySettlementDetails.data?.miscTaxAmount ?? 0
        );

        const miscCharge = miscChargeWithTax - miscTaxAmount;

        const miscTaxPercentage: number = (miscTaxAmount / miscCharge) * 100;

        const total: number =
          earlySettlementChargeWithTax +
          miscChargeWithTax +
          totalLoanOutstandingRounded;

        const initialValues = {
          ...earlySettlementDetails.data,
          ...earlySettlementDetails.data?.accountDetails,
          ...earlySettlementDetails.data?.earlySettlementChargeOB,
          earlySettlementDate: earlySettlementDetails.data?.earlySettlementDate
            ? dayjs(earlySettlementDetails.data?.earlySettlementDate)
            : dayjs(),
          agreementNo: earlySettlementDetails.data?.accountDetails?.agreementNo,

          earlySettlementStatus: earlySettlementDetails.data?.status,

          latePaymentCharge: numberWithCommas(latePaymentChargeRounded),
          adjustmentCharge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB
              ?.adjustmentCharge,
          ),
          adminCharge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB?.adminCharge,
          ),
          bankCharge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB?.bankCharge,
          ),
          blacklistUpliftCharge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB
              ?.blacklistUpliftCharge,
          ),
          principalOB: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB?.principalOB,
          ),
          reminderLetter1Charge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB
              ?.reminderLetter1Charge,
          ),
          reminderLetter2Charge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB
              ?.reminderLetter2Charge,
          ),
          reminderLetter3Charge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB
              ?.reminderLetter3Charge,
          ),
          ecaCharge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB?.ecaCharge,
          ),
          fieldCharge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB?.fieldCharge,
          ),
          interestOB: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB?.interestOB,
          ),
          legalCharge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB?.legalCharge,
          ),
          lodCharge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB?.lodCharge,
          ),
          refundCharge: numberWithCommas(
            earlySettlementDetails.data?.earlySettlementChargeOB?.refundCharge,
          ),
          earlySettlementChargeDisplay: numberWithCommas(
            earlySettlementDetails?.data?.earlySettlementChargeOB
              .earlySettlementCharge,
          ),
          earlySettlementCharge: numberWithCommas(earlySettlementCharge),

          earlySettlementTaxPercentage: numberWithCommas(defaultTax),

          earlySettlementChargeWithTax: numberWithCommas(
            earlySettlementChargeWithTax,
          ),
          miscCharge: numberWithCommas(miscCharge),
          miscChargeDisplay: numberWithCommas(
            earlySettlementDetails?.data?.earlySettlementChargeOB?.miscCharge,
          ),

          miscTaxPercentage: numberWithCommas(defaultTax),
          miscChargeWithTax: numberWithCommas(miscChargeWithTax),
          totalLoanOutstanding: numberWithCommas(totalLoanOutstandingRounded),
          total: numberWithCommas(total),
        };

        setFormInitialValues(initialValues);
        setTimeout(() => setLoading(false), 100);
      });
  };

  const onBack = () => {
    navigate(-1);
  };

  useLifecycles(
    () => {},
    () => dispatch(earlySettlementDetailsStateReset()),
  );

  const initialCallbackState = {
    fetchEarlySettlementDetailsById,
  };

  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    if (agreementId) {
      callbackState.fetchEarlySettlementDetailsById();
    }
  }, [agreementId, callbackState]);

  useEffect(() => {
    if (earlySettlementDetails?.data) {
      updateFormValues();
    }
  }, [earlySettlementDetails?.data]);

  return (
    <div className="account-enquiry-container">
      {earlySettlementDetails?.isLoading ? (
        <div className="py-40">
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>Early Settlement Details - redCASH CEP</title>
          </Helmet>
          <PageHeader
            className="p-0 mb-4"
            onBack={onBack}
            title={<h3 className="m-0">Early Settlement Details</h3>}
          />
          <br />
          {loading ? (
            <div className="py-40">
              <LoadingSpinner />
            </div>
          ) : (
            earlySettlementDetails.data &&
            formInitialValues && (
              <EarlySettlementForm
                earlySettlement={earlySettlementDetails.data}
                formInitialValues={formInitialValues}
                callback={async () => {
                  await fetchEarlySettlementDetailsById();
                  await updateFormValues();
                }}
                newRequest={earlySettlementDetails.data?.status === "NEW"}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default EarlySettlementDetails;
