import { AxiosRequestConfig } from "axios";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";
import { CollectionActivityStatusEnum } from "../../enum/applicationStepStatus";

const blobConfig: AxiosRequestConfig = { responseType: "blob" };

const getAccountDetails = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountDetails/agreementNo/${requestData.agreementNo}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getAccountDetailsById = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountDetails/id/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getEnglishAccountStatementbyAgreementId = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/statement-of-account/en/agreement/${requestData.agreementId}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getMalayAccountStatementbyAgreementId = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/statement-of-account/bm/agreement/${requestData.agreementId}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getAccountStatement = async (requestData: any) => {
  const { payload } = requestData;
  const params = {
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
    // sortField: requestData.sortField,
    // sortOrder: requestData.sortOrder,
  };
  const url = `/redCash/admin/api/v1.0/statement-of-account/search`;
  const res = await collectionSetupAxiosInstance.post(url, payload, { params });
  return res.data;
};

const getAccountDetailsForAoc = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountDetails/getAocAgreement/${requestData.agreementNo}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getMIA1Listing = async (requestData: any) => {
  const params = {
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    search: requestData.search,
  };
  const url = `/redCash/api/v1.0/collectionActivity/accountDetailsList/category/${CollectionActivityStatusEnum.MIA1}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getMIA2Listing = async (requestData: any) => {
  const params = {
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    search: requestData.search,
  };

  const url = `/redCash/api/v1.0/collectionActivity/accountDetailsList/category/${CollectionActivityStatusEnum.MIA2}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getMIA3Listing = async (requestData: any) => {
  const params = {
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    search: requestData.search,
  };

  const url = `/redCash/api/v1.0/collectionActivity/accountDetailsList/category/${CollectionActivityStatusEnum.MIA3}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getNPLListing = async (requestData: any) => {
  const params = {
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    search: requestData.search,
  };

  const url = `/redCash/api/v1.0/collectionActivity/accountDetailsList/category/${CollectionActivityStatusEnum.NPL}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getBPListing = async (requestData: any) => {
  const params = {
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
  };

  const url = `/redCash/api/v1.0/collectionActivity/accountDetailsList/category/${CollectionActivityStatusEnum.BP}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getPTPListing = async (requestData: any) => {
  const params = {
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
  };

  const url = `/redCash/api/v1.0/collectionActivity/accountDetailsList/category/${CollectionActivityStatusEnum.PTP}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getDueAccountTenureDetails = async (requestData: any) => {
  const url = `/redCash/api/v1.0/dueAccountTenureDetails/agreementNo/${requestData.agreementNo}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getAccountTenureDetails = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountTenureDetails/agreementNo/${requestData.agreementNo}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getAccountWaiverCharges = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountDetails/waiverCharges/accountDetailsId/${requestData}`;
  const res = await collectionSetupAxiosInstance.get<any>(url);
  return res.data;
};

const getAccountDetailsByAgreementNo = async (agreementNo: any) => {
  const url = `/redCash/api/v1.0/accountDetails/agreementNo/${agreementNo}/specialTagging`;
  const res = await collectionSetupAxiosInstance.get(url);

  return res.data;
};

const getTaggingHistoryByAccountDetailsId = async (params: any) => {
  const { accountDetailsId, pagination, ...restParams } = params;

  const paramsData = {
    ...restParams,
    currentPage: pagination.current ?? 1,
    pageSize: pagination.pageSize ?? 20,
  };

  const url = `/redCash/api/v1.0/accountDetails/accountDetailsId/${accountDetailsId}/taggingHistory`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  return res.data;
};

const getAccountSmsHistory = async (requestData: any) => {
  const { accountDetailsId, pagination, ...restParams } = requestData;

  const paramsData = {
    ...restParams,
    currentPage: pagination.current ?? 1,
    pageSize: pagination.pageSize ?? 20,
  };

  const url = `/redCash/api/v1.0/smsPaymentNotification/history/${accountDetailsId}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  return res.data;
};

const getAccountEmailHistory = async (requestData: any) => {
  const { accountDetailsId, pagination, ...restParams } = requestData;

  const paramsData = {
    ...restParams,
    currentPage: pagination.current ?? 1,
    pageSize: pagination.pageSize ?? 20,
  };

  const url = `/redCash/api/v1.0/emailPaymentNotification/history/${accountDetailsId}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  return res.data;
};

const getCollectionHistoryLog = async (requestData: any) => {
  const { accountDetailsId, pagination, ...restParams } = requestData;

  const paramsData = {
    ...restParams,
    currentPage: pagination.current ?? 1,
    pageSize: pagination.pageSize ?? 20,
  };

  const url = `/redCash/api/v1.0/historyLog/accountDetails/${accountDetailsId}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  return res.data;
};

const getAccountDetailsCollectionSystemLog = async (requestData: any) => {
  const { accountDetailsId, pagination, ...restParams } = requestData;

  const paramsData = {
    ...restParams,
    currentPage: pagination.current ?? 1,
    pageSize: pagination.pageSize ?? 20,
  };

  const url = `/redCash/api/v1.0/accountDetails/collectionSystemLog/accountDetailsId/${accountDetailsId}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  return res.data;
};

const getRefundChargeConfig = async () => {
  const url = `/redCash/api/v1.0/refundChargesConfig`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const updateRefundChargeConfig = async (requestData: any) => {
  const { id, ...restData } = requestData;
  const url = `/redCash/api/v1.0/refundChargesConfig/${id}`;
  const res = await collectionSetupAxiosInstance.patch(url, restData);
  return res.data;
};

const getSelfBilledEInvoiceSubmissionHistoryLog = async (requestData: any) => {
  const { accountDetailsId, pagination, ...restParams } = requestData;

  const paramsData = {
    ...restParams,
    currentPage: pagination.current ?? 1,
    pageSize: pagination.pageSize ?? 20,
  };

  const url = `/redCash/api/v1.0/eInvoice/selfBilled/accountDetails/${accountDetailsId}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  console.log(res.data);
  return res.data;
};

const getSelfBilledEInvoiceListing = async (requestData: any) => {
  const { params } = requestData;
  const paramsData = {
    ...params,
  };

  const url = `/redCash/api/v1.0/eInvoice/selfBilled`;

  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  return res.data;
};

const getConsolidatedEInvoiceListing = async (requestData: any) => {
  const { params } = requestData;
  const paramsData = {
    ...params,
  };

  const url = `/redCash/api/v1.0/eInvoice/consolidated`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  console.log(res.data);
  return res.data;
};

const getIndividualCreditNoteListing = async (requestData: any) => {
  const { params } = requestData;
  const paramsData = {
    ...params,
  };
  const url = `/redCash/api/v1.0/eInvoice/selfBilledCreditNote`;

  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  return res.data;
};

const getConsolidatedCreditNoteListing = async (requestData: any) => {
  const { params } = requestData;
  const paramsData = {
    ...params,
  };
  const url = `/redCash/api/v1.0/eInvoice/consolidatedCreditNote`;

  const res = await collectionSetupAxiosInstance.get(url, {
    params: paramsData,
  });

  return res.data;
};

const getEInvoiceTotal = async () => {
  const url = "/redCash/api/v1.0/eInvoice/total";
  const res = await collectionSetupAxiosInstance.get(url);

  console.log(res.data);
  return res.data;
};

const getCollectionActivitiesFixTotal = async () => {
  const url = "/redCash/api/v1.0/collectionActivity/category/fixTotal";
  const res = await collectionSetupAxiosInstance.get(url);

  console.log(res.data);
  return res.data;
};

const collectionService = {
  getAccountDetails,
  getAccountDetailsById,
  getEnglishAccountStatementbyAgreementId,
  getMalayAccountStatementbyAgreementId,
  getAccountStatement,
  getAccountDetailsForAoc,
  getMIA1Listing,
  getMIA2Listing,
  getMIA3Listing,
  getBPListing,
  getNPLListing,
  getPTPListing,
  getDueAccountTenureDetails,
  getAccountWaiverCharges,
  getAccountTenureDetails,
  getAccountDetailsByAgreementNo,
  getTaggingHistoryByAccountDetailsId,
  getAccountSmsHistory,
  getAccountEmailHistory,
  getCollectionHistoryLog,
  getAccountDetailsCollectionSystemLog,
  getRefundChargeConfig,
  updateRefundChargeConfig,
  getSelfBilledEInvoiceSubmissionHistoryLog,
  getSelfBilledEInvoiceListing,
  getConsolidatedEInvoiceListing,
  getIndividualCreditNoteListing,
  getConsolidatedCreditNoteListing,
  getEInvoiceTotal,
  getCollectionActivitiesFixTotal,
};

export default collectionService;
