import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  createProductThunk,
  updateProductThunk,
} from "../../../services/productService/productThunk";
import { Product } from "../../../features/application/applicationSlice";
import { AppDispatch, RootState } from "../../../store/store";
import dayjs from "dayjs";
import moment from "moment";

const ProductFormModal: React.FC<{
  product?: Product;
  callback?: () => void;
}> = ({ product, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [isReferralCodeEmpty, setIsReferralCodeEmpty] = useState(
    product?.referralCode === "",
  );

  const { products } = useSelector((state: RootState) => state.product);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        const startDate = new Date(values.startDate);
        const expiredDate = new Date(values.expiredDate);

        if (startDate >= expiredDate) {
          setLoading(false);
          message.error("Start Date must be before Expired Date");
        } else {
          if (product != null) {
            dispatch(
              updateProductThunk({
                productId: product.id,
                payload: {
                  ...values,
                  startDate: new Date(values.startDate),
                  expiredDate: new Date(values.expiredDate),
                  companyId: process.env.REACT_APP_COMPANY_ID,
                },
              }),
            )
              .unwrap()
              .then(() => {
                if (callback) callback();
                message.success("Update Successfully");
                handleCancel();
              })
              .finally(() => setLoading(false));
          } else {
            dispatch(
              createProductThunk({
                ...values,
                startDate: new Date(values.startDate),
                expiredDate: new Date(values.expiredDate),
                companyId: process.env.REACT_APP_COMPANY_ID,
              }),
            )
              .unwrap()
              .then(() => {
                if (callback) callback();
                message.success("Create Successfully");
                handleCancel();
              })
              .finally(() => setLoading(false));
          }
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong. Please try again later.");
      });
  };

  const onReferralCodeChange = (e: any) => {
    if (e.target.value === "") {
      setIsReferralCodeEmpty(true);
    } else {
      setIsReferralCodeEmpty(false);
    }
  };

  return (
    <>
      <Button onClick={showModal}>
        {product != null ? "Update" : "Create Product"}
      </Button>
      <Modal
        title={product != null ? "Update Product" : "Create Product"}
        okText={product != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={700}
        destroyOnClose
      >
        <Form
          form={form}
          initialValues={{
            ...product,
            startDate: product?.startDate ? dayjs(product?.startDate) : null,
            expiredDate: product?.expiredDate
              ? dayjs(product?.expiredDate)
              : null,
          }}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter the name!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Code"
                name="code"
                rules={[{ required: true, message: "Please enter the code!" }]}
              >
                <Input
                  disabled={product != null && form.getFieldValue("code") == 8}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Referral Code"
                name="referralCode"
                // rules={[
                //   { required: true, message: "Please enter referral code!" },
                // ]}
              >
                <Input onChange={onReferralCodeChange} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Interest Rate"
                name="interestRate"
                rules={[
                  { required: true, message: "Please enter interest rate!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Stamping Duty (%)"
                name="stampingDutyPercentage"
                rules={[
                  { required: true, message: "Please enter stamping duty!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            {!isReferralCodeEmpty && (
              <Col span={8}>
                <Form.Item
                  label="Processing Fee to Discount"
                  name="processingFeeToDiscount"
                  rules={[
                    {
                      required: true,
                      message: "Please enter processing fee to discount!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Min Age Checking"
                name="minAgeChecking"
                rules={[
                  { required: true, message: "Please enter min age checking!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Max Age Checking"
                name="maxAgeChecking"
                rules={[
                  { required: true, message: "Please enter max age checking!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Min Gross Income Checking"
                name="minGrossIncomeChecking"
                rules={[
                  {
                    required: true,
                    message: "Please enter min gross income checking!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Min Tenure"
                name="minTenure"
                rules={[
                  { required: true, message: "Please enter min tenure!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Max Tenure"
                name="maxTenure"
                rules={[
                  { required: true, message: "Please enter max tenure!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Interval Tenure"
                name="intervalTenure"
                rules={[
                  { required: true, message: "Please enter interval tenure!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Min Loan Amount"
                name="minLoanAmount"
                rules={[
                  { required: true, message: "Please enter min loan amount!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Max Loan Amount"
                name="maxLoanAmount"
                rules={[
                  { required: true, message: "Please enter max loan amount!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Interval Loan Amount"
                name="intervalLoanAmount"
                rules={[
                  {
                    required: true,
                    message: "Please enter interval loan amount!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Processing Fee"
                name="processingFee"
                rules={[
                  { required: true, message: "Please enter processing Fee!" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[
                  { required: true, message: "Please enter start date!" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Expired Date"
                name="expiredDate"
                rules={[
                  { required: true, message: "Please enter expired date!" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ProductFormModal;
