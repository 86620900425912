import { Button, Input, message, Popconfirm, Space } from "antd";
import { SmeApplication } from "../../../../../features/smeApplication/smeApplicationSlice";
import { SME_APPLICATION_STATUS } from "../../../../../enum/smeApplicationStatus";
import { useState } from "react";
import { Role } from "../../../../../enum/roles";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import {
  approveSmeApplicationThunk,
  getSmeApplicationDetailsThunk,
  getSmeApplicationsListingThunk,
  updateSmeApplicationStatusThunk,
} from "../../../../../services/smeApplicationService/smeApplicationThunk";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;

export const UpdateSmeApplicationAction = ({ smeApplicationDetails }: { smeApplicationDetails: SmeApplication }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const applicationStatus = smeApplicationDetails?.status;

  const [remarks, setRemarks] = useState("");
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [referBackLoading, setReferBackLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [followUpLoading, setFollowUpLoading] = useState(false);

  const viewRemarkInput = [SME_APPLICATION_STATUS.REFERBACK, SME_APPLICATION_STATUS.FOLLOWED_UP, SME_APPLICATION_STATUS.CANCELED, SME_APPLICATION_STATUS.PENDING, SME_APPLICATION_STATUS.SUBMITTED_FOR_VERIFIER_2, SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_1, SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_2].includes(applicationStatus);

  const canCancel = [Role.SUPER_ADMIN, Role.VERIFIER].includes(currentRole) && [SME_APPLICATION_STATUS.PENDING, SME_APPLICATION_STATUS.FOLLOWED_UP].includes(applicationStatus);
  const canFollowUp = [Role.SUPER_ADMIN, Role.VERIFIER].includes(currentRole) && [SME_APPLICATION_STATUS.PENDING].includes(applicationStatus);
  const canSubmitToVerifier2 = [Role.SUPER_ADMIN, Role.VERIFIER].includes(currentRole) && [SME_APPLICATION_STATUS.PENDING, SME_APPLICATION_STATUS.REFERBACK, SME_APPLICATION_STATUS.FOLLOWED_UP].includes(applicationStatus);
  const canSubmitToApproval1 = [Role.SUPER_ADMIN, Role.VERIFIER_2].includes(currentRole) && [SME_APPLICATION_STATUS.SUBMITTED_FOR_VERIFIER_2].includes(applicationStatus);
  const canSubmitToApproval2 = [Role.SUPER_ADMIN, Role.APPROVER].includes(currentRole) && [SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_1].includes(applicationStatus);
  const canApprove = [Role.SUPER_ADMIN, Role.APPROVER_2].includes(currentRole) && [SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_2].includes(applicationStatus);
  const canReferback = [Role.SUPER_ADMIN, Role.VERIFIER_2, Role.APPROVER, Role.APPROVER_2].includes(currentRole) && [SME_APPLICATION_STATUS.SUBMITTED_FOR_VERIFIER_2, SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_1, SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_2].includes(applicationStatus);
  const canReject = [Role.SUPER_ADMIN, Role.VERIFIER, Role.VERIFIER_2, Role.APPROVER, Role.APPROVER_2].includes(currentRole) && [SME_APPLICATION_STATUS.PENDING, SME_APPLICATION_STATUS.FOLLOWED_UP, SME_APPLICATION_STATUS.REFERBACK, SME_APPLICATION_STATUS.SUBMITTED_FOR_VERIFIER_2, SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_1, SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_2].includes(applicationStatus);
  const canSubmitToPending = [Role.SUPER_ADMIN, Role.VERIFIER].includes(currentRole) && [SME_APPLICATION_STATUS.FOLLOWED_UP].includes(applicationStatus);

  const fetchSmeApplicationListing = () => {
    dispatch(getSmeApplicationsListingThunk({}));
  }

  const resetRemarks = () => {
    setRemarks("");
  };

  const handleUpdateApplicationStatus = (status: SME_APPLICATION_STATUS) => {

    if (remarks === "" || remarks === null) {
      message.error("Please enter remarks");
      return;
    }

    if (status === SME_APPLICATION_STATUS.APPROVED) {
      setApproveLoading(true);

      const approvePayload = {
        createdBy: localStorage.getItem("username"),
        remarks: remarks,
      };

      dispatch(approveSmeApplicationThunk({ id: smeApplicationDetails.id, language: "EN", payload: approvePayload }))
        .unwrap()
        .then(() => {
          message.success("Application approved");
          resetRemarks();
          fetchSmeApplicationListing();
          navigate(-1)
        }).catch(() => {
          message.error("Failed to approve application status");
        })
        .finally(() => {
          setApproveLoading(false);
        });

      return;
    } else if (status === SME_APPLICATION_STATUS.REJECTED) {
      setRejectLoading(true);
    } else if (status === SME_APPLICATION_STATUS.REFERBACK) {
      setReferBackLoading(true);
    } else if ([SME_APPLICATION_STATUS.SUBMITTED_FOR_VERIFIER_2, SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_1, SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_2].includes(status)) {
      setSubmitLoading(true);
    } else if (status === SME_APPLICATION_STATUS.CANCELED) {
      setCancelLoading(true);
    } else if (status === SME_APPLICATION_STATUS.FOLLOWED_UP) {
      setFollowUpLoading(true);
    }

    const payload = {
      status: status,
      createdBy: localStorage.getItem("username"),
      remarks: remarks,
    };

    dispatch(updateSmeApplicationStatusThunk({ id: smeApplicationDetails.id, payload }))
      .unwrap()
      .then(() => {
        message.success("Application status updated");
        fetchSmeApplicationListing();
        navigate(-1)
      }).catch(() => {
        message.error("Failed to update application status");
      })
      .finally(() => {
        setSubmitLoading(false);
        setReferBackLoading(false);
        setRejectLoading(false);
        setApproveLoading(false);
        setCancelLoading(false);
        setFollowUpLoading(false);
      });
  };

  return (
    <>
      {viewRemarkInput && (
        <div className="mb-2">
          <p className="info-title">Remark</p>
          <TextArea value={remarks} onChange={(e) => setRemarks(e.target.value)} rows={4} />
          <br />
          <br />
        </div>
      )}
      <>
        <div className="flex justify-end mb-4">
          <Space>
            {canCancel && (
              <>
                <Button
                  size="large"
                  loading={cancelLoading}
                  onClick={() => handleUpdateApplicationStatus(SME_APPLICATION_STATUS.CANCELED)}
                >
                  Cancel
                </Button>
              </>
            )}

            {canFollowUp && (
              <>
                <Button
                  danger
                  size="large"
                  loading={followUpLoading}
                  onClick={() => handleUpdateApplicationStatus(SME_APPLICATION_STATUS.FOLLOWED_UP)}
                >
                  Follow Up
                </Button>
              </>
            )}

            {canReferback && (
              <Button
                size="large"
                loading={referBackLoading}
                onClick={() => handleUpdateApplicationStatus(SME_APPLICATION_STATUS.REFERBACK)}
              >
                Referback
              </Button>
            )}

            {canSubmitToVerifier2 && (
              <>
                <Button
                  type="primary"
                  size="large"
                  loading={submitLoading}
                  onClick={() => handleUpdateApplicationStatus(SME_APPLICATION_STATUS.SUBMITTED_FOR_VERIFIER_2)}
                >
                  Submit to Verifier 2
                </Button>
              </>
            )}

            {canSubmitToApproval1 && (
              <Button
                type="primary"
                size="large"
                loading={submitLoading}
                onClick={() => handleUpdateApplicationStatus(SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_1)}
              >
                Submit to Approval 1
              </Button>
            )}

            {canSubmitToApproval2 && (
              <Button
                type="primary"
                size="large"
                loading={submitLoading}
                onClick={() => handleUpdateApplicationStatus(SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_2)}
              >
                Submit to Approval 2
              </Button>
            )}

            {canApprove && (
              <Button
                type="primary"
                size="large"
                loading={approveLoading}
                onClick={() => handleUpdateApplicationStatus(SME_APPLICATION_STATUS.APPROVED)}
              >
                Approve
              </Button>
            )}

            {canReject && (
              <Popconfirm
                title="Reject the application"
                description="Are you sure to reject this application?"
                onConfirm={() => handleUpdateApplicationStatus(SME_APPLICATION_STATUS.REJECTED)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="large" loading={rejectLoading} danger>Reject</Button>
              </Popconfirm>
            )}
          </Space>
        </div>
      </>
    </>
  );
};
