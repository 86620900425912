import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import {
  createRestructureCase,
  getRestructureCase,
  getRestructureListing,
  getRestructureQuotation,
  updateRestructureCase,
  updateRestructureCaseStatus,
} from "./restructureService";

export const createRestructureCaseThunk = createAsyncThunk(
  "restructure/createRestructureCase",
  async (requestData: any, thunkAPI) => {
    try {
      return await createRestructureCase(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRestructureCaseThunk = createAsyncThunk(
  "restructure/getRestructureCase",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRestructureCase(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRestructureCaseThunk = createAsyncThunk(
  "restructure/updateRestructureCase",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateRestructureCase(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRestructureCaseStatusThunk = createAsyncThunk(
  "restructure/updateRestructureCaseStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateRestructureCaseStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getOpenRestructureListingThunk = createAsyncThunk(
  "restructure/getOpenRestructureListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRestructureListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingRestructureListingThunk = createAsyncThunk(
  "restructure/getPendingRestructureListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRestructureListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVoidRestructureListingThunk = createAsyncThunk(
  "restructure/getVoidRestructureListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRestructureListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApprovedRestructureListingThunk = createAsyncThunk(
  "restructure/getApprovedRestructureListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRestructureListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferbackRestructureListingThunk = createAsyncThunk(
  "restructure/getReferbackRestructureListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRestructureListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRestructureQuotationThunk = createAsyncThunk(
  "restructure/getRestructureQuotation",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRestructureQuotation(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
