import "./index.less";

import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getHistoryLogsByApplicationIDAndAgreementIDThunk,
  getHistoryLogsByApplicationIDThunk,
} from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDateAndTime } from "../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";

const HistoryLogTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { applicationDetails, historyLogs } = useSelector(
    (state: RootState) => state.application,
  );

  const columns: ColumnsType<any> = [
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Old Values",
      dataIndex: "oldValueString",
      key: "oldValueString",
    },
    {
      title: "New Values",
      dataIndex: "newValueString",
      key: "newValueString",
    },
  ];

  useEffect(() => {
    if (applicationDetails.data?.id) {
      if (applicationDetails.data?.agreementId) {
        dispatch(
          getHistoryLogsByApplicationIDAndAgreementIDThunk({
            applicationId: applicationDetails.data?.id,
            agreementId: applicationDetails.data?.agreementId,
          }),
        );
      } else {
        dispatch(
          getHistoryLogsByApplicationIDThunk({
            applicationId: applicationDetails.data?.id,
          }),
        );
      }
    }
  }, [applicationDetails.data?.id, applicationDetails.data?.agreementId]);

  return (
    <Table
      dataSource={historyLogs?.data}
      className="min-w-fit"
      columns={columns}
      bordered={true}
    />
  );
};

export default HistoryLogTab;
