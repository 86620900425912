import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { ekycSetupAxiosInstance } from "../../axios/ekycSetupAxios";
import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";
import { experianServiceAxiosInstance } from "../../axios/experianServiceAxios";
import { AxiosRequestConfig } from "axios";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";
import { fileUploadSetupAxiosInstance } from "../../axios/fileUploadAxios";

const blobConfig: AxiosRequestConfig = { responseType: "blob" };

const getFileById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/admin/api/v1.0/file/${fileId}`;
  const res = await beSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getMasterSetupFileById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/masterSetup/file/id/${fileId}/module/master`;
  const res = await fileUploadSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getFilesByEntityId = async (requestData: any) => {
  const { entityId } = requestData;

  const url = `/redCash/admin/api/v1.0/file/entity/${entityId}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getMasterSetupFilesByEntityId = async (requestData: any) => {
  const { entityId } = requestData;
  const url = `/redCash/admin/master/api/v1.0/file/entity/${entityId}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const getEkycFilesByEntityId = async (requestData: any) => {
  const { entityId } = requestData;

  const url = `/redCash/api/v1.0/file/entity/${entityId}`;
  const res = await ekycSetupAxiosInstance.get(url);
  return res.data;
};

const getEkycFileById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/file/${fileId}`;
  const res = await ekycSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const fileUpload = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/file`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await beSetupAxiosInstance.post(url, requestData, config);
  return res.data;
};

const masterSetupFileUpload = async (requestData: any) => {
  const url = `/redCash/api/v1.0/file`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  };
  const res = await fileUploadSetupAxiosInstance.post(url, requestData, config);
  return res.data;
};

const fileDelete = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/admin/api/v1.0/file/${fileId}`;
  const res = await beSetupAxiosInstance.delete(url);
  return res.data;
};

const agreementFileDelete = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/file/deleteAgreementFile`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const masterFileDelete = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/masterSetup/file/${fileId}/module/master`;
  const res = await fileUploadSetupAxiosInstance.delete(url);
  return res.data;
};

const getApplicationFormById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/application-form/${fileId}`;
  const res = await beSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getExperianReportById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/experian/file/${fileId}`;
  const res = await experianServiceAxiosInstance.get(url, blobConfig);
  return res.data;
};

const waiverFileUpload = async (requestData: any) => {
  const url = `/redCash/api/v1.0/waiver/file`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await collectionSetupAxiosInstance.post(url, requestData, config);
  return res.data;
};

const waiverFileDelete = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/waiver/file/${fileId}`;
  const res = await collectionSetupAxiosInstance.delete(url);
  return res.data;
};

const getWaiverFileById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/waiver/file/${fileId}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getWaiverFileByEntityId = async (requestData: any) => {
  const { entityId } = requestData;
  const url = `/redCash/api/v1.0/waiver/file/entity/${entityId}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const fileUploadByType = async (requestData: any) => {
  const url = `/redCash/api/v1.0/${requestData.type}/file`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await collectionSetupAxiosInstance.post(url, requestData, config);
  return res.data;
};

const fileDeleteByType = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/${requestData.type}/file/${fileId}`;
  const res = await collectionSetupAxiosInstance.delete(url);
  return res.data;
};

const getFileByIdByType = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/${requestData.type}/file/${fileId}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getFileByEntityIdByType = async (requestData: any) => {
  const { entityId } = requestData;
  const url = `/redCash/api/v1.0/${requestData.type}/file/entity/${entityId}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getEInvoiceFileById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/eInvoice/file/${fileId}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const fileService = {
  getFileById,
  getFilesByEntityId,
  fileUpload,
  fileDelete,
  getEkycFilesByEntityId,
  getEkycFileById,
  getMasterSetupFileById,
  masterSetupFileUpload,
  getMasterSetupFilesByEntityId,
  masterFileDelete,
  getApplicationFormById,
  getExperianReportById,
  agreementFileDelete,
  waiverFileUpload,
  waiverFileDelete,
  getWaiverFileById,
  getWaiverFileByEntityId,
  fileUploadByType,
  fileDeleteByType,
  getFileByIdByType,
  getFileByEntityIdByType,
  getEInvoiceFileById,
};

export default fileService;
