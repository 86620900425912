import { createSlice } from "@reduxjs/toolkit";
import { getWriteOffMonitorListingThunk } from "../../services/writeOffService/writeOffThunk";

export type writeOffMonitor = {
  id: string;
  agreementNo: string;
  writeOffDate: string;
  lastPaymentDate: string;
  writeOffAmount: number;
  addOnCharges: number;
  waiver: number;
  payment: number;
  writeOffOutstandingBalance: number;
};

export type writeOffMonitorResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: writeOffMonitor[];
};

export type writeOffState = {
  writeOffMonitorListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffMonitorResponse;
  };
};

const initialState: writeOffState = {
  writeOffMonitorListing: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
};

export const writeOffMonitorSlice = createSlice({
  name: "writeOffMonitor",
  initialState,
  reducers: {
    writeOffMonitorStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWriteOffMonitorListingThunk.pending, (state) => {
        state.writeOffMonitorListing.isLoading = true;
      })
      .addCase(getWriteOffMonitorListingThunk.fulfilled, (state, action) => {
        state.writeOffMonitorListing.isLoading = false;
        state.writeOffMonitorListing.data = action.payload;
      })
      .addCase(getWriteOffMonitorListingThunk.rejected, (state, action) => {
        state.writeOffMonitorListing.isLoading = false;
        state.writeOffMonitorListing.isError = true;
        state.writeOffMonitorListing.errorMessage = action.payload;
      });
  },
});

export const { writeOffMonitorStateReset } = writeOffMonitorSlice.actions;

export default writeOffMonitorSlice.reducer;
