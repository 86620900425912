import { caseAssignmentAxiosInstance } from "../../../axios/caseAssignmentAxios";

const getAllBucket = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/caseAssignment/api/v1/bucket/listing`;
  const res = await caseAssignmentAxiosInstance.get(url, { params });
  return res.data;
};

const createBucket = async (requestData: any) => {
  const url = `/caseAssignment/api/v1/bucket`;
  const res = await caseAssignmentAxiosInstance.post(url, requestData);
  return res.data;
};

const updateBucket = async (requestData: any) => {
  const { bucketId, payload } = requestData;
  const url = `/caseAssignment/api/v1/bucket/id/${bucketId}`;
  const res = await caseAssignmentAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteBucket = async (requestData: any) => {
  const { bucketId } = requestData;
  const url = `/caseAssignment/api/v1/bucket/id/${bucketId}`;
  const res = await caseAssignmentAxiosInstance.delete(url);
  return res.data;
};

const bucketService = {
  getAllBucket,
  createBucket,
  updateBucket,
  deleteBucket,
};

export default bucketService;
