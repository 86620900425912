import { createSlice } from "@reduxjs/toolkit";
import {
  getLoanInsuranceByIdThunk,
  getLoanInsuranceListThunk,
} from "../../services/masterService/masterThunk";
import {
  getLoanInsuranceBatchThunk,
  getLoanInsuranceBatch2Thunk,
} from "../../services/loanInsuranceService/loanInsuranceThunk";

export interface LoanInsuranceCoverage {
  id: string;
  coverageName: string;
  coverageAmount: number;
}

export interface LoanInsurancePlan {
  id: string;
  name: string;
  code: string;
  isActive: boolean;
  createdAt?: string;
  updatedAt?: string;
}
export interface LoanInsurance {
  id: string;
  name: string;
  code: string;
  isActive: boolean;
  createdAt?: string;
  updatedAt?: string;
  mandatory?: boolean;
  commision: number;
  serviceTax: number;
  loanInsuranceCoverage: LoanInsuranceCoverage[];
  loanInsurancePlan: LoanInsurancePlan[];
}

export interface LoanInsuranceBatch {
  id: string;
  batchNo: string;
  status: string;
  postDate?: string;
  completedDate?: string;
  uploadedDate?: string;
  createdAt: string;
  updatedAt: string;
  isDownloaded: boolean;
  isUploaded: boolean;
}

export interface LoanInsuranceResponse {
  total: number;
  currentPage: number;
  pageSize: number;
  data: LoanInsurance[];
}

export interface LoanInsuranceBatchResponse {
  searchTotal: number;
  fixTotal: number;
  currentPage: number;
  pageSize: number;
  data: LoanInsuranceBatch[];
}

export interface LoanInsuranceState {
  loanInsuranceState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: LoanInsuranceResponse;
  };
  loanInsuranceDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: LoanInsurance;
  };
  loanInsuranceBatch1: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: LoanInsuranceBatchResponse;
  };
  loanInsuranceBatch2: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: LoanInsuranceBatchResponse;
  };
}

const initialState: LoanInsuranceState = {
  loanInsuranceState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  loanInsuranceDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      id: "",
      name: "",
      isActive: false,
      createdAt: "",
      updatedAt: "",
      code: "",
      commision: 0,
      serviceTax: 0,
      mandatory: false,
      loanInsuranceCoverage: [],
      loanInsurancePlan: [],
    },
  },
  loanInsuranceBatch1: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      fixTotal: 0,
      searchTotal: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  loanInsuranceBatch2: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      fixTotal: 0,
      searchTotal: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
};

export const loanInsuranceSlice = createSlice({
  name: "loanInsurance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoanInsuranceListThunk.pending, (state) => {
        state.loanInsuranceState.isLoading = true;
      })
      .addCase(getLoanInsuranceListThunk.fulfilled, (state, action) => {
        state.loanInsuranceState.isLoading = false;
        state.loanInsuranceState.data = action.payload;
      })
      .addCase(getLoanInsuranceListThunk.rejected, (state, action) => {
        state.loanInsuranceState.isLoading = false;
        state.loanInsuranceState.isError = true;
        state.loanInsuranceState.errorMessage = action.payload;
      });

    builder
      .addCase(getLoanInsuranceByIdThunk.pending, (state) => {
        state.loanInsuranceDetails.isLoading = true;
      })
      .addCase(getLoanInsuranceByIdThunk.fulfilled, (state, action) => {
        state.loanInsuranceDetails.isLoading = false;
        state.loanInsuranceDetails.data = action.payload;
      })
      .addCase(getLoanInsuranceByIdThunk.rejected, (state, action) => {
        state.loanInsuranceDetails.isLoading = false;
        state.loanInsuranceDetails.isError = true;
        state.loanInsuranceDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getLoanInsuranceBatchThunk.pending, (state) => {
        state.loanInsuranceBatch1.isLoading = true;
      })
      .addCase(getLoanInsuranceBatchThunk.fulfilled, (state, action) => {
        state.loanInsuranceBatch1.isLoading = false;
        state.loanInsuranceBatch1.data = action.payload;
      })
      .addCase(getLoanInsuranceBatchThunk.rejected, (state, action) => {
        state.loanInsuranceBatch1.isLoading = false;
        state.loanInsuranceBatch1.isError = true;
        state.loanInsuranceBatch1.errorMessage = action.payload;
      });

    builder
      .addCase(getLoanInsuranceBatch2Thunk.pending, (state) => {
        state.loanInsuranceBatch2.isLoading = true;
      })
      .addCase(getLoanInsuranceBatch2Thunk.fulfilled, (state, action) => {
        state.loanInsuranceBatch2.isLoading = false;
        state.loanInsuranceBatch2.data = action.payload;
      })
      .addCase(getLoanInsuranceBatch2Thunk.rejected, (state, action) => {
        state.loanInsuranceBatch2.isLoading = false;
        state.loanInsuranceBatch2.isError = true;
        state.loanInsuranceBatch2.errorMessage = action.payload;
      });
  },
});

export default loanInsuranceSlice.reducer;
