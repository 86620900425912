import {
  Button,
  Form,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../../../../store/store";
import { LoanInsurancePlan } from "../../../../../features/loanInsurance/loanInsuranceSlice";
import {
  createLoanInsurancePlanThunk,
  deleteLoanInsurancePlanByIdThunk,
  updateLoanInsurancePlanByIdThunk,
} from "../../../../../services/masterService/masterThunk";

const LoanInsurancePlanModal: React.FC<{
  loanInsuranceProductId?: string;
  data?: LoanInsurancePlan;
  callback?: () => void;
}> = ({ loanInsuranceProductId, data, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const updateMode = data != null;

  const labelClassName = "w-[100px]";

  const showModal = () => {
    setIsModalOpen(true);
    console.log(data);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        let payload;

        if (updateMode) {
          payload = {
            ...values,
            id: data.id,
            amount: Number(values.amount),
          };

          dispatch(updateLoanInsurancePlanByIdThunk(payload))
            .unwrap()
            .then(() => {
              form.resetFields();
              if (callback) callback();
              message.success("Update Successfully");
              handleCancel();
            })
            .catch(() => message.error(`Error save loan insurance plan`))
            .finally(() => setLoading(false));
        } else {
          payload = {
            ...values,
            loanInsuranceProductId,
          };

          dispatch(createLoanInsurancePlanThunk(payload))
            .unwrap()
            .then(() => {
              form.resetFields();
              if (callback) callback();
              message.success("Update Successfully");
              handleCancel();
            })
            .catch(() => message.error(`Error save loan insurance plan`))
            .finally(() => setLoading(false));
        }
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
        setLoading(false);
      });
  };

  const onDeleteLoanInsurancePlan = async () => {
    if (data) {
      setLoading(true);
      dispatch(deleteLoanInsurancePlanByIdThunk({ id: data.id }))
        .unwrap()
        .then(() => {
          if (callback) callback();
          message.success("Delete Successfully");
        })
        .catch(() => message.error(`Error delete loan insurance plan`))
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {updateMode ? (
        <>
          <Space>
            <Button icon={<EditOutlined />} onClick={showModal}>
              View
            </Button>
            <Popconfirm
              title="Delete this loan insurance plan?"
              onConfirm={() => onDeleteLoanInsurancePlan()}
              okText="Yes"
              cancelText="No"
            >
              <Button>Delete</Button>
            </Popconfirm>
          </Space>
        </>
      ) : (
        <Row justify="end" className="mb-2" align="bottom">
          <Button icon={<PlusOutlined />} onClick={showModal}>
            New
          </Button>
        </Row>
      )}

      <Modal
        title={
          data != null
            ? "Update Loan Insurance Plan"
            : "Create Loan Insurance Plan"
        }
        okText={data != null ? "Save" : "Add New"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={450}
      >
        <Form
          className="pt-4 pb-2"
          form={form}
          initialValues={data}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Tenure</span>}
            name="tenure"
            rules={[{ required: true, message: "Please enter Tenure." }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Please Input"
              controls={false}
              inputMode="numeric"
              type="number"
            />
          </Form.Item>

          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Amount (RM)</span>}
            name="amount"
            rules={[{ required: true, message: "Please enter Amount." }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Please Input"
              controls={false}
              inputMode="numeric"
              type="number"
            />
          </Form.Item>

          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Tax (%)</span>}
            name="tax"
            rules={[{ required: true, message: "Please enter Tax." }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Please Input"
              controls={false}
              inputMode="numeric"
              type="number"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LoanInsurancePlanModal;
