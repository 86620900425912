import "./index.less";

import { AppDispatch, RootState } from "../../../store/store";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Tabs,
  TabsProps,
  Tag,
  Typography,
  Upload,
  message,
} from "antd";
import { Key, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  addWriteOffRemarkThunk,
  downloadWriteOffApprovalListThunk,
  getWriteOffByBatchNoThunk,
  getWriteOffFileByFileIdThunk,
  getWriteOffRemarkListingByBatchIdThunk,
  getWriteOffSummaryByBatchIdThunk,
  selectAgreementNoInWriteOffBatchThunk,
  updateWriteOffStatusThunk,
  uploadWriteOffApprovalFormThunk,
  uploadWriteOffApprovalListThunk,
} from "../../../services/writeOffService/writeOffThunk";
import { downloadLink, previewNewPage } from "../../../helpers/downloadLink";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { PageHeader } from "@ant-design/pro-components";
import { UploadOutlined } from "@ant-design/icons";
import WriteOffProposalDetails from "./WriteOffProposalDetails";
import WriteOffProposalSummary from "./WriteOffProposalSummary";
import WriteOffRemarksTable from "./RemarksTable";
import { WriteOffStatusEnum } from "../../../enum/writeOff";
import { WriteOffSummaryDetailsPageLabel } from "../../../constant/writeOff";
import { setWriteOffBatch } from "../../../features/writeOff/writeOffSlice";
import { Role } from "../../../enum/roles";
import jwt_decode from "jwt-decode";

const { TextArea } = Input;
const { Text, Title } = Typography;

const editableStatus = [WriteOffStatusEnum.NEW, WriteOffStatusEnum.REFERBACK];

const WriteOffProposalPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { batchNo } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const [selectedRow, setSelectedRow] = useState<Key[]>([]);
  const [status, setStatus] = useState<WriteOffStatusEnum>(
    WriteOffStatusEnum.NEW,
  );
  const [justificationValue, setJustificationValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [populate, setPopulate] = useState(false);
  const [approvalListFileName, setApprovalListFileName] = useState("");
  const [approvalDocFileName, setApprovalDocFileName] = useState("");

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const { writeOffBatch } = useSelector((state: RootState) => state.writeOff);
  const { writeOffSummary, writeOffDetails, writeOffRemarkListing } =
    useSelector((state: RootState) => state.writeOffProposal);

  const fetchOneWriteOff = async () => {
    await dispatch(
      getWriteOffByBatchNoThunk({
        filters: { id: writeOffBatch?.data?.id },
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(setWriteOffBatch(res.data[0]));
        setStatus(
          WriteOffStatusEnum[
            res.data[0]?.status as keyof typeof WriteOffStatusEnum
          ],
        );
      })
      .catch((error) => message.error("Error to get write off batch info."));
  };

  const fetchWriteOffSummary = async () => {
    await dispatch(
      getWriteOffSummaryByBatchIdThunk({ id: writeOffBatch?.data?.id }),
    );
  };

  const fetchWriteOffBatchRemark = async () => {
    await dispatch(
      getWriteOffRemarkListingByBatchIdThunk({
        id: writeOffBatch?.data?.id,
      }),
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "summary",
      label: "Write Off Proposal Summary",
      children: (
        <WriteOffProposalSummary
          dataSource={writeOffSummary}
          callback={fetchWriteOffSummary}
        />
      ),
    },
    {
      key: "details",
      label: "Write Off Proposal Details",
      children:
        status !== WriteOffStatusEnum.PENDING ||
        (status == WriteOffStatusEnum.PENDING && populate) ? (
          <WriteOffProposalDetails
            dataSource={writeOffDetails}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            batchStatus={status}
          />
        ) : (
          <Row justify={"space-around"} className="m-10">
            <Button onClick={() => setPopulate(true)}>Populate</Button>
          </Row>
        ),
    },
  ];

  const onBack = () => {
    navigate("/write-off/write-off-listing");
  };

  const onTabChange = (key: string) => {
    if (key === "summary") {
      fetchWriteOffSummary();
    }

    queryParams.set("tab", key);
    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const initialCallbackState = {
    fetchOneWriteOff,
    fetchWriteOffSummary,
    fetchWriteOffBatchRemark,
  };

  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    if (batchNo) {
      callbackState.fetchOneWriteOff();
      callbackState.fetchWriteOffSummary();
      callbackState.fetchWriteOffBatchRemark();
    }
  }, [batchNo, callbackState]);

  useEffect(() => {
    if (writeOffDetails.data) {
      setSelectedRow(
        writeOffDetails.data?.data
          ?.filter((item: any) => item.status == "SELECTED")
          .map((item) => item.id),
      );
    }
  }, [writeOffDetails.data]);

  const updateSelectedAccount = async () => {
    // save selected accounts
    const initialSelected = writeOffDetails.data?.data
      ?.filter((item: any) => item.status == "SELECTED")
      .map((item) => item.id);
    const newSelected = selectedRow.filter(
      (id) => !initialSelected.includes(id.toString()),
    );
    const deselected = initialSelected.filter(
      (id) => !selectedRow.includes(id),
    );
    if (newSelected.length > 0) {
      await dispatch(
        selectAgreementNoInWriteOffBatchThunk({
          writeOffAccounts: newSelected,
          id: writeOffBatch.data?.id,
          action: "select",
        }),
      )
        .unwrap()
        .catch((error) => message.error("Error save changes.", error));
    }
    if (deselected.length > 0) {
      await dispatch(
        selectAgreementNoInWriteOffBatchThunk({
          writeOffAccounts: deselected,
          id: writeOffBatch.data?.id,
          action: "deselect",
        }),
      )
        .unwrap()
        .catch((error) => message.error("Error save changes.", error));
    }
  };

  const updateRemark = () => {
    if (justificationValue) {
      dispatch(
        addWriteOffRemarkThunk({
          batchId: writeOffBatch?.data?.id,
          remarks: justificationValue,
          username: localStorage.getItem("username"),
        }),
      )
        .unwrap()
        .then((res) => {
          fetchWriteOffBatchRemark();
          setJustificationValue("");
        })
        .catch((res) => message.error("Failed to save remark."));
    }
  };

  // Update write off batch status
  const onSubmitWriteOff = async (updatedStatus: string) => {
    const payload = {
      id: writeOffBatch?.data?.id,
      status: updatedStatus,
    };

    setLoading(true);

    switch (updatedStatus) {
      case WriteOffStatusEnum.DRAFT:
        updateSelectedAccount();
        updateRemark();
        message.success("Batch changes successfully saved.");
        break;

      case WriteOffStatusEnum.PROCESSING:
        updateSelectedAccount();
        updateRemark();
        await dispatch(updateWriteOffStatusThunk(payload))
          .unwrap()
          .then((res) =>
            message.success(
              `Batch status successfully updated to ${updatedStatus}.`,
            ),
          )
          .catch((error) => {
            message.error("Error change status.", error);
            setLoading(false);
          });
        break;

      case WriteOffStatusEnum.PENDING:
        await updateSelectedAccount().then(async () => {
          await dispatch(updateWriteOffStatusThunk(payload))
            .unwrap()
            .then((res) =>
              message.success(
                `Batch status successfully updated to ${updatedStatus}.`,
              ),
            )
            .catch((error) => {
              message.error("Error change status.", error);
              setLoading(false);
            });
        });
        updateRemark();

        break;
      case WriteOffStatusEnum.VOID:
      case WriteOffStatusEnum.APPROVED:
      case WriteOffStatusEnum.REFERBACK:
        await dispatch(updateWriteOffStatusThunk(payload))
          .unwrap()
          .then((res) =>
            message.success(
              `Batch status successfully updated to ${updatedStatus}.`,
            ),
          )
          .catch((error) => {
            message.error("Error change status.", error);
            setLoading(false);
          });
        break;
    }

    callbackState.fetchOneWriteOff();
    setLoading(false);
    navigate(`/write-off/proposal/${batchNo}`); // go to initial state
  };

  const onDownloadApprovalList = () => {
    setLoading(true);
    dispatch(downloadWriteOffApprovalListThunk({ id: writeOffBatch?.data?.id }))
      .unwrap()
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.content]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", res.filename ?? "");
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 100);
        message.success("Successfully download approval list.");
      })
      .catch((error) =>
        message.error(
          "Failed to download approval list. Please try again later.",
        ),
      )
      .finally(() => setLoading(false));
  };

  const onUploadApprovalList = (
    onSuccess:
      | ((body: any, xhr?: XMLHttpRequest | undefined) => void)
      | undefined,
    file: string | Blob,
  ) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadWriteOffApprovalListThunk({
        id: writeOffBatch?.data?.id,
        file: formData,
      }),
    )
      .unwrap()
      .then((res) => {
        fetchApprovalFiles();
        onSuccess?.("ok");
        setPopulate(false);
        message.success("Approval listing successfully uploaded.");
      })
      .catch((error) => message.error("Error upload approval listing"));
  };

  const onUploadSupportingDoc = (
    onSuccess:
      | ((body: any, xhr?: XMLHttpRequest | undefined) => void)
      | undefined,
    file: string | Blob,
  ) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadWriteOffApprovalFormThunk({
        id: writeOffBatch?.data?.id,
        file: formData,
      }),
    )
      .unwrap()
      .then((res) => {
        fetchApprovalFiles();
        onSuccess?.("ok");
        message.success("Approval form successfully uploaded.");
      })
      .catch((error) => message.error("Error upload approval form"));
  };

  const onGetDoc = (
    fileId: any,
    setFileName?:
      | {
          (value: SetStateAction<string>): void;
          (value: SetStateAction<string>): void;
          (arg0: any): void;
        }
      | undefined,
  ) => {
    setLoading(true);
    dispatch(
      getWriteOffFileByFileIdThunk({
        fileId: fileId,
      }),
    )
      .unwrap()
      .then((res: any) => {
        if (setFileName) {
          setFileName(res.name);
          return;
        }
        downloadLink(res);
      })
      .finally(() => setLoading(false));
  };

  const fetchApprovalFiles = () => {
    if (writeOffBatch?.data?.approvalListingFileId) {
      onGetDoc(
        writeOffBatch?.data?.approvalListingFileId,
        setApprovalListFileName,
      );
    }
    if (writeOffBatch?.data?.approveFileId) {
      {
        onGetDoc(writeOffBatch?.data?.approveFileId, setApprovalDocFileName);
      }
    }
  };

  useEffect(() => {
    fetchApprovalFiles();
  }, [writeOffBatch.data]);

  return (
    <div className="account-enquiry-container">
      {loading || writeOffBatch?.isLoading || writeOffSummary?.isLoading ? (
        <div className="py-40">
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>Write Off Proposal - redCASH CEP</title>
          </Helmet>
          <PageHeader
            className="p-0 mb-4"
            onBack={onBack}
            title={
              <Space>
                <h3 className="m-0">
                  Write Off {WriteOffSummaryDetailsPageLabel[status]}
                </h3>{" "}
                <Tag>{writeOffBatch?.data?.batchNumber}</Tag>
                <Tag>{writeOffBatch?.data?.status}</Tag>
              </Space>
            }
          />

          {status == WriteOffStatusEnum.PENDING && (
            <>
              <Row justify="space-between" className="mb-5">
                <Col
                  sm={{
                    order: 2,
                  }}
                  order={1}
                >
                  <Space className="mb-5">
                    <Button onClick={onDownloadApprovalList}>Download</Button>
                  </Space>
                </Col>
                <Col
                  sm={{
                    order: 1,
                  }}
                  order={2}
                >
                  <Upload
                    maxCount={1}
                    customRequest={({ onSuccess, file }) => {
                      onUploadApprovalList(onSuccess, file);
                    }}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>
                      Upload Approval Listing
                    </Button>
                  </Upload>
                  <Button
                    type={"text"}
                    onClick={() =>
                      onGetDoc(writeOffBatch?.data?.approvalListingFileId)
                    }
                  >
                    {approvalListFileName}
                  </Button>
                </Col>
              </Row>
              <Row className="mb-10">
                <Upload
                  maxCount={1}
                  customRequest={({ onSuccess, file }) => {
                    onUploadSupportingDoc(onSuccess, file);
                  }}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>
                    Upload Approval Form
                  </Button>
                </Upload>
                <Button
                  type={"text"}
                  onClick={() => onGetDoc(writeOffBatch?.data?.approveFileId)}
                >
                  {approvalDocFileName}
                </Button>
              </Row>
            </>
          )}

          {status == WriteOffStatusEnum.APPROVED && (
            <>
              <Col span={15} sm={5} className="mb-3">
                <Text strong>Approval Documents:</Text>
              </Col>
              <Col span={18}>
                {writeOffBatch?.data?.approvalListingFileId && (
                  <Button
                    type={"text"}
                    onClick={() =>
                      onGetDoc(writeOffBatch?.data?.approvalListingFileId)
                    }
                  >
                    {approvalListFileName}
                  </Button>
                )}
                {writeOffBatch?.data?.approveFileId && (
                  <Button
                    type={"text"}
                    onClick={() => onGetDoc(writeOffBatch?.data?.approveFileId)}
                  >
                    {approvalDocFileName}
                  </Button>
                )}
              </Col>
              <br />
            </>
          )}

          <Tabs
            defaultActiveKey={activeTab ?? "summary"}
            onChange={(key) => onTabChange(key)}
            items={items}
            destroyInactiveTabPane
          />

          <br />

          <div>
            <Row align="middle" gutter={16}>
              <Col xs={24} sm={5} xl={3}>
                <Text>Justification:</Text>
              </Col>

              <Col xs={23} sm={19} xl={21}>
                <Row justify={"start"} gutter={16}>
                  {editableStatus.includes(status) && (
                    <Col xs={24} sm={13} xl={9}>
                      <TextArea
                        onChange={(e) => setJustificationValue(e.target.value)}
                        autoSize
                        value={justificationValue}
                      />
                    </Col>
                  )}
                  <Col span={3}>
                    <Button onClick={() => setIsModalOpen(true)}>
                      Remark History
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Modal
              title="Remark History"
              open={isModalOpen}
              onCancel={() => setIsModalOpen(false)}
              cancelText="OK"
              okButtonProps={{ style: { display: "none" } }}
              width="80%"
              centered
            >
              <WriteOffRemarksTable dataSource={writeOffRemarkListing?.data} />
            </Modal>
          </div>

          <br />
          <div className="flex flex-wrap justify-end mb-8 gap-1">
            {status == WriteOffStatusEnum.NEW && (
              <>
                <Button
                  type="default"
                  size="large"
                  loading={loading}
                  onClick={() => onSubmitWriteOff("DRAFT")}
                >
                  Save as Draft
                </Button>
                <Button
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => onSubmitWriteOff("PENDING")}
                >
                  Submit
                </Button>
              </>
            )}
            {(currentRole == Role.SUPER_ADMIN ||
              currentRole == Role.APPROVER ||
              currentRole == Role.WRITE_OFF_BATCH_TWO_APPROVER) &&
              status == WriteOffStatusEnum.PROCESSING && (
                <>
                  <Button
                    type="default"
                    size="large"
                    loading={loading}
                    onClick={() => onSubmitWriteOff("VOID")}
                  >
                    Void
                  </Button>
                  <Button
                    type="default"
                    size="large"
                    loading={loading}
                    onClick={() => onSubmitWriteOff("REFERBACK")}
                  >
                    Referback
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    onClick={() => onSubmitWriteOff("PENDING")}
                  >
                    Submit for Approval
                  </Button>
                </>
              )}
            {status == WriteOffStatusEnum.PENDING && (
              <>
                <Button
                  type="default"
                  size="large"
                  loading={loading}
                  onClick={() => onSubmitWriteOff("VOID")}
                >
                  Void
                </Button>
                <Button
                  type="default"
                  size="large"
                  loading={loading}
                  onClick={() => onSubmitWriteOff("REFERBACK")}
                >
                  Referback
                </Button>
                <Button
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => onSubmitWriteOff("APPROVED")}
                >
                  Approve
                </Button>
              </>
            )}
            {status == WriteOffStatusEnum.REFERBACK && (
              <>
                <Button
                  type="default"
                  size="large"
                  loading={loading}
                  onClick={() => onSubmitWriteOff("DRAFT")}
                >
                  Save as Draft
                </Button>
                <Button
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => onSubmitWriteOff("PENDING")}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WriteOffProposalPage;
