import "./index.less";

import { Button, Col, Form, message, Modal, Row, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import AgreementsTable from "../../../components/AgreementsTable";
import InfoList from "../../../components/InfoList";
import RemarksTable from "../../../components/RemarksTable";
import UpdateWaiverStatusModal from "../../../components/UpdateWaiverStatusModal";
import { WAIVER_BATCH_STATUS } from "../../../enum/waiverBatchStatus";
import {
  waiverBatchNoStateReset,
  waiverRemarkStateReset,
  waiverStateReset,
} from "../../../features/waiver/waiverSlice";
import {
  getWaiverDetailsByIdThunk,
  submitWaiverThunkForApprovalThunk,
} from "../../../services/waiverService/waiverThunk";
import { AppDispatch } from "../../../store/store";
import { displayDate, displayDateAndTime } from "../../../utils/datetime.util";
import LoadingSpinner from "../../../components/LoadingSpinner";
import UploadedWaiverDocument from "../../../components/UploadedWaiverDocument";
import TextArea from "antd/es/input/TextArea";
import { numberWithCommas } from "../../../helpers/number";
import InfoListRow from "../../../components/InfoList/InfoListRow";

const WaiverDetailsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { waiverId } = useParams();
  const [submitWaiverBatch, setSubmitWaiverBatchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [searchResultData, setSearchResultData] = useState({
    status: "",
    batchNo: "",
    id: "",
    totalNoOfAccount: 0,
    createdAt: "",
    createdBy: "",
    verifier: "",
    verifyDate: "",
    decider: "",
    decidedAt: "",
    totalWaiverBeforeTax: 0,
    totalWaiverTax: 0,
    totalWaiverAfterTax: 0,
  });
  const [fileList, setFileList] = useState<any>([]);
  const [batchRemarks, setBatchRemarks] = useState<any | null>(null);

  const fetchWaiverDetails = async (waiverId: string) => {
    setLoading(true);
    await dispatch(getWaiverDetailsByIdThunk(waiverId)).then((res) => {
      let obj: any = {};
      obj = res.payload;
      let totalWaiverTax = 0;
      let totalWaiverBeforeTax = 0;
      let totalWaiverAfterTax = 0;

      for (
        let i = 0;
        i < obj.data?.waiverBatchDetails.WaiverCharges.length;
        i++
      ) {
        totalWaiverTax += parseFloat(
          obj.data?.waiverBatchDetails.WaiverCharges[i].tax,
        );
        totalWaiverAfterTax += parseFloat(
          obj.data?.waiverBatchDetails.WaiverCharges[i].amount,
        );
      }

      totalWaiverBeforeTax = totalWaiverAfterTax - totalWaiverTax;

      const payload = {
        id: obj.data.waiverBatchDetails.id,
        batchNo: obj.data.waiverBatchDetails.batchNo,
        status: obj.data.waiverBatchDetails.status,
        totalNoOfAccount: obj.data.waiverBatchDetails.totalNoOfAccount,
        createdAt: obj.data.waiverBatchDetails.createdAt,
        createdBy: obj.data?.waiverBatchDetails.createdBy,
        verifier: obj.data?.waiverBatchDetails.verifier,
        verifyDate: obj.data?.waiverBatchDetails.verifyDate,
        decider: obj.data?.waiverBatchDetails.decider,
        decidedAt: obj.data?.waiverBatchDetails.decidedAt,
        totalWaiverBeforeTax,
        totalWaiverTax,
        totalWaiverAfterTax: totalWaiverAfterTax,
      };
      setSearchResultData(payload);
      setLoading(false);
      setFileList(obj.data?.file[0]);
    });
  };

  const resetWaiverStateReset = () => dispatch(waiverStateReset());
  const [isModalOpen, setIsModalOpen] = useState(false);

  //   useLifecycles(mount, unmount);
  // useLifecycles(
  //   () => waiverId && fetchWaiverDetails(waiverId),
  //   // () => resetWaiverStateReset(),
  // );

  useEffect(() => {
    if (waiverId !== undefined) {
      fetchWaiverDetails(waiverId);
    }
  }, [waiverId]);

  const navigate = useNavigate();
  const waiverBatchDetails = [
    {
      title: "Batch No.",
      value: searchResultData.batchNo,
    },
    {
      title: "Created By",
      value: searchResultData?.createdBy,
    },
    {
      title: "Created Date",
      value: displayDate(searchResultData?.createdAt),
    },
    {
      title: "Approved By",
      value: searchResultData?.decider,
    },
    {
      title: "Approval Date",
      value: displayDate(searchResultData?.decidedAt),
    },
    {
      title: "Total Waiver Before Tax",
      value: numberWithCommas(searchResultData.totalWaiverBeforeTax),
    },
    {
      title: "Total Tax",
      value: numberWithCommas(searchResultData.totalWaiverTax),
    },
    {
      title: "Total Waiver Amount After Tax",
      value: numberWithCommas(searchResultData.totalWaiverAfterTax),
    },
  ];

  const onBack = () => {
    dispatch(waiverStateReset());
    navigate(-1);
  };

  const onSubmitWaiverForApproval = async (searchResultData: any) => {
    setIsModalOpen(true);
  };

  const submitWaiverForPending = async (searchResultData: any) => {
    const payload = {
      waiverId: searchResultData.id,
      remarks: "",
      username: localStorage.getItem("username"),
    };
    await dispatch(submitWaiverThunkForApprovalThunk({ payload }))
      .unwrap()
      .then(() => {
        message.success(
          `Submitted waiver batch ${searchResultData.batchNo} for approval`,
        );
        setSubmitWaiverBatchLoading(false);
        dispatch(waiverBatchNoStateReset());
        dispatch(waiverRemarkStateReset());
        dispatch(waiverStateReset());
        navigate(`/waiver/waiver-listing`);
      })
      .catch(() => {
        message.error("Failed to submit waiver for approval");
        setSubmitWaiverBatchLoading(false);
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  const onFinish = async () => {
    setSubmitWaiverBatchLoading(true);
    console.log(searchResultData);
    const payload = {
      waiverId: searchResultData.id,
      remarks: batchRemarks,
      username: localStorage.getItem("username"),
    };

    await dispatch(submitWaiverThunkForApprovalThunk({ payload }))
      .unwrap()
      .then(() => {
        message.success(
          `Submitted waiver batch ${searchResultData.batchNo} for approval`,
        );
        setSubmitWaiverBatchLoading(false);
        dispatch(waiverBatchNoStateReset());
        dispatch(waiverRemarkStateReset());
        dispatch(waiverStateReset());
        navigate(`/waiver/waiver-listing`);
      })
      .catch(() => {
        message.error("Failed to submit waiver for approval");
        setSubmitWaiverBatchLoading(false);
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  return (
    <>
      <Helmet>
        <title>Waiver Batch Details - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title={
          <Space align="center">
            <h3 className="m-0">Waiver Details</h3>

            <Tag>{searchResultData.status}</Tag>
          </Space>
        }
        onBack={onBack}
      />
      {loading && (
        <>
          <LoadingSpinner />
        </>
      )}
      <div className="waiver-details-section-container">
        <div className="info-container flex flex-col gap-4">
          <InfoListRow
            title="Batch No."
            value={searchResultData.batchNo ?? "-"}
          />
          <InfoListRow
            title="Created By"
            value={searchResultData.createdBy ?? "-"}
          />
          <InfoListRow
            title="Created Date"
            value={displayDateAndTime(searchResultData.createdAt) ?? "-"}
          />
          <InfoListRow
            title="Approved By"
            value={
              searchResultData.status === WAIVER_BATCH_STATUS.APPROVED
                ? searchResultData.decider
                : "-"
            }
          />
          <InfoListRow
            title="Approval Date"
            value={
              searchResultData.status === WAIVER_BATCH_STATUS.APPROVED
                ? displayDateAndTime(searchResultData.decidedAt)
                : "-"
            }
          />
          <InfoListRow
            title="Total Waiver Before Tax"
            value={
              numberWithCommas(searchResultData.totalWaiverBeforeTax) ?? "-"
            }
          />
          <InfoListRow
            title="Total Waiver Tax"
            value={numberWithCommas(searchResultData.totalWaiverTax) ?? "-"}
          />
          <InfoListRow
            title="Total Waiver After Tax"
            value={
              numberWithCommas(searchResultData.totalWaiverAfterTax) ?? "-"
            }
          />
        </div>
      </div>

      {searchResultData.id && (
        <>
          <div className="waiver-details-section-container">
            <h3 className="waiver-details-section-title">List of Agreements</h3>
            <div className="info-container">
              <AgreementsTable
                waiverId={waiverId}
                value={searchResultData.id}
                callback={async (res: any) => {
                  // console.log(fileList);
                  // console.log(res[0]);
                  // let file = null;
                  // file = fileList;
                  // file.push(...res);
                  setFileList((prevFile: any) => [...prevFile, ...res]);
                }}
              />
            </div>
          </div>
          <br />

          <div className="waiver-details-section-container">
            <h3 className="waiver-details-section-title">Uploaded Documents</h3>
            <UploadedWaiverDocument
              status={searchResultData.status}
              defaultFileList={fileList}
            />
          </div>
          <br />

          <div className="waiver-details-section-container">
            <h3 className="waiver-details-section-title">Remarks History</h3>
            <div className="info-container">
              <RemarksTable value={searchResultData.id} />
            </div>
          </div>
        </>
      )}

      {searchResultData.status === WAIVER_BATCH_STATUS.PENDING && (
        <>
          <Row justify="end" className="mb-2" align="bottom">
            <Space>
              <UpdateWaiverStatusModal
                status={WAIVER_BATCH_STATUS.REFERBACK}
                waiverId={searchResultData.id}

                // callback={
                //     setPayload
                // }
              />

              <UpdateWaiverStatusModal
                status={WAIVER_BATCH_STATUS.VOID}
                waiverId={searchResultData.id}
                // callback={
                //     setPayload
                // }
              />

              <UpdateWaiverStatusModal
                status={WAIVER_BATCH_STATUS.APPROVED}
                waiverId={searchResultData.id}

                // callback={
                //     setPayload
                // }
              />
            </Space>
          </Row>
        </>
      )}
      {searchResultData.status === WAIVER_BATCH_STATUS.REFERBACK && (
        <>
          <Row justify="end" className="mb-2" align="bottom">
            <Button
              type="primary"
              size="large"
              loading={submitWaiverBatch}
              onClick={() => onSubmitWaiverForApproval(searchResultData)}
            >
              Submit
            </Button>
          </Row>
        </>
      )}

      {searchResultData.status === WAIVER_BATCH_STATUS.OPEN && (
        <>
          <Row justify="end" className="mb-2" align="bottom">
            <Button
              type="primary"
              size="large"
              loading={submitWaiverBatch}
              onClick={() => submitWaiverForPending(searchResultData)}
            >
              Submit
            </Button>
          </Row>
        </>
      )}

      <Modal
        title="Please enter reason"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={360}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          initialValues={{}}
        >
          <Form.Item
            label="Remarks"
            name="remarks"
            rules={[{ required: true, message: "Reason is required" }]}
          >
            <TextArea
              placeholder="Please input"
              style={{ width: "100%" }}
              onChange={(e) => setBatchRemarks(e.target.value)}
              minLength={5}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default WaiverDetailsPage;
