import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getPendingDisbursementAgreements = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/disbursement/pending-disbursement`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getDisbursementListingSummary = async (requestData: any) => {
  const url = `/redCash/api/v1.0/pdf/summaryOfDisbursementListing/${requestData?.batchNo}`;
  const res = await beSetupAxiosInstance.get(url, {
    responseType: "blob",
  });
  return res.data;
};

const getDisbursementListingSummaryTxtFile = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/disbursement/batch/${requestData?.batchId}/generateGiroFile`;
  const res = await beSetupAxiosInstance.get(url, {
    responseType: "blob",
  });
  return res.data;
};

const getDisbursementAgreementsByBatchId = async (requestData: any) => {
  const { batchId, params } = requestData;

  const restructuredParams = {
    filters: params?.filters,
    search: params?.search,
    sortField: params?.sortField,
    sortOrder: params?.sortOrder,
    currentPage: params?.pagination.current,
    pageSize: params?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/disbursement/batch/${batchId}/agreements`;
  const res = await beSetupAxiosInstance.get(url, {
    params: restructuredParams,
  });
  return res.data;
};

const getDisbursementBatchListing = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/disbursement/batch-listing`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const createDisbursementBatch = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/disbursement/create-batch`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const getDisbursementBatchDetails = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/disbursement/batch/${requestData?.batchId}`;
  const res = await beSetupAxiosInstance.get(url, requestData);
  return res.data;
};

const patchDisbursementBatchToInProcess = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/disbursement/batch/${requestData?.batchId}/in-process`;
  const res = await beSetupAxiosInstance.patch(url, requestData);
  return res.data;
};

const postAgreementsInBatch = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/disbursement/post-agreements`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const unpostAgreementByAgreementId = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/disbursement/unpost/${requestData.agreementId}`;
  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const postAgreement = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/disbursement/post-agreements`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const repostAgreement = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/disbursement/repost-agreements`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const patchPendingDisbursementAgreementToReferback = async (
  requestData: any,
) => {
  const { agreementId, payload } = requestData;

  const url = `/redCash/admin/api/v1.0/disbursement/agreement/${agreementId}/referback`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const disbursementService = {
  getPendingDisbursementAgreements,
  getDisbursementListingSummary,
  getDisbursementBatchListing,
  createDisbursementBatch,
  getDisbursementAgreementsByBatchId,
  getDisbursementBatchDetails,
  patchDisbursementBatchToInProcess,
  postAgreementsInBatch,
  unpostAgreementByAgreementId,
  postAgreement,
  patchPendingDisbursementAgreementToReferback,
  getDisbursementListingSummaryTxtFile,
  repostAgreement,
};

export default disbursementService;
