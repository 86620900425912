import dayjs from "dayjs";
import moment from "moment";

export const dateFormat = "DD/MM/YYYY";
export const monthYearFormat = "MM/YYYY";
export const dateTimeFormat = "DD/MM/YYYY hh:mm A";
export const timeFormat = "hh:mm A";

export const displayDateAndTime = (
  date: string | number | Date | null | undefined,
) => {
  return dayjs(date).format(dateTimeFormat);
};

export const displayDate = (
  date: string | number | Date | null | undefined,
) => {
  if (date === null) {
    return "-";
  }
  return dayjs(date).format(dateFormat);
};

export const displayTime = (
  date: string | number | Date | null | undefined,
) => {
  return dayjs(date).format(timeFormat);
};

export const displayDateFromString = (date: string | moment.MomentInput) => {
  return moment(date).format(dateFormat);
};

export const displayUctTime = (date: string) => {
  return moment.utc(date).format(timeFormat);
};
