import React from "react";
import { Button, Form, Input, InputNumber, message, Modal, Popconfirm, Select, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { AppDispatch, RootState } from "../../../../../../store/store";
import {
  createSmeCompanyDirectorThunk,
  createSmePartnershipDirectorThunk,
  deleteSmeCompanyDirectorByIdThunk,
  deleteSmePartnershipDirectorByIdThunk,
  getSmeApplicationDetailsThunk,
  patchSmeCompanyDirectorByIdThunk,
  patchSmePartnershipDirectorByIdThunk,
} from "../../../../../../services/smeApplicationService/smeApplicationThunk";
import { ColumnsType } from "antd/es/table";
import {
  SmeCompanyDirector,
  SmePartnershipDirector,
} from "../../../../../../features/smeApplication/smeApplicationDirectorsSlice";
import { icTypeOptions } from "../../../../../../enum/smeApplication";
import jwt_decode from "jwt-decode";

interface DirectorForm {
  directorName: string;
  nric: string;
  icType: string;
  shareholdingType: string;
  phoneNo: string;
  personalEmail: string;
  noOfShares: number;
  sharesPercentage: number;
}

export const EditDirectorModal = () => {

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [editingDirector, setEditingDirector] = React.useState<number | null>(null);

  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const directors = smeApplicationDetails?.data?.smePartnershipApplication?.smePartnershipDirectors ||
    smeApplicationDetails?.data?.smeCompanyApplication?.smeCompanyDirectors || [];

  const onClose = () => {
    setIsOpen(false);
    setEditingDirector(null);
    form.resetFields();
  };

  const handleAddDirector = async (values: any) => {
    try {
      if (editingDirector !== null) {
        // Update existing director
        // const updatedDirectors = [...directors];
        // updatedDirectors[editingDirector] = values;

        if (smeApplicationDetails?.data?.smePartnershipApplication) {
          await dispatch(patchSmePartnershipDirectorByIdThunk({
            ...values,
            noOfShares: +values.noOfShares,
            sharesPercentage: +values.sharesPercentage,
            id: form.getFieldValue("id"),
          }));
        }

        if (smeApplicationDetails?.data?.smeCompanyApplication) {
          await dispatch(patchSmeCompanyDirectorByIdThunk({
            ...values,
            noOfShares: +values.noOfShares,
            sharesPercentage: +values.sharesPercentage,
            id: form.getFieldValue("id"),
          }));
        }

        // await dispatch(updateDirectors(updatedDirectors));
      } else {
        // Add new director
        if (smeApplicationDetails?.data?.smePartnershipApplication) {
          await dispatch(createSmePartnershipDirectorThunk({
            ...values,
            smePartnershipApplicationId: smeApplicationDetails?.data?.smePartnershipApplication?.id,
          }));
        }

        if (smeApplicationDetails?.data?.smeCompanyApplication) {
          await dispatch(createSmeCompanyDirectorThunk({
            ...values,
            smeCompanyApplicationId: smeApplicationDetails?.data?.smeCompanyApplication?.id,
          }));
        }
      }
      form.resetFields();
      setEditingDirector(null);
      dispatch(getSmeApplicationDetailsThunk({
        applicationId: smeApplicationDetails?.data?.id,
      }));
    } catch (error) {
      console.error("Error managing director:", error);
    }
  };

  const handleDeleteDirector = async (index: number) => {
    try {
      if (directors[index].isAuthorizedPerson) {
        return message.error("Cannot delete authorized person");
      } else {
        if (smeApplicationDetails?.data?.smePartnershipApplication) {
          await dispatch(deleteSmePartnershipDirectorByIdThunk(directors[index].id));
        }

        if (smeApplicationDetails?.data?.smeCompanyApplication) {
          await dispatch(deleteSmeCompanyDirectorByIdThunk(directors[index].id));
        }

        dispatch(getSmeApplicationDetailsThunk({
          applicationId: smeApplicationDetails?.data?.id,
        }));
      }
    } catch (error) {
      console.error("Error deleting director:", error);
    }
  };

  const columns: ColumnsType<SmePartnershipDirector | SmeCompanyDirector> = [
    {
      title: "Name",
      dataIndex: "directorName",
      key: "directorName",
      render: (value: string, record) => {
        if (record.isAuthorizedPerson) {
          return <>{value} <Tag color={"error"}>Authorized</Tag></>;
        }

        return value;
      },
    },
    {
      title: "NRIC",
      dataIndex: "nric",
      key: "nric",
    },
    {
      title: "Contact Number",
      dataIndex: "phoneNo",
      key: "phoneNo",
    },
    {
      title: "Email",
      dataIndex: "personalEmail",
      key: "personalEmail",
    },
    {
      title: "Position",
      dataIndex: "shareholdingType",
      key: "shareholdingType",
    },
    {
      title: "Shares (Unit)",
      dataIndex: "noOfShares",
      key: "noOfShares",
      render: (text: number) => (text || "-"),
    },
    {
      title: "Shares (%)",
      dataIndex: "sharesPercentage",
      key: "sharesPercentage",
      render: (text: number) => (text ? `${text}%` : "-"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, _record: any, index: number) => (
        <div className="flex gap-2">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditingDirector(index);
              form.setFieldsValue({
                ...directors[index],
                sharesPercentage: +directors[index].sharesPercentage,
              });
            }}
          />
          <Popconfirm
            title="Delete director?"
            description="Are you sure you want to delete this director?"
            onConfirm={() => handleDeleteDirector(index)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <>
      {
        (currentRole === 'VERIFIER' || currentRole === 'VERIFIER_2' || currentRole === 'SUPER_ADMIN') && (
          <Button type="primary" onClick={() => setIsOpen(true)} icon={<PlusOutlined />}>
            Manage Directors
          </Button>
        )
      }
      <Modal
        title="Manage Directors"
        open={isOpen}
        onCancel={onClose}
        footer={null}
        width={1200}
        className="overflow-auto"
      >
        <div className="mb-6">
          <Table
            dataSource={directors}
            columns={columns}
            rowKey={(record, index) => index?.toString() || "0"}
            pagination={false}
            className="mb-6"
          />

          <div className="bg-gray-50 p-6 rounded-lg mt-6">
            <h3 className="text-lg font-semibold mb-4">
              {editingDirector !== null ? "Edit Director" : "Add New Director"}
            </h3>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleAddDirector}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Form.Item
                  name="directorName"
                  label="Director Name"
                  rules={[{ required: true, message: "Please enter director name" }]}
                >
                  <Input disabled={!!(editingDirector && directors[editingDirector].isAuthorizedPerson)} />
                </Form.Item>

                <Form.Item
                  name="nric"
                  label="NRIC"
                  rules={[{ required: true, message: "Please enter NRIC" }]}
                >
                  <Input disabled={!!(editingDirector && directors[editingDirector].isAuthorizedPerson)} />
                </Form.Item>

                <Form.Item
                  name="icType"
                  label="Identity Card Type"
                  rules={[{ required: true, message: "Please enter IC type" }]}
                >
                  <Select
                    placeholder={"Please Select"}
                    options={icTypeOptions}
                    disabled={!!(editingDirector && directors[editingDirector].isAuthorizedPerson)} />
                </Form.Item>

                <Form.Item
                  name="shareholdingType"
                  label="Position in Company"
                  rules={[{ required: true, message: "Please enter position" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="phoneNo"
                  label="Contact Number"
                  rules={[{ required: true, message: "Please enter contact number" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="personalEmail"
                  label="Personal Email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    { type: "email", message: "Please enter a valid email" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="noOfShares"
                  label="No. of Shares Unit"
                  rules={[{ required: true, message: "Please enter number of shares" }]}
                >
                  <InputNumber className="w-full" min={0} />
                </Form.Item>

                <Form.Item
                  name="sharesPercentage"
                  label="Shares Percentage"
                  rules={[
                    { required: true, message: "Please enter shares percentage" },
                    { type: "number", min: 0, max: 100, message: "Percentage must be between 0 and 100" },
                  ]}
                >
                  <InputNumber className="w-full" min={0} max={100} />
                </Form.Item>
              </div>

              <div className="flex justify-end gap-2 mt-4">
                <Button onClick={() => {
                  form.resetFields();
                  setEditingDirector(null);
                }}>
                  Reset
                </Button>
                <Button type="primary" htmlType="submit">
                  {editingDirector !== null ? "Update Director" : "Add Director"}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};