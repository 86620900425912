import { authSetupAxiosInstance } from "../../axios/authSetupAxios";
import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { experianServiceAxiosInstance } from "../../axios/experianServiceAxios";
import { ApplicationStatusEnum } from "../../enum/applicationStepStatus";

const getApplications = async (requestData: any) => {
  const params = {
    filters: requestData.filters,
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/applications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getSubmitForApprovalApplication = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData.filters,
      applicationStatus: ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL,
    },
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/application/submit-for-approval`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getPendingApplications = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData.filters,
      applicationStatus: ApplicationStatusEnum.PENDING,
    },
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/applications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getReferbackApplications = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData.filters,
      applicationStatus: ApplicationStatusEnum.REFERBACK,
    },
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/applications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getFollowUpApplications = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData.filters,
      applicationStatus: ApplicationStatusEnum.FOLLOWED_UP,
    },
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/applications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getReferbackFromPendingDisbursementApplications = async (
  requestData: any,
) => {
  const params = {
    filters: {
      ...requestData.filters,
      applicationStatus:
        ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT,
    },
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/applications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getApprovedApplications = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData.filters,
      applicationStatus: ApplicationStatusEnum.APPROVED,
    },
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/applications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getRejectedApplications = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData.filters,
      applicationStatus: ApplicationStatusEnum.REJECTED,
    },
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/applications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getCancelledApplications = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData.filters,
      applicationStatus: ApplicationStatusEnum.CANCELED,
    },
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/applications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getDraftApplications = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData.filters,
      applicationStatus: ApplicationStatusEnum.DRAFT,
    },
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/admin/api/v1.0/applications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getApplicationDetails = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getInternalRemarksByApplicationID = async (requestData: any) => {
  const { adminId, filters } = requestData;
  const params = { filters: requestData.filters };
  const url = `/redCash/admin/api/v1.0/internalRemark/application/${filters.applicationId}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getHistoryLogsByApplicationID = async (requestData: any) => {
  const { applicationId } = requestData;
  const params = {
    sortField: "createdAt",
    sortOrder: "desc",
  };
  const url = `/redCash/admin/api/v1.0/historyLog/application/${applicationId}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getHistoryLogsByApplicationIDAndAgreementID = async (
  requestData: any,
) => {
  const { applicationId, agreementId } = requestData;
  const params = {
    sortField: "createdAt",
    sortOrder: "desc",
  };
  const url = `/redCash/admin/api/v1.0/historyLog/application/${applicationId}/agreement/${agreementId}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const createInternalRemark = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/internalRemark`;
  const res = await beSetupAxiosInstance.post(url, {
    ...requestData,
    username: localStorage.getItem("username"),
  });
  return res.data;
};

const referBackApplication = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/status/referBack`;

  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const cancelApplication = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/status/cancel`;

  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const referBackApplicationFromPendingDisbursement = async (
  requestData: any,
) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/status/refer-back-from-pending-disbursement`;

  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const submitForApproval = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/status/submittedForApproval`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const approveApplication = async (requestData: any) => {
  const { applicationId, language, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/${language}/status/approved`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const rejectApplication = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/status/rejected`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteApplication = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application/${applicationId}`;

  const res = await beSetupAxiosInstance.delete(url);
  return res.data;
};

const getApplicationCreditRecord = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/admin/api/v1.0/creditRecord/application/${applicationId}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const updateApplicationFinanceAmount = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/api/v1.0/application/update-finance-amount/id/${applicationId}`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateApplicant = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/user`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateApplicationNetIncome = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/patchNetIncome`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateApplicationStatusToFollowedUp = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/status/followed-up`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateApplicationStatusToCaseSettledFromFollowedUp = async (
  requestData: any,
) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/status/settled-from-followed-up`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateApplicantUserDetails = async (requestData: any) => {
  const { userId, payload, applicationId, agreementId } = requestData;
  const url = `/redCash/admin/auth/api/v1.0/user/${userId}/applicationId/${applicationId}/agreementId/${agreementId}`;

  const res = await authSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const globalSearch = async (requestData: any) => {
  const { payload } = requestData;
  const params = {
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
    // sortField: requestData.sortField,
    // sortOrder: requestData.sortOrder,
  };
  const url = `/redCash/admin/api/v1.0/application/global-search`;
  const res = await beSetupAxiosInstance.post(url, payload, { params });
  return res.data;
};

const getApplicationExperianReport = async (requestData: any) => {
  const { fileId, newIc, params } = requestData;
  const url = `redCash/api/v1.0/experian/xmlToHtml/fileId/${fileId}/newIc/${newIc}`;
  const res = await experianServiceAxiosInstance.get(url, { params });
  return res.data;
};

const regenerateApplicationForm = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `redCash/api/v1.0/regenerateApplicationForm/${applicationId}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const reRunApplicationCreditEvaluationEngine = async (requestData: any) => {
  const { applicationId, isNewApplication } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/rerun-engine/isNewApplication/${isNewApplication}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getFinoryEmbeddedUrl = async (applicationId: string) => {
  const url = `/redCash/api/v1.0/application/${applicationId}/finory`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const applicationService = {
  getApplications,
  getPendingApplications,
  getReferbackApplications,
  getFollowUpApplications,
  getApprovedApplications,
  getRejectedApplications,
  getCancelledApplications,
  getDraftApplications,
  getApplicationDetails,
  getInternalRemarksByApplicationID,
  getHistoryLogsByApplicationID,
  createInternalRemark,
  referBackApplication,
  cancelApplication,
  submitForApproval,
  approveApplication,
  rejectApplication,
  deleteApplication,
  getHistoryLogsByApplicationIDAndAgreementID,
  getApplicationCreditRecord,
  updateApplicationFinanceAmount,
  updateApplicant,
  updateApplicationNetIncome,
  updateApplicationStatusToFollowedUp,
  updateApplicationStatusToCaseSettledFromFollowedUp,
  updateApplicantUserDetails,
  globalSearch,
  referBackApplicationFromPendingDisbursement,
  getApplicationExperianReport,
  getReferbackFromPendingDisbursementApplications,
  getSubmitForApprovalApplication,
  regenerateApplicationForm,
  reRunApplicationCreditEvaluationEngine,
  getFinoryEmbeddedUrl,
};

export default applicationService;
