import "../index.less";

import { useSelector } from "react-redux";

import InfoGrid from "../../../components/InfoGrid";
import { numberWithCommas, roundTo } from "../../../helpers/number";
import { RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import TotalChargesModal from "./TotalChargesModal";
import { Button, Popover, Table } from "antd";
import { useEffect } from "react";
import { parse } from "node:path/win32";

const LoanInformationCard = () => {
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const accountDetails = accountEnquiry?.data?.accountDetails;
  const lastPaymentChannel =
    accountEnquiry?.data.accountDetails?.lastPaymentChannel;

  const loanInformation = [
    {
      title: "Paid Instalment Records",
      value: accountDetails?.paidInstalmentMonth,
    },
    {
      title: "Month-in-arrear (MIA)",
      value: accountDetails?.mia,
    },
    {
      title: "Instalment-in-arrear (IIA)",
      value: accountDetails?.iia,
    },
    {
      title: "Day Past Due",
      value: accountDetails?.dpd,
    },
    {
      title: "Last Payment Amount",
      value:
        accountDetails?.lastPaymentAmount != null
          ? `RM ${numberWithCommas(accountDetails?.lastPaymentAmount)}`
          : "-",
    },
    {
      title: "Last Payment Date",
      value: accountDetails?.lastPaymentDate
        ? displayDate(accountDetails?.lastPaymentDate)
        : "-",
    },
    {
      title: "Instalment Payment Mode",
      value: lastPaymentChannel ?? "-",
    },
    {
      title: "Next Instalment Date",
      value:
        accountDetails?.status == 'ACTIVE'
          ? accountDetails?.nextInstalmentDate
            ? displayDate(accountDetails?.nextInstalmentDate)
            : "-"
          : "-",
    },
    {
      title: "Total Due Outstanding",
      value:
        accountDetails?.totalDueInstalment != null ? (
          <Popover
            className="p-0"
            content={
              <Table
                className="max-h-96 overflow-auto"
                size="small"
                dataSource={accountEnquiry.data?.tenureDetails ?? []}
                pagination={false}
                columns={[
                  {
                    title: "Due Date",
                    dataIndex: "dueDate",
                    key: "dueDate",
                    align: "center",
                    render: (text) =>
                      text != null ? `${displayDate(text)}` : "-",
                  },
                  {
                    title: "Outstanding Amount",
                    dataIndex: "outstandingAmount",
                    key: "outstandingAmount",
                    render: (text: string | number | null | undefined) =>
                      text != null ? `RM ${numberWithCommas(text)}` : "-",
                    align: "center",
                  },
                ]}
              />
            }
          >
            <Button type="link">
              {`RM ${numberWithCommas(accountDetails?.totalDueInstalment)}`}
            </Button>
          </Popover>
        ) : (
          "-"
        ),
    },
    {
      title: "Current Month Instalment Balance",
      value:
        accountDetails?.currentMonthInsBalance != null
          ? `RM ${numberWithCommas(accountDetails?.currentMonthInsBalance)}`
          : "-",
    },
    {
      title: "Total Charges",
      value:
        accountDetails?.accountCharges != null ? (
          <TotalChargesModal
            accountCharges={accountDetails.accountCharges}
            accountDetailsId={accountDetails.id}
          />
        ) : (
          "-"
        ),
    },
    {
      title: "Billing Outstanding",
      value:
        accountDetails?.billingOutstanding != null
          ? `RM ${roundTo(accountDetails?.billingOutstanding, 2)}`
          : "-",
    },
    {
      title: "Total Paid Instalment",
      value:
        accountDetails?.totalPaidInstalment != null
          ? `RM ${numberWithCommas(accountDetails?.totalPaidInstalment)}`
          : "-",
    },
    {
      title: "Total Instalment Balance",
      value:
        accountDetails?.instalmentBalance != null
          ? `RM ${numberWithCommas(accountDetails?.instalmentBalance)}`
          : "-",
    },
    {
      title: "Total Paid Amount",
      value:
        accountDetails?.totalPaidInstalment != null
          ? `RM ${numberWithCommas(accountDetails?.totalPaidInstalment)}`
          : "-",
    },
    {
      title: "Total Loan Outstanding",
      value:
        accountDetails?.totalLoanOutstanding != null
          ? `RM ${numberWithCommas(accountDetails?.totalLoanOutstanding)}`
          : "-",
    },
    {
      title: "Special Tagging (A)",
      value:
        accountDetails?.specialTaggingAuto != null
          ? accountDetails?.specialTaggingAuto
          : "-",
    },
    {
      title: "Special Tagging (M)",
      value:
        accountDetails?.specialTaggingManual != null
          ? accountDetails?.specialTaggingManual
          : "-",
    },
    {
      title: "Aging Type",
      value: accountDetails?.agingType,
    },
    {
      title: "NPL Flag",
      value: accountDetails?.nplFlag ? "YES" : "NO",
    },
    {
      title: "Refund Amount",
      value:
        accountDetails?.refundAmount != null
          ? `RM ${numberWithCommas(accountDetails?.refundAmount)}`
          : "-",
    },
    {
      title: "Extra Payment Amount",
      value:
        accountDetails?.extraPaymentAmount != null
          ? `RM ${numberWithCommas(accountDetails?.extraPaymentAmount)}`
          : "-",
    },
  ];

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        {/* <h3 className="account-enquiry-section-title">Loan Information</h3> */}
        <div className="info-container">
          <InfoGrid title="Loan Info" data={loanInformation} />
        </div>
      </div>
    </div>
  );
};

export default LoanInformationCard;
