import { Result, Space, Tabs } from "antd";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import RescheduleCalculationContent from "./components/reschedule-calculation";
import RescheduleDetailsContent from "./components/reschedule-details";

import type { TabsProps } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useLifecycles } from "react-use";
import { getRescheduleCaseThunk } from "../../../services/rescheduleService/rescheduleThunk";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  getAccountDetailsByIdThunk,
  getAccountDetailsThunk,
} from "../../../services/collectionService/collectionThunk";

const RescheduleDetailsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { rescheduleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");

  const items: TabsProps["items"] = [
    {
      key: "calculation",
      label: "Reschedule Calculation",
      children: <RescheduleCalculationContent />,
    },
    {
      key: "details",
      label: "Reschedule Details",
      children: <RescheduleDetailsContent />,
    },
  ];

  const onBack = () => {
    navigate(-1);
  };

  useLifecycles(() => {
    dispatch(
      getRescheduleCaseThunk({
        id: rescheduleId,
      }),
    )
      .unwrap()
      .then(async (res) => {
        dispatch(getAccountDetailsByIdThunk({ id: res.data.accountDetailsId }))
          .unwrap()
          .then(() => {});
      });
  });

  return (
    <>
      <Helmet>
        <title>Reschedule Details - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="p-0 mb-4"
        onBack={onBack}
        title={
          <Space align="center">
            <h3 className="m-0">Reschedule Details</h3>
          </Space>
        }
      />
      {rescheduleId ? (
        <Tabs
          items={items}
          defaultActiveKey={activeTab ?? "calculation"}
          centered
        />
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, reschedule details not found."
        />
      )}
    </>
  );
};

export default RescheduleDetailsPage;
