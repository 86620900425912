import { Button, Input, Select, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { numberWithCommas } from "../../../../helpers/number";
import {
  getCancelledApplicationsThunk,
  getDraftApplicationsThunk,
} from "../../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate, displayTime } from "../../../../utils/datetime.util";

import type { Application } from "../../../../features/application/applicationSlice";
import type { ColumnsType } from "antd/es/table";
import type { TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import UploadFileModal from "./UploadFileModal";

const { Search } = Input;

const DraftApplicationListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { draftApplicationList } = useSelector(
    (state: RootState) => state.application,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<Application>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("applicationNo");

  const tablePagination = {
    total: draftApplicationList?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    // {
    //   title: "Application Date",
    //   dataIndex: "submissionDate",
    //   key: "submissionDate",
    //   sorter: (a, b) => a.submissionDate - b.submissionDate,
    //   sortOrder:
    //     sortedInfo.columnKey === "submissionDate" ? sortedInfo.order : null,
    //   render: (text) => (text ? displayDate(text) : "-"),
    // },
    // {
    //   title: "Time",
    //   dataIndex: "submissionDate",
    //   key: "submissionDate",
    //   width: 120,
    //   render: (text) => (text ? displayTime(text) : "-"),
    // },
    {
      title: "Application No.",
      dataIndex: "applicationNo",
      key: "applicationNo",
      render: (text, record) => (
        // <Button
        //   type="link"
        //   onClick={() => navigate(`/application/details/${record.id}`)}
        // >
        <span style={{ userSelect: "text" }}>{text}</span>
        // </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.user?.firstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      render: (_, record) => record?.user?.nric ?? "-",
    },
    {
      title: "Applied Loan Amount",
      dataIndex: "loanApplied",
      key: "loanApplied",
      render: (text) => (text ? `RM ${numberWithCommas(text)}` : "-"),
    },
    // {
    //   title: "Bucket",
    //   dataIndex: "bucket",
    //   key: "bucket",
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => <UploadFileModal data={record} />,
    },
  ];

  const fetchApplications = async (params?: any) => {
    await dispatch(getDraftApplicationsThunk(params));
  };

  const onSearch = (value: string) => {
    fetchApplications({
      ...initialParams,
      filters: { ...filterState, [searchFiled]: value },
    });
    setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const handleTableChange: TableProps<Application>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      sorter?.order == null
        ? {
            ...initialParams,
            ...filterState,
            pagination,
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          };

    fetchApplications(param);
    setSortedInfo(sorter as SorterResult<Application>);
    setPaginationState({
      current: pagination.current ?? initialParams.pagination.current,
      pageSize: pagination.pageSize ?? initialParams.pagination.pageSize,
    });

    queryParams.set(
      "pageNum",
      `${pagination.current ?? initialParams.pagination.current}`,
    );
    queryParams.set(
      "pageSize",
      `${pagination.pageSize ?? initialParams.pagination.pageSize}`,
    );

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>Draft Application - redCASH CEP</title>
      </Helmet>

      <Input.Group compact>
        <Select
          defaultValue="applicationNo"
          style={{ width: 200 }}
          onChange={(value) => setSearchFiled(value)}
          options={[
            { value: "applicationNo", label: "Application No" },
            // { value: "applicantFirstName", label: "Applicant Name" },
            // { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={draftApplicationList?.data?.data}
        loading={draftApplicationList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default DraftApplicationListing;
