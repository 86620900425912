// create a select date modal, and accept batch as props
// the date modal will have a date picker
// the date picker will have a submit button
// the date picker will have a cancel button

import React, { useEffect, useState } from "react";
import { Modal, DatePicker, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { patchLoanInsuranceBatchTrunk } from "../../../services/loanInsuranceService/loanInsuranceThunk";
import { LoanInsuranceBatch } from "../../../features/loanInsurance/loanInsuranceSlice";
import { LOAN_INSURANCE_BATCH_STATUS } from "../../../enum/loanInsuranceBatchStatus";
import moment from "moment";

interface DateModalProps {
  batch: LoanInsuranceBatch;
  callback?: () => void;
}

const DateModal: React.FC<DateModalProps> = ({ batch, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [date, setDate] = useState<string | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [DateModalTitle, setDateModalTitle] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleDateChange = (date: any, dateString: string) => {
    setDate(dateString);
  };

  const handleOk = async () => {
    setLoading(true);
    if (!date) {
      message.error("Please select a date");
    }
    let body: any;
    if (batch.status === "IN_PROCESS") {
      body = {
        postDate: new Date(date!),
        status: LOAN_INSURANCE_BATCH_STATUS.REQUEST_SUBMIT_FOR_ZURICH,
      };
    } else if (batch.status === "UPLOAD_ZURICH_REPORT_SUCCESSFUL") {
      body = {
        completedDate: new Date(date!),
        status: LOAN_INSURANCE_BATCH_STATUS.COMPLETED,
      };
    }

    await dispatch(
      patchLoanInsuranceBatchTrunk({ id: batch.id, body }),
    ).finally(() => {
      setIsOpenModal(false);
      setLoading(false);
    });

    if (callback) callback();
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  useEffect(() => {
    if (batch.status === "IN_PROCESS") {
      setDateModalTitle(`Select Post Date`);
    } else if (batch.status === "UPLOAD_ZURICH_REPORT_SUCCESSFUL") {
      setDateModalTitle(`Select Upload Date`);
    }
  }, [batch]);

  return (
    <>
      <Button onClick={openModal}>{DateModalTitle}</Button>
      <Modal
        title={DateModalTitle}
        open={isOpenModal}
        onOk={handleOk}
        onCancel={() => setIsOpenModal(false)}
        confirmLoading={loading}
      >
        <DatePicker
          onChange={handleDateChange}
          disabledDate={(current: any) =>
            current.isBefore(moment().subtract(1, "day"))
          }
        />
      </Modal>
    </>
  );
};

export default DateModal;
