import { batch } from "react-redux";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

export const getAocBatch = async (requestData: any) => {
  const params = {
    filters: { ...requestData?.filters, status: "OPEN" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
  };

  const url = `/redCash/api/v1.0/add-on-charges/batch`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

export const generateAocBatchNo = async (params: any) => {
  const { username } = params;

  const result = await collectionSetupAxiosInstance.post<any>(
    `/redCash/api/v1.0/add-on-charges/generateBatchNo`,
    { username },
  );

  return result.data;
};

export const addAgreementToAoc = async (payload: any) => {
  const result = await collectionSetupAxiosInstance.post<any>(
    `/redCash/api/v1.0/add-on-charges/addAgreement`,
    payload,
  );

  return result;
};

export const getAocListing = async (requestData: any) => {
  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/add-on-charges/batch`;
  const res = await collectionSetupAxiosInstance.get<any>(url, { params });
  return res.data;
};

export const geOpenAoc = async (requestData: any) => {
  const params = {
    filters: { status: "OPEN" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/add-on-charges/batch`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

export const getPendingAoc = async (requestData: any) => {
  const params = {
    filters: { status: "PENDING" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/add-on-charges/batch`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

export const getReferbackAoc = async (requestData: any) => {
  const params = {
    filters: { status: "REFERBACK" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/add-on-charges/batch`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  console.log(res.data);
  return res.data;
};

export const getVoidAoc = async (requestData: any) => {
  const params = {
    filters: { status: "VOID" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/add-on-charges/batch`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

export const getApprovedAoc = async (requestData: any) => {
  const params = {
    filters: { status: "APPROVED" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/add-on-charges/batch`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

export const getAocBatchById = async (batchId: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/add-on-charges/batch/${batchId}`,
  );
  return result.data;
};

export const submitAocForPending = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch(
    `redcash/api/v1.0/add-on-charges/batch/${requestData.addOnChargeBatchId}/pending`,
    requestData,
  );
  return result.data;
};

export const submitAocForVoid = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch(
    `redcash/api/v1.0/add-on-charges/batch/${requestData.addOnChargeBatchId}/void`,
    requestData,
  );
  return result.data;
};

export const submitAocForReferback = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch(
    `redcash/api/v1.0/add-on-charges/batch/${requestData.addOnChargeBatchId}/referback`,
    requestData,
  );
  return result.data;
};

export const submitAocForApprove = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch(
    `redcash/api/v1.0/add-on-charges/batch/${requestData.batchId}/approved`,
    requestData,
  );
  return result.data;
};

export const updateAocAgreementCharges = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch(
    `redcash/api/v1.0/add-on-charges/batch/update`,
    requestData,
  );
};

export const getAgreement = async (requestData: any) => {
  console.log(requestData);
  const result = await collectionSetupAxiosInstance.post<any>(
    `/redCash/api/v1.0/add-on-charges/searchAgreement`,
    requestData,
  );

  return result.data;
};

export const resubmitAocForPending = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch(
    `redcash/api/v1.0/add-on-charges/batch/${requestData.addOnChargeBatchId}/pending`,
    requestData,
  );
  return result.data;
};

export const getAgreementByEmail = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.post(
    `redcash/api/v1.0/add-on-charges/searchAgreement`,
    requestData,
  );
  return result.data;
};
