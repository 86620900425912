import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  TablePaginationConfig,
  UploadFile,
} from "antd";
import { RcFile } from "antd/es/upload";
import startCase from "lodash.startcase";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLifecycles } from "react-use";

import { DeleteOutlined } from "@ant-design/icons";

import RefundUploadedRefundDocuments from "../../../../components/RefundFileListModal";
import RequestRefund from "../../../../components/RequestRefund";
import { REFUND_FINANCE_STATUS, REFUND_STATUS } from "../../../../enum/refund";
import { Refund } from "../../../../features/refund/refundSlice";
import { numberWithCommas } from "../../../../helpers/number";
import { waiverFileUploadThunk } from "../../../../services/fileService/fileThunk";
import {
  updateRefundDetailsThunk,
  updateRefundStatusThunk,
} from "../../../../services/refundService/refundThunk";
import {
  updateUnmatchedRefundDetailsThunk,
  updateUnmatchedRefundStatusThunk,
} from "../../../../services/unmatchedService/unmatchedThunk";
import { AppDispatch } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";
import AccountEnquiryModal from "../../../AccountEnquiry/AccountEnquiryModal";
import RefundDetailsModal from "../refundDetailsModal";

import type { ColumnsType } from "antd/es/table";
const { Search } = Input;

type RefundTableProps = {
  dataSource: any;
  status?: string;
  viewMode?: boolean;
  isUnmatched?: boolean;
  callback?: any;
};

const RefundTable: React.FC<RefundTableProps> = (props) => {
  const { dataSource, status, viewMode, isUnmatched, callback } = props;
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refundDetails, setRefundDetails] = useState<Refund>();
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [searchData, setSearchData] = useState(initialParams.search);
  const [remarkModal, setRemarkModal] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(REFUND_STATUS.VOID);
  const [remark, setRemark] = useState(
    refundDetails ? refundDetails.remark : "",
  );
  const [ibgRoutingNumber, setIbgRoutingNumber] = useState<any>();

  const tablePagination = {
    total: dataSource?.data?.searchTotal ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };
  const [form] = Form.useForm();

  const onSearch = (value: string) => {
    const filters = {
      ...filterState,
      status,
    };

    const search = {
      ...initialParams.search,
      [searchFiled]: value.toUpperCase(),
    };

    setSearchData({ [searchFiled]: value.toUpperCase() });

    const params = {
      ...initialParams,
      filters,
      search,
    };

    callback(params);
    // setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const openModal = (data: any) => {
    setRefundDetails(data);
    form.setFieldsValue({
      agreementNo: data.agreementNo,
      contactPhoneNo: data.contactPhoneNo,
      bank: data.bank,
      bankAccountNo: data.bankAccountNo,
      extraPaymentAmount: (+data.extraPaymentAmount).toFixed(2),
      unmatchedAmount: (+data.unmatchedAmount ?? 0).toFixed(2),
      refundAdminCharge: (+data.refundAdminCharge).toFixed(2),
      refundAmount: (+data.refundAmount).toFixed(2),
      remark: data.remark,
      paymentMethod: data.paymentMethod,
      unsuccessfulReason: data.unsuccessfulReason,
      ibgRoutingNumber: data.ibgRoutingNumber,
    });
    setIsModalOpen(true);
    setRemark(data.remark);
  };

  const onSaveFile = async () => {
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const data = new FormData();
        if (refundDetails?.id == null) {
          return message.error("Refund ID is null");
        }
        data.append("entityId", refundDetails.id);
        data.append("fileType", "REFUND");
        data.append("file", fileList[i] as RcFile);

        await dispatch(waiverFileUploadThunk(data));
      }
      setFileList([]);
      form.setFieldValue("file", []);
    }
  };

  const onFinished = async (saveDraft?: boolean | undefined) => {
    setUpdateLoading(true);
    await form.validateFields().then(async (values) => {
      if (type === "unmatched") {
        setRemark(values.remark);
        const requestData = {
          payload: {
            agreementNo: values.agreementNo,
            contactPhoneNo: values.contactPhoneNo,
            bank: values.bank,
            bankAccountNo: values.bankAccountNo,
            paymentMethod: values.paymentMethod,
            refundAdminCharge: parseFloat(values.refundAdminCharge),
            refundAmount: parseFloat(values.refundAmount),
            remark: values.remark,
            unmatchedAmount: parseFloat(values.unmatchedAmount),
          },
          id: refundDetails?.id,
        };
        await dispatch(updateUnmatchedRefundDetailsThunk(requestData))
          .then(() => {
            callback(initialParams);
            if (saveDraft) {
              message.success("Updated successfully");
              onSaveFile();
            }
          })
          .finally(() => {
            setUpdateLoading(false);
            setIsModalOpen(false);
          });
      } else {
        setRemark(values.remark);
        const requestData = {
          payload: {
            agreementNo: values.agreementNo,
            contactPhoneNo: values.contactPhoneNo,
            bank: values.bank,
            bankAccountNo: values.bankAccountNo,
            paymentMethod: values.paymentMethod,
            refundAdminCharge: parseFloat(values.refundAdminCharge),
            refundAmount: parseFloat(values.refundAmount),
            remark: values.remark,
            extraPaymentAmount: parseFloat(values.extraPaymentAmount),
            ibgRoutingNumber,
          },
          id: refundDetails?.id,
        };
        await dispatch(updateRefundDetailsThunk(requestData))
          .then(() => {
            callback(initialParams);
            if (saveDraft) {
              message.success("Updated successfully");
              onSaveFile();
            }
          })
          .finally(() => {
            setUpdateLoading(false);
            setIsModalOpen(false);
          });
      }
    });
  };

  const updateRefundStatus = async (statusToUpdate: string | undefined) => {
    setUpdateStatusLoading(true);
    if (remark === undefined || remark.length === 0) {
      message.error("Please insert remark before update");
      setUpdateStatusLoading(false);
      return;
    }

    if (status === REFUND_STATUS.REFERBACK || status === REFUND_STATUS.OPEN) {
      await onFinished();
      await onSaveFile();
    }

    if (
      (status === REFUND_STATUS.VOID && remark!.length <= 0) ||
      (status === REFUND_STATUS.REFERBACK && remark!.length <= 0)
    ) {
      message.error("Please insert remark before void");
      setUpdateStatusLoading(false);
      return;
    }

    const requestData = {
      id: refundDetails?.id,
      status: statusToUpdate,
      payload: {
        remark,
      },
    };

    console.log(requestData);

    if (statusToUpdate === REFUND_STATUS.PENDING) {
      await form.validateFields().then(async (values) => {
        requestData.payload.remark = values.remark;
      });
    }

    if (type === "unmatched") {
      await dispatch(updateUnmatchedRefundStatusThunk(requestData))
        .unwrap()
        .then(() => {
          message.success("Updated successfully");
          callback(initialParams);
        })
        .catch(() => {
          message.error("Something went wrong. Please try again later.");
        })
        .finally(() => {
          setUpdateStatusLoading(false);
          setIsModalOpen(false);
          setRemarkModal(false);
        });
    } else {
      await dispatch(updateRefundStatusThunk(requestData))
        .unwrap()
        .then(() => {
          message.success("Updated successfully");
          callback(initialParams);
        })
        .catch(() => {
          message.error("Something went wrong. Please try again later.");
        })
        .finally(() => {
          setUpdateStatusLoading(false);
          setIsModalOpen(false);
          setRemarkModal(false);
        });
    }

    setRemark("");
  };

  const columns: ColumnsType<any> = [
    {
      title: "Date Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal key={record.id} agreementNo={record.agreementNo} />
      ),
    },
    {
      title: "Extra Payment Amount",
      dataIndex: "extraPaymentAmount",
      key: "extraPaymentAmount",
      render: (text) => (text != null ? `RM${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (text) => (text != null ? startCase(text) : "-"),
    },
    {
      title: "Refund To",
      children: [
        {
          title: "Bank",
          dataIndex: "bank",
          key: "bank",
          render: (text) => (text != null ? startCase(text) : "-"),
        },
        {
          title: "Bank Account No",
          dataIndex: "bankAccountNo",
          key: "bankAccountNo",
          render: (text) => (text != null ? startCase(text) : "-"),
        },
      ],
    },
    {
      title: "Refund Amount",
      dataIndex: "refundAmount",
      key: "refundAmount",
      render: (text) => (text != null ? `RM${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Refund Charge",
      dataIndex: "refundAdminCharge",
      key: "refundAdminCharge",
      render: (text) => (text != null ? `RM${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Phone No",
      dataIndex: "contactPhoneNo",
      key: "contactPhoneNo",
      render: (text) => text ?? "-",
    },
    {
      title: "Action",
      render: (text, record) => {
        if (status === REFUND_STATUS.PENDING) {
          return (
            <Space>
              <RefundUploadedRefundDocuments
                entityId={record.id}
                status={record.status}
              />

              <Button type="primary" onClick={() => openModal(record)}>
                {viewMode ? "View" : "Update"}
              </Button>
            </Space>
          );
        } else {
          return (
            <Space>
              <RefundUploadedRefundDocuments
                entityId={record.id}
                status={record.status}
              />
              <Button type="primary" onClick={() => openModal(record)}>
                {viewMode ? "View" : "Update"}
              </Button>
            </Space>
          );
        }
      },
    },
  ];

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    callback({
      ...initialParams,
      ...filterState,
      currentPage: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
      search: searchData,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useLifecycles(() => {
    callback(initialParams);
  });

  const onOpenRemarkModal = (status: REFUND_STATUS) => {
    setUpdateStatus(status);
    setRemarkModal(true);
    setRemark("");
  };

  return (
    <>
      <Helmet>
        <title>Refund Listing - redCASH CEP</title>
      </Helmet>

      <div className="flex justify-between mb-4">
        <Input.Group compact>
          <Select
            defaultValue="agreementNo"
            style={{ width: 200 }}
            onChange={(value) => setSearchFiled(value)}
            options={[
              { value: "agreementNo", label: "Agreement No" },
              { value: "contactPhoneNo", label: "Phone No" },
            ]}
          />

          <Search
            placeholder="input search text"
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Input.Group>

        {status === REFUND_STATUS.OPEN && (
          <RequestRefund
            isUnmatched={isUnmatched}
            status={status}
            type={type ?? "refund"}
            callback={() => callback(initialParams)}
          />
        )}
      </div>

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={dataSource?.data?.data ?? []}
        loading={dataSource?.loading}
        onChange={handleTableChange}
      />
      <RefundDetailsModal
        form={form}
        viewMode={viewMode}
        loading={updateLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        fileList={fileList}
        setFileList={setFileList}
        type={type ?? "refund"}
        callback={(e: any) => {
          if (e.ibgRoutingNumber) {
            setIbgRoutingNumber(e.ibgRoutingNumber);
          } else {
            setRemark(e);
          }
        }}
        submitButtons={[
          <Space key={"space"}>
            {status === REFUND_STATUS.PENDING && (
              <Button
                icon={<DeleteOutlined style={{ color: "red" }} />}
                danger
                loading={updateStatusLoading}
                onClick={() => onOpenRemarkModal(REFUND_STATUS.VOID)}
              >
                Void
              </Button>
            )}

            {(status === REFUND_STATUS.OPEN ||
              status === REFUND_STATUS.REFERBACK) && (
              <Button
                key="update"
                onClick={() => updateRefundStatus(REFUND_STATUS.PENDING)}
                type="primary"
                disabled={viewMode}
                loading={updateStatusLoading}
              >
                Submit
              </Button>
            )}

            {status === REFUND_STATUS.PENDING && (
              <Button
                key="update"
                onClick={() => onOpenRemarkModal(REFUND_STATUS.REFERBACK)}
                loading={updateStatusLoading}
              >
                Referback
              </Button>
            )}

            {status === REFUND_STATUS.PENDING && (
              <Button
                key="update"
                onClick={() => onOpenRemarkModal(REFUND_STATUS.APPROVED)}
                loading={updateStatusLoading}
              >
                Approved
              </Button>
            )}
          </Space>,
        ]}
      />

      <Modal
        title={
          updateStatus === REFUND_STATUS.VOID
            ? "Please insert remark before void"
            : updateStatus === REFUND_STATUS.APPROVED
            ? "Please insert remark before approved"
            : "Please insert remark before referback"
        }
        open={remarkModal}
        onCancel={() => setRemarkModal(false)}
        onOk={() => updateRefundStatus(updateStatus)}
        destroyOnClose
      >
        <Input
          onChange={(e) => {
            setRemark(e.target.value);
            console.log(e.target.value);
          }}
        ></Input>
      </Modal>
    </>
  );
};

export default RefundTable;
