import { Button, Card, Input, Select, Space, message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import {
  AgreementsByBatchId,
  agreementsByBatchIdReset,
} from "../../../features/noticesGenerationConfig/noticesGenerationConfigSlice";
import { numberWithCommas } from "../../../helpers/number";
import {
  generateAllNoticePDFThunk,
  generateCSVListThunk,
  generateSingleNoticePDFThunk,
  getAgreementsByBatchIdThunk,
} from "../../../services/noticesGenerationConfigService/noticesGenerationConfigThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import SelectBatch from "../SelectBatch";

import type { TablePaginationConfig, TableProps } from "antd/es/table";

const { Search } = Input;

interface ReminderLetterContentProps {
  letterNumber: string;
}

const ReminderLetterContent = ({
  letterNumber,
}: ReminderLetterContentProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchBatchNo = queryParams.get("searchAgreementNo")?.trim();
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const [searchType, setSearchType] = useState("agreementNo");
  const [loading, setLoading] = useState(false);
  const [resultNotFound, setResultNotFound] = useState(false);
  const [selectedBatchId, setSelectedBatchId] = useState<string>();
  const [selectedBatchNo, setSelectedBatchNo] = useState<string>();
  const { agreementsByBatchId } = useSelector(
    (state: RootState) => state.noticesConfig,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  // const initialParams = useMemo(
  //   () => ({
  //     pagination: {
  //       current: pageNum ? +pageNum : 1,
  //       pageSize: pageSize ? +pageSize : 10,
  //     },
  //     sortOrder: "desc",
  //     sortField: "createdAt",
  //     search: {},
  //     filters: {},
  //   }),
  //   [pageNum, pageSize],
  // );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: agreementsByBatchId?.total,
    // total: 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const generateSingleNoticePDF = (accountBatchID: string) => {
    setIsLoading(true);
    dispatch(
      generateSingleNoticePDFThunk({
        accountBatchID,
      }),
    )
      .unwrap()
      .then((res) => {
        // const htmlReport = parse(res);
        const newWindow = window.open("", "_blank");
        if (newWindow != null) {
          newWindow.document.write(res);
          newWindow.document.close();
        }
        setIsLoading(false);
      });
  };

  const generateAllNoticePDF = (batchID: string) => {
    setIsLoadingAll(true);
    dispatch(
      generateAllNoticePDFThunk({
        batchID,
      }),
    )
      .unwrap()
      .then((res) => {
        // const htmlReport = parse(res);
        const newWindow = window.open("", "_blank");
        if (newWindow != null) {
          newWindow.document.write(res);
          newWindow.document.close();
        }
        setIsLoadingAll(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "No",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Day Past Due",
      dataIndex: "dpd",
      key: "dpd",
      render: (text, record) => record.noticesGenerationBatch.DPD ?? "-",
    },
    {
      title: "Agreement No",
      render: (text, record) => record.accountDetails.agreementNo ?? "-",
    },
    {
      title: "Outstanding Balance (RM)",
      dataIndex: "outstandingBalance",
      key: "outstandingBalance",
      render: (text, record) =>
        numberWithCommas(record.outstandingAmount) ?? "-",
    },
    {
      title: "Reminder Letter Printed Date",
      dataIndex: "reminderLetterPrintedDate",
      key: "reminderLetterPrintedDate",
      render: (text, record) => displayDate(record.printedDate) ?? "-",
    },
    {
      title: "Action",
      render: (_, record) => (
        <Button
          onClick={() => {
            selectedBatchId && generateSingleNoticePDF(record.id);
          }}
          loading={isLoading}
        >
          Print
        </Button>
      ),
    },
  ];

  const onSearch = async (value: string) => {
    if (value) {
      console.log(searchType, value);
      setLoading(true);
      await dispatch(
        getAgreementsByBatchIdThunk({
          batchId: selectedBatchId,
          params: {
            ...initialParams,
            ...filterState,
            currentPage: paginationState.current,
            pageSize: paginationState.pageSize,
            search: { [searchType]: value.toUpperCase() },
          },
        }),
      )
        // .then((res) => {
        //   if (res.payload && res.payload.length > 0) {
        //     setResultNotFound(false);
        //   } else {
        //     message.error("Result Not Found");
        //     setResultNotFound(true);
        //   }
        // })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await dispatch(
        getAgreementsByBatchIdThunk({
          batchId: selectedBatchId,
          params: {
            ...initialParams,
            ...filterState,
            currentPage: paginationState.current,
            pageSize: paginationState.pageSize,
          },
        }),
      );
    }
  };

  const handleBatchNoSelect = async (batchId: string, batchNo: string) => {
    setSelectedBatchId(batchId);
    setSelectedBatchNo(batchNo);
    await dispatch(
      getAgreementsByBatchIdThunk({
        batchId,
        params: {
          ...initialParams,
          ...filterState,
          currentPage: paginationState.current,
          pageSize: paginationState.pageSize,
        },
      }),
    );
  };

  // const handleTableChange: TableProps<AgreementsByBatchId>["onChange"] = async (
  //   pagination,
  //   filters,
  //   sorter: any,
  // ) => {
  //   const params =
  //     sorter?.order == null
  //       ? {
  //           ...initialParams,
  //           ...filterState,
  //           pagination,
  //         }
  //       : {
  //           ...initialParams,
  //           ...filterState,
  //           pagination,
  //           sortField: sorter?.columnKey,
  //           sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
  //         };

  //   dispatch(getAgreementsByBatchIdThunk({ batchId: selectedBatchId, params }));
  //   setPaginationState({
  //     current: pagination.current ?? 1,
  //     pageSize: pagination.pageSize ?? 10,
  //   });

  //   queryParams.set("pageNum", `${pagination.current ?? 1}`);
  //   queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
  //   const newSearch = queryParams.toString();
  //   navigate({
  //     pathname: location.pathname,
  //     search: newSearch,
  //   });
  // };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    await dispatch(
      getAgreementsByBatchIdThunk({
        batchId: selectedBatchId,
        params: {
          ...initialParams,
          currentPage: pagination.current,
          pageSize: pagination.pageSize,
        },
      }),
    );
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const exportCSV = async () => {
    setIsLoadingExcel(true);
    await dispatch(generateCSVListThunk(selectedBatchId))
      .unwrap()
      .then((res) => {
        // const htmlReport = parse(res);
        // const newWindow = window.open("", "_blank");
        // if (newWindow != null) {
        //   newWindow.document.write(res);
        //   newWindow.document.close();
        // }
        downloadFile(res, `BatchList_${selectedBatchNo}`, "csv");
      });
  };

  const downloadFile = (csvContent: any, fileName: any, filetype: any) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const _win = window.navigator as any;
    if (_win && _win.msSaveBlob) {
      // For IE browser
      _win.msSaveBlob(blob, fileName);
      setIsLoadingExcel(false);
    } else {
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
      setIsLoadingExcel(false);
    }
  };

  // useEffect(() => {
  //   if (searchAgreementNo) {
  //     setSearchType("AGREEMENT_NO");
  //     callbackState.handleBatchNoSelect({
  //       agreementNo: searchAgreementNo,
  //     });
  //   }
  // }, [callbackState, searchAgreementNo]);

  useLifecycles(
    () => {
      dispatch(agreementsByBatchIdReset());
    },
    () => {
      dispatch(agreementsByBatchIdReset());
    },
  );

  return (
    <>
      <Card className="mb-4">
        <div className="flex gap-1 mb-2 items-center">
          <span className="w-20">Batch No : </span>
          <Input
            className="w-[20rem]"
            placeholder="Batch No"
            disabled={true}
            value={selectedBatchNo}
          />
          <SelectBatch letterNumber={letterNumber} onOk={handleBatchNoSelect} />
        </div>
        <div className="flex gap-1 mb-2 items-center">
          <span className="w-16">Type : </span>
          <Input.Group compact>
            <Select
              className="w-[10rem]"
              value={searchType}
              onChange={(value) => {
                // setResultNotFound(false);
                setSearchType(value);
              }}
              options={[
                { value: "agreementNo", label: "Agreement No" },
                { value: "applicantNric", label: "Applicant NRIC" },
                { value: "applicantFirstName", label: "Applicant Name" },
                { value: "applicantPhoneNo", label: "Phone No" },
                { value: "applicantEmail", label: "Email" },
              ]}
            />
            <Search
              className="w-[20rem]"
              placeholder="Input search text"
              enterButton="Search"
              loading={loading}
              onSearch={onSearch}
              defaultValue={searchBatchNo}
              status={resultNotFound ? "error" : ""}
              onChange={() => setResultNotFound(false)}
            />
          </Input.Group>
        </div>
      </Card>
      {agreementsByBatchId?.data != null &&
        agreementsByBatchId?.data?.length > 0 && (
          <div className="flex justify-end mb-2">
            <Space>
              <Button onClick={() => exportCSV()} loading={isLoadingExcel}>
                Export to Excel
              </Button>
              <Button
                onClick={() => {
                  selectedBatchId && generateAllNoticePDF(selectedBatchId);
                }}
                loading={isLoadingAll}
              >
                Print All
              </Button>
            </Space>
          </div>
        )}
      <Table
        rowKey="id"
        className="min-w-fit"
        dataSource={agreementsByBatchId?.data ?? []}
        loading={agreementsByBatchId.isLoading}
        columns={columns}
        bordered={true}
        onChange={handleTableChange}
        pagination={tablePagination}
      />
    </>
  );
};

export default ReminderLetterContent;
