import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";
import {
  BatchNoResponse,
  waiverDataResponse,
} from "../../features/waiver/waiverSlice";

export const getWaiver = async (params: any) => {
  const { agreementId, agreementNo, pagination, ...restParams } = params;
  const paramsData = {
    ...restParams,
    currentPage: pagination.current,
    pageSize: pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get<waiverDataResponse>(
    `/redCash/admin/api/v1.0//${agreementId}`,
    { params: paramsData },
  );
  return result.data;
};

export const getBatchNo = async (params: any) => {
  const { pagination, ...restParams } = params;
  const paramsData = {
    ...restParams,
    currentPage: pagination.current,
    pageSize: pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get<BatchNoResponse>(
    `/redCash/api/v1.0/waiver/getBatchNo`,
    { params: paramsData },
  );

  return result.data;
};

export const getWaiverDetailsById = async (waiverId: any) => {
  const result = await collectionSetupAxiosInstance.get<BatchNoResponse>(
    `/redCash/api/v1.0/waiver/getWaiverBatchDetails/${waiverId}`,
  );
  return result.data;
};

export const createBatchNo = async (params: any) => {
  const { username } = params;

  const result = await collectionSetupAxiosInstance.post<any>(
    `/redCash/api/v1.0/waiver/createWaiverBatch`,
    { username },
  );

  return result.data;
};

export const createWaiverRecord = async (requestData: any) => {
  const { payload } = requestData;
  const result = await collectionSetupAxiosInstance.post<any>(
    `/redCash/api/v1.0/waiver/addWaiverRecord`,
    payload,
  );

  return result.data;
};

export const submitWaiverForApproval = async (requestData: any) => {
  const { payload } = requestData;
  const result = await collectionSetupAxiosInstance.patch<any>(
    `/redCash/api/v1.0/waiver/submitWaiverForApproval/${payload.waiverId}`,
    payload,
  );

  return result.data;
};

export const getAgreementList = async (params: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/waiver/getAgreementListByWaiverId/${params}`,
  );
  return result.data;
};

export const deleteAgreementFromwaiver = async (requestData: any) => {
  const { payload } = requestData;
  console.log(payload);
  console.log(requestData);
  const result = await collectionSetupAxiosInstance.delete<any>(
    `/redCash/api/v1.0/waiver/deleteAgreementNoFromWaiver/waiverId/${payload.waiverId}/accountDetailsId/${payload.accountDetailsId}`,
  );

  return result.data;
};

export const getWaiverRemarks = async (params: any) => {
  console.log(params);
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/waiver/getWaiverRemarks/${params.waiverRemarks}`,
    { params: params },
  );
  return result.data;
};

export const submitWaiverToReferback = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch<any>(
    `/redCash/api/v1.0/waiver/${requestData.waiverId}/referback`,
    requestData,
  );

  return result.data;
};

export const submitWaiverToVoid = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch<any>(
    `/redCash/api/v1.0/waiver/${requestData.waiverId}/void`,
    requestData,
  );

  return result.data;
};

export const submitWaiverToApprove = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch<any>(
    `/redCash/api/v1.0/waiver/${requestData.waiverId}/approve`,
    requestData,
  );

  return result.data;
};

export const getWaiverCharges = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get<any>(
    `/redCash/api/v1.0/waiver/getWaiverCharges/waiverId/${requestData.waiverId}/accountDetailsId/${requestData.accountDetailsId}`,
  );

  return result.data;
};

export const editWaiverRecord = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch<any>(
    `/redCash/api/v1.0/waiver/editWaiverAgreementNo/${requestData.waiverId}`,
    requestData,
  );

  return result.data;
};

export const checkAgreement = async (agreementNo: any) => {
  const result = await collectionSetupAxiosInstance.get<any>(
    `/redCash/api/v1.0/waiver/checkAgreement/${agreementNo}`,
  );

  return result.data;
};
