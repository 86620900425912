import { getNricInfo } from "../../../../../utils/nric.util";
import { trimPhoneNo } from "../../../../../utils/number.util";
import { displayDate } from "../../../../../utils/datetime.util";
import { getFormattedAddress } from "../../../../../utils/string.util";
import InfoGrid from "../../../../../components/InfoGrid";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { SME_ORGANIZATION_TYPE } from "../../../../../enum/smeApplication";
import { EditApplicationInfoModal } from "./EditApplicationInfo";

export const ApplicationInfoDetailsTab = () => {
  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const data = smeApplicationDetails?.data?.smeIndividualApplication || smeApplicationDetails?.data?.smeSolePropApplication || smeApplicationDetails?.data?.smePartnershipApplication;

  const customerInfo1 = [
    {
      title: "Full Name",
      value: data?.name,
    },
    {
      title: "Nationality",
      value: data?.nationality,
    },
    {
      title: "Race",
      value: data?.applicantRace,
    },
  ];

  const customerInfo2 = [
    {
      title: "Identity Card Type",
      value: data?.icType,
    },
    {
      title: "NRIC",
      value: data?.nric,
    },
    {
      title: "Date Of Birth",
      value: displayDate(data?.dateOfBirth),
    },
    {
      title: "Age",
      value: getNricInfo(data?.nric).age,
    },
  ];

  const customerInfo3 = [
    {
      title: "Email Address",
      value: data?.emailAddress,
    },
    {
      title: "Contact No.",
      value: trimPhoneNo(data?.applicantPhoneNo),
    },
    {
      title: "Occupation Type",
      value: data?.occupationType,
    },
  ];

  const customerInfo4 = [
    {
      title: "NRIC Address",
      value: getFormattedAddress(data?.nricAddress1, data?.nricAddress2, data?.nricAddress3),
    },
    {
      title: "NRIC Postcode",
      value: data?.nricPostcode,
    },
    {
      title: "NRIC City",
      value: data?.nricCity,
    },
    {
      title: "NRIC State",
      value: data?.nricState,
    },
    {
      title: "Home Address",
      value: getFormattedAddress(data?.homeAddress1, data?.homeAddress2, data?.homeAddress3),
    },
    {
      title: "Home Postcode",
      value: data?.homePostcode,
    },
    {
      title: "Home City",
      value: data?.homeCity,
    },
    {
      title: "Home State",
      value: data?.homeState,
    },
    {
      title: "Mailing Address",
      value: getFormattedAddress(data?.mailingAddress1, data?.mailingAddress2, data?.mailingAddress3),
    },
    {
      title: "Mailing Postcode",
      value: data?.mailingPostcode,
    },
    {
      title: "Mailing City",
      value: data?.mailingCity,
    },
    {
      title: "Mailing State",
      value: data?.mailingState,
    }
  ];

  const employmentTypeInfo = [
    {
      title: "Employment Type",
      value: data?.applicantEmploymentType,
    },
  ]

  const employmentInfo = [
    ...(smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.INDIVIDUAL ? employmentTypeInfo : []),
    {
      title: "Name of Employer / Nature of Business",
      value: data?.employerName,
    },
  ];

  const emergencyInfo = [
    {
      title: "Emergency Name",
      value: data?.emergencyContactPersonName,
    },
    {
      title: "Emergency Contact Number",
      value: trimPhoneNo(data?.emergencyContactPersonPhoneNo),
    },
    {
      title: "Emergency Email",
      value: data?.emergencyContactEmail,
    },
    {
      title: "Relationship",
      value: data?.emergencyContactRelationship,
    },
  ];

  return (
    <div className="info-container">
      <InfoGrid
        title="Customer Information"
        data={customerInfo1}
        extra={
          <EditApplicationInfoModal />
        }
      />
      <br /><br />
      <InfoGrid data={customerInfo2} />
      <br /><br />
      <InfoGrid data={customerInfo3} />
      <br /><br />
      <InfoGrid data={customerInfo4} />
      <br /><br />
      <InfoGrid title="Employment Information" data={employmentInfo} />
      {smeApplicationDetails?.data?.organizationType !== SME_ORGANIZATION_TYPE.COMPANY && (
        <>
          <br /><br />
          <InfoGrid title="Emergency Information" data={emergencyInfo} />
        </>
      )}
    </div>
  );
};