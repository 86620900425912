import "./index.less";

import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import PasswordConfirmationModal from "../PasswordConfirmationModal";

const UpdatePasswordModal: React.FC<{
  callback: () => void;
  form: any;
}> = ({ callback, form }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.setFieldsValue({ password: null });
  };

  const onFinish = async () => {
    setIsModalOpen(false);
    // setIsPasswordModalOpen(true);
    // callback();
  };

  return (
    <>
      <Button onClick={showModal}>New Password</Button>
      <Modal
        title="Update Officer Password"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={500}
        centered
        destroyOnClose
      >
        <Form
          form={form}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            className="my-5"
            label={<span className="w-[100px]">Password</span>}
            name="password"
            rules={[
              {
                required: true,
                message: "Please input officer password.",
              },
            ]}
          >
            <Input.Password className="w-[300px]" />
          </Form.Item>
        </Form>
      </Modal>
      <PasswordConfirmationModal
        callback={callback}
        handleCancelPassDown={handleCancel}
        isModalOpen={isPasswordModalOpen}
        setIsModalOpen={setIsPasswordModalOpen}
      />
    </>
  );
};

export default UpdatePasswordModal;
