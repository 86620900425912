import "./index.less";

import { Button, Form, message, Popconfirm, Select, Space } from "antd";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import { Application } from "../../features/application/applicationSlice";
import { AppDispatch, RootState } from "../../store/store";
import { disableDirectDebitThunk } from "../../services/agreementService/agreementThunk";
import {
  getAccountDetailsThunk,
  getDueAccountTenureDetailsThunk,
  getAccountTenureDetailsThunk,
  getCollectionHistoryLogThunk,
} from "../../services/collectionService/collectionThunk";
import { getDunningHistoryListingThunk } from "../../services/dunningService/dunningThunk";

type DirectDebitFormModalProps = {
  searchAgreementNo: string;
  applicationDetails?: Application;
  callback?: () => void;
  form?: string;
};

const DirectDebitFormModal: React.FC<DirectDebitFormModalProps> = (props) => {
  const { searchAgreementNo } = props;
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const dispatch = useDispatch<AppDispatch>();

  const [directDebitLoading, setDirectDebitLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const disableDirectDebit = async () => {
    setDirectDebitLoading(true);

    const res = await dispatch(
      disableDirectDebitThunk({
        accountDetailsId: accountEnquiry.data?.accountDetails?.id,
        agreementId: accountEnquiry.data.accountDetails?.agreementId,
      }),
    )
      .unwrap()
      .then((res) => {
        if (searchAgreementNo) {
          dispatch(getAccountDetailsThunk({ agreementNo: searchAgreementNo }))
            .unwrap()
            .then((res) => {
              dispatch(
                getDunningHistoryListingThunk({
                  accountDetailsId: res?.accountDetails?.id,
                }),
              );
              dispatch(
                getDueAccountTenureDetailsThunk({
                  agreementNo: searchAgreementNo,
                }),
              );
              dispatch(
                getAccountTenureDetailsThunk({
                  agreementNo: searchAgreementNo,
                }),
              );

              dispatch(
                getCollectionHistoryLogThunk({
                  accountDetailsId: res?.accountDetails?.id,
                }),
              );
            })
            .finally(() => {
              setLoadingSearch(false);
            });
        }
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setDirectDebitLoading(false);
      });
  };

  return (
    <>
      <Popconfirm
        title="Disable direct debit?"
        onConfirm={disableDirectDebit}
        okText="Yes"
        cancelText="No"
      >
        <Button loading={directDebitLoading}>
          <Space>
            Cancel Direct Debit
            <DeleteOutlined />
          </Space>
        </Button>
      </Popconfirm>
    </>
  );
};

export default DirectDebitFormModal;
