import { Button, Input, message, Popconfirm, Space } from "antd";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ApplicationStatusEnum } from "../../../../enum/applicationStepStatus";
import { Role } from "../../../../enum/roles";
import {
  Application,
  applicationStateReset,
} from "../../../../features/application/applicationSlice";
import {
  approveApplicationThunk,
  cancelApplicationThunk,
  createInternalRemarkThunk,
  getApplicationDetailsThunk,
  getApprovedApplicationsThunk,
  getCancelledApplicationsThunk,
  getFollowUpApplicationsThunk,
  getInternalRemarksByApplicationIDThunk,
  getPendingApplicationsThunk,
  getReferbackApplicationsThunk,
  getReferbackFromPendingDisbursementApplicationsThunk,
  getRejectedApplicationsThunk,
  getSubmitForApprovalApplicationsThunk,
  referBackApplicationThunk,
  rejectApplicationThunk,
  submitForApprovalThunk,
  updateApplicationStatusToCaseSettledFromFollowedUpThunk,
  updateApplicationStatusToFollowedUpThunk,
} from "../../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { IS_DEVELOPMENT_MODE } from "../../../../utils/environment.util";
import { LANGUAGE, setLanguage } from "../../../../features/config/configSlice";

const { TextArea } = Input;

const UpdateApplicationAction = ({
  applicationDetails,
  approveCallback,
  callback,
}: {
  applicationDetails: Application;
  approveCallback: () => void;
  callback: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [remarks, setRemarks] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [referbackLoading, setReferbackLoading] = useState(false);
  const [followUpLoading, setFollowUpLoading] = useState(false);
  const [doneFollowUpLoading, setDoneFollowUpLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;
  const { language } = useSelector((state: RootState) => state.config);

  const viewRemarkInput =
    (applicationDetails?.applicationStatus ===
      ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL ||
      applicationDetails?.applicationStatus ===
        ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT ||
      applicationDetails?.applicationStatus ===
        ApplicationStatusEnum.REFERBACK ||
      applicationDetails?.applicationStatus ===
        ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT ||
      applicationDetails?.applicationStatus === ApplicationStatusEnum.PENDING ||
      applicationDetails?.applicationStatus ===
        ApplicationStatusEnum.FOLLOWED_UP) &&
    !(
      currentRole === Role.APPROVER &&
      (applicationDetails?.applicationStatus ===
        ApplicationStatusEnum.PENDING ||
        applicationDetails?.applicationStatus ===
          ApplicationStatusEnum.REFERBACK ||
        applicationDetails?.applicationStatus ===
          ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT ||
        applicationDetails?.applicationStatus ===
          ApplicationStatusEnum.FOLLOWED_UP)
    );

  const statusToCancelApplication =
    applicationDetails?.applicationStatus === ApplicationStatusEnum.PENDING ||
    applicationDetails.applicationStatus === ApplicationStatusEnum.REFERBACK;

  const statusToViewSubmitForApprovalOrReject =
    (applicationDetails.applicationStatus === ApplicationStatusEnum.PENDING ||
      applicationDetails.applicationStatus ===
        ApplicationStatusEnum.REFERBACK ||
      applicationDetails.applicationStatus ===
        ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT) &&
    (currentRole === Role.SUPER_ADMIN || currentRole === Role.VERIFIER);

  const statusToReferBack =
    applicationDetails?.applicationStatus ===
      ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL ||
    applicationDetails?.applicationStatus ===
      ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT;

  const statusToFollowUp =
    applicationDetails?.applicationStatus === ApplicationStatusEnum.PENDING;

  const statusToDoneFollowUp =
    applicationDetails?.applicationStatus === ApplicationStatusEnum.FOLLOWED_UP;

  const redirectAfterSubmit = (applicationStatus: string) => {
    if (applicationStatus === ApplicationStatusEnum.PENDING) {
      queryParams.set("tab", "pending");
    } else if (applicationStatus === ApplicationStatusEnum.REFERBACK) {
      queryParams.set("tab", "referBack");
    } else if (applicationStatus === ApplicationStatusEnum.FOLLOWED_UP) {
      queryParams.set("tab", "followUp");
    } else if (applicationStatus === ApplicationStatusEnum.REJECTED) {
      queryParams.set("tab", "rejected");
    } else if (
      applicationStatus ===
      ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT
    ) {
      queryParams.set("tab", "referBackFromPendingDisbursement");
    }
    const newSearch = queryParams.toString();

    navigate({
      pathname: "/application-listing/verifying",
      search: newSearch,
    });
  };

  const fetchApplicationListing = (applicationStatus: string | undefined) => {

    const initialParams = {
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "submissionDate",
      search: {},
      filters: {},
    }

    if (applicationStatus === ApplicationStatusEnum.PENDING) {
      dispatch(getPendingApplicationsThunk(initialParams));
    } else if (applicationStatus === ApplicationStatusEnum.REFERBACK) {
      dispatch(getReferbackApplicationsThunk(initialParams));
    } else if (
      applicationStatus ===
      ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT
    ) {
      dispatch(getReferbackFromPendingDisbursementApplicationsThunk({}));
    } else if (applicationStatus === ApplicationStatusEnum.FOLLOWED_UP) {
      dispatch(getFollowUpApplicationsThunk(initialParams));
    } else if (applicationStatus === ApplicationStatusEnum.REJECTED) {
      dispatch(getRejectedApplicationsThunk(initialParams));
    } else if (applicationStatus === ApplicationStatusEnum.CANCELED) {
      dispatch(getCancelledApplicationsThunk(initialParams));
    } else if (
      applicationStatus === ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL
    ) {
      dispatch(getSubmitForApprovalApplicationsThunk(initialParams));
    } else if (applicationStatus === ApplicationStatusEnum.APPROVED) {
      dispatch(getApprovedApplicationsThunk(initialParams));
    }
  };

  const onRemarkChange = (e: any) => {
    setRemarks(e.target.value);
  };

  const onCreateRemark = () => {
    if (remarks) {
      dispatch(
        createInternalRemarkThunk({
          companyId: process.env.REACT_APP_COMPANY_ID,
          applicationId: applicationDetails?.id,
          remarks,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(
            getInternalRemarksByApplicationIDThunk({
              filters: { applicationId: applicationDetails?.id },
            }),
          );
          message.success("Remark submitted");
          setRemarks("");
        })
        .catch(() => {
          message.error("Remark submission failed");
        });
    }
  };

  const onSubmitForApproval = () => {
    setSubmitLoading(true);

    // onCreateRemark();

    if (!remarks.length) {
      setSubmitLoading(false);
      return message.error("Remark is required");
    }

    const payload = {
      username: localStorage.getItem("username"),
      companyId: process.env.REACT_APP_COMPANY_ID,
      applicationId: applicationDetails?.id,
      remarks,
    };

    dispatch(
      submitForApprovalThunk({
        applicationId: applicationDetails?.id,
        payload,
      }),
    )
      .unwrap()
      .then((res) => {
        message.success("Application submitted for approval");
        fetchApplicationListing(applicationDetails?.applicationStatus);
        fetchApplicationListing(ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL);
        callback();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const onApproveApplication = () => {
    setApproveLoading(true);

    // onCreateRemark();
    if (!remarks.length) {
      setApproveLoading(false);
      return message.error("Remark is required");
    }

    let languageToPass = "EN";
    if (applicationDetails.language && applicationDetails.language === "MS") {
      languageToPass = "BM";
    }

    const payload = {
      username: localStorage.getItem("username"),
      companyId: process.env.REACT_APP_COMPANY_ID,
      applicationId: applicationDetails?.id,
      remarks,
    };

    dispatch(
      approveApplicationThunk({
        applicationId: applicationDetails?.id,
        language: languageToPass,
        payload,
      }),
    )
      .unwrap()
      .then(async (res) => {
        message.success("Application approved");
        fetchApplicationListing(applicationDetails?.applicationStatus);
        fetchApplicationListing(ApplicationStatusEnum.APPROVED);
        callback();
        if (approveCallback) approveCallback();
      })
      .finally(() => {
        setApproveLoading(false);
      });
  };

  const onRejectApplication = () => {
    setRejectLoading(true);

    // onCreateRemark();
    if (!remarks.length) {
      setRejectLoading(false);
      return message.error("Remark is required");
    }

    const payload = {
      username: localStorage.getItem("username"),
      companyId: process.env.REACT_APP_COMPANY_ID,
      applicationId: applicationDetails?.id,
      remarks,
    };
    dispatch(
      rejectApplicationThunk({
        applicationId: applicationDetails?.id,
        payload,
      }),
    )
      .unwrap()
      .then((res) => {
        message.success("Application rejected");
        fetchApplicationListing(applicationDetails?.applicationStatus);
        fetchApplicationListing(ApplicationStatusEnum.REJECTED);
        callback();
      })
      .finally(() => {
        setRejectLoading(false);
        // window.location.reload(); // force refresh because api is not returning the correct status
        if (IS_DEVELOPMENT_MODE) {
          // user may not exists and throw error // continue if development mode
          callback();
        }
      });
  };

  const onFollowUpApplication = () => {
    setFollowUpLoading(true);

    // onCreateRemark();
    if (!remarks.length) {
      setFollowUpLoading(false);
      return message.error("Remark is required");
    }

    const payload = {
      username: localStorage.getItem("username"),
      companyId: process.env.REACT_APP_COMPANY_ID,
      applicationId: applicationDetails?.id,
      remarks,
    };

    dispatch(
      updateApplicationStatusToFollowedUpThunk({
        applicationId: applicationDetails?.id,
        payload,
      }),
    )
      .unwrap()
      .then(() => {
        message.success("Application followed up");
        fetchApplicationListing(applicationDetails?.applicationStatus);
        fetchApplicationListing(ApplicationStatusEnum.FOLLOWED_UP);
        callback();
      })
      .finally(() => {});
  };

  const onDoneFollowUpApplication = () => {
    setDoneFollowUpLoading(true);

    onCreateRemark();

    dispatch(
      updateApplicationStatusToCaseSettledFromFollowedUpThunk({
        applicationId: applicationDetails?.id,
        // payload: { remarks },
      }),
    )
      .unwrap()
      .then(() => {
        message.success("Application done follow up");
        fetchApplicationListing(applicationDetails?.applicationStatus);
        fetchApplicationListing(ApplicationStatusEnum.FOLLOWED_UP);
        callback();
      })
      .finally(() => {
        setDoneFollowUpLoading(false);
      });
  };

  const onReferBackApplication = () => {
    setReferbackLoading(true);

    // onCreateRemark();
    if (!remarks.length) {
      setReferbackLoading(false);
      return message.error("Remark is required");
    }

    const payload = {
      username: localStorage.getItem("username"),
      companyId: process.env.REACT_APP_COMPANY_ID,
      applicationId: applicationDetails?.id,
      remarks,
    };

    dispatch(
      referBackApplicationThunk({
        applicationId: applicationDetails?.id,
        payload,
      }),
    )
      .unwrap()
      .then(() => {
        message.success("Application referred back");
        fetchApplicationListing(applicationDetails?.applicationStatus);
        fetchApplicationListing(ApplicationStatusEnum.REFERBACK);
        callback();
      })
      .finally(() => {
        setReferbackLoading(false);
      });
  };

  const onCancelApplication = () => {
    setDeleteLoading(true);

    // onCreateRemark();
    if (!remarks.length) {
      setDeleteLoading(false);
      return message.error("Remark is required");
    }

    const payload = {
      username: localStorage.getItem("username"),
      companyId: process.env.REACT_APP_COMPANY_ID,
      applicationId: applicationDetails?.id,
      remarks,
    };
    dispatch(
      cancelApplicationThunk({
        applicationId: applicationDetails?.id,
        payload,
      }),
    )
      .unwrap()
      .then(() => {
        message.success("Application cancelled");
        fetchApplicationListing(applicationDetails?.applicationStatus);
        fetchApplicationListing(ApplicationStatusEnum.CANCELED);
        callback();
      })
      .finally(() => setDeleteLoading(false));
  };

  const handleLangChange = (lang: LANGUAGE) => {
    dispatch(setLanguage(lang));
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    if (applicationDetails) {
      if (
        applicationDetails.language === "ms" ||
        applicationDetails.language === "bm"
      ) {
        handleLangChange(LANGUAGE.MS);
      } else {
        handleLangChange(LANGUAGE.EN);
      }
    }
  }, [applicationDetails]);

  return (
    <>
      {viewRemarkInput && (
        <div className="mb-2">
          <p className="info-title">Remark</p>
          <TextArea value={remarks} onChange={onRemarkChange} rows={4} />
          <br />
          <br />
        </div>
      )}

      <>
        <div className="flex justify-end mb-4">
          <Space>
            {(currentRole === Role.SUPER_ADMIN ||
              currentRole === Role.VERIFIER) &&
              (statusToCancelApplication || statusToDoneFollowUp) && (
                <Popconfirm
                  title="Do you want to cancel this application?"
                  onConfirm={onCancelApplication}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="large" loading={deleteLoading} danger>
                    Cancel
                  </Button>
                </Popconfirm>
              )}

            {(currentRole === Role.SUPER_ADMIN ||
              currentRole === Role.VERIFIER) &&
              statusToFollowUp && (
                <Button
                  size="large"
                  loading={followUpLoading}
                  onClick={onFollowUpApplication}
                >
                  Follow Up
                </Button>
              )}

            {(currentRole === Role.SUPER_ADMIN ||
              currentRole === Role.VERIFIER) &&
              statusToDoneFollowUp && (
                <>
                  <Popconfirm
                    title="Reject this application?"
                    onConfirm={onRejectApplication}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="large" loading={rejectLoading}>
                      Reject
                    </Button>
                  </Popconfirm>

                  <Button
                    type="primary"
                    size="large"
                    loading={submitLoading}
                    onClick={onSubmitForApproval}
                  >
                    Submit for Approval
                  </Button>
                  {/* <Button
                    size="large"
                    loading={doneFollowUpLoading}
                    onClick={onDoneFollowUpApplication}
                  >
                    Done Follow Up
                  </Button> */}
                </>
              )}

            {(currentRole === Role.SUPER_ADMIN ||
              currentRole === Role.VERIFIER) && (
              <>
                {statusToViewSubmitForApprovalOrReject && (
                  <>
                    <Popconfirm
                      title="Reject this application?"
                      onConfirm={onRejectApplication}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button size="large" loading={rejectLoading}>
                        Reject
                      </Button>
                    </Popconfirm>
                    <Button
                      type="primary"
                      size="large"
                      loading={submitLoading}
                      onClick={onSubmitForApproval}
                    >
                      Submit for Approval
                    </Button>
                  </>
                )}
              </>
            )}

            {(currentRole === Role.SUPER_ADMIN ||
              currentRole === Role.APPROVER ||
              currentRole === Role.VERIFIER_2 ||
              currentRole === Role.VERIFIER) &&
              statusToReferBack && (
                <Button
                  size="large"
                  loading={referbackLoading}
                  onClick={onReferBackApplication}
                >
                  Refer-back
                </Button>
              )}

            {(currentRole === Role.SUPER_ADMIN ||
              currentRole === Role.APPROVER ||
              currentRole == Role.VERIFIER_2) &&
              statusToReferBack && (
                <>
                  <Popconfirm
                    title="Reject this application?"
                    onConfirm={onRejectApplication}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="large" loading={rejectLoading}>
                      Reject
                    </Button>
                  </Popconfirm>
                  <Button
                    type="primary"
                    size="large"
                    loading={approveLoading}
                    onClick={onApproveApplication}
                  >
                    Approve
                  </Button>
                </>
              )}
          </Space>
        </div>
      </>
    </>
  );
};

export default UpdateApplicationAction;
