import { createSlice } from "@reduxjs/toolkit";
import { getAllRegionThunk } from "../../services/caseAssignmentService/regionService/regionThunk";

export type Region = {
  id: string;
  regionName: string;
  createdAt?: string;
  updatedAt?: string;
};

export type RegionState = {
  regionsState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Region[];
  };
};

const initialState: RegionState = {
  regionsState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    regionReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRegionThunk.pending, (state) => {
        state.regionsState.isLoading = true;
      })
      .addCase(getAllRegionThunk.fulfilled, (state, action) => {
        state.regionsState.isLoading = false;
        state.regionsState.data = action.payload.data;
      })
      .addCase(getAllRegionThunk.rejected, (state, action) => {
        state.regionsState.isLoading = false;
        state.regionsState.isError = true;
        state.regionsState.errorMessage = action.payload;
      });
  },
});

export const { regionReset } = regionSlice.actions;
export default regionSlice.reducer;
