import { AppDispatch, RootState } from "../../../store/store";
import { Button, Input, Select, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";

import type { ColumnsType } from "antd/es/table";
import FieldVisitCompanyFormModal from "./Company/FieldVisitCompanyFormModal";
import { Helmet } from "react-helmet-async";
import { PageHeader } from "@ant-design/pro-components";
import type { TablePaginationConfig } from "antd/es/table";
import lodash from "lodash";
import { getAllFieldVisitCompanyThunk } from "../../../services/fieldVisitService/fieldVisitThunk";
import { useLifecycles } from "react-use";

const { Search } = Input;

const FieldVisitOfficerManagementPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { fieldVisitCompanyState } = useSelector(
    (state: RootState) => state.fieldVisit,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchField, setSearchFiled] = useState("companyCode");
  const searchFields = ["companyCode", "companyName"];

  const tablePagination = {
    total: fieldVisitCompanyState.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Company Code",
      dataIndex: "companyCode",
      key: "companyCode",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (_, record) => (
        <>
          {lodash
            .uniq(
              record.companyLocation?.map((item: any) =>
                item.state.toUpperCase(),
              ),
            )
            .join(", ")}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "companyEmail",
      key: "companyEmail",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text, record) => (text ? "Active" : "Not Active"),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => {
        return (
          <Button
            onClick={() => {
              navigate(
                `/field-visit-officer-management/company-details/${record.id}`,
              );
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  const fetchAllFieldVisitCompany = async (params?: any) => {
    dispatch(getAllFieldVisitCompanyThunk({ params }));
  };

  const onSearch = (value: string) => {
    fetchAllFieldVisitCompany({
      ...initialParams,
      filters: { ...filterState, [searchField]: value },
    });
    setFilterState({ ...initialParams.filters, [searchField]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchAllFieldVisitCompany({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };
    fetchAllFieldVisitCompany(params);
  });

  return (
    <>
      <Helmet>
        <title>Field Visit Officer Management - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title="Field Visit Officer Management"
      />
      <div className="flex justify-between mb-2">
        <Input.Group compact>
          <Select
            defaultValue={searchField}
            style={{ width: 200 }}
            onChange={(value) => setSearchFiled(value)}
            options={
              Array.isArray(columns)
                ? columns
                    .filter(
                      (col: any) =>
                        col.key !== "action" && searchFields.includes(col.key),
                    )
                    .map((col: any) => ({
                      value: col.dataIndex,
                      label: `${col.title}`,
                    }))
                : []
            }
          />

          <Search
            placeholder="Input search text"
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Input.Group>
        <FieldVisitCompanyFormModal
          callback={() => fetchAllFieldVisitCompany(initialParams)}
        />
      </div>

      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={fieldVisitCompanyState?.data ?? []}
        loading={fieldVisitCompanyState.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default FieldVisitOfficerManagementPage;
