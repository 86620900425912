import "./index.less";

import { Button, Form, Input, Modal, message } from "antd";
import React, { useState } from "react";
import { loginThunk } from "../../../../../../services/userService/userThunk";
import { ProForm, ProFormText } from "@ant-design/pro-components";
import { IdcardOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";

const PasswordConfirmationModal: React.FC<{
  callback: () => void;
  handleCancelPassDown: () => void;
  isModalOpen: boolean;
  setIsModalOpen: any;
}> = ({ callback, handleCancelPassDown, isModalOpen, setIsModalOpen }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>();

  const handleCancel = () => {
    handleCancelPassDown();
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    await form.validateFields().then(async (values) => {
      setLoading(true);
      await dispatch(
        loginThunk({
          username: values.username,
          password: values.password,
        }),
      )
        .unwrap()
        .then((res) => {
          callback();
          setIsModalOpen(false);
        })
        .catch((err) => {
          message.error("Invalid username or password");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <>
      <Modal
        title="Login Required"
        okText="Confirm"
        cancelText="Cancel"
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
        width={500}
        centered
        destroyOnClose
        confirmLoading={loading}
      >
        <Form form={form} layout="vertical" className="my-7">
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username.",
              },
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input user password.",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PasswordConfirmationModal;
