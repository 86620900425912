import "antd/dist/reset.css";
import "./App.less";

import { ConfigProvider } from "antd";
import jwt_decode from "jwt-decode";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import LoadingSpinner from "./components/LoadingSpinner";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "./components/ProtectedRoute";
import { Role } from "./enum/roles";
import { MainLayout } from "./layout/MainLayout";
import { authRoutes, notAuthRoutes } from "./routes";
import { logoutThunk } from "./services/userService/userThunk";
import { AppDispatch, RootState } from "./store/store";
import { IS_DEVELOPMENT_MODE } from "./utils/environment.util";

const LogoutPage = lazy(() => import("./pages/Logout/LogoutPage"));

const helmetContext = {};

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const { hasLoggedUser } = useSelector((state: RootState) => state.user);

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: hasLoggedUser,
    authenticationPath: "/login",
  };

  const [state, setState] = useState<string>("Active");
  const [count, setCount] = useState<number>(0);
  const [remaining, setRemaining] = useState<number>(0);

  const onIdle = () => {
    dispatch(logoutThunk());
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1800000, // 30 min
    throttle: 500,
  });

  const setRoutes: any = (route: any) =>
    route?.routes?.length > 0 &&
    route.routes?.map((subRoute: any) => {
      return (
        <Route
          key={subRoute.id}
          path={subRoute.path ?? ""}
          element={subRoute.component}
        >
          {setRoutes(subRoute)}
        </Route>
      );
    });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <HelmetProvider context={helmetContext}>
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#e40308" },
        }}
      >
        <div className="App">
          <BrowserRouter>
            <Suspense
              fallback={
                <div className="h-screen m-auto">
                  <LoadingSpinner />
                </div>
              }
            >
              <Routes>
                {notAuthRoutes.map((route: any) => (
                  <Route
                    key={route.id}
                    path={route.path ?? ""}
                    element={route.component}
                    // element={
                    //   <>
                    //     <Helmet>
                    //       <title>{`${route.name} - RedCash CEP`} </title>
                    //       <meta name={route.name} content={route.name} />
                    //     </Helmet>
                    //     <route.component />
                    //   </>
                    // }
                  />
                ))}

                <Route
                  path="/"
                  element={
                    <ProtectedRoute
                      {...defaultProtectedRouteProps}
                      outlet={<MainLayout />}
                    />
                  }
                >
                  {authRoutes.map(
                    (route: any) =>
                      route?.access.some(
                        (role: Role) => role.valueOf() === currentRole,
                      ) && (
                        <Route
                          key={route.id}
                          path={route.path}
                          element={route.component}
                        >
                          {setRoutes(route)}
                        </Route>
                      ),
                  )}
                </Route>
                <Route path={"/logout"} element={<LogoutPage />} />
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={
                        hasLoggedUser &&
                        Object.values(Role).some(
                          (access: string) => access === currentRole,
                        )
                          ? "/dashboard"
                          : "/login"
                      }
                      replace
                    />
                  }
                />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </div>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default App;
