import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../../helpers/errorMessage";
import groupService from "./groupService";

export const getAllGroupThunk = createAsyncThunk(
  "group/getAll",
  async (requestData: any, thunkAPI) => {
    try {
      return await groupService.getAllGroup(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createGroupThunk = createAsyncThunk(
  "group/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await groupService.createGroup(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateGroupThunk = createAsyncThunk(
  "group/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await groupService.updateGroup(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteGroupThunk = createAsyncThunk(
  "group/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await groupService.deleteGroup(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
