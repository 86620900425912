import { Tabs, Tag } from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import {
  getCompletedLiveAttestationsThunk,
  getPendingAttestationsThunk,
  getRequestedLiveAttestationsThunk,
  getVideoAttestationsThunk,
} from "../../../services/attestationService/attestationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import CompletedLiveAttestationPage from "./CompletedLiveAttestation";
import PendingAttestationPage from "./PendingAttestation";
import RequestedLiveAttestationPage from "./RequestedLiveAttestation";
import VideoAttestationPage from "./VideoAttestation";

import type { TabsProps } from "antd";

const AttestationListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    pendingAttestationList,
    requestedLiveAttestationList,
    completedLiveAttestationList,
    videoAttestationList,
  } = useSelector((state: RootState) => state.attestation);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const items: TabsProps["items"] = [
    {
      key: "pending",
      label: (
        <span>
          Pending Attestation{" "}
          {pendingAttestationList?.data?.searchTotal != null && (
            <Tag>{pendingAttestationList.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <PendingAttestationPage />,
    },
    {
      key: "live-requested",
      label: (
        <span>
          Requested for Live Attestation{" "}
          {requestedLiveAttestationList?.data?.searchTotal != null && (
            <Tag>{requestedLiveAttestationList.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <RequestedLiveAttestationPage />,
    },
    {
      key: "live-completed",
      label: (
        <span>
          Completed Live Attestation{" "}
          {completedLiveAttestationList?.data?.searchTotal != null && (
            <Tag>{completedLiveAttestationList.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <CompletedLiveAttestationPage />,
    },
    {
      key: "video",
      label: (
        <span>
          Video Attestation{" "}
          {videoAttestationList?.data?.searchTotal != null && (
            <Tag>{videoAttestationList.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <VideoAttestationPage />,
    },
  ];

  const fetchPendingAttestations = async (params?: any) => {
    await dispatch(getPendingAttestationsThunk(params));
  };

  const fetchRequestedLiveAttestations = async (params?: any) => {
    await dispatch(getRequestedLiveAttestationsThunk(params));
  };

  const fetchCompletedLiveAttestations = async (params?: any) => {
    await dispatch(getCompletedLiveAttestationsThunk(params));
  };

  const fetchVideoAttestations = async (params?: any) => {
    await dispatch(getVideoAttestationsThunk(params));
  };

  const onTabChange = (key: string) => {
    if (key === "live-requested") {
      fetchRequestedLiveAttestations(initialParams);
    } else if (key === "live-completed") {
      fetchCompletedLiveAttestations(initialParams);
    } else if (key === "video") {
      fetchVideoAttestations(initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);
    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };
    fetchPendingAttestations(params);
    fetchRequestedLiveAttestations(params);
    fetchCompletedLiveAttestations(params);
    fetchVideoAttestations(params);
  });

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Attestation" />
      <Tabs
        defaultActiveKey={activeTab ?? "live"}
        items={items}
        onChange={(key) => onTabChange(key)}
        destroyInactiveTabPane
      />
    </>
  );
};

export default AttestationListingPage;
