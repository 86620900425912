import { Button, Input, Select, Space, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { DownloadOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-components";

import {
  applicationStateReset,
  setAccountStatementSearchValue,
} from "../../features/application/applicationSlice";
import { numberWithCommas } from "../../helpers/number";
import {
  getAccountStatementThunk,
  getEnglishAccountStatementbyAgreementIdThunk,
  getMalayAccountStatementbyAgreementIdThunk,
} from "../../services/collectionService/collectionThunk";
import { AppDispatch, RootState } from "../../store/store";
import { saveBlobAsPDF } from "../../utils/file";
import AccountEnquiryModal from "../AccountEnquiry/AccountEnquiryModal";

import type { TablePaginationConfig } from "antd/es/table";
import type { ColumnsType } from "antd/es/table";

const { Search } = Input;

const AccountStatementPage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { accountStatementSearchValue } = useSelector(
    (state: RootState) => state.application,
  );
  const { accountStatementList } = useSelector(
    (state: RootState) => state.collection,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 15,
      },
      sortOrder: "desc",
      sortField: "createdAt",
    }),
    [],
  );

  const [loadingRows, setLoadingRows] = useState<number[]>([]);
  const [searchType, setSearchType] = useState("AGREEMENT_NO");
  const [searchValue, setSearchValue] = useState(
    accountStatementSearchValue?.value ?? "",
  );
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: accountStatementList?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const handleDownloadAccountStatementClick = (
    lang: "en" | "bm",
    record: any,
  ) => {
    setLoadingRows((prevLoadingRows) => [...prevLoadingRows, record.id]);
    if (lang === "en") {
      dispatch(
        getEnglishAccountStatementbyAgreementIdThunk({
          agreementId: record?.agreementId,
        }),
      )
        .unwrap()
        .then((response) => {
          const filename = `Statement_of_Account_${record?.agreement?.agreementNo}_EN`;
          saveBlobAsPDF(response, filename);
        })
        .finally(() =>
          setLoadingRows((prevLoadingRows) =>
            prevLoadingRows.filter((id) => id !== record.id),
          ),
        );
    }

    if (lang === "bm") {
      dispatch(
        getMalayAccountStatementbyAgreementIdThunk({
          agreementId: record?.agreementId,
        }),
      )
        .unwrap()
        .then((response) => {
          const filename = `Statement_of_Account_${record?.agreement?.agreementNo}_BM`;
          saveBlobAsPDF(response, filename);
        })
        .finally(() =>
          setLoadingRows((prevLoadingRows) =>
            prevLoadingRows.filter((id) => id !== record.id),
          ),
        );
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Application No.",
      render: (text, record) =>
        record?.agreement?.application?.applicationNo ?? "-",
    },
    {
      title: "Agreement No",
      render: (_, record) => (
        <AccountEnquiryModal
          key={record.id}
          agreementNo={record.agreement?.agreementNo}
        />
      ),
    },
    {
      title: "Applicant Name",
      render: (text, record) =>
        record?.agreement?.application?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "applicantNric",
      key: "applicantNric",
    },
    {
      title: "Applied Loan Amount",
      render: (text, record) =>
        record?.agreement?.application?.financeAmount != null
          ? `RM ${numberWithCommas(
              record?.agreement?.application?.financeAmount,
            )}`
          : "-",
    },
    {
      title: "Statement",
      align: "center",
      render: (text, record) =>
        record != null ? (
          <Space>
            <Button
              icon={<DownloadOutlined />}
              loading={loadingRows.includes(record.id)}
              onClick={() => handleDownloadAccountStatementClick("en", record)}
            >
              English
            </Button>
            <Button
              icon={<DownloadOutlined />}
              loading={loadingRows.includes(record.id)}
              onClick={() => handleDownloadAccountStatementClick("bm", record)}
            >
              Melayu
            </Button>
          </Space>
        ) : (
          ""
        ),
    },
  ];

  const fetchAccountStatement = (requestData?: any) => {
    dispatch(getAccountStatementThunk(requestData));
  };

  const resetApplicationState = async () => {
    await dispatch(applicationStateReset());
  };

  const saveAccountStatementValue = async (payload: any) => {
    await dispatch(setAccountStatementSearchValue(payload));
  };

  const onSearch = async (value: string) => {
    if (value) {
      const payload = { searchType, value };
      setSearchValue(value);
      fetchAccountStatement({ params: initialParams, payload });
      saveAccountStatementValue(payload);
    }
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchAccountStatement({
      params: { ...initialParams, pagination },
      payload: { searchType, value: searchValue },
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  return (
    <>
      <Helmet>
        <title>Account Statement - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Account Statement" />
      <Input.Group compact>
        <Select
          defaultValue={searchType}
          style={{ width: 200 }}
          onChange={setSearchType}
          options={[
            { value: "AGREEMENT_NO", label: "Agreement No" },
            { value: "CUSTOMER_NRIC", label: "Applicant NRIC" },
            { value: "CUSTOMER_NAME", label: "Applicant Name" },
            { value: "PHONE_NO", label: "Phone No" },
            { value: "EMAIL", label: "Email" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 220, marginBottom: 16 }}
          defaultValue={searchValue}
        />
      </Input.Group>

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={accountStatementList?.data?.data}
        loading={accountStatementList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AccountStatementPage;
