import { createSlice } from "@reduxjs/toolkit";

import { getTrustSignersThunk } from "../../services/trustSignerService/trustSignerThunk";
import {Agreement} from "../agreement/agreementSlice";

export type TrustSigner = {
  id: string;
  adminId: string;
  userId: string;
  name: string;
  role: string;
  priority: number;
  status: number;
  createdAt?: string;
  updatedAt?: string;
};

export type TrustSignerState = {
  trustSigners: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number,
      currentPage: number,
      pageSize: number,
      data: TrustSigner[]
    },
  };
};

const initialState: TrustSignerState = {
  trustSigners: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: []
    },
  },
};

export const trustSignerSlice = createSlice({
  name: "trustSigner",
  initialState,
  reducers: {
    trustSignerStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrustSignersThunk.pending, (state) => {
        state.trustSigners.isLoading = true;
      })
      .addCase(getTrustSignersThunk.fulfilled, (state, action) => {
        state.trustSigners.isLoading = false;
        state.trustSigners.data = action.payload;
      })
      .addCase(getTrustSignersThunk.rejected, (state, action) => {
        state.trustSigners.isLoading = false;
        state.trustSigners.isError = true;
        state.trustSigners.errorMessage = action.payload;
      });
  },
});

export const { trustSignerStateReset } = trustSignerSlice.actions;

export default trustSignerSlice.reducer;
