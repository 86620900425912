import { Button, Space, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import { getWiseAiResultListingThunk } from "../../../services/ekycService/ekycThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDateAndTime } from "../../../utils/datetime.util";
import { EkycRawDataModal } from "./EkycRawDataModal";
import { UserRawDataModal } from "./UserRawDataModel";

import type { ColumnsType } from "antd/es/table";
import type { TableProps } from "antd/es/table";
import type { EkycDetailsType } from "../../../features/ekyc/ekycSlice";

const EkycReportPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [selectedUserId, setSetlectedUserId] = useState(null);
  const [ekycRawDataModalVisible, setEkycRawDataModalVisible] = useState(false);
  const [userRawDataModalVisible, setUserRawDataModalVisible] = useState(false);

  const { ekycReportListingState } = useSelector(
    (state: RootState) => state.ekyc,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: ekycReportListingState.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        paginationState.current > 1
          ? (paginationState.current - 1) * paginationState.pageSize + index + 1
          : index + 1,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index: number) => displayDateAndTime(text),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "eKYC Success",
      dataIndex: "ekycSuccess",
      key: "ekycSuccess",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Face Confidence",
      dataIndex: "faceConfidence",
      key: "faceConfidence",
    },
    {
      title: "Face Match",
      dataIndex: "faceIsMatch",
      key: "faceIsMatch",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Front Ic Number",
      dataIndex: "frontIcNumber",
      key: "frontIcNumber",
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => {
              setSelectedSessionId(record.sessionId);
              setEkycRawDataModalVisible(true);
            }}
          >
            View Raw Data
          </Button>
          <Button
            onClick={() => {
              setSetlectedUserId(record.userId);
              setUserRawDataModalVisible(true);
            }}
          >
            View User Details
          </Button>
        </Space>
      ),
    },
  ];

  const fetchEkycReport = async (params?: any) => {
    await dispatch(getWiseAiResultListingThunk({ params }));
  };

  const handleTableChange: TableProps<EkycDetailsType>["onChange"] = async (
    pagination,
    filters,
  ) => {
    const param = {
      ...initialParams,
      ...filterState,
      pagination,
    };

    fetchEkycReport(param);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(async () => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    await fetchEkycReport(params);
  });

  return (
    <>
      <Helmet>
        <title>eKYC Report - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="eKYC Report" />
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={ekycReportListingState?.data?.data ?? []}
        loading={ekycReportListingState.isLoading}
        onChange={handleTableChange}
      />

      {ekycRawDataModalVisible && (
        <EkycRawDataModal
          sessionId={selectedSessionId}
          isModalOpen={ekycRawDataModalVisible}
          onCloseModal={() => setEkycRawDataModalVisible(false)}
        />
      )}
      {userRawDataModalVisible && (
        <UserRawDataModal
          userId={selectedUserId}
          isModalOpen={userRawDataModalVisible}
          onCloseModal={() => setUserRawDataModalVisible(false)}
        />
      )}
    </>
  );
};

export default EkycReportPage;
