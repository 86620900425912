export function numberWithCommas(
  value: number | undefined | null | string,
  fractionDigits?: number,
) {
  if (value === undefined || value === null) return value;
  if (typeof value === "string") value = parseFloat(value);
  value = roundTo(value, 2);
  return value.toString().replace(/\B(?=(\d{3})+(?!\d)(?=\.\d*))/g, ",");
}

export const onShowDsrInPercentage = (dsr: number | null) => {
  return dsr ? `${(dsr * 100).toFixed(2)} %` : `0 %`;
};

export const toNumber = (value: any) => {
  try {
    if (value === null || value === undefined) {
      return 0;
    }

    return Number(value);
  } catch (err) {
    return 0;
  }
};

export function roundTo(value: number | null, digits: number) {
  if (value === null) {
    return 0;
  }
  const multiplicator = 10 ** digits;
  value = parseFloat((value * multiplicator).toFixed(11));
  const test = Math.round(value) / multiplicator;
  return test.toFixed(digits);
}
