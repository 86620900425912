import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

export const getUnmatchedList = async (requestData: any) => {
  const url = `/redCash/api/v1.0/unmatched/list`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData,
  });
  return res.data;
};

export const getUnmatchedRefundList = async (requestData: any) => {
  const url = `/redCash/api/v1.0/unmatched/refund-list`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData,
  });
  return res.data;
};

export const getUnmatchedRefundFinanceList = async (requestData: any) => {
  const url = `/redCash/api/v1.0/unmatched/refund-finance/list`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData,
  });
  return res.data;
};

export const getUnmatchedRefundById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/unmatched/refund/id/${id}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData,
  });
  return res.data;
};

export const createUnmatchedRefund = async (requestData: any) => {
  const url = `/redCash/api/v1.0/unmatched/refund`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const updateUnmatchedRefundDetails = async (requestData: any) => {
  const { payload, id } = requestData;
  const url = `/redCash/api/v1.0/unmatched/refund/update/id/${id}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

export const updateUnmatchedRefundStatus = async (requestData: any) => {
  const { id, status, payload } = requestData;
  const url = `/redCash/api/v1.0/unmatched/refund/update/id/${id}/status/${status}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

export const updateUnmatchedRefundFinanceStatus = async (requestData: any) => {
  const { id, status, postDate, payload } = requestData;
  const url = `/redCash/api/v1.0/unmatched/refund-finance/update/id/${id}/status/${status}/postDate/${postDate}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

export const downloadUnmatchedRefundPaymentFile = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/unmatched/refund/generatePaymentFile/id/${id}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    responseType: "blob",
  });
  return res.data;
};
