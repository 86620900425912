import { Button, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from "../../../components/LoadingSpinner";
import { listingSelectBatchNoStateReset } from "../../../features/noticesGenerationConfig/noticesGenerationConfigSlice";
import { getBatchListThunk } from "../../../services/noticesGenerationConfigService/noticesGenerationConfigThunk";
import { AppDispatch, RootState } from "../../../store/store";

import type { ColumnsType } from "antd/es/table";

interface SelectBatchProps {
  letterNumber: string;
  onOk: (selectedData: any, selectedBatchNo: any) => void;
}

const SelectBatch = ({ letterNumber, onOk }: SelectBatchProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const { listingSelectBatchNoState } = useSelector(
    (state: RootState) => state.noticesConfig,
  );

  const [visible, setVisible] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (text: string, record: any, index: number) => index + 1,
    },
    {
      title: "Batch No",
      dataIndex: "batchNo",
      key: "batchNo",
    },
    {
      title: "Action",
      render: (_, record) => (
        <Button onClick={() => onOk(record.id, record.batchNo)}>Select</Button>
      ),
    },
  ];

  const onModalClose = () => {
    setVisible(false);
    dispatch(listingSelectBatchNoStateReset());
  };

  const getBatchList = (num: string) => {
    dispatch(getBatchListThunk({ letterNumber: num }));
  };

  const initialCallbackState = { getBatchList };
  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    if (visible) {
      callbackState.getBatchList(letterNumber);
    }
  }, [callbackState, letterNumber, visible]);

  return (
    <>
      <Button onClick={() => setVisible(true)}>Select Batch</Button>
      <Modal
        open={visible}
        title="Please Select Batch:"
        onCancel={onModalClose}
        footer={null}
        destroyOnClose
        centered
      >
        {listingSelectBatchNoState.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table
            columns={columns}
            dataSource={listingSelectBatchNoState?.data ?? []}
            pagination={{ pageSize: 10 }}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  onOk(record.id, record.batchNo);
                  onModalClose();
                },
              };
            }}
            rowClassName={(record) =>
              record.selected ? "table-row-selected" : ""
            }
          />
        )}
      </Modal>
    </>
  );
};

export default SelectBatch;
