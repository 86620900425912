import "./index.less";

import type { DatePickerProps } from "antd";
import { Button, ConfigProvider, DatePicker, Input, message, Modal, Select, Space, Table, Tag } from "antd";
import startCase from "lodash.startcase";
import moment from "moment";
import React, { Key, useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createBreakpoint, useLifecycles } from "react-use";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-components";
import { disbursementAgreementsStateReset } from "../../../../features/disbursement/disbursementSlice";
import { downloadLink } from "../../../../helpers/downloadLink";
import { numberWithCommas } from "../../../../helpers/number";
import { AppDispatch, RootState } from "../../../../store/store";
import { onCalculateNetDisbursementAmount } from "../../../../utils/calculation.util";
import { displayDate } from "../../../../utils/datetime.util";
import UpdateDisbursementModal from "./UpdateDisbursementModal";

import type { ColumnsType, TablePaginationConfig, TableProps } from "antd/es/table";
import { SmeAgreement } from "../../../../features/smeAgreement/smeAgreementSlice";
import { SME_AGREEMENT_STATUS, SME_DISBURSEMENT_BATCH_STATUS } from "../../../../enum/smeApplicationStatus";
import {
  getDisbursementListingSummaryPdfThunk,
  getSmeDisbursementBatchAgreementsThunk,
  getSmeDisbursementBatchBankFileThunk,
  getSmeDisbursementBatchByIdThunk,
  getSmeDisbursementBatchListingThunk,
  postSmeDisbursementBatchAgreementsThunk,
  unpostSmeDisbursementBatchAgreementByIdThunk,
} from "../../../../services/smeDisbursementService/smeDisbursementThunk";
import { SorterResult } from "antd/es/table/interface";

const { Option } = Select;
const { Search } = Input;

const useBreakpoint = createBreakpoint();

const SmeBatchDetailsPage = () => {
  const [tableLoading, setTableLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const { smeDisbursementBatchAgreements, smeDisbursementBatchDetails } = useSelector(
    (state: RootState) => state.smeDisbursement,
  );

  const [selectedInProcessDisbursement, setSelectedInProcessDisbursement] =
    useState<SmeAgreement[]>([]);
  const [
    selectedInProcessDisbursementKey,
    setSelectedInProcessDisbursementKey,
  ] = useState<Key[]>([]);

  const { batchId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [{ confirm }, contextHolder] = Modal.useModal();
  const [postDate, setPostDate] = useState<string>();
  const [downloading, setDownloading] = useState(false);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchType, setSearchType] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState('');
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const tablePagination = {
    total: smeDisbursementBatchAgreements?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const batchDetailsColumns: ColumnsType<any> = [
    {
      title: "Signed Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      sorter: (a, b) => a.signDate - b.signDate,
      sortOrder: sortedInfo.columnKey === "signDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/sme/agreement/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) =>
        record?.smeApplication?.smeIndividualApplication?.name ??
        record?.smeApplication?.smeSolePropApplication?.name ??
        record?.smeApplication?.smePartnershipApplication?.name ??
        record?.smeApplication?.smeCompanyApplication?.companyName ??
        "-",
    },
    {
      title: "Approved Loan Amount",
      dataIndex: "systemLoanAmountSuggested",
      key: "systemLoanAmountSuggested",
      render: (_, record) =>
        record.smeApplication?.financeAmount != null
          ? `RM ${numberWithCommas(+record.smeApplication?.financeAmount ?? 0)}`
          : "-",
    },
    {
      title: "Net Disbursement Amount",
      dataIndex: "disbursementAmount",
      key: "disbursementAmount",
      render: (text, record) =>
        `RM ${numberWithCommas(
          onCalculateNetDisbursementAmount(
            +record.smeApplication?.financeAmount ?? 0,
            +record.smeApplication?.processingFee ?? 0,
            +record.smeApplication?.stampingDutyPercentage ?? 0,
            +record.smeApplication?.processingFeeToDiscount ?? 0,
            0,
            record.smeApplication?.status,
          ),
        )}`,
    },
  ];

  const completedTableColumns: ColumnsType<SmeAgreement> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Posted Date",
      dataIndex: "disbursementPostedDate",
      key: "postedDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record.status === SME_AGREEMENT_STATUS.PENDING_DISBURSEMENT ? (
          <UpdateDisbursementModal
            key={record.id}
            batchId={batchId}
            agreement={record}
            callback={updateDisbursementCallback}
          />
        ) : (
          <Button
            ghost
            danger
            key={record.id}
            onClick={() => showUnpostConfirmation(record.id)}
          >
            Unpost
          </Button>
        ),
    },
  ];

  const columns = [
    ...batchDetailsColumns,
    ...(smeDisbursementBatchDetails?.data?.status === SME_DISBURSEMENT_BATCH_STATUS.COMPLETED || smeDisbursementBatchDetails?.data?.status === SME_DISBURSEMENT_BATCH_STATUS.INCOMPLETE
      ? completedTableColumns
      : []),
  ];

  const unpostAgreeementByAgreementId = async (agreementId: string) => {
    setTableLoading(true);
    await dispatch(
      unpostSmeDisbursementBatchAgreementByIdThunk({
        agreementId: agreementId,
        id: batchId,
      }),
    )
      .unwrap()
      .then(async (res) => {
        fetchDisbursementBatchDetails();
        fetchDisbursementAgreements();
        message.success("Unposted Successfully");
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const inProcessDisbursementSelection = {
    selectedRowKeys: selectedInProcessDisbursementKey,
    onChange: (selectedRowKeys: React.Key[], selectedRows: SmeAgreement[]) => {
      setSelectedInProcessDisbursementKey(selectedRowKeys);
      setSelectedInProcessDisbursement(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
      );
    },
  };

  const fetchDisbursementAgreements = async (params?: any) => {
    await dispatch(
      getSmeDisbursementBatchAgreementsThunk({
        ...initialParams,
        params,
        id: batchId,
      }),
    );
  };

  const fetchDisbursementBatchListing = async (params?: any) => {
    await dispatch(getSmeDisbursementBatchListingThunk({ params }));
  };

  const fetchDisbursementBatchDetails = async (params?: any) => {
    await dispatch(getSmeDisbursementBatchByIdThunk({
      ...initialParams,
      ...params,
      id: batchId,
    }));
  };

  const onSearch = (value: string) => {
    fetchDisbursementAgreements({
      ...initialParams,
      search: { [searchType]: value },
    });

    setSearchValue(value);
  };

  const handleTableChange: TableProps<SmeAgreement>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    fetchDisbursementAgreements({ pagination });
    setSortedInfo(sorter as SorterResult<SmeAgreement>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const updateDisbursementCallback = async () => {
    await fetchDisbursementBatchDetails(batchId);
    await fetchDisbursementAgreements();
  };

  const showUnpostConfirmation = useCallback(
    (agreementId: string) => {
      confirm({
        title: "Warning",
        icon: <ExclamationCircleFilled />,
        content:
          "Are you sure unpost this application? Please check the account details before unpost.",
        okText: "Continue",
        onOk: async () => {
          await unpostAgreeementByAgreementId(agreementId);
        },
        onCancel() {
        },
      });
    },
    [confirm],
  );

  const onPostDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setPostDate(dateString);
  };

  const onReset = () => {
    setSelectedInProcessDisbursement([]);
    setSelectedInProcessDisbursementKey([]);
    setPostDate("");
  };

  const onDownloadBankFiles = async () => {
    setDownloading(true);
    if (!batchId) {
      return message.error("Invalid batch id");
    }

    await dispatch(getSmeDisbursementBatchBankFileThunk(batchId))
      .unwrap()
      .then(downloadLink)
      .finally(() => {
        setDownloading(false);
      });

    await fetchDisbursementAgreements();

    await dispatch(getDisbursementListingSummaryPdfThunk(batchId)).unwrap().then(downloadLink).finally(() => setDownloading(false));
  };

  const onPostAgreements = async () => {
    setTableLoading(true);
    await dispatch(
      postSmeDisbursementBatchAgreementsThunk({
        smeAgreementIds: selectedInProcessDisbursementKey,
        postedDate: moment(postDate).toISOString(),
        id: batchId,
      }),
    )
      .unwrap()
      .then(async (res) => {
        fetchDisbursementBatchDetails();
        fetchDisbursementAgreements();
        fetchDisbursementBatchListing();
        message.success("Posted Successfully");
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  useLifecycles(
    () => {
      fetchDisbursementAgreements();
      fetchDisbursementBatchDetails(batchId);
    },
    () => {
      dispatch(disbursementAgreementsStateReset());
    },
  );

  return (
    <>
      {contextHolder}
      <Helmet>
        <title>Disbursement Details - redCASH CEP</title>
      </Helmet>

      <PageHeader
        className="px-0 mb-2"
        title="Disbursement Details"
        onBack={() => navigate(-1)}
        subTitle={
          <Space size="middle">
            <span>{`Batch No. ${smeDisbursementBatchDetails?.data?.batchNo}`}</span>
            {smeDisbursementBatchDetails?.data?.batchNo && (
              <>
                <Tag
                  color={
                    smeDisbursementBatchDetails?.data?.status === SME_DISBURSEMENT_BATCH_STATUS.INCOMPLETE
                      ? "error"
                      : smeDisbursementBatchDetails?.data?.status === SME_DISBURSEMENT_BATCH_STATUS.COMPLETED
                        ? "success"
                        : "default"
                  }
                >
                  {startCase(
                    smeDisbursementBatchDetails?.data?.status === SME_DISBURSEMENT_BATCH_STATUS.INCOMPLETE
                      ? "INCOMPLETE"
                      : smeDisbursementBatchDetails?.data?.status,
                  )}
                </Tag>
              </>
            )}
          </Space>
        }
      />

      <div className="flex justify-between mb-4">
        <Input.Group compact>
          <Select
            defaultValue="agreementNo"
            style={{ width: 160 }}
            options={[
              { value: "agreementNo", label: "Agreement No." },
              { value: "applicationNo", label: "Application No" },
              { value: "name", label: "Name" },
            ]}
            onChange={(value) => setSearchType(value)}
          >
          </Select>
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            style={{ width: 240 }}
          />
        </Input.Group>
        {smeDisbursementBatchDetails?.data?.status !== SME_DISBURSEMENT_BATCH_STATUS.COMPLETED && (
          <Button loading={downloading} onClick={() => onDownloadBankFiles()}>
            Download
          </Button>
        )}
      </div>

      <ConfigProvider theme={{ token: { colorPrimary: "#8F8F8F" } }}>
        <Table
          rowKey="id"
          className="min-w-fit"
          columns={columns}
          pagination={tablePagination}
          dataSource={smeDisbursementBatchAgreements?.data?.data}
          loading={smeDisbursementBatchAgreements?.isLoading || tableLoading}
          onChange={handleTableChange}
          rowSelection={
            smeDisbursementBatchDetails?.data?.status === SME_DISBURSEMENT_BATCH_STATUS.IN_PROCESS
              ? inProcessDisbursementSelection
              : undefined
          }
          rowClassName={(record, index) =>
            smeDisbursementBatchDetails?.data?.status === SME_DISBURSEMENT_BATCH_STATUS.INCOMPLETE &&
            record?.status === SME_AGREEMENT_STATUS.PENDING_DISBURSEMENT
              ? "bg-red-200"
              : ""
          }
        />
      </ConfigProvider>
      {smeDisbursementBatchDetails?.data?.status === SME_DISBURSEMENT_BATCH_STATUS.IN_PROCESS &&
        selectedInProcessDisbursement.length > 0 && (
          <div
            className={`
          ${isMobile ? "w-full" : "w-[calc(100%-340px)]"}
          bg-white 
            shadow-multi h-16  px-4 
            fixed bottom-0 right-0 
            flex items-center
            justify-between`}
          >
            <Space>
              <span>Post Date:</span>
              <DatePicker onChange={onPostDateChange} allowClear={false} />
            </Space>

            <Space>
              <h4 className="text-lg my-0 mr-4">
                {selectedInProcessDisbursement.length} Selected
              </h4>
              <ConfigProvider theme={{ token: { colorPrimary: "#e40308" } }}>
                <Space>
                  <Button danger onClick={onReset}>
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    disabled={!postDate}
                    onClick={onPostAgreements}
                  >
                    Post Date
                  </Button>
                </Space>
              </ConfigProvider>
            </Space>
          </div>
        )}
    </>
  );
};

export default SmeBatchDetailsPage;
