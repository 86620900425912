import { Button, Form, Input, message, Modal, Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Admin } from "../../../features/admin/adminSlice";
import {
  createAdminThunk,
  updateAdminThunk,
} from "../../../services/adminService/adminThunk";
import { AppDispatch } from "../../../store/store";

const AdminFormModal: React.FC<{
  admin?: Admin;
  callback?: () => void;
}> = ({ admin, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        if (admin != null) {
          dispatch(
            updateAdminThunk({
              adminId: admin.id,
              payload: values,
            }),
          )
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Update Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        } else {
          dispatch(
            createAdminThunk({
              ...values,
              companyId: process.env.REACT_APP_COMPANY_ID,
            }),
          )
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Create Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        }
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
      });
  };

  return (
    <>
      <Button onClick={showModal}>
        {admin != null ? "Update" : "Create Admin"}
      </Button>
      <Modal
        title={admin != null ? "Update Admin" : "Create Admin"}
        okText={admin != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={400}
        destroyOnClose
      >
        <Form
          form={form}
          initialValues={admin}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Username is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input.Password placeholder="Enter new password" />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Role is required" }]}
          >
            <Select
              placeholder="Please Select"
              allowClear
              options={[
                { value: "SUPER_ADMIN", label: "Super Admin" },
                { value: "APPROVER", label: "Approver" },
                { value: "VERIFIER", label: "Verifier" },
                { value: "FINANCE", label: "Finance" },
                { value: "CUSTOMER_SERVICE", label: "Customer Service" },
                {
                  value: "WRITE_OFF_BATCH_TWO_APPROVER",
                  label: "Write Off Batch 2 Approver",
                },
                {
                  value: "VERIFIER_2",
                  label: "SME Verifier 2",
                },
                {
                  value: "APPROVER_2",
                  label: "SME Approver 2",
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AdminFormModal;
