import "./index.less";

import { PageHeader } from "@ant-design/pro-components";
import {
  Button,
  Card,
  Collapse,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import {
  getLoanInsuranceByIdThunk,
  updateLoanInsuranceByIdThunk,
} from "../../../services/masterService/masterThunk";
import LoanInsurancePlan from "./loanInsurancePlan";
import LoanInsuranceCoverage from "./loanInsuranceCoverage";
import LoanInsuranceCoverageModal from "./loanInsuranceCoverage/LoanInsuranceCoverageModal";
import { useNavigate } from "react-router-dom";

const { Panel } = Collapse;

const LoanInsuranceDetailsPage = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [updating, setIsUpdating] = useState(false);

  const { loanInsuranceDetails } = useSelector(
    (state: RootState) => state.loanInsurance,
  );

  const fetchLoanInsuranceDetails = async () => {
    await dispatch(getLoanInsuranceByIdThunk({ id }))
      .unwrap()
      .then((res) => {
        form.setFieldsValue({
          code: res.code,
          name: res.name,
          mandatory: res.mandatory,
          isActive: res.isActive,
        });
      });
  };

  useEffect(() => {
    fetchLoanInsuranceDetails();
  }, [id]);

  const onFinish = async () => {
    setIsUpdating(true);
    await form.validateFields().then(async (values) => {
      const payload = {
        ...values,
        id: loanInsuranceDetails.data?.id,
        commision: Number(loanInsuranceDetails.data?.commision),
        serviceTax: Number(loanInsuranceDetails.data?.serviceTax),
      };

      await dispatch(updateLoanInsuranceByIdThunk(payload))
        .unwrap()
        .then(() => message.success("Update Successfully"))
        .catch(() => message.error(`Error save loan insurance product`))
        .finally(() => {
          fetchLoanInsuranceDetails();
          navigate("/loan-insurance-config");
          setIsUpdating(false);
        });
    });
  };

  return (
    <>
      <Helmet>
        <title>Loan Insurance Config Details- redCASH CEP</title>
      </Helmet>
      <PageHeader className="p-0 mb-4" title="Loan Insurance Config Details" />
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          code: loanInsuranceDetails.data?.code,
          name: loanInsuranceDetails.data?.name,
          mandatory: loanInsuranceDetails.data?.mandatory,
          isActive: loanInsuranceDetails.data?.isActive,
        }}
        labelCol={{ flex: "180px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
      >
        <Collapse
          defaultActiveKey={["loanInsurance"]}
          ghost
          expandIconPosition="end"
        >
          <Panel
            key="loanInsurance"
            className="application-details-section-container"
            header={
              <h3 className="application-details-section-title">
                Create New Option
              </h3>
            }
          >
            <Card className="mb-4 w-full">
              <Form.Item
                name="code"
                label={<span>Option Code</span>}
                rules={[
                  {
                    required: true,
                    message: "Option Code is required",
                  },
                ]}
              >
                <Input className={"readOnlyInput"} />
              </Form.Item>

              <Form.Item
                name="name"
                label={<span>Option Name</span>}
                rules={[
                  {
                    required: true,
                    message: "Option Name is required",
                  },
                ]}
              >
                <Input className={"readOnlyInput"} />
              </Form.Item>

              <Form.Item
                name="mandatory"
                label={<span>Mandatory</span>}
                rules={[
                  {
                    required: true,
                    message: "Mandatory is required",
                  },
                ]}
              >
                <Select
                  placeholder="Please select"
                  style={{ width: "100%" }}
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                name="isActive"
                label={<span>Status</span>}
                rules={[
                  {
                    required: true,
                    message: "Status is required",
                  },
                ]}
              >
                <Select
                  placeholder="Please select"
                  style={{ width: "100%" }}
                  options={[
                    { value: true, label: "Active" },
                    { value: false, label: "Inactive" },
                  ]}
                />
              </Form.Item>
            </Card>
          </Panel>

          <Panel
            key="loanInsuranceCoverage"
            className="application-details-section-container"
            header={
              <h3 className="application-details-section-title">Coverage</h3>
            }
          >
            <LoanInsuranceCoverage />
          </Panel>

          <Panel
            key="loanInsurancePlan"
            className="application-details-section-container"
            header={
              <h3 className="application-details-section-title">Tenure/Plan</h3>
            }
          >
            <LoanInsurancePlan />
          </Panel>
        </Collapse>
      </Form>

      <Row justify="end" className="mb-2" align="bottom">
        <Space>
          <Button onClick={onFinish} loading={updating}>
            Update
          </Button>
        </Space>
      </Row>
    </>
  );
};

export default LoanInsuranceDetailsPage;
