export enum RescheduleTypeEnum {
  OPEN = "OPEN",
  PENDING = "PENDING",
  REFERBACK = "REFERBACK",
  VOID = "VOID",
  APPROVED = "APPROVED",
}

export enum RnRType {
  Reschedule = "Reschedule",
  Restructure = "Restructure",
}
