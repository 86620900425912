import { Button, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLifecycles } from "react-use";
import jwt_decode from "jwt-decode";

import { getFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../store/store";
import UploadedDocument from "../UploadedDocument";
import { Role } from "../../enum/roles";

export type FileListModalProps = {
  entityId: string;
};

const FileListModal: React.FC<FileListModalProps> = ({ entityId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(false);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const fetchFileList = () => {
    setLoading(true);
    dispatch(getFilesByEntityIdThunk({ entityId }))
      .unwrap()
      .then((res) => setFileList(res))
      .finally(() => {
        setLoading(false);
      });
  };

  useLifecycles(() => entityId && fetchFileList());

  return (
    <>
      <Button
        onClick={showModal}
        disabled={currentRole !== Role.SUPER_ADMIN}
      >
        Uploaded File
      </Button>
      <Modal
        open={open}
        title="Uploaded File"
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <UploadedDocument
            key={entityId}
            defaultFileList={fileList}
            readOnly
          />
        )}
      </Modal>
    </>
  );
};
export default FileListModal;
