import { Helmet } from "react-helmet-async";

import { PageHeader } from "@ant-design/pro-components";

import RescheduleTable from "./components/rescheduleRequestTable";

const RescheduleListingPage = () => {
  return (
    <>
      <Helmet>
        <title>Reschedule Request - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Reschedule Request" />
      <RescheduleTable />
    </>
  );
};

export default RescheduleListingPage;
