import { AppDispatch, RootState } from "../../../../store/store";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Table } from "antd";
import { displayDateAndTime } from "../../../../utils/datetime.util";

type WriteOffRemarksHistoryTable = {
  dataSource: any;
};

const WriteOffRemarksTable: React.FC<WriteOffRemarksHistoryTable> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { dataSource } = props;

  const tablePagination = {
    total: dataSource.length,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDateAndTime(text) : "-"),

      width: "15%",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: "15%",
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      width: "15%",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: "70%",
    },
  ];

  return (
    <Table
      rowKey="id"
      className="min-w-fit"
      dataSource={dataSource
        .slice()
        .sort(
          (
            a: { createdAt: string | number | Date },
            b: { createdAt: string | number | Date },
          ) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)),
        )}
      columns={columns}
      bordered={true}
      pagination={tablePagination}
    />
  );
};

export default WriteOffRemarksTable;
