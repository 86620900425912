import { ekycSetupAxiosInstance } from "./../../axios/ekycSetupAxios";

const getWiseAiResultByUserId = async (requestData: any) => {
  const { userId } = requestData;
  const url = `/redCash/api/v1.0/wiseai/result/${userId}`;
  const res = await ekycSetupAxiosInstance.get(url);
  return res.data;
};

const getWiseAiRawDataBySessionId = async (requestData: any) => {
  const { sessionId } = requestData;
  const url = `/redCash/api/v1.0/wiseAi/result/sessionId/${sessionId}`;
  const res = await ekycSetupAxiosInstance.get(url);
  return res.data;
};

const getWiseAiResultListing = async (requestData: any) => {
  const params = {
    filters: requestData?.params?.filters,
    search: requestData?.params?.search,
    sortField: requestData?.params?.sortField,
    sortOrder: requestData?.params?.sortOrder,
    currentPage: requestData?.params?.pagination?.current,
    pageSize: requestData?.params?.pagination?.pageSize,
  };
  const url = `redCash/api/v1.0/wiseai/listing`;

  const res = await ekycSetupAxiosInstance.get(url, { params });
  return res.data;
};

const uploadeKYCFile = async (requestData: any) => {
  const url = `/redCash/api/v1.0/file/userEKYC`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await ekycSetupAxiosInstance.post(url, requestData, config);
  return res.data;
};

const getWiseAiResultByApplicationId = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/wiseai/result/applicationId/${applicationId}`;
  const res = await ekycSetupAxiosInstance.get(url);
  return res.data;
};

const getSmeEkycFileEntityId = async (username: string) => {
  const url = `/redCash/api/v1.0/wiseai/result/smeUsername/${username}`;
  const res = await ekycSetupAxiosInstance.get(url);
  return res.data;
};

const ekycService = {
  getWiseAiResultByUserId,
  getWiseAiResultListing,
  getWiseAiRawDataBySessionId,
  uploadeKYCFile,
  getWiseAiResultByApplicationId,
  getSmeEkycFileEntityId
};

export default ekycService;
