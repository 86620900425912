import { Button, Input, message, Select, Table } from "antd";
import dayjs from "dayjs";
import startCase from "lodash.startcase";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import LoadingSpinner from "../../../components/LoadingSpinner";
import { collectionStateReset } from "../../../features/collection/collectionSlice";
import { runingEarlySettlementStateReset } from "../../../features/earlySettlement/earlySettlementSlice";
import { numberWithCommas } from "../../../helpers/number";
import { useStateCallback } from "../../../hooks/useStateCallback";
import {
  getEstimateLatePaymentChargeThunk,
  getRunningEarlySettlementByAgreementNoThunk,
} from "../../../services/earlySettlementService/earlySettlementThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import EarlySettlementForm from "./EarlySettlementForm";

import type { ColumnsType } from "antd/es/table";
import { searchDisbursedAgreementThunk } from "../../../services/agreementService/agreementThunk";
import { getAgreementByEmailThunk } from "../../../services/aocService/aocThunk";
import SearchAgreementResult from "../../AccountEnquiry/SearchAgreementResult";
import AccountEnquiryModal from "../../AccountEnquiry/AccountEnquiryModal";
import { getTaxService } from "../../../services/taxService/taxService";

const { Search } = Input;

const RequestEarlySettlementPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const searchAgreementNo = queryParams.get("searchAgreementNo")?.trim();
  const [formInitialValues, setFormInitialValues] = useStateCallback<any>(null);
  const [searchType, setSearchType] = useState("AGREEMENT_NO");
  const [searchFiled, setSearchFiled] = useState("AGREEMENT NO");
  const [resultNotFound, setResultNotFound] = useState(false);
  const [searchResultVisible, setSearchResultVisible] = useState(false);
  const [agreementData, setAgreementData] = useState<any>([]);

  const { tax } = useSelector((state: RootState) => state.tax)

  const { runingEarlySettlement } = useSelector(
    (state: RootState) => state.earlySettlement,
  );

  const [loading, setLoading] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "Agreement Date",
      render: (_, record) =>
        record.accountDetails?.createdAt
          ? displayDate(record.accountDetails?.createdAt)
          : "-",
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal
          key={record.id}
          agreementNo={record.accountDetails?.agreementNo}
        />
      ),
    },
    {
      title: "Status",
      render: (_, record) =>
        record.accountDetails?.status ? record.accountDetails?.status : "-",
    },
    {
      title: "Applicant Name",
      render: (text, record) =>
        record?.accountDetails?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      render: (text, record) => record?.accountDetails?.applicantNric ?? "-",
    },
    {
      title: "MIA",
      render: (text, record) => record?.accountDetails?.mia ?? "-",
    },
    {
      title: "Maturity Date",
      render: (text, record) =>
        record?.accountDetails?.maturedDate
          ? displayDate(record?.accountDetails?.maturedDate)
          : "-",
    },
    // {
    //   title: "Approved Loan Amount",
    //   key: "systemLoanAmountSuggested",
    //   render: (text, record) =>
    //     `RM ${numberWithCommas(
    //       onCalculateNetDisbursementAmount(
    //         record.application?.financeAmount ?? 0,
    //         record.application?.productProcessingFee ?? 0,
    //         record.application?.productStampingDutyPercentage ?? 0,
    //         record.application?.productProcessingFeeToDiscount ?? 0,
    //       ),
    //     )}`,
    // },
    // {
    //   title: "Net Disbursement Amount",
    //   dataIndex: "disbursementAmount",
    //   key: "disbursementAmount",
    //   render: (text, record) => (record.disbursementAmount ?? "-"),
    // },
  ];

  const onSearch = async (value: string) => {
    if (value) {
      setLoading(true);

      await dispatch(
        searchDisbursedAgreementThunk({ [searchType]: value })
      )
        .then((result) => {
          setAgreementData(result.payload);
          if (result.payload && result.payload.length > 0) {
            setSearchResultVisible(true);
          } else {
            message.error("Result Not Found");
            setResultNotFound(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      message.error("Result Not Found");
      dispatch(collectionStateReset());
    }
  };

  const handleSearchResultSelect = async (agreementNo: any) => {
    setSearchResultVisible(false);
    await dispatch(
      getRunningEarlySettlementByAgreementNoThunk({
        agreementNo: agreementNo,
      }),
    )
      .unwrap()
      .then(() => {
        queryParams.set("searchAgreementNo", agreementNo);
        const newSearch = queryParams.toString();
        navigate({
          pathname: location.pathname,
          search: newSearch,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchResultCancel = () => {
    setSearchResultVisible(false);
  };

  const updateFormValues = async () => {
    if (runingEarlySettlement?.data?.earlySettlement) {
      setLoading(true);
      setFormInitialValues(null);
      await dispatch(
        getEstimateLatePaymentChargeThunk({
          agreementId:
            runingEarlySettlement?.data?.earlySettlement?.accountDetails
              ?.agreementId,
          settlementDate: runingEarlySettlement.data?.earlySettlement
            ?.earlySettlementDate
            ? dayjs(
                runingEarlySettlement.data?.earlySettlement
                  ?.earlySettlementDate,
              ).format("DD-MM-YYYY")
            : dayjs().format("DD-MM-YYYY"),
        }),
      )
        .unwrap()
        .then(async (res) => {
          const totalLoanOutstandingRounded =
            res?.totalLoanOutstanding?.amount ?? 0;
          const latePaymentChargeRounded = res?.latePaymentCharge?.amount ?? 0;

          const earlySettlementChargeWithTax: number = +(
            runingEarlySettlement?.data?.earlySettlement
              ?.earlySettlementChargeAfterTax ?? 0
          );

          const earlySettlementTaxAmount: number = +(
            runingEarlySettlement?.data?.earlySettlement
              ?.earlySettlementTaxAmount ?? 0
          );

          const earlySettlementCharge =
            earlySettlementChargeWithTax - earlySettlementTaxAmount;

          const miscChargeWithTax: number = +(
            runingEarlySettlement?.data?.earlySettlement?.miscChargeAfterTax ??
            0
          );

          const miscTaxAmount: number = +(
            runingEarlySettlement?.data?.earlySettlement?.miscTaxAmount ?? 0
          );

          const miscCharge = miscChargeWithTax - miscTaxAmount;

          const total: number =
            earlySettlementChargeWithTax +
            miscChargeWithTax +
            totalLoanOutstandingRounded;

          const initialValues = {
            ...runingEarlySettlement?.data?.earlySettlement,
            ...runingEarlySettlement?.data?.earlySettlement?.accountDetails,
            earlySettlementDate: runingEarlySettlement?.data?.earlySettlement
              ?.earlySettlementDate
              ? dayjs(
                  runingEarlySettlement?.data?.earlySettlement
                    ?.earlySettlementDate,
                )
              : dayjs(),

            adjustmentCharge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.adjustmentCharge,
            ),
            adminCharge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.adminCharge,
            ),
            bankCharge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.bankCharge,
            ),
            blacklistUpliftCharge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.blacklistUpliftCharge,
            ),
            principalOB: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.principalOB,
            ),
            reminderLetter1Charge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.reminderLetter1Charge,
            ),
            reminderLetter2Charge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.reminderLetter2Charge,
            ),
            reminderLetter3Charge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.reminderLetter3Charge,
            ),
            ecaCharge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.ecaCharge,
            ),
            fieldCharge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.fieldCharge,
            ),
            interestOB: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.interestOB,
            ),
            legalCharge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.legalCharge,
            ),
            lodCharge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.lodCharge,
            ),
            refundCharge: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.refundCharge,
            ),

            earlySettlementChargeDisplay: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB.earlySettlementCharge,
            ),
            earlySettlementCharge: numberWithCommas(earlySettlementCharge),

            agreementNo:
              runingEarlySettlement?.data?.earlySettlement?.accountDetails
                ?.agreementNo,
            latePaymentCharge: numberWithCommas(latePaymentChargeRounded),
            earlySettlementStatus:
              runingEarlySettlement?.data?.earlySettlement?.status,
            earlySettlementTaxPercentage: numberWithCommas(tax.data.tax),

            earlySettlementChargeWithTax: numberWithCommas(
              earlySettlementChargeWithTax,
            ),

            miscCharge: numberWithCommas(miscCharge),
            miscChargeDisplay: numberWithCommas(
              runingEarlySettlement?.data?.earlySettlement
                ?.earlySettlementChargeOB?.miscCharge,
            ),

            miscTaxPercentage: numberWithCommas(tax.data.tax),
            miscChargeWithTax: numberWithCommas(miscChargeWithTax),
            totalLoanOutstanding: numberWithCommas(totalLoanOutstandingRounded),
            total: numberWithCommas(total),
          };

          setFormInitialValues(initialValues, () => setLoading(false));
        });
    }
  };

  const initialCallbackState = { onSearch };
  const [callbackState] = useState(initialCallbackState);

  // useEffect(() => {
  //   if (searchAgreementNo) {
  //     callbackState.onSearch(searchAgreementNo);
  //   }
  // }, [callbackState, searchAgreementNo]);

  useEffect(() => {
    if (runingEarlySettlement?.data?.earlySettlement) {
      updateFormValues();
    }
  }, [runingEarlySettlement?.data?.earlySettlement]);

  useLifecycles(
    () => {
      dispatch(runingEarlySettlementStateReset());
      if (searchAgreementNo) {
        handleSearchResultSelect(searchAgreementNo);
      }
    },
    () => dispatch(runingEarlySettlementStateReset()),
  );

  useEffect(() => {
    dispatch(getTaxService)
  }, [])

  return (
    <>
      <Helmet>
        <title>Request Early Settlement - redCASH CEP</title>
      </Helmet>
      {/* <div className="max-w-6xl w-full m-auto"> */}
      <PageHeader className="px-0 mb-2" title="Request Early Settlement" />
      <Input.Group compact>
        <Select
          defaultValue={"AGREEMENT_NO"}
          style={{ width: 200 }}
          onChange={(value) => {
            setResultNotFound(false);
            setSearchType(value);
          }}
          options={[
            { value: "agreementNo", label: "Agreement No" },
            { value: "applicantNric", label: "Applicant NRIC" },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantPhoneNo", label: "Phone No" },
            { value: "applicantEmail", label: "Email" },
          ]}
        />
        <Search
          placeholder="Agreement No"
          onSearch={onSearch}
          style={{ width: 200 }}
          defaultValue={searchAgreementNo ?? ""}
        />
      </Input.Group>
      <br />
      {runingEarlySettlement?.data?.earlySettlement && (
        <>
          <Table
            rowKey="id"
            className="min-w-fit mt-4 mb-8"
            columns={columns}
            pagination={false}
            dataSource={
              runingEarlySettlement?.data?.earlySettlement
                ? [runingEarlySettlement.data?.earlySettlement]
                : []
            }
            loading={runingEarlySettlement?.isLoading}
          />

          {runingEarlySettlement?.isLoading || loading ? (
            <div className="py-40">
              <LoadingSpinner />
            </div>
          ) : (
            runingEarlySettlement?.data?.earlySettlement &&
            formInitialValues && (
              <EarlySettlementForm
                earlySettlement={runingEarlySettlement.data?.earlySettlement}
                formInitialValues={formInitialValues}
                callback={() => {
                  if (searchAgreementNo) {
                    callbackState.onSearch(searchAgreementNo);
                  }
                }}
                newRequest={
                  runingEarlySettlement.data?.earlySettlement?.status === "NEW"
                }
              />
            )
          )}
        </>
      )}
      {/* </div> */}

      {searchResultVisible && (
        <SearchAgreementResult
          visible={searchResultVisible}
          data={agreementData}
          onOk={handleSearchResultSelect}
          onCancel={handleSearchResultCancel}
        />
      )}
    </>
  );
};

export default RequestEarlySettlementPage;
