import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const getReportListing = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/getReportList/reportType/${requestData.params?.reportType}`,
    { params },
  );
  return result.data;
};

const downloadReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/getFile/${requestData.id}`,
    { responseType: "blob" },
  );
  return result.data;
};

const getManualPaymentTemplate = async (requestData: any) => {
  const result = await beSetupAxiosInstance.get(
    `/redCash/api/v1.0/payment/downloadTemplate`,
    { responseType: "blob" },
  );
  return result.data;
};

const generateMasterListingReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateMasterListingReport/v2/reportDate/${requestData.reportDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateCollectionReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/collectionReport/v2/startDate/${requestData.startDate}/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateKpktReport = async (requestData: any) => {
  const result = await beSetupAxiosInstance.get(
    `/redCash/admin/api/v1.0/report/kpkt/startDate/${requestData.startDate}/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateInterestChargesReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateInterestCharges/v2/startDate/${requestData.startDate}/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateMiscBillingReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateMiscChargeReport/v2/startDate/${requestData.startDate}/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateLoanMaturityReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateLoanMaturity/v2/startDate/${requestData.startDate}/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateOverDueReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateOverDue/v2/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateLoanCncReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateLoanCNC/v2/startDate/${requestData.startDate}/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateInterestCncReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateInterestCNC/v2/startDate/${requestData.startDate}/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateLateChargesReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateLateCharges/v2/startDate/${requestData.startDate}/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generatePartnerReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/partnerInfo/v2/startDate/${requestData.startDate}/endDate/${requestData.endDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateAgingReport = async (requestData: any) => {
  const query = {
    startDate: requestData.startDate,
    email: requestData.email,
    isSave: requestData.isSave,
  };
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/v2/agingReport`,
    { params: query, responseType: "blob" },
  );

  return result.data;
};

const generateCustomerDetailsReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/customerDetailsReport/v2/startDate/${requestData.startDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateStampDutyReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/stampDutyReport/v2/reportDate/${requestData.reportDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateConsolidatedEInvoiceDetailsReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/consolidatedEInvoiceDetailsReport/v2/einvoice/${requestData.einvoiceId}`,
    { responseType: "blob" },
  );

  return result.data;
};

const generateConsolidatedCreditNoteDetailsReport = async (
  requestData: any,
) => {
  const { id } = requestData;
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/consolidatedCreditNoteDetailsReport/v2/einvoice/${id}`,
    { responseType: "blob" },
  );

  return result.data;
};

export const generateLoanInsuranceReport = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateLoanInsuranceReport/v2/reportDate/${requestData.startDate}`,
    { responseType: "blob" },
  );

  return result.data;
};

export const generateSmeMonthlyDeductionReport = async (reportDate: string) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/report/generateSmeMonthlyDeductionReport`,
    { params: { reportDate }, responseType: "blob" },
  );

  return result.data;
};

const reportService = {
  getReportListing,
  downloadReport,
  getManualPaymentTemplate,
  generateMasterListingReport,
  generateCollectionReport,
  generateKpktReport,
  generateInterestChargesReport,
  generateMiscBillingReport,
  generateLoanMaturityReport,
  generateOverDueReport,
  generateLoanCncReport,
  generateInterestCncReport,
  generateLateChargesReport,
  generatePartnerReport,
  generateAgingReport,
  generateCustomerDetailsReport,
  generateStampDutyReport,
  generateConsolidatedEInvoiceDetailsReport,
  generateConsolidatedCreditNoteDetailsReport,
  generateLoanInsuranceReport,
  generateSmeMonthlyDeductionReport
};
export default reportService;
