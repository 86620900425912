import { Tabs, Tag } from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import {
  getPendingSigningAgreementsThunk,
  getSignedAgreementsThunk,
} from "../../../services/agreementService/agreementThunk";
import { AppDispatch, RootState } from "../../../store/store";
import CompletedSigningTab from "./CompletedSigningTab";
import PendingSigningTab from "./PendingSigningTab";

import type { TabsProps } from "antd";

const AgreementSigningListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const { pendingSigningAgreements, signedAgreements } = useSelector(
    (state: RootState) => state.agreement,
  );

  const items: TabsProps["items"] = [
    {
      key: "pendingSigning",
      label: (
        <span>
          Pending Signing{" "}
          {pendingSigningAgreements?.data?.searchTotal != null && (
            <Tag>{pendingSigningAgreements.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <PendingSigningTab />,
    },
    {
      key: "completedSigning",
      label: (
        <span>
          Completed Signing{" "}
          {signedAgreements?.data?.searchTotal != null && (
            <Tag>{signedAgreements.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <CompletedSigningTab />,
    },
  ];

  const fetchPendingAgreements = async (params?: any) => {
    await dispatch(getPendingSigningAgreementsThunk(params));
  };

  const fetchSignedAgreements = async (params?: any) => {
    await dispatch(getSignedAgreementsThunk(params));
  };

  const onTabChange = (key: string) => {
    if (key === "pendingSigning") {
      fetchPendingAgreements(initialParams);
    } else if (key === "completedSigning") {
      fetchSignedAgreements(initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchPendingAgreements(params);
    fetchSignedAgreements(params);
  });

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Agreement Signing" />
      <Tabs
        defaultActiveKey={activeTab ?? "pendingSigning"}
        onChange={(key) => onTabChange(key)}
        items={items}
        destroyInactiveTabPane
      />
    </>
  );
};

export default AgreementSigningListingPage;
