import { createSlice } from "@reduxjs/toolkit";
import {
  getAllActionCodeThunk,
  getAllActiveActionCodeThunk,
  getAvailableActionCodeThunk,
} from "../../services/actionCodeService/actionCodeThunk";
import { STATUS_ACTION_CODE } from "../../enum/statusActionCode";

export type ActionCode = {
  id: string;
  code: string;
  description: string;
  actionType: string;
  status?: STATUS_ACTION_CODE;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
};

export type ActionCodeState = {
  actionCodesState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ActionCode[];
  };
  actionCodeDetailsState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: ActionCode;
  };
  actionCodeActiveListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ActionCode[];
  };
  actionCodeAvalaibleListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ActionCode[];
  };
};

const initialState: ActionCodeState = {
  actionCodesState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  actionCodeDetailsState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  actionCodeActiveListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  actionCodeAvalaibleListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const actionCodeSlice = createSlice({
  name: "actionCode",
  initialState,
  reducers: {
    actionCodeReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllActionCodeThunk.pending, (state) => {
        state.actionCodesState.isLoading = true;
      })
      .addCase(getAllActionCodeThunk.fulfilled, (state, action) => {
        state.actionCodesState.isLoading = false;
        state.actionCodesState.data = action.payload.data;
      })
      .addCase(getAllActionCodeThunk.rejected, (state, action) => {
        state.actionCodesState.isLoading = false;
        state.actionCodesState.isError = true;
        state.actionCodesState.errorMessage = action.payload;
      });
    builder
      .addCase(getAllActiveActionCodeThunk.pending, (state) => {
        state.actionCodeActiveListingState.isLoading = true;
      })
      .addCase(getAllActiveActionCodeThunk.fulfilled, (state, action) => {
        state.actionCodeActiveListingState.isLoading = false;
        state.actionCodeActiveListingState.data = action.payload.data;
      })
      .addCase(getAllActiveActionCodeThunk.rejected, (state, action) => {
        state.actionCodeActiveListingState.isLoading = false;
        state.actionCodeActiveListingState.isError = true;
        state.actionCodeActiveListingState.errorMessage = action.payload;
      });
    builder
      .addCase(getAvailableActionCodeThunk.pending, (state) => {
        state.actionCodeAvalaibleListingState.isLoading = true;
      })
      .addCase(getAvailableActionCodeThunk.fulfilled, (state, action) => {
        state.actionCodeAvalaibleListingState.isLoading = false;
        state.actionCodeAvalaibleListingState.data = action.payload.data;
      })
      .addCase(getAvailableActionCodeThunk.rejected, (state, action) => {
        state.actionCodeAvalaibleListingState.isLoading = false;
        state.actionCodeAvalaibleListingState.isError = true;
        state.actionCodeAvalaibleListingState.errorMessage = action.payload;
      });
  },
});

export const { actionCodeReset } = actionCodeSlice.actions;

export default actionCodeSlice.reducer;
