import { Table } from "antd";
import { ColumnsType, SorterResult } from "antd/es/table/interface";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import UpdateDunningInfoModal from "../../../components/UpdateDunningInfoModal";
import { numberWithCommas } from "../../../helpers/number";
import {
  getBPListingThunk,
  getPTPListingThunk,
} from "../../../services/collectionService/collectionThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate, displayDateAndTime } from "../../../utils/datetime.util";
import AccountEnquiryModal from "../../AccountEnquiry/AccountEnquiryModal";

import type { TableProps } from "antd/es/table";
import type { AccountDetails } from "../../../features/collection/collectionSlice";
import { ActionType } from "../../../enum/actionType";

const BPListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { bpList } = useSelector((state: RootState) => state.collection);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<AccountDetails>>(
    {},
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const tablePagination = {
    total: bpList.data?.fixTotal,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const handleTableChange: TableProps<AccountDetails>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      sorter?.order == null
        ? {
            ...initialParams,
            ...filterState,
            pagination,
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          };

    dispatch(getBPListingThunk(param));
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
    setSortedInfo(sorter as SorterResult<AccountDetails>);

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const updateCompleteCallback = () => {
    dispatch(getBPListingThunk(initialParams));
    dispatch(getPTPListingThunk(initialParams));
  };

  const columns: ColumnsType<any> = [
    {
      title: "No",
      key: "index",
      dataIndex: "index",
      fixed: "left",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "DPD",
      dataIndex: "dpd",
      key: "dpd",
      fixed: "left",
      sorter: (a, b) => a.accountDetails.dpd - b.accountDetails.dpd,
      sortOrder: sortedInfo.columnKey === "dpd" ? sortedInfo.order : null,
      render: (text, record) =>
        record.accountDetails.dpd ? record.accountDetails.dpd : "-",
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      fixed: "left",
      render: (_, record) => (
        <AccountEnquiryModal
          key={record?.id}
          agreementNo={record.accountDetails?.agreementNo}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "applicantFirstName",
      key: "applicantFirstName",
      fixed: "left",
      width: "200px",
      render: (text, record) =>
        record?.accountDetails.applicantFirstName ?? "-",
    },
    {
      title: "i-score",
      dataIndex: "iscore",
      key: "iscore",
      fixed: "left",
      render: (_, record) => record?.accountDetails?.user?.iScore ?? "-",
    },
    {
      title: "Total Due Outstanding",
      dataIndex: "totalLoanOutstanding",
      key: "totalLoanOutstanding",
      fixed: "left",
      render: (_, record) => {
        const totalDueInstalment =
          record.accountDetails.totalDueInstalment < 0
            ? 0
            : record.accountDetails.totalDueInstalment;
        const totalLoanOutstanding =
          +record.accountDetails?.totalCharges + +totalDueInstalment;

        return record
          ? `RM ${numberWithCommas(
              totalLoanOutstanding > 0 ? totalLoanOutstanding : 0,
            )}`
          : "-";
      },
    },
    {
      title: "Total Billing Outstanding",
      dataIndex: "billingOutstanding",
      key: "billingOutstanding",
      fixed: "left",
      render: (_, record) =>
        record.accountDetails?.billingOutstanding
          ? `RM ${numberWithCommas(
              record.accountDetails?.billingOutstanding > 0
                ? record.accountDetails?.billingOutstanding
                : 0,
            )}`
          : "-",
    },
    {
      title: "Date & Time",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => a.dpd - b.dpd,
      sortOrder: sortedInfo.columnKey === "updatedAt" ? sortedInfo.order : null,
      fixed: "left",
      width: "200px",
      render: (_, record) => displayDateAndTime(record.updatedAt),
    },
    {
      title: "User ID",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: (a, b) => a.dpd - b.dpd,
      sortOrder: sortedInfo.columnKey === "createdBy" ? sortedInfo.order : null,
      fixed: "left",
      width: "200px",
      render: (_, record) => record.createdBy,
    },
    {
      title: "PTP Date",
      dataIndex: "ptpDate",
      key: "ptpDate",
      sorter: (a, b) => a.dpd - b.dpd,
      sortOrder: sortedInfo.columnKey === "ptpDate" ? sortedInfo.order : null,
      fixed: "left",
      width: "200px",
      render: (_, record) => (record ? displayDate(record.ptpDate) : "-"),
    },
    {
      title: "PTP Amount",
      dataIndex: "ptpAmount",
      key: "ptpAmount",
      fixed: "left",
      width: 50,
      render: (_, record) =>
        record.ptpAmount != null
          ? `RM` + numberWithCommas(record.ptpAmount)
          : "-",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "left",
      width: "200px",
      render: (_, record) => (
        <UpdateDunningInfoModal
          key={`"updateDunningInfoModal${record?.id}`}
          accountDetailsId={record?.accountDetailsId}
          agreementNo={record?.accountDetails.agreementNo}
          onFinishCallback={updateCompleteCallback}
          isUpdate={true}
          id={record?.id}
          statusActivity={ActionType.BP}
          prevPtpAmount={record?.ptpAmount ?? 0}
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(getBPListingThunk(initialParams));
  }, []);

  return (
    <>
      <Helmet>
        <title>BP - redCASH CEP</title>
      </Helmet>
      <Table
        rowKey="id"
        columns={columns}
        loading={bpList.isLoading}
        dataSource={bpList.data?.data}
        pagination={tablePagination}
        onChange={handleTableChange}
      />
    </>
  );
};
export default BPListing;
