import { Button, Input, Select, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { numberWithCommas } from "../../../../helpers/number";
import { getCancelledApplicationsThunk } from "../../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate, displayTime } from "../../../../utils/datetime.util";

import type { Application } from "../../../../features/application/applicationSlice";
import type { ColumnsType } from "antd/es/table";
import type { TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { getAdminsThunk } from "../../../../services/adminService/adminThunk";
import { useLifecycles } from "react-use";

const { Search } = Input;

const CancelledApplicationListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { cancelledApplicationList } = useSelector(
    (state: RootState) => state.application,
  );
  const { admins } = useSelector((state: RootState) => state.admin);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<Application>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("applicationNo");
  const [searchValue, setSearchValue] = useState("");
  const tablePagination = {
    total: cancelledApplicationList.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Application Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
      sorter: (a, b) => a.submissionDate - b.submissionDate,
      sortOrder:
        sortedInfo.columnKey === "submissionDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Time",
      dataIndex: "submissionDate",
      key: "submissionDate",
      width: 120,
      render: (text) => (text ? displayTime(text) : "-"),
    },
    {
      title: "Application No.",
      dataIndex: "applicationNo",
      key: "applicationNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/application/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      render: (_, record) => record?.applicantNric ?? "-",
    },
    {
      title: "Applied Loan Amount",
      dataIndex: "loanApplied",
      key: "loanApplied",
      render: (text) => (text ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Credit Bucket",
      dataIndex: "bucket",
      key: "bucket",
    },
    ...(process.env.REACT_APP_CASE_ASSIGNMENT_ENABLED === "YES"
      ? [
          {
            title: "Case Assignment",
            key: "caseAssignment",
            children: [
              {
                title: "Bucket",
                dataIndex: ["caseAssignment", "bucket", "bucketName"],
                key: "caseAssignmentBucketName",
                render: (bucketName: any) => {
                  return bucketName ?? "-";
                },
              },
              {
                title: "Group",
                dataIndex: ["caseAssignment", "group", "groupCode"],
                key: "caseAssignmentBucketName",
                render: (groupCode: any) => {
                  return groupCode ?? "-";
                },
              },
              {
                title: "User",
                dataIndex: ["caseAssignment", "user", "userId"],
                key: "caseAssignmentUserId",
                render: (adminId: string) => {
                  const admin = admins.data.find(
                    (admin) => admin.id === adminId,
                  );
                  return admin?.username ?? "-";
                },
              },
            ],
          },
        ]
      : []),
  ];

  const fetchApplications = async (params?: any) => {
    await dispatch(getCancelledApplicationsThunk(params));
  };

  const fetchAdmins = async (params?: any) => {
    await dispatch(getAdminsThunk({ params }));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchApplications({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      fetchApplications({
        ...initialParams,
        filters: { ...filterState },
      });
    }

    setFilterState({ ...initialParams.filters });
  };

  const handleTableChange: TableProps<Application>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
            search: {
              [searchFiled]: searchValue,
            },
          };

    fetchApplications(param);
    setSortedInfo(sorter as SorterResult<Application>);
    setPaginationState({
      current: pagination.current ?? initialParams.pagination.current,
      pageSize: pagination.pageSize ?? initialParams.pagination.pageSize,
    });

    queryParams.set(
      "pageNum",
      `${pagination.current ?? initialParams.pagination.current}`,
    );
    queryParams.set(
      "pageSize",
      `${pagination.pageSize ?? initialParams.pagination.pageSize}`,
    );

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() =>
    fetchAdmins({
      pagination: {
        current: 1,
        pageSize: 10000, // get all admins
      },
    }),
  );

  return (
    <>
      <Helmet>
        <title>Cancelled Application - redCASH CEP</title>
      </Helmet>

      <Input.Group compact>
        <Select
          defaultValue="applicationNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No" },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={cancelledApplicationList.data.data}
        loading={cancelledApplicationList.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default CancelledApplicationListing;
