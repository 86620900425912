import "../index.less";

import { Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLifecycles } from "react-use";

import CustomerFormModal from "../../../components/CustomerFormModal";
import InfoGrid from "../../../components/InfoGrid";
import { applicationDetailsStateReset } from "../../../features/application/applicationSlice";
import { getApplicationCreditRecordThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import { getNricInfo } from "../../../utils/nric.util";
import { getAccountDetailsThunk } from "../../../services/collectionService/collectionThunk";
import {
  getGrossSalaryRangeListThunk,
  getRaceListThunk,
} from "../../../services/dropdownParameterService/dropdownParameterThunk";
import { useEffect, useState } from "react";
import { LANGUAGE } from "../../../features/config/configSlice";
import { trimPhoneNo } from "../../../utils/number.util";

const CustomerDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const { creditRecords } = useSelector(
    (state: RootState) => state.application,
  );
  const { raceState } = useSelector(
    (state: RootState) => state.dropdownParameter,
  );
  const { language } = useSelector((state: RootState) => state.config);

  const [application, setApplication] = useState<any>({});

  const editable = true;
  // const editable =
  //   application?.applicationStatus === ApplicationStatusEnum.PENDING ||
  //   application?.applicationStatus === ApplicationStatusEnum.REFERBACK ||
  //   application?.applicationStatus ===
  //     ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT;

  const customerInformation = [
    {
      title: "Full Name",
      value: accountEnquiry?.data.accountDetails?.applicantFirstName
        ? accountEnquiry?.data.accountDetails?.applicantFirstName
        : application?.user?.firstName,
    },
    {
      title: "Customer Type",
      value: application?.customerType,
    },
    {
      title: "Date of Birth",
      value: accountEnquiry.data.accountDetails?.applicantNric
        ? displayDate(
            getNricInfo(
              application?.user?.nric ??
                accountEnquiry.data.accountDetails?.applicantNric ??
                "",
            ).birthDate.toDate(),
          )
        : "-",
    },
    {
      title: "Race",
      value:
        (language === LANGUAGE.EN
          ? raceState.data
              .find((item) => application?.user?.raceId == item.id)
              ?.name?.toUpperCase()
          : raceState.data
              .find((item) => application?.user?.raceId == item.id)
              ?.malayName?.toUpperCase()) ??
        accountEnquiry.data.accountDetails?.applicantRace ??
        application?.applicantRace,
    },
    {
      title: "Identity No",
      value:
        application?.user?.nric ??
        accountEnquiry.data.accountDetails?.applicantNric ??
        application?.applicantNric,
    },
    {
      title: "Phone No",
      value: trimPhoneNo(
        accountEnquiry.data.accountDetails?.applicantPhoneNo ??
          application?.user?.phoneNo ??
          application?.applicantPhoneNo,
      ),
    },
    {
      title: "Email Address",
      value:
        application?.user?.email ??
        accountEnquiry.data.accountDetails?.applicantEmail ??
        application?.applicantEmail,
    },
    {
      title: "Residential Address",
      value: [
        accountEnquiry.data.accountDetails?.applicantAddress1 ??
          application?.applicantAddress1 ??
          application?.user?.residentialAddress1,

        accountEnquiry.data.accountDetails?.applicantAddress2 ??
          application?.applicantAddress2 ??
          application?.user?.residentialAddress2,

        accountEnquiry.data.accountDetails?.applicantAddress3 ??
          application?.applicantAddress3 ??
          application?.user?.residentialAddress3,
      ]
        .filter((el) => el)
        .join(", "),
    },
    {
      title: "Postcode",
      value:
        accountEnquiry.data.accountDetails?.applicantPostcode ??
        application?.applicantPostcode ??
        application?.user?.postcode,
    },
    {
      title: "City",
      value:
        accountEnquiry.data.accountDetails?.applicantCity ??
        application?.applicantCity ??
        application?.user?.city,
    },
    {
      title: "State",
      value:
        accountEnquiry.data.accountDetails?.applicantState ??
        application?.applicantState ??
        application?.user?.state,
    },
    {
      title: "",
      value: "",
    },
    {
      title: "Risk Profiling",
      value: creditRecords?.data?.amla === "APPROVE" ? "LOW" : "MEDIUM/HIGH",
    },
    {
      title: "Marketing Consent",
      value:
        application?.user?.agreeMarketing ??
        application?.applicantAcceptMarketing
          ? "YES"
          : "NO",
    },
    {
      title: "i-Score",
      value:
        application?.iScore && application?.iScore?.length > 0
          ? application?.iScore[0].iScore
          : "-",
    },
    {
      title: "Tin Validated Number",
      value: application?.user?.tinNumber ?? "-",
    },
  ];

  // creditRecords?.data?.iscore && applicationDetails?.data?.iScore &&
  // accountEnquiry.data?.agreementDetails?.application?.iScore?.length > 0
  //   ? `${creditRecords?.data?.iscore} (${applicationDetails?.data?.iScore[0].iScore})`
  //   : "-",

  const fetchApplicationCreditRecord = (applicationId: string) => {
    dispatch(getApplicationCreditRecordThunk({ applicationId: applicationId }));
  };

  const resetApplicationDetailsState = () =>
    dispatch(applicationDetailsStateReset());

  useLifecycles(
    () =>
      accountEnquiry.data?.agreementDetails?.applicationId &&
      fetchApplicationCreditRecord(
        accountEnquiry.data?.agreementDetails?.applicationId,
      ),
    () => resetApplicationDetailsState(),
  );

  useEffect(() => {
    dispatch(getRaceListThunk());
  }, [dispatch]);

  useEffect(() => {
    setApplication(accountEnquiry?.data?.agreementDetails?.application);
  }, [accountEnquiry?.data?.agreementDetails?.application]);

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="flex justify-between items-end">
          <h3 className="account-enquiry-section-subtitle mb-0">
            Customer Information
          </h3>
          {editable && (
            <CustomerFormModal
              key={application?.id}
              applicationDetails={application}
              callback={async () => {
                accountEnquiry.data?.agreementDetails?.applicationId &&
                  fetchApplicationCreditRecord(
                    accountEnquiry.data?.agreementDetails?.applicationId,
                  );

                await dispatch(
                  getAccountDetailsThunk({
                    agreementNo:
                      accountEnquiry.data.agreementDetails?.agreementNo,
                  }),
                );
              }}
              from="AccountEnquiry"
            />
          )}
        </div>

        <Divider style={{ margin: 0 }} />
        <div className="info-container">
          <InfoGrid data={customerInformation} />
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
