import { Button, Card, Input, Select } from "antd";
import Table, { ColumnsType, type TablePaginationConfig } from "antd/es/table";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { AppDispatch, RootState } from "../../../store/store";
import SelectBatch from "../SelectBatch";
import { displayDate } from "../../../utils/datetime.util";
import { numberWithCommas } from "../../../helpers/number";
import { Helmet } from "react-helmet-async";
import { PageHeader } from "@ant-design/pro-components";
import { getPaymentNoticeBatchDetailsThunk } from "../../../services/paymentNoticeService/paymentNoticeThunk";
import { paymentNoticeBatchDetailsReset } from "../../../features/paymentNotice/paymentNoticeSlice";

const { Search } = Input;

const PaymentNoticeListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const batchId = queryParams.get("batchId");
  const batchNo = queryParams.get("batchNo");

  const [searchField, setSearchField] = useState("agreementNo");
  const [loading, setLoading] = useState(false);
  const { paymentNoticeBatchDetails } = useSelector(
    (state: RootState) => state.paymentNotice,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 5,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const fetchPaymentNoticeBatchDetails = async (params?: any) => {
    await dispatch(getPaymentNoticeBatchDetailsThunk(params));
  };

  const handleBatchNoSelect = async (batchId: string, batchNo: string) => {
    queryParams.set("batchId", batchId);
    queryParams.set("batchNo", batchNo);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
    fetchPaymentNoticeBatchDetails({ batchId });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    // fetchPaymentNoticeBatchDetails({
    //   batchId,
    //   pagination,
    // });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const onSearch = async (value: string) => {
    setLoading(true);
    if (value) {
      fetchPaymentNoticeBatchDetails({
        batchId,
        filters: { [searchField]: value },
      });
    } else {
      fetchPaymentNoticeBatchDetails({ batchId });
    }
    setLoading(false);
  };

  const onReset = async () => {
    dispatch(paymentNoticeBatchDetailsReset());
    navigate("/payment-notice-listing");
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Day Past Due",
      dataIndex: ["accountDetails", "dpd"],
      key: "dpd",
    },
    {
      title: "Agreement No",
      dataIndex: ["accountDetails", "agreementNo"],
    },
    {
      title: "Outstanding Balance (RM)",
      dataIndex: "outstandingAmount",
      key: "outstandingAmount",
      render: (text) => numberWithCommas(text) ?? "-",
    },
    {
      title: "Payment Notice Date",
      dataIndex: "paymentNoticeDate",
      key: "paymentNoticeDate",
      render: (_, record) => displayDate(record.createdAt) ?? "-",
    },
  ];

  const tablePagination = {
    total: paymentNoticeBatchDetails.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  useLifecycles(
    () => {
      dispatch(paymentNoticeBatchDetailsReset());
      if (batchId) {
        fetchPaymentNoticeBatchDetails({ batchId });
      }
    },
    () => {
      dispatch(paymentNoticeBatchDetailsReset());
    },
  );

  return (
    <>
      <Helmet>
        <title>Payment Notice - RedCash Collection</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Payment Notice" />
      <Card className="mb-4">
        <div className="flex gap-1 mb-2 items-center">
          <span className="w-20">Batch No : </span>
          <Input
            className="w-[20rem]"
            placeholder="Batch No"
            disabled={true}
            value={batchNo ?? ""}
          />
          <SelectBatch handleSelectBatch={handleBatchNoSelect} />
        </div>
        <div className="flex gap-1 mb-2 items-center">
          <span className="w-16">Type : </span>
          <Input.Group compact>
            <Select
              className="w-[10rem]"
              value={searchField}
              onChange={(value) => {
                setSearchField(value);
              }}
              options={[
                { value: "agreementNo", label: "Agreement No" },
                { value: "applicantNric", label: "Applicant NRIC" },
                { value: "applicantFirstName", label: "Applicant Name" },
                { value: "applicantPhoneNo", label: "Phone No" },
                { value: "applicantEmail", label: "Email" },
              ]}
            />
            <Search
              className="w-[20rem]"
              placeholder="Input search text"
              enterButton="Search"
              loading={loading}
              onSearch={onSearch}
            />
          </Input.Group>
        </div>
      </Card>
      <Table
        rowKey="id"
        className="min-w-fit"
        dataSource={paymentNoticeBatchDetails?.data?.data}
        loading={paymentNoticeBatchDetails?.isLoading}
        columns={columns}
        pagination={tablePagination}
        onChange={handleTableChange}
      />
      <div className="flex flex-wrap justify-end mb-8 mt-3 gap-1">
        <Button onClick={onReset} type="primary">
          Reset
        </Button>
      </div>
    </>
  );
};

export default PaymentNoticeListing;
