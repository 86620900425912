import { createAsyncThunk } from "@reduxjs/toolkit";

import agreementService from "./agreementService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const createAgreementThunk = createAsyncThunk(
  "agreement/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.createAgreement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAgreementsThunk = createAsyncThunk(
  "agreement/getList",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getAgreements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingSigningAgreementsThunk = createAsyncThunk(
  "agreement/getPendingSigningList",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getPendingSigningAgreements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSignedAgreementsThunk = createAsyncThunk(
  "agreement/getSignedList",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getSignedAgreements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAgreementDetailsThunk = createAsyncThunk(
  "agreement/getDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getAgreementDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingEStampAgreementsThunk = createAsyncThunk(
  "agreement/getPendingEStampList",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getPendingEStampAgreements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getInProgressEStampAgreementsThunk = createAsyncThunk(
  "agreement/getInProgressEStampList",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getInProgressEStampAgreements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getUploadedEStampAgreementsThunk = createAsyncThunk(
  "agreement/getUploadedEStampList",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getUploadedEStampAgreements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const searchDisbursedAgreementThunk = createAsyncThunk(
  "agreement/searchDisbursedAgreement",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getAccountDetailsByAgreementNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const approveEStampingThunk = createAsyncThunk(
  "agreement/approveEStamping",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.approveEStamping(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const clickedDownloadEStampingThunk = createAsyncThunk(
  "agreement/clickedDownloadEStamping",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.clickedDownloadEStamping(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const disableDirectDebitThunk = createAsyncThunk(
  "agreement/disableDirectDebit",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.disableDirectDebit(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const clickedDownloadSmeEStampingThunk = createAsyncThunk(
  "agreement/clickedDownloadSmeEStamping",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.clickedDownloadSmeEStamping(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
