import { createAsyncThunk } from "@reduxjs/toolkit";
import noticesHistoryService from "./noticesHistoryService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getListingNoticesHistoryThunk = createAsyncThunk(
  "noticesHistory/getListing",
  async (requetsData: any, thunkAPI) => {
    try {
      return await noticesHistoryService.getNoticesHistoryListing(requetsData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
