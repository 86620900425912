import { caseAssignmentAxiosInstance } from "../../../axios/caseAssignmentAxios";

const getUsersCaseCount = async (requestData: any) => {
  const url = `/caseAssignment/api/v1/user-listing`;
  const res = await caseAssignmentAxiosInstance.get(url);
  return res.data;
};

const caseAssignmentService = {
  getUsersCaseCount,
};

export default caseAssignmentService;
