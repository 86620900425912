import "./index.less";

import { Button, message, Space, Table } from "antd";
import startCase from "lodash.startcase";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import LoadingSpinner from "../../../../components/LoadingSpinner";
import UpdateAocAmountModal from "../../../../components/UpdateAOCAmountModal";
import { AddOnCharges } from "../../../../features/aoc/aocSlice";
import { collectionStateReset } from "../../../../features/collection/collectionSlice";
import { numberWithCommas } from "../../../../helpers/number";
import { updateAocAgreementChargesThunk } from "../../../../services/aocService/aocThunk";
import collectionService from "../../../../services/collectionService/collectionService";
import { getTaxThunk } from "../../../../services/taxService/taxThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";
import AccountEnquiryModal from "../../../AccountEnquiry/AccountEnquiryModal";

import type { ColumnsType } from "antd/es/table";

interface DataType {
  key: React.Key;
  type: string;
  amount: number;
  amountAfterTax: number;
  name: string;
  tax: number;
}
const EditAocByAgreementNo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const { aocBatchById } = useSelector((state: RootState) => state.aoc);
  const { agreementList } = useSelector((state: RootState) => state.agreement);
  const { batchNo } = useSelector((state: RootState) => state.aoc);
  const { batchId, agreementNo, accountDetailsId } = useParams();
  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 1,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchResultVisible, setSearchResultVisible] = useState(false);
  const [searchResultData, setSearchResultData] = useState<any>([]);
  const [amountForAllCharges, setAmountForAllCharges] = useState(0);
  const [displayButton, setDisplayButton] = useState(false);
  const [batchNoId, setBatchNoId] = useState("");
  const [invalidCharges, setInvalidCharges] = useState<any>([]);
  const { tax } = useSelector((state: RootState) => state.tax);
  const [remarks, setRemarks] = useState<any>(null);
  const chargesData: DataType[] = [
    {
      key: "latePaymentCharge",
      type: "latePaymentCharge",
      name: " Late Payment Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "reminderLetter1Charge",
      type: "reminderLetter1Charge",
      name: "Reminder Letter 1 Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "reminderLetter2Charge",
      type: "reminderLetter2Charge",
      name: "Reminder Letter 2 Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "reminderLetter3Charge",
      type: "reminderLetter3Charge",
      name: "Reminder Letter 3 Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "earlySettlementCharge",
      type: "earlySettlementCharge",
      name: "Early Settlement Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "ecaCharge",
      type: "ecaCharge",
      name: "External Collection Agency Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "fieldCharge",
      type: "fieldCharge",
      name: "Field Visit Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "legalCharge",
      type: "legalCharge",
      name: "Legal Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "lodCharge",
      type: "lodCharge",
      name: "LOD Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "miscCharge",
      type: "miscCharge",
      name: "Misc Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "refundCharge",
      type: "refundCharge",
      name: "Refund Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "blacklistUpliftCharge",
      type: "blacklistUpliftCharge",
      name: "Credit Info, Blacklist UpLift Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "adminCharge",
      type: "adminCharge",
      name: "Admin Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "bankCharge",
      type: "bankCharge",
      name: "Bank Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
  ];
  const [chargesArray, setChargesArray] = useState<DataType[]>([]);
  const chargesTypeTableColumns: ColumnsType<DataType> = [
    {
      title: "Charge Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => startCase(record.name),
    },
    {
      title: "Amount Before Tax",
      key: "amount",
      render: (text, record) => `RM ${record.amount.toFixed(2)}` ?? "-",
    },
    {
      title: "Tax",
      key: "tax",
      render: (text, record) => `RM ${numberWithCommas(record.tax)}` ?? "-",
    },
    {
      title: "Amount After Tax",
      key: "amountAfterTax",
      render: (text, record) =>
        `RM ${numberWithCommas(record.amountAfterTax)}` ?? "-",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <UpdateAocAmountModal
            chargeDetails={record}
            tax={tax.data.tax}
            callback={(res: any) => {
              const matchingObject = chargesArray?.find(
                (obj: any) => startCase(obj.type) === res.chargeType,
              );
              if (matchingObject) {
                if (matchingObject.amount == res?.amount) {
                  setDisplayButton(false);
                  message.error("Please do not enter same amount");
                } else {
                  matchingObject.amount = res?.amountAfterTax - res?.tax;
                  matchingObject.tax = res?.tax;
                  matchingObject.amountAfterTax = res?.amountAfterTax;
                  message.success("Amount updated");
                  setDisplayButton(true);
                }
              }
            }}
          />
        </Space>
      ),
    },
  ];

  const agreementTableColumns: ColumnsType<any> = [
    {
      title: "Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal key={record.id} agreementNo={record.agreementNo} />
      ),
    },
    {
      title: "Status",
      dataIndex: "agreementStatus",
      key: "agreementStatus",
      render: (text) => startCase(text),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.application.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.application.applicantNric ?? "-",
    },
    {
      title: "MIA",
      dataIndex: "mia",
      key: "mia",
      render: (text, record) => record?.mia ?? "-",
    },
  ];

  const fetchTax = async (params?: any) => {
    await dispatch(getTaxThunk(params));
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onSearch = async (value: string) => {
    if (value) {
      setLoading(true);
      await collectionService
        .getAccountDetailsForAoc({
          agreementNo: value,
        })
        .then((result) => {
          if (Object.keys(result.agreementDetails).length != 0) {
            setSearchResultVisible(true);
            const arrayData: any = [];
            const agingType = result.accountDetails?.agingType;
            arrayData.push({
              ...result.agreementDetails,
              agingType,
              accountDetailsId: result.accountDetails.id,
              remarks: null,
              mia: result.accountDetails?.mia,
            });

            const objectExisted = searchResultData.find(
              (res: any) => res.id === arrayData[0].id,
            );
            const selectedBatch = batchNo.data.data.find(
              (item) => item.id === batchNoId,
            );

            const agreementExisted = selectedBatch?.addOnChargesAgreement.find(
              (res: any) => res.accountDetailsId === result.accountDetails.id,
            );

            console.log("yyy", agreementExisted);

            if (agreementExisted) {
              message.error(
                `This agreement existed in batch ${selectedBatch?.batchNo}`,
              );
            } else {
              if (!objectExisted) {
                setSearchResultData([...searchResultData, arrayData[0]]);
                setInvalidCharges(result.accountDetails.addOnCharges);
              } else {
                message.error("Agreement already in list");
              }
            }
          } else {
            message.error("Result Not Found");
            dispatch(collectionStateReset());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(collectionStateReset());
    }
  };

  const updateAocAgreementCharges = async () => {
    let aocAmountBeforeTax = 0;
    let aocAmountTax = 0;

    for (const item of chargesArray) {
      if (item.amount > 0) {
        aocAmountBeforeTax += item.amountAfterTax - item.tax;
      }
    }

    let amountToUpdate = 0;
    const filteredData: AddOnCharges[] =
      aocBatchById?.data?.addOnCharges == null ||
      aocBatchById?.data?.addOnCharges?.length < 1
        ? []
        : aocBatchById?.data?.addOnCharges?.reduce((acc, item) => {
            if (
              item.accountDetailsId !== accountEnquiry.data?.accountDetails?.id
            ) {
              amountToUpdate += item.amount;
            }
            return acc;
          }, []);

    aocAmountTax =
      aocAmountBeforeTax + (aocAmountBeforeTax * tax.data?.tax) / 100;
    const payload = {
      createdById: localStorage.getItem("username"),
      aocBatchId: aocBatchById.data?.id,
      addOnChargesAgreements: searchResultData.map((item: any) => ({
        agreementId: item.id,
        aocAmountBeforeTax: aocAmountBeforeTax,
        aocAmountTax: aocAmountTax,
        accountDetailsId: item.accountDetailsId,
        remarks: remarks,
      })),

      addOnCharges: chargesArray.filter((value: any) => {
        return value.amount > 0;
      }),
      totalAocAmountBeforeTax: aocAmountBeforeTax + amountToUpdate,
      totalAocTax: aocAmountTax + amountToUpdate,
    };
    await dispatch(updateAocAgreementChargesThunk(payload)).then((res: any) => {
      message.success(`Add-on charges successfully updated.`);
      navigate(`/write-off/monitor`);
    });
  };

  const onBack = () => {
    setChargesArray([]);
    navigate(-1);
  };

  useLifecycles(() => setChargesArray([]));

  useEffect(() => {
    const filteredData: AddOnCharges[] =
      aocBatchById?.data?.addOnCharges == null ||
      aocBatchById?.data?.addOnCharges?.length < 1
        ? []
        : aocBatchById?.data?.addOnCharges?.filter(
            (res: any) => res.accountDetailsId === accountDetailsId,
          );

    const filterAgreements: any[] =
      aocBatchById.data?.addOnChargesAgreement == null
        ? []
        : aocBatchById.data?.addOnChargesAgreement.filter(
            (res: any) => res.accountDetailsId === accountDetailsId,
          );

    if (
      filterAgreements !== null &&
      filterAgreements?.length > 0 &&
      filterAgreements[0]?.id
    ) {
      setRemarks(filterAgreements[0].remarks);
    }

    const updatedChargesArray = chargesData.map((obj1: any) => {
      const matchingObj = filteredData.find(
        (obj2: any) => obj2.type === obj1.type,
      );

      return matchingObj
        ? {
            key: matchingObj.type,
            type: matchingObj.type,
            name: matchingObj.type,
            amount: matchingObj.amount - matchingObj.tax,
            tax: matchingObj.tax,
            amountAfterTax: matchingObj.amount,
          }
        : obj1;
    });

    for (let i = 0; i < updatedChargesArray.length; i++) {
      for (let k = 0; k < filteredData.length; k++) {
        if (updatedChargesArray[i].type === filteredData[k].type) {
          updatedChargesArray[i].amount =
            filteredData[k].amount - filteredData[k].tax;
        }

        if (
          updatedChargesArray[i].type === "ecaCharge" &&
          filteredData[k].type === "externalCollectionAgentCharge"
        ) {
          updatedChargesArray[i].amount =
            filteredData[k].amount - filteredData[k].tax;
          updatedChargesArray[i].tax = filteredData[k].tax;
          updatedChargesArray[i].name = "External Collection Agency Charge";
          updatedChargesArray[i].type = "ecaCharge";
          updatedChargesArray[i].amountAfterTax = filteredData[k].amount;
        }

        if (
          updatedChargesArray[i].type === "fieldCharge" &&
          filteredData[k].type === "fieldVisitCharge"
        ) {
          updatedChargesArray[i].amount =
            filteredData[k].amount - filteredData[k].tax;
          updatedChargesArray[i].tax = filteredData[k].tax;
          updatedChargesArray[i].name = "Field Visit Charge";
          updatedChargesArray[i].type = "fieldCharge";
          updatedChargesArray[i].amountAfterTax = filteredData[k].amount;
        }

        if (
          updatedChargesArray[i].type === "blacklistUpliftCharge" &&
          filteredData[k].type === "creditInfo,blacklistUpliftCharge"
        ) {
          updatedChargesArray[i].amount =
            filteredData[k].amount - filteredData[k].tax;
          updatedChargesArray[i].tax = filteredData[k].tax;
          updatedChargesArray[i].name = "Credit Info,Blacklist Uplift Charge";
          updatedChargesArray[i].type = "blacklistUpliftCharge";
          updatedChargesArray[i].amountAfterTax = filteredData[k].amount;
        }
      }
    }
    setChargesArray(updatedChargesArray);
  }, [
    aocBatchById?.data,
    accountEnquiry.data?.accountDetails?.id,
    invalidCharges,
    accountDetailsId,
  ]);

  useEffect(() => {
    fetchTax();
    onSearch(agreementNo!);
  }, [initialParams]);

  return (
    <>
      <Helmet>
        <title>Edit Account Add-on Charges - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title="Edit Account Add-on Charges"
        onBack={onBack}
      />
      {aocBatchById.isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="section-container">
          {searchResultData.length > 0 && (
            <>
              <br />
              <div className="section-container">
                <Table
                  rowKey="id"
                  className="min-w-fit mb-4"
                  columns={agreementTableColumns}
                  pagination={false}
                  dataSource={searchResultData}
                  loading={agreementList?.isLoading}
                />
              </div>
              <div className="section-container">
                <h3 className="section-title">Select Charges</h3>
                {selectedRowKeys.length > 0 && (
                  <>
                    <UpdateAocAmountModal
                      tax={tax.data.tax}
                      selectedCharges={selectedRowKeys}
                      amountForAllCharges={amountForAllCharges}
                      callback={(res: any) => {
                        for (let i = 0; i < res.charges.length; i++) {
                          const matchingObject = chargesArray.find(
                            (obj: any) => obj.type === res.charges[i],
                          );

                          if (matchingObject) {
                            matchingObject.amount = res?.amount;
                            matchingObject.tax = res?.tax;
                            matchingObject.amountAfterTax = res?.amountAfterTax;

                            setAmountForAllCharges(res?.amount);
                            setDisplayButton(true);
                          }
                        }
                      }}
                    />
                  </>
                )}

                {chargesArray.length > 0 && (
                  <Table
                    rowSelection={{
                      ...rowSelection,
                    }}
                    columns={chargesTypeTableColumns}
                    pagination={false}
                    loading={aocBatchById?.isLoading}
                    dataSource={chargesArray}
                  />
                )}
              </div>
              <br />
              <div className="w-full flex justify-end gap-x-1.5 mb-8">
                <Button
                  disabled={searchResultData.length < 1 || !displayButton}
                  onClick={updateAocAgreementCharges}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default EditAocByAgreementNo;
