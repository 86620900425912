import { AxiosRequestConfig } from "axios";

import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const blobConfig: AxiosRequestConfig = { responseType: "blob" };

const getEarlySettlements = async (requestData: any) => {
  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/early-settlement`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getVoidEarlySettlements = async (requestData: any) => {
  const params = {
    filters: { ...requestData?.filters, status: "VOID" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/early-settlement`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getNewEarlySettlements = async (requestData: any) => {
  const params = {
    filters: { ...requestData?.filters, status: "NEW" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/early-settlement`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getApprovedEarlySettlements = async (requestData: any) => {
  const params = {
    filters: { ...requestData?.filters, status: "APPROVED" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/early-settlement`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getInProcessEarlySettlements = async (requestData: any) => {
  const params = {
    filters: { ...requestData?.filters, status: "IN_PROCESS" },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/early-settlement`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getRunningEarlySettlement = async (requestData: any) => {
  const url = `/redCash/api/v1.0/early-settlement/running/accountDetails/${requestData.accountDetailsId}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getRunningEarlySettlementByAgreementNo = async (requestData: any) => {
  const { agreementNo, payload } = requestData;
  const url = `/redCash/api/v1.0/early-settlement/running/agreementNo/${agreementNo}`;
  const res = await collectionSetupAxiosInstance.get(url, payload);
  return res.data;
};

const getEarlySettlementById = async (requestData: any) => {
  const url = `/redCash/api/v1.0/early-settlement/${requestData.earlySettlementId}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getEarlySettlementEnQuotation = async (requestData: any) => {
  const url = `/redCash/api/v1.0/early-settlement/${requestData.earlySettlementId}/quotation/en`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getEarlySettlementBmQuotation = async (requestData: any) => {
  const url = `/redCash/api/v1.0/early-settlement/${requestData.earlySettlementId}/quotation/bm`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getEarlySettlementEnTaxInvoice = async (requestData: any) => {
  const url = `/redCash/api/v1.0/early-settlement/${requestData.earlySettlementId}/tax-invoice/en`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getEarlySettlementBmTaxInvoice = async (requestData: any) => {
  const url = `/redCash/api/v1.0/early-settlement/${requestData.earlySettlementId}/tax-invoice/bm`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const updateEarlySettlement = async (requestData: any) => {
  const { earlySettlementId, payload } = requestData;
  const url = `/redCash/api/v1.0/early-settlement/${earlySettlementId}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const getEstimateLatePaymentCharge = async (requestData: any) => {
  const { agreementId, settlementDate } = requestData;
  const url = `/redCash/api/v1.0/early-settlement/estimateLatePaymentCharge/agreementId/${agreementId}/settlementDate/${settlementDate}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const approveEarlySettlement = async (requestData: any) => {
  const { earlySettlementId, payload } = requestData;
  const url = `/redCash/api/v1.0/early-settlement/${earlySettlementId}/approved`;
  const res = await collectionSetupAxiosInstance.patch(url);
  return res.data;
};

const rejectEarlySettlement = async (requestData: any) => {
  const { earlySettlementId, payload } = requestData;
  const url = `/redCash/api/v1.0/early-settlement/${earlySettlementId}/void`;
  const res = await collectionSetupAxiosInstance.patch(url);
  return res.data;
};
const earlySettlementService = {
  getEarlySettlements,
  getNewEarlySettlements,
  getVoidEarlySettlements,
  getApprovedEarlySettlements,
  getInProcessEarlySettlements,
  getRunningEarlySettlement,
  getRunningEarlySettlementByAgreementNo,
  getEarlySettlementById,
  getEarlySettlementEnQuotation,
  getEarlySettlementBmQuotation,
  getEarlySettlementEnTaxInvoice,
  getEarlySettlementBmTaxInvoice,
  getEstimateLatePaymentCharge,
  updateEarlySettlement,
  approveEarlySettlement,
  rejectEarlySettlement,
};

export default earlySettlementService;
