import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import fileService from "./fileService";

export const getEkycFilesByEntityIdThunk = createAsyncThunk(
  "file/getEkycFilesByEntityId",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getEkycFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEkycFileByIdThunk = createAsyncThunk(
  "file/getEkycFileById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getEkycFileById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getFilesByEntityIdThunk = createAsyncThunk(
  "file/getFilesByEntityId",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getMasterSetupFilesByEntityIdThunk = createAsyncThunk(
  "master/file/getFilesByEntityId",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getFileByIdThunk = createAsyncThunk(
  "file/getFileById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFileById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getMasterSetupFileByIdThunk = createAsyncThunk(
  "master/file/getFileById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getMasterSetupFileById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const fileUploadThunk = createAsyncThunk(
  "file/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.fileUpload(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const masterSetupFileUploadThunk = createAsyncThunk(
  "master/file/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.masterSetupFileUpload(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const fileDeleteThunk = createAsyncThunk(
  "file/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.fileDelete(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const agreementFileDeleteThunk = createAsyncThunk(
  "file/agreement/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.agreementFileDelete(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const masterFileDeleteThunk = createAsyncThunk(
  "master/file/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.masterFileDelete(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApplicationFormByIdThunk = createAsyncThunk(
  "application-form/getFileById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getApplicationFormById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getExperianReportByIdThunk = createAsyncThunk(
  "experian-report/getFileById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getExperianReportById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const waiverFileUploadThunk = createAsyncThunk(
  "waiver/file/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.waiverFileUpload(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const waiverFileDeleteThunk = createAsyncThunk(
  "waiver/file/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.waiverFileDelete(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWaiverFileByIdThunk = createAsyncThunk(
  "waiver/file/getFileById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getWaiverFileById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWaiverFileByEntityIdThunk = createAsyncThunk(
  "waiver/file/entity/getFileByEntityId",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getWaiverFileByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const fileUploadByTypeThunk = createAsyncThunk(
  "file/create/byType",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.fileUploadByType(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const fileDeleteByTypeThunk = createAsyncThunk(
  "file/delete/byType",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.fileDeleteByType(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getFileByIdByTypeThunk = createAsyncThunk(
  "file/getFileById/byType",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFileByIdByType(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getFileByEntityIdByTypeThunk = createAsyncThunk(
  "file/entity/getFileByEntityId/byType",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFileByEntityIdByType(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadEInvoiceThunk = createAsyncThunk(
  "report/downloadEInvoice",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getEInvoiceFileById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeFilesByEntityIdThunk = createAsyncThunk(
  "file/getFilesByEntityId",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
