import { Tabs, Tag } from "antd";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "@ant-design/pro-components";

import { WAIVER_BATCH_STATUS } from "../../../enum/waiverBatchStatus";
import {
  getAllWaiverThunk,
  getApprovedWaiverThunk,
  getOpenWaiverThunk,
  getPendingWaiverThunk,
  getReferbackWaiverThunk,
  getVoidWaiverThunk,
} from "../../../services/waiverService/waiverThunk";
import { AppDispatch, RootState } from "../../../store/store";
import AllWaiverListing from "./AllWaiverListing";
import ApprovedWaiverListing from "./ApprovedWaiverListing";
import OpenWaiverListing from "./OpenWaiverListing";
import PendingWaiverListing from "./PendingWaiverListing";
import ReferbackWaiverListing from "./ReferbackWaiverListing";
import VoidWaiverListing from "./VoidWaiverListing";

import type { TabsProps } from "antd";
const WaiverListing = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { allWaiverListing } = useSelector((state: RootState) => state.waiver);
  const { openWaiver } = useSelector((state: RootState) => state.waiver);
  const { pendingWaiver } = useSelector((state: RootState) => state.waiver);
  const { voidWaiver } = useSelector((state: RootState) => state.waiver);
  const { approvedWaiver } = useSelector((state: RootState) => state.waiver);
  const { referbackWaiver } = useSelector((state: RootState) => state.waiver);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <span>
          All{" "}
          {allWaiverListing?.data?.total != null && (
            <Tag>{allWaiverListing.data.total}</Tag>
          )}
        </span>
      ),
      children: <AllWaiverListing />,
    },
    {
      key: "2",
      label: (
        <span>
          Open{" "}
          {openWaiver?.data?.total != null && (
            <Tag>{openWaiver.data.total}</Tag>
          )}
        </span>
      ),
      children: <OpenWaiverListing />,
    },
    {
      key: "3",
      label: (
        <span>
          Pending{" "}
          {pendingWaiver?.data?.total != null && (
            <Tag>{pendingWaiver.data.total}</Tag>
          )}
        </span>
      ),
      children: <PendingWaiverListing />,
    },
    {
      key: "4",
      label: (
        <span>
          Referback{" "}
          {referbackWaiver?.data?.total != null && (
            <Tag>{referbackWaiver.data.total}</Tag>
          )}
        </span>
      ),
      children: <ReferbackWaiverListing />,
    },
    {
      key: "5",
      label: (
        <span>
          Void{" "}
          {voidWaiver?.data?.total != null && (
            <Tag>{voidWaiver.data.total}</Tag>
          )}
        </span>
      ),
      children: <VoidWaiverListing />,
    },
    {
      key: "6",
      label: (
        <span>
          Approved{" "}
          {approvedWaiver?.data?.total != null && (
            <Tag>{approvedWaiver.data.total}</Tag>
          )}
        </span>
      ),
      children: <ApprovedWaiverListing />,
    },
  ];

  const onFetchWaiverBatch = (status?: WAIVER_BATCH_STATUS) => {
    if (status === WAIVER_BATCH_STATUS.PENDING) {
      return dispatch(
        getPendingWaiverThunk({
          ...initialParams,
          filters: {
            ...initialParams.filters,
            status: WAIVER_BATCH_STATUS.PENDING,
          },
        }),
      );
    } else if (status === WAIVER_BATCH_STATUS.REFERBACK) {
      return dispatch(
        getReferbackWaiverThunk({
          ...initialParams,
          filters: {
            ...initialParams.filters,
            status: WAIVER_BATCH_STATUS.REFERBACK,
          },
        }),
      );
    } else if (status === WAIVER_BATCH_STATUS.VOID) {
      return dispatch(
        getVoidWaiverThunk({
          ...initialParams,
          filters: {
            ...initialParams.filters,
            status: WAIVER_BATCH_STATUS.VOID,
          },
        }),
      );
    } else if (status === WAIVER_BATCH_STATUS.OPEN) {
      return dispatch(
        getOpenWaiverThunk({
          ...initialParams,
          filters: {
            ...initialParams.filters,
            status: WAIVER_BATCH_STATUS.OPEN,
          },
        }),
      );
    } else if (status === WAIVER_BATCH_STATUS.APPROVED) {
      return dispatch(
        getApprovedWaiverThunk({
          ...initialParams,
          filters: {
            ...initialParams.filters,
            status: WAIVER_BATCH_STATUS.APPROVED,
          },
        }),
      );
    } else {
      return dispatch(getAllWaiverThunk(initialParams));
    }
  };

  useEffect(() => {
    dispatch(getAllWaiverThunk(initialParams));

    dispatch(
      getOpenWaiverThunk({
        ...initialParams,
        filters: {
          ...initialParams.filters,
          status: WAIVER_BATCH_STATUS.OPEN,
        },
      }),
    );

    dispatch(
      getPendingWaiverThunk({
        ...initialParams,
        filters: {
          ...initialParams.filters,
          status: WAIVER_BATCH_STATUS.PENDING,
        },
      }),
    );

    dispatch(
      getReferbackWaiverThunk({
        ...initialParams,
        filters: {
          ...initialParams.filters,
          status: WAIVER_BATCH_STATUS.REFERBACK,
        },
      }),
    );

    dispatch(
      getVoidWaiverThunk({
        ...initialParams,
        filters: {
          ...initialParams.filters,
          status: WAIVER_BATCH_STATUS.VOID,
        },
      }),
    );

    dispatch(
      getApprovedWaiverThunk({
        ...initialParams,
        filters: {
          ...initialParams.filters,
          status: WAIVER_BATCH_STATUS.APPROVED,
        },
      }),
    );
  }, []);

  const onTabChange = (key: string) => {
    // const currentTab = items.find((item) => item.key === key);
    // setTitle(`${currentTab?.lsabel}`);
    if (key === "1") {
      onFetchWaiverBatch();
    } else if (key === "2") {
      onFetchWaiverBatch(WAIVER_BATCH_STATUS.OPEN);
    } else if (key === "3") {
      onFetchWaiverBatch(WAIVER_BATCH_STATUS.PENDING);
    } else if (key === "4") {
      onFetchWaiverBatch(WAIVER_BATCH_STATUS.REFERBACK);
    } else if (key === "5") {
      onFetchWaiverBatch(WAIVER_BATCH_STATUS.VOID);
    } else if (key === "6") {
      onFetchWaiverBatch(WAIVER_BATCH_STATUS.APPROVED);
    }
  };

  return (
    <>
      <Helmet>
        <title>Waiver Listing- redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Waiver Listing" />
      <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
    </>
  );
};

export default WaiverListing;
