import { createSlice } from "@reduxjs/toolkit";
import {
  getAttestationsThunk,
  getCompletedLiveAttestationsThunk,
  getPendingAttestationsThunk,
  getRequestedLiveAttestationsThunk,
  getVideoAttestationsThunk,
} from "../../services/attestationService/attestationThunk";

export type Attestation = {
  id?: string;
  agreementId?: string;
  attestationStatus?: boolean;
  liveAttestationDateTime?: string;
  requestedLiveAttestationDate?: string;
  requestedLiveAttestationTime?: string;
  liveAttestationRequest?: boolean;
  videoAttestation?: boolean;
  createdAt?: string;
  updatedAt?: string;
  agreement?: any;
  personInCharge: string;
};

export type AttestationState = {
  attestationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: Attestation[];
    };
  };
  pendingAttestationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: Attestation[];
      searchTotal: number;
    };
  };
  requestedLiveAttestationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      searchTotal: number;
      data: Attestation[];
    };
  };
  completedLiveAttestationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      searchTotal: number;
      data: Attestation[];
    };
  };
  videoAttestationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      searchTotal: number;
      data: Attestation[];
    };
  };
};

const initialState: AttestationState = {
  attestationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  pendingAttestationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
  requestedLiveAttestationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      searchTotal: 0,
      data: [],
    },
  },
  completedLiveAttestationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      searchTotal: 0,
      data: [],
    },
  },
  videoAttestationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      searchTotal: 0,
      data: [],
    },
  },
};

export const attestationSlice = createSlice({
  name: "attestation",
  initialState,
  reducers: {
    attestationStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttestationsThunk.pending, (state) => {
        state.attestationList.isLoading = true;
      })
      .addCase(getAttestationsThunk.fulfilled, (state, action) => {
        state.attestationList.isLoading = false;
        state.attestationList.data = action.payload;
        // state.attestations.data = Array.isArray(action.payload) ? action.payload.map((item: any) => ({
        //   ...item,
        //   user: item?.user?.user,
        // })) : [];
      })
      .addCase(getAttestationsThunk.rejected, (state, action) => {
        state.attestationList.isLoading = false;
        state.attestationList.isError = true;
        state.attestationList.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingAttestationsThunk.pending, (state) => {
        state.pendingAttestationList.isLoading = true;
      })
      .addCase(getPendingAttestationsThunk.fulfilled, (state, action) => {
        state.pendingAttestationList.isLoading = false;
        state.pendingAttestationList.data = action.payload;
      })
      .addCase(getPendingAttestationsThunk.rejected, (state, action) => {
        state.pendingAttestationList.isLoading = false;
        state.pendingAttestationList.isError = true;
        state.pendingAttestationList.errorMessage = action.payload;
      });

    builder
      .addCase(getRequestedLiveAttestationsThunk.pending, (state) => {
        state.requestedLiveAttestationList.isLoading = true;
      })
      .addCase(getRequestedLiveAttestationsThunk.fulfilled, (state, action) => {
        state.requestedLiveAttestationList.isLoading = false;
        state.requestedLiveAttestationList.data = action.payload;
      })
      .addCase(getRequestedLiveAttestationsThunk.rejected, (state, action) => {
        state.requestedLiveAttestationList.isLoading = false;
        state.requestedLiveAttestationList.isError = true;
        state.requestedLiveAttestationList.errorMessage = action.payload;
      });

    builder
      .addCase(getCompletedLiveAttestationsThunk.pending, (state) => {
        state.completedLiveAttestationList.isLoading = true;
      })
      .addCase(getCompletedLiveAttestationsThunk.fulfilled, (state, action) => {
        state.completedLiveAttestationList.isLoading = false;
        state.completedLiveAttestationList.data = action.payload;
      })
      .addCase(getCompletedLiveAttestationsThunk.rejected, (state, action) => {
        state.completedLiveAttestationList.isLoading = false;
        state.completedLiveAttestationList.isError = true;
        state.completedLiveAttestationList.errorMessage = action.payload;
      });

    builder
      .addCase(getVideoAttestationsThunk.pending, (state) => {
        state.videoAttestationList.isLoading = true;
      })
      .addCase(getVideoAttestationsThunk.fulfilled, (state, action) => {
        state.videoAttestationList.isLoading = false;
        state.videoAttestationList.data = action.payload;
      })
      .addCase(getVideoAttestationsThunk.rejected, (state, action) => {
        state.videoAttestationList.isLoading = false;
        state.videoAttestationList.isError = true;
        state.videoAttestationList.errorMessage = action.payload;
      });
  },
});

export const { attestationStateReset } = attestationSlice.actions;

export default attestationSlice.reducer;
