import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import writeOffConfigService from "./writeOffConfigService";

export const getAllWriteOffConfigThunk = createAsyncThunk(
  "writeOff/getAllWriteOffConfig",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffConfigService.getAllWriteOffConfig(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateWriteOffConfigThunk = createAsyncThunk(
  "writeOff/updateWriteOffConfig",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffConfigService.updateWriteOffConfig(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createWriteOffConfig = createAsyncThunk(
  "writeOff/createWriteOffConfig",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffConfigService.createWriteOffConfig(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
