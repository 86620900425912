import "./index.less";

import { Button, ConfigProvider, Input, message, Select, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { numberWithCommas } from "../../../helpers/number";
import { AppDispatch, RootState } from "../../../store/store";
import { onCalculateNetDisbursementAmount } from "../../../utils/calculation.util";
import { displayDate } from "../../../utils/datetime.util";

import type { ColumnsType, TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { SmeAgreement } from "../../../features/smeAgreement/smeAgreementSlice";
import {
  createSmeDisbursementBatchThunk,
  getSmeDisbursementBatchListingThunk,
  getSmePendingDisbursementAgreementsListingThunk,
} from "../../../services/smeDisbursementService/smeDisbursementThunk";
import type { Agreement } from "../../../features/agreement/agreementSlice";
import startCase from "lodash.startcase";

const { Search } = Input;
const useBreakpoint = createBreakpoint();

const PendingDisbursementListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const [selectedPendingDisbursement, setSelectedPendingDisbursement] =
    useState<string[]>([]);

  const { pendingDisbursementSmeAgreements } = useSelector(
    (state: RootState) => state.smeDisbursement,
  );

  const { fetchDisbursementBatchListingParams } = useSelector(
    (state: RootState) => state.fetchParameter,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 20,
      },
      sortOrder: "desc",
      sortField: "signDate",
      search: {},
      filters: {},
    }),
    [],
  );

  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 20;
    setPaginationState(initialParams.pagination);
  };

  const tablePagination = {
    total: pendingDisbursementSmeAgreements?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const pendingTableColumns: ColumnsType<any> = [
    {
      title: "Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      sorter: (a, b) => a.signDate - b.signDate,
      sortOrder: sortedInfo.columnKey === "signDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/sme/agreement/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Organization Type",
      render: (text, record) =>
        startCase(record.smeApplication?.organizationType ?? "-"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) =>
        record?.smeApplication?.smeIndividualApplication?.name ??
        record?.smeApplication?.smeSolePropApplication?.name ??
        record?.smeApplication?.smePartnershipApplication?.name ??
        record?.smeApplication?.smeCompanyApplication?.companyName ??
        "-",
    },
    {
      title: "Finance Amount",
      key: "financeAmount",
      render: (_, record) =>
        record?.smeApplication?.financeAmount != null
          ? `RM ${numberWithCommas(+record?.smeApplication?.financeAmount)}`
          : "-",
    },
    {
      title: "Disbursement Amount",
      dataIndex: "disbursementAmount",
      key: "disbursementAmount",
      render: (_, record) =>
        `RM ${numberWithCommas(
          onCalculateNetDisbursementAmount(
            +record.smeApplication?.financeAmount || 0,
            Number(record.smeApplication?.processingFee) || 0,
            +record.smeApplication?.stampingDutyPercentage || 0,
            +record.smeApplication?.processingFeeToDiscount || 0,
            0,
            "APPROVED",
          ),
        )}`,
    },
  ];

  const pendingDisbursementSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: SmeAgreement[]) => {
      setSelectedPendingDisbursement(
        selectedRowKeys.length > 0 ? `${selectedRowKeys}`.split(",") : [],
      );
    },
  };

  const fetchDisbursementAgreements = async (params?: any) => {
    await dispatch(getSmePendingDisbursementAgreementsListingThunk(params));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchDisbursementAgreements({
        ...initialParams,
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      setSearchValue("");
      fetchDisbursementAgreements({
        ...initialParams,
      });
    }
  };

  const handleTableChange: TableProps<SmeAgreement>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
            ...initialParams,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          }
        : {
            ...initialParams,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
            search: {
              [searchFiled]: searchValue.toUpperCase(),
            },
          };

    fetchDisbursementAgreements(param);
    setSortedInfo(sorter as SorterResult<SmeAgreement>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 20,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 20}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const fetchDisbursementBatchListing = async (params?: any) => {
    await dispatch(getSmeDisbursementBatchListingThunk(params));
  };

  const initialCallbackState = {
    fetchDisbursementAgreements,
  };
  const [callbackState] = useState(initialCallbackState);

  const onCreateBatch = () => {
    setIsLoading(true);
    dispatch(
      createSmeDisbursementBatchThunk({
        smeAgreementIds: selectedPendingDisbursement,
      }),
    )
      .unwrap()
      .then((result) => {
        fetchDisbursementAgreements(initialParams);
        fetchDisbursementBatchListing(fetchDisbursementBatchListingParams);
        message.success("Batch created successfully");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    callbackState.fetchDisbursementAgreements(initialParams);
  }, [callbackState, initialParams]);

  return (
    <>
      <Helmet>
        <title>SME Pending Disbursement - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 160 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
            { value: "name", label: "Name" },
          ]}
        />

        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 240 }}
        />
      </Input.Group>
      <br />
      <ConfigProvider theme={{ token: { colorPrimary: "#00b96b" } }}>
        <Table
          rowKey="id"
          className="min-w-fit"
          columns={pendingTableColumns}
          pagination={tablePagination}
          dataSource={pendingDisbursementSmeAgreements?.data?.data}
          loading={pendingDisbursementSmeAgreements?.isLoading}
          onChange={handleTableChange}
          rowSelection={pendingDisbursementSelection}
        />
        {selectedPendingDisbursement.length > 0 && (
          <div
            className={`
          ${isMobile ? "w-full" : "w-[calc(100%-340px)]"}
          bg-white 
            shadow-multi h-16  px-4 
            fixed bottom-0 right-0 
            flex items-center
            justify-end`}
          >
            <h4 className="text-lg my-0 mr-4">
              {selectedPendingDisbursement.length} Selected
            </h4>
            <ConfigProvider theme={{ token: { colorPrimary: "#e40308" } }}>
              <Button
                type="primary"
                onClick={() => onCreateBatch()}
                loading={isLoading}
              >
                Create batch
              </Button>
            </ConfigProvider>
          </div>
        )}
      </ConfigProvider>
    </>
  );
};

export default PendingDisbursementListingPage;
