import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import paymentNoticeService from "./paymentNoticeService";

export const getPaymentNoticeBatchListThunk = createAsyncThunk(
  "paymentNotice/getPaymentNoticeBatchList",
  async (requestData: any, thunkAPI) => {
    try {
      return await paymentNoticeService.getPaymentNoticeBatchList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPaymentNoticeBatchDetailsThunk = createAsyncThunk(
  "paymentNotice/getPaymentNoticeBatchDetails",
  async (requestData: any, thunkAPI) => {
    try {
      console.log("iii", requestData);
      return await paymentNoticeService.getPaymentNoticeBatchDetails(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPaymentNoticeConfigThunk = createAsyncThunk(
  "paymentNotice/getPaymentNoticeConfig",
  async (requestData, thunkAPI) => {
    try {
      return await paymentNoticeService.getPaymentNoticeConfig(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createPaymentNoticeConfigThunk = createAsyncThunk(
  "paymentNotice/createPaymentNoticeConfig",
  async (requestData: any, thunkAPI) => {
    try {
      return await paymentNoticeService.createPaymentNoticeConfig(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updatePaymentNoticeConfigThunk = createAsyncThunk(
  "paymentNotice/updatePaymentNoticeConfig",
  async (requestData: any, thunkAPI) => {
    try {
      return await paymentNoticeService.updatePaymentNoticeConfig(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deletePaymentNoticeConfigThunk = createAsyncThunk(
  "paymentNotice/deletePaymentNoticeConfig",
  async (requestData: any, thunkAPI) => {
    try {
      return await paymentNoticeService.deletePaymentNoticeConfig(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
