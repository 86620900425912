export enum FileTypeEnum {
  SALARYSLIP = "SALARYSLIP",
  BANKSTATEMENT = "BANKSTATEMENT",
  OTHERSRELEVANTDOCUMENTS = "OTHERSRELEVANTDOCUMENTS",
  PAIDRECEIPT = "PAIDRECEIPT",
  VIDEOTTESTATION = "VIDEOTTESTATION",
  LIVEATTESTATIONPROOF = "LIVEATTESTATIONPROOF",
  PROTECTEDSIGNEDAGREEMENTPDF = "PROTECTEDSIGNEDAGREEMENTPDF",
  SIGNEDAGREEMENTPDF = "SIGNEDAGREEMENTPDF",
  EKYCFILES = "EKYCFILES",
  APPLICATIONFILES = "APPLICATIONFILES",
  EXPERIANREPORT = "EXPERIANREPORT",
  ESTAMPING = "ESTAMPING",
  NRVB = "NRVB",
  IRISS = "IRISS",
  SPKCCS = "SPKCCS",
  AMLA = "AMLA",
  WAIVER_CHARGE = "WAIVER_CHARGE",
  AOC_CHARGE = "AOC_CHARGE",
  FIELD_VISIT = "FIELD_VISIT",
  MANUAL_PAYMENT = "MANUAL_PAYMENT",
  REFUND = "REFUND",
  RESCHEDULE_AND_RESTRUCTURE = "RESCHEDULE_AND_RESTRUCTURE",

  // SME File Types
  BUSINESS_INTELLIGENCE = "BUSINESS_INTELLIGENCE",
  COMPANY_INFORMATION = "COMPANY_INFORMATION",
  LIQUIDATED_REPORT = "LIQUIDATED_REPORT",
  CDDI = "CDDI",
  CDDC = "CDDC",
  ECCRIS = "ECCRIS",
  SME_E_STAMPING = "SME_E_STAMPING",
  APPLICATIONFORM = "APPLICATIONFORM",
  SME_LIVE_ATTESTATION_PROOF = "SME_LIVE_ATTESTATION_PROOF",
}
