import "./index.less";

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LoadingOutlined } from "@ant-design/icons";

import { Role } from "../../enum/roles";
import { Application } from "../../features/application/applicationSlice";
import { LANGUAGE, setLanguage } from "../../features/config/configSlice";
import { useDebounce } from "../../hooks/useDebounceHook";
import {
  updateApplicantThunk,
  updateApplicantUserDetailsThunk,
} from "../../services/applicationService/applicationThunk";
import {
  getBankListThunk,
  getEmployeeTypeListThunk,
  getGrossSalaryRangeListThunk,
  getRaceListThunk,
  getRelationshipListThunk,
} from "../../services/dropdownParameterService/dropdownParameterThunk";
import { getCityStateByPostcodeThunk } from "../../services/locationService/locationThunk";
import { AppDispatch, RootState } from "../../store/store";
import { monthYearFormat } from "../../utils/datetime.util";
import {
  numberRegexPattern,
  phoneNumberValidator,
} from "../../utils/validation";

import type { RangePickerProps } from "antd/es/date-picker";
import { getAdminByIdThunk } from "../../services/adminService/adminThunk";

const { Option } = Select;
const mb8 = { marginBottom: 8 };

type CustomerFormModalProps = {
  applicationDetails?: Application;
  callback?: () => void;
  from?: string;
};

const CustomerFormModal: React.FC<CustomerFormModalProps> = (props) => {
  const { applicationDetails, callback, from } = props;

  const [bankIbgRoutingCode, setBankIbgRoutingCode] = useState<string>(
    applicationDetails?.applicantBankIbgRoutingNumber ?? "",
  );

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { admin } = useSelector((state: RootState) => state.admin);

  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [postcode, setPostcode] = useState<string>("");
  const [validPostcode, setValidPostcode] = useState<boolean>(true);
  const debouncedPostcode = useDebounce<string>(postcode, 500);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const invalidPostcodeMsg = "Invalid postcode";

  const {
    raceState,
    grossSalaryRangeState,
    employeeTypeState,
    bankState,
    relationshipState,
  } = useSelector((state: RootState) => state.dropdownParameter);
  const { language } = useSelector((state: RootState) => state.config);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onPostcodeChange = () => {
    setValidPostcode(true);
    setPostcode(form.getFieldsValue().applicantPostcode);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today
    return current && moment(current.valueOf()) > moment().endOf("day");
  };

  const onFinish = async () => {
    if (!validPostcode) {
      return message.error(invalidPostcodeMsg);
    }
    await form
      .validateFields()
      .then((values) => {
        const userPayload: { [key: string]: any } = {
          firstName: values.applicantFirstName?.toUpperCase(),
          // isNric: applicationDetails?.user?.isNric,
          isNric: true,
          nric: values.applicantNric?.toUpperCase(),
          phoneNo: values.applicantPhoneNo,
          residentialAddress1:
            values.address.applicantAddress1?.toUpperCase() ?? "",
          residentialAddress2:
            values.address.applicantAddress2?.toUpperCase() ?? "",
          residentialAddress3:
            values.address.applicantAddress3?.toUpperCase() ?? "",
          city: values.applicantCity?.toUpperCase(),
          postcode: values.applicantPostcode,
          state: values.applicantState?.toUpperCase(),
          email: values.applicantEmail,
          raceId: values.applicantRace,
          grossSalaryRangeId: values.applicantGrossSalaryRange,
          partnerId: values.applicantPartnerId,

          // For cep update
          applicantEmploymentType: values?.applicantEmploymentType,
          applicantBankName: values?.applicantBankName,
          applicantPersonalBankNo: values?.applicantPersonalBankNo,
          applicantEmergencyContactRelationship:
            values?.applicantEmergencyContactRelationship?.toUpperCase(),

          actualMonthlySalary: values?.actualMonthlySalary,

          employmentType: values?.employmentType,
          employerName: values?.employerName?.toUpperCase(),
          employerContactNo: values?.employerContactNo,
          employmentStartingFrom: values?.employmentStartingFrom,
          jobTitle: values?.jobTitle?.toUpperCase(),

          emergencyContactName: values?.emergencyContactName?.toUpperCase(),
          emergencyContactNo: values?.emergencyContactNo,
          emergencyContactPersonEmail: values?.emergencyContactPersonEmail,
          applicantBankIbgRoutingNumber: bankIbgRoutingCode,
          applicantPartnerId: values?.applicantPartnerId,
          icType: values?.icType,

          secondEmergencyContactName:
            values?.secondEmergencyContactName?.toUpperCase(),
          secondEmergencyContactNo: values?.secondEmergencyContactNo,
          applicantSecondEmergencyContactRelationship:
            values?.applicantSecondEmergencyContactRelationship?.toUpperCase(),
          createdBy: localStorage.getItem("username"),
        };

        setLoading(true);
        dispatch(
          updateApplicantUserDetailsThunk({
            userId: applicationDetails?.userId,
            applicationId: applicationDetails?.id,
            agreementId: applicationDetails?.agreementId,
            payload: userPayload,
          }),
        ).then(() => {
          if (callback) callback();
          handleCancel();
        });
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLangChange = (lang: LANGUAGE) => {
    dispatch(setLanguage(lang));
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    dispatch(getRaceListThunk());
    dispatch(getGrossSalaryRangeListThunk());
    dispatch(getBankListThunk());
    dispatch(getEmployeeTypeListThunk());
    dispatch(getRelationshipListThunk());
  }, [dispatch]);

  useEffect(() => {
    if (applicationDetails) {
      if (
        applicationDetails.language === "ms" ||
        applicationDetails.language === "bm"
      ) {
        handleLangChange(LANGUAGE.MS);
      } else {
        handleLangChange(LANGUAGE.EN);
      }
    }
  }, [applicationDetails?.language]);

  useEffect(() => {
    if (debouncedPostcode) {
      dispatch(
        getCityStateByPostcodeThunk({
          postcode: form.getFieldsValue().applicantPostcode,
        }),
      )
        .unwrap()
        .then((res) => {
          setValidPostcode(res.length > 0);
          if (res.length > 0) {
            form.setFieldsValue({
              applicantCity: res[0].post_office,
              applicantState: res[0].state_code,
            });
          } else {
            form.setFieldsValue({
              applicantCity: undefined,
              applicantState: undefined,
            });
          }
        })
        .catch((error: Error) => {
          console.log(error);
          setValidPostcode(false);
        });
    }
  }, [debouncedPostcode]);

  useEffect(() => {
    dispatch(getAdminByIdThunk({ adminId: currentUser.data?.id }));
  }, [currentUser.data?.id]);

  return (
    <>
      <Button onClick={showModal} className="mb-2">
        Edit
      </Button>
      <Modal
        title="Edit Application Information"
        open={isModalOpen}
        onCancel={handleCancel}
        width={1000}
        footer={null}
        destroyOnClose
      >
        <Form
          form={form}
          name="register"
          size="large"
          requiredMark={false}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            applicantFirstName:
              applicationDetails?.user?.firstName ??
              accountEnquiry.data.accountDetails?.applicantFirstName ??
              "",
            applicantNric:
              applicationDetails?.user?.nric ??
              accountEnquiry.data.accountDetails?.applicantNric ??
              "",
            applicantEmail:
              applicationDetails?.user?.email ??
              accountEnquiry.data.accountDetails?.applicantEmail ??
              "",
            applicantRace: applicationDetails?.user?.raceId,
            applicantPartnerId:
              applicationDetails?.user?.partnerId ??
              accountEnquiry.data.accountDetails?.applicantPartnerId ??
              "",
            applicantGrossSalaryRange:
              applicationDetails?.user?.grossSalaryRangeId,
            applicantPhoneNo:
              applicationDetails?.user?.phoneNo ||
              applicationDetails?.applicantPhoneNo
                ? accountEnquiry.data.accountDetails?.applicantPhoneNo ??
                  applicationDetails?.user?.phoneNo
                : null,
            address: {
              applicantAddress1:
                accountEnquiry.data.accountDetails?.applicantAddress1 ??
                applicationDetails?.user?.residentialAddress1 ??
                "",
              applicantAddress2:
                accountEnquiry.data.accountDetails?.applicantAddress2 ??
                applicationDetails?.user?.residentialAddress2 ??
                "",
              applicantAddress3:
                accountEnquiry.data.accountDetails?.applicantAddress3 ??
                applicationDetails?.user?.residentialAddress3 ??
                "",
            },
            applicantPostcode:
              accountEnquiry.data.accountDetails?.applicantPostcode ??
              applicationDetails?.user?.postcode ??
              "",
            applicantCity:
              applicationDetails?.user?.city ??
              accountEnquiry.data.accountDetails?.applicantCity ??
              "",
            applicantState:
              accountEnquiry.data.accountDetails?.applicantState ??
              applicationDetails?.user?.state ??
              "",
            // prefix: "+60",
            identityCardType: "nric",
            applicantEmploymentType:
              accountEnquiry.data.accountDetails?.employmentType ??
              applicationDetails?.applicantEmploymentType,
            applicantBankName:
              accountEnquiry.data.accountDetails?.applicantBankName ??
              applicationDetails?.applicantBankName,
            applicantPersonalBankNo:
              accountEnquiry.data.accountDetails?.applicantPersonalBankNo ??
              applicationDetails?.applicantPersonalBankNo,
            actualMonthlySalary:
              accountEnquiry.data.accountDetails?.actualMonthlySalary ??
              applicationDetails?.actualMonthlySalary,
            employerName:
              accountEnquiry.data.accountDetails?.employerName ??
              applicationDetails?.employerName,
            employerContactNo:
              accountEnquiry.data.accountDetails?.employerContactNo ??
              applicationDetails?.employerContactNo,
            employmentStartingFrom:
              accountEnquiry.data.accountDetails?.employmentStartingFrom != null
                ? dayjs(
                    accountEnquiry.data.accountDetails?.employmentStartingFrom,
                  )
                : applicationDetails?.employmentStartingFrom != null
                ? dayjs(applicationDetails?.employmentStartingFrom)
                : null,
            jobTitle:
              accountEnquiry.data.accountDetails?.jobTitle ??
              applicationDetails?.jobTitle,
            emergencyContactName:
              accountEnquiry.data.accountDetails?.emergencyContactName ??
              applicationDetails?.emergencyContactName,
            emergencyContactNo:
              accountEnquiry.data.accountDetails?.emergencyContactNo ??
              applicationDetails?.emergencyContactNo,
            applicantEmergencyContactRelationship:
              accountEnquiry.data.accountDetails
                ?.applicantEmergencyContactRelationship ??
              applicationDetails?.applicantEmergencyContactRelationship,
            emergencyContactPersonEmail:
              accountEnquiry.data.accountDetails?.emergencyContactPersonEmail ??
              applicationDetails?.emergencyContactPersonEmail,
            icType: applicationDetails?.user?.icType,
            secondEmergencyContactName:
              accountEnquiry.data.accountDetails?.secondEmergencyContactName ??
              applicationDetails?.secondEmergencyContactName,
            secondEmergencyContactNo:
              accountEnquiry.data.accountDetails?.secondEmergencyContactNo ??
              applicationDetails?.secondEmergencyContactNo,
            applicantSecondEmergencyContactRelationship:
              accountEnquiry.data.accountDetails
                ?.applicantSecondEmergencyContactRelationship ??
              applicationDetails?.applicantSecondEmergencyContactRelationship,
          }}
          scrollToFirstError
        >
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantFirstName"
                label="Full Name"
                style={{ ...mb8, width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  disabled={
                    from === "AccountEnquiry" &&
                    admin.data.admin.role !== Role.SUPER_ADMIN
                      ? true
                      : false
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantNric"
                label="NRIC"
                style={{ ...mb8, width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "NRIC is required",
                  },
                ]}
              >
                <Input
                  placeholder="NRIC"
                  disabled={
                    from === "AccountEnquiry" &&
                    admin.data.admin.role !== Role.SUPER_ADMIN
                      ? true
                      : false
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantPartnerId"
                label="Partner ID"
                style={{ ...mb8, width: "100%" }}
                rules={[
                  {
                    len: 7,
                    message: "Please enter a valid redONE Partner ID no.",
                  },
                  // only allow number
                  {
                    pattern: numberRegexPattern,
                    message: "Please enter numbers only (0-9)",
                  },
                ]}
              >
                <Input inputMode="numeric" placeholder="Partner ID" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantPhoneNo"
                label="Phone No."
                style={{ ...mb8 }}
                // label={
                //   <label>
                //     red<span style={{ color: primaryColor }}>ONE</span> Phone
                //     Number
                //   </label>
                // }
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number!",
                  },
                  // {
                  //   validator: (_, value) => phoneNumberValidator(`${value}`),
                  // },
                ]}
              >
                <Input
                  // className="phone-input-with-prefix"
                  // addonBefore="(MY)+60"
                  inputMode="tel"
                  style={{ width: "100%" }}
                  disabled={
                    from === "cep" && admin.data.admin.role !== Role.SUPER_ADMIN
                      ? true
                      : false
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantEmail"
                label="Email Address"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    type: "email",
                    message: "The enter is not valid Email Address",
                  },
                  {
                    required: true,
                    message: "Please enter your Email Address",
                  },
                ]}
              >
                <Input
                  placeholder="Example: example@email.com"
                  onInput={(e) =>
                    ((e.target as HTMLInputElement).value = (
                      e.target as HTMLInputElement
                    ).value.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantRace"
                label="Race"
                style={{ ...mb8, width: "100%" }}
              >
                <Select
                  placeholder="Please Select"
                  loading={raceState.isLoading}
                  allowClear
                >
                  {Array.isArray(raceState.data) &&
                    raceState.data.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {language === LANGUAGE.EN
                            ? item.name?.toUpperCase()
                            : item.malayName?.toUpperCase()}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantGrossSalaryRange"
                label="Gross Salary Range"
                style={{ ...mb8, width: "100%" }}
              >
                <Select
                  placeholder="Select Salary Range"
                  allowClear
                  loading={grossSalaryRangeState.isLoading}
                >
                  {Array.isArray(grossSalaryRangeState.data) &&
                    grossSalaryRangeState.data.map((item) => {
                      if (item.minSalary < 2000)
                        return (
                          <Option key={item.id} value={item.id}>
                            Below 2000
                          </Option>
                        );
                      else if (item.maxSalary > 10000)
                        return (
                          <Option key={item.id} value={item.id}>
                            Above 10,000
                          </Option>
                        );
                      else
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.minSalary} - {item.maxSalary}
                          </Option>
                        );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Current Residential Address" style={{ ...mb8 }}>
                <Input.Group>
                  <Row gutter={16}>
                    <Col span={24} md={8}>
                      <Form.Item
                        name={["address", "applicantAddress1"]}
                        style={{ ...mb8, width: "100%" }}
                        validateFirst={true}
                        rules={[
                          { required: true, message: "Address is required" },
                        ]}
                      >
                        <Input placeholder="Address line 1" />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={8}>
                      <Form.Item
                        name={["address", "applicantAddress2"]}
                        style={{ ...mb8, width: "100%" }}
                      >
                        <Input placeholder="Address line 2" />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={8}>
                      <Form.Item
                        name={["address", "applicantAddress3"]}
                        style={{ width: "100%", marginBottom: 0 }}
                      >
                        <Input placeholder="Address line 3" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantPostcode"
                label="Postcode"
                validateFirst={true}
                style={mb8}
                rules={[
                  {
                    pattern: numberRegexPattern,
                    message: "Invalid postcode",
                  },
                  { required: true, message: "Please enter your postcode" },
                ]}
                validateStatus={validPostcode ? "success" : "error"}
                help={!validPostcode && invalidPostcodeMsg}
              >
                <Input
                  inputMode="numeric"
                  placeholder="Please input"
                  onChange={onPostcodeChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item name="applicantCity" label="City" style={mb8}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item name="applicantState" label="State" style={mb8}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="icType"
                label="IC Type"
                validateFirst={true}
                style={mb8}
              >
                <Select
                  loading={raceState.isLoading}
                  options={[
                    { value: "MYKAD", label: "MyKad" },
                    { value: "MYS_TENTERA", label: "MyTentera" },
                  ]}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantEmploymentType"
                label="Employment Type"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    required: true,
                    message: "Employment type is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Employment Type"
                  allowClear
                  loading={employeeTypeState.isLoading}
                >
                  {Array.isArray(employeeTypeState.data) &&
                    employeeTypeState.data.map((item) => {
                      return (
                        <Option
                          key={item.name?.toUpperCase()}
                          value={item.name?.toUpperCase()}
                        >
                          {language === LANGUAGE.EN
                            ? item.name?.toUpperCase()
                            : item.malayName?.toUpperCase()}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="jobTitle"
                label="Job Title"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter job title",
                  },
                ]}
              >
                <Input placeholder="Please input" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="employmentStartingFrom"
                label="Employment Start Date"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    required: true,
                    message: "Employment start date is required",
                  },
                ]}
              >
                <DatePicker
                  picker="month"
                  format={monthYearFormat}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="actualMonthlySalary"
                label="Monthly Net Salary"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    required: true,
                    message: "Monthly net salary is required.",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  controls={false}
                  inputMode="numeric"
                  placeholder="Please input"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name="employerName"
                label="Employer Name"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter employer name",
                  },
                ]}
              >
                <Input placeholder="Please input" />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name="employerContactNo"
                label="Employer Phone No."
                style={{ ...mb8 }}
                // label={
                //   <label>
                //     red<span style={{ color: primaryColor }}>ONE</span> Phone
                //     Number
                //   </label>
                // }
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter employer phone number!",
                  },
                  // {
                  //   validator: (_, value) => phoneNumberValidator(`${value}`),
                  // },
                ]}
              >
                <Input
                  // className="phone-input-with-prefix"
                  inputMode="tel"
                  // addonBefore="(MY)+60"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantBankName"
                label="Bank Name"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    required: true,
                    message: "Bank is required",
                  },
                ]}
              >
                <Select
                  placeholder="Please Select"
                  allowClear
                  loading={bankState.isLoading}
                  onChange={(value) => {
                    if (bankState?.data) {
                      const foundBank = bankState.data.find(
                        (item) => item.name.toUpperCase() === value,
                      );

                      if (foundBank && foundBank.ibgRoutingNumber) {
                        setBankIbgRoutingCode(foundBank.ibgRoutingNumber);
                      }
                    }
                  }}
                >
                  {Array.isArray(bankState.data) &&
                    bankState.data.map((item) => {
                      return (
                        <Option key={item.id} value={item.name.toUpperCase()}>
                          {language === LANGUAGE.EN
                            ? item.name?.toUpperCase()
                            : item.malayName?.toUpperCase()}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="applicantPersonalBankNo"
                label="Personal Bank Account No."
                extra="Note: Bank Account must be same with apply person"
                validateFirst={true}
                style={{ ...mb8, width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter your personal bank account No.",
                  },
                  {
                    min: 5,
                    max: 17,
                    message: "Invalid bank account No.",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Please input"
                  inputMode="numeric"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="emergencyContactName"
                label="Emergency Contact Person’s Name"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter emergency contact person’s name",
                  },
                ]}
              >
                <Input placeholder="Please input" />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name="applicantEmergencyContactRelationship"
                label="Emergency Contact Relationship"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    required: true,
                    message:
                      "Please select emergency contact relationship status.",
                  },
                ]}
              >
                <Select
                  placeholder="Please Select"
                  allowClear
                  loading={relationshipState.isLoading}
                >
                  {Array.isArray(relationshipState.data) &&
                    relationshipState.data
                      .filter((res) => res.name !== "Partner")
                      .map((item) => {
                        return (
                          <Option key={item.id} value={item.name.toUpperCase()}>
                            {language === LANGUAGE.EN
                              ? item.name?.toUpperCase()
                              : item.malayName?.toUpperCase()}
                          </Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="emergencyContactNo"
                label="Emergency Contact Number"
                validateFirst={true}
                style={{ ...mb8 }}
              >
                <Input
                  // className="phone-input-with-prefix"
                  // addonBefore="(MY)+60"
                  inputMode="tel"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} md={8}>
              <Form.Item
                name="emergencyContactPersonEmail"
                label="Emergency Contact Person’s Email"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    type: "email",
                    message: "The enter is not valid Email Address",
                  },
                  {
                    required: true,
                    message: "Please enter emergency contact person’s email",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value === getFieldValue("email")) {
                        return Promise.reject(
                          "Emergency contact email cannot be the same as personal email",
                        ); // The validator should always return a promise on both success and error
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="example@email.com"
                  onInput={(e) =>
                    ((e.target as HTMLInputElement).value = (
                      e.target as HTMLInputElement
                    ).value.toLowerCase())
                  }
                />
              </Form.Item>
            </Col> */}
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="secondEmergencyContactName"
                label="Second Emergency Contact Person’s Name"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    message:
                      "Please enter second emergency contact person’s name",
                  },
                ]}
              >
                <Input placeholder="Please input" />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name="applicantSecondEmergencyContactRelationship"
                label="Second Emergency Contact Relationship"
                validateFirst={true}
                style={{ ...mb8 }}
                rules={[
                  {
                    message:
                      "Please select second emergency contact relationship status.",
                  },
                ]}
              >
                <Select
                  placeholder="Please Select"
                  allowClear
                  loading={relationshipState.isLoading}
                >
                  {Array.isArray(relationshipState.data) &&
                    relationshipState.data
                      .filter((res) => res.name !== "Partner")
                      .map((item) => {
                        return (
                          <Option key={item.id} value={item.name.toUpperCase()}>
                            {language === LANGUAGE.EN
                              ? item.name?.toUpperCase()
                              : item.malayName?.toUpperCase()}
                          </Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="secondEmergencyContactNo"
                label="Second Emergency Contact Number"
                validateFirst={true}
                style={{ ...mb8 }}
              >
                <Input
                  // className="phone-input-with-prefix"
                  // addonBefore="(MY)+60"
                  inputMode="tel"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-full mt-4">
              {loading ? <LoadingOutlined style={{ fontSize: 28 }} /> : "Save"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerFormModal;
