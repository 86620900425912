export const successIconGreen = "#82FF8E";
export const successTextGreen = "#52c41a";
export const successBackgroundGreen = "#E8FFD1";
export const dangerIconRed = "#F13035";
export const dangerTextRed = "#ff4d4f";

export const primaryColor = "#e40308";
export const primaryWhite = "#fff";

export const bgBlue = "#e6f7ff";
export const gradiantPrimaryColor =
  "linear-gradient(90deg, #f13035 27.08%, #fc6767 127.08%)";
