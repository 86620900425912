import "./index.less";

import {
  Card,
  Col,
  DatePicker,
  message,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs, { Dayjs } from "dayjs";
import startCase from "lodash.startcase";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";

import { DualAxes, G2 } from "@ant-design/charts";
import { Pie } from "@ant-design/plots";
import { PageHeader } from "@ant-design/pro-components";

import LoadingSpinner from "../../components/LoadingSpinner";
import { numberWithCommas } from "../../helpers/number";
import {
  getApplicationProcessDataThunk,
  getDashboardCard1Data,
  getDashboardCard2Data,
  getDashboardCard3Data,
  getDistinctApplicationUserIdTotalThunk,
  downloadApplicationProcessReportThunk,
  getRegistrationCaseThunk,
  getTotalCasesThunk,
} from "../../services/dashboardService/dashboardThunk";
import { AppDispatch } from "../../store/store";
import CasePieChart from "./components/casePieComponent";
import CasesCard from "./components/casesCard";
import PerformanceCard from "./components/performanceCard";
import {
  dataPerformanceTableOrder,
  dateTypeMapDict,
  inProcessCaseOrder,
  inProgressStatusList,
  performanceStatusMapDict,
} from "./constants/dict";

import type { TimeRangePickerProps } from "antd";
import type { RadioChangeEvent } from "antd";
const { RangePicker } = DatePicker;
const { registerTheme } = G2;
const { Text } = Typography;

const DashboardPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [totalCases, setTotalCases] = useState<any[]>([]);
  const [data_process_case, setDataProcessCase] = useState<any[]>([]);
  const [dataTotalCases, setDataTotalCases] = useState({
    data: [
      {
        status: "inProgress",
        totalCases: 0,
        totalLoanAmount: 0,
        totalInterestAmount: 0,
        loanApplied: 0,
      },
    ],
    totalCases: 0,
    totalLoanAmount: 0,
  });
  const [dataPerformance, setDataPerformance] = useState({
    data: [{ name: "", total: 0, data: [{ date: "", value: 0 }] }],
  });
  const [dataPerformanceStatus, setDataPerformanceStatus]: any = useState({});
  const [dataInProgressCases, setDataInProgressCases] = useState({
    data: [{ status: "", total: 0, data: [{ day: "", value: 0 }] }],
    total: 0,
  });
  const [selectedDateTypeCard1, setSelectedDateTypeCard1] = useState("date");
  const [selectedDateTypeCard2, setSelectedDateTypeCard2] = useState("date");
  const [selectedDateCard1, setSelectedDateCard1] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(1, "month"),
    dayjs(),
  ]);
  const [selectedDateCard2, setSelectedDateCard2] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(7, "day"),
    dayjs(),
  ]);
  const [selectedStatusCard2, setSelectedStatusCard2] =
    useState("NEW_APPLICATION");

  const getApplicationProcessData = async () => {
    let applicationData: any[] = [];
    let finalData: any[] = [];
    let registrationCase: any;
    await dispatch(getRegistrationCaseThunk())
      .unwrap()
      .then((res) => {
        registrationCase = res;
      })
      .catch((error) => {
        message.error(`Failed to get data for registration cases.`);
      });

    await dispatch(getApplicationProcessDataThunk())
      .unwrap()
      .then((res) => {
        applicationData = res.data;
      })
      .catch((error) => {
        message.error(`Failed to get data for application process cases.`);
      });

    // all existing users minus all users with application already // assume no application with non-existing users
    // to get total number of registration/testing-eligibility in progress
    let registrationCaseTotal = 0;
    await dispatch(getDistinctApplicationUserIdTotalThunk())
      .unwrap()
      .then((res) => {
        // registrationCaseTotal = Math.max(
        //   0,
        //   registrationCase?.total - res.data.total,
        // );
        registrationCaseTotal = res.data.total;
      })
      .catch((error) => {
        message.error(`Failed to get data for registration cases.`);
      });

    finalData = applicationData;
    finalData.sort((a, b) => {
      return (
        inProcessCaseOrder.indexOf(a.name) - inProcessCaseOrder.indexOf(b.name)
      );
    });
    setDataProcessCase(finalData);
  };

  const getTotalCasesData = async () => {
    let applicationData: any[] = [];
    await dispatch(getTotalCasesThunk())
      .unwrap()
      .then((res) => {
        applicationData = res.data;
      })
      .catch((error) => {
        message.error(`Failed to get data for total cases.`);
      });

    setTotalCases(applicationData);
  };

  // card 1 fetch data
  const onGetTotalCase = async () => {
    const dateStringCard1 = onProcessDateToString(
      selectedDateCard1,
      selectedDateTypeCard1,
    );
    //console.log("date card 1 selected:", dateStringCard1);
    dispatch(
      getDashboardCard1Data({
        startDate: dateStringCard1[0],
        endDate: dateStringCard1[1],
      }),
    )
      .unwrap()
      .then((res) => {
        res.data.totalCases > 0 &&
          setDataTotalCases({
            ...res.data,
            data: res.data.data.filter((item: any) => item.totalCases > 0),
          });
      })
      .catch((error) => {
        message.error("Failed to get data for total cases number.");
      });
  };

  useEffect(() => {
    getApplicationProcessData();
    getTotalCasesData();
  }, []);

  useEffect(() => {
    if (selectedDateCard1[0] && selectedDateCard1[1]) {
      onGetTotalCase();
    }
  }, [selectedDateCard1, selectedDateTypeCard1]);

  // card 2 fetch data
  const onGetPerformanceData = async () => {
    let dateStringCard2 = selectedDateCard2;
    let selectedDates: any;
    // use startDate and endDate for date and week // use dates[] for month and year
    // dates[] is an array of start date separated by interval of month or year
    if (selectedDateTypeCard2 == "date" || selectedDateTypeCard2 == "week") {
      dateStringCard2 = onProcessDateToString(
        selectedDateCard2,
        selectedDateTypeCard2,
      );
      //console.log("date card 2 selected:", dateStringCard2);
    } else {
      selectedDates = onProcessDateToListOfStartDates(
        selectedDateCard2,
        selectedDateTypeCard2,
      );
      //console.log("date card 2 selected:", selectedDates);
    }

    let resPerformance: any;
    await dispatch(
      getDashboardCard2Data({
        startDate: dateStringCard2[0],
        endDate: dateStringCard2[1],
        status: selectedStatusCard2,
        dates: selectedDates,
        type: dateTypeMapDict[selectedDateTypeCard2],
      }),
    )
      .unwrap()
      .then((res) => {
        // setDataPerformance(res);
        resPerformance = res;
      })
      .catch((error) => {
        message.error("Failed to get data for performance.");
      });

    const resPerformanceSorted = {
      data: resPerformance.data.map((item: any) => ({
        ...item,
        data:
          item?.data?.sort(
            (a: any, b: any) =>
              Number(new Date(a.date)) - Number(new Date(b.date)),
          ) ?? [],
      })),
    };
    resPerformance && setDataPerformance(resPerformanceSorted);
  };

  const getPerformanceDataSum = (data: any, name: string) => {
    return Object.values(data).reduce(
      (a, b: any) => a + b.find((item: any) => item.name == name).total,
      0,
    );
  };

  const getPerformanceDataChildrenSum = (data: any, name: string) => {
    return Object.values(data).reduce((a: any, b: any) => {
      const tmpPrevData = a?.find((item: any) => item.name == name)?.data ?? a;
      const tmpData = b.find((item: any) => item.name == name).data;
      const sum = tmpPrevData.map((item: any, index: any) => {
        return {
          ...item,
          value:
            item.value +
            tmpData.find((i: { date: any }) => i.date == item.date).value,
        };
      });
      return sum;
    });
  };

  useEffect(() => {
    if (selectedDateCard2[0] && selectedDateCard2[1]) {
      onGetPerformanceData();
    }
  }, [selectedDateCard2, selectedDateTypeCard2, selectedStatusCard2]);

  // card 3 fetch data
  const onGetTotalInProgressCases = async () => {
    const dataInProgressCasesList: any[] = [];
    let totalCount = 0;
    await Promise.all(
      inProgressStatusList.map(async (status) => {
        await dispatch(getDashboardCard3Data({ status }))
          .unwrap()
          .then((res) => {
            // console.log(`card 3 ${status} data`, res.data[0]);
            // handle data count 0
            if (res.data[0]?.data?.length == 0) {
              res.data[0] = {
                status: res.data[0]?.status,
                data: [{ day: "", value: 0 }],
              };
            }
            const statusTotalCases = res.data[0]?.data?.reduce(
              (prev: number, next: { value: number }) => prev + next.value,
              0,
            );

            dataInProgressCasesList.push({
              ...res.data[0],
              total: statusTotalCases,
            });

            res.data[0]?.data?.map(
              (item: { value: number }) => (totalCount += item.value),
            );
          })
          .catch((error) => {
            message.error(`Failed to get data for ${status} number of cases.`);
          });
      }),
    );
    setDataInProgressCases({
      data: dataInProgressCasesList,
      total: totalCount,
    });
  };

  useEffect(() => {
    onGetTotalInProgressCases();
  }, []);

  const data_pie = dataTotalCases?.data.map((item) => ({
    type: startCase(item.status),
    value: item.totalCases,
  }));

  const data_column: { name: any; date: any; value: any }[] = [];
  const data_column_stacked: {
    name: any;
    date: any;
    value: any;
    status?: string;
  }[] = [];

  if (selectedStatusCard2) {
    dataPerformance?.data?.map((itemParent: any) =>
      itemParent?.data?.map((item: any) =>
        data_column.push({
          name: startCase(itemParent.name),
          date: item.date,
          value: item.value,
        }),
      ),
    );

    dataPerformance?.data?.map((itemParent: any) =>
      itemParent?.data?.map((item: any) =>
        data_column_stacked.push({
          name: startCase(itemParent.name),
          date: item.date,
          value: item.value,
          status: selectedStatusCard2,
        }),
      ),
    );
  }

  registerTheme("custom-theme", {
    colors10: ["#ff3131", "#ffbaba", "#982f3f", "#dae6e6", "#c5d9d9"],
  });

  const data_performance_table: Record<string, any>[] = [];

  dataPerformanceTableOrder?.map((name: any) => {
    const itemParent = dataPerformance?.data.find((item) => item.name === name);
    const tmpArray: Record<string, any> = {};
    tmpArray["type"] = startCase(itemParent?.name);
    itemParent?.data?.map((item: any) => {
      tmpArray[item.date] = item.value;
    });
    data_performance_table.push(tmpArray);
  });

  const columns_performance_table: ColumnsType<any> = [
    {
      title: "type",
      dataIndex: "type",
      key: "type",
      render: (text: string) => (
        <>
          <Col>
            <div
              className={text === "Total Loan Amount" ? "square" : "line"}
              style={{
                backgroundColor:
                  text === "Total Loan Amount" ? "#ff3131" : "#d9d9d9",
              }}
            />{" "}
            <span style={{ fontSize: 15 }}>{text}</span>
          </Col>
        </>
      ),
      width: "18%",
    },
  ];
  // add dynamic columns
  Object.keys(data_performance_table?.[0]).forEach((key) => {
    if (key !== "type") {
      columns_performance_table.push({
        title: key,
        dataIndex: key,
        key: key,
        align: "center",
        width:
          (100 - 18) / Object.keys(data_performance_table?.[0]).length + "%",
        render: (text: string) => <>{numberWithCommas(text)}</>,
      });
    }
  });

  const rangePresets: TimeRangePickerProps["presets"] =
    selectedDateTypeCard1 === "date"
      ? [
          { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
          { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
          { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
          { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
        ]
      : [];

  const dateFilterComponent = (
    pickerStyle: any,
    onChangeDate: any,
    value: any,
  ) => {
    return (
      <RangePicker
        picker={pickerStyle}
        presets={rangePresets}
        onChange={onChangeDate}
        value={value}
        style={{ width: 250 }}
      />
    );
  };

  // handle download in progress status report
  const handleDownloadFile = (res: any) => {
    const url = window.URL.createObjectURL(new Blob([res.content]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", res.filename ?? "");
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }, 100);
  };

  // Download application process report
  const onDownloadApplicationProcessReport = (status: string) => {
    dispatch(
      downloadApplicationProcessReportThunk({
        applicationProcessStatus: status
          .toUpperCase()
          .replace("-", "")
          .replace(" ", "_"),
      }),
    )
      .unwrap()
      .then((res) => {
        handleDownloadFile(res);
        message.success(`Successfully download ${status} report.`);
      })
      .catch((err) =>
        message.error(
          `Failed to download ${status} report. Please try again later.`,
        ),
      );
  };

  const applicationProcessCasesCard = (
    name: string,
    numberOfCases: string | number,
  ) => (
    <Col xs={24} sm={12} xl={4} className="w-full">
      {/* <h4 className="mb-0">{name}</h4> */}
      <Text
        underline
        strong
        style={{ cursor: "pointer" }}
        onClick={() => onDownloadApplicationProcessReport(name)}
      >
        {name}
      </Text>
      <p>
        Number of Cases:{" "}
        {numberWithCommas(numberOfCases)?.replace(/\.(.*?[0]*)/g, "")}
      </p>
    </Col>
  );

  const totalCasesProcessCard = (name: string, total: string | number) => (
    <Col xs={24} sm={12} xl={4} className="w-full">
      {/* <h4 className="mb-0">{name}</h4> */}
      <Text underline strong>
        {name}
      </Text>
      <p>Total: {numberWithCommas(total)?.replace(/\.(.*?[0]*)/g, "")}</p>
    </Col>
  );

  const onChangeDefaultDate = (dataType?: string, defaultRange?: number[]) => {
    switch (dataType) {
      case "date":
        return [dayjs().add(defaultRange ? defaultRange[0] : -7, "d"), dayjs()];
      case "week":
        return [dayjs().add(defaultRange ? defaultRange[1] : -4, "w"), dayjs()];
      case "month":
        return [dayjs().add(defaultRange ? defaultRange[2] : -5, "M"), dayjs()];
      case "year":
        return [dayjs().add(defaultRange ? defaultRange[3] : -3, "y"), dayjs()];
      default:
        return [dayjs().add(-7, "d"), dayjs()];
    }
  };

  const onChangeDateCard1 = (date: any, dateType?: string) => {
    const defaultDate = onChangeDefaultDate(dateType, [
      dayjs().daysInMonth(),
      0,
      0,
      0,
    ]);
    setSelectedDateCard1(date ?? defaultDate);
  };

  const onChangeDateCard2 = (date: any, dateType?: string) => {
    const defaultDate = onChangeDefaultDate(dateType, [-7, -4, -5, -3]);
    setSelectedDateCard2(date ?? defaultDate);
  };

  const onProcessDateToString = (date: any, dataType: string) => {
    switch (dataType) {
      case "date":
      case "week":
      case "month":
      case "year": {
        const startDate = date[0].startOf(dataType).format();
        const endDate = date[1].endOf(dataType).format();
        return [startDate, endDate];
      }

      default: {
        return date;
      }
    }
  };

  const onProcessDateToListOfStartDates = (
    date: [Dayjs, Dayjs],
    dateType: any,
  ) => {
    const startDate = date[0].startOf(dateType).format();
    const endDate = date[1].endOf(dateType).format();
    const dateList = [];
    let current = dayjs(startDate);
    while (current.isBefore(endDate)) {
      dateList.push({
        startDate: current.startOf(dateType).format(),
        endDate: current.endOf(dateType).format(),
      });
      current = current.add(1, dateType);
    }
    return dateList;
  };

  if (
    !(
      dataTotalCases.totalCases ||
      dataPerformance.data.length > 1 ||
      dataInProgressCases.data.length > 1
    )
  ) {
    return (
      <div className="h-screen m-auto">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Dashboard - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Dashboard" />

      <Row gutter={[16, 16]} className="mb-4">
        <Col className="w-full">
          <Card title={<h4 className="mb-0">Total Cases</h4>}>
            <Row gutter={[16, 16]} justify="space-between" className="mb-4">
              {totalCases.map((item) =>
                totalCasesProcessCard(item.name, item.total),
              )}
            </Row>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mb-4">
        <Col className="w-full">
          <Card title={<h4 className="mb-0">Application Process Cases</h4>}>
            <Row gutter={[16, 16]} justify="space-between" className="mb-4">
              {data_process_case.map((item) =>
                applicationProcessCasesCard(item.name, item.total),
              )}
            </Row>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mb-4">
        <Col lg={24} xl={16} className="w-full">
          <Card
            title={
              <h4 className="mb-0">
                Total Cases: {dataTotalCases.totalCases} (RM{" "}
                {numberWithCommas(dataTotalCases.totalLoanAmount)})
              </h4>
            }
          >
            <div className="flex gap-x-6 gap-y-2 justify-end items-center mb-4 flex-wrap">
              <Radio.Group
                style={{ minWidth: 260 }}
                value={selectedDateTypeCard1}
                onChange={(e: RadioChangeEvent) => {
                  setSelectedDateTypeCard1(e.target.value);
                  onChangeDateCard1(null, e.target.value);
                }}
              >
                <Radio.Button value="date">Day</Radio.Button>
                <Radio.Button value="week">Week</Radio.Button>
                <Radio.Button value="month">Month</Radio.Button>
                <Radio.Button value="year">Year</Radio.Button>
              </Radio.Group>
              {dateFilterComponent(
                selectedDateTypeCard1,
                onChangeDateCard1,
                selectedDateCard1,
              )}
            </div>
            <Pie
              data={data_pie}
              angleField="value"
              colorField="type"
              color={["#ff3131", "#982f3f", "#ffbaba", "#dae6e6", "#c5d9d9"]}
              style={{ fontSize: 30, height: 350 }}
              label={{
                type: "outer",
                content: "{name} {percentage}",
                labelLine: false,
              }}
              interactions={[
                { type: "pie-legend-active" },
                { type: "element-active" },
              ]}
              appendPadding={15}
              className="my-9"
            />
          </Card>
        </Col>
        <Col lg={24} xl={8} className="w-full">
          <Row gutter={[16, 16]}>
            {dataTotalCases?.data.map((item) => (
              <Col xs={24} md={12} lg={12} xl={24} key={nanoid()}>
                <CasesCard
                  status={item.status}
                  totalCases={
                    dataTotalCases?.data?.find((i) => i.status === item.status)
                      ?.totalCases ?? 0
                  }
                  totalLoanAmount={
                    item.status !== "inProgress"
                      ? dataTotalCases?.data?.find(
                          (i) => i.status === item.status,
                        )?.totalLoanAmount ?? 0
                      : dataTotalCases?.data?.find(
                          (i) => i.status === item.status,
                        )?.loanApplied ?? 0
                  }
                  totalInterestAmount={
                    dataTotalCases?.data?.find((i) => i.status === item.status)
                      ?.totalInterestAmount ?? 0
                  }
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mb-4">
        <Col lg={24} xl={18} className="w-full">
          <Card title={<h4 className="mb-0">Performance</h4>}>
            <div className="flex gap-x-6 gap-y-2 items-center mb-4 flex-wrap">
              <Select
                defaultValue={selectedStatusCard2}
                style={{ width: 170 }}
                onChange={(value) => setSelectedStatusCard2(value)}
                options={[
                  ...Object.entries(performanceStatusMapDict).map(
                    ([key, value]) => ({
                      value: key,
                      label: value,
                    }),
                  ),
                ]}
              />

              <Radio.Group
                style={{ minWidth: 260 }}
                value={selectedDateTypeCard2}
                onChange={(e: RadioChangeEvent) => {
                  setSelectedDateTypeCard2(e.target.value);
                  onChangeDateCard2(null, e.target.value);
                }}
              >
                <Radio.Button value="date">Day</Radio.Button>
                <Radio.Button value="week">Week</Radio.Button>
                <Radio.Button value="month">Month</Radio.Button>
                <Radio.Button value="year">Year</Radio.Button>
              </Radio.Group>
              {dateFilterComponent(
                selectedDateTypeCard2,
                onChangeDateCard2,
                selectedDateCard2,
              )}
            </div>

            <Row>
              <Col push={4} span={20} className="w-full">
                {/* <Column
                  data={data_column}
                  xField="date"
                  yField="value"
                  seriesField="name"
                  isGroup={true}
                  color={["#ff3131", "#d9d9d9"]}
                  className="mb-4"
                  legend={false}
                  tooltip={false}
                /> */}
                <DualAxes
                  data={[
                    data_column_stacked
                      .filter((item) => item.name === "Total Loan Amount")
                      .map((item) => ({
                        ...item,
                        name: item.name.toUpperCase(),
                      })),
                    data_column
                      .filter((item) => item.name !== "Total Loan Amount")
                      .map((item) => ({
                        ...item,
                        name: item.name.toUpperCase(),
                      })),
                  ]}
                  xField="date"
                  yField={["value", "value"]}
                  geometryOptions={[
                    {
                      geometry: "column",
                      isStack: true,
                      seriesField: "name",
                    },
                    {
                      geometry: "line",
                      seriesField: "name",
                      color: ["#d9d9d9", "#ffbaba"],
                    },
                  ]}
                  tooltip={{
                    domStyles: { "g2-tooltip-title": { display: "none" } },
                    customItems: (items) =>
                      items.filter((item) => item?.data?.name !== ""),
                  }}
                  theme="custom-theme"
                />
              </Col>
            </Row>
            <Table
              columns={columns_performance_table}
              dataSource={data_performance_table}
              size="small"
              showHeader={false}
              pagination={false}
            />
          </Card>
        </Col>
        <Col lg={24} xl={6} className="w-full">
          <Row gutter={[16, 16]}>
            {dataPerformance?.data?.map((d: { name: string | undefined }) => (
              <Col xs={12} md={12} lg={8} xl={24} key={nanoid()}>
                {d?.name && (
                  <PerformanceCard
                    amountType={d.name}
                    value={
                      dataPerformance?.data?.find(
                        (i: { name: string | undefined }) => i.name === d.name,
                      )?.total ?? 0
                    }
                  />
                )}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mb-4">
        <Col span={24} className="w-full">
          <Card
            title={
              <h4 className="mb-0">
                Total In Progress Cases: {dataInProgressCases.total}
              </h4>
            }
          >
            <div className="flex justify-end">
              <Space size="middle">
                <Col>
                  <div
                    className="square"
                    style={{ backgroundColor: "#ADD9B5" }}
                  />{" "}
                  1-3 Days
                </Col>
                <Col>
                  <div
                    className="square"
                    style={{ backgroundColor: "#fd8b06" }}
                  />{" "}
                  4-6 Days
                </Col>
                <Col>
                  <div
                    className="square"
                    style={{ backgroundColor: "#ff3131" }}
                  ></div>{" "}
                  {">7 Days"}
                </Col>
              </Space>
            </div>
            <div className="my-4 flex justify-evenly gap-4 flex-wrap">
              {inProgressStatusList.map((status) =>
                dataInProgressCases.data.map((item) =>
                  item.status === status ? (
                    <CasePieChart
                      key={nanoid()}
                      status={
                        item.status === "ATTESTATION"
                          ? "LIVE_ATTESTATION"
                          : item.status
                      }
                      dayData={item.data}
                      countData={[
                        {
                          type: "Count",
                          value: item?.total,
                        },
                        {
                          type: "Others",
                          value: dataInProgressCases.total - item.total,
                        },
                      ]}
                      percentage={(
                        (Number(item.total) /
                          Number(dataInProgressCases.total)) *
                        100
                      ).toFixed(2)}
                      totalCases={item.total}
                    />
                  ) : null,
                ),
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DashboardPage;
