import { Button, Col, Form, message, Modal, Row, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/store";
import {
  getSmeApplicationDetailsThunk,
  patchSmeCreditRecordStatusBySmeApplicationIdAndTypeThunk,
} from "../../../../../../services/smeApplicationService/smeApplicationThunk";
import { SME_APPLICATION_STATUS } from "../../../../../../enum/smeApplicationStatus";

const STATUS_OPTIONS = ["APPROVED", "REJECTED"] as const;

export type UpdateCreditRecordModalProps = {
  title: string,
  value: string,
  inputKey: string
}

export const UpdateCreditRecordModal: React.FC<UpdateCreditRecordModalProps> = (props) => {
  const { title, inputKey, value } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const showModal = () => {
    setIsModalVisible(true);
    form.setFieldsValue({
      [inputKey]: value,
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      await dispatch(patchSmeCreditRecordStatusBySmeApplicationIdAndTypeThunk({
        id: smeApplicationDetails?.data?.id,
        type: inputKey,
        status: values[inputKey],
      })).unwrap();

      await dispatch(getSmeApplicationDetailsThunk({
        applicationId: smeApplicationDetails?.data?.id,
      }));

      message.success("Credit assessment updated successfully");
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error("Failed to update credit assessment");
      console.error("Failed to update credit assessment:", error);
    } finally {
      setLoading(false);
    }
  };

  const statusOptions = STATUS_OPTIONS.map((status) => ({
    label: status,
    value: status,
  }));

  if (
    smeApplicationDetails?.data?.status !== SME_APPLICATION_STATUS.PENDING &&
    smeApplicationDetails?.data?.status !== SME_APPLICATION_STATUS.SUBMITTED_FOR_VERIFIER_2 &&
    smeApplicationDetails?.data?.status !== SME_APPLICATION_STATUS.REFERBACK &&
    smeApplicationDetails?.data?.status !== SME_APPLICATION_STATUS.FOLLOWED_UP
  ) {
    return null; // Only allow to update if the application status is PENDING, SUBMITTED_FOR_VERIFIER_2, REFERBACK, or FOLLOWED_UP
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Update
      </Button>
      <Modal
        title="Update Credit Assessment"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 16]}>
            <Col span={12} key={inputKey}>
              <Form.Item
                label={title}
                name={inputKey}
                rules={[{ required: true, message: "Please select a status" }]}
              >
                <Select options={statusOptions} />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end gap-2">
            <Button onClick={handleCancel} disabled={loading}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
