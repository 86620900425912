import UploadedDocument from "../../../../components/UploadedDocument";
import { FileTypeEnum } from "../../../../enum/fileType";
import React, { useEffect } from "react";
import { Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { getRequiredFileByPartnerTypeThunk } from "../../../../services/smeFileService/smeFileThunk";
import { getFilesByEntityIdThunk } from "../../../../services/fileService/fileThunk";

const { Panel } = Collapse;

export const DocumentsPanel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );
  const { requiredFileByPartnerType, smeFilesByEntityId } = useSelector((state: RootState) => state.smeFile);

  const fetchRequiredFileByEntityId = () => {
    if(smeApplicationDetails.data.id) {
      dispatch(getRequiredFileByPartnerTypeThunk(smeApplicationDetails?.data?.organizationType));
    }
  }

  const fetchSmeFilesByEntityId = () => {
    if(smeApplicationDetails.data.id) {
      dispatch(getFilesByEntityIdThunk({ entityId: smeApplicationDetails?.data?.id }));
    }
  }

  useEffect(() => {
    fetchRequiredFileByEntityId()
    fetchSmeFilesByEntityId()
  }, [smeApplicationDetails]);

  return (
    <Collapse ghost expandIconPosition="end">
      <Panel
        key="documents"
        className="application-details-section-container"
        header={
          <h3 className="application-details-section-title">Documents</h3>
        }
      >
        {requiredFileByPartnerType?.data?.map((fileType) => {
          return (
            <UploadedDocument
              key={fileType.code}
              entityId={smeApplicationDetails?.data?.id}
              fileType={fileType.code as FileTypeEnum}
              description={fileType.value}
              defaultFileList={smeFilesByEntityId?.data?.[fileType.code]}
              callback={fetchSmeFilesByEntityId}
            />
          );
        })}
      </Panel>
    </Collapse>
  );
};