import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "./dashboardService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getDashboardCard1Data = createAsyncThunk(
  "dashboard/getDashboardCard1Data",
  async (requestData: any, thunkAPI) => {
    try {
      return await dashboardService.getDashboardCard1Data(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDashboardCard2Data = createAsyncThunk(
  "dashboard/getDashboardCard2Data",
  async (requestData: any, thunkAPI) => {
    try {
      return await dashboardService.getDashboardCard2Data(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDashboardCard3Data = createAsyncThunk(
  "dashboard/getDashboardCard3Data",
  async (requestData: any, thunkAPI) => {
    try {
      return await dashboardService.getDashboardCard3Data(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadApplicationProcessReportThunk = createAsyncThunk(
  "dashboard/downloadApplicationProcessReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await dashboardService.downloadApplicationProcessReport(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadApplicationStatusReportThunk = createAsyncThunk(
  "dashboard/downloadApplicationStatusReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await dashboardService.downloadApplicationStatusReport(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadAgreementStatusReportThunk = createAsyncThunk(
  "dashboard/downloadAgreementStatusReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await dashboardService.downloadAgreementStatusReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApplicationProcessDataThunk = createAsyncThunk(
  "dashboard/getApplicationProcessData",
  async (requestData, thunkAPI) => {
    try {
      return await dashboardService.getApplicationProcessData();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDistinctApplicationUserIdTotalThunk = createAsyncThunk(
  "dashboard/getDistinctApplicationUserIdTotal",
  async (requestData, thunkAPI) => {
    try {
      return await dashboardService.getDistinctApplicationUserIdTotal();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRegistrationCaseThunk = createAsyncThunk(
  "dashboard/getRegistrationCase",
  async (requestData, thunkAPI) => {
    try {
      return await dashboardService.getRegistrationCase();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getTotalCasesThunk = createAsyncThunk(
  "dashboard/getTotalCases",
  async (requestData, thunkAPI) => {
    try {
      return await dashboardService.getTotalCases();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
