import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

export const getPaymentNoticeBatchList = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smsPaymentNotification/SMSPaymentNoticelist`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData,
  });
  return res.data;
};

export const getPaymentNoticeBatchDetails = async (requestData: any) => {
  const params = {
    filters: requestData.filters,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination?.current,
    pageSize: requestData.pagination?.pageSize,
  };
  const url = `/redCash/api/v1.0/smsPaymentNotification/getSMSPaymentNoticeDetailsByID/batchID/${requestData.batchId}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

export const generateSingleNoticePDF = async (requestData: any) => {
  const { accountBatchID } = requestData;
  const url = `/redCash/api/v1.0/noticesGeneration/generateSingleNoticePDF/accountBatchID/${accountBatchID}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const generateAllNoticePDF = async (requestData: any) => {
  const { batchID } = requestData;
  const url = `/redCash/api/v1.0/noticesGeneration/generateAllNoticePDF/batchID/${batchID}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const generateCSVList = async (batchID: any) => {
  const url = `/redCash/api/v1.0/noticesGeneration/generateCSVList/batchID/${batchID}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const getPaymentNoticeConfig = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smsPaymentNotification/getPaymentNoticeConfigList`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const createPaymentNoticeConfig = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smsPaymentNotification/addPaymentNoticeConfig`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const updatePaymentNoticeConfig = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smsPaymentNotification/editPaymentNoticeConfig`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const deletePaymentNoticeConfig = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smsPaymentNotification/deletePaymentNoticeConfig/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.delete(url);
  return res.data;
};

const paymentNoticeService = {
  getPaymentNoticeBatchList,
  getPaymentNoticeBatchDetails,
  getPaymentNoticeConfig,
  createPaymentNoticeConfig,
  updatePaymentNoticeConfig,
  deletePaymentNoticeConfig,
};

export default paymentNoticeService;
