import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import smeAttestationService from "./smeAttestationService";

export const updateSmeLiveAttestationCompleteThunk = createAsyncThunk(
  "smeAttestation/updateSmeLiveAttestationComplete",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAttestationService.updateSmeLiveAttestationComplete(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);