import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Popconfirm, Space, Spin, Table, message } from "antd";
import { AppDispatch, RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { getApplicationDetailsThunk } from "../../services/applicationService/applicationThunk";
import applicationService from "../../services/applicationService/applicationService";
import { get } from "http";
import LoadingSpinner from "../LoadingSpinner";

const FinoryOcrTaskRef = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );

  const [finoryOcrUrl, setFinoryOcrUrl] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const getFinoryOcrTaskUrl = async () => {
    // Get finory embedded url
    setLoading(true);
    setOpenModal(true);
    await applicationService
      .getFinoryEmbeddedUrl(applicationDetails.data?.id ?? "-")

      .then((res: any) => {
        console.log(res);
        setFinoryOcrUrl(res);
        setLoading(false);
      });
  };

  const onFinish = async () => {
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleIFrameLoading = () => {
    message.loading("Loading...", 0.5);
  };

  return (
    <>
      {loading && (
        <>
          <LoadingSpinner />
        </>
      )}

      <Space>
        <Button type="primary" onClick={() => getFinoryOcrTaskUrl()}>
          Financial Summary
        </Button>

        <Modal
          title="User Finance Summary"
          okText="Confirm"
          open={openModal}
          onOk={onFinish}
          onCancel={handleCancel}
          confirmLoading={loading}
          width="100%"
          bodyStyle={{ height: 1000 }}
        >
          {loading ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <>
              {finoryOcrUrl.length > 0 ? (
                <iframe
                  src={finoryOcrUrl}
                  width="100%"
                  height="100%"
                  allowFullScreen
                  onLoad={handleIFrameLoading}
                ></iframe>
              ) : (
                <div>Something went wrong. Please try again later.</div>
              )}
            </>
          )}
        </Modal>
      </Space>
    </>
  );
};

export default FinoryOcrTaskRef;
