import "./index.less";

import { Tabs, TabsProps, Tag } from "antd";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import { AppDispatch, RootState } from "../../store/store";
import IndividualEInvoicePage from "./IndividualEInvoice";
import ConsolidateEInvoicePage from "./ConsolidatedEInvoice";
import {
  getConsolidatedCreditNoteListThunk,
  getConsolidatedEInvoiceListThunk, getIndividualCreditNoteListThunk,
  getSelfBilledEInvoiceListThunk,
} from "../../services/collectionService/collectionThunk";
import { EInvoiceSubmissionStatus } from "../../enum/eInvoice";
import IndividualCreditNotePage from "./IndividualCreditNote";
import ConsolidateCreditNotePage from "./ConsolidateCreditNote";

const EInvoiceListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");
  const { eInvoiceTotal } = useSelector((state: RootState) => state.collection);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: { status: EInvoiceSubmissionStatus.PENDING },
    }),
    [],
  );

  const items: TabsProps["items"] = [
    {
      key: "individual",
      label: (
        <span>
          Individual{" "}
          {eInvoiceTotal?.data?.individual.invalid != null && (
            <Tag>{eInvoiceTotal.data.individual.invalid}</Tag>
          )}
        </span>
      ),
      children: <IndividualEInvoicePage />,
    },
    {
      key: "consolidated",
      label: (
        <span>
          Consolidated{" "}
          {eInvoiceTotal?.data?.consolidate.invalid != null && (
            <Tag>{eInvoiceTotal.data.consolidate.invalid}</Tag>
          )}
        </span>
      ),
      children: <ConsolidateEInvoicePage />,
    },
    {
      key: "individualCreditNote",
      label: (
        <span>
          Individual Credit Note{" "}
          {eInvoiceTotal?.data?.individualCreditNote.invalid != null && (
            <Tag>{eInvoiceTotal.data.individualCreditNote.invalid}</Tag>
          )}
        </span>
      ),
      children: <IndividualCreditNotePage />,
    },
    {
      key: "consolidatedCreditNote",
      label: (
        <span>
          Consolidated Credit Note{" "}
          {eInvoiceTotal?.data?.consolidatedCreditNote.invalid != null && (
            <Tag>{eInvoiceTotal.data.consolidatedCreditNote.invalid}</Tag>
          )}
        </span>
      ),
      children: <ConsolidateCreditNotePage />,
    },
  ];

  const fetchIndividualInvoice = async (params?: any) => {
    await dispatch(getSelfBilledEInvoiceListThunk({ params }));
  };

  const fetchConsolidatedInvoice = async (params?: any) => {
    await dispatch(getConsolidatedEInvoiceListThunk({ params }));
  };

  const fetchIndividualCreditNote = async (params?: any) => {
    await dispatch(getIndividualCreditNoteListThunk({ params }));
  };

  const fetchConsolidatedCreditNote = async (params?: any) => {
    await dispatch(getConsolidatedCreditNoteListThunk({ params }));
  };

  const onTabChange = (key: string) => {
    if (key === "individual") {
      fetchIndividualInvoice(initialParams);
    } else if (key === "consolidated") {
      fetchConsolidatedInvoice(initialParams);
    } else if (key === "individualCreditNote") {
      fetchIndividualCreditNote(initialParams);
    } else if (key === "consolidatedCreditNote") {
      fetchConsolidatedCreditNote(initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchIndividualInvoice(params);
    fetchConsolidatedInvoice(params);
    fetchIndividualCreditNote(params);
    fetchConsolidatedCreditNote(params);
  });

  return (
    <>
      <Helmet>
        <title>e-Invoice - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="e-Invoice Listing" />
      <Tabs
        defaultActiveKey={activeTab ?? "individual"}
        items={items}
        onChange={onTabChange}
      />
    </>
  );
};

export default EInvoiceListingPage;
