import {
  Button,
  DatePicker,
  DatePickerProps,
  Input,
  message,
  Modal,
  Space,
  Table,
  TimeRangePickerProps,
} from "antd";
import React, { useEffect, useState } from "react";
import { displayDate } from "../../../utils/datetime.util";
import { useLocation, useNavigate } from "react-router-dom";

import { AppDispatch } from "../../../store/store";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  downloadReportThunk,
  generateAgingReportThunk,
  generateCollectionReportThunk,
  generateCustomerDetailsReportThunk,
  generateInterestChargesReportThunk,
  generateKpktReportThunk,
  generateLateChargesReportThunk,
  generateLoanCncReportThunk,
  generateLoanInsuranceReportThunk,
  generateLoanMaturityReportThunk,
  generateMasterListingReportThunk,
  generateMiscBillingReportThunk,
  generateOverDueReportThunk,
  generatePartnerReportThunk,
  generateSmeMonthlyDeductionReportThunk,
  generateStampDutyReportThunk,
} from "../../../services/reportService/reportThunk";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import startCase from "lodash.startcase";
import moment from "moment";
import { emailValidator } from "../../../utils/validation";
import { ReportTypeLabel } from "../../../constant/report";

const { RangePicker } = DatePicker;

type ReportTableProps = {
  dataSource: any;
  callback: any;
  initialParams: any;
  type: string;
};

const ReportTable: React.FC<ReportTableProps> = (props) => {
  const { dataSource, callback, initialParams, type } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [downloading, setDownloading] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [reportDate, setReportDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [email, setEmail] = useState("");

  const onDownloadReport = (id: any, fileName: string) => {
    setDownloading(true);
    dispatch(downloadReportThunk({ id }))
      .unwrap()
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 100);
        message.success("Successfully download report.");
      })
      .catch((error) =>
        message.error("Failed to download report. Please try again later."),
      )
      .finally(() => setDownloading(false));
  };
  const tablePagination = {
    total: dataSource?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "reportDate",
      key: "reportDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => onDownloadReport(record.fileId, record.fileName)}
          >
            Download
          </Button>
        </Space>
      ),
    },
  ];

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  const onChangeDate = (date: any) => {
    const selectedDate_ = {
      startDate: date[0].startOf("days").format(),
      endDate: date[1].endOf("days").format(),
    };
    callback({
      ...initialParams,
      filters: {
        startRangeDate: selectedDate_.startDate,
        endRangeDate: selectedDate_.endDate,
      },
    });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    callback({ ...initialParams, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const openDateModal = async () => {
    setIsDateModalOpen(true);
  };

  const generateReport = async () => {
    setIsDateModalOpen(false);

    // check the email input
    if (type === "AGING_REPORT") {
      emailValidator(email).catch((error) => {
        message.error("Invalid Email");

        return;
      });
    }

    switch (type) {
      case "MASTER_LISTING":
        await dispatch(
          generateMasterListingReportThunk({
            reportDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(reportDate).format("YYYY_MM_DD")}_report_${startCase(
                type,
              )}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));

        break;
      case "COLLECTION":
        await dispatch(
          generateCollectionReportThunk({
            startDate,
            endDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(startDate).format("YYYY_MM_DD")}-${moment(
                endDate,
              ).format("YYYY_MM_DD")}_report_${startCase(type)}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));
        break;

      case "KPKT":
        await dispatch(
          generateKpktReportThunk({
            startDate,
            endDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(startDate).format("YYYY_MM_DD")}-${moment(
                endDate,
              ).format("YYYY_MM_DD")}_report_${startCase(type)}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));
        break;

      case "INTEREST_CHARGE":
        await dispatch(
          generateInterestChargesReportThunk({
            startDate,
            endDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(startDate).format("YYYY_MM_DD")}-${moment(
                endDate,
              ).format("YYYY_MM_DD")}_report_${startCase(type)}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));
        break;

      case "MISC_BILLING":
        await dispatch(
          generateMiscBillingReportThunk({
            startDate,
            endDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(startDate).format("YYYY_MM_DD")}-${moment(
                endDate,
              ).format("YYYY_MM_DD")}_report_${startCase(type)}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));
        break;

      case "LOAN_MATURITY":
        await dispatch(
          generateLoanMaturityReportThunk({
            startDate,
            endDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(startDate).format("YYYY_MM_DD")}-${moment(
                endDate,
              ).format("YYYY_MM_DD")}_report_${startCase(type)}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));
        break;

      case "CUSTOMER_LOAN":
        await dispatch(
          generateLoanCncReportThunk({
            startDate,
            endDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(startDate).format("YYYY_MM_DD")}-${moment(
                endDate,
              ).format("YYYY_MM_DD")}_report_${startCase(type)}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));
        break;

      case "LATE_CHARGES":
        await dispatch(
          generateLateChargesReportThunk({
            startDate,
            endDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(startDate).format("YYYY_MM_DD")}-${moment(
                endDate,
              ).format("YYYY_MM_DD")}_report_${startCase(type)}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));
        break;

      case "PARTNER_INFO":
        await dispatch(
          generatePartnerReportThunk({
            startDate,
            endDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(startDate).format("YYYY_MM_DD")}-${moment(
                endDate,
              ).format("YYYY_MM_DD")}_report_${startCase(type)}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));
        break;

      case "OVERDUE":
        await dispatch(
          generateOverDueReportThunk({
            endDate: reportDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(reportDate).format("YYYY_MM_DD")}_report_${startCase(
                type,
              )}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));

        break;

      case "AGING_REPORT":
        await dispatch(
          generateAgingReportThunk({
            startDate: reportDate,
            email,
            isSave: false,
          }),
        )
          .unwrap()
          .then((res) => {
            message.info(
              "Report is being generated. Please check your email later.",
            );
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));

        break;

      case "CUSTOMER_DETAILS":
        await dispatch(
          generateCustomerDetailsReportThunk({
            startDate: reportDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(reportDate).format("YYYY_MM_DD")}_report_${startCase(
                type,
              )}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));

        break;

      case "STAMP_DUTY":
        await dispatch(
          generateStampDutyReportThunk({
            reportDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(reportDate).format("YYYY_MM_DD")}_report_${startCase(
                type,
              )}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));

        break;

      case "LOAN_INSURANCE":
        setDownloading(true);
        await dispatch(
          generateLoanInsuranceReportThunk({
            startDate: reportDate,
          }),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${moment(reportDate).format("YYYY_MM_DD")}_report_${startCase(
                type,
              )}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) =>
            message.error("Failed to download report. Please try again later."),
          )
          .finally(() => setDownloading(false));

        break;

      case "SME_MONTHLY_DEDUCTION":
        setDownloading(true);
        await dispatch(
          generateSmeMonthlyDeductionReportThunk(
            moment(reportDate).endOf("month").toISOString(),
          ),
        )
          .unwrap()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", res.name);
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
            }, 100);
            message.success("Successfully download report.");
          })
          .catch((error) => {
            console.log(error)
            message.error("Failed to download report.");
          })
          .finally(() => setDownloading(false));
        break;
    }
  };

  const reportDateOnChange: DatePickerProps["onChange"] = (
    date,
    dateString,
  ) => {
    setReportDate(dateString);
  };

  const rangeDateOnChange = (date: any) => {
    setStartDate(date[0].format("YYYY-MM-DD"));
    setEndDate(date[1].format("YYYY-MM-DD"));
  };

  useEffect(() => {
    console.log(type);
  }, [type]);

  return (
    <>
      <div className="flex justify-between mb-4">
        {type !== "KPKT" && (
          <>
            <Input.Group compact>
              <RangePicker
                presets={rangePresets}
                onChange={onChangeDate}
                style={{ width: 250 }}
              />
            </Input.Group>
          </>
        )}

        <Button
          type="primary"
          onClick={() => openDateModal()}
          loading={downloading}
        >
          Generate Report
        </Button>
      </div>

      <br />
      {downloading && (
        <div className="absolute w-full h-full z-50 backdrop-brightness-95">
          <LoadingSpinner />
        </div>
      )}

      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={dataSource?.data ?? []}
        loading={dataSource?.isLoading}
        onChange={handleTableChange}
      />
      <Modal
        title="Please pick a date"
        open={isDateModalOpen}
        onCancel={() => setIsDateModalOpen(false)}
        onOk={() => {
          generateReport();
        }}
      >
        {/*Display Range Picker*/}
        {(type === "COLLECTION" ||
          type === "KPKT" ||
          type === "INTEREST_CHARGE" ||
          type === "MISC_BILLING" ||
          type === "LOAN_MATURITY" ||
          type === "CUSTOMER_LOAN" ||
          type === "LATE_CHARGES" ||
          type === "PARTNER_INFO" ||
          type === "CUSTOMER_LOAN_INTEREST") && (
          <RangePicker
            presets={rangePresets}
            onChange={rangeDateOnChange}
            style={{ width: 250 }}
          />
        )}

        {/*Display Date Picker*/}
        {(type === "MASTER_LISTING" ||
          type === "OVERDUE" ||
          type === "AGING_REPORT" ||
          type === "CUSTOMER_DETAILS" ||
          type === "STAMP_DUTY" ||
          type === "LOAN_INSURANCE") && (
          <DatePicker onChange={reportDateOnChange} style={{ width: "100%" }} />
        )}

        {(type === "SME_MONTHLY_DEDUCTION") && (
          <DatePicker onChange={reportDateOnChange} picker="month" style={{ width: "100%" }} />
        )}

        <br />

        {type === "AGING_REPORT" && (
          <>
            <Input
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              allowClear={true}
              style={{ width: "100%", marginTop: "10px" }}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default ReportTable;
