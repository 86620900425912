import {
  Button,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "@ant-design/pro-components";

import {
  deleteProductThunk,
  deleteSmeProductByIdThunk,
  getSmeProductsThunk,
} from "../../services/productService/productThunk";
import { AppDispatch, RootState } from "../../store/store";
import SmeProductFormModal from "./SmeProductFormModal";

import type { ColumnsType } from "antd/es/table";
import type { TablePaginationConfig } from "antd/es/table";

const { Option } = Select;
const { Search } = Input;

const SmeProductMaintenancePage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { smeProducts } = useSelector((state: RootState) => state.product);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("code");

  const tablePagination = {
    total: smeProducts.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Referral Code",
      dataIndex: "referralCode",
      key: "referralCode",
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space>
          {!smeProducts.isLoading && (
            <SmeProductFormModal
              key={record?.id}
              product={record}
              callback={() => fetchSmeProducts(initialParams)}
            />
          )}
          <Popconfirm
            title="Do you want to cancel this product?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" loading={deleteLoading} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const fetchSmeProducts = async (params?: any) => {
    await dispatch(getSmeProductsThunk({ params }));
  };

  const onSearch = (value: string) => {
    fetchSmeProducts({
      ...initialParams,
      // filters: { ...filterState, [searchFiled]: value },
      search: { [searchFiled]: value },
    });
    // setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchSmeProducts({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const onDelete = async (productId: string) => {
    setDeleteLoading(true);
    await dispatch(deleteSmeProductByIdThunk({ productId }))
      .unwrap()
      .then(() => {
        message.success("Deleted");
        fetchSmeProducts(initialParams);
      })
      .finally(() => setDeleteLoading(false));
  };

  useEffect(() => {
    fetchSmeProducts(initialParams);
    console.log(smeProducts.data);
  }, [initialParams]);

  return (
    <>
      <Helmet>
        <title>SME Product Config - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="SME Product Config" />
      <Row justify="space-between">
        <Col>
          <Input.Group compact>
            <Select
              defaultValue="code"
              style={{ width: 200 }}
              onChange={(value) => setSearchFiled(value)}
            >
              {Array.isArray(columns) &&
                columns
                  .filter((col: any) => col.key !== "action")
                  .map((col: any) => (
                    <Option key={col.key} value={col.dataIndex}>
                      {col.title?.toString()}
                    </Option>
                  ))}
            </Select>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </Input.Group>
        </Col>
        <Col>
          <SmeProductFormModal
            callback={() => fetchSmeProducts(initialParams)}
          />
        </Col>
      </Row>

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={smeProducts.data.data}
        loading={smeProducts.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default SmeProductMaintenancePage;
