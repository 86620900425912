import { createAsyncThunk } from "@reduxjs/toolkit";

import trustSignerService from "./trustSignerService";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import fileService from "../fileService/fileService";

export const getTrustSignersThunk = createAsyncThunk(
  "trustSigner/get",
  async (requestData: any, thunkAPI) => {
    try {
      return await trustSignerService.getTrustSigners(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createTrustSignerThunk = createAsyncThunk(
  "trustSigner/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await trustSignerService.createTrustSigner(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateTrustSignerThunk = createAsyncThunk(
  "trustSigner/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await trustSignerService.updateTrustSigner(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteTrustSignerThunk = createAsyncThunk(
  "trustSigner/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await trustSignerService.deleteTrustSigner(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSignatureFilesThunk = createAsyncThunk(
  "trustSigner/getSignatureFiles",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
