import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Popconfirm,
  Row,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../../../../store/store";
import { LoanInsuranceCoverage } from "../../../../../features/loanInsurance/loanInsuranceSlice";
import {
  createLoanInsuranceCoverageThunk,
  deleteLoanInsuranceCoverageByIdThunk,
  updateLoanInsuranceCoverageByIdThunk,
} from "../../../../../services/masterService/masterThunk";

const LoanInsuranceCoverageModal: React.FC<{
  loanInsuranceProductId?: string;
  data?: LoanInsuranceCoverage;
  callback?: () => void;
}> = ({ loanInsuranceProductId, data, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const updateMode = data != null;

  const labelClassName = "w-[100px]";

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        let payload;
        if (updateMode) {
          payload = {
            ...values,
            id: data.id,
          };
          dispatch(updateLoanInsuranceCoverageByIdThunk(payload))
            .unwrap()
            .then(() => {
              form.resetFields();
              if (callback) callback();
              message.success("Update Successfully");
              handleCancel();
            })
            .catch(() => message.error(`Error save loan insurance coverage`))
            .finally(() => setLoading(false));
        } else {
          payload = {
            ...values,
            loanInsuranceProductId,
          };

          dispatch(createLoanInsuranceCoverageThunk(payload))
            .unwrap()
            .then(() => {
              form.resetFields();
              if (callback) callback();
              message.success("Create Successfully");
              handleCancel();
            })
            .catch(() => message.error(`Error create loan insurance coverage`))
            .finally(() => setLoading(false));
        }
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
        setLoading(false);
      });
  };

  const onDeleteLoanInsuranceCoverage = async () => {
    if (data !== undefined) {
      setLoading(true);
      await dispatch(deleteLoanInsuranceCoverageByIdThunk({ id: data.id }))
        .unwrap()
        .then()
        .catch(() => message.error(`Error delete loan insurance coverage`))
        .finally(() => {
          setLoading(false);
          if (callback) callback();
        });
    }
  };

  return (
    <>
      {updateMode ? (
        <>
          <Space>
            <Button icon={<EditOutlined />} onClick={showModal}>
              View
            </Button>
            <Popconfirm
              title="Delete this loan insurance coverage?"
              onConfirm={() => onDeleteLoanInsuranceCoverage()}
              okText="Yes"
              cancelText="No"
            >
              <Button>Delete</Button>
            </Popconfirm>
          </Space>
        </>
      ) : (
        <Row justify="end" className="mb-2" align="bottom">
          <Button icon={<PlusOutlined />} onClick={showModal}>
            New
          </Button>
        </Row>
      )}

      <Modal
        title={
          data != null
            ? "Update Loan Insurance Coverage"
            : "Create Loan Insurance Coverage"
        }
        okText={data != null ? "Save" : "Add New"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={450}
      >
        <Form
          className="pt-4 pb-2"
          form={form}
          initialValues={data}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Coverage</span>}
            name="coverageName"
            rules={[{ required: true, message: "Please enter coverage name." }]}
          >
            <Input style={{ width: "100%" }} placeholder="Please Input" />
          </Form.Item>

          {!updateMode && (
            <>
              <Form.Item
                className="mb-4"
                label={<span className={labelClassName}>Coverage Code</span>}
                name="coverageCode"
                rules={[
                  { required: true, message: "Please enter coverage code." },
                ]}
              >
                <Input style={{ width: "100%" }} placeholder="Please Input" />
              </Form.Item>
            </>
          )}

          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Amount (RM)</span>}
            name="coverageAmount"
            rules={[
              { required: true, message: "Please enter coverage amount." },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Please Input"
              controls={false}
              inputMode="numeric"
              type="number"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LoanInsuranceCoverageModal;
