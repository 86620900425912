import { createSlice } from "@reduxjs/toolkit";

import {
  getAdminByIdThunk,
  getAdminsThunk,
} from "../../services/adminService/adminThunk";

export type Admin = {
  id: string;
  username: string;
  password?: number;
  passwordSalt: number;
  passwordHash: string;
  companyId: string;
  role: string;
  createdAt?: string;
  updatedAt?: string;
};

export type AdminState = {
  admins: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Admin[];
  };
  admin: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      admin: Admin;
    };
  };
};

const initialState: AdminState = {
  admins: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  admin: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      admin: {
        id: "",
        username: "",
        passwordSalt: 0,
        passwordHash: "",
        companyId: "",
        role: "",
      },
    },
  },
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    adminStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminsThunk.pending, (state) => {
        state.admins.isLoading = true;
      })
      .addCase(getAdminsThunk.fulfilled, (state, action) => {
        state.admins.isLoading = false;
        state.admins.data = action.payload;
      })
      .addCase(getAdminsThunk.rejected, (state, action) => {
        state.admins.isLoading = false;
        state.admins.isError = true;
        state.admins.errorMessage = action.payload;
      });

    builder
      .addCase(getAdminByIdThunk.pending, (state) => {
        state.admin.isLoading = true;
      })
      .addCase(getAdminByIdThunk.fulfilled, (state, action) => {
        state.admin.isLoading = false;
        state.admin.data = action.payload;
      })
      .addCase(getAdminByIdThunk.rejected, (state, action) => {
        state.admin.isLoading = false;
        state.admin.isError = true;
        state.admin.errorMessage = action.payload;
      });
  },
});

export const { adminStateReset } = adminSlice.actions;

export default adminSlice.reducer;
