import { Button, Modal } from "antd";
import { useState } from "react";

export type EmailMessageContentProps = {
  letterType: string;
};

const EmailMessageContent: React.FC<any> = (
  props: EmailMessageContentProps,
) => {
  const { letterType } = props;
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        View
      </Button>

      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        width={1500}
        footer={null}
      >
        <p>{letterType}</p>
      </Modal>
    </>
  );
};
export default EmailMessageContent;
