import { createSlice } from "@reduxjs/toolkit";
import {
  getVoidWaiverThunk,
  getAllWaiverThunk,
  getOpenWaiverThunk,
  getReferbackWaiverThunk,
  getPendingWaiverThunk,
  getApprovedWaiverThunk,
  getBatchNoThunk,
  getWaiverDetailsByIdThunk,
  getAgreementListThunk,
  getWaiverRemarksThunk,
} from "../../services/waiverService/waiverThunk";
import { Application } from "../application/applicationSlice";
import { AccountCharges } from "../collection/collectionSlice";
import { getWaiverFileByEntityIdThunk } from "../../services/fileService/fileThunk";

export type UploadedFile = {
  id: string;
  entityId: string;
  filename: string;
  originalName: string;
  mimeType: string;
  fileTypeId: string;
  fileType: {
    id: string;
    name: string;
    description?: string;
    fileSizeLimit?: number;
    createdAt?: string;
    updatedAt?: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type waiverData = {
  id: string;
  batchNo: string;
  status: string;
  totalNoOfAccount: number;
  totalWaiverAfterTax: number;
  createdAt?: string;
  updatedAt?: string;
  createdBy: string;
  decidedBy: string;
  verifier: string;
};

export type BatchNo = {
  id: string;
  batchNo: string;
  status: string;
  totalNoOfAccount: number;
  totalWaiverAfterTax: number;
  createdAt?: string;
  updatedAt?: string;
  createdBy: string;
  decidedBy: string;
  verifier: string;
};

export type waiverRemark = {
  id: string;
  remarks: string;
  waiverBatchId: string;
  userId: string;
  createdAt?: string;
  updatedAt?: string;
};

export type waiverDataResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: waiverData[];
};

export type BatchNoResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: BatchNo[];
};

export type waiverRemarkDataResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: waiverRemark[];
};

export interface Agreement {
  id: string;
  companyId: string;
  agreementNo: string;
  agreementStatus: string;
  loanTenure: number;
  loanInterestRate: number;
  firstInstalmentDate: string;
  monthlyInstalmentDay: number;
  monthlyInstalmentAmount: number;
  lastInstalmentAmount: number;
  loanPayableAmount: number;
  signDate: string;
  repaymentOption: string;
  applicationId: string;
  stampingDate: string;
  createdAt: string;
  updatedAt: string;
  application: Application;
  accountCharges: AccountCharges;
}

export interface AgreementListResponse {
  data: readonly any[] | undefined;
  Agreement?: Agreement;
}

export type waiverState = {
  allWaiverListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: waiverDataResponse;
  };
  openWaiver: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: waiverDataResponse;
  };
  pendingWaiver: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: waiverDataResponse;
  };
  referbackWaiver: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: waiverDataResponse;
  };
  voidWaiver: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: waiverDataResponse;
  };
  approvedWaiver: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: waiverDataResponse;
  };
  batchNo: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: BatchNoResponse;
  };
  agreementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: AgreementListResponse;
  };
  waiverRemark: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: waiverRemarkDataResponse;
  };
  waiverDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: object;
  };
  waiverFile: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: UploadedFile[];
  };
};

const initialState: waiverState = {
  allWaiverListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  openWaiver: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  pendingWaiver: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  referbackWaiver: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  voidWaiver: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  approvedWaiver: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  batchNo: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  agreementList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  waiverRemark: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  waiverDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: undefined,
    },
  },
  waiverFile: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const waiverSlice = createSlice({
  name: "waiver",
  initialState,
  reducers: {
    waiverStateReset: () => initialState,
    waiverBatchNoStateReset: (state) => {
      state.batchNo = initialState.batchNo;
    },
    waiverRemarkStateReset: (state) => {
      state.waiverRemark = initialState.waiverRemark;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWaiverThunk.pending, (state) => {
        state.allWaiverListing.isLoading = true;
      })
      .addCase(getAllWaiverThunk.fulfilled, (state, action) => {
        state.allWaiverListing.isLoading = false;
        state.allWaiverListing.data = action.payload;
      })
      .addCase(getAllWaiverThunk.rejected, (state, action) => {
        state.allWaiverListing.isLoading = false;
        state.allWaiverListing.isError = true;
        state.allWaiverListing.errorMessage = action.payload;
      });

    builder
      .addCase(getOpenWaiverThunk.pending, (state) => {
        state.openWaiver.isLoading = true;
      })
      .addCase(getOpenWaiverThunk.fulfilled, (state, action) => {
        state.openWaiver.isLoading = false;
        state.openWaiver.data = action.payload;
      })
      .addCase(getOpenWaiverThunk.rejected, (state, action) => {
        state.openWaiver.isLoading = false;
        state.openWaiver.isError = true;
        state.openWaiver.errorMessage = action.payload;
        state.openWaiver.data.data = [];
      });

    builder
      .addCase(getPendingWaiverThunk.pending, (state) => {
        state.pendingWaiver.isLoading = true;
      })
      .addCase(getPendingWaiverThunk.fulfilled, (state, action) => {
        state.pendingWaiver.isLoading = false;
        state.pendingWaiver.data = action.payload;
      })
      .addCase(getPendingWaiverThunk.rejected, (state, action) => {
        state.pendingWaiver.isLoading = false;
        state.pendingWaiver.isError = true;
        state.pendingWaiver.errorMessage = action.payload;
        state.pendingWaiver.data.data = [];
      });

    builder
      .addCase(getReferbackWaiverThunk.pending, (state) => {
        state.referbackWaiver.isLoading = true;
      })
      .addCase(getReferbackWaiverThunk.fulfilled, (state, action) => {
        state.referbackWaiver.isLoading = false;
        state.referbackWaiver.data = action.payload;
      })
      .addCase(getReferbackWaiverThunk.rejected, (state, action) => {
        state.referbackWaiver.isLoading = false;
        state.referbackWaiver.isError = true;
        state.referbackWaiver.errorMessage = action.payload;
        state.referbackWaiver.data.data = [];
      });
    builder
      .addCase(getVoidWaiverThunk.pending, (state) => {
        state.voidWaiver.isLoading = true;
      })
      .addCase(getVoidWaiverThunk.fulfilled, (state, action) => {
        state.voidWaiver.isLoading = false;
        state.voidWaiver.data = action.payload;
      })
      .addCase(getVoidWaiverThunk.rejected, (state, action) => {
        state.voidWaiver.isLoading = false;
        state.voidWaiver.isError = true;
        state.voidWaiver.errorMessage = action.payload;
        state.voidWaiver.data.data = [];
      });
    builder
      .addCase(getApprovedWaiverThunk.pending, (state) => {
        state.approvedWaiver.isLoading = true;
      })
      .addCase(getApprovedWaiverThunk.fulfilled, (state, action) => {
        state.approvedWaiver.isLoading = false;
        state.approvedWaiver.data = action.payload;
      })
      .addCase(getApprovedWaiverThunk.rejected, (state, action) => {
        state.approvedWaiver.isLoading = false;
        state.approvedWaiver.isError = true;
        state.approvedWaiver.errorMessage = action.payload;
        state.approvedWaiver.data.data = [];
      });
    builder
      .addCase(getBatchNoThunk.pending, (state) => {
        state.batchNo.isLoading = true;
      })
      .addCase(getBatchNoThunk.fulfilled, (state, action) => {
        state.batchNo.isLoading = false;
        state.batchNo.data = action.payload;
      })
      .addCase(getBatchNoThunk.rejected, (state, action) => {
        state.batchNo.isLoading = false;
        state.batchNo.isError = true;
        state.batchNo.errorMessage = action.payload;
        state.batchNo.data.data = [];
      });
    builder
      .addCase(getWaiverDetailsByIdThunk.pending, (state) => {
        state.waiverDetails.isLoading = true;
      })
      .addCase(getWaiverDetailsByIdThunk.fulfilled, (state, action) => {
        state.waiverDetails.isLoading = false;
        state.waiverDetails.data = action.payload.data;
      })
      .addCase(getWaiverDetailsByIdThunk.rejected, (state, action) => {
        state.waiverDetails.isLoading = false;
        state.waiverDetails.isError = true;
        state.waiverDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getAgreementListThunk.pending, (state) => {
        state.agreementList.isLoading = true;
      })
      .addCase(getAgreementListThunk.fulfilled, (state, action) => {
        state.agreementList.isLoading = false;
        state.agreementList.data = action.payload;
      })
      .addCase(getAgreementListThunk.rejected, (state, action) => {
        state.agreementList.isLoading = false;
        state.agreementList.isError = true;
        state.agreementList.errorMessage = action.payload;
      });

    builder
      .addCase(getWaiverRemarksThunk.pending, (state) => {
        state.waiverRemark.isLoading = true;
      })
      .addCase(getWaiverRemarksThunk.fulfilled, (state, action) => {
        state.waiverRemark.isLoading = false;
        state.waiverRemark.data = action.payload;
      })
      .addCase(getWaiverRemarksThunk.rejected, (state, action) => {
        state.waiverRemark.isLoading = false;
        state.waiverRemark.isError = true;
        state.waiverRemark.errorMessage = action.payload;
      });

    builder
      .addCase(getWaiverFileByEntityIdThunk.pending, (state) => {
        state.waiverFile.isLoading = true;
      })
      .addCase(getWaiverFileByEntityIdThunk.fulfilled, (state, action) => {
        state.waiverFile.isLoading = false;
        state.waiverFile.data = action.payload;
      })
      .addCase(getWaiverFileByEntityIdThunk.rejected, (state, action) => {
        state.waiverFile.isLoading = false;
        state.waiverFile.isError = true;
        state.waiverFile.errorMessage = action.payload;
      });
  },
});

export const {
  waiverStateReset,
  waiverBatchNoStateReset,
  waiverRemarkStateReset,
} = waiverSlice.actions;

export default waiverSlice.reducer;
