import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import {
  addAgreementToAoc,
  generateAocBatchNo,
  getAocBatch,
  getAocBatchById,
  getAocListing,
  getApprovedAoc,
  getPendingAoc,
  getReferbackAoc,
  geOpenAoc,
  getVoidAoc,
  submitAocForApprove,
  submitAocForPending,
  submitAocForReferback,
  submitAocForVoid,
  updateAocAgreementCharges,
  resubmitAocForPending,
  getAgreementByEmail,
} from "./aocService";

export const getAocBatchThunk = createAsyncThunk(
  "aoc/getBatchNo",
  async (requestData: any, thunkAPI) => {
    try {
      return await getAocBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateAocBatchThunk = createAsyncThunk(
  "aoc/createBatchNo",
  async (requestData: any, thunkAPI) => {
    try {
      return await generateAocBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const addAgreementToAocThunk = createAsyncThunk(
  "aoc/addAgreement",
  async (requestData: any, thunkAPI) => {
    try {
      return await addAgreementToAoc(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAllAocThunk = createAsyncThunk(
  "aoc/getAocListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getAocListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getOpenAocThunk = createAsyncThunk(
  "aoc/getOpenAoc",
  async (requestData: any, thunkAPI) => {
    try {
      return await geOpenAoc(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingAocThunk = createAsyncThunk(
  "aoc/getPendingAoc",
  async (requestData: any, thunkAPI) => {
    try {
      return await getPendingAoc(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferbackAocThunk = createAsyncThunk(
  "aoc/getReferbackAoc",
  async (requestData: any, thunkAPI) => {
    try {
      return await getReferbackAoc(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVoidAocThunk = createAsyncThunk(
  "aoc/getVoidAoc",
  async (requestData: any, thunkAPI) => {
    try {
      return await getVoidAoc(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApprovedAocThunk = createAsyncThunk(
  "aoc/getApprovedAoc",
  async (requestData: any, thunkAPI) => {
    try {
      return await getApprovedAoc(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAocBatchByIdThunk = createAsyncThunk(
  "aoc/getAocBatchById",
  async (requestData: any, thunkAPI) => {
    try {
      return await getAocBatchById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const submitAocForPendingThunk = createAsyncThunk(
  "aoc/submitAocForPending",
  async (requestData: any, thunkAPI) => {
    try {
      return await submitAocForPending(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const submitAocForVoidThunk = createAsyncThunk(
  "aoc/submitAocForVoid",
  async (requestData: any, thunkAPI) => {
    try {
      return await submitAocForVoid(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const submitAocForReferbackThunk = createAsyncThunk(
  "aoc/submitAocForReferback",
  async (requestData: any, thunkAPI) => {
    try {
      return await submitAocForReferback(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const submitAocForApproveThunk = createAsyncThunk(
  "aoc/submitAocForApproved",
  async (requestData: any, thunkAPI) => {
    try {
      return await submitAocForApprove(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateAocAgreementChargesThunk = createAsyncThunk(
  "aoc/updateAocAgreementCharges",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateAocAgreementCharges(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const resubmitAocForPendingThunk = createAsyncThunk(
  "aoc/resubmitAocForPending",
  async (requestData: any, thunkAPI) => {
    try {
      return await resubmitAocForPending(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAgreementByEmailThunk = createAsyncThunk(
  "aoc/getAgreementByEmail",
  async (requestData: any, thunkAPI) => {
    try {
      return await getAgreementByEmail(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
