import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import { getTaxService } from "./taxService";

export const getTaxThunk = createAsyncThunk(
  "tax/getList",
  async (requestData: any, thunkAPI) => {
    try {
      return await getTaxService();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
