import { Button, message, Popconfirm, Space, Table } from "antd";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLifecycles, useLocation } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import {
  deleteTrustSignerThunk,
  getTrustSignersThunk,
} from "../../services/trustSignerService/trustSignerThunk";
import { AppDispatch, RootState } from "../../store/store";
import TrustSignerFormModal from "./TrustSignerFormModal";

import type { ColumnsType } from "antd/es/table";
import type { TablePaginationConfig } from "antd/es/table";

const TrustSignerListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { trustSigners } = useSelector((state: RootState) => state.trustSigner);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");

  const tablePagination = {
    total: trustSigners.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (text ? "Active" : "Not Active"),
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space>
          <TrustSignerFormModal
            key={record.id}
            trustSigner={record}
            callback={() => fetchTrustSigners(initialParams)}
          />
          <Popconfirm
            title="Do you want to cancel this trustSigner?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" loading={deleteLoading} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const fetchTrustSigners = async (params?: any) => {
    await dispatch(getTrustSignersThunk({ params }));
  };

  const onSearch = (value: string) => {
    fetchTrustSigners({
      ...initialParams,
      filters: { ...filterState, [searchFiled]: value },
    });
    setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchTrustSigners({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const onDelete = async (trustSignerId: string) => {
    setDeleteLoading(true);
    await dispatch(deleteTrustSignerThunk({ trustSignerId }))
      .unwrap()
      .then(() => {
        message.success("Deleted");
        fetchTrustSigners(initialParams);
      })
      .finally(() => setDeleteLoading(false));
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchTrustSigners(params);
  });

  return (
    <>
      <Helmet>
        <title>Trust Signer Config - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title="Trust Signer Config"
        extra={
          <TrustSignerFormModal
            key="newTrustSignerForm"
            callback={() => fetchTrustSigners(initialParams)}
          />
        }
      />
      {/* uncomment below component if search is needed */}
      {/* <Input.Group compact>
            <Select
              defaultValue="name"
              style={{ width: 200 }}
              onChange={(value) => setSearchFiled(value)}
            >
              {Array.isArray(columns) &&
                columns
                  .filter((col: any) => col.key !== "action")
                  .map((col: any) => (
                    <Option key={col.key} value={col.dataIndex}>
                      {col.title?.toString()}
                    </Option>
                  ))}
            </Select>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </Input.Group> */}

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={trustSigners.data.data}
        loading={trustSigners.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TrustSignerListingPage;
