import { createAsyncThunk } from "@reduxjs/toolkit";

import attestationService from "./attestationService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getAttestationsThunk = createAsyncThunk(
  "attestation/getAttestations",
  async (requestData: any, thunkAPI) => {
    try {
      return await attestationService.getAttestations(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingAttestationsThunk = createAsyncThunk(
  "attestation/getPendingAttestations",
  async (requestData: any, thunkAPI) => {
    try {
      return await attestationService.getPendingAttestations(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRequestedLiveAttestationsThunk = createAsyncThunk(
  "attestation/getRequestedLiveAttestations",
  async (requestData: any, thunkAPI) => {
    try {
      return await attestationService.getRequestedLiveAttestations(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getCompletedLiveAttestationsThunk = createAsyncThunk(
  "attestation/getCompletedLiveAttestations",
  async (requestData: any, thunkAPI) => {
    try {
      return await attestationService.getCompletedLiveAttestations(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVideoAttestationsThunk = createAsyncThunk(
  "attestation/getVideoAttestations",
  async (requestData: any, thunkAPI) => {
    try {
      return await attestationService.getVideoAttestations(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateAttestationThunk = createAsyncThunk(
  "attestation/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await attestationService.updateAttestation(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
