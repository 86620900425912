import InfoGrid from "../../../../components/InfoGrid";
import React, { useState } from "react";
import { Collapse, Space } from "antd";
import { numberWithCommas } from "../../../../helpers/number";
import { SME_APPLICATION_STATUS } from "../../../../enum/smeApplicationStatus";
import {
  onCalculateEstimatedMonthlyAmount,
  onCalculateFinalInstalmentAmount,
  onCalculateStampingDutyFee,
  onCalculateTotalInstalmentAmount,
  onCalculateTotalInterest,
} from "../../../../utils/calculation.util";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { UpdateSmeApplicationAction } from "./UpdateSmeApplicationAction";
import UpdateFinanceAmountModal from "../UpdateFinanceAmountModal";
import { getSmeApplicationDetailsThunk } from "../../../../services/smeApplicationService/smeApplicationThunk";
import UpdateLoanTenureModal from "../UpdateLoanTenureModal";
import jwt_decode from "jwt-decode";
import { Role } from "../../../../enum/roles";
import UpdateInterestRateModal from "../UpdateInterestRateModal";

const { Panel } = Collapse;

export const ApplicationSummaryPanel: React.FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const fetchSmeApplicationDetails = () => {
    dispatch(getSmeApplicationDetailsThunk({ applicationId: smeApplicationDetails?.data?.id }));
  };

  const applicationSummaryInfo = [
    {
      title: "Finance Amount",
      value: (
        <Space size="middle">
          {`RM ${numberWithCommas(
            smeApplicationDetails?.data?.financeAmount ?? 0,
          )}`}
          {!readOnly &&
            smeApplicationDetails?.data?.status ===
              SME_APPLICATION_STATUS.PENDING && (
              <UpdateFinanceAmountModal
                applicationDetails={smeApplicationDetails?.data}
                callback={() => fetchSmeApplicationDetails()}
              />
            )}
        </Space>
      ),
    },
    {
      title: "Instalment Period",
      value: (
        <Space size="middle">
          {`${+smeApplicationDetails?.data?.appliedLoanTenure} months`}
          {!readOnly &&
            smeApplicationDetails?.data?.status ===
              SME_APPLICATION_STATUS.PENDING && (
              <UpdateLoanTenureModal
                applicationDetails={smeApplicationDetails?.data}
                callback={() => fetchSmeApplicationDetails()}
              />
            )}
        </Space>
      ),
    },
    {
      title: "Interest Rate",
      value: (
        <Space size="middle">
          {+smeApplicationDetails?.data?.appliedLoanInterestRate
            ? `${+smeApplicationDetails?.data?.appliedLoanInterestRate} %`
            : "-"}
          {!readOnly &&
            smeApplicationDetails?.data?.status ===
              SME_APPLICATION_STATUS.PENDING && (
              <UpdateInterestRateModal
                applicationDetails={smeApplicationDetails?.data}
                callback={() => fetchSmeApplicationDetails()}
              />
            )}
        </Space>
      ),
    },
    {
      title: "Interest Amount",
      value: `RM ${numberWithCommas(
        onCalculateTotalInterest(
          +smeApplicationDetails?.data?.financeAmount ?? 0,
          +smeApplicationDetails?.data?.appliedLoanInterestRate ?? 0,
          +smeApplicationDetails?.data?.appliedLoanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Total Instalment Amount",
      value: `RM ${numberWithCommas(
        onCalculateTotalInstalmentAmount(
          +smeApplicationDetails?.data?.financeAmount ?? 0,
          +smeApplicationDetails?.data?.appliedLoanInterestRate ?? 0,
          +smeApplicationDetails?.data?.appliedLoanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Monthly Instalment",
      value: `RM ${numberWithCommas(
        onCalculateEstimatedMonthlyAmount(
          +smeApplicationDetails?.data?.financeAmount ?? 0,
          +smeApplicationDetails?.data?.appliedLoanInterestRate ?? 0,
          +smeApplicationDetails?.data?.appliedLoanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Final Instalment Amount",
      value: `RM ${numberWithCommas(
        onCalculateFinalInstalmentAmount(
          +smeApplicationDetails?.data?.financeAmount ?? 0,
          +smeApplicationDetails?.data?.appliedLoanInterestRate ?? 0,
          +smeApplicationDetails?.data?.appliedLoanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Processing Fee",
      value: smeApplicationDetails?.data?.processingFee
        ? `RM ${numberWithCommas(
          smeApplicationDetails?.data?.processingFee,
        )}`
        : "-",
    },
    {
      title: "Stamp Duty Fee",
      value: smeApplicationDetails?.data?.stampingFee
        ? `RM ${numberWithCommas(smeApplicationDetails?.data?.stampingFee)}`
        : "-",
    },
    {
      title: "DSR",
      value: smeApplicationDetails?.data?.dsr ?? "-",
    },
  ];

  return (
    <Collapse ghost expandIconPosition="end" defaultActiveKey={currentRole === Role.APPROVER_2 ? ['applicationSummary'] : []}>
      <Panel
        key="applicationSummary"
        className="application-details-section-container"
        header={
          <h3 className="application-details-section-title">
            Application Summary
          </h3>
        }
      >
        <InfoGrid data={applicationSummaryInfo} />
        <div className="h-8" />
        {!readOnly && (
          <UpdateSmeApplicationAction
            smeApplicationDetails={smeApplicationDetails?.data}
          />
        )}
      </Panel>
    </Collapse>
  );
};
