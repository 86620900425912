import { beSetupAxiosInstance } from "./../../axios/beSetupAxios";

const getTrustSigners = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData.params?.filters
    },
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/trustSigner/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const createTrustSigner = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/trustSigner`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updateTrustSigner = async (requestData: any) => {
  const { trustSignerId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/trustSigner/${trustSignerId}`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteTrustSigner = async (requestData: any) => {
  const { trustSignerId } = requestData;
  const url = `/redCash/admin/api/v1.0/trustSigner/${trustSignerId}`;
  const res = await beSetupAxiosInstance.delete(url);
  return res.data;
};

const trustSignerService = {
  getTrustSigners,
  createTrustSigner,
  updateTrustSigner,
  deleteTrustSigner,
};

export default trustSignerService;
