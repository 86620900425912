import "./index.less";

import { Button, Form, InputNumber, message, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { ApplicationStatusEnum } from "../../../../enum/applicationStepStatus";
import { Application } from "../../../../features/application/applicationSlice";
import { AppDispatch } from "../../../../store/store";
import { updateApplicationFinanceAmountThunk } from "../../../../services/applicationService/applicationThunk";

type UpdateFinanceAmountModalProps = {
  applicationDetails?: Application;
  callback?: () => void;
};

const UpdateFinanceAmountModal: React.FC<UpdateFinanceAmountModalProps> = (
  props,
) => {
  const { applicationDetails, callback } = props;
  const dispatch = useDispatch<AppDispatch>();

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        const payload = { financeAmount: values.financeAmount };

        await dispatch(
          updateApplicationFinanceAmountThunk({
            applicationId: applicationDetails?.id,
            payload,
          }),
        )
          .unwrap()
          .then(() => {
            if (callback) callback();
            message.success("Update Successfully");
          });
      })
      .catch((err) => {
        message.error("Something went wrong. Please try again later.");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        disabled={
          applicationDetails?.applicationStatus ===
            ApplicationStatusEnum.APPROVED ||
          applicationDetails?.applicationStatus ===
            ApplicationStatusEnum.REJECTED
        }
        ghost
      >
        Update
      </Button>
      <Modal
        title="Update Finance Amount"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={360}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          initialValues={{
            financeAmount: applicationDetails?.financeAmount,
          }}
        >
          <Form.Item
            name="financeAmount"
            rules={[{ required: true, message: "Finance amount is required" }]}
          >
            <InputNumber
              placeholder="Please input"
              style={{ width: "100%" }}
              controls={false}
              inputMode="numeric"
              type={"number"}
              prefix="RM"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateFinanceAmountModal;
