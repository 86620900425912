import React, { useEffect } from "react";
import { Col, Collapse, Row, Table } from "antd";
import InfoList from "../../../../components/InfoList";
import { AppDispatch, RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getSmePartnerCommissionResultsThunk } from "../../../../services/smeApplicationService/smeApplicationThunk";
import { numberWithCommas } from "../../../../helpers/number";
import { ColumnsType } from "antd/es/table";
import { SmePartnerCommissionResultResponse } from "../../../../features/smePartner/smePartnerSlice";

const { Panel } = Collapse;

export const CreditAssessmentPanel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { smeApplicationDetails, smePartnerCommissionResult } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const creditAssessmentInfo1 = [
    {
      title: "Customer Due-D Individual (CDDI)",
      value: smeApplicationDetails?.data?.smeCreditRecords?.cddiStatus ?? "-",
      key: "cddiStatus",
    },
    {
      title: "Bankruptcy Check (NRVB) Report",
      value: smeApplicationDetails?.data?.smeCreditRecords?.nrvbStatus ?? "-",
      key: "nrvbStatus",
    },
    {
      title: "Business Intelligence (BI) - SSM",
      value: smeApplicationDetails?.data?.smeCreditRecords?.ssmStatus ?? "-",
      key: "ssmStatus",
    },
  ];

  const creditAssessmentInfo2 = [
    {
      title: "Customer Due-D Company (CDDC)",
      value: smeApplicationDetails?.data?.smeCreditRecords?.cddcStatus ?? "-",
      key: "cddcStatus",
    },
    {
      title: "Official Liquidated Or Wound Up Companies Report (OR)",
      value: smeApplicationDetails?.data?.smeCreditRecords?.orStatus ?? "-",
      key: "orStatus",
    },
    {
      title: "Enhanced C-Risk Score Intelligence (ECCRIS) with SSM",
      value: smeApplicationDetails?.data?.smeCreditRecords?.eCcrisStatus ?? "-",
      key: "eCcrisStatus",
    },
    {
      title: "Company Information (CI)",
      value: smeApplicationDetails?.data?.smeCreditRecords?.ciStatus ?? "-",
      key: "ciStatus",
    },
  ];

  const dateColumns = (smePartnerCommissionResult?.data && smePartnerCommissionResult?.data?.length > 0)
    ? Object.keys(smePartnerCommissionResult?.data[0]).filter(
      (key) => key !== "title" && key !== "average",
    )
    : [];

  const columns: ColumnsType<SmePartnerCommissionResultResponse> = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
      render: (value: string) => (<b>{value}</b>),
    },
    ...(dateColumns ? dateColumns.map((date) => ({
      title: date,
      dataIndex: date,
      key: date,
      render: (value: number, record: any) => {
        if (record.title === "New Active Lines (Direct)" || record.title === "New Active Lines (Group)") {
          return value;
        }
        return `RM ${numberWithCommas(value, 2)}`;
      },
    })) : []),
    {
      title: "Average",
      dataIndex: "average",
      key: "average",
      render: (value: number, record: any) => {
        if (record.title === "New Active Lines (Direct)" || record.title === "New Active Lines (Group)") {
          return value;
        }
        return `RM ${numberWithCommas(value, 2)}`;
      },
    },
  ];

  useEffect(() => {
    if (smeApplicationDetails?.data?.redOneUsername) {
      dispatch(getSmePartnerCommissionResultsThunk(smeApplicationDetails.data.redOneUsername));
    }
  }, [smeApplicationDetails?.data?.redOneUsername]);

  return (
    <>
      <Collapse ghost expandIconPosition="end">
        <Panel
          key="creditAssessment"
          className="application-details-section-container"
          header={
            <h3 className="application-details-section-title">
              Credit Assessment
            </h3>
          }
        >
          <Row gutter={[16, 16]} className="mb-3">
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <InfoList data={creditAssessmentInfo1} />
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <InfoList data={creditAssessmentInfo2} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {(smePartnerCommissionResult?.data && smePartnerCommissionResult?.data.length > 0) && (
                <Table
                  pagination={false}
                  rowKey="title"
                  className="min-w-fit"
                  columns={columns}
                  dataSource={smePartnerCommissionResult?.data}
                />
              )}
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};