import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import fieldVisitService from "./fieldVisitService";

export const getAllFieldVisitCompanyThunk = createAsyncThunk(
  "fieldVisit/getAllCompany",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getAllFieldVisitCompany(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getFieldVisitCompanyThunk = createAsyncThunk(
  "fieldVisit/getCompany",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getFieldVisitCompany(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAllFieldVisitOfficerThunk = createAsyncThunk(
  "fieldVisit/getAllOfficer",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getAllFieldVisitOfficer(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getFieldVisitOfficerByPostCodeThunk = createAsyncThunk(
  "fieldVisit/getFieldVisitOfficerByPostCode",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getFieldVisitOfficerByPostCode(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getOpenFieldVisitsThunk = createAsyncThunk(
  "fieldVisit/getOpen",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getOpenFieldVisits(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingFieldVisitsThunk = createAsyncThunk(
  "fieldVisit/getDraft",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getDraftFieldVisits(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getCloseFieldVisitsThunk = createAsyncThunk(
  "fieldVisit/getClose",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getCloseFieldVisits(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVisitedFieldVisitsThunk = createAsyncThunk(
  "fieldVisit/getVisited",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getVisitedFieldVisits(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getFieldVisitHistoriesThunk = createAsyncThunk(
  "fieldVisit/accountDetailsFieldVisitHistories",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getFieldVisitHistories(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getFieldVisitByIdThunk = createAsyncThunk(
  "fieldVisit/getById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getFieldVisitById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createFieldVisitThunk = createAsyncThunk(
  "fieldVisit/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.createFieldVisit(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createFieldVisitCompanyThunk = createAsyncThunk(
  "fieldVisit/createCompany",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.createFieldVisitCompany(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createFieldVisitOfficerThunk = createAsyncThunk(
  "fieldVisit/createOfficer",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.createFieldVisitOfficer(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateFieldVisitCompanyThunk = createAsyncThunk(
  "fieldVisit/updateCompany",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.updateFieldVisitCompany(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateFieldVisitThunk = createAsyncThunk(
  "fieldVisit/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.updateFieldVisit(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateFieldVisitDetailsThunk = createAsyncThunk(
  "fieldVisit/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.updateFieldVisitDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateFieldVisitOfficerThunk = createAsyncThunk(
  "fieldVisit/updateOfficer",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.updateFieldVisitOfficer(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getFieldVisitCompanyByStateThunk = createAsyncThunk(
  "fieldVisit/getCompanyByState",
  async (requestData: any, thunkAPI) => {
    try {
      return await fieldVisitService.getFieldVisitCompanyByState(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
