import { createAsyncThunk } from "@reduxjs/toolkit";

import productService from "./productService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getProductsThunk = createAsyncThunk(
  "product/get",
  async (requestData: any, thunkAPI) => {
    try {
      return await productService.getProducts(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createProductThunk = createAsyncThunk(
  "product/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await productService.createProduct(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateProductThunk = createAsyncThunk(
  "product/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await productService.updateProduct(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteProductThunk = createAsyncThunk(
  "product/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await productService.deleteProduct(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeProductsThunk = createAsyncThunk(
  "product/sme/get",
  async (requestData: any, thunkAPI) => {
    try {
      return await productService.getSmeProducts(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteSmeProductByIdThunk = createAsyncThunk(
  "product/sme/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await productService.deleteSmeProductById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmeProductThunk = createAsyncThunk(
  "product/sme/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await productService.createSmeProduct(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSmeProductThunk = createAsyncThunk(
  "product/sme/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await productService.updateSmeProduct(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
