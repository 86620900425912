import "./index.less";

import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createManualPaymentThunk,
  getPaymentHistoryThunk,
} from "../../../services/paymentService/paymentThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { getTransactionHistoryThunk } from "../../../services/transactionHistoryService/transactionHistoryThunk";

const InsertPaymentModal: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const { currentUser } = useSelector((state: RootState) => state.user);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onFinish = async () => {
    await form.validateFields().then((values) => {
      // setLoading(true);

      if (accountEnquiry.data?.agreementDetails?.id == null) {
        return message.error("Agreement not found!");
      }
      if (currentUser?.data?.username == null) {
        return message.error("Current user not found!");
      }

      handleCancel(); // no need await API response
      message.success("Request sent successfully");

      dispatch(
        createManualPaymentThunk({
          agreementId: accountEnquiry.data.agreementDetails.id,
          amount: values.amount,
          channel: values.channel,
          createdBy: currentUser.data.username,
          paymentDate: values.paymentDate,
          bank: values.bank,
          tranId: values.referenceNo,
          referenceNo: values.referenceNo,
        }),
      )
        .unwrap()
        .then((res) => {
          dispatch(
            getTransactionHistoryThunk({
              agreementId: accountEnquiry.data?.agreementDetails?.id,
            }),
          );
          dispatch(
            getPaymentHistoryThunk({
              agreementId: accountEnquiry.data?.accountDetails?.agreementId,
            }),
          );
        })
        .finally(() => {
          // handleCancel();
          // setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  return (
    <>
      <Button onClick={showModal}>Insert Payment</Button>
      <Modal
        centered
        title="Insert Payment"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={360}
        destroyOnClose
        okText="Insert Payment"
        confirmLoading={loading}
      >
        <Form
          form={form}
          name="insertPayment"
          layout="vertical"
          scrollToFirstError
          requiredMark={false}
        >
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Amount is required",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} controls={false} />
          </Form.Item>

          <Form.Item
            name="channel"
            label="Channel"
            rules={[{ required: true, message: "Payment Channel is required" }]}
          >
            <Select
              placeholder="Please Select"
              allowClear
              options={[
                { value: "RAZER_PAY", label: "Razer Pay" },
                { value: "JOMPAY", label: "JomPay" },
                { value: "EWALLET", label: "E-Wallet" },
                { value: "DIRECT_DEBIT", label: "Direct Debit" },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="paymentDate"
            label="Payment Date"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: "Payment date is required",
              },
            ]}
          >
            <DatePicker placeholder="Select Date" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="bank"
            label="Bank"
            rules={[{ required: true, message: "Bank is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="referenceNo"
            label="Reference No"
            rules={[{ required: true, message: "Reference No. is required" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default InsertPaymentModal;
