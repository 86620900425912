import { Button, Form, message, Modal, Space, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Table, { ColumnsType } from "antd/es/table";

import {
  DeleteOutlined,
  DownloadOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import { FileTypeEnum } from "../../../enum/fileType";
import {
  getWaiverFileByEntityIdThunk,
  waiverFileDeleteThunk,
  waiverFileUploadThunk,
} from "../../../services/fileService/fileThunk";
import { AppDispatch } from "../../../store/store";

import type { RcFile, UploadProps } from "antd/es/upload";

import { displayDate } from "../../../utils/datetime.util";
import { handleDownload } from "../../../utils/preview.utils";
import { FIELD_VISIT_STATUS } from "../../../enum/fieldVisitStatus";
type UpdateFieldVisitModalProps = {
  callback?: any;
  entityId: any;
  defaultFileList?: any;
};

const FieldVisitAttachmentModal: React.FC<UpdateFieldVisitModalProps> = (
  props,
) => {
  const { entityId, defaultFileList, callback } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [downloading, setDownloading] = useState(false);

  const { Dragger } = Upload;
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const removeFileFromList = async (file: any) => {
    const result = fileList.find(
      (uploadedFile: RcFile) => uploadedFile?.uid === file.uid,
    );

    if (result) {
      const index = fileList.indexOf(result);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      console.log(file);
      await dispatch(waiverFileDeleteThunk({ fileId: file.id }));

      // if (!file.uid.startsWith("rc-upload")) {
      //   await dispatch(waiverFileDeleteThunk({ fileId: file.uid }));
      //   await callback();
      // }
    }
  };

  const fetchFileList = async () => {
    setLoading(true);
    await dispatch(getWaiverFileByEntityIdThunk({ entityId }))
      .unwrap()
      .then((res) => {
        console.log(res);
        setFileList(
          res.map((file: any) => ({
            ...file,
            id: file.id,
            uid: file.id,
            name: file.filename.substring(file.filename.indexOf("-") + 1),
            status: "done",
          })) ?? [],
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fileTableColumns: ColumnsType<any> = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        record.filename.substring(record.filename.indexOf("-") + 1) ?? "-",
    },
    {
      title: "Uploaded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 160,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Action",
      key: "action",
      width: 260,
      render: (_, file) => (
        <Space wrap={false}>
          <Button
            loading={downloading}
            style={{ marginRight: 20 }}
            onClick={() => {
              handleDownload(
                file,
                setDownloading,
                dispatch,
                FileTypeEnum.FIELD_VISIT,
              );

              // handlePreview(
              //   file,
              //   setDownloading,
              //   dispatch,
              //   FileTypeEnum.AOC_CHARGE,
              // );
            }}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
          {/* <Button
            disabled={
              status === FIELD_VISIT_STATUS.CLOSED ||
              status === FIELD_VISIT_STATUS.VISITED
            }
            onClick={() => removeFileFromList(file)}
            icon={<DeleteOutlined style={{ color: "red" }} />}
            danger
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];

  const onFinish = async () => {
    setIsModalOpen(false);
  };

  const uploadProps: UploadProps = {
    multiple: true,
    fileList,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: RcFile) => {
      fileList.push(file);
      // setFileList([...fileList, file]);
      return false;
    },
  };

  useEffect(() => {
    fetchFileList();
  }, [entityId]);

  return (
    <>
      <Button onClick={showModal}>Attachment</Button>
      <Modal
        title="Uploaded File"
        // okText="Ok"
        // cancelText="Close"
        // onOk={onFinish}
        open={isModalOpen}
        onCancel={handleCancel}
        confirmLoading={loading || uploading}
        footer={[
          <Button key="close" onClick={() => onFinish()} type="primary">
            Close
          </Button>,
        ]}
      >
        <Table
          rowKey="id"
          className="min-w-fit my-2"
          columns={fileTableColumns}
          dataSource={fileList}
          loading={loading}
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
        />
      </Modal>
    </>
  );
};

export default FieldVisitAttachmentModal;
