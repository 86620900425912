import { Tabs, Tag } from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import {
  getApprovedEarlySettlementsThunk,
  getEarlySettlementsThunk,
  getInProcessEarlySettlementsThunk,
  getNewEarlySettlementsThunk,
  getVoidEarlySettlementsThunk,
} from "../../../services/earlySettlementService/earlySettlementThunk";
import { AppDispatch, RootState } from "../../../store/store";
import EarlySettlementTable from "./EarlySettlementTable";

import type { TabsProps } from "antd";

const EarlySettlementListing = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const fetchEarlySettlements = async (params?: any) => {
    await dispatch(getEarlySettlementsThunk(params));
  };

  const fetchNewEarlySettlements = async (params?: any) => {
    await dispatch(getNewEarlySettlementsThunk(params));
  };

  const fetchVoidEarlySettlements = async (params?: any) => {
    await dispatch(getVoidEarlySettlementsThunk(params));
  };

  const fetchApprovedEarlySettlements = async (params?: any) => {
    await dispatch(getApprovedEarlySettlementsThunk(params));
  };

  const fetchInProcessEarlySettlements = async (params?: any) => {
    await dispatch(getInProcessEarlySettlementsThunk(params));
  };

  const {
    allEarlySettlementList,
    newEarlySettlementList,
    voidEarlySettlementList,
    approvedEarlySettlementList,
    inProcessEarlySettlementList,
  } = useSelector((state: RootState) => state.earlySettlement);

  const items: TabsProps["items"] = [
    {
      key: "all",
      label: (
        <span>
          All{" "}
          {allEarlySettlementList?.data?.total != null && (
            <Tag>{allEarlySettlementList.data.total}</Tag>
          )}
        </span>
      ),
      children: (
        <EarlySettlementTable
          dataSource={allEarlySettlementList}
          callback={fetchEarlySettlements}
        />
      ),
    },
    {
      key: "new",
      label: (
        <span>
          New{" "}
          {newEarlySettlementList?.data?.total != null && (
            <Tag>{newEarlySettlementList.data.total}</Tag>
          )}
        </span>
      ),
      children: (
        <EarlySettlementTable
          dataSource={newEarlySettlementList}
          callback={fetchNewEarlySettlements}
        />
      ),
    },
    {
      key: "inProcess",
      label: (
        <span>
          In Process{" "}
          {inProcessEarlySettlementList?.data?.total != null && (
            <Tag>{inProcessEarlySettlementList.data.total}</Tag>
          )}
        </span>
      ),
      children: (
        <EarlySettlementTable
          dataSource={inProcessEarlySettlementList}
          callback={fetchInProcessEarlySettlements}
        />
      ),
    },
    {
      key: "void",
      label: (
        <span>
          Void{" "}
          {voidEarlySettlementList?.data?.total != null && (
            <Tag>{voidEarlySettlementList.data.total}</Tag>
          )}
        </span>
      ),
      children: (
        <EarlySettlementTable
          dataSource={voidEarlySettlementList}
          callback={fetchVoidEarlySettlements}
        />
      ),
    },
    {
      key: "approved",
      label: (
        <span>
          Approved{" "}
          {approvedEarlySettlementList?.data?.total != null && (
            <Tag>{approvedEarlySettlementList.data.total}</Tag>
          )}
        </span>
      ),
      children: (
        <EarlySettlementTable
          dataSource={approvedEarlySettlementList}
          callback={fetchApprovedEarlySettlements}
        />
      ),
    },
  ];

  const onTabChange = (key: string) => {
    if (key === "all") {
      fetchEarlySettlements(initialParams);
    } else if (key === "new") {
      fetchNewEarlySettlements(initialParams);
    } else if (key === "inProcess") {
      fetchInProcessEarlySettlements(initialParams);
    } else if (key === "void") {
      fetchVoidEarlySettlements(initialParams);
    } else if (key === "approved") {
      fetchApprovedEarlySettlements(initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchEarlySettlements(params);
    fetchNewEarlySettlements(params);
    fetchInProcessEarlySettlements(initialParams);
    fetchVoidEarlySettlements(params);
    fetchApprovedEarlySettlements(params);
  });

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Early Settlement Listing" />
      <Tabs
        defaultActiveKey={activeTab ?? "all"}
        onChange={(key) => onTabChange(key)}
        items={items}
        destroyInactiveTabPane
      />
    </>
  );
};

export default EarlySettlementListing;
