import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

const getProducts = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/master/api/v1.0/product/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url, { params });
  return res.data;
};

const createProduct = async (requestData: any) => {
  const url = `/redCash/admin/master/api/v1.0/product`;
  const res = await masterSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updateProduct = async (requestData: any) => {
  const { productId, payload } = requestData;
  const url = `/redCash/admin/master/api/v1.0/product/${productId}`;
  const res = await masterSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteProduct = async (requestData: any) => {
  const { productId } = requestData;
  const url = `/redCash/admin/master/api/v1.0/product/${productId}`;
  const res = await masterSetupAxiosInstance.delete(url);
  return res.data;
};

const getProductById = async (productId: string) => {
  const url = `/redCash/master/api/v1.0/product/id/${productId}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const getSmeProducts = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/master/api/v1.0/sme/product`;
  const res = await masterSetupAxiosInstance.get(url, { params });
  return res.data;
};

const deleteSmeProductById = async (requestData: any) => {
  const { productId } = requestData;
  const url = `/redCash/admin/master/api/v1.0/sme/product/${productId}`;
  const res = await masterSetupAxiosInstance.delete(url);
  return res.data;
};

const createSmeProduct = async (requestData: any) => {
  const url = `/redCash/master/api/v1.0/sme/product`;
  const res = await masterSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updateSmeProduct = async (requestData: any) => {
  const { productId, payload } = requestData;
  const url = `/redCash/admin/master/api/v1.0/sme/product/${productId}`;
  const res = await masterSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const productService = {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  getProductById,
  getSmeProducts,
  deleteSmeProductById,
  createSmeProduct,
  updateSmeProduct,
};

export default productService;
