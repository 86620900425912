import { Modal, Table } from "antd";

import { displayDate } from "../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";

interface SearchAgreementResultProps {
  visible: boolean;
  data: any[];
  onOk: (agreementNo: string) => void;
  onCancel: () => void;
}

const SearchAgreementResult = ({
  visible,
  data,
  onOk,
  onCancel,
}: SearchAgreementResultProps) => {
  const columns: ColumnsType<any> = [
    {
      title: "Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
    },
    {
      title: "Applicant Name",
      render: (_, record) =>
        record?.application?.applicantFirstName ??
        record?.applicantFirstName ??
        "-",
    },
    {
      title: "Applicant NRIC",
      render: (_, record) =>
        record?.application?.applicantNric ?? record.applicantNric ?? "-",
    },
  ];

  return (
    <Modal
      open={visible}
      title="Please Select:"
      onCancel={onCancel}
      width={800}
      footer={null}
      centered
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 7 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => onOk(record.agreementNo),
          };
        }}
        rowClassName={(record) => (record.selected ? "table-row-selected" : "")}
        rowKey={(record) => record.id}
      />
    </Modal>
  );
};

export default SearchAgreementResult;
