import { Button, Form, Input, message, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { UploadOutlined } from "@ant-design/icons";

import { PreRecordedVideo } from "../../../features/preRecordedVideo/preRecordedVideoSlice";
import {
  masterFileDeleteThunk,
  masterSetupFileUploadThunk,
} from "../../../services/fileService/fileThunk";
import {
  createPreRecordedVideoThunk,
  deletePreRecordedVideoThunk,
  getPreRecordedVideoFilesThunk,
  updatePreRecordedVideoThunk,
} from "../../../services/preRecordedVideoService/preRecordedVideoThunk";
import { AppDispatch } from "../../../store/store";

import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";

const PreRecordedVideoFormModal: React.FC<{
  preRecordedVideo?: PreRecordedVideo;
  videoLanguageVersion?: "EN" | "BM";
  callback?: () => void;
}> = ({ preRecordedVideo, videoLanguageVersion, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [loadingFile, setLoadingFile] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    setFileList([]);
    form.resetFields();
  };

  const removeFileFromList = async (file: any) => {
    const result = fileList.find(
      (uploadedFile: any) => uploadedFile?.uid === file.uid,
    );

    if (result) {
      const index = fileList.indexOf(result);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileList(newFileList);
      if (!file.uid.startsWith("rc-upload")) {
        await dispatch(masterFileDeleteThunk({ fileId: file.uid }));
        await dispatch(
          deletePreRecordedVideoThunk({
            preRecordedVideoId: preRecordedVideo?.id,
          }),
        );
        if (callback) callback();
      }
    }
  };

  const uploadProps: UploadProps = {
    maxCount: 1,
    onRemove: removeFileFromList,
    beforeUpload: (file: RcFile) => {
      const correctFileType = file.type === "video/mp4";

      if (!correctFileType) {
        message.error("Only allowed mp4!");
        return Upload.LIST_IGNORE;
      }

      if (fileList.length > 1) {
        message.error("Maximum 1 video only");
        return Upload.LIST_IGNORE;
      }

      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  const onFinish = async () => {
    if (fileList.length < 1) {
      return message.error("No file selected");
    }
    if (fileList.length > 1) {
      return message.error("Maximum 1 video only");
    }
    let fileType = "VIDEOATTESTATION";

    if (videoLanguageVersion === "BM") {
      fileType = "PRERECORDEDVIDEOATTESTATIONBM";
    }

    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        let entityId = "";

        if (preRecordedVideo != null) {
          entityId = preRecordedVideo.id;

          await dispatch(
            updatePreRecordedVideoThunk({
              id: preRecordedVideo.id,
              payload: {
                ...preRecordedVideo,
                title: values.title,
                companyId: process.env.REACT_APP_COMPANY_ID,
              },
            }),
          ).then((res) => {
            if (callback) callback();
          });
        } else {
          await dispatch(
            createPreRecordedVideoThunk({
              companyId: process.env.REACT_APP_COMPANY_ID,
              title: values.title,
            }),
          )
            .unwrap()
            .then((res) => {
              entityId = res.id;
            });
        }

        if (fileList.length > 0 && fileList[0].uid.startsWith("rc-upload")) {
          // const chunkSize = 1024 * 1024 * 10;
          // const fileSize = fileList[0].size ? fileList[0].size : 0;
          // const totalChunks = Math.ceil(fileSize / chunkSize);
          // const chunkProgress = 100 / totalChunks;

          // let chunkNumber = 0;
          // let start = 0;
          // let end = 0;

          // const uploadNextChunk = async () => {
          //   if (end <= fileSize) {
          //     end = start + chunkSize;
          //     const chunk = fileList[0].slice(start, end);
          //     const data = new FormData();
          //     data.append("entityId", entityId);
          //     data.append("fileType", fileType);
          //     data.append("filename", fileList[0].name);
          //     data.append("chunkNumber", chunkNumber.toString());
          //     data.append("totalChunks", totalChunks.toString());
          //     data.append("chunkSize", chunkSize.toString());
          //     data.append("start", start.toString());
          //     data.append("end", end.toString());
          //     data.append("file", chunk as RcFile);

          //     await dispatch(masterSetupFileUploadThunk(data));
          //     start = end;
          //     chunkNumber++;

          //     setLoading(true);
          //     uploadNextChunk();
          //   } else {
          //     if (preRecordedVideo != null) {
          //       message.success("Update Successfully");
          //       handleCancel();
          //     } else {
          //       message.success("Create Successfully");
          //       message.success("Update Successfully");
          //     }
          //     setLoading(false);
          //     handleCancel();
          //     if (callback) callback();
          //   }
          // };

          // await uploadNextChunk();
          const data = new FormData();
          data.append("entityId", entityId);
          data.append("fileType", fileType);
          data.append("file", fileList[0] as RcFile);

          await dispatch(masterSetupFileUploadThunk(data)).then((res) => {
            if (callback) callback();
          });
        }
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (preRecordedVideo?.id != null && isModalOpen) {
      setLoadingFile(true);
      dispatch(getPreRecordedVideoFilesThunk({ entityId: preRecordedVideo.id }))
        .unwrap()
        .then((res) => {
          if (res?.length > 0) {
            setFileList(
              res.map((file: any) => ({
                uid: file.id,
                name: file.filename.substring(file.filename.indexOf("-") + 1),
                status: "done",
              })) ?? [],
            );
            //   dispatch(getFileByIdThunk({ fileId: res[0].id }))
            //     .unwrap()
            //     .then((res) => setVideoFile(res));
          }
        })
        .finally(() => setLoadingFile(false));
    }
  }, [preRecordedVideo?.id, isModalOpen]);

  return (
    <>
      <Button
        onClick={showModal}
        // icon={preRecordedVideo != null ? <EditOutlined /> : <PlusOutlined />}
        loading={loading}
      >
        {preRecordedVideo != null ? "Update" : "Create"}
      </Button>
      <Modal
        title={
          preRecordedVideo != null
            ? "Update Pre Recorded Video"
            : "Create Pre Recorded Video"
        }
        okText={preRecordedVideo != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form
          form={form}
          initialValues={{ ...preRecordedVideo }}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please enter the title!" }]}
          >
            <Input />
          </Form.Item>
        </Form>

        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Upload Video (MP4)</Button>
        </Upload>
      </Modal>
    </>
  );
};

export default PreRecordedVideoFormModal;
