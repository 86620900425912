import { Tabs, Tag } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import { ApplicationStatusEnum } from "../../../enum/applicationStepStatus";
import {
  getCancelledApplicationsThunk,
  getFollowUpApplicationsThunk,
  getPendingApplicationsThunk,
  getReferbackApplicationsThunk,
  getReferbackFromPendingDisbursementApplicationsThunk,
  getRejectedApplicationsThunk,
} from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import CancelledApplicationListing from "./CancelledApplicationListing";
import FollowUpApplicationListing from "./FollowUpApplicationListing";
import PendingApplicationListing from "./PendingApplicationListing";
import ReferBackApplicationListing from "./ReferBackApplicationListing";
import ReferBackFromPendingDisbursementApplicationListing from "./ReferBackFromPendingDisbursementApplicationListing";
import RejectedApplicationListing from "./RejectedApplicationListing";

import type { TabsProps } from "antd";

const VerifyingApplicationListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const [title, setTitle] = useState("Pending");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const [searchFiled, setSearchFiled] = useState("applicationNo");

  const {
    pendingApplicationList,
    followUpApplicationList,
    referBackApplicationList,
    referBackFromPendingDisbursementApplicationList,
    rejectApplicationList,
    cancelledApplicationList,
  } = useSelector((state: RootState) => state.application);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "submissionDate",
      search: {
        [searchFiled]: "",
      },
      filters: {},
    }),
    [],
  );

  const items: TabsProps["items"] = [
    {
      key: "pending",
      label: (
        <span>
          Pending{" "}
          {pendingApplicationList?.data?.searchTotal != null && (
            <Tag>{pendingApplicationList.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <PendingApplicationListing />,
    },
    {
      key: "referBack",
      label: (
        <span>
          Refer-back{" "}
          {referBackApplicationList?.data?.searchTotal != null && (
            <Tag>{referBackApplicationList.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <ReferBackApplicationListing />,
    },
    {
      key: "referBackFromPendingDisbursement",
      label: (
        <span>
          Refer-back from Pending Disbursement{" "}
          {referBackFromPendingDisbursementApplicationList?.data?.searchTotal !=
            null && (
            <Tag>
              {referBackFromPendingDisbursementApplicationList.data.searchTotal}
            </Tag>
          )}
        </span>
      ),
      children: <ReferBackFromPendingDisbursementApplicationListing />,
    },
    {
      key: "followUp",
      label: (
        <span>
          Follow Up{" "}
          {followUpApplicationList?.data?.searchTotal != null && (
            <Tag>{followUpApplicationList.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <FollowUpApplicationListing />,
    },
    {
      key: "rejected",
      label: (
        <span>
          Rejected{" "}
          {rejectApplicationList?.data?.searchTotal != null && (
            <Tag>{rejectApplicationList.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <RejectedApplicationListing />,
    },
    {
      key: "cancelled",
      label: (
        <span>
          Cancelled{" "}
          {cancelledApplicationList?.data?.searchTotal != null && (
            <Tag>{cancelledApplicationList.data.searchTotal}</Tag>
          )}
        </span>
      ),
      children: <CancelledApplicationListing />,
    },
  ];

  const onFetchApplications = (status: ApplicationStatusEnum, params: any) => {
    if (status === ApplicationStatusEnum.PENDING) {
      dispatch(getPendingApplicationsThunk(params));
    }
    if (status === ApplicationStatusEnum.REFERBACK) {
      dispatch(getReferbackApplicationsThunk(params));
    }
    if (status === ApplicationStatusEnum.FOLLOWED_UP) {
      dispatch(getFollowUpApplicationsThunk(params));
    }
    if (status === ApplicationStatusEnum.REJECTED) {
      dispatch(getRejectedApplicationsThunk(params));
    }
    if (status === ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT) {
      dispatch(getReferbackFromPendingDisbursementApplicationsThunk(params));
    }
    if (status === ApplicationStatusEnum.CANCELED) {
      dispatch(getCancelledApplicationsThunk(params));
    }
  };

  const onTabChange = (key: string) => {
    if (key === "pending") {
      onFetchApplications(ApplicationStatusEnum.PENDING, initialParams);
    } else if (key === "referBack") {
      onFetchApplications(ApplicationStatusEnum.REFERBACK, initialParams);
    } else if (key === "followUp") {
      onFetchApplications(ApplicationStatusEnum.FOLLOWED_UP, initialParams);
    } else if (key === "rejected") {
      onFetchApplications(ApplicationStatusEnum.REJECTED, initialParams);
    } else if (key === "referBackFromPendingDisbursement") {
      onFetchApplications(
        ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT,
        initialParams,
      );
    } else if (key === "cancelled") {
      onFetchApplications(ApplicationStatusEnum.CANCELED, initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    onFetchApplications(ApplicationStatusEnum.PENDING, params);
    onFetchApplications(ApplicationStatusEnum.REFERBACK, params);
    onFetchApplications(ApplicationStatusEnum.FOLLOWED_UP, params);
    onFetchApplications(ApplicationStatusEnum.REJECTED, params);
    onFetchApplications(ApplicationStatusEnum.CANCELED, params);
    onFetchApplications(
      ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT,
      params,
    );
  });

  return (
    <>
      <Helmet>
        <title>{`Application Listing - redCASH CEP`}</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Verifying Application" />
      <Tabs
        defaultActiveKey={activeTab ?? "pending"}
        items={items}
        onChange={onTabChange}
      />
    </>
  );
};

export default VerifyingApplicationListingPage;
