import { createAsyncThunk } from "@reduxjs/toolkit";
import dropdownParameterService from "./dropdownParameterService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getRaceListThunk = createAsyncThunk(
  "race/list",
  async (requestData, thunkAPI) => {
    try {
      return await dropdownParameterService.getRaceList();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getGrossSalaryRangeListThunk = createAsyncThunk(
  "grossSalaryRange/list",
  async (requestData, thunkAPI) => {
    try {
      return await dropdownParameterService.getGrossSalaryRangeList();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getLoanReasonListThunk = createAsyncThunk(
  "loanReason/list",
  async (requestData, thunkAPI) => {
    try {
      return await dropdownParameterService.getLoanReasonList();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEmployeeTypeListThunk = createAsyncThunk(
  "employeeType/list",
  async (requestData, thunkAPI) => {
    try {
      return await dropdownParameterService.getEmployeeTypeList();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getBankListThunk = createAsyncThunk(
  "bank/list",
  async (requestData, thunkAPI) => {
    try {
      return await dropdownParameterService.getBankList();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRelationshipListThunk = createAsyncThunk(
  "relationship/list",
  async (requestData, thunkAPI) => {
    try {
      return await dropdownParameterService.getRelationshipList();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getUnsuccessfulReasonTrunk = createAsyncThunk(
  "unsuccessfulReason/list",
  async (requestData, thunkAPI) => {
    try {
      return await dropdownParameterService.getUnsuccessfulReason();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
