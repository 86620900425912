export enum SPECIAL_TAGGING_STATUS {
  UNCLEAR = "UNCLEAR",
  CLEAR = "CLEAR",
}

export enum SPECIAL_TAGGING_TYPE {
  EARLY_SETTLEMENT = "EARLY_SETTLEMENT",
  FULL_SETTLEMENT = "FULL_SETTLEMENT",
  DECEASED = "DECEASED",
  WRITE_OFF = "WRITE_OFF",
  // fraud
  FRD = "FRD",
  TPS = "TPS",
  BKC = "BKC",
}
