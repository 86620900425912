import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Upload,
  message,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import {
  getCloseFieldVisitsThunk,
  getPendingFieldVisitsThunk,
  getOpenFieldVisitsThunk,
  getVisitedFieldVisitsThunk,
} from "../../../services/fieldVisitService/fieldVisitThunk";
import { AppDispatch, RootState } from "../../../store/store";

import type { TablePaginationConfig, TableProps, TabsProps } from "antd";
import { displayDateAndTime } from "../../../utils/datetime.util";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { getManualPaymentThunk } from "../../../services/manualPayment.service/manualPaymentThunk";
import AccountEnquiryModal from "../../AccountEnquiry/AccountEnquiryModal";
import { ManualPaymentData } from "../../../features/payment/paymentSlice";
import { downloadManualPaymentTemplateThunk } from "../../../services/reportService/reportThunk";
import Dragger from "antd/es/upload/Dragger";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { InboxOutlined } from "@ant-design/icons";
import { FileTypeEnum } from "../../../enum/fileType";
import { uploadManualPaymentFileThunk } from "../../../services/paymentService/paymentThunk";

const { Search } = Input;

const ManualPaymentListPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");
  const { manualPayment } = useSelector((state: RootState) => state.payment);
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [downloading, setDownloading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const { currentUser } = useSelector((state: RootState) => state.user);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchState, setSearchState] = useState(initialParams.search);

  const tablePagination = {
    total: manualPayment.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: manualPayment.currentPage,
    pageSize: manualPayment.pageSize,
  };

  const fetchManualPayment = async (params?: any) => {
    await dispatch(getManualPaymentThunk({ params }));
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchManualPayment(params);
  });

  const handleTableChange: TableProps<ManualPaymentData>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      sorter?.order == null
        ? {
            ...initialParams,
            ...filterState,
            pagination,
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          };

    fetchManualPayment(param);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const onSearch = (value: string) => {
    fetchManualPayment({
      ...initialParams,
      filters: filterState,
      search: { [searchFiled]: value },
    });
    setSearchState({ [searchFiled]: value });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      render: (text, record) => (
        <AccountEnquiryModal agreementNo={record.agreementNo} />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Pay Date",
      dataIndex: "payDate",
      render: (tex, record) => displayDateAndTime(record.payDate),
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: "Reference No",
      dataIndex: "referenceNo",
      key: "referenceNo",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (tex, record) => displayDateAndTime(record.createdAt),
    },
  ];

  const onDownloadReport = async () => {
    setDownloading(true);
    setIsLoadingExcel(true);
    await dispatch(downloadManualPaymentTemplateThunk())
      .unwrap()
      .then((res) => {
        downloadFile(res, `ManualPaymentTemplate`, "xlsx");
      })
      .catch((error) => {
        console.log(error);
        message.error("Failed to download template. Please try again later.");
      })
      .finally(() => setDownloading(false));
  };

  const downloadFile = (csvContent: any, fileName: any, filetype: any) => {
    const blob = new Blob([csvContent], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    const _win = window.navigator as any;
    if (_win && _win.msSaveBlob) {
      // For IE browser
      _win.msSaveBlob(blob, fileName);
      setIsLoadingExcel(false);
    } else {
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
      setIsLoadingExcel(false);
    }
  };

  const handleOk = async () => {
    setUploading(true);
    setOpen(false);
    if (fileList.length > 2) {
      return message.error("Limit 1 files only");
    } else if (fileList.length == 0) {
      return message.error("Please upload file");
    } else {
      await form
        .validateFields()
        .then(async (values) => {
          const username = currentUser?.data?.username;

          const formData = new FormData();
          formData.append("fileType", FileTypeEnum.MANUAL_PAYMENT);
          formData.append("username", username ?? "");
          formData.append("file", fileList[0] as RcFile);

          dispatch(uploadManualPaymentFileThunk(formData))
            .unwrap()
            .then(async (res) => {
              message.success("Submit Successfully");

              fetchManualPayment(initialParams);
              handleCancel();
              setUploading(false);
              setFileList([]);
            })
            .catch(() => {
              setUploading(false);
              setFileList([]);
              handleCancel();
            });
        })
        .catch(() => {
          message.error("Something went wrong. Please try again later.");
          setFileList([]);
          setUploading(false);
          handleCancel();
        });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    newFileList = newFileList.slice(-2);

    setFileList(newFileList);
  };

  const uploadProps: UploadProps = {
    maxCount: 1,
    fileList,
    beforeUpload: (file: RcFile) => {
      if (fileList.length > 0) {
        message.error("Only allowed 1 file");
        return Upload.LIST_IGNORE;
      }
      const correctFileType =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!correctFileType) {
        message.error("Only allowed xlsx");
        return Upload.LIST_IGNORE;
      }

      fileList.push(file);
      return false;
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Manual Payment Listing" />
      <Row justify="start" className="mb-2" align="bottom">
        <Space>
          <Input.Group compact>
            <Select
              defaultValue="agreementNo"
              style={{ width: 200 }}
              onChange={(value) => setSearchFiled(value)}
              options={[
                { value: "agreementNo", label: "Agreement No" },
                { value: "channel", label: "Channel" },
              ]}
            />

            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </Input.Group>{" "}
          <Button
            type="primary"
            onClick={() => onDownloadReport()}
            loading={isLoadingExcel}
          >
            Download File
          </Button>
          <Button
            type="primary"
            onClick={() => setOpen(true)}
            loading={uploading}
          >
            Upload File
          </Button>
        </Space>
      </Row>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        dataSource={manualPayment.data ?? []}
        columns={columns}
        pagination={tablePagination}
        onChange={handleTableChange}
      />

      <Modal
        open={open}
        title="Upload File"
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
      >
        <Form
          form={form}
          name="uploadManualPaymentFile"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item label="Manual Payment File">
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ManualPaymentListPage;
