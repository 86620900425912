import { Button, Dropdown, Input, Select, Space, Table } from "antd";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { DownOutlined } from "@ant-design/icons";

import LoadingSpinner from "../../../../components/LoadingSpinner";
import {
  getEarlySettlementBmQuotationThunk,
  getEarlySettlementBmTaxInvoiceThunk,
  getEarlySettlementEnQuotationThunk,
  getEarlySettlementEnTaxInvoiceThunk,
} from "../../../../services/earlySettlementService/earlySettlementThunk";
import { AppDispatch } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";
import { saveBlobAsPDF } from "../../../../utils/file";

import type { ColumnsType } from "antd/es/table";
import type { TablePaginationConfig } from "antd/es/table";
import AccountEnquiryModal from "../../../AccountEnquiry/AccountEnquiryModal";

const { Search } = Input;

type EarlySettlementTableProps = {
  dataSource: any;
  callback: any;
};

const EarlySettlementTable: React.FC<EarlySettlementTableProps> = (props) => {
  const { dataSource, callback } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [downloading, setDownloading] = useState(false);

  const tablePagination = {
    total: dataSource?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const fetchEarlySettlementEnQuotation = async (params?: any) => {
    setDownloading(true);
    await dispatch(getEarlySettlementEnQuotationThunk(params))
      .unwrap()
      .then((response) => {
        const filename = `EARLY_SETTLEMENT_QUOTATION_${params?.earlySettlementId}_EN`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => setDownloading(false));
  };

  const fetchEarlySettlementBmQuotation = async (params?: any) => {
    setDownloading(true);
    await dispatch(getEarlySettlementBmQuotationThunk(params))
      .unwrap()
      .then((response) => {
        const filename = `EARLY_SETTLEMENT_QUOTATION_${params?.earlySettlementId}_BM`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => setDownloading(false));
  };

  const fetchEarlySettlementEnTaxInvoice = async (params?: any) => {
    setDownloading(true);
    await dispatch(getEarlySettlementEnTaxInvoiceThunk(params))
      .unwrap()
      .then((response) => {
        const filename = `EARLY_SETTLEMENT_TAX_INVOICE_${params?.earlySettlementId}_EN`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => setDownloading(false));
  };

  const fetchEarlySettlementBmTaxInvoice = async (params?: any) => {
    setDownloading(true);
    await dispatch(getEarlySettlementBmTaxInvoiceThunk(params))
      .unwrap()
      .then((response) => {
        const filename = `EARLY_SETTLEMENT_TAX_INVOICE_${params?.earlySettlementId}_BM`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => setDownloading(false));
  };

  const handleDownloadQuotationClick = (e: any, earlySettlementId: string) => {
    if (e?.key === "bm")
      fetchEarlySettlementBmQuotation({
        earlySettlementId,
      });
    else {
      fetchEarlySettlementEnQuotation({
        earlySettlementId,
      });
    }
  };

  const handleDownloadTaxInvoiceClick = (e: any, earlySettlementId: string) => {
    if (e?.key === "bm")
      fetchEarlySettlementEnTaxInvoice({
        earlySettlementId,
      });
    else {
      fetchEarlySettlementBmTaxInvoice({
        earlySettlementId,
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Aging",
      render: (text, record) => record?.accountDetails?.agingType ?? "-",
    },
    {
      title: "Early Settlement Date",
      dataIndex: "earlySettlementDate",
      key: "earlySettlementDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Early Settlement No",
      dataIndex: "earlySettlementNo",
      key: "earlySettlementNo",
      render: (text, record) =>
        record.earlySettlementNo ? (
          <Button
            type="link"
            onClick={() => navigate(`/early-settlement/details/${record.id}`)}
          >
            <span style={{ userSelect: "text" }}>
              {record.earlySettlementNo}
            </span>
          </Button>
        ) : (
          "-"
        ),
    },
    {
      title: "Agreement No",
      render: (_, record) => (
        <AccountEnquiryModal
          key={record.id}
          agreementNo={record.accountDetails?.agreementNo}
        />
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (text, record) =>
        record?.accountDetails?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.accountDetails?.applicantNric ?? "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Auto Tagging",
      dataIndex: "specialTaggingAuto",
      key: "specialTaggingAuto",
    },
    {
      title: "Manual Tagging",
      dataIndex: "specialTaggingManual",
      key: "specialTaggingManual",
    },
    {
      title: "Download",
      key: "download",
      width: 200,
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{
              items: [
                {
                  label: "English",
                  key: "en",
                },
                {
                  label: "Bahasa Malaysia",
                  key: "bm",
                },
              ],
              onClick: (e) => handleDownloadQuotationClick(e, record.id),
            }}
          >
            <Button>
              <Space>
                Quotation
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          <Dropdown
            menu={{
              items: [
                {
                  label: "English",
                  key: "en",
                },
                {
                  label: "Bahasa Malaysia",
                  key: "bm",
                },
              ],
              onClick: (e) => handleDownloadTaxInvoiceClick(e, record.id),
            }}
          >
            <Button>
              <Space>
                Tax Invoice
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const onSearch = (value: string) => {
    callback({
      ...initialParams,
      filters: { ...filterState, [searchFiled]: value },
    });
    setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    callback({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>All Early Settlement - redCASH CEP</title>
      </Helmet>

      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => setSearchFiled(value)}
          options={[
            { value: "agreementNo", label: "Agreement No" },
            { value: "applicantNric", label: "Applicant NRIC" },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantPhoneNo", label: "Applicant Phone No." },
            { value: "applicantEmail", label: "Applicant Email" },
          ]}
        />

        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      {downloading && (
        <div className="absolute w-full h-full z-50 backdrop-brightness-95">
          <LoadingSpinner />
        </div>
      )}
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={dataSource?.data?.data ?? []}
        loading={dataSource?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default EarlySettlementTable;
