import { AppDispatch, RootState } from "../../store/store";
import { Button, Popconfirm, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import React, { useEffect, useMemo, useState } from "react";
import {
  displayDate,
  displayDateAndTime,
  displayDateFromString,
} from "../../utils/datetime.util";
import {
  getAgreementListThunk,
  getWaiverRemarksThunk,
} from "../../services/waiverService/waiverThunk";
import { useDispatch, useSelector } from "react-redux";

import { getInternalRemarksByApplicationIDThunk } from "../../services/applicationService/applicationThunk";

type RemarksHistoryTable = {
  value?: string;
  dataSource?: any;
};

const RemarksTable: React.FC<RemarksHistoryTable> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { value, dataSource } = props;

  const [searchResultdata, setSearchResultData] = useState();
  const { waiverRemark } = useSelector((state: RootState) => state.waiver);
  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 5,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: waiverRemark.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const getWaiverRemark = async () => {
    const payload = {
      ...initialParams,
      waiverRemarks: value,
    };
    await dispatch(getWaiverRemarksThunk(payload)).then(async (res) => {
      const array: any = [];
      for (let i = 0; i < res.payload.data.length; i++) {
        array.push({
          ...res.payload.data[i],
        });
        setSearchResultData(array);
      }
    });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    if (dataSource) {
      return;
    }
    getWaiverRemark();
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    if (dataSource) {
      return;
    }
    getWaiverRemark();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDateAndTime(text) : "-"),
      width: "15%",
    },
    {
      title: "Username",
      dataIndex: "userId",
      key: "userId",
      render: (text, record) => record.username ?? "-",
      width: "15%",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (text, record) => record.remarks ?? "-",
      width: "70%",
    },
  ];

  return (
    <Table
      rowKey="id"
      className="min-w-fit"
      dataSource={dataSource ?? searchResultdata}
      columns={columns}
      bordered={true}
      pagination={tablePagination}
      onChange={handleTableChange}
    />
  );
};

export default RemarksTable;
