import { Button, Modal, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  CloseCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

import { PreRecordedVideo } from "../../features/preRecordedVideo/preRecordedVideoSlice";
import {
  getFileByIdThunk,
  getMasterSetupFileByIdThunk,
} from "../../services/fileService/fileThunk";
import { getPreRecordedVideoFilesThunk } from "../../services/preRecordedVideoService/preRecordedVideoThunk";
import { AppDispatch } from "../../store/store";
import LoadingSpinner from "../LoadingSpinner";

const { Paragraph, Text } = Typography;

const VideoModal: React.FC<{
  preRecordedVideo?: PreRecordedVideo;
}> = ({ preRecordedVideo }) => {
  const dispatch = useDispatch<AppDispatch>();
  const videoRef = useRef<any>(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playingVideo, setPlayingVideo] = useState(true);
  const [videoFile, setVideoFile] = useState(null);
  const [videoLink, setVideoLink] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const playPause = () => {
    if (videoRef?.current?.paused) {
      setPlayingVideo(true);
      videoRef?.current?.play();
    } else {
      videoRef?.current?.pause();
      setPlayingVideo(false);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (preRecordedVideo?.id != null && isModalOpen) {
      setLoadingFile(true);
      dispatch(getPreRecordedVideoFilesThunk({ entityId: preRecordedVideo.id }))
        .unwrap()
        .then((res) => {
          if (res?.length > 0) {
            setVideoLink(
              `${process.env.REACT_APP_FILE_UPLOAD_URL}/redCash/api/v1.0/video/file/${res[0].id}`,
            );
            dispatch(getMasterSetupFileByIdThunk({ fileId: res[0].id }))
              .unwrap()
              .then((res) => {
                setVideoFile(res);
              })
              .catch((err) => setVideoFile(null))
              .finally(() => setLoadingFile(false));
          } else {
            setVideoFile(null);
            setLoadingFile(false);
          }
        })
        .catch((err) => setVideoFile(null));
    }
  }, [preRecordedVideo?.id, isModalOpen]);

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<PlayCircleOutlined />}>
        Play
      </Button>
      <Modal
        title={preRecordedVideo?.title}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        footer={null}
        destroyOnClose
        centered
      >
        {loadingFile ? (
          <LoadingSpinner />
        ) : videoFile != null ? (
          <video ref={videoRef} className="h-full w-full" autoPlay controls>
            <source src={videoLink} type="video/mp4" />
          </video>
        ) : (
          <div className="py-24 flex justify-center">
            <strong>Video Not Found</strong>
          </div>
        )}
        {/*<div className="flex justify-center">*/}
        {/*  <Button*/}
        {/*    type={!playingVideo ? "primary" : "default"}*/}
        {/*    className="w-32"*/}
        {/*    icon={*/}
        {/*      !playingVideo ? <PlayCircleOutlined /> : <PauseCircleOutlined />*/}
        {/*    }*/}
        {/*    size="large"*/}
        {/*    onClick={playPause}*/}
        {/*  >*/}
        {/*    {!playingVideo ? "Play" : "Pause"}*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </Modal>
    </>
  );
};

export default VideoModal;
