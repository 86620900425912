import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

const getPreRecordedVideoEN = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/master/api/v1.0/preRecordedVideo/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getPreRecordedVideosBm = async (requestData: any) => {
  console.log(requestData);
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/master/api/v1.0/preRecordedVideoBm`;
  const res = await masterSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getPreRecordedVideoBM = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/master/api/v1.0/preRecordedVideoBm`;
  const res = await masterSetupAxiosInstance.get(url, { params });
  return res.data;
};

const createPreRecordedVideo = async (requestData: any) => {
  const url = `/redCash/master/api/v1.0/preRecordedVideo`;
  const res = await masterSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updatePreRecordedVideo = async (requestData: any) => {
  const { id, payload } = requestData;
  const url = `/redCash/master/api/v1.0/preRecordedVideo/${id}`;
  const res = await masterSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const deletePreRecordedVideo = async (requestData: any) => {
  const { preRecordedVideoId } = requestData;
  const url = `/redCash/master/api/v1.0/preRecordedVideo/${preRecordedVideoId}`;
  const res = await masterSetupAxiosInstance.delete(url);
  return res.data;
};
const peRecordedVideoService = {
  getPreRecordedVideoEN,
  getPreRecordedVideoBM,
  createPreRecordedVideo,
  updatePreRecordedVideo,
  deletePreRecordedVideo,
  getPreRecordedVideosBm,
};

export default peRecordedVideoService;
