export enum ReportTypeEnum {
  MASTER_LISTING = "MASTER_LISTING",
  INTEREST_CHARGE = "INTEREST_CHARGE",
  MISC_BILLING = "MISC_BILLING",
  LOAN_MATURITY = "LOAN_MATURITY",
  OVERDUE = "OVERDUE",
  COLLECTION = "COLLECTION",
  CUSTOMER_LOAN = "CUSTOMER_LOAN",
  CUSTOMER_LOAN_INTEREST = "CUSTOMER_LOAN_INTEREST",
  LATE_CHARGES = "LATE_CHARGES",
  AGING_REPORT = "AGING_REPORT",
  CUSTOMER_DETAILS = "CUSTOMER_DETAILS",
  STAMP_DUTY = "STAMP_DUTY",
  LOAN_INSURANCE = "LOAN_INSURANCE",
}
