import { Button, Input, Select, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";
import { numberWithCommas } from "../../../helpers/number";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate, displayTime } from "../../../utils/datetime.util";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { getAdminsThunk } from "../../../services/adminService/adminThunk";
import { SmeApplication, smeApplicationStateReset } from "../../../features/smeApplication/smeApplicationSlice";
import { getSmeApplicationsListingThunk } from "../../../services/smeApplicationService/smeApplicationThunk";

const { Search } = Input;

const SmeSearchApplicationPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { smeApplicationsList } = useSelector(
    (state: RootState) => state.smeApplication,
  );
  const { admins } = useSelector((state: RootState) => state.admin);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<SmeApplication>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [searchState, setSearchState] = useState(initialParams.search);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("applicationNo");
  const [searchValue, setSearchValue] = useState("");

  const tablePagination = {
    total: smeApplicationsList.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Application Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortOrder:
        sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      render: (text, record) => displayDate(text ?? record.createdAt) ?? "-",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (text, record) => displayTime(text ?? record.createdAt) ?? "-",
    },
    {
      title: "Application No.",
      dataIndex: "applicationNo",
      key: "applicationNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/sme/application/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Organization Type",
      dataIndex: "organizationType",
      key: "organizationType",
      render: (text: string) => text ?? "-",
    },
    {
      title: "Applicant Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        record?.smeIndividualApplication?.name ?? record?.smeSolePropApplication?.name ?? record?.smePartnershipApplication?.name ?? record?.smeCompanyApplication?.companyName ?? "-"
      ),
    },
    {
      title: "Applied Loan Amount",
      dataIndex: "appliedLoanAmount",
      key: "appliedLoanAmount",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Credit Bucket",
      dataIndex: "bucket",
      key: "bucket",
    },
    ...(process.env.REACT_APP_CASE_ASSIGNMENT_ENABLED === "YES"
      ? [
        {
          title: "Case Assignment",
          key: "caseAssignment",
          children: [
            {
              title: "Bucket",
              dataIndex: ["caseAssignment", "bucket", "bucketName"],
              key: "caseAssignmentBucketName",
              render: (bucketName: any) => {
                return bucketName ?? "-";
              },
            },
            {
              title: "Group",
              dataIndex: ["caseAssignment", "group", "groupCode"],
              key: "caseAssignmentBucketName",
              render: (groupCode: any) => {
                return groupCode ?? "-";
              },
            },
            {
              title: "User",
              dataIndex: ["caseAssignment", "user", "userId"],
              key: "caseAssignmentUserId",
              render: (adminId: string) => {
                const admin = admins.data.find(
                  (admin) => admin.id === adminId,
                );
                return admin?.username ?? "-";
              },
            },
          ],
        },
      ]
      : []),
  ];

  const fetchApplications = async (params?: any) => {
    await dispatch(getSmeApplicationsListingThunk(params));
  };

  const fetchAdmins = async (params?: any) => {
    await dispatch(getAdminsThunk({ params }));
  };

  const resetApplicationState = async () => {
    await dispatch(smeApplicationStateReset());
  };

  const onSearch = (value: string) => {
    fetchApplications({
      ...initialParams,
      filters: filterState,
      ...(value ? { search: { ...initialParams.search, [searchFiled]: value.toUpperCase() } } : {}),
    });
    setSearchState({ [searchFiled]: value });
    setSearchValue(value);
  };

  const handleTableChange: TableProps<SmeApplication>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param = {
      ...initialParams,
      ...filterState,
      pagination,
      sortField: sorter?.columnKey,
      sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
      ...(searchValue ? { search: { ...initialParams.search, [searchFiled]: searchValue.toUpperCase() } } : {}),
    };

    fetchApplications(param);
    setSortedInfo(sorter as SorterResult<SmeApplication>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useLifecycles(() => {
    resetApplicationState();

    const params = {
      ...initialParams,
      filters: filterState,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
      search: {
        applicationNo: "",
      },
    };
    console.log(params);
    fetchApplications(params);
    fetchAdmins({
      pagination: {
        current: 1,
        pageSize: 10000, // get all admins
      },
    });
  });

  return (
    <>
      <Helmet>
        <title>Search SME Application - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Search SME Application" />
      <Input.Group compact>
        <Select
          defaultValue={searchFiled}
          style={{ width: 200 }}
          onChange={(value) => setSearchFiled(value)}
          options={[
            { value: "applicationNo", label: "Application No" },
            { value: "name", label: "Applicant Name" },
          ]}
        />
        <Search
          placeholder="Input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={smeApplicationsList.data.data}
        loading={smeApplicationsList.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default SmeSearchApplicationPage;
