export const roundUpToNearestThousands = (number: number) => {
  if (number % 1000 === 0) return number;
  return parseInt(String(number / 1000)) * 1000 + 1000;
};

export const roundUp = (number: number) => {
  return Math.ceil(number);
};

export function roundToDecimal(number: number, decimalPlaces: number): number {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.round(number * multiplier) / multiplier;
}

export const trimPhoneNo = (phoneNo: string | null | undefined) => {
  if (!phoneNo) return null;

  if (phoneNo.startsWith("+6")) {
    return phoneNo.slice(2);
  } else {
    // if (phoneNo.startsWith("0")) {
    //   return phoneNo.replace(/^0+/, "");
    // } else {
    //   return `${phoneNo}`;
    // }
    return `${phoneNo}`;
  }
};
