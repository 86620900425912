import { Button, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLifecycles } from "react-use";

import {
  getFilesByEntityIdThunk,
  getWaiverFileByEntityIdThunk,
} from "../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../store/store";
import UploadedDocument from "../UploadedDocument";
import UploadedWaiverDocument from "../UploadedWaiverDocument";

export type WaiverFileListModalProps = {
  entityId: string;
};

const WaiverFileListModal: React.FC<WaiverFileListModalProps> = ({
  entityId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { waiverFile } = useSelector((state: RootState) => state.waiver);

  // const showModal = () => {
  //   setOpen(true);
  // };

  const handleOk = () => {
    setLoading(false);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const fetchFileList = async () => {
    setOpen(true);
    setLoading(true);
    await dispatch(getWaiverFileByEntityIdThunk({ entityId }))
      .unwrap()
      .then((res) => {
        setFileList(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button onClick={() => fetchFileList()}>Uploaded File</Button>
      <Modal
        open={open}
        title="Uploaded File"
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <UploadedWaiverDocument
            key={entityId}
            defaultFileList={fileList}
            readOnly
            callback={fetchFileList}
          />
        )}
      </Modal>
    </>
  );
};
export default WaiverFileListModal;
