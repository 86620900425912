import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";
import { RnRType } from "../../enum/reschedule";
import { AxiosRequestConfig } from "axios";

const blobConfig: AxiosRequestConfig = { responseType: "blob" };

export const createRestructureCase = async (requestData: any) => {
  const url = `/redCash/api/v1.0/restructure/create`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const getRestructureCase = async (requestData: any) => {
  const url = `/redCash/api/v1.0/reschedule/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const updateRestructureCase = async (requestData: any) => {
  const url = `/redCash/api/v1.0/reschedule/update/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const updateRestructureCaseStatus = async (requestData: any) => {
  const url = `/redCash/api/v1.0/reschedule/updateStatus/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const getRestructureListing = async (requestData: any) => {
  const params = {
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
    filters: { ...requestData.filters, type: RnRType.Restructure },
    search: requestData.search,
  };

  const url = `/redCash/api/v1.0/reschedule-listing`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params,
  });
  return res.data;
};

export const getRestructureQuotation = async (requestData: any) => {
  const url = `/redCash/api/v1.0/reschedule/quotation/rescheduleID/${requestData.rescheduleId}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);

  return res.data;
};
