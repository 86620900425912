import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import InfoGrid from "../../../../../components/InfoGrid";
import React from "react";
import { EditDirectorModal } from "./EditDirector";

export const DirectorInfoTab = () => {
  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const data = smeApplicationDetails?.data?.smePartnershipApplication?.smePartnershipDirectors || smeApplicationDetails?.data?.smeCompanyApplication?.smeCompanyDirectors;

  const directorDetails = data?.map((director, index) => {
    return [
      {
        title: `${index + 1}. Name`,
        value: director?.directorName,
      },
      {
        title: `NRIC`,
        value: director?.nric,
      },
      {
        title: `Identity Card Type`,
        value: director?.icType,
      },
      {
        title: `Position in Company`,
        value: director?.shareholdingType,
      },
      {
        title: `Contact Number`,
        value: director?.phoneNo,
      },
      {
        title: `Personal Email`,
        value: director?.personalEmail,
      },
      {
        title: `No. Of Shares Unit`,
        value: director?.noOfShares,
      },
      {
        title: `No. Of Shares %`,
        value: director?.sharesPercentage,
      },
    ];
  });

  return (
    <div className="info-container">
      <InfoGrid title="Details of Shareholders, Directors or Beneficial Owners Details" extra={
        <EditDirectorModal />
      } />
      <InfoGrid data={directorDetails.flat()} />
    </div>
  );
};