export enum REFUND_STATUS {
  OPEN = "OPEN",
  REFERBACK = "REFERBACK",
  APPROVED = "APPROVED",
  VOID = "VOID",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
}

export enum REFUND_FINANCE_STATUS {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  INCOMPLETE = "INCOMPLETE",
  VOID = "VOID",
  REFUNDED = "REFUNDED",
  REFERBACK = "REFERBACK",
}

export enum REFUND_FINANCE_BATCH_STATUS {
  INCOMPLETE = "INCOMPLETE",
  COMPLETED = "COMPLETED",
  IN_PROCESS = "IN_PROCESS",
}

export const refundAdminCharge = 5;
