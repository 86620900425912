import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../../helpers/errorMessage";
import ruleService from "./ruleService";

export const getAllRuleThunk = createAsyncThunk(
  "rule/getAll",
  async (requestData: any, thunkAPI) => {
    try {
      return await ruleService.getAllRule(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createRuleThunk = createAsyncThunk(
  "rule/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await ruleService.createRule(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRuleThunk = createAsyncThunk(
  "rule/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await ruleService.updateRule(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteRuleThunk = createAsyncThunk(
  "rule/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await ruleService.deleteRule(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
