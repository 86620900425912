import { createAsyncThunk } from "@reduxjs/toolkit";
import reportService from "./reportService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getReportListingThunk = createAsyncThunk(
  "report/getReportListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.getReportListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadReportThunk = createAsyncThunk(
  "report/downloadReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.downloadReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadManualPaymentTemplateThunk = createAsyncThunk(
  "report/downloadManualPaymentTemplate",
  async (requestData, thunkAPI) => {
    try {
      return await reportService.getManualPaymentTemplate(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateMasterListingReportThunk = createAsyncThunk(
  "report/generateMasterListingReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateMasterListingReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateCollectionReportThunk = createAsyncThunk(
  "report/generateCollectionReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateCollectionReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateKpktReportThunk = createAsyncThunk(
  "report/generateKpktReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateKpktReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateMiscBillingReportThunk = createAsyncThunk(
  "report/generateMiscBillingReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateMiscBillingReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateLoanMaturityReportThunk = createAsyncThunk(
  "report/generateLoanMaturityReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateLoanMaturityReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateOverDueReportThunk = createAsyncThunk(
  "report/generateOverDueReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateOverDueReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateLoanCncReportThunk = createAsyncThunk(
  "report/generateLoanCncReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateLoanCncReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateInterestCncReportThunk = createAsyncThunk(
  "report/generateInterestCncReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateInterestCncReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateLateChargesReportThunk = createAsyncThunk(
  "report/generateLateChargesReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateLateChargesReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generatePartnerReportThunk = createAsyncThunk(
  "report/generatePartnerReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generatePartnerReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateAgingReportThunk = createAsyncThunk(
  "report/generateAgingReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateAgingReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateCustomerDetailsReportThunk = createAsyncThunk(
  "report/generateCustomerDetailsReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateCustomerDetailsReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateStampDutyReportThunk = createAsyncThunk(
  "report/generateStampDutyReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateStampDutyReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateInterestChargesReportThunk = createAsyncThunk(
  "report/generateInterestChargesReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateInterestChargesReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateConsolidatedEInvoiceDetailsReportThunk = createAsyncThunk(
  "report/generateConsolidatedEInvoiceDetailsReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateConsolidatedEInvoiceDetailsReport(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateConsolidatedCreditNoteDetailsReportThunk =
  createAsyncThunk(
    "report/generateConsolidatedCreditNoteDetailsReport",
    async (requestData: any, thunkAPI) => {
      try {
        return await reportService.generateConsolidatedCreditNoteDetailsReport(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const generateLoanInsuranceReportThunk = createAsyncThunk(
  "report/generateLoanInsuranceReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await reportService.generateLoanInsuranceReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateSmeMonthlyDeductionReportThunk = createAsyncThunk(
  "report/generateSmeMonthlyDeductionReport",
  async (reportDate: string, thunkAPI) => {
    try {
      return await reportService.generateSmeMonthlyDeductionReport(reportDate);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);