import { roundUp, roundUpToNearestThousands } from "./number.util";
import { TradeReference } from "../features/application/applicationSlice";

export const onCalculateRepaymentInstalmentAmount = (
  loanAmount: number,
  tenure: number,
) => {
  return (loanAmount + ((loanAmount * 18) / 100) * (tenure / 12)) / tenure;
};

export const onCalculateStampingDutyFee = (
  loanAmount: number,
  stampingDutyPercentage: number,
) => {
  const amt = roundUpToNearestThousands(loanAmount);
  return (amt * stampingDutyPercentage) / 100;
};

export const onCalculateTotalInterest = (
  loanAmount: number,
  interestRate: number,
  tenure: number,
) => {
  return ((loanAmount * (interestRate / 100)) / 12) * tenure;
};

export const onCalculateTotalInstalmentAmount = (
  loanAmount: number,
  interestRate: number,
  tenure: number,
) => {
  return (
    onCalculateTotalInterest(loanAmount, interestRate, tenure) + loanAmount
  );
};

export const onCalculateEstimatedMonthlyAmount = (
  loanAmount: number,
  interestRate: number,
  tenure: number,
) => {
  const totalInterestAmt = +onCalculateTotalInterest(
    loanAmount,
    interestRate,
    tenure,
  );
  return roundUp((loanAmount + totalInterestAmt) / tenure);
};

export const onCalculateLoanAmountIncludedInterest = (
  loanAmount: number,
  interestRate: number,
  tenure: number,
) => {
  const totalInterestAmt = +onCalculateTotalInterest(
    loanAmount,
    interestRate,
    tenure,
  );
  return loanAmount + totalInterestAmt;
};

export const onCalculateLoanPayableAmount = () => {
  return 0;
};

export const onGetFirstInstalmentDate = () => {
  const today = new Date();
  const date = today.getDate();
  if (date <= 10) {
    today.setDate(11);
    today.setMonth(today.getMonth() + 1);
    return today;
  }
  if (date >= 11 && date <= 20) {
    today.setDate(21);
    today.setMonth(today.getMonth() + 1);
    return today;
  }
  if (date >= 21 && date <= 31) {
    today.setDate(1);
    today.setMonth(today.getMonth() + 2);
    return today;
  }
  today.setDate(11);
  today.setMonth(today.getMonth() + 1);
  return today;
};

export const onGetMonthlyInstalmentDay = () => {
  const today = new Date();
  const date = today.getDate();
  if (date <= 10) {
    return 11;
  }
  if (date >= 11 && date <= 20) {
    return 21;
  }
  if (date >= 21 && date <= 31) {
    return 1;
  }
  return 11;
};

export const onCalculateFinalInstalmentAmount = (
  loanAmount: number,
  interestRate: number,
  tenure: number,
) => {
  return (
    onCalculateLoanAmountIncludedInterest(loanAmount, interestRate, tenure) -
    onCalculateEstimatedMonthlyAmount(loanAmount, interestRate, tenure) *
      (tenure - 1)
  );
};

export const onCalculateNetDisbursementAmount = (
  loanAmount: number,
  processingFee: number,
  stampingDutyPercentage: number,
  productStampingFeeToDeduct: number,
  loanInsuranceAmount: number,
  status?: string,
) => {
  if (status === "APPROVED") {
    const stampingFee = onCalculateStampingDutyFee(
      loanAmount,
      stampingDutyPercentage,
    );
    return (
      loanAmount -
      processingFee -
      stampingFee +
      productStampingFeeToDeduct -
      loanInsuranceAmount
    );
  } else {
    return 0;
  }
};

export const onCalculateTotalAmountOfTradeReference = (
  tradeReference: TradeReference[],
) => {
  let total = 0;

  tradeReference.map((details) => {
    total += parseInt(details.amount.replace(/,/g, ""), 10);
  });

  return total;
};
