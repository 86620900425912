import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getCurrentUserThunk,
  getWhitelistMemberThunk,
} from "../../services/userService/userThunk";

export type LoginPayload = {
  username: string;
  password: string;
};

export type ResetPasswordPayload = {
  username: string;
};

export type Race = {
  id: string;
  adminId: string;
  code: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type GrossSalary = {
  id: string;
  adminId: string;
  minSalary: number;
  maxSalary: number;
  createdAt?: string;
  updatedAt?: string;
};

export type User = {
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  isNric?: boolean;
  identityCardType?: string;
  nric?: string;
  phoneNo?: string;
  residentialAddress1?: string;
  residentialAddress2?: string;
  residentialAddress3?: string;
  city?: string;
  postcode?: number;
  state?: string;
  grossSalaryRangeId?: string;
  grossSalaryRange?: GrossSalary;
  raceId?: string;
  race?: Race;
  email?: string;
  agreeMarketing?: boolean;
  agreeTncAndPp?: boolean;
  partnerId?: string;
  redCashEligibleDate?: string;
  createdAt?: string;
  updatedAt?: string;
  icType?: string;
  tinNumber?: string;
};

export type whitelistMember = {
  id?: string;
  phoneNo?: string;
  expiredAt?: string;
  productCode?: string;
  createdAt?: string;
  updatedAt?: string;
  startDate?: string;
};

export type UserState = {
  registrationFormData: User;
  currentUser: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: User;
  };
  hasLoggedUser: boolean;
  whitelistMember: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: whitelistMember[];
    total: number;
    currentPage: number;
    pageSize: number;
  };
};

const initialState: UserState = {
  hasLoggedUser: false,
  registrationFormData: {
    firstName: "",
    lastName: "",
    isNric: true,
    nric: "",
    phoneNo: "",
    residentialAddress1: "",
    residentialAddress2: "",
    residentialAddress3: "",
    city: "",
    postcode: 0,
    state: "",
    raceId: "",
    email: "",
  },
  currentUser: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      id: undefined,
      username: undefined,
    },
  },
  whitelistMember: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
    total: 0,
    currentPage: 1,
    pageSize: 10,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser.data = action.payload;
    },
    setRegistrationFormData: (state, action: PayloadAction<User>) => {
      state.registrationFormData = action.payload;
    },
    setHasLoggedUser: (state, action: PayloadAction<boolean>) => {
      state.hasLoggedUser = action.payload;
    },
    userStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserThunk.pending, (state) => {
        state.currentUser.isLoading = true;
      })
      .addCase(getCurrentUserThunk.fulfilled, (state, action) => {
        state.currentUser.isLoading = false;
        state.currentUser.data = action.payload;
      })
      .addCase(getCurrentUserThunk.rejected, (state, action) => {
        state.currentUser.isLoading = false;
        state.currentUser.isError = true;
        state.currentUser.errorMessage = action.payload;
      });

    builder
      .addCase(getWhitelistMemberThunk.pending, (state) => {
        state.whitelistMember.isLoading = true;
      })
      .addCase(getWhitelistMemberThunk.fulfilled, (state, action) => {
        state.whitelistMember.isLoading = false;
        state.whitelistMember.data = action.payload.data;
        state.whitelistMember.currentPage = action.payload.currentPage;
        state.whitelistMember.pageSize = action.payload.pageSize;
        state.whitelistMember.total = action.payload.total;
      })
      .addCase(getWhitelistMemberThunk.rejected, (state, action) => {
        state.whitelistMember.isLoading = false;
        state.whitelistMember.isError = true;
        state.whitelistMember.errorMessage = action.payload;
      });
  },
});

export const {
  setCurrentUser,
  setRegistrationFormData,
  setHasLoggedUser,
  userStateReset,
} = userSlice.actions;

export default userSlice.reducer;
