import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../../helpers/errorMessage";
import regionService from "./regionService";

export const getAllRegionThunk = createAsyncThunk(
  "region/getAll",
  async (requestData: any, thunkAPI) => {
    try {
      return await regionService.getAllRegion(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createRegionThunk = createAsyncThunk(
  "region/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await regionService.createRegion(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRegionThunk = createAsyncThunk(
  "region/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await regionService.updateRegion(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteRegionThunk = createAsyncThunk(
  "region/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await regionService.deleteRegion(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
