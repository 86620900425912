import { Tabs, Tag } from "antd";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";

import { RescheduleTypeEnum } from "../../../enum/reschedule";
import { AppDispatch, RootState } from "../../../store/store";
import RescheduleTable from "./components/rescheduleTable";

import type { TabsProps } from "antd";
import {
  getOpenRescheduleListingThunk,
  getPendingRescheduleListingThunk,
  getVoidRescheduleListingThunk,
  getApprovedRescheduleListingThunk,
  getReferRescheduleListingThunk,
} from "../../../services/rescheduleService/rescheduleThunk";
import { useLifecycles } from "react-use";

const RescheduleListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const {
    openRescheduleListing,
    pendingRescheduleListing,
    voidRescheduleListing,
    approvedRescheduleListing,
    referbackRescheduleListing,
  } = useSelector((state: RootState) => state.reschedule);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [activeTab, pageNum, pageSize],
  );

  const fetchOpenRescheduleListing = (params: any) => {
    dispatch(
      getOpenRescheduleListingThunk({
        ...params,
        filters: { status: RescheduleTypeEnum.OPEN },
      }),
    );
  };

  const fetchPendingRescheduleListing = (params: any) => {
    dispatch(
      getPendingRescheduleListingThunk({
        ...params,
        filters: { status: RescheduleTypeEnum.PENDING },
      }),
    );
  };

  const fetchVoidRescheduleListing = (params: any) => {
    dispatch(
      getVoidRescheduleListingThunk({
        ...params,
        filters: { status: RescheduleTypeEnum.VOID },
      }),
    );
  };

  const fetchApproveRescheduleListing = (params: any) => {
    dispatch(
      getApprovedRescheduleListingThunk({
        ...params,
        filters: { status: RescheduleTypeEnum.APPROVED },
      }),
    );
  };

  const fetchReferbackRescheduleListing = (params: any) => {
    dispatch(
      getReferRescheduleListingThunk({
        ...params,
        filters: { status: RescheduleTypeEnum.REFERBACK },
      }),
    );
  };

  const items: TabsProps["items"] = [
    {
      key: RescheduleTypeEnum.OPEN,
      label: (
        <span>
          Open{" "}
          {openRescheduleListing?.data?.data != null && (
            <Tag>{openRescheduleListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RescheduleTable
          key="reschedule-open"
          listingState={openRescheduleListing}
          callback={fetchOpenRescheduleListing}
          status={RescheduleTypeEnum.OPEN}
        />
      ),
    },
    {
      key: RescheduleTypeEnum.PENDING,
      label: (
        <span>
          Pending{" "}
          {pendingRescheduleListing?.data?.data != null && (
            <Tag>{pendingRescheduleListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RescheduleTable
          key="reschedule-pending"
          listingState={pendingRescheduleListing}
          callback={fetchPendingRescheduleListing}
          status={RescheduleTypeEnum.PENDING}
        />
      ),
    },

    {
      key: RescheduleTypeEnum.REFERBACK,
      label: (
        <span>
          REFERBACK{" "}
          {referbackRescheduleListing?.data?.data != null && (
            <Tag>{referbackRescheduleListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RescheduleTable
          key="reschedule-approved"
          listingState={referbackRescheduleListing}
          callback={fetchReferbackRescheduleListing}
          status={RescheduleTypeEnum.REFERBACK}
        />
      ),
    },
    {
      key: RescheduleTypeEnum.VOID,
      label: (
        <span>
          Void{" "}
          {voidRescheduleListing?.data?.data != null && (
            <Tag>{voidRescheduleListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RescheduleTable
          key="reschedule-void"
          listingState={voidRescheduleListing}
          callback={fetchVoidRescheduleListing}
          status={RescheduleTypeEnum.VOID}
        />
      ),
    },
    {
      key: RescheduleTypeEnum.APPROVED,
      label: (
        <span>
          Approved{" "}
          {approvedRescheduleListing?.data?.data != null && (
            <Tag>{approvedRescheduleListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RescheduleTable
          key="reschedule-approved"
          listingState={approvedRescheduleListing}
          callback={fetchApproveRescheduleListing}
          status={RescheduleTypeEnum.APPROVED}
        />
      ),
    },
  ];

  const onTabChange = (key: string) => {
    if (key === RescheduleTypeEnum.OPEN) {
      fetchOpenRescheduleListing(initialParams);
    } else if (key === RescheduleTypeEnum.PENDING) {
      fetchPendingRescheduleListing(initialParams);
    } else if (key === RescheduleTypeEnum.VOID) {
      fetchVoidRescheduleListing(initialParams);
    } else if (key === RescheduleTypeEnum.APPROVED) {
      fetchApproveRescheduleListing(initialParams);
    } else if (key === RescheduleTypeEnum.REFERBACK) {
      fetchReferbackRescheduleListing(initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useEffect(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };
    fetchOpenRescheduleListing(params);
    fetchPendingRescheduleListing(params);
    fetchVoidRescheduleListing(params);
    fetchApproveRescheduleListing(params);
    fetchReferbackRescheduleListing(params);
  }, []);

  return (
    <>
      <Helmet>
        <title>Reschedule Listing - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Reschedule" />
      <Tabs
        defaultActiveKey={activeTab ?? RescheduleTypeEnum.OPEN}
        items={items}
        onChange={onTabChange}
      />
    </>
  );
};

export default RescheduleListingPage;
