import { createAsyncThunk } from "@reduxjs/toolkit";

import { CreateManualPaymentRequest } from "../../features/payment/paymentSlice";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import {
  createManualPayment,
  getPaymentHistory,
  uploadManualPayment,
} from "./paymentService";

export const getPaymentHistoryThunk = createAsyncThunk(
  "payment/getAllHistory",
  async (requestData: any, thunkAPI) => {
    try {
      return await getPaymentHistory(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createManualPaymentThunk = createAsyncThunk(
  "payment/createManualPayment",
  async (requestData: CreateManualPaymentRequest, thunkAPI) => {
    try {
      return await createManualPayment(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const uploadManualPaymentFileThunk = createAsyncThunk(
  "payment/uploadManualPaymentFile",
  async (requestData: any, thunkAPI) => {
    try {
      return await uploadManualPayment(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
