import { Button, Input, Select, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { numberWithCommas } from "../../../../helpers/number";
import { getSignedAgreementsThunk } from "../../../../services/agreementService/agreementThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";

import type { TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import type { ColumnsType } from "antd/es/table";
import type { Agreement } from "../../../../features/agreement/agreementSlice";
import { getSmeCompletedSigningThunk } from "../../../../services/smeAgreementService/smeAgreementThunk";

const { Search } = Input;

const SmeCompletedSigningTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { completedSmeAgreementSigningList } = useSelector(
    (state: RootState) => state.smeAgreement,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
    console.log(type);
  };

  const tablePagination = {
    total: completedSmeAgreementSigningList?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Approval Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() =>
            navigate(`/sme/agreement/details/${record.smeAgreementId}`)
          }
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.applicantName ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.applicantNric ?? "-",
    },
    {
      title: "Finance Amount",
      key: "financeAmount",
      render: (_, record) =>
        record?.financeAmount != null
          ? `RM ${numberWithCommas(record?.financeAmount)}`
          : "-",
    },
  ];

  const fetchAgreements = async (params?: any) => {
    await dispatch(
      getSmeCompletedSigningThunk({
        query: params,
        status: "completed",
      }),
    );
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchAgreements({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
      // setFilterState({ ...initialParams.filters, [searchFiled]: value });
    } else {
      fetchAgreements({
        ...initialParams,
        filters: { ...filterState },
      });
      setSearchValue("");
    }
  };

  const handleTableChange: TableProps<any>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
            search: {
              [searchFiled]: searchValue.toUpperCase(),
            },
          };

    fetchAgreements(param);
    setSortedInfo(sorter as SorterResult<Agreement>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>Completed Signing - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
            { value: "name", label: "Applicant Name" },
            { value: "nric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={completedSmeAgreementSigningList?.data?.data ?? []}
        loading={completedSmeAgreementSigningList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default SmeCompletedSigningTab;
