import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "@ant-design/pro-components";
import { AppDispatch, RootState } from "../../store/store";
import { Tabs, TabsProps, Tag } from "antd";
import {
  getUnclearSpecialTaggingListThunk,
  getClearSpecialTaggingListThunk,
} from "../../services/specialTaggingService/specialTaggingThunk";
import SpecialTaggingTable from "./specialTaggingTable";
import { SPECIAL_TAGGING_STATUS } from "../../enum/specialTagging";
import { useLocation, useNavigate } from "react-router-dom";

const SpecialTaggingListing = () => {
  const dispatch = useDispatch<AppDispatch>();

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const { clearSpecialTaggingList, unclearSpecialTaggingList } = useSelector(
    (state: RootState) => state.specialTagging,
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const fetchUnclearList = async (params?: any) => {
    const param = {
      ...params,
      filters: { status: SPECIAL_TAGGING_STATUS.UNCLEAR },
    };

    await dispatch(getUnclearSpecialTaggingListThunk(param));
  };

  const fetchClearList = async (params?: any) => {
    const param = {
      ...params,
      filters: { status: SPECIAL_TAGGING_STATUS.CLEAR },
    };

    await dispatch(getClearSpecialTaggingListThunk(param));
  };

  const initialCallbackState = {
    fetchUnclearList,
    fetchClearList,
  };

  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    callbackState.fetchUnclearList(initialParams);
    callbackState.fetchClearList(initialParams);
  }, [callbackState, initialParams]);

  const callback = (params: any, status: string) => {
    if (status === SPECIAL_TAGGING_STATUS.UNCLEAR) {
      callbackState.fetchUnclearList(params);
    } else {
      callbackState.fetchClearList(params);
    }
  };

  const onTabChange = (key: string) => {
    queryParams.set("type", key);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const items: TabsProps["items"] = [
    {
      key: "unclear",
      label: (
        <span>
          Unclear{" "}
          {unclearSpecialTaggingList?.data?.data != null && (
            <Tag>{unclearSpecialTaggingList?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <SpecialTaggingTable
          dataSource={unclearSpecialTaggingList}
          status={SPECIAL_TAGGING_STATUS.UNCLEAR}
          callback={callback}
        />
      ),
    },
    {
      key: "clear",
      label: (
        <span>
          Clear{" "}
          {clearSpecialTaggingList?.data?.data != null && (
            <Tag>{clearSpecialTaggingList?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <SpecialTaggingTable
          dataSource={clearSpecialTaggingList}
          status={SPECIAL_TAGGING_STATUS.CLEAR}
          callback={callback}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Special Tagging Listing" />
      <Tabs
        defaultActiveKey="unclear"
        items={items}
        onChange={onTabChange}
        destroyInactiveTabPane
      />
    </>
  );
};

export default SpecialTaggingListing;
