import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { SME_ORGANIZATION_TYPE } from "../../enum/smeApplication";

const getRequiredFileByPartnerType = async (organizationType: SME_ORGANIZATION_TYPE) => {
  const url = `/redCash/api/v1.0/sme/file/getRequiredFileByPartnerType/type/${organizationType}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const smeFileService = {
  getRequiredFileByPartnerType,
};

export default smeFileService;