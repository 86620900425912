import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getLoanInsuranceBatch1 = async (requestData: any) => {
  const params = {
    ...requestData,
  };
  const url = `/redCash/api/v1.0/loanInsuranceBatch1-listing`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getLoanInsuranceBatch2 = async (params: any) => {
  const url = `/redCash/api/v1.0/loanInsuranceBatch2-listing`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const patchLoanInsuranceBatch = async (requestData: any) => {
  const { id, body } = requestData;

  const url = `/redCash/api/v1.0/loanInsuranceBatch/id/${id}`;
  const res = await beSetupAxiosInstance.patch(url, body);
  return res.data;
};

const getLoanInsuranceReport = async (requestData: any) => {
  const url = `/redCash/api/v1.0/loanInsuranceBatch/report/${requestData.id}`;
  const res = await beSetupAxiosInstance.post(url, {
    downloadBy: requestData.downloadBy,
  });
  return res.data;
};

const uploadReport = async (requestData: any) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const url = `/redCash/api/v1.0/loanInsuranceBatch/reUploadReport`;
  const res = await beSetupAxiosInstance.post(url, requestData, config);
  return res.data;
};

const loanInsuranceService = {
  getLoanInsuranceBatch1,
  getLoanInsuranceBatch2,
  patchLoanInsuranceBatch,
  getLoanInsuranceReport,
  uploadReport,
};

export default loanInsuranceService;
