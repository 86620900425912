import "./index.less";

import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  UploadFile,
  UploadProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InboxOutlined } from "@ant-design/icons";

import {
  getAccountDetailsThunk,
  getRefundChargeConfigThunk,
} from "../../services/collectionService/collectionThunk";
import { waiverFileUploadThunk } from "../../services/fileService/fileThunk";
import { getBankFromMaster } from "../../services/masterService/masterService";
import { createRefundCasesThunk } from "../../services/refundService/refundThunk";
import { AppDispatch, RootState } from "../../store/store";
import { getTaxThunk } from "../../services/taxService/taxThunk";
import { refundAdminCharge } from "../../enum/refund";

export type RefundFileListProps = {
  entityId?: string;
  status?: string;
  type?: "refund" | "unmatched" | string;
  callback?: any;
  isUnmatched?: boolean;
};
const RequestRefund: React.FC<RefundFileListProps> = (props) => {
  const { entityId, status, type, callback, isUnmatched } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [bankList, setBankList] = useState<any>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { tax } = useSelector((state: RootState) => state.tax);
  const { refundChargeConfig } = useSelector(
    (state: RootState) => state.collection,
  );
  const [ibgRoutingNumber, setIbgRoutingNumber] = useState<any>("");

  const uploadProps: UploadProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      fileList.push(file);
      // setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const getAccountDetails = async (value: string) => {
    if (value.length !== 10) {
      return;
    }

    message.loading("Please wait", 1.2);
    await dispatch(getAccountDetailsThunk({ agreementNo: value }))
      .unwrap()
      .then((res: any) => {
        const refundAdminCharge = refundChargeConfig.data?.data[0].refundCharge
          ? refundChargeConfig.data?.data[0].refundCharge
          : 5 + (5 * tax.data.tax) / 100; //todo config
        if (
          parseFloat(res.accountDetails.extraPaymentAmount) >
            refundAdminCharge &&
          res.accountDetails.status !== "CLOSED"
        ) {
          const bank = bankList.find(
            (item: any) =>
              item.name.toUpperCase() ===
              res.agreementDetails.application.applicantBankName,
          );

          if (bank) {
            setIbgRoutingNumber(bank.ibgRoutingNumber);
          }

          form.setFieldsValue({
            contactPhoneNo: res.agreementDetails.application.applicantPhoneNo,
            bank: res.agreementDetails.application.applicantBankName,
            bankAccountNo:
              res.agreementDetails.application.personalBankAccountNo,
            extraPaymentAmount: res.accountDetails.extraPaymentAmount,
            refundAdminCharge: refundAdminCharge,
            refundAmount:
              +res.accountDetails.extraPaymentAmount - refundAdminCharge,
            ibgRoutingNumber: bank?.ibgRoutingNumber,
          });
        } else {
          message.error("Agreement number is not available for refund.");
          form.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
        form.resetFields();
        form.setFields([
          {
            name: "agreementNo",
            value: value,
            errors: ["Agreement number is not available for refund."],
          },
        ]);
      });
  };

  const onFinish = async () => {
    await form.validateFields().then(async (values) => {
      if (
        parseFloat(values.extraPaymentAmount) <
        parseFloat(values.refundAdminCharge)
      ) {
        message.error(
          "Refund admin charge cannot be more than extra payment amount.",
        );
        return;
      }
      const payload = {
        agreementNo: values.agreementNo,
        contactPhoneNo: values.contactPhoneNo,
        bank: values.bank,
        bankAccountNo: values.bankAccountNo,
        paymentMethod: values.paymentMethod,
        extraPaymentAmount: parseFloat(values.extraPaymentAmount),
        refundAdminCharge: parseFloat(values.refundAdminCharge),
        refundAmount: parseFloat(values.refundAmount),
        remark: values.remark,
        ibgRoutingNumber,
      };
      await dispatch(createRefundCasesThunk(payload))
        .then(async (res: any) => {
          if (fileList.length > 0) {
            for (let i = 0; i < fileList.length; i++) {
              const data = new FormData();
              data.append("entityId", res.payload.id);
              data.append("fileType", "REFUND");
              data.append("file", fileList[i] as RcFile);

              await dispatch(waiverFileUploadThunk(data));
            }
          }
        })
        .finally(() => {
          setIsModalOpen(false);
          form.resetFields();
          setFileList([]);
          if (callback) callback(status);
        });
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const getBankList = async () => {
    await getBankFromMaster().then((res: any) => {
      console.log(res);
      setBankList(res);
    });
  };

  const fetchTax = async (params?: any) => {
    await dispatch(getTaxThunk(params));
  };

  useEffect(() => {
    getBankList();
    fetchTax();
    dispatch(getRefundChargeConfigThunk());
  }, []);

  return (
    <>
      {!isUnmatched && (
        <Button type="primary" onClick={() => openModal()}>
          Request Refund
        </Button>
      )}

      <Modal
        title="Request Refund"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            label="Agreement Number"
            name="agreementNo"
            rules={[{ required: true, message: "Agreement No is required" }]}
          >
            <Input
              placeholder="Please input"
              name="agreementNo"
              style={{ width: "100%" }}
              onChange={(e) => {
                getAccountDetails(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Contact Phone No"
            name="contactPhoneNo"
            rules={[
              { required: true, message: "Contact phone no is required" },
            ]}
          >
            <Input
              placeholder="Please input"
              name="contactPhoneNo"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            label="Bank"
            name="bank"
            rules={[{ required: true, message: "Bank is required" }]}
          >
            <Select
              placeholder="Please select"
              style={{ width: "100%" }}
              options={
                bankList?.map((item: any) => ({
                  label: item.name,
                  value: item.name,
                })) ?? []
              }
              onChange={(value) => {
                const bank = bankList.find((item: any) => item.name === value);
                callback({ ibgRoutingNumber: bank?.ibgRoutingNumber });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Bank Account No"
            name="bankAccountNo"
            rules={[{ required: true, message: "Bank account no is required" }]}
          >
            <Input
              placeholder="Please input"
              name="bankAccountNo"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item label="Payment Method" name="paymentMethod">
            <Select>
              <Select.Option value="Bank">Bank</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Extra Payment Amount"
            name="extraPaymentAmount"
            rules={[
              { required: true, message: "Extra payment amount is required" },
            ]}
          >
            <InputNumber
              placeholder="Please input"
              style={{ width: "100%" }}
              controls={false}
              inputMode="numeric"
              type={"number"}
              prefix="RM"
              precision={2}
            />
          </Form.Item>

          <Form.Item
            label="Refund Admin Charge"
            name="refundAdminCharge"
            rules={[
              { required: true, message: "Refund admin charge is required" },
            ]}
          >
            <InputNumber
              placeholder="Please input"
              style={{ width: "100%" }}
              controls={false}
              inputMode="numeric"
              type={"number"}
              prefix="RM"
              precision={2}
            />
          </Form.Item>

          <Form.Item label="Refund Amount" name="refundAmount">
            <InputNumber
              placeholder="Please input"
              style={{ width: "100%" }}
              controls={false}
              inputMode="numeric"
              type={"number"}
              prefix="RM"
              precision={2}
            />
          </Form.Item>

          <Form.Item label="Supporting Documents" name="file">
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>

          <Form.Item label="Remark" name="remark">
            <TextArea
              placeholder="Please input"
              name="remark"
              style={{ width: "100%" }}
              minLength={5}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RequestRefund;
