import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import type { ColumnsType } from "antd/es/table";
import { AppDispatch, RootState } from "../../../../store/store";
import startCase from "lodash.startcase";
import { numberWithCommas } from "../../../../helpers/number";
import LoanInsurancePlanModal from "./LoanInsurancePlanModal";
import { getLoanInsuranceByIdThunk } from "../../../../services/masterService/masterThunk";
import { useParams } from "react-router-dom";

export type LoanInsurancePlanProps = {
  callback?: any;
};

const LoanInsurancePlan = (props: LoanInsurancePlanProps) => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const { loanInsuranceDetails } = useSelector(
    (state: RootState) => state.loanInsurance,
  );

  const fetchLoanInsuranceDetails = async () => {
    await dispatch(getLoanInsuranceByIdThunk({ id }));
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "Tenure (months)",
      dataIndex: "tenure",
      key: "tenure",
      render: (text, record) => {
        return startCase(record.tenure);
      },
    },
    {
      title: "Amount (RM)",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => {
        return numberWithCommas(record.amount);
      },
    },
    {
      title: "Tax (%)",
      dataIndex: "tax",
      key: "tax",
      render: (text, record) => {
        return numberWithCommas(record.tax);
      },
    },
    {
      title: "Tax (RM)",
      dataIndex: "tax",
      key: "tax",
      render: (text, record) => {
        return numberWithCommas(record.taxAmount);
      },
    },
    {
      title: "Amount with tax (RM)",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text, record) => {
        return numberWithCommas(record.amountWithTax);
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <LoanInsurancePlanModal
          loanInsuranceProductId={loanInsuranceDetails.data?.id}
          data={record}
          callback={() => {
            fetchLoanInsuranceDetails();
          }}
        />
      ),
    },
  ];

  return (
    <>
      <LoanInsurancePlanModal
        loanInsuranceProductId={loanInsuranceDetails.data?.id}
        callback={() => {
          fetchLoanInsuranceDetails();
        }}
      />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        dataSource={loanInsuranceDetails.data?.loanInsurancePlan ?? []}
        loading={loanInsuranceDetails.isLoading}
        rowClassName={() => "editable-row"}
        pagination={false}
      />
    </>
  );
};
export default LoanInsurancePlan;
