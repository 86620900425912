import { Button, message, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";

import UpdateWaiverAmountModal from "../../../../components/UpdateWaiverAmountModal";
import { waiverStateReset } from "../../../../features/waiver/waiverSlice";
import { numberWithCommas } from "../../../../helpers/number";
import { getTaxThunk } from "../../../../services/taxService/taxThunk";
import {
  editWaiverAgreementNoThunk,
  getWaiverChargesThunk,
} from "../../../../services/waiverService/waiverThunk";
import { AppDispatch, RootState } from "../../../../store/store";

import type { ColumnsType } from "antd/es/table";

const EditWaiverChargesByAgreementNoPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const waiverId = ":waiverId";
  const { accountDetailsId, agreementNo } = useParams();
  const [chargesData, setChargesData] = useState([{}]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [resultNotFound, setResultNotFound] = useState(true);
  const [searchResultData, setSearchResultData] = useState({
    status: "",
    batchNo: "",
    id: "",
    totalNoOfAccount: 0,
    createdAt: "",
    createdBy: "",
    verifier: "",
    verifyDate: "",
    decider: "",
    decidedDate: "",
  });
  const { tax } = useSelector((state: RootState) => state.tax);
  const taxToDivided = 1 + tax.data.tax / 100;
  const fetchTax = async (params?: any) => {
    await dispatch(getTaxThunk(params));
  };
  const chargesColumn: ColumnsType<any> = [
    {
      title: "Charges Type",
      dataIndex: "chargesType",
      key: "chargesType",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: number) =>
        `RM ${numberWithCommas(text / taxToDivided)}` ?? "-",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (_, record: any) =>
        record?.amount != null
          ? `RM ${numberWithCommas(
              record.amount - record.amount / taxToDivided,
            )}`
          : "-",
    },
    {
      title: "Amount After Tax",
      dataIndex: "amountTotal",
      key: "amountTotal",
      render: (_, record: any) =>
        record?.amount != null ? `RM ${numberWithCommas(record.amount)}` : "-",
    },
    {
      title: "Waiver Amount",
      dataIndex: "waiverAmount",
      key: "waiverAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Waiver Tax",
      dataIndex: "waiverTax",
      key: "waiverTax",
      render: (_, record: any) =>
        record?.waiverAmount != null
          ? `RM ${numberWithCommas((record.waiverAmount * tax.data.tax) / 100)}`
          : "-",
    },
    {
      title: "Waiver After Tax",
      dataIndex: "total",
      key: "total",
      render: (_, record: any) =>
        record?.waiverAmount != null
          ? `RM ${numberWithCommas(
              record.waiverAmount + (record.waiverAmount * tax.data.tax) / 100,
            )}`
          : "-",
    },
    {
      title: "Action",
      dataIndex: "waiverAmount",
      key: "waiverAmount",
      render: (text, record) => (
        <Space size="middle">
          <UpdateWaiverAmountModal
            chargeDetails={record}
            chargesArray={chargesData}
            tax={tax.data.tax}
            // callback={
            //     setPayload
            // }
          />
        </Space>
      ),
    },
  ];

  const fetchWaiverCharges = async (
    waiverId: string,
    accountDetailsId: string,
    agreementNo: string,
  ) => {
    const payload = {
      waiverId,
      accountDetailsId,
    };

    let waiveCharges = null;

    await dispatch(getWaiverChargesThunk(payload)).then((res) => {
      if (res.payload.data.length > 0) {
        waiveCharges = res.payload.data;
        setResultNotFound(false);
      }
    });

    if (waiveCharges) {
      setChargesData(waiveCharges);
    } else {
      message.error("Result not found");
    }
  };

  const updateWaiver = () => {
    setUpdateLoading(true);
    const payload = {
      charges: chargesData,
      accountDetailsId: accountDetailsId,
      waiverId: waiverId,
      username: localStorage.getItem("username"),
    };

    dispatch(editWaiverAgreementNoThunk(payload))
      .then(() => {
        setUpdateLoading(false);
        message.success("Updated waiver amount");
      })
      .catch(() => {
        message.error("Failed to insert waiver record");
        setUpdateLoading(false);
      });
  };

  const initialCallbackState = { fetchWaiverCharges };
  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    if (waiverId != null && accountDetailsId != null && agreementNo != null)
      callbackState.fetchWaiverCharges(waiverId, accountDetailsId, agreementNo);
    fetchTax();
  }, [accountDetailsId, agreementNo, callbackState, waiverId]);

  const navigate = useNavigate();

  const onBack = () => {
    dispatch(waiverStateReset());
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>WaiverDetails - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title={
          <Space align="center">
            <h3 className="m-0">Edit Waiver</h3>
            <Tag>{searchResultData.status}</Tag>
          </Space>
        }
        onBack={onBack}
      />
      {!resultNotFound && (
        <div className="edit-waiver-section-container">
          <div className="info-container">
            <Table
              rowKey="id"
              className="min-w-fit"
              columns={chargesColumn}
              dataSource={chargesData}
            />
          </div>
          <br />

          <Button
            loading={updateLoading}
            onClick={() => updateWaiver()}
            type="primary"
          >
            Update
          </Button>
        </div>
      )}
    </>
  );
};

export default EditWaiverChargesByAgreementNoPage;
