import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

const getCityStateByPostcode = async (requestData: any) => {
  const { postcode } = requestData;
  const url = `/redCash/master/api/v1.0/city-state/${postcode}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const locationService = {
  getCityStateByPostcode,
};

export default locationService;
