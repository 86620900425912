import { caseAssignmentAxiosInstance } from "../../../axios/caseAssignmentAxios";

const getAllGroup = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/caseAssignment/api/v1/group/listing`;
  const res = await caseAssignmentAxiosInstance.get(url, { params });
  return res.data;
};

const createGroup = async (requestData: any) => {
  const url = `/caseAssignment/api/v1/group`;
  const res = await caseAssignmentAxiosInstance.post(url, requestData);
  return res.data;
};

const updateGroup = async (requestData: any) => {
  const { groupId, payload } = requestData;
  const url = `/caseAssignment/api/v1/group/id/${groupId}`;
  const res = await caseAssignmentAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteGroup = async (requestData: any) => {
  const { groupId } = requestData;
  const url = `/caseAssignment/api/v1/group/id/${groupId}`;
  const res = await caseAssignmentAxiosInstance.delete(url);
  return res.data;
};

const groupService = {
  getAllGroup,
  createGroup,
  updateGroup,
  deleteGroup,
};

export default groupService;
