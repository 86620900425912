import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getSmeApplicationCount = async () => {
  const url = `/redCash/api/v1.0/smeApplication/count`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getPendingSmeApplicationsListing = async (requestData: any) => {
  const params = {
    filters: requestData.filters,
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/smeApplication/status/pending`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getReferBackSmeApplicationsListing = async (requestData: any) => {
  const params = {
    filters: requestData.filters,
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/smeApplication/status/referback`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getSmeApplicationsListing = async (requestData: any) => {
  const params = {
    filters: requestData.filters,
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/smeApplications`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getSmeApplicationDetails = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/${applicationId}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getSmeApplicationRemarks = async (requestData: any) => {
  const { id } = requestData;
  const params = {
    filters: requestData.filters,
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };
  const url = `/redCash/api/v1.0/smeApplication/${id}/remarks`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const updateSmeApplicationStatus = async (requestData: any) => {
  const { id, payload } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/${id}/status`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const approveSmeApplication = async (requestData: any) => {
  const { id, language, payload } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/${id}/language/${language}/status/approved`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const cancelSmeApplication = async (requestData: any) => {
  const { id, payload } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/${id}/status/cancelled`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateSmeApplicationFinanceAmount = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/update-finance-amount/id/${applicationId}`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateSmeApplicationLoanTenure = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/update-loan-tenure/id/${applicationId}`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const getHistoryLogsBySmeApplicationId = async (id: string) => {
  const url = `/redCash/admin/api/v1.0/historyLog/smeApplication/${id}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getSmePartnerCommissionResults = async (id: string) => {
  const url = `/redCash/api/v1.0/smePartner/${id}/commissionResults`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const patchSmePartnershipApplicationInfoById = async (payload: any) => {
  const { id, ...rest } = payload;

  const url = `/redCash/api/v1.0/smeApplication/${id}/update-partnership-application-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const patchSmeSolePropsApplicationInfoById = async (payload: any) => {
  const { id, ...rest } = payload;

  const url = `/redCash/api/v1.0/smeApplication/${id}/update-sole-props-application-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const patchSmeIndividualApplicationInfoById = async (payload: any) => {
  const { id, ...rest } = payload;

  const url = `/redCash/api/v1.0/smeApplication/${id}/update-individual-application-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const patchSmePartnershipCompanyInfoById = async (payload: any) => {
  const { id, ...rest } = payload;

  const url = `/redCash/api/v1.0/smeApplication/${id}/update-partnership-company-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const patchSmePartnershipDirectorById = async (payload: any) => {
  const { id, ...rest } = payload;

  const url = `/redCash/api/v1.0/partnership-director/${id}`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const patchSmeCompanyDirectorById = async (payload: any) => {
  const { id, ...rest } = payload;

  const url = `/redCash/api/v1.0/company-director/${id}`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const createSmePartnershipDirector = async (payload: any) => {
  const url = `/redCash/api/v1.0/smePartnership/director`;
  const res = await beSetupAxiosInstance.post(url, payload);
  return res.data;
};

const updateSmePartnershipDisbursementInfoById = async (payload: any) => {
  const { id, ...rest } = payload;
  const url = `/redCash/api/v1.0/smePartnership/${id}/update-disbursement-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const updateSmeIndividualDisbursementInfoById = async (payload: any) => {
  const { id, ...rest } = payload;
  const url = `/redCash/api/v1.0/smeIndividual/${id}/update-disbursement-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const updateSmeSolePropsDisbursementInfoById = async (payload: any) => {
  const { id, ...rest } = payload;
  const url = `/redCash/api/v1.0/smeSoleProps/${id}/update-disbursement-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const updateSmeCompanyDisbursementInfoById = async (payload: any) => {
  const { id, ...rest } = payload;
  const url = `/redCash/api/v1.0/smeCompany/${id}/update-disbursement-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const patchSmeCompanyCompanyInfoById = async (payload: any) => {
  const { id, ...rest } = payload;

  const url = `/redCash/api/v1.0/smeApplication/${id}/update-company-company-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const patchSmeSolePropsCompanyInfoById = async (payload: any) => {
  const { id, ...rest } = payload;

  const url = `/redCash/api/v1.0/smeApplication/${id}/update-sole-props-company-info`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const patchCreditRecordBySmeApplicationId = async (payload: any) => {
  const { id, ...rest } = payload;

  const url = `/redCash/api/v1.0/smeApplication/${id}/creditRecord`;
  const res = await beSetupAxiosInstance.patch(url, rest);
  return res.data;
};

const createSmeCompanyDirector = async (payload: any) => {
  const url = `/redCash/api/v1.0/smeCompany/director`;
  const res = await beSetupAxiosInstance.post(url, payload);
  return res.data;
};

const deleteSmeCompanyDirectorById = async (id: string) => {
  const url = `/redCash/api/v1.0/smeCompany/director/${id}`;
  const res = await beSetupAxiosInstance.delete(url);
  return res.data;
};

const deleteSmePartnershipDirectorById = async (id: string) => {
  const url = `/redCash/api/v1.0/smePartnership/director/${id}`;
  const res = await beSetupAxiosInstance.delete(url);
  return res.data;
};

const updateSmeApplicationInterestRate = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/update-interest-rate/id/${applicationId}`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const patchSmeCreditRecordStatusBySmeApplicationIdAndType = async (
  requestData: any,
) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/${id}/creditRecord/single`;
  const res = await beSetupAxiosInstance.patch(url, requestData);
  return res.data;
};

const regenerateSmeApplicationForm = async (id: string) => {
  const url = `/redCash/api/v1.0/smeApplication/${id}/regenerateApplicationForm`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const smeApplicationGlobalSearch = async (requestData: any) => {
  const { payload } = requestData;
  const params = {
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
    // sortField: requestData.sortField,
    // sortOrder: requestData.sortOrder,
  };
  const url = `/redCash/api/v1.0/smeApplications/global-search`;
  const res = await beSetupAxiosInstance.post(url, payload, { params });
  return res.data;
};

const smeApplicationService = {
  patchCreditRecordBySmeApplicationId,
  updateSmeSolePropsDisbursementInfoById,
  patchSmeSolePropsCompanyInfoById,
  patchSmeSolePropsApplicationInfoById,
  updateSmeIndividualDisbursementInfoById,
  patchSmeIndividualApplicationInfoById,
  updateSmeCompanyDisbursementInfoById,
  patchSmeCompanyDirectorById,
  patchSmeCompanyCompanyInfoById,
  updateSmePartnershipDisbursementInfoById,
  createSmePartnershipDirector,
  patchSmePartnershipDirectorById,
  patchSmePartnershipCompanyInfoById,
  patchSmePartnershipApplicationInfoById,
  getPendingSmeApplicationsListing,
  getReferBackSmeApplicationsListing,
  getSmeApplicationsListing,
  getSmeApplicationDetails,
  getSmeApplicationRemarks,
  updateSmeApplicationStatus,
  approveSmeApplication,
  getSmeApplicationCount,
  cancelSmeApplication,
  updateSmeApplicationFinanceAmount,
  updateSmeApplicationLoanTenure,
  getHistoryLogsBySmeApplicationId,
  getSmePartnerCommissionResults,
  deleteSmePartnershipDirectorById,
  deleteSmeCompanyDirectorById,
  createSmeCompanyDirector,
  updateSmeApplicationInterestRate,
  patchSmeCreditRecordStatusBySmeApplicationIdAndType,
  regenerateSmeApplicationForm,
  smeApplicationGlobalSearch,
};

export default smeApplicationService;
