import "./index.less";

import {
  Button,
  Col,
  Collapse,
  message,
  Result,
  Row,
  Skeleton,
  Space,
  Tag,
} from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import ApplicationDetailsTab from "../../../components/ApplicationDetailsTab";
import CreateAgreementModal from "../../../components/CreateAgreementModal";
import InfoGrid from "../../../components/InfoGrid";
import InfoList from "../../../components/InfoList";
import InfoListRow from "../../../components/InfoList/InfoListRow";
import InternalRemarkTable from "../../../components/InternalRemarkTable";
import LoadingParagraph from "../../../components/LoadingParagraphLong";
import UpdateNetIncomeModal from "../../../components/UpdateNetIncomeModal";
import UploadedDocument from "../../../components/UploadedDocument";
import { FileTypeEnum } from "../../../enum/fileType";
import { agreementStateReset } from "../../../features/agreement/agreementSlice";
import { applicationDetailsStateReset } from "../../../features/application/applicationSlice";
import { numberWithCommas } from "../../../helpers/number";
import { getAgreementDetailsThunk } from "../../../services/agreementService/agreementThunk";
import {
  getApplicationCreditRecordThunk,
  getApplicationDetailsThunk,
  onReRunApplicationCreditEvaluationThunk,
} from "../../../services/applicationService/applicationThunk";
import { getFilesByEntityIdThunk } from "../../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../../store/store";
import {
  onCalculateEstimatedMonthlyAmount,
  onCalculateFinalInstalmentAmount,
  onCalculateStampingDutyFee,
  onCalculateTotalInstalmentAmount,
  onCalculateTotalInterest,
} from "../../../utils/calculation.util";
import { displayDate } from "../../../utils/datetime.util";
import UpdateApplicationAction from "./UpdateApplicationAction";
import { displayApplicationStatus } from "../../../utils/string.util";
import UpdateFinanceAmountModal from "./UpdateFinanceAmountModal";
import { ApplicationStatusEnum } from "../../../enum/applicationStepStatus";
import FinoryOcrTaskRef from "../../../components/FinoryOcrTaskRef";
import applicationService from "../../../services/applicationService/applicationService";
import { beSetupAxiosInstance } from "../../../axios/beSetupAxios";

const { Panel } = Collapse;

dayjs.extend(duration);

const ApplicationDetailsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { applicationId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const readOnly = queryParams.get("readOnly") === "true";

  const [finoryOcrUrl, setFinoryOcrUrl] = useState("");

  const [createAgreementModalOpen, setCreateAgreementModalOpen] =
    useState(false);

  const { applicationDetails, applicationFiles, creditRecords } = useSelector(
    (state: RootState) => state.application,
  );

  const handleIFrameLoading = () => {
    message.loading("Loading...", 0.5);
  };

  const getFinoryOcrTaskUrl = async () => {
    // Get finory embedded url
    // setLoading(true);
    // setOpenModal(true);
    await applicationService
      .getFinoryEmbeddedUrl(applicationDetails.data?.id ?? "-")

      .then((res: any) => {
        setFinoryOcrUrl(res);
        // setLoading(false);
      });
  };

  const employmentStartingFrom = dayjs(
    applicationDetails?.data?.employmentStartingFrom,
  );

  const currentDate = dayjs();
  const duration = dayjs.duration(currentDate.diff(employmentStartingFrom));
  const years = duration.years();
  const months = duration.months();

  let currentEmploymentDuration = "";
  if (years === 0) {
    currentEmploymentDuration = `${months} ${months <= 1 ? "month" : "months"}`;
  } else if (years === 1 && months === 0) {
    currentEmploymentDuration = "1 year";
  } else if (years === 1 && months > 0) {
    currentEmploymentDuration = `1 year ${months} ${
      months <= 1 ? "month" : "months"
    }`;
  } else if (years > 1 && months === 0) {
    currentEmploymentDuration = `${years} ${years <= 1 ? "year" : "years"}`;
  } else {
    currentEmploymentDuration = `${years} ${
      years === 1 ? "year" : "years"
    } ${months} ${months <= 1 ? "month" : "months"}`;
  }

  const showMiaInCcrisResult = (
    miaTwoTimes: string | undefined,
    miaThreeTimes: string | undefined,
  ) => {
    if (
      miaThreeTimes === undefined ||
      miaTwoTimes === undefined ||
      miaThreeTimes === null ||
      miaTwoTimes === null ||
      miaThreeTimes === "" ||
      miaTwoTimes === "" ||
      !miaThreeTimes ||
      !miaTwoTimes
    ) {
      return "NOT_AVAILABLE";
    }

    if (miaTwoTimes === "APPROVE") {
      if (miaThreeTimes === "APPROVE") {
        return "APPROVE";
      } else {
        return "REJECT";
      }
    } else if (miaTwoTimes === "NOT_AVAILABLE") {
      if (miaThreeTimes === "NOT_AVAILABLE") {
        return "NOT_AVAILABLE";
      }
    } else {
      return "REJECT";
    }
  };

  const loanApplicationData = [
    {
      title: "Application Date",
      value: displayDate(applicationDetails?.data?.submissionDate),
    },
    {
      title: "Application No.",
      value: applicationDetails?.data?.applicationNo ?? "-",
    },
    {
      title: "Campaign Code",
      value: applicationDetails?.data?.productReferralCode ?? "-",
    },
    {
      title: "Discount Amount",
      value: applicationDetails?.data?.productProcessingFeeToDiscount
        ? `RM ${numberWithCommas(
            applicationDetails?.data?.productProcessingFeeToDiscount,
          )}`
        : "-",
    },
    {
      title: "Applicant Name",
      value: applicationDetails?.data?.applicantFirstName,
    },
    {
      title: "Applicant NRIC",
      value: applicationDetails?.data?.applicantNric,
    },
    {
      title: "Contact No.",
      value: applicationDetails?.data?.applicantPhoneNo,
    },
    {
      title: "Email Address",
      value: applicationDetails?.data?.applicantEmail,
    },
    {
      title: "Applied Loan Amount",
      value: `RM ${numberWithCommas(applicationDetails?.data?.loanApplied)}`,
    },
    {
      title: "Monthly Instalment",
      value: `RM ${numberWithCommas(
        onCalculateEstimatedMonthlyAmount(
          applicationDetails?.data?.loanApplied ?? 0,
          applicationDetails?.data?.loanInterestRate ?? 0,
          applicationDetails?.data?.loanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Interest Rate",
      value: `${applicationDetails?.data?.loanInterestRate} %`,
    },
    {
      title: "Instalment Period",
      value: applicationDetails?.data?.loanTenure ?? "-",
    },
    {
      title: "Bank Name",
      value: applicationDetails?.data?.applicantBankName ?? "-",
    },
    {
      title: "Bank Account No.",
      value: applicationDetails?.data?.applicantPersonalBankNo ?? "-",
    },
    {
      title: "Loan Type",
      value: applicationDetails?.data?.loanType ?? "-",
    },
    {
      title: "Reason For Loan",
      value: `${
        applicationDetails?.data?.applicantLoanReason ??
        applicationDetails?.data?.loanReason?.name
      } ${
        applicationDetails?.data?.customReason
          ? "- " + applicationDetails?.data?.customReason
          : ""
      }`,
    },
    {
      title: "Marketing Consent",
      value: applicationDetails?.data?.applicantAcceptMarketing ? "YES" : "NO",
    },
    {
      title: "Partner ID No",
      value: applicationDetails?.data?.applicantPartnerId,
    },
    {
      title: "Customer Type",
      value: applicationDetails?.data?.customerType,
    },
    {
      title: "redONE Subscription Date",
      value: displayDate(applicationDetails?.data?.user?.redCashEligibleDate),
    },
    {
      title: "TIN",
      value: applicationDetails?.data?.user?.tinNumber ?? '-',
    },
  ];

  const showCcrisLegalStatus = () => {
    if (creditRecords?.data?.ccris_legal) {
      if (
        creditRecords?.data?.ccris_legal === "NOT_AVAILABLE" ||
        creditRecords?.data?.experian_legal === "NOT_AVAILABLE"
      ) {
        return "NOT_AVAILABLE";
      }

      if (
        creditRecords?.data?.ccris_legal === "APPROVE" &&
        creditRecords?.data?.experian_legal === "APPROVE"
      ) {
        return "APPROVE";
      } else {
        return "REJECT";
      }
    }

    return "-";
  };

  const creditAssessmentInfo1 = [
    {
      title: "Age",
      value: creditRecords?.data?.age ?? "-",
    },
    {
      title: "Net Income",
      value: (
        <Space size="middle">
          {`RM ${numberWithCommas(
            applicationDetails?.data?.actualMonthlySalary ?? 0,
          )}`}
          {!readOnly && (
            <UpdateNetIncomeModal
              applicationDetails={applicationDetails?.data}
              callback={() =>
                applicationId && fetchApplicationDetails(applicationId)
              }
            />
          )}
        </Space>
      ),
    },
    {
      title: "Bankruptcy Check (NRVB)",
      value: creditRecords?.data?.bankruptcy_check ?? "-",
    },
    {
      title: "AMLA (CCDI Report)",
      value: creditRecords?.data?.amla ?? "-",
    },
    {
      title: "i-Score",
      value: `${creditRecords.data?.iscore ?? "-"} (${
        creditRecords.data?.iscore_value ?? "-"
      })`,
    },
    {
      title: "Special Attention Flag",
      value: creditRecords?.data?.special_attention_flag ?? "-",
    },
    {
      title: "CCRIS Status Code (AKPK, etc.)",
      value: creditRecords?.data?.ccris_status_code ?? "-",
    },
    {
      title: "CCRIS Legal",
      value: showCcrisLegalStatus(),
    },
  ];

  const creditAssessmentInfo2 = [
    {
      title: "MIA In CCRIS",
      value: showMiaInCcrisResult(
        creditRecords?.data?.mia_in_ccris_two_times,
        creditRecords?.data?.mia_in_ccris_three_times,
      ),
    },
    // {
    //   title: "Trade Reference",
    //   value: (creditRecords?.data?.trade_reference_amount && applicationDetails?.data?.tradeReference) ? `${creditRecords?.data?.trade_reference_amount} (RM ${numberWithCommas(onCalculateTotalAmountOfTradeReference(applicationDetails?.data?.tradeReference))})` : "-",
    // },
    {
      title: "Trade Reference",
      value: creditRecords?.data?.trade_reference_amount ?? "-",
    },
    {
      title: "Years of Current Employment",
      value: applicationDetails.data?.currentEmploymentDuration ?? "-",
    },
    {
      title: "Employment Type",
      value: applicationDetails?.data?.applicantEmploymentType,
    },
    {
      title: "Employment Status",
      value: "ACTIVE", // Hardcode ACTIVE for now
    },
    {
      title: "Risk Profiling",
      value: creditRecords?.data?.risk_profile ?? "-",
    },
    {
      title: "DSR",
      value: creditRecords.data?.dsr_value ?? "-",
    },
    {
      title: "Eligible Loan Amount (ELA)",
      value:
        applicationDetails?.data?.eligibleLoanAmount != null
          ? `RM ${numberWithCommas(
              applicationDetails?.data?.eligibleLoanAmount,
            )}`
          : "-",
    },
  ];

  const applicationSummaryInfo = [
    {
      title: "Finance Amount",
      value: (
        <Space size="middle">
          {`RM ${numberWithCommas(
            applicationDetails?.data?.financeAmount ?? 0,
          )}`}
          {!readOnly &&
            (applicationDetails.data?.applicationStatus ===
              ApplicationStatusEnum.PENDING ||
              applicationDetails.data?.applicationStatus ===
                ApplicationStatusEnum.REFERBACK ||
              applicationDetails.data?.applicationStatus ===
                ApplicationStatusEnum.FOLLOWED_UP) && (
              <UpdateFinanceAmountModal
                applicationDetails={applicationDetails?.data}
                callback={() =>
                  applicationId && fetchApplicationDetails(applicationId)
                }
              />
            )}
        </Space>
      ),
    },
    {
      title: "Interest Amount",
      value: `RM ${numberWithCommas(
        onCalculateTotalInterest(
          applicationDetails?.data?.financeAmount ?? 0,
          applicationDetails?.data?.loanInterestRate ?? 0,
          applicationDetails?.data?.loanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Interest Rate",
      value: applicationDetails?.data?.loanInterestRate
        ? `${applicationDetails?.data?.loanInterestRate} %`
        : "-",
    },
    {
      title: "Instalment Period",
      value: `${applicationDetails?.data?.loanTenure} months`,
    },
    {
      title: "Total Instalment Amount",
      value: `RM ${numberWithCommas(
        onCalculateTotalInstalmentAmount(
          applicationDetails?.data?.financeAmount ?? 0,
          applicationDetails?.data?.loanInterestRate ?? 0,
          applicationDetails?.data?.loanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Monthly Instalment",
      value: `RM ${numberWithCommas(
        onCalculateEstimatedMonthlyAmount(
          applicationDetails?.data?.financeAmount ??
            applicationDetails?.data?.financeAmount ??
            0,
          applicationDetails?.data?.loanInterestRate ?? 0,
          applicationDetails?.data?.loanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Final Instalment Amount",
      value: `RM ${numberWithCommas(
        onCalculateFinalInstalmentAmount(
          applicationDetails?.data?.financeAmount ?? 0,
          applicationDetails?.data?.loanInterestRate ?? 0,
          applicationDetails?.data?.loanTenure ?? 0,
        ),
      )}`,
    },
    {
      title: "Net Salary/Income",
      value: `RM ${numberWithCommas(
        applicationDetails?.data?.actualMonthlySalary,
      )}`,
    },
    {
      title: "Processing Fee",
      value: applicationDetails?.data?.productProcessingFee
        ? `RM ${numberWithCommas(
            applicationDetails?.data?.productProcessingFee,
          )}`
        : "-",
    },
    {
      title: "Stamp Duty Fee",
      value: `RM ${numberWithCommas(
        onCalculateStampingDutyFee(
          applicationDetails?.data?.financeAmount ?? 0,
          applicationDetails?.data?.productStampingDutyPercentage ?? 0,
        ),
      )}`,
    },
    {
      title: "DSR",
      value: applicationDetails?.data?.dsr ?? "-",
    },
  ];

  const onBack = () => {
    navigate(-1);
    // if (
    //   applicationDetails.data?.applicationStatus ===
    //   ApplicationStatusEnum.PENDING
    // ) {
    //   queryParams.set("tab", "pending");
    // } else if (
    //   applicationDetails.data?.applicationStatus ===
    //   ApplicationStatusEnum.REFERBACK
    // ) {
    //   queryParams.set("tab", "referBack");
    // } else if (
    //   applicationDetails.data?.applicationStatus ===
    //   ApplicationStatusEnum.FOLLOWED_UP
    // ) {
    //   queryParams.set("tab", "followUp");
    // } else if (
    //   applicationDetails.data?.applicationStatus ===
    //   ApplicationStatusEnum.REJECTED
    // ) {
    //   queryParams.set("tab", "rejected");
    // } else if (
    //   applicationDetails.data?.applicationStatus ===
    //   ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT
    // ) {
    //   queryParams.set("tab", "referBackFromPendingDisbursement");
    // }
    // const newSearch = queryParams.toString();
    // navigate(
    //   {
    //     pathname: "/application-listing/verifying",
    //     search: newSearch,
    //   },
    //   { replace: true },
    // );
  };

  const fetchFileList = () => {
    dispatch(
      getFilesByEntityIdThunk({ entityId: applicationDetails?.data?.id }),
    );
  };

  const fetchApplicationDetails = (applicationId: string) => {
    dispatch(getApplicationDetailsThunk({ applicationId }))
      .unwrap()
      .then((res) => {
        if (res?.agreementId) {
          dispatch(
            getAgreementDetailsThunk({
              agreementId: res?.agreementId,
            }),
          );
        } else {
          dispatch(agreementStateReset());
        }
      });
    dispatch(getFilesByEntityIdThunk({ entityId: applicationId }));

    // get application credit record
    dispatch(getApplicationCreditRecordThunk({ applicationId: applicationId }));
  };

  const onReRunCreditEvaluationEngineOldCase = () => {
    dispatch(
      onReRunApplicationCreditEvaluationThunk({
        applicationId: applicationId,
        isNewApplication: "false",
      }),
    ).then(() => {
      message.success("Rerun credit evaluation engine successfully");
    });
  };

  const onReRunCreditEvaluationEngineNewCase = () => {
    dispatch(
      onReRunApplicationCreditEvaluationThunk({
        applicationId: applicationId,
        isNewApplication: "true",
      }),
    ).then(() => {
      message.success("Rerun credit evaluation engine successfully");
    });
  };

  const resetState = () => {
    dispatch(agreementStateReset());
    dispatch(applicationDetailsStateReset());
  };
  // useLifecycles(mount, unmount);
  useLifecycles(() => {
    resetState();
    applicationId && fetchApplicationDetails(applicationId);
  });

  useEffect(() => {
    getFinoryOcrTaskUrl();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Application Details - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="p-0 mb-4"
        onBack={onBack}
        title={
          <Space align="center">
            <h3 className="m-0">Application Details</h3>
            {!applicationDetails?.isLoading &&
              applicationDetails.data?.applicationStatus && (
                <Tag>
                  {displayApplicationStatus(
                    applicationDetails.data?.applicationStatus,
                  )}
                </Tag>
              )}
          </Space>
        }
        extra={[
          <CreateAgreementModal
            key={`CreateAgreementModal${applicationDetails.data?.id}`}
            modalOpen={createAgreementModalOpen}
          />,
        ]}
      />

      {applicationDetails.isLoading ? (
        <LoadingParagraph />
      ) : applicationDetails.data ? (
        <>
          <Collapse
            defaultActiveKey={["loanApplication", "applicationSummary"]}
            ghost
            expandIconPosition="end"
          >
            <Panel
              key="loanApplication"
              className="application-details-section-container"
              header={
                <h3 className="application-details-section-title">
                  Loan Application
                </h3>
              }
            >
              <InfoGrid data={loanApplicationData} />
            </Panel>

            <Panel
              key="documents"
              className="application-details-section-container"
              header={
                <h3 className="application-details-section-title">Documents</h3>
              }
            >
              <UploadedDocument
                readOnly={readOnly}
                entityId={applicationDetails.data.id}
                fileType={FileTypeEnum.SALARYSLIP}
                description="Latest 3 months of salary slip OR EPF Statement OR Form EA OR
              Borang B/BE tax receipt"
                defaultFileList={applicationFiles?.data?.salaryslip}
                callback={fetchFileList}
              />
              <UploadedDocument
                readOnly={readOnly}
                entityId={applicationDetails.data.id}
                fileType={FileTypeEnum.BANKSTATEMENT}
                description="Latest 3 months of bank statement (showing monthly salary credited or other credited income)"
                defaultFileList={applicationFiles?.data?.bankstatement}
              />
              <UploadedDocument
                readOnly={readOnly}
                entityId={applicationDetails.data.id}
                fileType={FileTypeEnum.OTHERSRELEVANTDOCUMENTS}
                description="Others Relevant Documents"
                defaultFileList={
                  applicationFiles?.data?.othersrelevantdocuments
                }
                callback={fetchFileList}
              />
            </Panel>

            {creditRecords?.data != null &&
            (
              Object.keys(creditRecords.data) as Array<
                keyof typeof creditRecords.data
              >
            ).some((key) =>
              creditRecords?.data != null
                ? creditRecords.data[key] === "-"
                : false,
            ) ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              <Panel
                key="creditAssessment"
                className="application-details-section-container"
                header={
                  <h3 className="application-details-section-title">
                    Credit Assessment
                  </h3>
                }
              >
                <Row gutter={[16, 16]} className="mb-4">
                  <Col span={12}>
                    <div className="info-container">
                      <InfoListRow
                        title="Bucket"
                        value={applicationDetails?.data?.bucket}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }}>
                    <InfoList data={creditAssessmentInfo1} />
                  </Col>
                  <Col sm={{ span: 24 }} md={{ span: 12 }}>
                    <InfoList data={creditAssessmentInfo2} />
                  </Col>
                </Row>
                <br />
                {applicationDetails.data?.applicationStatus !==
                  ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL &&
                  applicationDetails.data?.applicationStatus !==
                    ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT &&
                  applicationDetails.data?.applicationStatus !==
                    ApplicationStatusEnum.APPROVED && (
                    <Space direction={"horizontal"}>
                      {/*<Button*/}
                      {/*  onClick={() => onReRunCreditEvaluationEngineOldCase()}*/}
                      {/*>*/}
                      {/*  Rerun Engine (Without Trigger Experian)*/}
                      {/*</Button>*/}
                      {/*<Button*/}
                      {/*  onClick={() => onReRunCreditEvaluationEngineNewCase()}*/}
                      {/*>*/}
                      {/*  Rerun Engine (Trigger Experian)*/}
                      {/*</Button>*/}
                      <Button
                        onClick={async () => {
                          const url = `/redCash/admin/api/v1.0/application/${applicationId}/new-engine/isNewApplication/${true}`;
                          const res = await beSetupAxiosInstance.get(url);

                          message.success(
                            "Rerun credit evaluation engine successfully, please refresh the page after 10 second",
                          );
                        }}
                      >
                        Run New Engine
                      </Button>
                    </Space>
                  )}
              </Panel>
            )}

            <Panel
              key="applicationSummary"
              className="application-details-section-container"
              header={
                <h3 className="application-details-section-title">
                  Application Summary
                </h3>
              }
            >
              <InfoGrid data={applicationSummaryInfo} />
              <div className="h-8" />
              {!readOnly && (
                <UpdateApplicationAction
                  applicationDetails={applicationDetails?.data}
                  approveCallback={() => setCreateAgreementModalOpen(true)}
                  callback={() =>
                    // applicationId && fetchApplicationDetails(applicationId)
                    navigate(-1)
                  }
                />
              )}

              {applicationDetails.data?.ocrTaskRef && (
                <>
                  <FinoryOcrTaskRef />
                </>
              )}
            </Panel>

            <Panel
              key="internalRemark"
              className="application-details-section-container"
              header={
                <h3 className="application-details-section-title">
                  Internal Remark
                </h3>
              }
            >
              <InternalRemarkTable />
            </Panel>

            {/* <Panel
              key="ocr"
              className="application-details-section-container"
              header={
                <h3 className="application-details-section-title">
                  Bank Statement Analysis
                </h3>
              }
            >
              <>
                {finoryOcrUrl.length > 0 ? (
                  <iframe
                    src={finoryOcrUrl}
                    width="100%"
                    height="1000px"
                    allowFullScreen
                    onLoad={handleIFrameLoading}
                  ></iframe>
                ) : (
                  <div>Loading.</div>
                )}
              </>
            </Panel> */}
            <Panel
              key="moreDetails"
              className="application-details-section-container"
              header={
                <h3 className="application-details-section-title">
                  More Details
                </h3>
              }
            >
              <ApplicationDetailsTab />
            </Panel>
          </Collapse>
        </>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the application does not exist."
        />
      )}
    </div>
  );
};

export default ApplicationDetailsPage;
