import { createSlice } from "@reduxjs/toolkit";
import { getAllGroupThunk } from "../../services/caseAssignmentService/groupService/groupThunk";
import { type } from "os";

type UserGroup = {
  id: string;
  groupId: string;
  userId: string;
  isActive?: boolean;
  createdAt: string;
  updatedAt: string;
};

export type Group = {
  id: string;
  groupCode: string;
  superiorGroupId: string | number;
  teamId?: string;
  UserGroup?: UserGroup[];
  caseAssignedCount: number;
  isActive?: boolean;
  createdAt: string;
  updatedAt: string;
};

export type GroupState = {
  groupsState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Group[];
  };
};

const initialState: GroupState = {
  groupsState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    groupReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGroupThunk.pending, (state) => {
        state.groupsState.isLoading = true;
      })
      .addCase(getAllGroupThunk.fulfilled, (state, action) => {
        state.groupsState.isLoading = false;
        state.groupsState.data = action.payload.data;
      })
      .addCase(getAllGroupThunk.rejected, (state, action) => {
        state.groupsState.isLoading = false;
        state.groupsState.isError = true;
        state.groupsState.errorMessage = action.payload;
      });
  },
});

export const { groupReset } = groupSlice.actions;

export default groupSlice.reducer;
