import "./index.less";

import { Button, Typography, message } from "antd";
import React, { useState } from "react";

import { AppDispatch } from "../../../store/store";
import { Helmet } from "react-helmet-async";
import { PageHeader } from "@ant-design/pro-components";
import { createWriteOffBatchThunk } from "../../../services/writeOffService/writeOffThunk";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const CreateWriteOffPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const createWritOff = async () => {
    setLoading(true);
    const payload = {
      createdBy: localStorage.getItem("username"),
    };
    await dispatch(createWriteOffBatchThunk(payload))
      .unwrap()
      .then((res) => {
        message.success("Created New Write Off Batch");
        navigate("/write-off/write-off-listing");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Helmet>
        <title>Create WriteOff - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Create Write Off Batch" />
      <div className="card-center" style={{ marginTop: -70 }}>
        <Title level={3}>Generate Write Off</Title>
        <Button onClick={createWritOff} style={{ width: 300 }}>
          Generate
        </Button>
      </div>
    </>
  );
};

export default CreateWriteOffPage;
