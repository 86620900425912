import { Table, Tabs, Tag } from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import type { TabsProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDateAndTime } from "../../../utils/datetime.util";

const JomPayPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  //   const initialParams = useMemo(
  //     () => ({
  //       pagination: {
  //         current: 1,
  //         pageSize: 10,
  //       },
  //       sortOrder: "desc",
  //       sortField: "createdAt",
  //       search: {},
  //       filters: {},
  //     }),
  //     [],
  //   );

  //   const {
  //     openFieldVisitListingState,
  //     draftFieldVisitListingState,
  //     closeFieldVisitListingState,
  //     visitedFieldVisitListingState,
  //   } = useSelector((state: RootState) => state.fieldVisit);

  //   useLifecycles(() => {
  //     const params = {
  //       ...initialParams,
  //       pagination: {
  //         current: pageNum ? +pageNum : initialParams.pagination.current,
  //         pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
  //       },
  //     };
  //   });

  //   const data = [
  //     {
  //       agreementNo: "8-000006-0",
  //       amount: 20,
  //       payDate: displayDateAndTime(new Date()),
  //       channel: "RAZER PAY",
  //       referenceNo: "TEST 1",
  //       createdBy: "TEST USER",
  //       createdAt: displayDateAndTime(new Date()),
  //     },
  //   ];

  //   const columns: ColumnsType<any> = [
  //     {
  //       title: "Agreement No",
  //       dataIndex: "agreementNo",
  //       key: "agreementNo",
  //     },
  //     {
  //       title: "Amount",
  //       dataIndex: "amount",
  //       key: "amount",
  //     },
  //     {
  //       title: "Pay Date",
  //       dataIndex: "payDate",
  //       key: "payDate",
  //     },
  //     {
  //       title: "Channel",
  //       dataIndex: "channel",
  //       key: "channel",
  //     },
  //     {
  //       title: "Reference No",
  //       dataIndex: "referenceNo",
  //       key: "referenceNo",
  //     },
  //     {
  //       title: "Created By",
  //       dataIndex: "createdBy",
  //       key: "createdBy",
  //     },
  //     {
  //       title: "Created At",
  //       dataIndex: "createdAt",
  //       key: "createdAt",
  //     },
  //   ];

  return (
    <>
      <PageHeader className="px-0 mb-2" title="JomPay" />
    </>
  );
};

export default JomPayPage;
