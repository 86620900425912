import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";

import type { ColumnsType } from "antd/es/table";

import { getInternalRemarksByApplicationIDThunk } from "../../services/applicationService/applicationThunk";
import { displayDateAndTime } from "../../utils/datetime.util";
import { AppDispatch, RootState } from "../../store/store";

import "./index.less";

const InternalRemarkTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { applicationDetails, internalRemarks } = useSelector(
    (state: RootState) => state.application
  );

  const columns: ColumnsType<any> = [
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "10rem",
      render: (text) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Description",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Created By",
      dataIndex: "username",
      key: "username",
      render: (text) => (text ?? "-"),
    },
  ];

  useEffect(() => {
    dispatch(
      getInternalRemarksByApplicationIDThunk({
        filters: { applicationId: applicationDetails.data?.id },
      })
    );
  }, [applicationDetails.data?.id]);

  return (
    <Table
      rowKey="id"
      className="min-w-fit"
      dataSource={internalRemarks?.data}
      columns={columns}
      bordered={true}
    />
  );
};

export default InternalRemarkTable;
