import { authSetupAxiosInstance } from "../../axios/authSetupAxios";
import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getDashboardCard1Data = async (requestData: any) => {
  const url = `/redCash/api/v1.0/dashboard/first-card-data`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const getDashboardCard2Data = async (requestData: any) => {
  const url = `/redCash/api/v1.0/dashboard/second-card-data`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const getDashboardCard3Data = async (requestData: any) => {
  const url = `/redCash/api/v1.0/dashboard/third-card-data`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const downloadApplicationProcessReport = async (requestData: any) => {
  let result;
  if (requestData?.applicationProcessStatus === "REGISTRATION/INELIGIBLE") {
    const url = `/redCash/auth/api/v1.0/report/applicationProcess`;
    result = await authSetupAxiosInstance.post(url, requestData, {
      responseType: "blob",
    });
  } else {
    const url = `/redCash/admin/api/v1.0/report/applicationProcess`;
    result = await beSetupAxiosInstance.post(url, requestData, {
      responseType: "blob",
    });
  }

  return {
    filename: result.headers["content-disposition"]?.split('"')[1],
    content: result.data,
  };
};

const downloadApplicationStatusReport = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/report/applicationstatus`;
  const result = await beSetupAxiosInstance.post(url, requestData, {
    responseType: "blob",
  });
  return {
    filename: result.headers["content-disposition"]?.split('"')[1],
    content: result.data,
  };
};

const downloadAgreementStatusReport = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/report/agreementstatus`;
  const result = await beSetupAxiosInstance.post(url, requestData, {
    responseType: "blob",
  });
  return {
    filename: result.headers["content-disposition"]?.split('"')[1],
    content: result.data,
  };
};

const getApplicationProcessData = async () => {
  const url = `/redCash/api/v1.0/dashboard/application-process`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getRegistrationCase = async () => {
  const url = `/redCash/auth/api/v1.0/dashboard/application/allRegistrationCase`;
  const res = await authSetupAxiosInstance.get(url);
  return res.data;
};

const getDistinctApplicationUserIdTotal = async () => {
  const url = `/redCash/api/v1.0/dashboard/distinct-application-userId-count`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getTotalCases = async () => {
  const url = `/redCash/api/v1.0/dashboard/totalCasesSection`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const dashboardService = {
  getDashboardCard1Data,
  getDashboardCard2Data,
  getDashboardCard3Data,
  downloadApplicationProcessReport,
  downloadApplicationStatusReport,
  downloadAgreementStatusReport,
  getApplicationProcessData,
  getRegistrationCase,
  getDistinctApplicationUserIdTotal,
  getTotalCases,
};

export default dashboardService;
