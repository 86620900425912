import { createSlice } from "@reduxjs/toolkit";
import { AccountDetails } from "../collection/collectionSlice";
import {
  getPaymentNoticeBatchDetailsThunk,
  getPaymentNoticeBatchListThunk,
} from "../../services/paymentNoticeService/paymentNoticeThunk";

export type PaymentNoticeBatchNo = {
  id: string;
  batchNo: string;
  DPD: number;
  configId?: string;
  createdAt?: string;
  updatedAt?: string;
};

export interface paymentNoticeDetails {
  id: string;
  batchId: string;
  accountDetailsId: string;
  outstandingAmount: number;
  otherCharges: any;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  accountDetails: AccountDetails;
}

export type paymentNoticeDetailsResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: paymentNoticeDetails[];
};

export type paymentNoticeBatchResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: PaymentNoticeBatchNo[];
};

export type PaymentNoticeState = {
  paymentNoticeBatchListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: paymentNoticeBatchResponse;
  };
  paymentNoticeBatchDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: paymentNoticeDetailsResponse;
  };
};

const initialState: PaymentNoticeState = {
  paymentNoticeBatchListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { total: 0, currentPage: 0, pageSize: 0, data: [] },
  },
  paymentNoticeBatchDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { total: 0, currentPage: 0, pageSize: 0, data: [] },
  },
};

export const paymentNoticeSlice = createSlice({
  name: "paymentNotice",
  initialState,
  reducers: {
    paymentNoticeReset: () => initialState,
    paymentNoticeBatchDetailsReset: (state) => {
      state.paymentNoticeBatchDetails = initialState.paymentNoticeBatchDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentNoticeBatchListThunk.pending, (state) => {
        state.paymentNoticeBatchListing.isLoading = true;
      })
      .addCase(getPaymentNoticeBatchListThunk.fulfilled, (state, action) => {
        state.paymentNoticeBatchListing.isLoading = false;
        state.paymentNoticeBatchListing.data = action.payload;
      })
      .addCase(getPaymentNoticeBatchListThunk.rejected, (state, action) => {
        state.paymentNoticeBatchListing.isLoading = false;
        state.paymentNoticeBatchListing.isError = true;
        state.paymentNoticeBatchListing.errorMessage = action.payload;
      });

    builder
      .addCase(getPaymentNoticeBatchDetailsThunk.pending, (state) => {
        state.paymentNoticeBatchDetails.isLoading = true;
      })
      .addCase(getPaymentNoticeBatchDetailsThunk.fulfilled, (state, action) => {
        state.paymentNoticeBatchDetails.isLoading = false;
        state.paymentNoticeBatchDetails.data = action.payload;
      })
      .addCase(getPaymentNoticeBatchDetailsThunk.rejected, (state, action) => {
        state.paymentNoticeBatchDetails.isLoading = false;
        state.paymentNoticeBatchDetails.isError = true;
        state.paymentNoticeBatchDetails.errorMessage = action.payload;
      });
  },
});
export const { paymentNoticeReset, paymentNoticeBatchDetailsReset } =
  paymentNoticeSlice.actions;

export default paymentNoticeSlice.reducer;
