import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PlusOutlined } from "@ant-design/icons";

import { STATE_ENUM } from "../../../../enum/state";
import { CreateFieldVisitRequest } from "../../../../features/fieldVisit/fieldVisitSlice";
import { useDebounce } from "../../../../hooks/useDebounceHook";
import fieldVisitService from "../../../../services/fieldVisitService/fieldVisitService";
import { createFieldVisitThunk } from "../../../../services/fieldVisitService/fieldVisitThunk";
import { getCityStateByPostcodeThunk } from "../../../../services/locationService/locationThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { trimPhoneNo } from "../../../../utils/number.util";
import {
  numberRegexPattern,
  phoneRegexPattern,
} from "../../../../utils/validation";

const { TextArea } = Input;

const RequestFieldVisitModal: React.FC<{
  accountDetailsId?: string;
  callback?: () => void;
}> = ({ accountDetailsId, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAccountAvailable, setIsAccountAvailable] = useState(false);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const stateOptions = Object.values(STATE_ENUM).map((value) => ({
    label: `${value.replace(/[_]/g, " ")}`,
    value,
  }));

  const navigate = useNavigate();

  const [postcode, setPostcode] = useState<string>("");
  const [validPostcode, setValidPostcode] = useState<boolean>(true);
  const debouncedPostcode = useDebounce<string>(postcode, 500);
  const invalidPostcodeMsg = "Invalid postcode";

  const onPostcodeChange = () => {
    setValidPostcode(true);
    setPostcode(form.getFieldsValue().postCode);
  };

  useEffect(() => {
    if (debouncedPostcode) {
      dispatch(
        getCityStateByPostcodeThunk({
          postcode: form.getFieldsValue().postCode,
        }),
      )
        .unwrap()
        .then((res) => {
          setValidPostcode(res.length > 0);
          if (res.length > 0) {
            form.setFieldsValue({
              city: res[0].post_office,
              stateLocation: res[0].state_code,
            });
          } else {
            form.setFieldsValue({
              city: undefined,
              stateLocation: undefined,
            });
          }
        })
        .catch((error: Error) => {
          console.log(error);
          setValidPostcode(false);
        });
    }
  }, [debouncedPostcode]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    if (!validPostcode) {
      return message.error(invalidPostcodeMsg);
    }
    await form
      .validateFields()
      .then(async (values) => {
        let remarkObject: any = {};
        setLoading(true);

        if (values.remarks) {
          remarkObject.remarks = values.remarks;
          remarkObject.username = currentUser?.data?.username;
        } else {
          remarkObject = null;
        }
        const payload: CreateFieldVisitRequest = {
          accountDetailsId: accountDetailsId,
          address1: values.address1,
          address2: values.address2,
          address3: values.address3,
          contactNo: trimPhoneNo(values.contactNo) ?? undefined,
          contactMode: values.contactMode,
          createdBy: currentUser?.data?.username,
          postCode: values.postCode.toString(),
          state: values.stateLocation ?? "-",
          city: values.city,
          remarks: remarkObject,
          agreementNo: accountEnquiry?.data?.agreementDetails?.agreementNo,
          applicationNo:
            accountEnquiry?.data?.agreementDetails?.application?.applicationNo,
          applicantName:
            accountEnquiry?.data?.agreementDetails?.application
              ?.applicantFirstName,
          applicantNric:
            accountEnquiry?.data?.agreementDetails?.application?.applicantNric,
        };

        console.log(payload);

        dispatch(createFieldVisitThunk(payload))
          .unwrap()
          .then((res) => {
            if (callback) {
              callback();
            }
            handleCancel();
            message.success("Field Visit Requested Successfully");
            navigate(`/field-visit/listing`);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        message.error("Please fill in the required fields");
      });
  };

  useEffect(() => {
    fieldVisitService
      .checkAccountDetailsForFieldVisit({
        accountDetailsId: accountEnquiry.data.accountDetails?.id,
      })
      .then((res) => {
        setIsAccountAvailable(res);
      });
  }, []);

  return (
    <>
      {isAccountAvailable && (
        <Button onClick={showModal} icon={<PlusOutlined />}>
          Request Field Visit
        </Button>
      )}

      <Modal
        title="Request Field Visit"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={400}
        okText="Submit Request"
        destroyOnClose
        centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          initialValues={{
            address1:
              accountEnquiry?.data?.agreementDetails?.application
                ?.applicantAddress1 ?? "-",
            address2:
              accountEnquiry?.data?.agreementDetails?.application
                ?.applicantAddress2,
            address3:
              accountEnquiry?.data?.agreementDetails?.application
                ?.applicantAddress3,
            postCode:
              accountEnquiry.data.agreementDetails?.application
                ?.applicantPostcode,
            stateLocation:
              accountEnquiry.data.agreementDetails?.application?.applicantState,
            city: accountEnquiry.data.agreementDetails?.application
              ?.applicantCity,
            contactNo: trimPhoneNo(
              accountEnquiry.data.accountDetails?.applicantPhoneNo,
            ),
            contactMode: "phoneNumber",
          }}
        >
          <Form.Item label="Contact Mode" name="contactMode" className="mb-4">
            <Select defaultValue={"phoneNumber"}>
              <Select.Option value="phoneNumber">Phone Number</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Contact No"
            name="contactNo"
            className="mb-4"
            rules={[
              {
                pattern: phoneRegexPattern,
                message: "Invalid phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Address To Visit" className="mb-2">
            <Input.Group>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    // name={["address", "address1"]}
                    name="address1"
                    validateFirst={true}
                    rules={[{ required: true, message: "Address is required" }]}
                  >
                    <Input placeholder="Address line 1" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    // name={["address", "address2"]}
                    name="address2"
                  >
                    <Input placeholder="Address line 2" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    // name={["address", "address3"]}
                    name="address3"
                  >
                    <Input placeholder="Address line 3" />
                  </Form.Item>
                  <Form.Item
                    name="postCode"
                    validateFirst={true}
                    validateStatus={validPostcode ? "success" : "error"}
                    help={!validPostcode && invalidPostcodeMsg}
                    rules={[
                      {
                        pattern: numberRegexPattern,
                        message: "Invalid postcode",
                      },
                    ]}
                  >
                    <Input
                      inputMode="numeric"
                      placeholder="Please input"
                      onChange={onPostcodeChange}
                    />
                  </Form.Item>
                  <Form.Item name="city">
                    <Input placeholder="City" />
                  </Form.Item>
                  <Form.Item name="stateLocation">
                    <Select
                      placeholder="Select State"
                      allowClear
                      options={stateOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
          </Form.Item>
          <Form.Item label="Remarks" name="remarks" className="mb-4">
            <TextArea
              placeholder="Please input"
              style={{ width: "100%" }}
              minLength={5}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RequestFieldVisitModal;
