import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import InfoGrid from "../../../components/InfoGrid";
import { SME_ORGANIZATION_TYPE } from "../../../enum/smeApplication";
import { getFormattedAddress } from "../../../utils/string.util";

export const SmeCompanyDetails = () => {
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const data = accountEnquiry?.data?.accountDetails?.smeApplication

  let authorizedDetails: any[] = [];
  const companyDetails = [
    {
      title: "Company Name",
      value: data?.companyName,
    },
    {
      title: "Company Email",
      value: data?.companyEmail,
    },
    {
      title: "Business Old Registration No.",
      value: data?.businessOldRegistrationNo,
    },
    {
      title: "Business New Registration No.",
      value: data?.businessNewRegistrationNo,
    },
    {
      title: "Country of Incorporation/Registration",
      value: data?.countryOfIncorporation,
    },
    {
      title: "Business Contact No.",
      value: data?.businessContactNo,
    },
    // {
    //   title: "SSM Expiry Date",
    //   value: displayDate(smeApplicationDetails?.data?.smePartner?.ssmExpiryDate),
    // },
  ];

  const shareholdingStatus = [
    {
      title: "Shareholding Status",
      value: data?.shareholdingStatus,
    }
  ]

  const managementInfo = [
    {
      title: "Name of Senior Management",
      value: data?.nameOfSeniorManagement,
    },
    {
      title: "Nature of Business",
      value: data?.natureOfBusiness,
    },
    ...(accountEnquiry?.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.COMPANY ? shareholdingStatus : [])
  ];

  const businessAddress = [
    {
      title: "Business Address",
      value: getFormattedAddress(data?.businessAddress1, data?.businessAddress2, data?.businessAddress3),
    },
    {
      title: "Business Address Postcode",
      value: data?.businessPostcode,
    },
    {
      title: "Business Address City",
      value: data?.businessCity,
    },
    {
      title: "Business Address State",
      value: data?.businessState,
    },
  ];

  const address = [
    {
      title: "Registered Address (SSM)",
      value: getFormattedAddress(data?.registeredSsmAddress1, data?.registeredSsmAddress2, data?.registeredSsmAddress3),
    },
    {
      title: "Registered Address (SSM) Postcode",
      value: data?.registeredSsmPostcode,
    },
    {
      title: "Registered Address (SSM) City",
      value: data?.registeredSsmCity,
    },
    {
      title: "Registered Address (SSM) State",
      value: data?.registeredSsmState,
    },
    {
      title: "Business Authorized Address",
      value: accountEnquiry?.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.SOLE_PROP ? getFormattedAddress(data?.businessAddress1, data?.businessAddress2, data?.businessAddress3) : getFormattedAddress(data?.businessAuthorisedAddress1, data?.businessAuthorisedAddress2, data?.businessAuthorisedAddress3),
    },
    {
      title: "Business Authorized Address Postcode",
      value: accountEnquiry?.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.SOLE_PROP ? data?.businessPostcode : data?.businessAuthorisedPostcode,
    },
    {
      title: "Business Authorized Address City",
      value: accountEnquiry?.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.SOLE_PROP ? data?.businessCity : data?.businessAuthorisedCity,
    },
    {
      title: "Business Authorized Address State",
      value: accountEnquiry?.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.SOLE_PROP ? data?.businessState : data?.businessAuthorisedState,
    },
    ...(accountEnquiry?.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.COMPANY ? businessAddress : []),
  ];

  if (data && accountEnquiry?.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.COMPANY) {
    const authorized = (accountEnquiry?.data?.accountDetails?.smeDirector ?? []).find((director: any) => director.isAuthorizedPerson);
    authorizedDetails = [
      {
        title: "Authorised Contact Person",
        value: authorized?.directorName,
      },
      {
        title: "Authorised Contact Person's NRIC",
        value: authorized?.nric,
      },
      {
        title: "Authorised Contact Person's Email",
        value: authorized?.personalEmail,
      },
      {
        title: "Authorised Contact Number",
        value: authorized?.phoneNo,
      },
    ];
  }

  return (
    <div className="info-container">
      <InfoGrid title="Company Details" data={companyDetails}  />
      <br />
      <br />
      <InfoGrid data={managementInfo} />
      <br />
      <br />
      <InfoGrid data={address} />
      {(data && accountEnquiry?.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.COMPANY) && (
        <>
          <br />
          <br />
          <InfoGrid data={authorizedDetails} />
        </>
      )}
    </div>
  );
};