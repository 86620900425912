import "./index.less";

import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
  UploadFile,
  UploadProps,
} from "antd";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import startCase from "lodash.startcase";
import TextArea from "rc-textarea";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { InboxOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-components";

import LoadingSpinner from "../../../components/LoadingSpinner";
import UpdateAocAmountModal from "../../../components/UpdateAOCAmountModal";
import { collectionStateReset } from "../../../features/collection/collectionSlice";
import { numberWithCommas } from "../../../helpers/number";
import {
  getAgreementsThunk,
  searchDisbursedAgreementThunk,
} from "../../../services/agreementService/agreementThunk";
import {
  addAgreementToAocThunk,
  generateAocBatchThunk,
  getAgreementByEmailThunk,
  getAocBatchThunk,
} from "../../../services/aocService/aocThunk";
import { getAccountDetailsThunk } from "../../../services/collectionService/collectionThunk";
import { waiverFileUploadThunk } from "../../../services/fileService/fileThunk";
import { getTaxThunk } from "../../../services/taxService/taxThunk";
import { checkAgreement } from "../../../services/waiverService/waiverService";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import AccountEnquiryModal from "../../AccountEnquiry/AccountEnquiryModal";
import SearchAgreementResult from "../../AccountEnquiry/SearchAgreementResult";

import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
interface DataType {
  key: React.Key;
  type: string;
  amount: number;
  amountAfterTax: number;
  name: string;
  tax: number;
}
const AddAccountCharges: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const { agreementList } = useSelector((state: RootState) => state.agreement);
  const { batchNo } = useSelector((state: RootState) => state.aoc);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 1,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {
        // agreementStatus:  AgreementStatusEnum.PENDING,
      },
    }),
    [],
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchAgreementNo = queryParams.get("agreementNo")?.trim();
  const [searchType, setSearchType] = useState("AGREEMENT_NO");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchResultVisible, setSearchResultVisible] = useState(false);
  const [searchResultData, setSearchResultData] = useState<any>([]);
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [amountForAllCharges, setAmountForAllCharges] = useState(0);
  const [resultNotFound, setResultNotFound] = useState(false);
  const [displayButton, setDisplayButton] = useState(false);
  const [creatingBatchNo, setCreatingBatchNo] = useState(false);
  const [generatedBatchNo, setBatchNo] = useState("");
  const [batchNoId, setBatchNoId] = useState("");
  const [totalNoOfAccount, setTotalNoOfAccount] = useState(0);
  const [aocBatchRemarks, setAocBatchRemarks] = useState<any>(null);
  const [agreementRemarks, setAgreementRemarks] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [accountDetailsAgreement, setAccountDetailsAgreement] = useState();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isEmptyBatch, setEmptyBatch] = useState(false);
  const { tax } = useSelector((state: RootState) => state.tax);
  const [agreementData, setAgreementData] = useState<any>([]);
  const uploadProps: UploadProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      fileList.push(file);
      // setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const chargesData: DataType[] = [
    {
      key: "latePaymentCharge",
      type: "latePaymentCharge",
      name: " Late Payment Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "reminderLetter1Charge",
      type: "reminderLetter1Charge",
      name: "Reminder Letter 1 Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "reminderLetter2Charge",
      type: "reminderLetter2Charge",
      name: "Reminder Letter 2 Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "reminderLetter3Charge",
      type: "reminderLetter3Charge",
      name: "Reminder Letter 3 Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "earlySettlementCharge",
      type: "earlySettlementCharge",
      name: "Early Settlement Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "ecaCharge",
      type: "ecaCharge",
      name: "External Collection Agency Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "fieldCharge",
      type: "fieldCharge",
      name: "Field Visit Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "legalCharge",
      type: "legalCharge",
      name: "Legal Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "lodCharge",
      type: "lodCharge",
      name: "LOD Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "miscCharge",
      type: "miscCharge",
      name: "Misc Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "refundCharge",
      type: "refundCharge",
      name: "Refund Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "blacklistUpliftCharge",
      type: "blacklistUpliftCharge",
      name: "Credit Info, Blacklist UpLift Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "adminCharge",
      type: "adminCharge",
      name: "Admin Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
    {
      key: "bankCharge",
      type: "bankCharge",
      name: "Bank Charge",
      amount: 0.0,
      tax: 0.0,
      amountAfterTax: 0.0,
    },
  ];
  const [chargesArray, setChargesArray] = useState(chargesData);
  const chargesTypeTableColumns: ColumnsType<DataType> = [
    {
      title: "Charge Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => startCase(record.name),
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: `Tax (${tax.data.tax}%)`,
      key: "tax",
      dataIndex: "tax",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: `Amount After Tax`,
      key: "amountAfterTax",
      dataIndex: "amountAfterTax",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <UpdateAocAmountModal
            chargeDetails={record}
            tax={tax.data.tax}
            callback={(res: any) => {
              const matchingObject = chargesArray.find(
                (obj: any) => startCase(obj.type) === res.chargeType,
              );

              if (matchingObject) {
                matchingObject.amount = res?.amount;
                matchingObject.tax = res?.tax;
                matchingObject.amountAfterTax = res?.amountAfterTax;
                setDisplayButton(true);
              }
            }}
          />
        </Space>
      ),
    },
  ];

  const fetchTax = async (params?: any) => {
    await dispatch(getTaxThunk(params));
  };

  const openModal = (value: any) => {
    setIsModalOpen(true);
    setAccountDetailsAgreement(value.accountDetailsId);

    searchResultData.map((item: any) => {
      if (item.accountDetailsId === value.accountDetailsId) {
        form.setFieldValue("remarks", item.remarks);
        setAgreementRemarks(item.remarks);
      } else {
        setAgreementRemarks(null);
      }
    });
  };
  const agreementTableColumns: ColumnsType<any> = [
    {
      title: "Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal key={record.id} agreementNo={record.agreementNo} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => record.status ?? "-",
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (_, record) => record?.application.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "user",
      key: "user",
      render: (_, record) => record?.application.applicantNric ?? "-",
    },
    {
      title: "MIA",
      key: "mia",
      dataIndex: "mia",
      render: (text) => text ?? "-",
    },
    { title: "Remarks", dataIndex: "remarks", key: "remarks" },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
            <Space>
              <Button onClick={() => openModal(record)}>Add Remarks</Button>
              <Button type="primary" onClick={() => removeRecord(record.id)}>
                Remove
              </Button>
            </Space>
          </>
        );
      },
    },
    // {
    //   title: "Net Disbursement Amount",
    //   dataIndex: "disbursementAmount",
    //   key: "disbursementAmount",
    //   render: (text, record) => (record.disbursementAmount ?? "-"),
    // },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const removeRecord = (id: string) => {
    const filteredData = searchResultData.filter(
      (value: any) => value.id !== id,
    );
    setSearchResultData(filteredData);
  };

  const fetchAddOnCharges = async (params?: any) => {
    // await dispatch(
    //   getAddOnChargesThunk({
    //     params,
    //     companyId: process.env.REACT_APP_COMPANY_ID,
    //   }),
    // );
  };

  const fetchAgreements = async (params?: any) => {
    await dispatch(
      getAgreementsThunk({
        params,
        companyId: process.env.REACT_APP_COMPANY_ID,
      }),
    );
  };

  const onSearch = async (value: string) => {
    if (value) {
      setLoading(true);

      await dispatch(
        searchDisbursedAgreementThunk({ [searchType]: value }),
      )
        .then((result) => {
          setAgreementData(result.payload);
          if (result.payload && result.payload.length > 0) {
            setSearchResultVisible(true);
          } else {
            message.error("Result Not Found");
            setResultNotFound(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      message.error("Result Not Found");
      dispatch(collectionStateReset());
    }
  };

  const getAocBatch = async (params?: any) => {
    await dispatch(getAocBatchThunk(params)).then((res: any) => {
      if (res.payload.data.length > 0) {
        setEmptyBatch(false);
      } else {
        setEmptyBatch(true);
      }
    });
  };

  const handleSearchResultSelect = async (selectedData: any) => {
    setSearchResultVisible(false);
    await dispatch(getAccountDetailsThunk({ agreementNo: selectedData }))
      .then(async (result) => {
        const isAgreementApproved = await checkAgreement(selectedData);
        if (isAgreementApproved) {
          if (Object.keys(result.payload.agreementDetails).length != 0) {
            setResultNotFound(false);
            const arrayData: any = [];
            const agingType = result.payload.accountDetails?.agingType;
            arrayData.push({
              ...result.payload.agreementDetails,
              agingType,
              accountDetailsId: result.payload.accountDetails.id,
              remarks: null,
              status: result.payload.accountDetails?.status,
              mia: result.payload.accountDetails?.mia,
            });

            // if (searchResultData.length < 1) {
            //   setSearchResultData(arrayData);
            // }

            const objectExisted = searchResultData.find(
              (res: any) => res.id === arrayData[0].id,
            );
            const selectedBatch = batchNo.data.data.find(
              (item) => item.id === batchNoId,
            );

            const agreementExisted = selectedBatch?.addOnChargesAgreement.find(
              (res: any) =>
                res.accountDetailsId === result.payload.accountDetails.id,
            );

            if (agreementExisted) {
              message.error(
                `This agreement existed in batch ${selectedBatch?.batchNo}`,
              );
            } else {
              if (!objectExisted) {
                setSearchResultData([...searchResultData, arrayData[0]]);
                // const filterCharges = chargesArray.filter((obj2: any) => {
                //   return !result.payload.accountDetails?.addOnCharges.some(
                //     (obj1: any) =>
                //       obj1.type === obj2.type &&
                //       // Compare obj1.createdAt after 31days with current date
                //       moment(obj1.createdAt).add(31, "days").isAfter(moment()),
                //   );
                // });

                // setChargesArray(filterCharges);
                setChargesArray(chargesArray);
              } else {
                message.error("Agreement already in list");
              }
            }
          } else {
            message.error("Result Not Found");
            setResultNotFound(true);
            dispatch(collectionStateReset());
          }
        } else {
          message.error(
            `This agreement: ${selectedData} is still in progress under Waiver/Add-On Charges, please either approve or void it first`,
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchResultCancel = () => {
    setSearchResultVisible(false);
  };

  // const initialCallbackState = { handleSearchResultSelect };
  // const [callbackState] = useState(initialCallbackState);

  // useEffect(() => {
  //   if (searchAgreementNo) {
  //     setSearchType("AGREEMENT_NO");
  //     callbackState.handleSearchResultSelect({
  //       agreementNo: searchAgreementNo,
  //     });
  //   }
  // }, [callbackState, searchAgreementNo]);

  const submitAoc = async () => {
    if (searchResultData.length < 1) {
      message.error("Please search for at least 1 agreement no");
    } else {
      let aocAmountBeforeTax = 0;
      let aocAmountTax = 0;

      for (const item of chargesArray) {
        if (item.amount > 0) {
          aocAmountBeforeTax += item.amount;
        }
      }

      aocAmountBeforeTax = aocAmountBeforeTax * searchResultData.length;

      aocAmountTax =
        aocAmountBeforeTax + (aocAmountBeforeTax * tax.data.tax) / 100;

      const payload = {
        createdById: localStorage.getItem("username"),
        aocBatchId: batchNoId,
        addOnChargesAgreements: searchResultData.map((item: any) => ({
          agreementId: item.id,
          aocAmountBeforeTax: aocAmountBeforeTax,
          aocAmountTax: aocAmountTax,
          accountDetailsId: item.accountDetailsId,
          remarks: item?.remarks,
        })),
        addOnCharges: chargesArray.filter((value: any) => {
          return value.amount > 0;
        }),
        totalNoOfAccount: searchResultData.length,
        totalAocAmountBeforeTax: aocAmountBeforeTax,
        totalAocTax: aocAmountTax,
        aocBatchRemarks: {
          remarks: aocBatchRemarks === null ? null : aocBatchRemarks,
          aocBatchId: batchNoId,
          username: localStorage.getItem("username"),
        },
      };

      await dispatch(addAgreementToAocThunk(payload)).then(async (res: any) => {
        if (res?.error) {
          message.error(res?.payload);
        } else {
          if (fileList.length > 0) {
            for (let i = 0; i < fileList.length; i++) {
              const data = new FormData();
              data.append("entityId", batchNoId);
              data.append("fileType", "AOC_CHARGE");
              data.append("file", fileList[i] as RcFile);

              await dispatch(waiverFileUploadThunk(data));
            }
          }
          message.success(`Added agreement to ${generatedBatchNo}`);
          navigate(`/add-on-charges/listing`);
        }
      });
    }
  };

  const createBatchNo = async (params?: any) => {
    setCreatingBatchNo(true);
    const username = localStorage.getItem("username");
    await dispatch(generateAocBatchThunk({ ...params, username }))
      .unwrap()
      .then(async (res) => {
        message.success("Created Batch No");
        setBatchNo(res.data.batchNo);
        setBatchNoId(res.data.id);
        setTotalNoOfAccount(res.data.totalNoOfAccount);
        setEmptyBatch(false);
        await dispatch(
          getAocBatchThunk({
            ...initialParams,
          }),
        );

        // await dispatch(getAgreementListThunk(res.data.id));
      })
      .finally(() => setCreatingBatchNo(false));
  };

  const handleChange = async (value: any, label: any) => {
    setBatchNo(label.label);
    setBatchNoId(label.value);
    const data = Object.entries(batchNo.data.data).filter(
      ([, value]) => value.batchNo === label.label,
    );
    setTotalNoOfAccount(data[0][1].totalNoOfAccount);
    const selectedBatch = batchNo.data.data.find(
      (item: any) => item.id === label.value,
    );

    const updatedSearchResultData = searchResultData.filter((item2: any) => {
      return !selectedBatch?.addOnChargesAgreement.some(
        (item1: any) => item1.accountDetailsId === item2.accountDetailsId,
      );
    });

    setSearchResultData(updatedSearchResultData);
  };

  const onFinish = async (record: any) => {
    const updatedData = searchResultData.map((item: any) => {
      if (item.accountDetailsId === accountDetailsAgreement) {
        return { ...item, remarks: agreementRemarks };
      } else {
        return { ...item };
      }
    });
    setSearchResultData(updatedData);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchTax();
    fetchAddOnCharges(initialParams);
    getAocBatch(initialParams);
  }, [initialParams]);

  useEffect(() => {
    // check the queryParams got pass in agreementNo or not
    if (searchAgreementNo) {
      if (batchNo.data.data.length === 0) {
        createBatchNo();
        handleSearchResultSelect(searchAgreementNo);
      } else {
        setBatchNo(batchNo.data?.data[0]?.batchNo);
        setBatchNoId(batchNo.data?.data[0]?.id);
        setTotalNoOfAccount(batchNo.data?.data[0]?.totalNoOfAccount);
        setEmptyBatch(false);
        handleSearchResultSelect(searchAgreementNo);
      }
      console.log(batchNo);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Add On Charges - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Add On Charges" />
      <div className="section-container">
        {isEmptyBatch ? (
          <>
            <Button
              loading={creatingBatchNo}
              onClick={() => createBatchNo()}
              type="primary"
            >
              Create Batch No
            </Button>
          </>
        ) : (
          <>
            <div className="flex justify-between mb-4">
              <Input.Group compact>
                <Select
                  placeholder="Select batch No"
                  style={{ width: 200 }}
                  onChange={handleChange}
                  defaultValue={
                    searchAgreementNo ? batchNo.data.data[0].batchNo : ""
                  }
                  options={batchNo.data?.data?.map((item: any) => ({
                    value: item.id,
                    label: item.batchNo,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                />
                <Select
                  className="w-[10rem]"
                  value={searchType}
                  onChange={(value) => {
                    setResultNotFound(false);
                    setSearchType(value);
                  }}
                  options={[
                    { value: "agreementNo", label: "Agreement No" },
                    { value: "applicantNric", label: "Applicant NRIC" },
                    { value: "applicantFirstName", label: "Applicant Name" },
                    { value: "applicantPhoneNo", label: "Phone No" },
                    { value: "applicantEmail", label: "Email" },
                  ]}
                />
                <Search
                  placeholder="Agreement No"
                  onSearch={onSearch}
                  loading={loading}
                  defaultValue={searchAgreementNo ?? ""}
                  status={resultNotFound ? "error" : ""}
                  style={{ width: 200, marginBottom: 8 }}
                  onChange={() => setResultNotFound(false)}
                />
              </Input.Group>
              <Button
                loading={creatingBatchNo}
                onClick={() => createBatchNo()}
                type="primary"
              >
                Create Batch No
              </Button>
            </div>
          </>
        )}

        {searchResultVisible && (
          <SearchAgreementResult
            visible={searchResultVisible}
            data={agreementData}
            onOk={handleSearchResultSelect}
            onCancel={handleSearchResultCancel}
          />
        )}

        {accountEnquiry.isLoading && (
          <>
            <LoadingSpinner />
          </>
        )}
        {generatedBatchNo !== "" && searchResultData.length > 0 && (
          <>
            <br />
            {/* <h3 className="waiver-details-section-title">
              AOC BatchNo : <Tag>{generatedBatchNo}</Tag>
            </h3> */}
            <br />
            <div className="section-container">
              {/* <h3 className="section-title">Select Account</h3>
              <Search
                placeholder="Agreement No"
                onSearch={onSearch}
                style={{ width: 200, marginBottom: 8 }}
              /> */}
              {/* </Input.Group> */}
              {/* <br /> */}
              <Table
                rowKey="id"
                className="min-w-fit mb-4"
                columns={agreementTableColumns}
                pagination={false}
                dataSource={searchResultData}
                loading={agreementList?.isLoading}
              />
            </div>

            <div className="info-container">
              <h3>Add Remarks for Aoc Batch No</h3>
              <TextArea
                rows={4}
                placeholder="Aoc Batch Remarks"
                onChange={(e) => setAocBatchRemarks(e.target.value)}
                className="textarea"
              />
            </div>

            <br />
            <div className="info-container">
              <h3>Upload Files</h3>
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </div>
            <br />

            <div className="section-container">
              <h3 className="section-title">Select Charges</h3>
              {selectedRowKeys.length > 0 && (
                <>
                  <UpdateAocAmountModal
                    tax={tax.data.tax}
                    selectedCharges={selectedRowKeys}
                    amountForAllCharges={amountForAllCharges}
                    callback={(res: any) => {
                      for (let i = 0; i < res.charges.length; i++) {
                        const matchingObject = chargesArray.find(
                          (obj: any) => obj.type === res.charges[i],
                        );

                        if (matchingObject) {
                          matchingObject.amount = res?.amount;
                          matchingObject.tax = res?.tax;
                          matchingObject.amountAfterTax = res?.amountAfterTax;

                          setAmountForAllCharges(res?.amount);
                          setDisplayButton(true);
                        }
                      }
                    }}
                  />
                </>
              )}
              <Table
                rowSelection={{
                  ...rowSelection,
                }}
                columns={chargesTypeTableColumns}
                pagination={false}
                // loading={agreementList?.isLoading}
                dataSource={chargesArray}
              />
            </div>

            <div className="w-full flex justify-end gap-x-1.5 mb-8">
              <Button
                disabled={searchResultData.length < 1 || !displayButton}
                onClick={submitAoc}
              >
                Save to batch
              </Button>
            </div>
          </>
        )}
        <Modal
          title="Please enter remarks"
          okText="Confirm"
          open={isModalOpen}
          onOk={onFinish}
          onCancel={handleCancel}
          confirmLoading={loading}
          width={360}
          destroyOnClose
          centered
        >
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
            initialValues={{
              remarks: agreementRemarks,
            }}
          >
            <Form.Item label="Remarks" name="remarks">
              <TextArea
                placeholder="Please input"
                name="remarks"
                style={{ width: "100%" }}
                onChange={(e) => setAgreementRemarks(e.target.value)}
                minLength={5}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default AddAccountCharges;
