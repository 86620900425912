import { createSlice } from "@reduxjs/toolkit";

import {
  getAllFieldVisitCompanyThunk,
  getAllFieldVisitOfficerThunk,
  getCloseFieldVisitsThunk,
  getPendingFieldVisitsThunk,
  getFieldVisitByIdThunk,
  getFieldVisitCompanyByStateThunk,
  getFieldVisitCompanyThunk,
  getFieldVisitHistoriesThunk,
  getFieldVisitOfficerByPostCodeThunk,
  getOpenFieldVisitsThunk,
  getVisitedFieldVisitsThunk,
} from "../../services/fieldVisitService/fieldVisitThunk";
import { AccountDetails } from "../collection/collectionSlice";

export type CompanyState = {
  id?: string;
  state?: string;
  fieldVisitCompanyId?: string;
  fieldVisitCompany?: FieldVisitCompany;
};

export type FieldVisitCompany = {
  id?: string;
  companyCode?: string;
  companyName?: string;
  location?: string[];
  companyLocation?: string[];
  officeNumber?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  companyCity?: string;
  postCode?: number;
  companyState?: string;
  companyEmail?: string;
  fieldVisitOfficer?: FieldVisitOfficer[];
  flatFee?: number;
  feePerMileage?: number;
  isActive?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type OfficerLocation = {
  state?: string;
  postcode?: string;
};

export type FieldVisitOfficer = {
  id?: string;
  firstName?: string;
  lastName?: string;
  nric?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  officerCity?: string;
  postCode?: number;
  officeNumber?: string;
  officerState?: string;
  officerEmail?: string;
  isActive?: boolean;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  fieldVisitCompanyId?: string;
  officerLocation: OfficerLocation[];
};

export type FieldVisit = {
  id?: string;
  accountDetailsId?: string;
  accountDetails?: AccountDetails;
  fieldVisitNo?: string;
  status?: string;
  fieldVisitOfficerId?: string;
  fvOfficer?: FieldVisitOfficer;
  address1?: string;
  address2?: string;
  address3?: string;
  postCode?: string;
  dateVisited?: string;
  arrivalTime?: string;
  leaveTime?: string;
  newContactNo?: string;
  newEmail?: string;
  newCompanyName?: string;
  newAddress1?: string;
  newAddress2?: string;
  newAddress3?: string;
  mileAge?: string;
  letterServed?: boolean;
  typeOfProperty?: string;
  propertyOwnerShip?: string;
  propertyCondition?: string;
  vehicleRegNo?: string;
  vehicleSightedModel?: string;
  fieldVisitRemark?: FieldVisitRemark[];
  createdAt?: string;
  updatedAt?: string;
  state?: string;
  meetUpPerson?: string;
  newPostCode?: string;
  newTown?: string;
  newState?: string;
  city?: string;
  jobPosition?: string;
  assignedDate?: string;
};

export type FieldVisitRemark = {
  id?: string;
  remarks?: string;
  fieldVisitCaseId?: string;
  username?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type FieldVisitDetails = {
  fieldVisitCase?: FieldVisit;
  fvOfficer?: FieldVisitOfficer;
};

export type CreateFieldVisitRequest = {
  fieldVisitOfficerId?: string;
  accountDetailsId?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  contactNo?: string;
  contactMode?: string;
  createdBy?: string;
  remarks?: FieldVisitRemark;
  postCode?: string;
  state?: string;
  city?: string;
  agreementNo?: string;
  applicationNo?: string;
  applicantName?: string;
  applicantNric?: string;
};

export type FieldVisitState = {
  fieldVisitCompanyState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: FieldVisitCompany[];
    total: number;
  };

  fieldVisitCompanyDetailsState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: FieldVisitCompany;
  };

  fieldVisitOfficerState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: FieldVisitOfficer[];
    total: number;
  };

  fieldVisitOfficersByPostCodeState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: FieldVisitOfficer[];
    total: number;
  };

  openFieldVisitListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: FieldVisit[];
    total: number;
    totalCount: number;
  };

  pendingFieldVisitListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: FieldVisit[];
    total: number;
    totalCount: number;
  };

  closeFieldVisitListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: FieldVisit[];
    total: number;
    totalCount: number;
  };

  visitedFieldVisitListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: FieldVisit[];
    total: number;
    totalCount: number;
  };

  fieldVisitHistoryListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: FieldVisit[];
    total: number;
  };

  fieldVisitDetailsState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: FieldVisitDetails;
  };

  companyState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: CompanyState[];
  };
};

const initialState: FieldVisitState = {
  fieldVisitCompanyState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
    total: 0,
  },

  fieldVisitCompanyDetailsState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },

  fieldVisitOfficerState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
    total: 0,
  },

  fieldVisitOfficersByPostCodeState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
    total: 0,
  },

  openFieldVisitListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    total: 0,
    data: [],
    totalCount: 0,
  },

  pendingFieldVisitListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    total: 0,
    data: [],
    totalCount: 0,
  },

  closeFieldVisitListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    total: 0,
    data: [],
    totalCount: 0,
  },

  visitedFieldVisitListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    total: 0,
    data: [],
    totalCount: 0,
  },

  fieldVisitHistoryListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    total: 0,
    data: [],
  },

  fieldVisitDetailsState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },

  companyState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const fieldVisitSlice = createSlice({
  name: "fieldVisit",
  initialState,
  reducers: {
    fieldVisitReset: () => initialState,
    fieldVisitDetailsStateReset: (state) => {
      state.fieldVisitDetailsState = initialState.fieldVisitDetailsState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFieldVisitCompanyThunk.pending, (state) => {
        state.fieldVisitCompanyState.isLoading = true;
      })
      .addCase(getAllFieldVisitCompanyThunk.fulfilled, (state, action) => {
        state.fieldVisitCompanyState.isLoading = false;
        state.fieldVisitCompanyState.data = action.payload.data;
        state.fieldVisitCompanyState.total = action.payload.total;
      })
      .addCase(getAllFieldVisitCompanyThunk.rejected, (state, action) => {
        state.fieldVisitCompanyState.isLoading = false;
        state.fieldVisitCompanyState.isError = true;
        state.fieldVisitCompanyState.errorMessage = action.payload;
      });

    builder
      .addCase(getFieldVisitCompanyThunk.pending, (state) => {
        state.fieldVisitCompanyDetailsState.isLoading = true;
      })
      .addCase(getFieldVisitCompanyThunk.fulfilled, (state, action) => {
        state.fieldVisitCompanyDetailsState.isLoading = false;
        state.fieldVisitCompanyDetailsState.data = {
          ...action.payload,
          companyLocation: action.payload.companyLocation?.map(
            (item: any) => item.state,
          ),
        };
      })
      .addCase(getFieldVisitCompanyThunk.rejected, (state, action) => {
        state.fieldVisitCompanyDetailsState.isLoading = false;
        state.fieldVisitCompanyDetailsState.isError = true;
        state.fieldVisitCompanyDetailsState.errorMessage = action.payload;
      });

    builder
      .addCase(getAllFieldVisitOfficerThunk.pending, (state) => {
        state.fieldVisitOfficerState.isLoading = true;
      })
      .addCase(getAllFieldVisitOfficerThunk.fulfilled, (state, action) => {
        state.fieldVisitOfficerState.isLoading = false;
        state.fieldVisitOfficerState.data = action.payload.data;
        state.fieldVisitOfficerState.total = action.payload.total;
      })
      .addCase(getAllFieldVisitOfficerThunk.rejected, (state, action) => {
        state.fieldVisitOfficerState.isLoading = false;
        state.fieldVisitOfficerState.isError = true;
        state.fieldVisitOfficerState.errorMessage = action.payload;
      });

    builder
      .addCase(getFieldVisitOfficerByPostCodeThunk.pending, (state) => {
        state.fieldVisitOfficersByPostCodeState.isLoading = true;
      })
      .addCase(
        getFieldVisitOfficerByPostCodeThunk.fulfilled,
        (state, action) => {
          state.fieldVisitOfficersByPostCodeState.isLoading = false;
          state.fieldVisitOfficersByPostCodeState.data = action.payload;
          state.fieldVisitOfficersByPostCodeState.total =
            action.payload?.length ?? 0;
        },
      )
      .addCase(
        getFieldVisitOfficerByPostCodeThunk.rejected,
        (state, action) => {
          state.fieldVisitOfficersByPostCodeState.isLoading = false;
          state.fieldVisitOfficersByPostCodeState.isError = true;
          state.fieldVisitOfficersByPostCodeState.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getOpenFieldVisitsThunk.pending, (state) => {
        state.openFieldVisitListingState.isLoading = true;
      })
      .addCase(getOpenFieldVisitsThunk.fulfilled, (state, action) => {
        state.openFieldVisitListingState.isLoading = false;
        state.openFieldVisitListingState.data = action.payload.data;
        state.openFieldVisitListingState.total = action.payload.total;
        state.openFieldVisitListingState.totalCount = action.payload.totalCount;
      })
      .addCase(getOpenFieldVisitsThunk.rejected, (state, action) => {
        state.openFieldVisitListingState.isLoading = false;
        state.openFieldVisitListingState.isError = true;
        state.openFieldVisitListingState.errorMessage = action.payload;
      });

    builder
      .addCase(getVisitedFieldVisitsThunk.pending, (state) => {
        state.visitedFieldVisitListingState.isLoading = true;
      })
      .addCase(getVisitedFieldVisitsThunk.fulfilled, (state, action) => {
        state.visitedFieldVisitListingState.isLoading = false;
        state.visitedFieldVisitListingState.data = action.payload.data;
        state.visitedFieldVisitListingState.total = action.payload.total;
        state.visitedFieldVisitListingState.totalCount =
          action.payload.totalCount;
      })
      .addCase(getVisitedFieldVisitsThunk.rejected, (state, action) => {
        state.visitedFieldVisitListingState.isLoading = false;
        state.visitedFieldVisitListingState.isError = true;
        state.visitedFieldVisitListingState.errorMessage = action.payload;
      });

    builder
      .addCase(getFieldVisitByIdThunk.pending, (state) => {
        state.fieldVisitDetailsState.isLoading = true;
      })
      .addCase(getFieldVisitByIdThunk.fulfilled, (state, action) => {
        state.fieldVisitDetailsState.isLoading = false;
        state.fieldVisitDetailsState.data = action.payload;
      })
      .addCase(getFieldVisitByIdThunk.rejected, (state, action) => {
        state.fieldVisitDetailsState.isLoading = false;
        state.fieldVisitDetailsState.isError = true;
        state.fieldVisitDetailsState.errorMessage = action.payload;
      });

    builder
      .addCase(getFieldVisitHistoriesThunk.pending, (state) => {
        state.fieldVisitHistoryListingState.isLoading = true;
      })
      .addCase(getFieldVisitHistoriesThunk.fulfilled, (state, action) => {
        state.fieldVisitHistoryListingState.isLoading = false;
        state.fieldVisitHistoryListingState.data = action.payload.data;
        state.fieldVisitHistoryListingState.total = action.payload.total;
      })
      .addCase(getFieldVisitHistoriesThunk.rejected, (state, action) => {
        state.fieldVisitHistoryListingState.isLoading = false;
        state.fieldVisitHistoryListingState.isError = true;
        state.fieldVisitHistoryListingState.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingFieldVisitsThunk.pending, (state) => {
        state.pendingFieldVisitListingState.isLoading = true;
      })
      .addCase(getPendingFieldVisitsThunk.fulfilled, (state, action) => {
        state.pendingFieldVisitListingState.isLoading = false;
        state.pendingFieldVisitListingState.data = action.payload.data;
        state.pendingFieldVisitListingState.total = action.payload.total;
        state.pendingFieldVisitListingState.totalCount =
          action.payload.totalCount;
      })
      .addCase(getPendingFieldVisitsThunk.rejected, (state, action) => {
        state.pendingFieldVisitListingState.isLoading = false;
        state.pendingFieldVisitListingState.isError = true;
        state.pendingFieldVisitListingState.errorMessage = action.payload;
      });

    builder
      .addCase(getCloseFieldVisitsThunk.pending, (state) => {
        state.closeFieldVisitListingState.isLoading = true;
      })
      .addCase(getCloseFieldVisitsThunk.fulfilled, (state, action) => {
        state.closeFieldVisitListingState.isLoading = false;
        state.closeFieldVisitListingState.data = action.payload.data;
        state.closeFieldVisitListingState.total = action.payload.total;
        state.closeFieldVisitListingState.totalCount =
          action.payload.totalCount;
      })
      .addCase(getCloseFieldVisitsThunk.rejected, (state, action) => {
        state.closeFieldVisitListingState.isLoading = false;
        state.closeFieldVisitListingState.isError = true;
        state.closeFieldVisitListingState.errorMessage = action.payload;
      });

    builder
      .addCase(getFieldVisitCompanyByStateThunk.pending, (state) => {
        state.companyState.isLoading = true;
      })
      .addCase(getFieldVisitCompanyByStateThunk.fulfilled, (state, action) => {
        state.companyState.isLoading = false;
        state.companyState.data = action.payload.data;
      })
      .addCase(getFieldVisitCompanyByStateThunk.rejected, (state, action) => {
        state.companyState.isLoading = false;
        state.companyState.isError = true;
        state.companyState.errorMessage = action.payload;
      });
  },
});

export const { fieldVisitReset, fieldVisitDetailsStateReset } =
  fieldVisitSlice.actions;

export default fieldVisitSlice.reducer;
