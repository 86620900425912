import "./index.less";

import { Button, Form, InputNumber, message, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { updateSmeApplicationLoanTenureThunk } from "../../../../services/smeApplicationService/smeApplicationThunk";
import { SME_APPLICATION_STATUS } from "../../../../enum/smeApplicationStatus";
import { SmeApplication } from "../../../../features/smeApplication/smeApplicationSlice";

type UpdateLoanTenureModalProps = {
  applicationDetails?: SmeApplication;
  callback?: () => void;
};

const UpdateLoanTenureModal: React.FC<UpdateLoanTenureModalProps> = (
  props,
) => {
  const { applicationDetails, callback } = props;
  const dispatch = useDispatch<AppDispatch>();

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        const payload = { appliedLoanTenure: values.appliedLoanTenure };

        await dispatch(
          updateSmeApplicationLoanTenureThunk({
            applicationId: applicationDetails?.id,
            payload,
          }),
        )
          .unwrap()
          .then(() => {
            if (callback) callback();
            message.success("Update Successfully");
          });
      })
      .catch((err) => {
        message.error("Something went wrong. Please try again later.");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        disabled={applicationDetails?.status !== SME_APPLICATION_STATUS.PENDING}
        ghost
      >
        Update
      </Button>
      <Modal
        title="Update Loan Tenure"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={360}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          initialValues={{
            appliedLoanTenure: applicationDetails?.appliedLoanTenure,
          }}
        >
          <Form.Item
            name="appliedLoanTenure"
            rules={[{ required: true, message: "Loan Tenure is required" }]}
          >
            <InputNumber
              placeholder="Please input"
              style={{ width: "100%" }}
              controls={false}
              inputMode="numeric"
              type={"number"}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateLoanTenureModal;
