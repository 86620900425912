import React, { useEffect } from "react";
import { Modal, Form, Input, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { SME_ORGANIZATION_TYPE } from "../../../../../../enum/smeApplication";
import {
  getSmeApplicationDetailsThunk,
  patchSmeCompanyCompanyInfoByIdThunk,
  patchSmePartnershipCompanyInfoByIdThunk,
  patchSmeSolePropsCompanyInfoByIdThunk,
} from "../../../../../../services/smeApplicationService/smeApplicationThunk";
import { getCityStateByPostcodeThunk } from "../../../../../../services/locationService/locationThunk";
import jwt_decode from "jwt-decode";

export const EditCompanyDetailsModal = () => {
  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = React.useState(false);

  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const data =
    smeApplicationDetails?.data?.smeSolePropApplication ||
    smeApplicationDetails?.data?.smePartnershipApplication ||
    smeApplicationDetails?.data?.smeCompanyApplication;

  React.useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        ...data,
        ...(smeApplicationDetails?.data?.smeSolePropApplication && {
          businessAuthorisedAddress1: data.businessAddress1,
          businessAuthorisedAddress2: data.businessAddress2,
          businessAuthorisedAddress3: data.businessAddress3,
          businessAuthorisedPostcode: data.businessPostcode,
          businessAuthorisedCity: data.businessCity,
          businessAuthorisedState: data.businessState,
        }),
      });
    }
  }, [data, isOpen, form]);

  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const handleSsmPostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 5) {
      dispatch(getCityStateByPostcodeThunk({ postcode: e.target.value }))
        .unwrap()
        .then((res) => {
          form.setFieldsValue({
            registeredSsmCity: res[0].post_office,
            registeredSsmState: res[0].state_code,
          });
        });
    } else {
      form.setFieldsValue({
        registeredSsmCity: null,
        registeredSsmState: null,
      });
    }
  };

  const handleBusinessAuthPostcodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.value.length === 5) {
      dispatch(getCityStateByPostcodeThunk({ postcode: e.target.value }))
        .unwrap()
        .then((res) => {
          form.setFieldsValue({
            businessAuthorisedCity: res[0].post_office,
            businessAuthorisedState: res[0].state_code,
          });
        });
    } else {
      form.setFieldsValue({
        businessAuthorisedCity: null,
        businessAuthorisedState: null,
      });
    }
  };

  const handleBusinessPostcodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.value.length === 5) {
      dispatch(getCityStateByPostcodeThunk({ postcode: e.target.value }))
        .unwrap()
        .then((res) => {
          console.log(res);
          form.setFieldsValue({
            businessCity: res[0].post_office,
            businessState: res[0].state_code,
          });
        });
    } else {
      form.setFieldsValue({
        businessCity: null,
        businessState: null,
      });
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      console.log("Form values:", values);
      // Dispatch your update action here

      if (
        smeApplicationDetails?.data?.organizationType ===
        SME_ORGANIZATION_TYPE.PARTNERSHIP
      ) {
        await dispatch(
          patchSmePartnershipCompanyInfoByIdThunk({
            id: smeApplicationDetails?.data?.id,
            ...values,
          }),
        );
      }

      if (
        smeApplicationDetails?.data?.organizationType ===
        SME_ORGANIZATION_TYPE.COMPANY
      ) {
        await dispatch(
          patchSmeCompanyCompanyInfoByIdThunk({
            id: smeApplicationDetails?.data?.id,
            ...values,
          }),
        );
      }

      if (
        smeApplicationDetails?.data?.organizationType ===
        SME_ORGANIZATION_TYPE.SOLE_PROP
      ) {
        const {
          businessAuthorisedAddress1,
          businessAuthorisedAddress2,
          businessAuthorisedAddress3,
          businessAuthorisedPostcode,
          businessAuthorisedCity,
          businessAuthorisedState,
          ...rest
        } = values;

        await dispatch(
          patchSmeSolePropsCompanyInfoByIdThunk({
            id: smeApplicationDetails?.data?.id,
            businessAddress1: businessAuthorisedAddress1,
            businessAddress2: businessAuthorisedAddress2,
            businessAddress3: businessAuthorisedAddress3,
            businessPostcode: businessAuthorisedPostcode,
            businessCity: businessAuthorisedCity,
            businessState: businessAuthorisedState,
            ...rest,
          }),
        );
      }

      dispatch(
        getSmeApplicationDetailsThunk({
          applicationId: smeApplicationDetails?.data?.id,
        }),
      );

      // onClose();
    } catch (error) {
      console.error("Error updating company info:", error);
    }
  };

  return (
    <>
      {(currentRole === "VERIFIER" ||
        currentRole === "VERIFIER_2" ||
        currentRole === "SUPER_ADMIN") && (
        <Button onClick={() => setIsOpen(true)}>Edit</Button>
      )}
      <Modal
        title="Edit Company Information"
        open={isOpen}
        onCancel={onClose}
        onOk={() => form.submit()}
        width={1600}
        className="overflow-auto"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="mt-4"
        >
          {/* Company Details Section */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Company Details</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[
                  { required: true, message: "Please enter company name" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="companyEmail"
                label="Company Email"
                rules={[
                  { required: true, message: "Please enter company email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="businessOldRegistrationNo"
                label="Business Old Registration No."
                rules={[
                  {
                    required: true,
                    message: "Business old registration number is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="businessNewRegistrationNo"
                label="Business New Registration No."
                rules={[
                  {
                    required: true,
                    message: "Business new registration number is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="countryOfIncorporation"
                label="Country of Incorporation/Registration"
                rules={[
                  {
                    required: true,
                    message: "Country of incorporation is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="businessContactNo"
                label="Business Contact No."
                rules={[
                  {
                    required: true,
                    message: "Business contact number is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>

          {/* Management Information */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">
              Management Information
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item
                name="nameOfSeniorManagement"
                label="Name of Senior Management"
                rules={[
                  {
                    required: true,
                    message: "Name of senior management is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="natureOfBusiness"
                label="Nature of Business"
                rules={[
                  { required: true, message: "Nature of business is required" },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>

          {/* Address Information */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Address Information</h3>

            {/* Registered Address (SSM) */}
            <div className="mb-4">
              <h4 className="font-medium mb-2">Registered Address (SSM)</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Form.Item
                  name="registeredSsmAddress1"
                  label="Address Line 1"
                  rules={[{ required: true, message: "Address is required" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="registeredSsmAddress2" label="Address Line 2">
                  <Input />
                </Form.Item>
                <Form.Item name="registeredSsmAddress3" label="Address Line 3">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="registeredSsmPostcode"
                  label="Postcode"
                  rules={[{ required: true, message: "Postcode is required" }]}
                >
                  <Input onChange={handleSsmPostcodeChange} />
                </Form.Item>
                <Form.Item
                  name="registeredSsmCity"
                  label="City"
                  rules={[{ required: true, message: "City is required" }]}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="registeredSsmState"
                  label="State"
                  rules={[{ required: true, message: "State is required" }]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </div>
            </div>

            {/* Business Authorized Address */}
            <div className="mb-4">
              <div className="flex justify-between items-center">
                <h4 className="font-medium mb-2">
                  Business Authorized Address
                </h4>
                <Button
                  type="link"
                  onClick={() => {
                    const ssmAddress = form.getFieldsValue([
                      "registeredSsmAddress1",
                      "registeredSsmAddress2",
                      "registeredSsmAddress3",
                      "registeredSsmPostcode",
                      "registeredSsmCity",
                      "registeredSsmState",
                    ]);

                    form.setFieldsValue({
                      businessAuthorisedAddress1:
                        ssmAddress.registeredSsmAddress1,
                      businessAuthorisedAddress2:
                        ssmAddress.registeredSsmAddress2,
                      businessAuthorisedAddress3:
                        ssmAddress.registeredSsmAddress3,
                      businessAuthorisedPostcode:
                        ssmAddress.registeredSsmPostcode,
                      businessAuthorisedCity: ssmAddress.registeredSsmCity,
                      businessAuthorisedState: ssmAddress.registeredSsmState,
                    });
                  }}
                >
                  Copy from SSM Address
                </Button>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Form.Item
                  name="businessAuthorisedAddress1"
                  label="Address Line 1"
                  rules={[{ required: true, message: "Address is required" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="businessAuthorisedAddress2"
                  label="Address Line 2"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="businessAuthorisedAddress3"
                  label="Address Line 3"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="businessAuthorisedPostcode"
                  label="Postcode"
                  rules={[{ required: true, message: "Postcode is required" }]}
                >
                  <Input onChange={handleBusinessAuthPostcodeChange} />
                </Form.Item>
                <Form.Item
                  name="businessAuthorisedCity"
                  label="City"
                  rules={[{ required: true, message: "City is required" }]}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="businessAuthorisedState"
                  label="State"
                  rules={[{ required: true, message: "State is required" }]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </div>
            </div>

            {smeApplicationDetails?.data?.organizationType ===
              SME_ORGANIZATION_TYPE.COMPANY && (
              <>
                {/* Business Address */}
                <div className="mb-4">
                  <div className="flex justify-between items-center">
                    <h4 className="font-medium mb-2">Business Address</h4>
                    <Button
                      type="link"
                      onClick={() => {
                        const ssmAddress = form.getFieldsValue([
                          "businessAuthorisedAddress1",
                          "businessAuthorisedAddress2",
                          "businessAuthorisedAddress3",
                          "businessAuthorisedPostcode",
                          "businessAuthorisedCity",
                          "businessAuthorisedState",
                        ]);

                        form.setFieldsValue({
                          businessAddress1:
                            ssmAddress.businessAuthorisedAddress1,
                          businessAddress2:
                            ssmAddress.businessAuthorisedAddress2,
                          businessAddress3:
                            ssmAddress.businessAuthorisedAddress3,
                          businessPostcode:
                            ssmAddress.businessAuthorisedPostcode,
                          businessCity: ssmAddress.businessAuthorisedCity,
                          businessState: ssmAddress.businessAuthorisedState,
                        });
                      }}
                    >
                      Copy from Business Authorized Address
                    </Button>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Form.Item
                      name="businessAddress1"
                      label="Address Line 1"
                      rules={[
                        { required: true, message: "Address is required" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="businessAddress2" label="Address Line 2">
                      <Input />
                    </Form.Item>
                    <Form.Item name="businessAddress3" label="Address Line 3">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="businessPostcode"
                      label="Postcode"
                      rules={[
                        { required: true, message: "Postcode is required" },
                      ]}
                    >
                      <Input onChange={handleBusinessPostcodeChange} />
                    </Form.Item>
                    <Form.Item
                      name="businessCity"
                      label="City"
                      rules={[{ required: true, message: "City is required" }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                      name="businessState"
                      label="State"
                      rules={[{ required: true, message: "State is required" }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
};
