import { Form, Input, Select, Table } from "antd";
import { ColumnsType, SorterResult } from "antd/es/table/interface";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Search, useLocation, useNavigate } from "react-router-dom";

import UpdateDunningInfoModal from "../../../components/UpdateDunningInfoModal";
import { numberWithCommas } from "../../../helpers/number";
import { getMIA1ListingThunk } from "../../../services/collectionService/collectionThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate, displayDateAndTime } from "../../../utils/datetime.util";
import AccountEnquiryModal from "../../AccountEnquiry/AccountEnquiryModal";

import type { TableProps } from "antd/es/table";
import type { AccountDetails } from "../../../features/collection/collectionSlice";
import { ActionType } from "../../../enum/actionType";
import { Application } from "../../../features/application/applicationSlice";

const MIA1Listing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");
  const tab = queryParams.get("tab");
  const { mia1List } = useSelector((state: RootState) => state.collection);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<AccountDetails>>(
    {},
  );
  const [searchField, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");
  const [editable, setEditable] = useState(true);
  const { Search } = Input;
  const [form] = Form.useForm();

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const tablePagination = {
    total: mia1List.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const handleTableChange: TableProps<AccountDetails>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      sorter?.order == null
        ? {
            ...initialParams,
            ...filterState,
            pagination,
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          };

    dispatch(getMIA1ListingThunk(param));
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
    setSortedInfo(sorter as SorterResult<AccountDetails>);

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const updateCompleteCallback = () => {
    dispatch(getMIA1ListingThunk(initialParams));
  };

  const fetchMia1List = async (params?: any) => {
    console.log(params);
    await dispatch(getMIA1ListingThunk(params));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value);
      fetchMia1List({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchField]: value,
        },
      });
    } else {
      fetchMia1List({
        ...initialParams,
        filters: { ...filterState },
      });
    }

    setFilterState({ ...initialParams.filters });
  };

  const onSearchTypeChange = (value: string) => {
    setSearchFiled(value);
    setSearchValue("");
    form.setFieldsValue({ fieldValue: "" });
  };

  useEffect(() => {
    setSearchFiled("agreementNo");
    setSearchValue("");
    form.setFieldsValue({ fieldType: "agreementNo", fieldValue: "" });
  }, [tab]);

  useEffect(() => {
    dispatch(getMIA1ListingThunk(initialParams));
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "No",
      key: "index",
      dataIndex: "index",
      fixed: "left",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "DPD",
      dataIndex: "dpd",
      key: "dpd",
      sorter: (a, b) => a.dpd - b.dpd,
      sortOrder: sortedInfo.columnKey === "dpd" ? sortedInfo.order : null,
      render: (text) => (text ? text : "-"),
      fixed: "left",
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      fixed: "left",
      render: (_, record) => (
        <AccountEnquiryModal
          key={record?.agreementNo}
          agreementNo={record?.agreementNo}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "applicantFirstName",
      key: "applicantFirstName",
      fixed: "left",
      render: (text, record) => (
        <div
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
            width: "100px",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "i-score",
      fixed: "left",
      render: (_, record) => record?.user?.iScore ?? "-",
    },
    {
      title: "Total Due Outstanding",
      dataIndex: "totalLoanOutstanding",
      key: "totalLoanOutstanding",
      fixed: "left",
      render: (_, record) => {
        const totalLoanOutstanding =
          +record.totalCharges + +record.totalDueInstalment;
        return record
          ? `RM ${numberWithCommas(
              totalLoanOutstanding > 0 ? totalLoanOutstanding : 0,
            )}`
          : "-";
      },
    },
    {
      title: "Total Billing Outstanding",
      dataIndex: "billingOutstanding",
      key: "billingOutstanding",
      fixed: "left",
      render: (_, record) =>
        record?.billingOutstanding
          ? `RM ${numberWithCommas(
              record.billingOutstanding > 0 ? record.billingOutstanding : 0,
            )}`
          : "-",
    },
    {
      title: "Special Tagging",
      dataIndex: "specialTaggingAuto",
      key: "specialTaggingAuto",
      width: "50px",
      sorter: (a, b) => a.specialTaggingAuto - b.specialTaggingAuto,
      sortOrder:
        sortedInfo.columnKey === "specialTaggingAuto" ? sortedInfo.order : null,
      fixed: "left",
      render: (_, record) =>
        record.specialTaggingAuto ? record.specialTaggingAuto : "-",
    },
    {
      title: "Action Code",
      dataIndex: "latestActionCode",
      key: "latestActionCode",
      width: "50px",
      sorter: (a, b) => a.latestActionCode - b.latestActionCode,
      sortOrder:
        sortedInfo.columnKey === "latestActionCode" ? sortedInfo.order : null,
      fixed: "left",
      render: (_, record) => record.latestActionCode ?? "-",
    },
    {
      title: "Date & Time",
      dataIndex: "latestActionCodeUpdatedDate",
      key: "latestActionCodeUpdatedDate",
      width: "200px",
      sorter: (a, b) =>
        a.latestActionCodeUpdatedDate - b.latestActionCodeUpdatedDate,
      sortOrder:
        sortedInfo.columnKey === "latestActionCodeUpdatedDate"
          ? sortedInfo.order
          : null,
      fixed: "left",
      render: (_, record) =>
        record.latestActionCodeUpdatedDate
          ? displayDateAndTime(record.latestActionCodeUpdatedDate)
          : "-",
    },
    {
      title: "User ID",
      dataIndex: "latestActionCodeCreatedBy",
      key: "latestActionCodeCreatedBy",
      sorter: (a, b) =>
        a.latestActionCodeCreatedBy - b.latestActionCodeCreatedBy,
      sortOrder:
        sortedInfo.columnKey === "latestActionCodeCreatedBy"
          ? sortedInfo.order
          : null,
      fixed: "left",
      render: (text, record) => (
        <div
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
            width: "100px",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "PTP",
      dataIndex: "latestPtpDate",
      key: "latestPtpDate",
      width: "200px",
      sorter: (a, b) => a.latestPtpDate - b.latestPtpDate,
      sortOrder:
        sortedInfo.columnKey === "latestPtpDate" ? sortedInfo.order : null,
      fixed: "left",
      render: (_, record) =>
        record?.latestPtpDate ? displayDate(record?.latestPtpDate) : "-",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "left",
      render: (_, record) => (
        <UpdateDunningInfoModal
          key={`"updateDunningInfoModal${record?.id}`}
          accountDetailsId={record?.id}
          agreementNo={record?.agreementNo}
          onFinishCallback={updateCompleteCallback}
          isUpdate={true}
          id={record?.id}
          statusActivity={ActionType.BP}
          prevPtpAmount={record?.ptpAmount ?? 0}
        />
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>1 MIA - redCASH CEP</title>
      </Helmet>

      <Form form={form}>
        <Input.Group compact>
          <Form.Item name="fieldType">
            <Select
              defaultValue={searchField}
              style={{ width: 200 }}
              onChange={(value) => onSearchTypeChange(value)}
              options={[
                { value: "agreementNo", label: "Agreement No" },
                { value: "applicantFirstName", label: "Applicant Name" },
              ]}
            />
          </Form.Item>{" "}
          <Form.Item name="fieldValue">
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </Form.Item>
        </Input.Group>
      </Form>

      <br />
      <Table
        rowKey="id"
        columns={columns}
        loading={mia1List.isLoading}
        dataSource={mia1List.data?.data}
        pagination={tablePagination}
        onChange={handleTableChange}
      />
    </>
  );
};
export default MIA1Listing;
