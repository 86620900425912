export const ReportTypeLabel = {
  MASTER_LISTING: "Master Listing",
  INTEREST_CHARGE: "Interest Charges Report",
  MISC_BILLING: "Misc Billing Report",
  LOAN_MATURITY: "Loan Maturity Report",
  OVERDUE: "Overdue Report",
  COLLECTION: "Collection Report",
  CUSTOMER_LOAN: "Loan (C-NC)",
  CUSTOMER_LOAN_INTEREST: "Interest (C-NC)",
  LATE_CHARGES: "Late Charges Report",
  PARTNER_INFO: "Partner Report",
  KPKT: "KPKT Report",
  AGING_REPORT: "Aging Report",
  CUSTOMER_DETAILS: "Customer Details Report",
  STAMP_DUTY: "Stamp Duty Report",
  LOAN_INSURANCE: "Loan Insurance Report",
  SME_MONTHLY_DEDUCTION: "SME Monthly Deduction",
};
