import "./index.less";

import { Result, Space, Tag } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";
import LoadingParagraph from "../../../components/LoadingParagraphLong";
import { AppDispatch, RootState } from "../../../store/store";
import { displayApplicationStatus } from "../../../utils/string.util";
import { getSmeApplicationDetailsThunk } from "../../../services/smeApplicationService/smeApplicationThunk";
import { smeApplicationDetailsStateReset } from "../../../features/smeApplication/smeApplicationSlice";
import { CreditAssessmentPanel } from "./CreditAssessmentPanel";
import { LoanApplicationPanel } from "./LoanApplicationPanel";
import { ExistingLoanPanel } from "./ExistingLoanPanel";
import { DocumentsPanel } from "./DocumentsPanel";
import { ApplicationSummaryPanel } from "./ApplicationSummaryPanel";
import { InternalRemarksPanel } from "./InternalRemarksPanel";
import { AdditionalDetailsPanel } from "./AdditionalDetailsPanel";

dayjs.extend(duration);

const SmeApplicationDetailsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { applicationId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const readOnly = queryParams.get("readOnly") === "true";

  const { smeApplicationDetails } = useSelector((state: RootState) => state.smeApplication);

  const onBack = () => {
    navigate(-1);
  };

  const fetchApplicationDetails = (applicationId: string) => {
    dispatch(getSmeApplicationDetailsThunk({ applicationId }));
  };

  const resetState = () => {
    dispatch(smeApplicationDetailsStateReset());
  };

  useLifecycles(() => {
    resetState();
    applicationId && fetchApplicationDetails(applicationId);
  });

  return (
    <div>
      <Helmet>
        <title>SME Application Details - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="p-0 mb-4"
        onBack={onBack}
        title={
          <Space align="center">
            <h3 className="m-0">SME Application Details</h3>
            {!smeApplicationDetails?.isLoading &&
              smeApplicationDetails.data?.status && (
                <Tag>
                  {displayApplicationStatus(
                    smeApplicationDetails.data?.status,
                  )}
                </Tag>
              )}
          </Space>
        }
      />

      {smeApplicationDetails.isLoading ? (
        <LoadingParagraph />
      ) : smeApplicationDetails.data ? (
        <>
          <LoanApplicationPanel />
          <ExistingLoanPanel />
          <DocumentsPanel />
          <CreditAssessmentPanel />
          <ApplicationSummaryPanel readOnly={readOnly} />
          <InternalRemarksPanel />
          <AdditionalDetailsPanel />
        </>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the application does not exist."
        />
      )}
    </div>
  );
};

export default SmeApplicationDetailsPage;
