export const downloadFile = (csvContent: any, fileName: any, filetype: any) => {
  const blob = new Blob([csvContent], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
  });
  const _win = window.navigator as any;
  if (_win && _win.msSaveBlob) {
    // For IE browser
    _win.msSaveBlob(blob, fileName);
  } else {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(objectUrl);
  }
};
