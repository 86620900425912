import "../index.less";

import { Divider } from "antd";
import { useSelector } from "react-redux";

import InfoGrid from "../../../components/InfoGrid";
import { RootState } from "../../../store/store";
import { trimPhoneNo } from "../../../utils/number.util";

const EmergencyInformation = () => {
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const application = accountEnquiry?.data?.agreementDetails?.application;

  const emergencyInformation = [
    {
      title: "Emergency Contact Person Name",
      value:
        accountEnquiry.data.accountDetails?.emergencyContactName ??
        application?.emergencyContactName,
    },
    {
      title: "Relationship",
      value:
        accountEnquiry.data.accountDetails
          ?.applicantEmergencyContactRelationship ??
        application?.applicantEmergencyContactRelationship,
    },
    {
      title: "Emergency Contact No.",
      value: trimPhoneNo(
        accountEnquiry.data.accountDetails?.emergencyContactNo ??
          application?.emergencyContactNo,
      ),
    },
    {
      title: "Emergency Contact Email Address",
      value:
        accountEnquiry.data.accountDetails?.emergencyContactPersonEmail ??
        application?.emergencyContactPersonEmail,
    },
    {
      title: "Second Emergency Contact Person Name",
      value:
        accountEnquiry.data.accountDetails?.secondEmergencyContactName ??
        application?.secondEmergencyContactName,
    },
    {
      title: "Second Emergency Relationship",
      value:
        accountEnquiry.data.accountDetails
          ?.applicantSecondEmergencyContactRelationship ??
        application?.applicantSecondEmergencyContactRelationship,
    },
    {
      title: "Second Emergency Contact No.",
      value: trimPhoneNo(
        accountEnquiry.data.accountDetails?.secondEmergencyContactNo ??
          application?.secondEmergencyContactNo,
      ),
    },
  ];

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <h3 className="account-enquiry-section-subtitle">
          Emergency Information
        </h3>
        <Divider style={{ margin: 0 }} />
        <div className="info-container">
          <InfoGrid data={emergencyInformation} />
        </div>
      </div>
    </div>
  );
};

export default EmergencyInformation;
