import {
  LoginPayload,
  ResetPasswordPayload,
} from "./../../features/user/userSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import { User } from "../../features/user/userSlice";
import userService from "./userService";

export const loginThunk = createAsyncThunk(
  "user/login",
  async (requestData: LoginPayload, thunkAPI) => {
    try {
      return await userService.login(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const logoutThunk = createAsyncThunk(
  "user/logout",
  async (_: void, thunkAPI) => {},
);

export const getCurrentUserThunk = createAsyncThunk(
  "user/getCurrent",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.getCurrentUser(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createUserThunk = createAsyncThunk(
  "user/create",
  async (requestData: User, thunkAPI) => {
    try {
      return await userService.createUser(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateUserThunk = createAsyncThunk(
  "user/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.updateUser(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const resetPasswordThunk = createAsyncThunk(
  "user/resetPassword",
  async (requestData: ResetPasswordPayload, thunkAPI) => {
    try {
      return await userService.resetPassword(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWhitelistMemberThunk = createAsyncThunk(
  "user/getWhitelistMember",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.getWhitelistMember(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteWhitelistMemberThunk = createAsyncThunk(
  "user/deleteWhitelistMember",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.deleteWhitelistMember(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const uploadWhitelistThunk = createAsyncThunk(
  "user/uploadWhitelist",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.uploadWhitelisted(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadWhitelistTemplateThunk = createAsyncThunk(
  "user/downloadWhitelistTemplate",
  async (requestData, thunkAPI) => {
    try {
      return await userService.downloadWhitelistTemplate(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
