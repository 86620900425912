import "./index.less";

import { Button, Form, InputNumber, message, Modal, Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import {
  LetterType,
  PaymentNoticeConfigType,
  SendMethod,
} from "../../../features/paymentNotice/paymentNoticeConfigSlice";
import {
  createPaymentNoticeConfigThunk,
  updatePaymentNoticeConfigThunk,
} from "../../../services/paymentNoticeService/paymentNoticeThunk";
import { AppDispatch } from "../../../store/store";
import { updateRefundChargeConfigThunk } from "../../../services/collectionService/collectionThunk";

const RefundChargeConfigModal: React.FC<{
  data?: PaymentNoticeConfigType;
  callback?: () => void;
}> = ({ data, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const updateMode = data != null;

  const labelClassName = "w-[100px]";

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        dispatch(
          updateRefundChargeConfigThunk({
            ...data,
            refundCharge: values.refundCharge,
            createdBy: localStorage.getItem("username"),
          }),
        )
          .unwrap()
          .then(() => {
            form.resetFields();
            if (callback) callback();
            message.success("Update Successfully");
            handleCancel();
          })
          .catch(() =>
            message.error(`Error save payment notice config ${values.DPD}`),
          )
          .finally(() => setLoading(false));
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
        setLoading(false);
      });
  };

  return (
    <>
      {updateMode ? (
        <Button icon={<EditOutlined />} onClick={showModal}>
          Update
        </Button>
      ) : (
        <Button icon={<PlusOutlined />} onClick={showModal}>
          New
        </Button>
      )}

      <Modal
        title={
          data != null
            ? "Update Payment Notice Config"
            : "Create Payment Notice Config"
        }
        okText={data != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={450}
      >
        <Form
          className="pt-4 pb-2"
          form={form}
          initialValues={data}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Refund Charge</span>}
            name="refundCharge"
            rules={[{ required: true, message: "Please enter Refund Charge." }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Please Input"
              controls={false}
              inputMode="numeric"
              type="number"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RefundChargeConfigModal;
