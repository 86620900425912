export enum Role {
  SUPER_ADMIN = "SUPER_ADMIN",
  APPROVER = "APPROVER",
  APPROVER_2 = "APPROVER_2",
  VERIFIER = "VERIFIER",
  VERIFIER_2 = "VERIFIER_2",
  ATTESTATOR = "ATTESTATOR",
  FINANCE = "FINANCE",
  WRITE_OFF_BATCH_TWO_APPROVER = "WRITE_OFF_BATCH_TWO_APPROVER",
  CUSTOMER_SERVICE = "CUSTOMER_SERVICE",
}
