import { Button, message, Popconfirm, Space, Table } from "antd";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "@ant-design/pro-components";

import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDateAndTime } from "../../../../utils/datetime.util";
import startCase from "lodash.startcase";
import { numberWithCommas } from "../../../../helpers/number";
import LoanInsuranceCoverageModal from "./LoanInsuranceCoverageModal";
import { useParams } from "react-router-dom";
import { getLoanInsuranceByIdThunk } from "../../../../services/masterService/masterThunk";

export type LoanInsuranceCoverageProps = {
  callback?: any;
};

const LoanInsuranceCoverage = (props: LoanInsuranceCoverageProps) => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const { loanInsuranceDetails } = useSelector(
    (state: RootState) => state.loanInsurance,
  );

  const fetchLoanInsuranceDetails = async () => {
    await dispatch(getLoanInsuranceByIdThunk({ id }));
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "Coverage",
      dataIndex: "coverageName",
      key: "coverageName",
      render: (text, record) => {
        return startCase(record.coverageName);
      },
    },
    {
      title: "Amount (RM)",
      dataIndex: "coverageAmount",
      key: "coverageAmount",
      render: (text, record) => {
        return numberWithCommas(record.coverageAmount);
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        /*Update or Delete loan insurance coverage*/
        <LoanInsuranceCoverageModal
          loanInsuranceProductId={loanInsuranceDetails.data?.id}
          data={record}
          callback={() => {
            fetchLoanInsuranceDetails();
          }}
        />
      ),
    },
  ];

  return (
    <>
      {/*Create loan insurance coverage*/}
      <LoanInsuranceCoverageModal
        loanInsuranceProductId={loanInsuranceDetails.data?.id}
        callback={() => {
          fetchLoanInsuranceDetails();
        }}
      />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        dataSource={loanInsuranceDetails.data?.loanInsuranceCoverage ?? []}
        loading={loanInsuranceDetails.isLoading}
        rowClassName={() => "editable-row"}
        pagination={false}
      />
    </>
  );
};
export default LoanInsuranceCoverage;
