import { Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "@ant-design/pro-components";

import { noticesConfigReset } from "../../../features/noticesGenerationConfig/noticesGenerationConfigSlice";
import { numberWithCommas } from "../../../helpers/number";
import { getAllListingNoticesGenerationConfigThunk } from "../../../services/noticesGenerationConfigService/noticesGenerationConfigThunk";
import { AppDispatch, RootState } from "../../../store/store";
import NoticesGenerationConfigFormModal from "../FormModalNoticesGenerationConfig";

const NoticesGenerationConfigListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { listingNoticesConfigState } = useSelector(
    (state: RootState) => state.noticesConfig,
  );

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "Letter Type",
      dataIndex: "letterType",
      key: "letterType",
      render: (_, record) => {
        return record?.letterType.replace(/[_]/g, " ");
      },
    },
    {
      title: "DPD",
      dataIndex: "dpdToTrigger",
      key: "dpdToTrigger",
    },
    {
      title: "Charges (RM)",
      dataIndex: "charge",
      key: "charge",
      render: (_, record) => {
        return numberWithCommas(record.charge);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space>
          <NoticesGenerationConfigFormModal
            key={record?.id}
            notices={{ ...record, charge: +record.charge }}
            callback={() => fetchNoticesConfigListing()}
          />
        </Space>
      ),
    },
  ];

  const fetchNoticesConfigListing = async () => {
    await dispatch(getAllListingNoticesGenerationConfigThunk());
  };

  const initialCallbackState = { fetchNoticesConfigListing };

  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    callbackState.fetchNoticesConfigListing();
  }, []);

  useEffect(() => {
    dispatch(noticesConfigReset());
  }, []);

  return (
    <>
      <Helmet>
        <title>Notices Generation Config - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title="Notices Generation Config"
        extra={
          <NoticesGenerationConfigFormModal
            callback={() => fetchNoticesConfigListing()}
          />
        }
      />

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        dataSource={listingNoticesConfigState?.data}
        loading={listingNoticesConfigState.isLoading}
      />
    </>
  );
};
export default NoticesGenerationConfigListing;
