import { createSlice } from "@reduxjs/toolkit";

import { getPaymentHistoryThunk } from "../../services/paymentService/paymentThunk";
import { getManualPaymentThunk } from "../../services/manualPayment.service/manualPaymentThunk";

export type PaymentHistoryData = {
  id: string;
  referenceNo: string;
  transactionType: string;
  transactionAmount: number;
  agreementId: string;
  instalmentNo: number;
  paymentDate: string;
  paymentUniqueId: string;
  createdAt?: string;
  updatedAt?: string;
  transactionId?: string;
};

export type ManualPaymentData = {
  id?: string;
  agreementNo?: string;
  amount?: number;
  channel?: string;
  createdBy?: string;
  payDate?: string;
  createdAt?: string;
};

export type CreateManualPaymentRequest = {
  agreementId: string;
  amount: number;
  channel: string;
  createdBy?: string;
  paymentDate: string;
  bank: string;
  referenceNo: string;
  tranId: string;
};

export type PaymentHistoryDataResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: PaymentHistoryData[];
};

export type PaymentState = {
  paymentHistory: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: PaymentHistoryDataResponse;
  };

  manualPayment: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ManualPaymentData[];
    total: number;
    pageSize: number;
    currentPage: number;
  };
};

const initialState: PaymentState = {
  paymentHistory: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },

  manualPayment: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    total: 0,
    pageSize: 0,
    currentPage: 0,
    data: [],
  },
};

export const payment = createSlice({
  name: "payment",
  initialState,
  reducers: {
    PaymentStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentHistoryThunk.pending, (state) => {
        state.paymentHistory.isLoading = true;
      })
      .addCase(getPaymentHistoryThunk.fulfilled, (state, action) => {
        state.paymentHistory.isLoading = false;
        state.paymentHistory.data = action.payload;
      })
      .addCase(getPaymentHistoryThunk.rejected, (state, action) => {
        state.paymentHistory.isLoading = false;
        state.paymentHistory.isError = true;
        state.paymentHistory.errorMessage = action.payload;
      });

    builder
      .addCase(getManualPaymentThunk.pending, (state) => {
        state.manualPayment.isLoading = true;
      })
      .addCase(getManualPaymentThunk.fulfilled, (state, action) => {
        state.manualPayment.isLoading = false;
        state.manualPayment.data = action.payload.data;
        state.manualPayment.total = action.payload.total;
        state.manualPayment.pageSize = action.payload.pageSize;
        state.manualPayment.currentPage = action.payload.currentPage;
      })
      .addCase(getManualPaymentThunk.rejected, (state, action) => {
        state.manualPayment.isLoading = false;
        state.manualPayment.isError = true;
        state.manualPayment.errorMessage = action.payload;
      });
  },
});

export const { PaymentStateReset } = payment.actions;

export default payment.reducer;
