import "./index.less";

import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import React, { useEffect, useState } from "react";

type UpdateWaiveAmountModalProps = {
  chargeDetails?: any;
  // callback?: (data:object) => any;
  chargesArray?: any;
  tax?: any;
  chargesType?: any;
  waiverAmount?: any;
};

const UpdateWaiverAmountModal: React.FC<UpdateWaiveAmountModalProps> = (
  props,
) => {
  const { chargeDetails, chargesArray, tax, chargesType, waiverAmount } = props;
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [waiverTax, setWaiverTax] = useState(0);

  const showModal = () => {
    setIsModalOpen(true);
    form.setFieldsValue({
      chargesType: chargeDetails.chargesType,
      waiverAmount: chargeDetails.waiverAmount,
      waiverTax: chargeDetails.waiverTax,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const total =
          parseFloat(values.waiverAmount.toFixed(2)) +
          parseFloat(((values.waiverAmount * tax) / 100).toFixed(2));
        //   if(values.waiverAmount === chargeDetails.amountTotal){
        //     const payload = {
        //       chargesType: values.chargesType,
        //       total: values.waiverAmount,
        //       waiverAmount: values.waiverAmount,
        //     };

        //     // Find the object in chargesArray with matching key
        //     const matchingObject = chargesArray.find(
        //       (obj: any) => obj.chargesType === payload.chargesType,
        //     );

        //     // Update the total if a matching object is found
        //     if (matchingObject) {
        //       matchingObject.total = payload.total;
        //       matchingObject.waiverAmount = payload.waiverAmount;
        //       matchingObject.waiverTax = 0;
        //     }

        //     // Callback method
        //     // if (callback) callback({...payload});

        //     message.success("Update Successfully");
        //     setIsModalOpen(false);
        //   }
        //  else
        if (total.toFixed(2) <= chargeDetails.amountTotal) {
          const payload = {
            chargesType: values.chargesType,
            total: total,
            waiverAmount: values.waiverAmount,
          };

          // Find the object in chargesArray with matching key
          const matchingObject = chargesArray.find(
            (obj: any) => obj.chargesType === payload.chargesType,
          );

          // Update the total if a matching object is found
          if (matchingObject) {
            matchingObject.total = parseFloat(payload.total.toFixed(2));
            matchingObject.waiverAmount = parseFloat(
              payload.waiverAmount.toFixed(2),
            );
            matchingObject.waiverTax = parseFloat(
              ((payload.waiverAmount * tax) / 100).toFixed(2),
            );
          }

          // Callback method
          // if (callback) callback({...payload});

          message.success("Update Successfully");
          setWaiverTax(0);
          setIsModalOpen(false);
        } else {
          message.error(
            "Waiver amount after tax can't bigger than charge amount",
          );
        }
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (chargeDetails) {
      setWaiverTax(chargeDetails?.waiverTax);
    }
  }, [chargeDetails]);
  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        // disabled={
        //     chargeDetails?.applicationStatus ===
        //     ApplicationStatusEnum.APPROVED ||
        //     chargeDetails?.applicationStatus ===
        //     ApplicationStatusEnum.REJECTED
        // }
        ghost
      >
        Edit
      </Button>
      <Modal
        title="Update Waive Amount"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={360}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item label="Charges Type" name="chargesType">
            <Input style={{ width: "100%" }} disabled />
          </Form.Item>
          <Form.Item
            label="Waive Amount"
            name="waiverAmount"
            rules={[{ required: true, message: "Waive amount is required" }]}
          >
            <InputNumber
              placeholder="Please input"
              style={{ width: "100%" }}
              controls={false}
              inputMode="numeric"
              type={"number"}
              prefix="RM"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateWaiverAmountModal;
