import { createSlice } from "@reduxjs/toolkit";

import {
  getApprovedEarlySettlementsThunk,
  getEarlySettlementByIdThunk,
  getEarlySettlementsThunk,
  getInProcessEarlySettlementsThunk,
  getNewEarlySettlementsThunk,
  getRunningEarlySettlementByAgreementNoThunk,
  getRunningEarlySettlementThunk,
  getVoidEarlySettlementsThunk,
} from "../../services/earlySettlementService/earlySettlementThunk";
import { AccountDetails } from "../collection/collectionSlice";

export interface EarlySettlement {
  id: string;
  accountDetailsId: string;
  earlySettlementNo: string;
  earlySettlementDate: string;
  earlySettlementChargeOBId: string;
  earlySettlementTaxAmount?: number;
  earlySettlementChargeAfterTax?: number;
  miscTaxAmount?: number;
  miscChargeAfterTax?: number;
  status: string;
  remark: string;
  specialTaggingAuto?: string;
  specialTaggingManual?: string;
  createdAt: string;
  updatedAt: string;
  earlySettlementChargeOB: EarlySettlementChargeOb;
  accountDetails: AccountDetails;
}

export interface EarlySettlementChargeOb {
  id: string;
  principalOB: number;
  interestOB: number;
  accountDetailsId: string;
  legalCharge: number;
  bankCharge: number;
  ecaCharge: number;
  lodCharge: number;
  noticesCharge: number;
  refundCharge: number;
  adminCharge: number;
  latePaymentCharge: number;
  fieldCharge: number;
  storageCharge: number;
  repoCharge: number;
  adjustmentCharge: number;
  reminderLetter1Charge: number;
  reminderLetter2Charge: number;
  reminderLetter3Charge: number;
  finalNoticeCharge: number;
  thirdPartyCollectCharge: number;
  towingCharge: number;
  fourthScheduleCharge: number;
  fifthScheduleCharge: number;
  blacklistUpliftCharge: number;
  earlySettlementCharge: number;
  miscCharge: number;
  createdAt: string;
  updatedAt: string;
}

export interface EarlySettlementState {
  runingEarlySettlement: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: { earlySettlement?: EarlySettlement };
  };
  allEarlySettlementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: EarlySettlement[];
    };
  };
  newEarlySettlementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: EarlySettlement[];
    };
  };
  voidEarlySettlementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: EarlySettlement[];
    };
  };
  approvedEarlySettlementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: EarlySettlement[];
    };
  };
  inProcessEarlySettlementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: EarlySettlement[];
    };
  };
  earlySettlementDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: EarlySettlement;
  };
}

const initialState: EarlySettlementState = {
  runingEarlySettlement: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { earlySettlement: undefined },
  },
  allEarlySettlementList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  newEarlySettlementList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  voidEarlySettlementList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  approvedEarlySettlementList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  inProcessEarlySettlementList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  earlySettlementDetails: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: undefined,
  },
};

export const earlySettlementSlice = createSlice({
  name: "earlySettlement",
  initialState,
  reducers: {
    earlySettlementStateReset: () => initialState,
    earlySettlementDetailsStateReset: (state) => {
      state.earlySettlementDetails = initialState.earlySettlementDetails;
    },
    runingEarlySettlementStateReset: (state) => {
      state.runingEarlySettlement = initialState.runingEarlySettlement;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRunningEarlySettlementThunk.pending, (state) => {
        state.runingEarlySettlement.isLoading = true;
      })
      .addCase(getRunningEarlySettlementThunk.fulfilled, (state, action) => {
        state.runingEarlySettlement.isLoading = false;
        state.runingEarlySettlement.data = action.payload;
      })
      .addCase(getRunningEarlySettlementThunk.rejected, (state, action) => {
        state.runingEarlySettlement.isLoading = false;
        state.runingEarlySettlement.isError = true;
        state.runingEarlySettlement.errorMessage = action.payload;
      });
    builder
      .addCase(getRunningEarlySettlementByAgreementNoThunk.pending, (state) => {
        state.runingEarlySettlement.isLoading = true;
      })
      .addCase(
        getRunningEarlySettlementByAgreementNoThunk.fulfilled,
        (state, action) => {
          state.runingEarlySettlement.isLoading = false;
          state.runingEarlySettlement.data = action.payload;
        },
      )
      .addCase(
        getRunningEarlySettlementByAgreementNoThunk.rejected,
        (state, action) => {
          state.runingEarlySettlement.isLoading = false;
          state.runingEarlySettlement.isError = true;
          state.runingEarlySettlement.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getEarlySettlementsThunk.pending, (state) => {
        state.allEarlySettlementList.isLoading = true;
      })
      .addCase(getEarlySettlementsThunk.fulfilled, (state, action) => {
        state.allEarlySettlementList.isLoading = false;
        state.allEarlySettlementList.data = action.payload;
      })
      .addCase(getEarlySettlementsThunk.rejected, (state, action) => {
        state.allEarlySettlementList.isLoading = false;
        state.allEarlySettlementList.isError = true;
        state.allEarlySettlementList.errorMessage = action.payload;
      });

    builder
      .addCase(getNewEarlySettlementsThunk.pending, (state) => {
        state.newEarlySettlementList.isLoading = true;
      })
      .addCase(getNewEarlySettlementsThunk.fulfilled, (state, action) => {
        state.newEarlySettlementList.isLoading = false;
        state.newEarlySettlementList.data = action.payload;
      })
      .addCase(getNewEarlySettlementsThunk.rejected, (state, action) => {
        state.newEarlySettlementList.isLoading = false;
        state.newEarlySettlementList.isError = true;
        state.newEarlySettlementList.errorMessage = action.payload;
      });

    builder
      .addCase(getVoidEarlySettlementsThunk.pending, (state) => {
        state.voidEarlySettlementList.isLoading = true;
      })
      .addCase(getVoidEarlySettlementsThunk.fulfilled, (state, action) => {
        state.voidEarlySettlementList.isLoading = false;
        state.voidEarlySettlementList.data = action.payload;
      })
      .addCase(getVoidEarlySettlementsThunk.rejected, (state, action) => {
        state.voidEarlySettlementList.isLoading = false;
        state.voidEarlySettlementList.isError = true;
        state.voidEarlySettlementList.errorMessage = action.payload;
      });

    builder
      .addCase(getApprovedEarlySettlementsThunk.pending, (state) => {
        state.approvedEarlySettlementList.isLoading = true;
      })
      .addCase(getApprovedEarlySettlementsThunk.fulfilled, (state, action) => {
        state.approvedEarlySettlementList.isLoading = false;
        state.approvedEarlySettlementList.data = action.payload;
      })
      .addCase(getApprovedEarlySettlementsThunk.rejected, (state, action) => {
        state.approvedEarlySettlementList.isLoading = false;
        state.approvedEarlySettlementList.isError = true;
        state.approvedEarlySettlementList.errorMessage = action.payload;
      });

    builder
      .addCase(getInProcessEarlySettlementsThunk.pending, (state) => {
        state.inProcessEarlySettlementList.isLoading = true;
      })
      .addCase(getInProcessEarlySettlementsThunk.fulfilled, (state, action) => {
        state.inProcessEarlySettlementList.isLoading = false;
        state.inProcessEarlySettlementList.data = action.payload;
      })
      .addCase(getInProcessEarlySettlementsThunk.rejected, (state, action) => {
        state.inProcessEarlySettlementList.isLoading = false;
        state.inProcessEarlySettlementList.isError = true;
        state.inProcessEarlySettlementList.errorMessage = action.payload;
      });

    builder
      .addCase(getEarlySettlementByIdThunk.pending, (state) => {
        state.earlySettlementDetails.isLoading = true;
      })
      .addCase(getEarlySettlementByIdThunk.fulfilled, (state, action) => {
        state.earlySettlementDetails.isLoading = false;
        state.earlySettlementDetails.data = action.payload;
      })
      .addCase(getEarlySettlementByIdThunk.rejected, (state, action) => {
        state.earlySettlementDetails.isLoading = false;
        state.earlySettlementDetails.isError = true;
        state.earlySettlementDetails.errorMessage = action.payload;
      });
  },
});

export const {
  earlySettlementStateReset,
  earlySettlementDetailsStateReset,
  runingEarlySettlementStateReset,
} = earlySettlementSlice.actions;

export default earlySettlementSlice.reducer;
