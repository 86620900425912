import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import smeFileService from "./smeFileService";
import { SME_ORGANIZATION_TYPE } from "../../enum/smeApplication";

export const getRequiredFileByPartnerTypeThunk = createAsyncThunk(
  "smeFile/getRequiredFileByPartnerType",
  async (organizationType: SME_ORGANIZATION_TYPE, thunkAPI) => {
    try {
      return await smeFileService.getRequiredFileByPartnerType(organizationType);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);