import "./index.less";

import { Button, Form, Input, message, Modal, Popconfirm, Space } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { WAIVER_BATCH_STATUS } from "../../enum/waiverBatchStatus";
import {
  approveWaiverThunk,
  submitReferbackWaiverThunk,
  submitVoidWaiverThunk,
} from "../../services/waiverService/waiverThunk";
import { AppDispatch } from "../../store/store";

const { TextArea } = Input;

type UpdateWaiverStatusModalProps = {
  // callback?: (data:object) => any;
  status?: any;
  waiverId?: any;
};

const UpdateWaiverStatusModal: React.FC<UpdateWaiverStatusModalProps> = (
  props,
) => {
  const { status, waiverId } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [remarks, setRemarks] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formattedDate = moment().toISOString();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const approveWaiver = async () => {
    const payload = {
      waiverId: waiverId,
      decider: localStorage.getItem("username"),
      remarks: remarks,
      decidedAt: formattedDate,
    };
    await dispatch(approveWaiverThunk(payload))
      .then((res) => navigate(`/waiver/waiver-listing`))
      .catch(() => {
        message.error("Failed to update waiver status");
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        // Callback method
        // if (callback) callback({...payload});

        if (remarks.length > 5) {
          if (status === WAIVER_BATCH_STATUS.REFERBACK) {
            message.success("Waiver status change to referback");

            const payload = {
              waiverId: waiverId,
              decider: localStorage.getItem("username"),
              remarks: remarks,
              decidedAt: formattedDate,
            };
            setIsModalOpen(false);
            await dispatch(submitReferbackWaiverThunk(payload))
              .then((res) => navigate(`/waiver/waiver-listing`))
              .catch(() => {
                message.error("Failed to update waiver status");
              });
          } else if (status === WAIVER_BATCH_STATUS.VOID) {
            message.success("Waiver status change to void");
            const payload = {
              waiverId: waiverId,
              decider: localStorage.getItem("username"),
              remarks: remarks,
              decidedAt: formattedDate,
            };

            setIsModalOpen(false);
            dispatch(submitVoidWaiverThunk(payload))
              .then((res) => navigate(`/waiver/waiver-listing`))
              .catch(() => {
                message.error("Failed to update waiver status");
              });
          }
        } else {
          message.error("The length of remarks should be more than 5");
        }
      })
      .catch(() => {
        message.error("Failed to update waiver status");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {status === "APPROVED" && (
        <>
          <Popconfirm
            title="Approve this waiver batch?"
            onConfirm={() => approveWaiver()}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              // disabled={
              //     chargeDetails?.applicationStatus ===
              //     ApplicationStatusEnum.APPROVED ||
              //     chargeDetails?.applicationStatus ===
              //     ApplicationStatusEnum.REJECTED
              // }
            >
              Approved
            </Button>
          </Popconfirm>
        </>
      )}

      {status === WAIVER_BATCH_STATUS.VOID && (
        <Button
          onClick={showModal}
          // disabled={
          //     chargeDetails?.applicationStatus ===
          //     ApplicationStatusEnum.APPROVED ||
          //     chargeDetails?.applicationStatus ===
          //     ApplicationStatusEnum.REJECTED
          // }
        >
          Void
        </Button>
      )}

      {status === WAIVER_BATCH_STATUS.REFERBACK && (
        <Button
          onClick={showModal}
          // disabled={
          //     chargeDetails?.applicationStatus ===
          //     ApplicationStatusEnum.APPROVED ||
          //     chargeDetails?.applicationStatus ===
          //     ApplicationStatusEnum.REJECTED
          // }
        >
          Referback
        </Button>
      )}

      <Modal
        title="Please enter reason"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={360}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          initialValues={{}}
        >
          <Form.Item
            label="Remarks"
            name="remarks"
            rules={[{ required: true, message: "Reason is required" }]}
          >
            <TextArea
              placeholder="Please input"
              style={{ width: "100%" }}
              onChange={(e) => setRemarks(e.target.value)}
              minLength={5}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateWaiverStatusModal;
