import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import writeOffService from "./writeOffService";

export const createWriteOffBatchThunk = createAsyncThunk(
  "writeOff/createWriteOffBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.createWriteOffBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAllWriteOffThunk = createAsyncThunk(
  "writeOff/getAllWriteOffListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getAllWriteOffListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getOpenWriteOffThunk = createAsyncThunk(
  "writeOff/getOpenWriteOffListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getOpenWriteOffListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getInProcessWriteOffThunk = createAsyncThunk(
  "writeOff/getInProcessWriteOffListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getInProcessWriteOffListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingWriteOffThunk = createAsyncThunk(
  "writeOff/getPendingWriteOffListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getPendingWriteOffListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferbackWriteOffThunk = createAsyncThunk(
  "writeOff/getReferbackWriteOffThunk",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getReferbackWriteOffListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVoidWriteOffThunk = createAsyncThunk(
  "writeOff/getVoidWriteOffListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getVoidWriteOffListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApprovedWriteOffThunk = createAsyncThunk(
  "writeOff/getApprovedWriteOffListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getApprovedWriteOffListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWriteOffByBatchNoThunk = createAsyncThunk(
  "writeOff/getWriteOffByBatchNoThunk",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getWriteOffByBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWriteOffSummaryByBatchIdThunk = createAsyncThunk(
  "writeOff/getWriteOffSummaryByBatchId",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getWriteOffSummaryByBatchId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWriteOffDetailsByBatchIdThunk = createAsyncThunk(
  "writeOff/getWriteOffDetailsByBatchId",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getWriteOffDetailsByBatchId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWriteOffRemarkListingByBatchIdThunk = createAsyncThunk(
  "writeOff/getWriteOffRemarkListingByBatchId",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getWriteOffRemarkListingByBatchId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const addWriteOffRemarkThunk = createAsyncThunk(
  "writeOff/addWriteOffRemark",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.addWriteOffRemark(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const addAgreementNoToWriteOffBatchThunk = createAsyncThunk(
  "writeOff/addAgreementNoToWriteOffBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.addAgreementNoToWriteOffBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const selectAgreementNoInWriteOffBatchThunk = createAsyncThunk(
  "writeOff/selectAgreementNoInWriteOffBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.selectAgreementNoInWriteOffBatch(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateWriteOffStatusThunk = createAsyncThunk(
  "writeOff/updateWriteOffStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.updateWriteOffStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadWriteOffApprovalListThunk = createAsyncThunk(
  "writeOff/downloadWriteOffApprovalList",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.downloadWriteOffApprovalList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const uploadWriteOffApprovalListThunk = createAsyncThunk(
  "writeOff/uploadWriteOffApprovalList",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.uploadWriteOffApprovalList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const uploadWriteOffApprovalFormThunk = createAsyncThunk(
  "writeOff/uploadWriteOffApprovalForm",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.uploadWriteOffApprovalForm(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWriteOffFileByFileIdThunk = createAsyncThunk(
  "writeOff/getWriteOffFileByFileId",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getWriteOffFileByFileId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWriteOffMonitorListingThunk = createAsyncThunk(
  "writeOff/getWriteOffMonitorListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await writeOffService.getWriteOffMonitorListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
