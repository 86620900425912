import InfoGrid from "../../../components/InfoGrid";
import {
  EditDirectorModal
} from "../../SmeApplication/ApplicationDetails/AdditionalDetailsPanel/DirectorInfoTab/EditDirector";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export const SmeDirectorDetails = () => {

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const data = accountEnquiry?.data?.accountDetails?.smeDirector

  const directorDetails = data?.map((director, index) => {
    return [
      {
        title: `${index + 1}. Name`,
        value: director?.directorName,
      },
      {
        title: `NRIC`,
        value: director?.nric,
      },
      {
        title: `Identity Card Type`,
        value: director?.icType,
      },
      {
        title: `Position in Company`,
        value: director?.shareholdingType,
      },
      {
        title: `Contact Number`,
        value: director?.phoneNo,
      },
      {
        title: `Personal Email`,
        value: director?.personalEmail,
      },
      {
        title: `No. Of Shares Unit`,
        value: director?.noOfShares,
      },
      {
        title: `No. Of Shares %`,
        value: director?.sharesPercentage,
      },
    ];
  });

  return (
    <div className="info-container">
      <InfoGrid title="Details of Shareholders, Directors or Beneficial Owners Details" />
      <InfoGrid data={directorDetails?.flat()} />
    </div>
  );
}