import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Popconfirm, Space, Table } from "antd";

import type { ColumnsType } from "antd/es/table";

import { AppDispatch, RootState } from "../../store/store";
import {
  deleteAgreementNoFromWaiverThunk,
  getAgreementListThunk,
} from "../../services/waiverService/waiverThunk";
import { WAIVER_BATCH_STATUS } from "../../enum/waiverBatchStatus";
import { useNavigate } from "react-router-dom";
import WaiverFileModal from "./WaiverFileModal";
import WaiverFileListModal from "../WaiverFileListModal";
import { numberWithCommas } from "../../helpers/number";
import AccountEnquiryModal from "../../pages/AccountEnquiry/AccountEnquiryModal";

type AgreementTableProps = {
  value?: string;
  callback?: any;
  waiverId?: string;
};

const AgreementsTable: React.FC<AgreementTableProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { value, callback, waiverId } = props;
  const navigate = useNavigate();

  const { agreementList } = useSelector((state: RootState) => state.waiver);

  const getAgreementList = async () => {
    await dispatch(getAgreementListThunk(value));
  };
  const [isCallback, setIsCallback] = useState(false);
  // useLifecycles(
  //     () => value && getApplicatinList(value),

  // );

  const deleteWaiverRecord = async (
    waiverId: string,
    accountDetailsId: string,
  ) => {
    const payload = {
      waiverId,
      accountDetailsId,
    };
    await dispatch(deleteAgreementNoFromWaiverThunk({ payload })).then(() => {
      getAgreementList();
    });
  };

  const editWaiverRecord = async (record: any) => {
    const waiverId = value;
    const accountDetailsId = record.accountCharges.accountDetailsId;
    const agreementNo = record.agreementNo;
    navigate(
      `/waiver/edit-waiver/waiverId/${waiverId}/accountDetails/${accountDetailsId}/agreementNo/${agreementNo}/status/${record.status}`,
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (text: string, record: any, index: number) => index + 1,
    },
    {
      title: "Agreement No",
      key: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal key={record.id} agreementNo={record.agreementNo} />
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "applicantFirstName",
      key: "applicantFirstName",
      render: (_, record) => record?.application?.applicantFirstName ?? "-",
    },
    {
      title: "Charge Amount After Tax (RM)",
      dataIndex: "amountAfterTax",
      key: "amountAfterTax",
      render: (_, record) =>
        record?.amountAfterTax != null
          ? `RM ${numberWithCommas(record?.amountAfterTax)}`
          : "-",
    },
    {
      title: "Total Waiver After Tax (RM)",
      dataIndex: "totalWaiverAfterTax",
      key: "totalWaiverAfterTax",
      render: (_, record) =>
        record?.totalWaiverAfterTax?.amount != null
          ? `RM ${numberWithCommas(record?.totalWaiverAfterTax?.amount)}`
          : "-",
    },
    {
      title: "Current Charge (RM)",
      dataIndex: "currentCharge",
      key: "currentCharge",
      render: (_, record) =>
        record?.currentCharge != null
          ? `RM ${numberWithCommas(record?.currentCharge)}`
          : "-",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (_, record) => record?.remarks ?? "-",
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
            <Space>
              {record.status === WAIVER_BATCH_STATUS.OPEN ? (
                <>
                  <WaiverFileListModal
                    entityId={record.accountDetailsWaiverBatchId}
                  />
                  <WaiverFileModal
                    waiverFile={record}
                    waiverId={waiverId}
                    callback={(res: any) => {
                      setIsCallback(true);
                      callback(res);
                    }}
                  />
                </>
              ) : (
                record.status === WAIVER_BATCH_STATUS.REFERBACK && (
                  <>
                    <WaiverFileListModal
                      entityId={record.accountDetailsWaiverBatchId}
                    />
                    <WaiverFileModal
                      waiverFile={record}
                      waiverId={waiverId}
                      callback={(res: any) => {
                        setIsCallback(true);
                        callback(res);
                      }}
                    />
                  </>
                )
              )}

              <Button
                type="primary"
                // disabled={
                //   // record.status !== WAIVER_BATCH_STATUS.REFERBACK ||
                //   record.status === WAIVER_BATCH_STATUS.APPROVED ||
                //   record.status === WAIVER_BATCH_STATUS.PENDING ||
                //   record.status === WAIVER_BATCH_STATUS.VOID
                // }
                onClick={() => editWaiverRecord(record)}
              >
                {record.status === WAIVER_BATCH_STATUS.OPEN ||
                record.status === WAIVER_BATCH_STATUS.REFERBACK
                  ? "Edit"
                  : "View"}
              </Button>
              <Popconfirm
                title="Delete this record?"
                onConfirm={() =>
                  deleteWaiverRecord(
                    value!,
                    record?.accountCharges.accountDetailsId,
                  )
                }
                okText="Yes"
                cancelText="No"
                disabled={
                  record.status === WAIVER_BATCH_STATUS.PENDING ||
                  record.status === WAIVER_BATCH_STATUS.APPROVED ||
                  record.status === WAIVER_BATCH_STATUS.VOID
                }
              >
                <Button
                  type="primary"
                  disabled={
                    record.status === WAIVER_BATCH_STATUS.PENDING ||
                    record.status === WAIVER_BATCH_STATUS.APPROVED ||
                    record.status === WAIVER_BATCH_STATUS.VOID
                  }
                >
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getAgreementList();
  }, []);

  return (
    <>
      {agreementList?.data && (
        <Table
          rowKey="id"
          className="min-w-fit"
          dataSource={agreementList?.data.data}
          columns={columns}
          bordered={true}
        />
      )}
    </>
  );
};

export default AgreementsTable;
