import { Button, Input, message, Popconfirm, Select, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../../helpers/number";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import type { TableProps } from "antd/es/table";
import type { ColumnsType } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import {
  approveSmeEStampingThunk,
  getCompletedSmeEStampListingThunk,
} from "../../../services/smeAgreementService/smeAgreementThunk";

const { Search } = Input;

const UploadedEStampTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { completedSmeEStampList } = useSelector(
    (state: RootState) => state.smeAgreement,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");
  const [approveLoading, setApproveLoading] = useState(false);

  const tablePagination = {
    total: completedSmeEStampList?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };

  const fetchAgreements = async (params?: any) => {
    await dispatch(
      getCompletedSmeEStampListingThunk({
        query: params,
        status: "completed",
      }),
    );
  };

  const onApprove = async (agreementId: string, data: any) => {
    setApproveLoading(true);

    const language = "EN";
    // if (data.application.language && data.application.language === "MS") {
    //   language = "BM";
    // }

    await dispatch(approveSmeEStampingThunk({ agreementId, language }))
      .unwrap()
      .then(() => {
        message.success("Approved");
        fetchAgreements(initialParams);
      })
      .finally(() => setApproveLoading(false));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchAgreements({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      fetchAgreements({
        ...initialParams,
        filters: { ...filterState },
      });
      setSearchValue("");
    }

    setFilterState({
      ...initialParams.filters,
    });
  };

  const handleTableChange: TableProps<any>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    setSortedInfo(sorter as SorterResult<any>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    const param = {
      ...initialParams,
      ...filterState,
      pagination,
      sortField: sorter?.order ? sorter?.columnKey : "createdAt",
      sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
      ...(searchValue.length > 0 && {
        search: {
          [searchFiled]: searchValue.toUpperCase(),
        },
      }),
    };

    fetchAgreements(param);

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      sorter: (a, b) => a.signDate - b.signDate,
      sortOrder: sortedInfo.columnKey === "signDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            queryParams.set("from", "estamp-pending");
            const newSearch = queryParams.toString();

            return navigate({
              pathname: `/sme/agreement/details/${record.smeAgreementId}`,
              search: newSearch,
            });
          }}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Name",
      dataIndex: "applicantName",
      key: "applicantName",
      render: (text, record) => record?.applicantName ?? "-",
    },
    {
      title: "Contact No.",
      dataIndex: "applicantContactNo",
      key: "applicantContactNo",
      render: (text, record) => record?.applicantContactNo ?? "-",
    },
    {
      title: "NRIC",
      dataIndex: "applicantNric",
      key: "applicantNric",
      render: (text, record) => record?.applicantNric ?? "-",
    },
    {
      title: "Finance Amount",
      key: "financeAmount",
      render: (text, record) =>
        record?.financeAmount != null
          ? `RM ${numberWithCommas(record?.financeAmount)}`
          : "-",
    },
    {
      title: "Status",
      dataIndex: "approveUploadedEStampingCert",
      key: "approveUploadedEStampingCert",
      render: (text, record) =>
        record?.approveUploadedEStampingCert &&
        record?.eStampingCertificateFileId != null
          ? "Approved"
          : "Uploaded",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const approved =
          record?.approveUploadedEStampingCert && record?.eStampFileId != null;

        return (
          <Popconfirm
            title="Approve this e-stamp?"
            onConfirm={() => onApprove(record.smeAgreementId, record)}
            okText="Yes"
            cancelText="No"
            disabled={approved}
          >
            <Button type="primary" disabled={approved} loading={approveLoading}>
              {approved ? "Approved" : "Approve"}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Uploaded E-Stamp - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
            { value: "name", label: "Applicant Name" },
            { value: "nric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={completedSmeEStampList?.data?.data ?? []}
        loading={completedSmeEStampList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default UploadedEStampTab;
