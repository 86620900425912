import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import actionCodeService from "./actionCodeService";

export const getAllActionCodeThunk = createAsyncThunk(
  "actionCode/getAll",
  async (requestData: any, thunkAPI) => {
    try {
      return await actionCodeService.getAllActionCode(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAllActiveActionCodeThunk = createAsyncThunk(
  "actionCode/getAllActive",
  async (requestData, thunkAPI) => {
    try {
      return await actionCodeService.getAllActiveActionCode();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAvailableActionCodeThunk = createAsyncThunk(
  "actionCode/getAvailableActionCode",
  async (requestData: any, thunkAPI) => {
    try {
      return await actionCodeService.getAvailableActionCode(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createActionCodeThunk = createAsyncThunk(
  "actionCode/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await actionCodeService.createActionCode(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateActionCodeThunk = createAsyncThunk(
  "actionCode/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await actionCodeService.updateActionCode(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateStatusActionCodeThunk = createAsyncThunk(
  "actionCode/updateStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await actionCodeService.updateStatusActionCode(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteActionCodeThunk = createAsyncThunk(
  "actionCode/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await actionCodeService.deleteActionCode(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
