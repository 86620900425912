import { Tabs, Tag } from "antd";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import { ApplicationStatusEnum } from "../../../enum/applicationStepStatus";
import { getDraftApplicationsThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";

import type { TabsProps } from "antd";
import DraftApplicationListing from "./DraftApplicationListing";

const EkycUploadImage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { draftApplicationList } = useSelector(
    (state: RootState) => state.application,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const items: TabsProps["items"] = [
    {
      key: "draft",
      label: (
        <span>
          Draft{" "}
          {draftApplicationList?.data?.total != null && (
            <Tag>{draftApplicationList.data.total}</Tag>
          )}
        </span>
      ),
      children: <DraftApplicationListing />,
    },
  ];

  const onFetchApplications = (status: ApplicationStatusEnum, params: any) => {
    if (status === ApplicationStatusEnum.DRAFT) {
      dispatch(getDraftApplicationsThunk(params));
    }
  };

  const onTabChange = (key: string) => {
    if (key === "draft") {
      onFetchApplications(ApplicationStatusEnum.DRAFT, initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    onFetchApplications(ApplicationStatusEnum.DRAFT, params);
  });

  return (
    <>
      <Helmet>
        <title>{`eKYC Upload Image - redCASH CEP`}</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="eKYC Upload Image" />
      <Tabs
        defaultActiveKey={activeTab ?? "draft"}
        items={items}
        onChange={onTabChange}
      />
    </>
  );
};

export default EkycUploadImage;
