import { ApplicationStatusEnum } from "../enum/applicationStepStatus";
import { AgreementStatusEnum } from "../enum/agreementStepStatus";
import startCase from "lodash.startcase";

export const showFinalStatus = (
  applicationStatus: ApplicationStatusEnum | null,
  agreementStatus: AgreementStatusEnum | null,
) => {
  if (!applicationStatus && !agreementStatus) {
    return "-";
  }

  if (applicationStatus === ApplicationStatusEnum.PENDING) {
    return "VERIFICATION";
  }

  if (applicationStatus === ApplicationStatusEnum.CANCELED) {
    return "CANCELLED";
  }

  if (applicationStatus === ApplicationStatusEnum.REJECTED) {
    return "REJECTED";
  }

  if (applicationStatus === ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL) {
    return "APPLICATION SUBMITTED FOR APPROVAL";
  }

  if (
    applicationStatus ===
    ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT
  ) {
    return "REFERBACK FROM PENDING DISBURSEMENT";
  }

  if (applicationStatus === ApplicationStatusEnum.REFERBACK) {
    return "REFERBACK";
  }

  if (applicationStatus === ApplicationStatusEnum.FOLLOWED_UP) {
    return "FOLLOW UP";
  }

  if (
    applicationStatus ===
    ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT
  ) {
    return "SUBMITTED FOR APPROVAL FROM PENDING DISBURSEMENT";
  }

  if (applicationStatus === ApplicationStatusEnum.APPROVED) {
    if (agreementStatus === AgreementStatusEnum.PENDING) {
      return "PENDING ATTESTATION";
    }

    if (
      agreementStatus === AgreementStatusEnum.REQUESTED_FOR_LIVE_ATTESTATION
    ) {
      return "REQUESTED FOR LIVE ATTESTATION";
    }

    if (agreementStatus === AgreementStatusEnum.PENDING_DISBURSEMENT) {
      return "PENDING DISBURSEMENT";
    }

    if (agreementStatus === AgreementStatusEnum.DISBURSED) {
      return "DISBURSED";
    }

    if (agreementStatus === AgreementStatusEnum.PENDING_SIGNING) {
      return "PENDING SIGNING";
    }

    if (agreementStatus === AgreementStatusEnum.DIRECT_DEBIT_ENROLLMENT) {
      return "DIRECT DEBIT ENROLLMENT";
    }
  }
};

export const displayApplicationStatus = (status: string) => {
  if (status === ApplicationStatusEnum.PENDING) {
    return "VERIFICATION";
  }
  if (status === ApplicationStatusEnum.FOLLOWED_UP) {
    return "FOLLOW UP";
  }
  return startCase(status);
};

export const getFormattedAddress = (addressLine1: string | null | undefined, addressLine2: string | null | undefined, addressLine3: string | null | undefined) => {
  let formattedAddress;

  if(addressLine1) {
    formattedAddress = addressLine1.replace(/[,\s!]/g, ' ').trim();
  }

  if(addressLine2) {
    const formattedLine2 = addressLine2 ? addressLine2.replace(/[,\s!]/g, ' ').trim() : '';
    formattedAddress += `\n${formattedLine2}`;
  }
  if (addressLine3) {
    const formattedLine3 = addressLine3 ? addressLine3.replace(/[,\s!]/g, ' ').trim() : '';
    formattedAddress += `\n${formattedLine3}`;
  }

  return formattedAddress;
}
