import { DatePicker, Form, Modal } from "antd";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

const RestructureDateModal = (props: {
  isModalVisible: boolean;
  setIsModalVisible: any;
  callback: any;
  loading?: boolean;
}) => {
  const { isModalVisible, setIsModalVisible, callback, loading } = props;
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState<Dayjs>();

  const onChange = (date: any) => {
    setSelectedDate(date);
  };

  const onClosModal = () => {
    setIsModalVisible(false);
    setSelectedDate(undefined);
    form.resetFields();
  };

  return (
    <>
      <Modal
        title="Restructure Date"
        open={isModalVisible}
        onOk={form.submit}
        onCancel={onClosModal}
        okText="Confirm"
        closable={false}
        width="320px"
        centered
        confirmLoading={loading}
      >
        <Form
          name="rescheduleDate"
          form={form}
          onFinish={() => callback(selectedDate?.startOf("day"))}
        >
          <Form.Item name="date" required>
            <DatePicker onChange={onChange} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RestructureDateModal;
