import { Tabs, TabsProps, Tag } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { REFUND_FINANCE_STATUS, REFUND_STATUS } from "../../../enum/refund";
import {
  getRefundFinanceBatchThunk,
  getRefundFinanceListThunk,
} from "../../../services/refundService/refundThunk";
import { AppDispatch, RootState } from "../../../store/store";
import RefundTable from "./refundTable";
import RefundFinanceTable from "./refundFinanceTable";
import RefundFinanceBatchTable from "./refundFinanceBatchTable";

const RefundFinanceListingTabs: React.FC<any> = (props) => {
  const { isUnmatched } = props;
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const activeTab = queryParams.get("tab");

  const { unmatchRefundListing, refundFinance, refundFinanceBatch } =
    useSelector((state: RootState) => state.refund);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const fetchAll = async (params?: any) => {
    if (type === "unmatched") {
      await fetchUnmatchedRefundFinanceList({
        ...params,
        filters: { status: "PENDING" },
        type: type ?? "refund",
        unmatchedRefundFianceBatchId: "null",
      });

      await fetchRefundFinanceBatch({
        ...params,
        type: type ?? "refund",
      });
    } else {
      await fetchPendingCreateBatchRefundFinanceList({
        ...params,
        filters: {
          status: "PENDING",
          refundFinanceBatchId: "null",
        },
        type: type ?? "refund",
      });

      await fetchRefundFinanceBatch({
        ...params,
        type: type ?? "refund",
      });
    }
  };

  const fetchPendingCreateBatchRefundFinanceList = async (params?: any) => {
    await dispatch(
      getRefundFinanceListThunk(
        params ?? {
          ...initialParams,
          type: type ?? "refund",
          filters: {
            refundFinanceBatchId: "null",
          },
        },
      ),
    );
  };

  const fetchRefundFinanceBatch = async (params?: any) => {
    await dispatch(
      getRefundFinanceBatchThunk(
        params ?? {
          ...initialParams,
          type: type ?? "refund",
        },
      ),
    );
  };

  const fetchUnmatchedRefundFinanceList = async (params?: any) => {
    let paramData: any = {};
    if (type === "unmatched") {
      paramData = {
        ...initialParams,
        type: type ?? "refund",
        filters: {
          status: "PENDING",
          unmatchedRefundFinanceBatchId: "null",
        },
      };
    }

    await dispatch(
      getRefundFinanceListThunk(
        params ?? {
          ...initialParams,
          type: type ?? "refund",
        },
      ),
    );
  };

  const refundFinanceItems: TabsProps["items"] = [
    {
      key: "pending",
      label: (
        <span>
          Pending Refund Finance <Tag>{refundFinance.data.total}</Tag>
        </span>
      ),
      children: (
        <RefundFinanceTable
          dataSource={refundFinance.data.data}
          status={REFUND_FINANCE_STATUS.PENDING}
          callback={(res: any) => {
            fetchAll(initialParams);
          }}
          isUnmatched={isUnmatched}
        />
      ),
    },
    {
      key: "created",
      label: (
        <span>
          Created Refund Finance Batch
          <Tag>{refundFinanceBatch?.data?.total}</Tag>
        </span>
      ),
      children: (
        <RefundFinanceBatchTable
          dataSource={refundFinanceBatch.data.data}
          callback={(res: any) => {
            fetchAll(initialParams);
          }}
        />
      ),
    },
  ];

  const unmatchedRefundFinanceItems: TabsProps["items"] = [
    {
      key: "pending",
      label: (
        <span>
          Pending Refund Finance <Tag>{refundFinance.data.total}</Tag>
        </span>
      ),
      children: (
        <RefundFinanceTable
          dataSource={refundFinance.data.data}
          status={REFUND_FINANCE_STATUS.PENDING}
          callback={(res: any) => {
            fetchAll(res);
          }}
          isUnmatched={isUnmatched}
        />
      ),
    },
    {
      key: "created",
      label: (
        <span>
          Created Refund Finance Batch{" "}
          <Tag>{refundFinanceBatch?.data?.total}</Tag>
        </span>
      ),
      children: (
        <RefundFinanceBatchTable
          dataSource={refundFinanceBatch.data.data}
          callback={(res: any) => {
            fetchAll(res);
          }}
        />
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey={activeTab ? activeTab : "referback"}
      items={
        type === "unmatched" ? unmatchedRefundFinanceItems : refundFinanceItems
      }
      onChange={(key) => {
        queryParams.set("tab", key);
        const newSearch = queryParams.toString();
        navigate({
          pathname: location.pathname,
          search: newSearch,
        });
      }}
      destroyInactiveTabPane
    />
  );
};

export default RefundFinanceListingTabs;
