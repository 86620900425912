import { InboxOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { Form, Input, InputNumber, Modal, Select, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { ReactNode, useEffect, useState } from "react";
import { getBankFromMaster } from "../../../../services/masterService/masterService";
import RefundFile from "../../../../components/RefundFileListModal";
import { REFUND_STATUS } from "../../../../enum/refund";

export type RefundFileListProps = {
  form: any;
  submitButtons?: ReactNode;
  viewMode?: boolean;
  loading: boolean;
  isModalOpen: boolean;
  setIsModalOpen: any;
  fileList?: any;
  setFileList?: any;
  viewFileID?: string;
  type?: string;
  callback?: any;
};
const RefundDetailsModal: React.FC<RefundFileListProps> = (props) => {
  const {
    form,
    submitButtons,
    viewMode,
    loading,
    isModalOpen,
    setIsModalOpen,
    fileList,
    setFileList,
    viewFileID,
    type,
    callback,
  } = props;
  const [bankList, setBankList] = useState<any>([]);
  const uploadProps: UploadProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      fileList.push(file);
      // setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getBankList = async () => {
    await getBankFromMaster().then((res: any) => {
      setBankList(res);
    });
  };

  useEffect(() => {
    getBankList();
  }, []);

  return (
    <>
      <Modal
        title={viewMode ? "View Refund Details" : "Update Refund Details"}
        okText="Confirm"
        open={isModalOpen}
        onCancel={handleCancel}
        confirmLoading={loading}
        destroyOnClose
        centered
        footer={submitButtons}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item label="Agreement Number" name="agreementNo">
            <Input
              placeholder="Please input"
              name="agreementNo"
              style={{ width: "100%" }}
              disabled
            />
          </Form.Item>

          <Form.Item label="Contact Phone No" name="contactPhoneNo">
            <Input
              placeholder="Please input"
              name="contactPhoneNo"
              style={{ width: "100%" }}
              disabled={viewMode}
            />
          </Form.Item>

          <Form.Item label="Bank" name="bank">
            <Select
              placeholder="Please select"
              style={{ width: "100%" }}
              options={
                bankList?.map((item: any) => ({
                  label: item.name,
                  value: item.name,
                  ibgRoutingNumber: item.ibgRoutingNumber,
                })) ?? []
              }
              onChange={(value) => {
                const bank = bankList.find((item: any) => item.name === value);
                callback({ ibgRoutingNumber: bank?.ibgRoutingNumber });
              }}
              disabled={viewMode}
            />
          </Form.Item>

          <Form.Item label="Bank Account No" name="bankAccountNo">
            <Input
              placeholder="Please input"
              name="bankAccountNo"
              style={{ width: "100%" }}
              disabled={viewMode}
            />
          </Form.Item>

          <Form.Item label="Payment Method" name="paymentMethod">
            <Select disabled={viewMode}>
              <Select.Option value="Bank">Bank</Select.Option>
            </Select>
          </Form.Item>

          {type === "unmatched" ? (
            <>
              <Form.Item label="Unmatched Amount" name="unmatchedAmount">
                <Input
                  placeholder="Please input"
                  style={{ width: "100%" }}
                  prefix="RM "
                  disabled
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item label="Extra Payment Amount" name="extraPaymentAmount">
                <Input
                  placeholder="Please input"
                  style={{ width: "100%" }}
                  prefix="RM "
                  disabled
                />
              </Form.Item>
            </>
          )}

          <Form.Item label="Refund Admin Charge" name="refundAdminCharge">
            <Input
              placeholder="Please input"
              style={{ width: "100%" }}
              prefix="RM "
              disabled
            />
          </Form.Item>

          <Form.Item label="Refund Amount" name="refundAmount">
            <Input
              placeholder="Please input"
              style={{ width: "100%" }}
              prefix="RM "
              disabled
            />
          </Form.Item>

          <Form.Item label="Supporting Documents" name="file">
            <Dragger {...uploadProps} disabled={viewMode}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>
          {viewFileID && (
            <>
              <RefundFile
                entityId={viewFileID}
                status={REFUND_STATUS.APPROVED}
              />
              <br />
              <br />
            </>
          )}

          <Form.Item label="Unsuccessful Reason" name="unsuccessfulReason">
            <TextArea
              placeholder="Please input"
              name="unsuccessfulReason"
              style={{ width: "100%" }}
              minLength={5}
              disabled={true}
            />
          </Form.Item>

          <Form.Item label="Remark" name="remark">
            <TextArea
              placeholder="Please input"
              name="remark"
              style={{ width: "100%" }}
              minLength={5}
              disabled={viewMode}
              onChange={(e) => {
                callback(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default RefundDetailsModal;
