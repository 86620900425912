import { Button, message, Popconfirm, Row, Space, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import {
  getAllActionCodeThunk,
  updateStatusActionCodeThunk,
} from "../../services/actionCodeService/actionCodeThunk";
import { AppDispatch, RootState } from "../../store/store";
import ActionCodeFormModal from "./ActionCodeFormModal";

import type { TablePaginationConfig } from "antd/es/table";
import type { ColumnsType } from "antd/es/table";

const ActionCodeManagementPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { actionCodesState } = useSelector(
    (state: RootState) => state.actionCode,
  );

  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("code");

  const tablePagination = {
    total: actionCodesState.data.length,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        paginationState.current > 1
          ? (paginationState.current - 1) * paginationState.pageSize + index + 1
          : index + 1,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action Type",
      dataIndex: "actionType",
      key: "actionType",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Popconfirm
          title="Do you want to update the status for this action code?"
          onConfirm={() => onChangeStatus(record.id, record.status)}
          okText="Yes"
          cancelText="No"
        >
          {record.status == "ACTIVE" ? (
            <Button
              type="text"
              loading={changeStatusLoading}
              style={{ color: "#62B649" }}
            >
              Active
            </Button>
          ) : (
            <Button type="text" loading={changeStatusLoading} danger>
              Inactive
            </Button>
          )}
        </Popconfirm>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space>
          <ActionCodeFormModal
            key={record?.id}
            actionCode={record}
            callback={() => fetchActionCodes(initialParams)}
          />
        </Space>
      ),
    },
  ];

  const fetchActionCodes = async (params?: any) => {
    await dispatch(getAllActionCodeThunk({ params }));
  };

  const onSearch = (value: string) => {
    fetchActionCodes({
      ...initialParams,
      filters: { ...filterState, [searchFiled]: value },
    });
    setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchActionCodes({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const onChangeStatus = async (actionCodeId: string, status: string) => {
    const changeStatus = status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

    setChangeStatusLoading(true);
    await dispatch(
      updateStatusActionCodeThunk({
        actionCodeId: actionCodeId,
        payload: { status: changeStatus },
      }),
    )
      .unwrap()
      .then(() => {
        message.success(changeStatus);
        fetchActionCodes(initialParams);
      })
      .finally(() => setChangeStatusLoading(false));
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchActionCodes(params);
  });

  return (
    <>
      <Helmet>
        <title>Action Code Config - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title="Action Code Config"
        extra={
          <ActionCodeFormModal
            callback={() => fetchActionCodes(initialParams)}
          />
        }
      />
      <Row justify="space-between">
        {/* <Col>
          <Input.Group compact>
            <Select
              defaultValue="code"
              style={{ width: 200 }}
              onChange={(value) => setSearchFiled(value)}
            >
              {Array.isArray(columns) &&
                columns
                  .filter((col: any) => col.key !== "action")
                  .map((col: any) => (
                    <Option key={col.key} value={col.dataIndex}>
                      {col.title?.toString()}
                    </Option>
                  ))}
            </Select>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </Input.Group>
        </Col> */}
      </Row>

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={actionCodesState?.data ?? []}
        loading={actionCodesState.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ActionCodeManagementPage;
