import "../index.less";

import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../store/store";
import { displayDateAndTime } from "../../../utils/datetime.util";

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useEffect, useMemo, useState } from "react";
import { getAccountSmsHistoryThunk } from "../../../services/collectionService/collectionThunk";

const SmsHistory = () => {
  // const { accountDetails } = useSelector(
  //   (state: RootState) => state.accountDetails,
  // );
  const dispatch = useDispatch<AppDispatch>();
  const { smsHistory, accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: smsHistory.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      key: "id",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Request Date",
      dataIndex: "requestDate",
      key: "requestDate",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Requestor",
      dataIndex: "requestor",
      key: "requestor",
      // render: (_, record) => record?.requestor?.firstName ?? "-",
    },
    {
      title: "Delivery Status",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
    },
    {
      title: "Sent Date & Time",
      dataIndex: "sendDate",
      key: "sendDate",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "SMS Message",
      dataIndex: "smsMessage",
      key: "smsMessage",
    },
  ];

  const fetchSmsHistory = async (params?: any) => {
    await dispatch(getAccountSmsHistoryThunk({ ...params }));
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchSmsHistory({
      ...initialParams,
      pagination,
      accountDetailsId: accountEnquiry.data?.accountDetails?.id,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    if (accountEnquiry.data?.accountDetails?.id) {
      fetchSmsHistory({
        ...initialParams,
        accountDetailsId: accountEnquiry.data?.accountDetails?.id,
      });
    }
  }, [initialParams, accountEnquiry.data?.accountDetails?.id]);

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <Table
            columns={columns}
            dataSource={smsHistory.data?.data ?? []}
            pagination={tablePagination}
            loading={smsHistory.isLoading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SmsHistory;
