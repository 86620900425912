import {
  Button,
  ConfigProvider,
  Form,
  Input,
  message,
  Select,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";

import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";
import { REFUND_FINANCE_STATUS, REFUND_STATUS } from "../../../../enum/refund";
import { RefundFinance } from "../../../../features/refund/refundSlice";
import { numberWithCommas } from "../../../../helpers/number";
import {
  createRefundFinanceBatchThunk,
  createUnmatchedRefundFinanceBatchThunk,
  getRefundFinanceListThunk,
  updateRefundFinanceStatusThunk,
} from "../../../../services/refundService/refundThunk";
import { updateUnmatchedRefundFinanceStatusThunk } from "../../../../services/unmatchedService/unmatchedThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";
import RefundDetailsModal from "../refundDetailsModal";

import type { ColumnsType } from "antd/es/table";

const { Search } = Input;

type RefundTableProps = {
  dataSource: any;
  status?: string;
  viewMode?: boolean;
  isUnmatched?: boolean;
  callback?: any;
};

const RefundFinanceTable: React.FC<RefundTableProps> = (props) => {
  const { dataSource, status, viewMode, isUnmatched, callback } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: { status: "PENDING" },
    }),
    [],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refundDetails, setRefundDetails] = useState<RefundFinance>();
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const [searchData, setSearchData] = useState(initialParams.search);

  const { refundFinance } = useSelector((state: RootState) => state.refund);
  const [loading, setLoading] = useState(false);
  const [refundFinanceArray, setRefundFinanceIdArray] = useState<any[]>([]);
  const [createBatchButton, setCreateBatchButton] = useState(false);
  const [createBatchLoading, setCreateBatchLoading] = useState(false);
  const [searchField, setSearchField] = useState("agreementNo");

  const tablePagination = {
    total: dataSource?.data?.searchTotal ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };
  const [form] = Form.useForm();

  const onSearch = (value: string) => {
    if (value === "") {
      fetchRefundFinanceListing();
      return;
    }

    const search = {
      ...initialParams.search,
      [searchField]: value,
    };

    const params = {
      ...initialParams,
      filter: filterState,
      search,
      type: type ?? "refund",
    };
    fetchRefundFinanceListing(params);
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    callback({
      ...initialParams,
      ...filterState,
      currentPage: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
      search: searchData,
      sortOrder: "desc",
      sortField: "createdAt",
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useLifecycles(() => {
    callback(initialParams);
  });

  const fetchRefundFinanceListing = async (params?: any) => {
    await dispatch(
      getRefundFinanceListThunk(
        params ?? {
          ...initialParams,
          type: type ?? "refund",
        },
      ),
    );
  };

  const updateRefundFinanceStatus = async (
    id: string,
    statusToUpdate: string | undefined,
    postDate?: any,
  ) => {
    setLoading(true);

    const requestData: any = {
      id: refundDetails !== undefined ? refundDetails.id : id,
      status: statusToUpdate,
    };
    if (statusToUpdate === REFUND_FINANCE_STATUS.REFUNDED) {
      requestData.postDate = postDate;
    }

    if (type === "unmatched") {
      await dispatch(updateUnmatchedRefundFinanceStatusThunk(requestData))
        .unwrap()
        .then((res: any) => {
          message.success(`Record updated to ${statusToUpdate} successfully`);
          fetchRefundFinanceListing({
            ...initialParams,
            filter: filterState,
            type: type ?? "refund",
          });
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
          setIsModalOpen(false);
        });
    } else {
      await dispatch(updateRefundFinanceStatusThunk(requestData))
        .unwrap()
        .then((res: any) => {
          message.success(`Record updated to ${statusToUpdate} successfully`);
          fetchRefundFinanceListing({
            ...initialParams,
            filter: filterState,
            type: type ?? "refund",
          });
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
          setIsModalOpen(false);
        });
    }

    if (statusToUpdate == REFUND_FINANCE_STATUS.REFERBACK) {
      queryParams.set("tab", "referback");
      const newSearch = queryParams.toString();
      navigate({
        pathname: "/refund/listing",
        search: newSearch,
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Approval Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: string) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No.",
      dataIndex: "agreementNo",
      key: "agreementNo",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "applicantNric",
      key: "applicantNric",
    },
    {
      title: "Refund Amount (RM)",
      dataIndex: "payAmount",
      key: "payAmount",
      render: (text, record) => numberWithCommas(text) ?? "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => text ?? "-",
    },
    // {
    //   title: "Action",
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <Space>
    //           <Button onClick={() => openModal(record)}>View</Button>
    //           <Button
    //             onClick={() => handleDownloadPaymentFile(record)}
    //             disabled={
    //               !(
    //                 record.status === REFUND_FINANCE_STATUS.INCOMPLETE ||
    //                 record.status === REFUND_FINANCE_STATUS.PROCESSING
    //               )
    //             }
    //           >
    //             Download
    //           </Button>
    //           <Button
    //             type="primary"
    //             onClick={() => handlePostDate(record)}
    //             disabled={!(record.status === REFUND_FINANCE_STATUS.INCOMPLETE)}
    //           >
    //             Post Date
    //           </Button>
    //         </Space>
    //       </>
    //     );
    //   },
    // },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
      );

      setRefundFinanceIdArray(selectedRowKeys as any[]);
      setCreateBatchButton(true);
    },
  };

  const createBatch = async () => {
    setCreateBatchLoading(true);

    if (type === "unmatched") {
      await dispatch(
        createUnmatchedRefundFinanceBatchThunk({
          refundFinance: refundFinanceArray,
          status: "IN_PROCESS",
        }),
      ).finally(() => {
        setCreateBatchLoading(false);
        setCreateBatchButton(false);
        callback();
      });
    } else {
      await dispatch(
        createRefundFinanceBatchThunk({
          refundFinance: refundFinanceArray,
          status: "IN_PROCESS",
        }),
      ).finally(() => {
        setCreateBatchLoading(false);
        setCreateBatchButton(false);
        callback();
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Refund Finance Listing - redCASH CEP</title>
      </Helmet>

      <ConfigProvider theme={{ token: { colorPrimary: "#00b96b" } }}>
        <Space className="mb-4">
          <Select
            defaultValue="agreementNo"
            style={{ width: 200 }}
            onChange={(value) => setSearchField(value)}
            options={[
              { value: "agreementNo", label: "Agreement No" },
              { value: "applicantNric", label: "Applicant NRIC" },
            ]}
          />
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Space>

        <Table
          rowKey="id"
          className="min-w-fit"
          columns={columns}
          pagination={tablePagination}
          dataSource={refundFinance?.data?.data ?? []}
          loading={refundFinance?.isLoading}
          onChange={handleTableChange}
          rowSelection={{
            ...rowSelection,
          }}
        />

        {refundFinanceArray.length > 0 && (
          <div
            className={`
          ${"w-[calc(100%-340px)]"}
          bg-white 
            shadow-multi h-16  px-4 
            fixed bottom-0 right-0 
            flex items-center
            justify-end`}
          >
            <h4 className="text-lg my-0 mr-4">
              {refundFinanceArray.length} Selected
            </h4>
            <ConfigProvider theme={{ token: { colorPrimary: "#e40308" } }}>
              <Button
                type="primary"
                onClick={() => createBatch()}
                disabled={refundFinanceArray.length < 1}
                loading={createBatchLoading}
              >
                Create Batch
              </Button>
            </ConfigProvider>
          </div>
        )}
      </ConfigProvider>

      <RefundDetailsModal
        form={form}
        viewMode={viewMode}
        loading={loading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        viewFileID={
          type === "unmatched"
            ? refundDetails?.unmatchedRefundId
            : refundDetails?.refund?.id
        }
        submitButtons={[
          <Space key={"space"}>
            {refundDetails?.status === REFUND_FINANCE_STATUS.PENDING && (
              <Button
                key="draft"
                onClick={() =>
                  updateRefundFinanceStatus(
                    refundDetails.id,
                    REFUND_FINANCE_STATUS.REFERBACK,
                  )
                }
                type="primary"
                loading={loading}
              >
                Referback
              </Button>
            )}

            {refundDetails?.status == REFUND_FINANCE_STATUS.PENDING && (
              <Button
                key="update"
                onClick={() =>
                  updateRefundFinanceStatus(
                    refundDetails.id,
                    REFUND_FINANCE_STATUS.PROCESSING,
                  )
                }
                loading={loading}
              >
                Proceed
              </Button>
            )}
          </Space>,
        ]}
        type={type ?? "refund"}
      />
    </>
  );
};

export default RefundFinanceTable;
