import { caseAssignmentAxiosInstance } from "../../../axios/caseAssignmentAxios";

const getAllRuleParameter = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/caseAssignment/api/v1/ruleParameter/list`;
  const res = await caseAssignmentAxiosInstance.get(url, { params });
  return res.data;
};

const createRuleParameter = async (requestData: any) => {
  const url = `/caseAssignment/api/v1/ruleParameter/create`;
  const res = await caseAssignmentAxiosInstance.post(url, requestData);
  return res.data;
};

const updateRuleParameter = async (requestData: any) => {
  const { ruleParameterId, payload } = requestData;
  const url = `/caseAssignment/api/v1/ruleParameter/id/${ruleParameterId}`;
  const res = await caseAssignmentAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteRuleParameter = async (requestData: any) => {
  const { ruleParameterId } = requestData;
  const url = `/caseAssignment/api/v1/ruleParameter/id/${ruleParameterId}`;
  const res = await caseAssignmentAxiosInstance.delete(url);
  return res.data;
};

const ruleParameterService = {
  getAllRuleParameter,
  createRuleParameter,
  updateRuleParameter,
  deleteRuleParameter,
};

export default ruleParameterService;
