import { createSlice } from "@reduxjs/toolkit";
import { getRequiredFileByPartnerTypeThunk } from "../../services/smeFileService/smeFileThunk";
import { getFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import groupBy from "lodash.groupby";
import { UploadedFile } from "../application/applicationSlice";

export type SmeFileConfig = {
  id: string
  code: string
  value: string
  neededByIndividual: boolean
  neededBySoleProp: boolean
  neededByPartnership: boolean
  neededByCompany: boolean
  createdAt: string
  updatedAt: string
}

const smeFiles: Record<string, UploadedFile[]> = {
  SSM: [],
  COMPANY_BANK_ACCOUNT: [],
  SME_LIVE_ATTESTATION_PROOF: [],
  BANK_STATEMENT: [],
  COMPANY_9_13_24_44_49_SECTION_14_15_51_58_78: [],
  FORM_2_3_8_11_SARAWAK: [],
  HOUSE_UTILITY_BILL: [],
  BUSINESS_INTELLIGENCE: [],
  COMPANY_INFORMATION: [],
  LIQUIDATED_REPORT: [],
  CDDI: [],
  CDDC: [],
  ECCRIS: [],
  NRIC: [],
  APPLICATIONFILES: []
};


export type SmeFileState = {
  requiredFileByPartnerType: {
    isLoading: boolean
    isError: boolean
    errorMessage: any
    data: SmeFileConfig[]
  }
  smeFilesByEntityId: {
    isLoading: boolean
    isError: boolean
    errorMessage: any
    data: typeof smeFiles
  }
}

const initialState: SmeFileState = {
  requiredFileByPartnerType: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  smeFilesByEntityId: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      APPLICATIONFORM: [] as UploadedFile[],
      SSM: [] as UploadedFile[],
      COMPANY_BANK_ACCOUNT: [] as UploadedFile[],
      SME_LIVE_ATTESTATION_PROOF: [] as UploadedFile[],
      BANK_STATEMENT: [] as UploadedFile[],
      COMPANY_9_13_24_44_49_SECTION_14_15_51_58_78: [] as UploadedFile[],
      FORM_2_3_8_11_LLP: [] as UploadedFile[],
      HOUSE_UTILITY_BILL: [] as UploadedFile[],
    },
  },
};

export const smeFileSlice = createSlice({
  name: "smeFile",
  initialState,
  reducers: {
    smeFileStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequiredFileByPartnerTypeThunk.pending, (state) => {
        state.requiredFileByPartnerType.isLoading = true;
      })
      .addCase(getRequiredFileByPartnerTypeThunk.fulfilled, (state, action) => {
        state.requiredFileByPartnerType.isLoading = false;
        state.requiredFileByPartnerType.data = action.payload;
      })
      .addCase(getRequiredFileByPartnerTypeThunk.rejected, (state, action) => {
        state.requiredFileByPartnerType.isLoading = false;
        state.requiredFileByPartnerType.isError = true;
        state.requiredFileByPartnerType.errorMessage = action.payload;
      });

    builder
      .addCase(getFilesByEntityIdThunk.pending, (state) => {
        state.smeFilesByEntityId.isLoading = true;
      })
      .addCase(getFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.smeFilesByEntityId.isLoading = false;
        state.smeFilesByEntityId.data = groupBy(
          action.payload,
          (file: UploadedFile) => file.fileType.name,
        );
      })
      .addCase(getFilesByEntityIdThunk.rejected, (state, action) => {
        state.smeFilesByEntityId.isLoading = false;
        state.smeFilesByEntityId.isError = true;
        state.smeFilesByEntityId.errorMessage = action.payload;
      });
  },
});

export const { smeFileStateReset } = smeFileSlice.actions;
export default smeFileSlice.reducer;