import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import {
  getSpecialTaggingList,
  clearPaymentSpecialTagging,
  requestRefundSpecialTagging,
} from "./specialTaggingService";

export const getUnclearSpecialTaggingListThunk = createAsyncThunk(
  "specialTagging/getUnclear",
  async (requestData: any, thunkAPI) => {
    try {
      return await getSpecialTaggingList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getClearSpecialTaggingListThunk = createAsyncThunk(
  "specialTagging/getClear",
  async (requestData: any, thunkAPI) => {
    try {
      return await getSpecialTaggingList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const clearPaymentSpecialTaggingThunk = createAsyncThunk(
  "specialTagging/clearPaymentSpecialTagging",
  async (requestData: any, thunkAPI) => {
    try {
      return await clearPaymentSpecialTagging(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const requestRefundSpecialTaggingThunk = createAsyncThunk(
  "specialTagging/requestRefundSpecialTagging",
  async (requestData: any, thunkAPI) => {
    try {
      return await requestRefundSpecialTagging(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
