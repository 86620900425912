import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

export const getListingNoticesGenerationConfig = async () => {
  const url = `/redCash/api/v1.0/noticesGenerationConfig/listing`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const getAgreementsByBatchId = async (requestData: any) => {
  const { batchId, params } = requestData;
  const url = `/redCash/api/v1.0/noticesGeneration/agreementList/batchID/${batchId}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

export const getDetailsByIdNoticesGenerationConfig = async (
  requestData: any,
) => {
  const { batchId } = requestData;
  const url = `/redCash/api/v1.0/noticesGeneration/agreementList/batchID/${batchId}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const getBatchList = async (requestData: any) => {
  const { letterNumber } = requestData;
  const url = `/redCash/api/v1.0/noticesGeneration/Batch/letterNumber/${letterNumber}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const createNoticesGenerationConfig = async (requestData: any) => {
  const { payload } = requestData;
  const url = `/redCash/api/v1.0/noticesGenerationConfig/create`;
  const res = await collectionSetupAxiosInstance.post(url, payload);
  return res.data;
};

export const updateNoticesGenerationConfig = async (requestData: any) => {
  const { noticesId, payload } = requestData;
  const url = `/redCash/api/v1.0/noticesGenerationConfig/update/noticesId/${noticesId}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

export const generateSingleNoticePDF = async (requestData: any) => {
  const { accountBatchID } = requestData;
  const url = `/redCash/api/v1.0/noticesGeneration/generateSingleNoticePDF/accountBatchID/${accountBatchID}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const generateAllNoticePDF = async (requestData: any) => {
  const { batchID } = requestData;
  const url = `/redCash/api/v1.0/noticesGeneration/generateAllNoticePDF/batchID/${batchID}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

export const generateCSVList = async (batchID: any) => {
  const url = `/redCash/api/v1.0/noticesGeneration/generateCSVList/batchID/${batchID}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const noticesGenerationConfigService = {
  getListingNoticesGenerationConfig,
  getAgreementsByBatchId,
  getBatchList,
  getDetailsByIdNoticesGenerationConfig,
  createNoticesGenerationConfig,
  updateNoticesGenerationConfig,
  generateSingleNoticePDF,
  generateAllNoticePDF,
  generateCSVList,
};

export default noticesGenerationConfigService;
