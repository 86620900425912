import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import InfoGrid from "../../../../../components/InfoGrid";
import { SME_ORGANIZATION_TYPE } from "../../../../../enum/smeApplication";
import { EditDisbursementDetailsModal } from "./EditDisbursementDetails";

export const DisbursementInfoTab = () => {
  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const data = smeApplicationDetails?.data?.smeIndividualApplication || smeApplicationDetails?.data?.smeSolePropApplication || smeApplicationDetails?.data?.smePartnershipApplication;

  const disbursementData = [
    {
      title: "Personal Account Name",
      value: data?.personalBankAccountName,
    },
    {
      title: "NRIC",
      value: data?.nric,
    },
    {
      title: "Bank Name",
      value: data?.personalBankName,
    },
    {
      title: "Personal Account Number",
      value: data?.personalBankAccountNo,
    },
    {
      title: "Contact No.",
      value: data?.applicantPhoneNo,
    },
  ];

  const companyData = smeApplicationDetails?.data?.smeCompanyApplication
  const companyDisbursementData = [
    {
      title: "Company Account Name",
      value: companyData?.bankAccountName,
    },
    {
      title: "Business Registration No.",
      value: companyData?.businessOldRegistrationNo || companyData?.businessNewRegistrationNo,
    },
    {
      title: "Company Bank Name",
      value: companyData?.bankName,
    },
    {
      title: "Company Account Number",
      value: companyData?.bankAccountNo,
    },
    {
      title: "Business Contact No.",
      value: companyData?.businessContactNo,
    },
  ];

  return (
    <div className="info-container">
      {smeApplicationDetails?.data?.organizationType !== SME_ORGANIZATION_TYPE.COMPANY && (
        <InfoGrid
          title="Personal Disbursement Details"
          data={disbursementData}
          extra={<EditDisbursementDetailsModal />}
        />
      )}
      {companyData && (
        <InfoGrid
          title="Company Disbursement Details"
          data={companyDisbursementData}
          extra={<EditDisbursementDetailsModal />}
        />
      )}
    </div>
  );
};
