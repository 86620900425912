import { AppDispatch, RootState } from "../../store/store";
import React, { useMemo } from "react";
import { Tabs, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { PageHeader } from "@ant-design/pro-components";
import ReportTable from "./ReportTable";
import { ReportTypeEnum } from "../../enum/report";
import { ReportTypeLabel } from "../../constant/report";
import type { TabsProps } from "antd";
import { getReportListingThunk } from "../../services/reportService/reportThunk";
import { useLifecycles } from "react-use";

const ReportListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { reportListing } = useSelector((state: RootState) => state.report);

  const initialParams = useMemo(
    () => ({
      reportType: activeTab ?? ReportTypeEnum.MASTER_LISTING,
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "reportDate",
      search: {},
      filters: {},
    }),
    [],
  );

  const onFetchReportListing = (params: any) => {
    dispatch(getReportListingThunk({ params }));
  };

  const items: TabsProps["items"] = Object.entries(ReportTypeLabel).map(
    ([key, value]) => ({
      key: key,
      label: value,
      children: (
        <ReportTable
          dataSource={reportListing}
          callback={onFetchReportListing}
          initialParams={initialParams}
          type={key}
        />
      ),
    }),
  );

  const onTabChange = (key: string) => {
    onFetchReportListing({ ...initialParams, reportType: key });

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };
    onFetchReportListing(params);
  });

  return (
    <>
      <Helmet>
        <title>{`Report - redCASH CEP`}</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Report" />
      <Tabs
        defaultActiveKey={activeTab ?? ReportTypeEnum.MASTER_LISTING}
        items={items}
        onChange={onTabChange}
      />
    </>
  );
};

export default ReportListingPage;
