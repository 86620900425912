import "./index.less";

import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLifecycles } from "react-use";

import { collectionStateReset } from "../../features/collection/collectionSlice";
import { AppDispatch } from "../../store/store";
import AccountEnquiryContent from "./AccountEnquiryContent";

const AccountEnquiryPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchAgreementNo = queryParams.get("searchAgreementNo")?.trim();
  const dispatch = useDispatch<AppDispatch>();

  useLifecycles(() => {
    dispatch(collectionStateReset());
  });

  return (
    <>
      <Helmet>
        <title>Account Enquiry - redCASH CEP</title>
      </Helmet>
      <AccountEnquiryContent searchAgreementNo={searchAgreementNo} />
    </>
  );
};

export default AccountEnquiryPage;
