import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getSmeAgreementListing = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smeAgreement`;
  const res = await beSetupAxiosInstance.get(url, requestData);
  return res.data;
};

const getPendingAttestationSmeAgreementListing = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smeAgreement/pending-attestation`;
  const res = await beSetupAxiosInstance.get(url, requestData);
  return res.data;
};

const getRequestedLiveAttestationSmeAgreementListing = async (
  requestData: any,
) => {
  const url = `/redCash/api/v1.0/smeAgreement/requested-live-attestation`;
  const res = await beSetupAxiosInstance.get(url, requestData);
  return res.data;
};

const getCompletedLiveAttestationSmeAgreementListing = async (
  requestData: any,
) => {
  const params = {
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    search: requestData.search,
  };

  const url = `/redCash/api/v1.0/smeAgreement/completed-live-attestation`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getCompletedVideoAttestationSmeAgreementListing = async (
  requestData: any,
) => {
  const url = `/redCash/api/v1.0/smeAgreement/completed-video-attestation`;
  const res = await beSetupAxiosInstance.get(url, requestData);
  return res.data;
};

const getSmeAgreementDetails = async (requestData: any) => {
  const { agreementId } = requestData;
  const url = `/redCash/api/v1.0/smeAgreement/${agreementId}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getSmeEStampListing = async (requestData: any) => {
  const { status } = requestData;
  const params = {
    currentPage: requestData.query.pagination.current,
    pageSize: requestData.query.pagination.pageSize,
    sortField: requestData.query.sortField,
    sortOrder: requestData.query.sortOrder,
    search: requestData.query.search,
  };

  const url = `/redCash/api/v1.0/smeEStamp/status/${status}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const approveSmeEStamp = async (requestData: any) => {
  const { agreementId } = requestData;
  const url = `/redCash/api/v1.0/smeAgreement/${agreementId}/approve-e-stamping`;
  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const getSmeAgreementSigningList = async (requestData: any) => {
  const { status } = requestData;
  const params = {
    currentPage: requestData.query.pagination.current,
    pageSize: requestData.query.pagination.pageSize,
    sortField: requestData.query.sortField,
    sortOrder: requestData.query.sortOrder,
    search: requestData.query.search,
  };

  const url = `/redCash/api/v1.0/smeAgreement/signingListing/status/${status}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const smeAgreementService = {
  getSmeAgreementListing,
  getPendingAttestationSmeAgreementListing,
  getRequestedLiveAttestationSmeAgreementListing,
  getCompletedLiveAttestationSmeAgreementListing,
  getCompletedVideoAttestationSmeAgreementListing,
  getSmeAgreementDetails,
  getSmeEStampListing,
  approveSmeEStamp,
  getSmeAgreementSigningList,
};

export default smeAgreementService;
