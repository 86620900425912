import { Tabs, Tag } from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import {
  getCloseFieldVisitsThunk,
  getPendingFieldVisitsThunk,
  getOpenFieldVisitsThunk,
  getVisitedFieldVisitsThunk,
} from "../../../services/fieldVisitService/fieldVisitThunk";
import { AppDispatch, RootState } from "../../../store/store";
import OpenTab from "./OpenTab";
import VisitedTab from "./VisitedTab";

import type { TabsProps } from "antd";
import { FIELD_VISIT_STATUS } from "../../../enum/fieldVisitStatus";
import DraftTab from "./DraftTab";
import CloseTab from "./CloseTab";

const FieldVisitListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const {
    openFieldVisitListingState,
    pendingFieldVisitListingState,
    closeFieldVisitListingState,
    visitedFieldVisitListingState,
  } = useSelector((state: RootState) => state.fieldVisit);

  const items: TabsProps["items"] = [
    {
      key: "open",
      label: (
        <span>
          Open{" "}
          {openFieldVisitListingState?.totalCount != null && (
            <Tag>{openFieldVisitListingState.totalCount}</Tag>
          )}
        </span>
      ),
      children: <OpenTab />,
    },
    {
      key: "pending",
      label: (
        <span>
          Pending{" "}
          {pendingFieldVisitListingState?.totalCount != null && (
            <Tag>{pendingFieldVisitListingState.totalCount}</Tag>
          )}
        </span>
      ),
      children: <DraftTab />,
    },
    {
      key: "close",
      label: (
        <span>
          Cancel{" "}
          {closeFieldVisitListingState?.totalCount != null && (
            <Tag>{closeFieldVisitListingState.totalCount}</Tag>
          )}
        </span>
      ),
      children: <CloseTab />,
    },
    {
      key: "visited",
      label: (
        <span>
          Visited{" "}
          {visitedFieldVisitListingState?.totalCount != null && (
            <Tag>{visitedFieldVisitListingState.totalCount}</Tag>
          )}
        </span>
      ),
      children: <VisitedTab />,
    },
  ];

  const fetchOpenFieldVisit = async (params?: any) => {
    const parameters = {
      params: { ...params, filters: { status: FIELD_VISIT_STATUS.OPEN } },
    };
    await dispatch(getOpenFieldVisitsThunk(parameters));
  };

  const fetchVisitedFieldVisit = async (params?: any) => {
    const parameters = {
      params: { ...params, filters: { status: FIELD_VISIT_STATUS.VISITED } },
    };

    await dispatch(getVisitedFieldVisitsThunk(parameters));
  };

  const fetchPendingFieldVisit = async (params?: any) => {
    const parameters = {
      params: { ...params, filters: { status: FIELD_VISIT_STATUS.PENDING } },
    };

    await dispatch(getPendingFieldVisitsThunk(parameters));
  };

  const fetchCloseFieldVisit = async (params?: any) => {
    const parameters = {
      params: { ...params, filters: { status: FIELD_VISIT_STATUS.CLOSED } },
    };

    await dispatch(getCloseFieldVisitsThunk(parameters));
  };

  const onTabChange = (key: string) => {
    if (key === "open") {
      fetchOpenFieldVisit(initialParams);
    } else if (key === "visited") {
      fetchVisitedFieldVisit(initialParams);
    } else if (key === "pending") {
      fetchPendingFieldVisit(initialParams);
    } else if (key === "close") {
      fetchCloseFieldVisit(initialParams);
    }
    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchOpenFieldVisit(params);
    fetchVisitedFieldVisit(params);
    fetchPendingFieldVisit(params);
    fetchCloseFieldVisit(params);
  });

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Field Visit Listing" />
      <Tabs
        defaultActiveKey={activeTab ?? "open"}
        items={items}
        onChange={(key) => onTabChange(key)}
        destroyInactiveTabPane
      />
    </>
  );
};

export default FieldVisitListingPage;
