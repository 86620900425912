import "./style.less";

import { message } from "antd";
import jwt_decode from "jwt-decode";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IdcardOutlined, LockOutlined } from "@ant-design/icons";
import ProForm, { ProFormText } from "@ant-design/pro-form";

import { primaryColor } from "../../../assets/style/variables";
import { authSetupAxiosInstance } from "../../../axios/authSetupAxios";
import { Role } from "../../../enum/roles";
import {
  setCurrentUser,
  setHasLoggedUser,
} from "../../../features/user/userSlice";
import { loginThunk } from "../../../services/userService/userThunk";
import { AppDispatch } from "../../../store/store";

const LoginPage: React.FC = () => {
  const [form] = ProForm.useForm();
  const [loading, setLoading] = useState<boolean>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const recaptchaRef = useRef<any>(null);

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue === "") {
      message.error("Please check I'm not a robot");
      setLoading(false);
      return;
    }

    authSetupAxiosInstance
      .post("/redCash/auth/api/v1.0/recapthaV2/verify-token", {
        token: recaptchaValue,
      })
      .then(async (res) => {
        if (res.data.success) {
          await dispatch(
            loginThunk({
              username: values.username,
              password: values.password,
            }),
          )
            .unwrap()
            .then((res) => {
              const decode: any = res?.accessToken
                ? jwt_decode(res.accessToken)
                : null;

              if (decode == null || decode.role == null) {
                message.error("Error: Role access not found");
                return navigate("/logout");
              }

              if (decode.id != null) {
                // dispatch(getCurrentUserThunk({ userId: decode.id }));
                dispatch(
                  setCurrentUser({ id: decode.id, username: values.username }),
                );
              }

              localStorage.setItem("accessToken", res.accessToken);
              localStorage.setItem("refreshToken", res.refreshToken);
              localStorage.setItem("username", values.username);
              dispatch(setHasLoggedUser(true));

              // redirect to default page after login based on role
              if (decode.role === Role.APPROVER) {
                navigate(`/application-listing/approval-review`);
                return;
              }

              if (decode.role === Role.FINANCE) {
                navigate(`/disbursement-listing`);
                return;
              }

              if (decode.role === Role.CUSTOMER_SERVICE) {
                navigate(`/application-listing/customer-service-search`);
                return;
              }

              if (decode.role === Role.APPROVER_2) {
                navigate(`/sme-application-listing/second-approval-review`);
                return;
              }

              navigate(`/dashboard`);
              return;
            })
            .catch((err) => {
              message.error("Invalid username or password");
              recaptchaRef.current.reset();
            })
            .finally(() => {
              Promise.resolve();
              setLoading(false);
            });
        } else {
          setLoading(false);
          recaptchaRef.current.reset();
          message.error("Please verify that you are not robot");
        }
      })
      .catch(() => {
        message.error("Please try again");
        recaptchaRef.current.reset();
        setLoading(false);
      });
  };

  const byPassLogin = async (values: any) => {
    await dispatch(
      loginThunk({
        username: values.username,
        password: values.password,
      }),
    )
      .unwrap()
      .then((res) => {
        const decode: any = res?.accessToken
          ? jwt_decode(res.accessToken)
          : null;

        if (decode == null || decode.role == null) {
          message.error("Error: Role access not found");
          return navigate("/logout");
        }

        if (decode.id != null) {
          // dispatch(getCurrentUserThunk({ userId: decode.id }));
          dispatch(
            setCurrentUser({ id: decode.id, username: values.username }),
          );
        }

        localStorage.setItem("accessToken", res.accessToken);
        localStorage.setItem("refreshToken", res.refreshToken);
        localStorage.setItem("username", values.username);
        dispatch(setHasLoggedUser(true));

        // redirect to default page after login based on role
        if (decode.role === Role.APPROVER) {
          navigate(`/application-listing/approval-review`);
          return;
        }

        if (decode.role === Role.FINANCE) {
          navigate(`/disbursement-listing`);
          return;
        }

        if (decode.role === Role.CUSTOMER_SERVICE) {
          navigate(`/application-listing/customer-service-search`);
          return;
        }

        if (decode.role === Role.APPROVER_2) {
          navigate(`/sme-application-listing/second-approval-review`);
          return;
        }

        navigate(`/dashboard`);
        return;
      })
      .catch((err) => {
        message.error("Invalid username or password");
        recaptchaRef.current.reset();
      })
      .finally(() => {
        Promise.resolve();
        setLoading(false);
      });
  };

  const handleKeyDown = async (event: any) => {
    if (event.key === "Enter") {
      await form.validateFields().then(async (values) => {
        handleSubmit(values);
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Login - redCASH CEP</title>
      </Helmet>

      <div className="w-screen h-full">
        <div className="max-w-sm mx-auto my-40">
          <h1 className="text-2xl font-poppins">
            <span className="font-thin">red</span>
            <span className="font-bold" style={{ color: primaryColor }}>
              CASH
            </span>
            <span className="ml-2 font-bold">CEP</span>
          </h1>

          <ProForm
            form={form}
            submitter={{
              render: (_, dom) => dom.pop(),
              searchConfig: {
                submitText: "Login",
              },
              submitButtonProps: {
                loading: loading,
                size: "large",
                style: {
                  width: "100%",
                },
              },
            }}
            onFinish={
              process.env.REACT_APP_BY_PASS_LOGIN === "no"
                ? handleSubmit
                : byPassLogin
            }
          >
            <ProFormText
              name="username"
              fieldProps={{
                size: "large",
                prefix: <IdcardOutlined className="prefixIcon" />,
              }}
              placeholder="Username"
              rules={[
                {
                  required: true,
                  message: "Please input your username.",
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: "large",
                prefix: <LockOutlined className="prefixIcon" />,
                onKeyDown: handleKeyDown,
              }}
              placeholder="Password"
              rules={[
                {
                  required: true,
                  message: "Please input user password.",
                },
              ]}
            />
          </ProForm>

          {process.env.REACT_APP_BY_PASS_LOGIN === "no" && (
            <>
              <div className="flex justify-center mt-4">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTHA_SITE_KEY!}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginPage;
