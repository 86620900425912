import { Button, Form, Input, message, Modal, Upload } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { UploadOutlined } from "@ant-design/icons";

import type { RcFile } from "antd/es/upload/interface";
import { AppDispatch } from "../../../../../store/store";
import { uploadeKYCFileThunk } from "../../../../../services/ekycService/ekycThunk";
import { nricRegexPattern } from "../../../../../utils/validation";

const UploadFileModal: React.FC<{
  data: any;
}> = ({ data }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [frontImage, setFrontImage] = useState<string | Blob>();
  const [backImage, setBackImage] = useState<string | Blob>();

  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFrontImage(undefined);
    setBackImage(undefined);
    form.resetFields();
  };

  const onUploadSupportingDoc = (
    onSuccess:
      | ((body: any, xhr?: XMLHttpRequest | undefined) => void)
      | undefined,
    file: string | Blob,
    type: string,
  ) => {
    if (type == "front") {
      setFrontImage(file);
    } else if (type == "back") {
      setBackImage(file);
    }
    onSuccess?.("ok");
  };

  const onFinish = async () => {
    await form.validateFields().then((values) => {
      if (!(frontImage || backImage)) {
        return message.error("Please upload both front and back IC image.");
      }

      setLoading(true);
      const formData = new FormData();
      formData.append("userId", data?.userId);
      formData.append("applicationId", data?.id);

      formData.append("fullname", values?.fullName);
      formData.append("icNumber", values?.applicantNric);

      formData.append("front", frontImage as RcFile);
      formData.append("back", backImage as RcFile);
      dispatch(uploadeKYCFileThunk(formData))
        .unwrap()
        .then((res) => {
          message.success("Upload Successfully");
          handleCancel();
        })
        .catch(() => {
          message.error("Something went wrong. Please try again later.");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <>
      <Button onClick={showModal}>Upload Image</Button>
      <Modal
        title="Upload IC Image"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading || uploading}
      >
        <Form
          form={form}
          name="uploadeKYCFile"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[{ required: true, message: "Please input full name" }]}
            initialValue={data?.applicantFirstName}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="NRIC"
            name="applicantNric"
            rules={[
              { required: true, message: "Please input ic number" },
              {
                pattern: nricRegexPattern,
                message: "Please input valid ic number, eg. 000000-00-0000",
              },
            ]}
            initialValue={data?.applicantNric}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Front">
            <Upload
              maxCount={1}
              accept={"image/*"}
              customRequest={({ onSuccess, file }) => {
                onUploadSupportingDoc(onSuccess, file, "front");
              }}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Back">
            <Upload
              maxCount={1}
              accept={"image/*"}
              customRequest={({ onSuccess, file }) => {
                onUploadSupportingDoc(onSuccess, file, "back");
              }}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UploadFileModal;
