import { Input, Radio, RadioChangeEvent, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { PageHeader } from "@ant-design/pro-components";
import {
  getRefundFinanceBatchThunk,
  getRefundFinanceListThunk,
} from "../../../services/refundService/refundThunk";
import { AppDispatch } from "../../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import RefundFinanceListingTabs from "../components/refund-finance-listing-tabs";

const { Search } = Input;

const RefundFinanceListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = useForm();

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [unmatched, setUnmatched] = useState(type === "unmatched");
  const navigate = useNavigate();

  const viewMode = true;

  const fetchRefundFinanceListing = async (params?: any) => {
    if (type === "unmatched") {
      await dispatch(
        getRefundFinanceListThunk(
          params ?? {
            ...initialParams,
            type: type ?? "refund",
            filters: {
              status: "PENDING",
              unmatchedRefundFinanceBatchId: "null",
            },
          },
        ),
      );
    } else {
      await dispatch(
        getRefundFinanceListThunk(
          params ?? {
            ...initialParams,
            type: type ?? "refund",
            filters: { status: "PENDING", refundFinanceBatchId: "null" },
          },
        ),
      );
    }
  };

  useEffect(() => {
    fetchRefundFinanceListing();
    fetchRefundFinanceBatch();
  }, [type]);

  const fetchUnmatchedRefundFinanceList = async (params?: any) => {
    let paramData: any = {};
    if (type === "unmatched") {
      paramData = {
        ...initialParams,
        type: type ?? "refund",
        filters: {
          status: "PENDING",
          unmatchedRefundFinanceBatchId: "null",
        },
        sortField: "createdAt",
        sortOrder: "desc",
      };
    }

    await dispatch(
      getRefundFinanceListThunk(
        params ?? {
          ...initialParams,
          type: type ?? "refund",
        },
      ),
    );
  };

  const fetchRefundFinanceBatch = async (params?: any) => {
    await dispatch(
      getRefundFinanceBatchThunk(
        params ?? {
          ...initialParams,
          type: type ?? "refund",
        },
      ),
    );
  };

  const fetchPendingCreateBatchRefundFinanceList = async (params?: any) => {
    await dispatch(
      getRefundFinanceListThunk(
        params ?? {
          ...initialParams,
          type: type ?? "refund",
          filters: {
            refundFinanceBatchId: "null",
          },
        },
      ),
    );
  };

  const fetchAll = async (params?: any) => {
    if (type === "unmatched") {
      await fetchUnmatchedRefundFinanceList({
        ...params,
        filters: { ...params.filters, status: "PENDING" },
        type: type ?? "refund",
        unmatchedRefundFianceBatchId: "null",
      });

      await fetchRefundFinanceBatch({
        ...params,
        filters: { ...params.filters },
        type: type ?? "refund",
      });
    } else {
      await fetchPendingCreateBatchRefundFinanceList({
        ...params,
        filters: {
          ...params.filters,
          status: "PENDING",
          refundFinanceBatchId: "null",
        },
        type: type ?? "refund",
      });

      await fetchRefundFinanceBatch({
        ...params,
        filters: { ...params.filters },
        type: type ?? "refund",
      });
    }
  };

  const onListTypeChange = (e: RadioChangeEvent) => {
    setUnmatched(e.target.value === "unmatched");

    queryParams.set("type", e.target.value);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });

    fetchAll();
  };

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Refund Finance Listing" />
      <Space className="mb-4">
        <Radio.Group
          onChange={onListTypeChange}
          defaultValue={type ?? "refund"}
        >
          <Radio.Button value="refund">Refund</Radio.Button>
          <Radio.Button value="unmatched">Unmatched</Radio.Button>
        </Radio.Group>
      </Space>
      <RefundFinanceListingTabs />
    </>
  );
};

export default RefundFinanceListing;
