import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  message,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import startCase from "lodash.startcase";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { REFUND_FINANCE_BATCH_STATUS } from "../../../../enum/refund";
import { RefundFinance } from "../../../../features/refund/refundSlice";
import { numberWithCommas } from "../../../../helpers/number";
import {
  downloadRefundPaymentFileThunk,
  getRefundFinanceBatchByIdThunk,
  getRefundFinanceBatchThunk,
  getRefundFinanceSummaryThunk,
  updateRefundFinanceBatchIsDownloadedThunk,
  updateUnmatchedRefundFinanceBatchThunk,
} from "../../../../services/refundService/refundThunk";
import { downloadUnmatchedRefundPaymentFileThunk } from "../../../../services/unmatchedService/unmatchedThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";
import { downloadLink } from "../../../../helpers/downloadLink";
import { saveBlobAsPDF } from "../../../../utils/file";
import {
  dangerIconRed,
  successTextGreen,
} from "../../../../assets/style/variables";

const { Search } = Input;

type RefundTableProps = {
  dataSource: any;
  status?: string;
  viewMode?: boolean;
  isUnmatched?: boolean;
  callback?: any;
};

const RefundFinanceBatchTable: React.FC<RefundTableProps> = (props) => {
  const { dataSource, status, viewMode, isUnmatched, callback } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [refundDetails, setRefundDetails] = useState<RefundFinance>();
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const [searchData, setSearchData] = useState(initialParams.search);
  const [searchValue, setSearchValue] = useState("");
  const { refundFinanceBatchDetails, refundFinanceBatch } = useSelector(
    (state: RootState) => state.refund,
  );
  const [filterDate, setFilterDate] = useState<string>("");
  const [downloading, setDownloading] = useState(false);

  const tablePagination = {
    total: dataSource?.data?.searchTotal ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };
  const [form] = Form.useForm();

  const fetchRefundFinanceBatch = async (params?: any) => {
    await dispatch(
      getRefundFinanceBatchThunk(
        params ?? {
          ...initialParams,
          type: type ?? "refund",
        },
      ),
    );
  };

  const handleDownloadPaymentFile = async (data: any) => {
    setDownloading(true);
    setRefundDetails(data);

    message.loading("Downloading", 1);

    if (type === "unmatched") {
      // Download payment file
      await dispatch(
        downloadUnmatchedRefundPaymentFileThunk({
          id: data.id,
        }),
      )
        .unwrap()
        .then(downloadLink);

      // Download summary file
      await dispatch(
        getRefundFinanceSummaryThunk({
          id: data.id,
          type: type ?? "refund",
        }),
      )
        .unwrap()
        .then(async (response) => {
          const filename = `REFUND_FINANCE_SUMMARY_${data.agreementNo}.pdf`;
          saveBlobAsPDF(response, filename);
          await dispatch(
            updateUnmatchedRefundFinanceBatchThunk({
              id: data.id,
              status: REFUND_FINANCE_BATCH_STATUS.INCOMPLETE,
            }),
          );
        })
        .finally(async () => {
          setDownloading(false);
          await fetchRefundFinanceBatch();
        });

      await dispatch(
        getRefundFinanceBatchByIdThunk({
          id: data.id,
          type: type ?? "refund",
        }),
      ).then((res) => {
        console.log(res);
        message.success("Updated");
      });
    } else {
      // Download payment file
      await dispatch(
        downloadRefundPaymentFileThunk({
          id: data.id,
        }),
      )
        .unwrap()
        .then(downloadLink);

      // Download summary file
      await dispatch(
        getRefundFinanceSummaryThunk({
          id: data.id,
        }),
      )
        .unwrap()
        .then(async (response) => {
          const filename = `REFUND_FINANCE_SUMMARY_${data.batchNo}.pdf`;
          saveBlobAsPDF(response, filename);
          await dispatch(
            updateRefundFinanceBatchIsDownloadedThunk({
              id: refundFinanceBatchDetails.data.id,
            }),
          );
        })
        .finally(async () => {
          setDownloading(false);
          await fetchRefundFinanceBatch();
        });

      await dispatch(
        getRefundFinanceBatchByIdThunk({
          id: data.id,
          type: type ?? "refund",
        }),
      ).then((res) => {
        console.log(res);
        message.success("Updated");
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => displayDate(text),
    },
    {
      title: "Completed Date",
      dataIndex: "completedDateTime",
      key: "completedDateTime",
      render: (text, record) =>
        record.status === "COMPLETED" ? displayDate(record.updatedAt) : "-",
    },
    {
      title: "Total Refund Amount",
      dataIndex: "totalRefundAmount",
      key: "totalRefundAmount",
      render: (text, record) => numberWithCommas(text),
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
      key: "batchNo",
      render: (text, record) => (
        <Button
          type="link"
          disabled={downloading}
          onClick={async () => {
            await dispatch(
              getRefundFinanceBatchByIdThunk({
                id: record.id,
                type: type ?? "refund",
              }),
            );
            navigate({
              pathname: `/refund-finance-batch/${record.id}`,
              search: `?type=${type}`,
            });
          }}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          style={{
            color:
              text === "IN_COMPLETE"
                ? dangerIconRed
                : text === "COMPLETED"
                ? successTextGreen
                : "black",
          }}
        >
          {startCase(text === "IN_COMPLETE" ? "INCOMPLETE" : text ?? "???")}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            <>
              <Button
                onClick={() => {
                  handleDownloadPaymentFile(record);
                }}
                loading={downloading}
              >
                Download
              </Button>
            </>
          </>
        );
      },
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    callback({
      ...initialParams,
      ...filterState,
      currentPage: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
      search: searchData,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const onSearch = async (value: string) => {
    if (value.length > 0) {
      setSearchValue(value);
      const params = filterDate
        ? {
            ...initialParams,
            search: {
              batchNo: value,
            },
            filters: {
              createdAt: filterDate ? filterDate : "",
            },
          }
        : {
            ...initialParams,
            search: {
              batchNo: value,
            },
          };

      fetchRefundFinanceBatch(params);
    }
  };

  const onFilterDateChange: DatePickerProps["onChange"] = (
    date,
    dateString,
  ) => {
    setFilterDate(dateString);

    const params =
      searchValue.length > 0
        ? {
            ...initialParams,
            search: {
              batchNo: searchValue,
            },
            filters: {
              refundFinanceBatchCreatedAt: dateString,
            },
          }
        : {
            ...initialParams,
            filters: {
              refundFinanceBatchCreatedAt: dateString,
            },
          };

    fetchRefundFinanceBatch(params);
  };

  return (
    <>
      <Helmet>
        <title>Refund Finance Batch Listing - redCASH CEP</title>
      </Helmet>

      <Space className="mb-4">
        <Search
          placeholder="Batch No."
          onSearch={onSearch}
          style={{ width: 200 }}
        />
        <Space className="ml-4">
          <span>Filter By Created Date:</span>
          <DatePicker onChange={onFilterDateChange} />
        </Space>
      </Space>

      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={refundFinanceBatch.data.data ?? []}
        loading={refundFinanceBatch?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default RefundFinanceBatchTable;
