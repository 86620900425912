import "../index.less";

import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useEffect, useMemo, useState } from "react";

import { numberWithCommas } from "../../../helpers/number";
import { displayDateAndTime } from "../../../utils/datetime.util";

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { getPaymentHistoryThunk } from "../../../services/paymentService/paymentThunk";
import { getCollectionHistoryLogThunk } from "../../../services/collectionService/collectionThunk";

const CollectionHistory = () => {
  // const { accountDetails } = useSelector(
  //   (state: RootState) => state.accountDetails,
  // );
  const dispatch = useDispatch<AppDispatch>();
  const { accountEnquiry, historyLog } = useSelector(
    (state: RootState) => state.collection,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: historyLog.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Old Value",
      dataIndex: "oldValueString",
      key: "oldValueString",
    },
    {
      title: "New Value",
      dataIndex: "newValueString",
      key: "newValueString",
    },
    {
      title:"Created By",
      dataIndex:"createdBy",
      key:"createdBy"
    }
  ];

  const fetchCollectionHistory = async (params?: any) => {
    await dispatch(
      getCollectionHistoryLogThunk({
        ...params,
        accountDetailsId: accountEnquiry.data.accountDetails?.id,
      }),
    );
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchCollectionHistory({
      ...initialParams,
      ...filterState,
      pagination,
      agreementId: accountEnquiry.data?.accountDetails?.agreementId,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    if (accountEnquiry.data?.accountDetails?.agreementId) {
      fetchCollectionHistory({
        ...initialParams,
        agreementId: accountEnquiry.data?.accountDetails?.agreementId,
      });
    }
  }, [initialParams, accountEnquiry.data?.accountDetails?.agreementId]);

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <Table
            columns={columns}
            dataSource={historyLog.data?.data}
            pagination={tablePagination}
            loading={historyLog.isLoading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CollectionHistory;
