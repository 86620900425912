import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { refreshAccessToken } from "../utils/auth.util";
import { message } from "antd";
import { restructureErrorMessage } from "../helpers/errorMessage";
import { generateFileName } from "../helpers/addFileNameHeader";

export const caseAssignmentAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CASE_ASSIGNMENT_URL,
});

caseAssignmentAxiosInstance.interceptors.request.use(function (
  config: AxiosRequestConfig,
) {
  const token = localStorage.getItem("accessToken") ?? "";
  if (config.headers)
    if (token) config.headers["Authorization"] = `Bearer ${token}`;

  return config;
});

caseAssignmentAxiosInstance.interceptors.response.use(
  function (res: AxiosResponse) {
    // process need to perform before return response
    generateFileName(res);
    return res;
  },
  async function (error: any) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshAccessToken();
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + (localStorage.getItem("accessToken") ?? "");
      return caseAssignmentAxiosInstance(originalRequest);
    } else {
      const errorMessage =
        error?.response?.data?.message ??
        error?.response?.data?.errMessage ??
        error?.message ??
        "Internal Server Error";
      message.error(restructureErrorMessage(errorMessage));
      // if (
      //   restructureErrorMessage(error) !== "Request failed with status code 400"
      // ) {
      //   message.error(restructureErrorMessage(error));
      // }
    }
    return Promise.reject(error);
  },
);
