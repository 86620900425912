import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import dunningService from "./dunningService";

export const saveDunningInfoThunk = createAsyncThunk(
  "dunning/saveDunningInfo",
  async (requestData: any, thunkAPI) => {
    try {
      return await dunningService.saveDunningInfo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateDunningInfoThunk = createAsyncThunk(
  "dunning/updateDunningInfo",
  async (requestData: any, thunkAPI) => {
    try {
      return await dunningService.updateDunningInfo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDunningHistoryListingThunk = createAsyncThunk(
  "dunning/getDunningHistoryListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await dunningService.getAllDunningHistory(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDunningContactModeListingThunk = createAsyncThunk(
  "dunning/getDunningContactModeListing",
  async (requestData, thunkAPI) => {
    try {
      return await dunningService.getAllDunningContactMode();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
