import { Button, Input, message, Popconfirm, Select, Space, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import FileListModal from "../../../../components/FileListModal";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate, displayTime } from "../../../../utils/datetime.util";
import UpdateLiveAttestationStatusModal from "./UpdateLiveAttestationStatusModal";

import type { Attestation } from "../../../../features/attestation/attestationSlice";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { cancelApplicationThunk } from "../../../../services/applicationService/applicationThunk";
import { SmeAgreement } from "../../../../features/smeAgreement/smeAgreementSlice";
import {
  getRequestedLiveAttestationSmeAgreementListingThunk,
} from "../../../../services/smeAgreementService/smeAgreementThunk";

const { Search } = Input;

const SmeRequestedLiveAttestationPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { requestedLiveAttestationSmeAgreementList } = useSelector(
    (state: RootState) => state.smeAgreement,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "requestedLiveAttestationDate",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<Attestation>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };

  const tablePagination = {
    total: requestedLiveAttestationSmeAgreementList?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const onCancelApplication = (data: any) => {
    setDeleteLoading(true);

    dispatch(
      cancelApplicationThunk({
        applicationId: data.agreement.application.id,
      }),
    )
      .unwrap()
      .then(() => {
        message.success("Application cancelled");
        fetchAttestations({
          ...initialParams,
          filters: filterState,
        });
      })
      .finally(() => setDeleteLoading(false));
  };
  const columns: ColumnsType<SmeAgreement> = [
    {
      title: "Scheduled Date",
      dataIndex: "requestedLiveAttestationDate",
      key: "requestedLiveAttestationDate",
      render: (_, record) => (record?.smeAttestation?.requestedLiveAttestationDate ? displayDate(record?.smeAttestation?.requestedLiveAttestationDate) : "-"),
    },
    {
      title: "Scheduled Time",
      dataIndex: "requestedLiveAttestationTime",
      key: "requestedLiveAttestationTime",
      render: (_, record) => (record?.smeAttestation?.requestedLiveAttestationTime ? displayTime(record?.smeAttestation?.requestedLiveAttestationTime) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/sme/agreement/details/${record?.id}`)}
        >
          <span style={{ userSelect: "text" }}>
            {text}
          </span>
        </Button>
      ),
    },
    {
      title: "Name",
      render: (_, record) =>
        record?.smeApplication?.smeIndividualApplication?.name ??
        record?.smeApplication?.smeSolePropApplication?.name ??
        record?.smeApplication?.smePartnershipApplication?.name ??
        record?.smeApplication?.smeCompanyApplication?.companyName ??
        "-",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <FileListModal entityId={record.id} />
          <UpdateLiveAttestationStatusModal
            agreement={record}
            callback={() => fetchAttestations(initialParams)}
          />
          <Popconfirm
            title="Do you want to cancel this application?"
            onConfirm={() => {
              onCancelApplication(record);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button loading={deleteLoading} danger>
              Cancel
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "PIC (ID/Name of Attestor)",
      dataIndex: "personInCharge",
      key: "personInCharge",
      render: (_, record) => record?.smeAttestation?.personInCharge ?? "-",
    },
  ];

  const fetchAttestations = async (params?: any) => {
    await dispatch(getRequestedLiveAttestationSmeAgreementListingThunk({ params }));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchAttestations({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      fetchAttestations({
        ...initialParams,
        filters: filterState,
      });
      setSearchValue("");
    }
    setFilterState({
      ...initialParams.filters,
    });
  };

  const handleTableChange: TableProps<SmeAgreement>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
          ...initialParams,
          ...filterState,
          pagination,
          sortField: sorter?.columnKey,
          sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
        }
        : {
          ...initialParams,
          ...filterState,
          pagination,
          sortField: sorter?.columnKey,
          sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          search: {
            [searchFiled]: searchValue,
          },
        };

    fetchAttestations(param);
    setSortedInfo(sorter as SorterResult<Attestation>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>SME Live Attestation - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      {
        <Table
          rowKey="id"
          className="min-w-fit"
          columns={columns}
          pagination={tablePagination}
          dataSource={requestedLiveAttestationSmeAgreementList?.data?.data ?? []}
          loading={requestedLiveAttestationSmeAgreementList?.isLoading}
          onChange={handleTableChange}
        />
      }
    </>
  );
};

export default SmeRequestedLiveAttestationPage;
