import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import InfoGrid from "../../InfoGrid";

import "./index.less";

const DisbursementInfoTab = () => {
  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );

  const disbursementData = [
    {
      title: "Applicant Name",
      value: applicationDetails?.data?.applicantFirstName,
    },
    {
      title: "Applicant NRIC",
      value: applicationDetails?.data?.applicantNric,
    },
    {
      title: "Bank Account No.",
      value: applicationDetails?.data?.applicantPersonalBankNo,
    },
    {
      title: "Bank Name",
      value: applicationDetails?.data?.applicantBankName,
    },
  ];

  return (
    <div className="info-container">
      <InfoGrid data={disbursementData} />
    </div>
  );
};

export default DisbursementInfoTab;
