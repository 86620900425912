import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import {
  createLoanInsurance,
  createLoanInsuranceCoverage,
  createLoanInsurancePlan,
  deleteLoanInsuranceById,
  deleteLoanInsuranceCoverageById,
  deleteLoanInsurancePlanById,
  getLoanInsuranceById,
  getLoanInsuranceCoverageById,
  getLoanInsuranceList,
  getLoanInsurancePlanById,
  updateLoanInsuranceById,
  updateLoanInsuranceCoverageById,
  updateLoanInsurancePlanById,
} from "./masterService";

// loan insurance
export const getLoanInsuranceListThunk = createAsyncThunk(
  "master/getLoanInsuranceList",
  async (requestData: any, thunkAPI) => {
    try {
      return await getLoanInsuranceList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getLoanInsuranceByIdThunk = createAsyncThunk(
  "master/getLoanInsuranceById",
  async (requestData: any, thunkAPI) => {
    try {
      return await getLoanInsuranceById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createLoanInsuranceThunk = createAsyncThunk(
  "master/createLoanInsurance",
  async (requestData: any, thunkAPI) => {
    try {
      return await createLoanInsurance(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateLoanInsuranceByIdThunk = createAsyncThunk(
  "master/updateLoanInsuranceById",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateLoanInsuranceById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// loan insurance plan
export const getLoanInsurancePlanByIdThunk = createAsyncThunk(
  "master/getLoanInsurancePlanById",
  async (requestData: any, thunkAPI) => {
    try {
      return await getLoanInsurancePlanById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createLoanInsurancePlanThunk = createAsyncThunk(
  "master/createLoanInsurancePlan",
  async (requestData: any, thunkAPI) => {
    try {
      return await createLoanInsurancePlan(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateLoanInsurancePlanByIdThunk = createAsyncThunk(
  "master/updateLoanInsurancePlanById",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateLoanInsurancePlanById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteLoanInsurancePlanByIdThunk = createAsyncThunk(
  "master/deleteLoanInsurancePlanById",
  async (requestData: any, thunkAPI) => {
    try {
      return await deleteLoanInsurancePlanById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// loan insurance coverage
export const getLoanInsuranceCoverageByIdThunk = createAsyncThunk(
  "master/getLoanInsuranceCoverageById",
  async (requestData: any, thunkAPI) => {
    try {
      return await getLoanInsuranceCoverageById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createLoanInsuranceCoverageThunk = createAsyncThunk(
  "master/createLoanInsuranceCoverage",
  async (requestData: any, thunkAPI) => {
    try {
      return await createLoanInsuranceCoverage(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateLoanInsuranceCoverageByIdThunk = createAsyncThunk(
  "master/updateLoanInsuranceCoverageById",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateLoanInsuranceCoverageById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteLoanInsuranceCoverageByIdThunk = createAsyncThunk(
  "master/deleteLoanInsuranceCoverageById",
  async (requestData: any, thunkAPI) => {
    try {
      return await deleteLoanInsuranceCoverageById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteLoanInsuranceByIdThunk = createAsyncThunk(
  "master/deleteLoanInsuranceById",
  async (requestData: any, thunkAPI) => {
    try {
      return await deleteLoanInsuranceById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
