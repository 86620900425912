import { Helmet } from "react-helmet-async";
import { PageHeader } from "@ant-design/pro-components";
import { Button, message, Popconfirm, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import VideoModal from "../../components/VideoModal";
import { displayDate } from "../../utils/datetime.util";
import { deletePreRecordedVideoThunk } from "../../services/preRecordedVideoService/preRecordedVideoThunk";
import {
  getSmePreRecordedVideoBMThunk,
  getSmePreRecordedVideoENThunk,
} from "../../services/smePreRecordedVideoService/smePreRecordedVideoThunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useState } from "react";
import { SmePreRecordedVideoFormModal } from "./SmePreRecordedVideoFormModal";
import { useLifecycles } from "react-use";

const SmePreRecordedVideoListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { smePreRecordedVideoEN, smePreRecordedVideoBM } = useSelector(
    (state: RootState) => state.smePreRecordedVideo,
  );
  const fetchPreRecordedVideos = async (params?: any) => {
    await dispatch(getSmePreRecordedVideoENThunk({}));
    await dispatch(getSmePreRecordedVideoBMThunk({}));
  };

  const onDelete = async (preRecordedVideoId: string) => {
    setDeleteLoading(true);
    await dispatch(deletePreRecordedVideoThunk({ preRecordedVideoId }))
      .unwrap()
      .then(() => {
        message.success("Deleted");
        fetchPreRecordedVideos();
      })
      .finally(() => setDeleteLoading(false));
  };

  useLifecycles(() => {
    fetchPreRecordedVideos();
  });

  function getColumns(language: "BM" | "EN"): ColumnsType<any> {
    const columns: ColumnsType<any> = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Video",
        key: "video",
        width: 120,
        render: (_, record) => (
          <VideoModal key={record.id} preRecordedVideo={record} />
        ),
      },
      {
        title: "Uploaded Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 180,
        render: (text) => (text ? displayDate(text) : "-"),
      },
      {
        title: "Action",
        key: "action",
        width: 324,
        render: (_, record) => (
          <Space>
            <SmePreRecordedVideoFormModal
              key={record.id}
              smePreRecordedVideo={record}
              videoLanguageVersion={language}
              callback={() => fetchPreRecordedVideos()}
            />
            <Popconfirm
              title="Do you want to delete this pre recorded video?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return columns;
  }

  return (
    <>
      <Helmet>
        <title>SME Pre Recorded Video Config - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="SME Pre Recorded Video Config" />

      <div className="flex justify-between items-center mb-2">
        <h3 className="text-md font-medium mb-0">EN version</h3>
        {smePreRecordedVideoEN?.data.length < 1 && (
          <SmePreRecordedVideoFormModal
            key="createVideoEN"
            videoLanguageVersion="EN"
            callback={() => fetchPreRecordedVideos()}
          />
        )}
      </div>
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={getColumns("EN")}
        pagination={false}
        dataSource={smePreRecordedVideoEN?.data || []}
        loading={smePreRecordedVideoEN?.isLoading}
      />

      {/* BM */}
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-md font-medium mb-0">BM version</h3>
        {smePreRecordedVideoBM?.data.length < 1 && (
          <SmePreRecordedVideoFormModal
            key="createVideoBM"
            videoLanguageVersion="BM"
            callback={() => fetchPreRecordedVideos()}
          />
        )}
      </div>
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={getColumns("BM")}
        pagination={false}
        dataSource={smePreRecordedVideoBM?.data || []}
        loading={smePreRecordedVideoBM?.isLoading}
      />
    </>
  );
};
export default SmePreRecordedVideoListingPage;