import { Card } from "antd";
import startCase from "lodash.startcase";
import { numberWithCommas } from "../../../helpers/number";

type PerformanceCardProps = {
  amountType?: string | null | undefined;
  value?: number | undefined | null | string;
};

const PerformanceCard: React.FC<PerformanceCardProps> = (props) => {
  const { amountType, value } = props;
  return (
    <Card
      title={
        <h4 className="mb-0">
          {amountType ? startCase(amountType) : amountType}
        </h4>
      }
      size="small"
    >
      {amountType === "totalApplication" ? (
        <>{value}</>
      ) : (
        <> RM {numberWithCommas(value)}</>
      )}
    </Card>
  );
};

PerformanceCard.defaultProps = {
  amountType: "",
  value: 0,
};

export default PerformanceCard;
