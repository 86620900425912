import { Button, Modal, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLifecycles } from "react-use";

import LoadingSpinner from "../../../components/LoadingSpinner";
import { getBatchListThunk } from "../../../services/noticesGenerationConfigService/noticesGenerationConfigThunk";
import { AppDispatch, RootState } from "../../../store/store";

import type {
  ColumnsType,
  TablePaginationConfig,
  TableProps,
} from "antd/es/table";
import { listingSelectBatchNoStateReset } from "../../../features/noticesGenerationConfig/noticesGenerationConfigSlice";
import { getPaymentNoticeBatchListThunk } from "../../../services/paymentNoticeService/paymentNoticeThunk";
import { Agreement } from "../../../features/agreement/agreementSlice";
import { SorterResult } from "antd/es/table/interface";
import { displayDate } from "../../../utils/datetime.util";

interface SelectBatchProps {
  handleSelectBatch: (selectedData: any, selectedBatchNo: any) => void;
}

const SelectBatch = ({ handleSelectBatch }: SelectBatchProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const { paymentNoticeBatchListing } = useSelector(
    (state: RootState) => state.paymentNotice,
  );
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const [visible, setVisible] = useState(false);
  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (text: string, record: any, index: number) => index + 1,
    },
    {
      title: "Batch No",
      dataIndex: "batchNo",
      key: "batchNo",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortOrder: sortedInfo.columnKey === "batchNo" ? sortedInfo.order : null,
    },
    {
      title: "Action",
      render: (_, record) => (
        <Button onClick={() => handleSelectBatch(record.id, record.batchNo)}>
          Select
        </Button>
      ),
    },
  ];

  const onModalClose = () => {
    setVisible(false);
    dispatch(listingSelectBatchNoStateReset());
  };

  const getBatchList = (params?: any) => {
    dispatch(getPaymentNoticeBatchListThunk(params));
  };

  const initialCallbackState = { getBatchList };
  const [callbackState] = useState(initialCallbackState);

  const tablePagination = {
    total: paymentNoticeBatchListing.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const handleTableChange: TableProps<any>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    console.log(sorter);
    setSortedInfo(sorter as SorterResult<Agreement>);
    const param = {
      ...initialParams,
      pagination,
      sortField: "createdAt",
      sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
      currentPage: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    };
    getBatchList({
      ...param,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    if (visible) {
      const params = {
        pagination: {
          current: 1,
          pageSize: 10,
        },
        currentPage: 1,
        pageSize: 10,
        sortOrder: "desc",
        sortField: "createdAt",
        search: {},
        filters: {},
      };
      callbackState.getBatchList(params);
    }
  }, [callbackState, visible]);

  return (
    <>
      <Button onClick={() => setVisible(true)}>Select Batch</Button>
      <Modal
        open={visible}
        title="Please Select Batch:"
        onCancel={onModalClose}
        footer={null}
        destroyOnClose
        centered
      >
        {paymentNoticeBatchListing.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table
            columns={columns}
            dataSource={paymentNoticeBatchListing?.data.data ?? []}
            pagination={tablePagination}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  handleSelectBatch(record.id, record.batchNo);
                  onModalClose();
                },
              };
            }}
            rowClassName={(record) =>
              record.selected ? "table-row-selected" : ""
            }
            onChange={handleTableChange}
          />
        )}
      </Modal>
    </>
  );
};

export default SelectBatch;
