import "./index.less";

import {
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Agreement } from "../../../../../features/agreement/agreementSlice";
import { AppDispatch, RootState } from "../../../../../store/store";
import {
  patchPendingDisbursementAgreementToReferbackTrunk,
  repostAgreementTrunk,
} from "../../../../../services/disbursementService/disbursementTrunk";
import { getUnsuccessfulReasonTrunk } from "../../../../../services/dropdownParameterService/dropdownParameterThunk";
import { primaryColor } from "../../../../../assets/style/variables";

const UpdateDisbursementModal: React.FC<{
  agreement?: Agreement;
  batchId?: string;
  callback?: () => void;
}> = ({ agreement, batchId, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { disbursementUnsuccessfulReasonState } = useSelector(
    (state: RootState) => state.dropdownParameter,
  );

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const actionValue = Form.useWatch("action", form);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        values.action === "Repost"
          ? await dispatch(
              repostAgreementTrunk({
                agreementList: [agreement?.id],
                postDate: values?.postDate,
                batchId,
              }),
            )
              .unwrap()
              .then(() => {
                if (callback) callback();
                message.success("Update Successfully");
              })
          : await dispatch(
              patchPendingDisbursementAgreementToReferbackTrunk({
                agreementId: agreement?.id,
                payload: {
                  remarks: `${values?.reason}, ${values?.remarks}.`,
                },
              }),
            )
              .unwrap()
              .then(() => {
                if (callback) callback();
                message.success("Update Successfully");
              });
      })
      .catch((reason) => {
        message.error(reason);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(getUnsuccessfulReasonTrunk());
  }, []);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: primaryColor } }}>
      <Button type="primary" danger onClick={showModal}>
        Update
      </Button>
      <Modal
        title="Disbursement Update"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={360}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          initialValues={{ action: "Repost" }}
        >
          <Form.Item
            label="Action"
            name="action"
            rules={[{ required: true, message: "Action is required" }]}
          >
            <Select
              placeholder="Please Select"
              allowClear
              options={[{ value: "Repost" }, { value: "Referback" }]}
            />
          </Form.Item>

          {actionValue === "Repost" ? (
            <Form.Item
              label="Post Date"
              name="postDate"
              rules={[{ required: true, message: "Please select date!" }]}
            >
              <DatePicker placeholder="Select Date" className="w-full" />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label="Unsuccessful Reason"
                name="reason"
                rules={[{ required: true, message: "Reason is required" }]}
              >
                <Select
                  placeholder="Please Select"
                  loading={disbursementUnsuccessfulReasonState.isLoading}
                  allowClear
                  options={
                    disbursementUnsuccessfulReasonState?.data?.map((item) => ({
                      value: item.name,
                    })) ?? []
                  }
                />
              </Form.Item>
              <Form.Item
                label="Remarks"
                name="remarks"
                rules={[{ required: true, message: "Remarks is required" }]}
              >
                <Input.TextArea rows={3} placeholder="Please input remarks" />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export default UpdateDisbursementModal;
