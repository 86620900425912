import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import {
  createRescheduleCase,
  getRescheduleCase,
  getRescheduleListing,
  getRescheduleQuotation,
  updateRescheduleCase,
  updateRescheduleCaseStatus,
} from "./rescheduleService";

export const createRescheduleCaseThunk = createAsyncThunk(
  "reschedule/createRescheduleCase",
  async (requestData: any, thunkAPI) => {
    try {
      return await createRescheduleCase(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRescheduleCaseThunk = createAsyncThunk(
  "reschedule/getRescheduleCase",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRescheduleCase(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRescheduleCaseThunk = createAsyncThunk(
  "reschedule/updateRescheduleCase",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateRescheduleCase(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRescheduleCaseStatusThunk = createAsyncThunk(
  "reschedule/updateRescheduleCaseStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateRescheduleCaseStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getOpenRescheduleListingThunk = createAsyncThunk(
  "reschedule/getOpenRescheduleListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRescheduleListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingRescheduleListingThunk = createAsyncThunk(
  "reschedule/getPendingRescheduleListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRescheduleListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVoidRescheduleListingThunk = createAsyncThunk(
  "reschedule/getVoidRescheduleListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRescheduleListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApprovedRescheduleListingThunk = createAsyncThunk(
  "reschedule/getApprovedRescheduleListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRescheduleListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferRescheduleListingThunk = createAsyncThunk(
  "reschedule/getReferRescheduleListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRescheduleListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRescheduleQuotationThunk = createAsyncThunk(
  "reschedule/getRescheduleQuotation",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRescheduleQuotation(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
