import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import noticesGenerationConfigService from "./noticesGenerationConfigService";

export const getAllListingNoticesGenerationConfigThunk = createAsyncThunk(
  "noticesGenerationConfig/getAll",
  async (requestData, thunkAPI) => {
    try {
      return await noticesGenerationConfigService.getListingNoticesGenerationConfig();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDetailsByIdNoticesGenerationConfigThunk = createAsyncThunk(
  "noticesGenerationConfig/getDetailsByIdNoticesGenerationConfig",
  async (requestData: any, thunkAPI) => {
    try {
      return await noticesGenerationConfigService.getDetailsByIdNoticesGenerationConfig(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createNoticesGenerationConfigThunk = createAsyncThunk(
  "noticesGenerationConfig/createNoticesGenerationConfig",
  async (requestData: any, thunkAPI) => {
    try {
      return await noticesGenerationConfigService.createNoticesGenerationConfig(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateNoticesGenerationConfigThunk = createAsyncThunk(
  "noticesGenerationConfig/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await noticesGenerationConfigService.updateNoticesGenerationConfig(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getBatchListThunk = createAsyncThunk(
  "noticesGenerationConfig/getBatchList",
  async (requestData: any, thunkAPI) => {
    try {
      return await noticesGenerationConfigService.getBatchList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAgreementsByBatchIdThunk = createAsyncThunk(
  "noticesGenerationConfig/getAgreementsByBatchId",
  async (requestData: any, thunkAPI) => {
    try {
      return await noticesGenerationConfigService.getAgreementsByBatchId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateSingleNoticePDFThunk = createAsyncThunk(
  "noticesGenerationConfig/generateSingleNoticePDF",
  async (requestData: any, thunkAPI) => {
    try {
      return await noticesGenerationConfigService.generateSingleNoticePDF(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateAllNoticePDFThunk = createAsyncThunk(
  "noticesGenerationConfig/generateAllNoticePDF",
  async (requestData: any, thunkAPI) => {
    try {
      return await noticesGenerationConfigService.generateAllNoticePDF(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const generateCSVListThunk = createAsyncThunk(
  "noticesGenerationConfig/generateCSVList",
  async (requestData: any, thunkAPI) => {
    try {
      return await noticesGenerationConfigService.generateCSVList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
