import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import smeDisbursementService from "./smeDisbursementService";

export const getSmeDisbursementBatchByIdThunk = createAsyncThunk(
  "smeDisbursement/getSmeDisbursementBatchById",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeDisbursementService.getSmeDisbursementBatchById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmePendingDisbursementAgreementsListingThunk = createAsyncThunk(
  "smeDisbursement/getSmePendingDisbursementAgreementsListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeDisbursementService.getSmePendingDisbursementAgreementsListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeDisbursementBatchListingThunk = createAsyncThunk(
  "smeDisbursement/getSmeDisbursementBatchListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeDisbursementService.getSmeDisbursementBatchListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeDisbursementBatchAgreementsThunk = createAsyncThunk(
  "smeDisbursement/getSmeDisbursementBatchAgreements",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeDisbursementService.getSmeDisbursementBatchAgreements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmeDisbursementBatchThunk = createAsyncThunk(
  "smeDisbursement/createSmeDisbursementBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeDisbursementService.createSmeDisbursementBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const postSmeDisbursementBatchAgreementsThunk = createAsyncThunk(
  "smeDisbursement/postSmeDisbursementBatchAgreements",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeDisbursementService.postSmeDisbursementBatchAgreements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const unpostSmeDisbursementBatchAgreementByIdThunk = createAsyncThunk(
  "smeDisbursement/unpostSmeDisbursementBatchAgreementById",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeDisbursementService.unpostSmeDisbursementBatchAgreementById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const repostSmeAgreementThunk = createAsyncThunk(
  "smeDisbursement/repostSmeAgreement",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeDisbursementService.repostSmeAgreement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeDisbursementBatchBankFileThunk = createAsyncThunk(
  "smeDisbursement/getSmeDisbursementBatchBankFile",
  async (id: string, thunkAPI) => {
    try {
      return await smeDisbursementService.getSmeDisbursementBatchBankFile(id);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updatePendingDisbursementAgreementToReferbackThunk = createAsyncThunk(
  "smeDisbursement/updatePendingDisbursementAgreementToReferback",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeDisbursementService.updatePendingDisbursementAgreementToReferback(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDisbursementListingSummaryPdfThunk = createAsyncThunk(
  "smeDisbursement/getDisbursementListingSummaryPdf",
  async (id: string, thunkAPI) => {
    try {
      return await smeDisbursementService.getDisbursementListingSummary(id);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);