import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import {
  createBatchNo,
  createWaiverRecord,
  deleteAgreementFromwaiver,
  editWaiverRecord,
  getAgreementList,
  getBatchNo,
  getWaiver,
  getWaiverCharges,
  getWaiverDetailsById,
  getWaiverRemarks,
  submitWaiverForApproval,
  submitWaiverToApprove,
  submitWaiverToReferback,
  submitWaiverToVoid,
} from "./waiverService";

export const getAllWaiverThunk = createAsyncThunk(
  "waiver/getList",
  async (requestData: any, thunkAPI) => {
    try {
      return await getBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getOpenWaiverThunk = createAsyncThunk(
  "waiver/getOpen",
  async (requestData: any, thunkAPI) => {
    try {
      return await getBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferbackWaiverThunk = createAsyncThunk(
  "waiver/getReferback",
  async (requestData: any, thunkAPI) => {
    try {
      return await getBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVoidWaiverThunk = createAsyncThunk(
  "waiver/getVoid",
  async (requestData: any, thunkAPI) => {
    try {
      return await getBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApprovedWaiverThunk = createAsyncThunk(
  "waiver/getApproved",
  async (requestData: any, thunkAPI) => {
    try {
      return await getBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingWaiverThunk = createAsyncThunk(
  "waiver/getPending",
  async (requestData: any, thunkAPI) => {
    try {
      return await getBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getBatchNoThunk = createAsyncThunk(
  "waiver/getBatchNo",
  async (requestData: any, thunkAPI) => {
    try {
      return await getBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createBatchNoThunk = createAsyncThunk(
  "waiver/createBatchNo",
  async (requestData: any, thunkAPI) => {
    try {
      return await createBatchNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createWaiverRecordThunk = createAsyncThunk(
  "waiver/createWaiverRecord",
  async (requestData: any, thunkAPI) => {
    try {
      return await createWaiverRecord(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const submitWaiverThunkForApprovalThunk = createAsyncThunk(
  "waiver/submitWaiverForApproval",
  async (requestData: any, thunkAPI) => {
    try {
      return await submitWaiverForApproval(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWaiverDetailsByIdThunk = createAsyncThunk(
  "waiver/getWaiverDetailsById",
  async (requestData: any, thunkAPI) => {
    try {
      return await getWaiverDetailsById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAgreementListThunk = createAsyncThunk(
  "waiver/getAgreementList",
  async (requestData: any, thunkAPI) => {
    try {
      return await getAgreementList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteAgreementNoFromWaiverThunk = createAsyncThunk(
  "waiver/deleteAgreementNo",
  async (requestData: any, thunkAPI) => {
    try {
      console.log(requestData);

      return await deleteAgreementFromwaiver(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWaiverRemarksThunk = createAsyncThunk(
  "waiver/getWaiverRemarks",
  async (requestData: any, thunkAPI) => {
    try {
      return await getWaiverRemarks(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const submitReferbackWaiverThunk = createAsyncThunk(
  "waiver/submitWaiverReferback",
  async (requestData: any, thunkAPI) => {
    try {
      return await submitWaiverToReferback(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const submitVoidWaiverThunk = createAsyncThunk(
  "waiver/submitWaiverVoid",
  async (requestData: any, thunkAPI) => {
    try {
      return await submitWaiverToVoid(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const approveWaiverThunk = createAsyncThunk(
  "waiver/approveWaiver",
  async (requestData: any, thunkAPI) => {
    try {
      return await submitWaiverToApprove(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWaiverChargesThunk = createAsyncThunk(
  "waiver/getWaiverCharges",
  async (requestData: any, thunkAPI) => {
    try {
      return await getWaiverCharges(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const editWaiverAgreementNoThunk = createAsyncThunk(
  "waiver/editWaiverAgreementNo",
  async (requestData: any, thunkAPI) => {
    try {
      return await editWaiverRecord(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
