import "./index.less";

import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SignatureCanvas from "react-signature-canvas";

import { CloseOutlined, UploadOutlined } from "@ant-design/icons";

import LoadingSpinner from "../../../components/LoadingSpinner";
import { TrustSigner } from "../../../features/trustSigner/trustSignerSlice";
import {
  fileDeleteThunk,
  fileUploadThunk,
  getFileByIdThunk,
} from "../../../services/fileService/fileThunk";
import {
  createTrustSignerThunk,
  getSignatureFilesThunk,
  updateTrustSignerThunk,
} from "../../../services/trustSignerService/trustSignerThunk";
import { AppDispatch } from "../../../store/store";
import { getBase64, toDataUrl } from "../../../utils/file";

import type { UploadProps } from "antd/es/upload/interface";

const { Text } = Typography;

const TrustSignerFormModal: React.FC<{
  trustSigner?: TrustSigner;
  callback?: () => void;
}> = ({ trustSigner, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const signRef = useRef() as React.MutableRefObject<any>;

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSignature, setLoadingSignature] = useState(false);

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      getBase64(file).then((res) => {
        signRef?.current?.fromDataURL(res, {
          ratio: 1,
          width: 380,
          height: 240,
        });
      });
      return false;
    },
    fileList: [],
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    clearSignature();
    form.resetFields();
  };

  const clearSignature = () => {
    signRef?.current?.clear();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        let entityId = "";

        if (trustSigner?.id != null) {
          entityId = trustSigner?.id;
          await dispatch(
            updateTrustSignerThunk({
              trustSignerId: trustSigner.id,
              payload: { ...values, status: values.status === "active" },
            }),
          )
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Update Successfully");
            });
        } else {
          await dispatch(
            createTrustSignerThunk({
              ...values,
              companyId: process.env.REACT_APP_COMPANY_ID,
              status: values.status === "active",
            }),
          )
            .unwrap()
            .then((res) => {
              entityId = res.id;
              if (callback) callback();
              message.success("Create Successfully");
            });
        }

        // Clear all previous files
        await dispatch(getSignatureFilesThunk({ entityId }))
          .unwrap()
          .then((res) => {
            if (res?.length > 0) {
              res?.length > 0 &&
                res.forEach((file: any) =>
                  dispatch(fileDeleteThunk({ fileId: file.id })),
                );
            }

            signRef.current.getCanvas().toBlob((blob: Blob) => {
              const formData = new FormData();
              formData.append("entityId", entityId);
              formData.append("fileType", "TRUSTSIGNER");
              formData.append("file", blob, "signature.png");

              dispatch(fileUploadThunk(formData))
                .unwrap()
                .then(async (res) => {
                  if (callback) callback();
                  handleCancel();
                })
                .finally(() => setLoading(false));
            });
          });
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
      });
  };

  useEffect(() => {
    if (trustSigner?.id != null && isModalOpen) {
      setLoadingSignature(true);
      dispatch(getSignatureFilesThunk({ entityId: trustSigner.id }))
        .unwrap()
        .then((res) => {
          if (res?.length > 0) {
            dispatch(getFileByIdThunk({ fileId: res[0].id }))
              .unwrap()
              .then((res) => {
                getBase64(res).then((res) => {
                  signRef?.current?.fromDataURL(res, {
                    ratio: 1,
                    width: 380,
                    height: 240,
                  });
                });
              });
          }
        })
        .finally(() => setLoadingSignature(false));
    }
  }, [trustSigner?.id, isModalOpen]);

  // useEffect(() => {
  //   if (signature?.data?.id != null) {
  //     dispatch(getFileByIdThunk({ fileId: signature.data.id }))
  //       .unwrap()
  //       .then((res) => setSignatureImage(res));
  //   }
  // }, [signature?.data?.id]);

  return (
    <>
      <Button onClick={showModal}>
        {trustSigner != null ? "Details" : "Create Trust Signer"}
      </Button>

      <Modal
        title={
          trustSigner != null ? "Trust Signer Details" : "Create Trust Signer"
        }
        okText={trustSigner != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={750}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          initialValues={{
            ...trustSigner,
            status: trustSigner?.status ? "active" : "notActive",
          }}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
        >
          <Row gutter={16}>
            <Col sm={24} md={10}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Name is required" }]}
              >
                <Input placeholder="Please Input" />
              </Form.Item>

              <Form.Item
                label="NRIC"
                name="userId"
                rules={[{ required: true, message: "NRIC is required" }]}
              >
                <Input placeholder="Please Input" />
              </Form.Item>

              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Role is required" }]}
              >
                <Select
                  placeholder="Please Select"
                  allowClear
                  options={[
                    { value: "APPROVER", label: "Approver" },
                    { value: "ATTESTATOR", label: "Attestator" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Priority"
                name="priority"
                rules={[{ required: true, message: "Priority is required" }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Please Input"
                />
              </Form.Item>

              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Radio.Group defaultValue="active">
                  <Radio.Button value="active">Active</Radio.Button>
                  <Radio.Button value="notActive">Not Active</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col sm={24} md={14}>
              <Text>Signature</Text>
              {loadingSignature ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div className="signature-container">
                    <SignatureCanvas
                      ref={signRef}
                      canvasProps={{
                        width: 400,
                        height: 250,
                        className: "sigCanvas",
                      }}
                    />
                  </div>
                  <Space>
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    <Button
                      onClick={() => clearSignature()}
                      icon={<CloseOutlined />}
                      danger
                    >
                      Clear Signature
                    </Button>
                  </Space>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default TrustSignerFormModal;
