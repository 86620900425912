import "./index.less";
import { Button, Form, Input, InputNumber, Modal, Select, message } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NoticesConfig } from "../../../features/noticesGenerationConfig/noticesGenerationConfigSlice";
import { AppDispatch } from "../../../store/store";
import { updateNoticesGenerationConfigThunk } from "../../../services/noticesGenerationConfigService/noticesGenerationConfigThunk";

const NoticesGenerationConfigFormModal: React.FC<{
  notices?: NoticesConfig;
  callback?: () => void;
}> = ({ notices, callback }) => {
  const labelClassName = "w-[80px]";

  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  console.log(notices, callback);

  const onFinish = async (values: any) => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        dispatch(
          updateNoticesGenerationConfigThunk({
            noticesId: notices?.id,
            payload: {
              ...values,
            },
          }),
        )
          .unwrap()
          .then(() => {
            if (callback) callback();
            message.success("Update Successfully");
            handleCancel();
          })
          .finally(() => setLoading(false));
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      {notices != null && <Button onClick={showModal}>Update</Button>}

      <Modal
        title={"Update Notices Generation"}
        okText={"Update"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={500}
        destroyOnClose
      >
        <Form
          form={form}
          initialValues={notices}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            label={<span className={labelClassName}>Letter Type</span>}
            name="letterType"
            rules={[
              { required: true, message: "Please enter the letter type." },
            ]}
          >
            <Input className={"readOnlyInput"} />
          </Form.Item>
          <Form.Item
            label={<span className={labelClassName}>DPD</span>}
            name="dpdToTrigger"
            rules={[{ required: true, message: "Please enter the DPD." }]}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item
            label={<span className={labelClassName}>Charges(RM)</span>}
            name="charge"
            rules={[
              { required: true, message: "Please enter the charges (RM)." },
            ]}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item
            label={<span className={labelClassName}>Status</span>}
            name="status"
            rules={[{ required: true, message: "Please select the status." }]}
          >
            <Select
              defaultValue="ACTIVE"
              className="w-full"
              options={[
                { value: "ACTIVE", label: "ACTIVE" },
                { value: "INACTIVE", label: "INACTIVE" },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NoticesGenerationConfigFormModal;
