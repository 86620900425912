import { AppDispatch, RootState } from "../../../../store/store";
import { Button, Col, Input, Row, Select, Space, Table, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  addAgreementNoToWriteOffBatchThunk,
  getWriteOffDetailsByBatchIdThunk,
} from "../../../../services/writeOffService/writeOffThunk";
import { useDispatch, useSelector } from "react-redux";

import type { ColumnsType } from "antd/es/table";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import type { TablePaginationConfig } from "antd/es/table";
import { WriteOffCategoryLabel } from "../../../../constant/writeOff";
import { WriteOffStatusEnum } from "../../../../enum/writeOff";
import { displayDate } from "../../../../utils/datetime.util";
import { numberWithCommas } from "../../../../helpers/number";
import { useParams } from "react-router-dom";
import AccountEnquiryModal from "../../../AccountEnquiry/AccountEnquiryModal";

const { Search } = Input;

type WriteOffProposalDetailsProps = {
  dataSource: any;
  selectedRow: any;
  setSelectedRow: any;
  batchStatus: string;
};

const editableStatus = ["NEW", "REFERBACK"];

const WriteOffProposalDetails: React.FC<WriteOffProposalDetailsProps> = (
  props,
) => {
  const { dataSource, selectedRow, setSelectedRow, batchStatus } = props;
  const dispatch = useDispatch<AppDispatch>();

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchField, setSearchField] = useState("agreementNo");
  const [searchSelected, setSearchSelected] = useState("ALL");
  const [searchAgreementNo, setSearchAgreementNo] = useState("");

  const { writeOffBatch } = useSelector((state: RootState) => state.writeOff);
  const { writeOffDetails } = useSelector(
    (state: RootState) => state.writeOffProposal,
  );

  const fetchWriteOffDetails = async (params?: any) => {
    await dispatch(
      getWriteOffDetailsByBatchIdThunk({
        ...params,
        id: writeOffBatch?.data?.id,
        status: batchStatus,
      }),
    );
  };

  const tablePagination = {
    total: dataSource?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Agreement No.",
      dataIndex: ["accountdetails", "agreementNo"],
      render: (text, record) => (
        <AccountEnquiryModal
          key={record.id}
          agreementNo={record.accountdetails.agreementNo}
        />
      ),
    },
    {
      title: "Code",
      dataIndex: "writeOffCategory",
      key: "writeOffCategory",
    },
    {
      title: "Write Off Account Category",
      dataIndex: "writeOffCategory",
      key: "writeOffCategory",
      render: (text) =>
        WriteOffCategoryLabel[text as keyof typeof WriteOffCategoryLabel],
    },
    // {
    //   title: "Finance Amount (RM)",
    //   dataIndex: ["accountdetails", "totalFinanceAmount"],
    //   key: "financeAmount",
    //   render: (text: number) =>
    //     text != null ? `RM ${numberWithCommas(text)}` : "-",
    // },
    // {
    //   title: "Total Loan Outstanding (RM)",
    //   dataIndex: ["accountdetails", "totalLoanOutstanding"],
    //   key: "totalLoanOutstanding",
    //   render: (text: number) =>
    //     text != null ? `RM ${numberWithCommas(text)}` : "-",
    // },
    {
      title: "Principal Outstanding (RM)",
      dataIndex: "principalAmount",
      key: "principalAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Interest Outstanding (RM)",
      dataIndex: "interestAmount",
      key: "interestAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Late Payment Charge (RM)",
      dataIndex: ["accountdetails", ""],
      key: "latePaymentCharge",
      render: (text: number, record) =>
        record.latePaymentCharge != null
          ? `RM ${numberWithCommas(record.latePaymentCharge)}`
          : "-",
    },
    {
      title: "Other Charges (RM)",
      dataIndex: ["accountdetails", ""],
      key: "otherCharges",
      render: (text: number, record) =>
        record.otherCharges != null
          ? `RM ${numberWithCommas(record.otherCharges)}`
          : "-",
    },
    {
      title: "Proposed Write Off Amount (RM)",
      dataIndex: "writeOffAmount",
      key: "writeOffAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
  ];

  const addAgreementNoToWriteOffBatch = () => {
    if (searchAgreementNo) {
      dispatch(
        addAgreementNoToWriteOffBatchThunk({
          agreementNo: searchAgreementNo,
          writeOffBatchId: writeOffBatch?.data?.id,
        }),
      )
        .unwrap()
        .then((res) => {
          message.success("Agreement successfully added to write off batch.");
          fetchWriteOffDetails({ ...initialParams, ...filterState });
        })
        .catch((error) =>
          message.error(`Error add agreement ${searchAgreementNo} to batch.`),
        );
    }
  };

  const onSearch = (value: string) => {
    if (!value || value == "ALL") {
      fetchWriteOffDetails({
        ...initialParams,
        filters: { ...initialParams.filters },
      });
      setFilterState({ ...initialParams.filters });
      return;
    }

    fetchWriteOffDetails({
      ...initialParams,
      filters: { ...filterState, [searchField]: value },
    });
    setFilterState({ ...initialParams.filters, [searchField]: value });
  };

  const onSearchSelected = (value: string) => {
    if (value == "ALL") {
      fetchWriteOffDetails({
        ...initialParams,
        filters: { ...initialParams.filters },
      });
      setFilterState({ ...initialParams.filters });
      return;
    }

    fetchWriteOffDetails({
      ...initialParams,
      filters: { ...filterState, finalSelected: value },
    });
    setFilterState({ ...initialParams.filters, finalSelected: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    fetchWriteOffDetails({ ...initialParams, ...filterState });
  }, []);

  return (
    <>
      {writeOffBatch?.isLoading || writeOffDetails?.isLoading ? (
        <div className="py-40">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Row gutter={16}>
            <Col>
              <Input.Group compact>
                <Select
                  defaultValue={"agreementNo"}
                  style={{ width: 200 }}
                  options={[
                    { value: "agreementNo", label: "Agreement No" },
                    { value: "applicantNric", label: "Applicant NRIC" },
                    { value: "applicantFirstName", label: "Applicant Name" },
                    { value: "applicantPhoneNo", label: "Phone No" },
                    { value: "applicantEmail", label: "Email" },
                  ]}
                  onChange={(value) => setSearchField(value)}
                />
                <Search onSearch={onSearch} style={{ width: 200 }} />
              </Input.Group>
            </Col>
            <Col>
              <Select
                defaultValue={"ALL"}
                style={{ width: 200 }}
                options={[
                  { value: "ALL", label: "ALL" },
                  { value: "SELECTED", label: "Written Off Account" },
                  { value: "NOT_SELECTED", label: "Non Write Off Account" },
                ]}
                onChange={(value) => {
                  setSearchSelected(value);
                  onSearchSelected(value);
                }}
                value={searchSelected}
              />
            </Col>
          </Row>
          <br />
          {editableStatus.includes(batchStatus) && (
            <div>
              <Space>
                <Input
                  placeholder="Agreement No."
                  style={{ width: 200 }}
                  onChange={(e) => setSearchAgreementNo(e.target.value)}
                />
                <Button onClick={addAgreementNoToWriteOffBatch}>Add New</Button>
              </Space>
            </div>
          )}
          <br />
          <Table
            rowKey="id"
            className="min-w-fit"
            columns={columns}
            pagination={tablePagination}
            dataSource={dataSource?.data?.data ?? []}
            loading={dataSource?.isLoading}
            onChange={handleTableChange}
            rowSelection={{
              selectedRowKeys: selectedRow.length > 0 ? selectedRow : [],
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRow(selectedRowKeys);
              },
              getCheckboxProps: (record) => ({
                disabled: !editableStatus.includes(batchStatus),
              }),
            }}
          />
        </>
      )}
    </>
  );
};

export default WriteOffProposalDetails;
