import { Button, Input, Select, Space, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import FileListModal from "../../../../components/FileListModal";
import { Attestation } from "../../../../features/attestation/attestationSlice";
import { getCompletedLiveAttestationsThunk } from "../../../../services/attestationService/attestationThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate, displayTime } from "../../../../utils/datetime.util";

import type { TableProps } from "antd/es/table";
import type { ColumnsType } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";

const { Search } = Input;

const CompletedLiveAttestationPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { completedLiveAttestationList } = useSelector(
    (state: RootState) => state.attestation,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "requestedLiveAttestationDate",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<Attestation>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };

  const tablePagination = {
    total: completedLiveAttestationList?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Scheduled Date",
      dataIndex: "requestedLiveAttestationDate",
      key: "requestedLiveAttestationDate",
      sorter: (a, b) =>
        a.requestedLiveAttestationDate - b.requestedLiveAttestationDate,
      sortOrder:
        sortedInfo.columnKey === "requestedLiveAttestationDate"
          ? sortedInfo.order
          : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Scheduled Time",
      dataIndex: "requestedLiveAttestationTime",
      key: "requestedLiveAttestationTime",
      render: (text) => (text ? displayTime(text) : "-"),
    },
    {
      title: "Agreement No",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/agreement/details/${record.agreement?.id}`)}
        >
          <span style={{ userSelect: "text" }}>
            {record.agreement?.agreementNo}
          </span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      render: (_, record) =>
        record?.agreement?.application?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      render: (_, record) =>
        record?.agreement?.application?.applicantNric ?? "-",
    },
    {
      title: "Completed Date",
      dataIndex: "liveAttestationDateTime",
      key: "liveAttestationDateTime",
      sorter: (a, b) => a.liveAttestationDateTime - b.liveAttestationDateTime,
      sortOrder:
        sortedInfo.columnKey === "liveAttestationDateTime"
          ? sortedInfo.order
          : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      key: "action",
      render: (_, record) => (
        <Space>
          <FileListModal entityId={record.id} />
        </Space>
      ),
    },
    {
      title: "PIC (ID/Name of Attestor)",
      dataIndex: "personInCharge",
      key: "personInCharge",
      render: (text) => text ?? "-",
    },
  ];

  const fetchAttestations = async (params?: any) => {
    await dispatch(getCompletedLiveAttestationsThunk(params));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchAttestations({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      fetchAttestations({
        ...initialParams,
        filters: filterState,
      });
      setSearchValue("");
    }
    // setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const handleTableChange: TableProps<Attestation>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
            search: {
              [searchFiled]: searchValue,
            },
          };

    fetchAttestations(param);
    setSortedInfo(sorter as SorterResult<Attestation>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>Live Attestation - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      {
        <Table
          rowKey="id"
          className="min-w-fit"
          columns={columns}
          pagination={tablePagination}
          dataSource={completedLiveAttestationList?.data?.data ?? []}
          loading={completedLiveAttestationList?.isLoading}
          onChange={handleTableChange}
        />
      }
    </>
  );
};

export default CompletedLiveAttestationPage;
