import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const getAccountDetailsByAgreementNo = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountDetails`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData,
  });
  return res.data;
};

const getAgreements = async (requestData: any) => {
  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/agreements/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getPendingSigningAgreements = async (requestData: any) => {
  const params = {
    // filters: {
    //   ...requestData?.filters,
    //   agreementStatus: AgreementStatusEnum.PENDING_SIGNING,
    // },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/agreement/getPendingSigning/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getSignedAgreements = async (requestData: any) => {
  // const params = {
  //   filters: {
  //     ...requestData?.filters,
  //     agreementStatus: AgreementStatusEnum.PENDING_DISBURSEMENT,
  //   },
  //   search: requestData?.search,
  //   sortField: requestData?.sortField,
  //   sortOrder: requestData?.sortOrder,
  //   currentPage: requestData?.pagination.current,
  //   pageSize: requestData?.pagination.pageSize,
  // };

  // const url = `/redCash/admin/api/v1.0/agreements/company/${process.env.REACT_APP_COMPANY_ID}`;

  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/agreement/completedSigning/company/c595bf81-8fd1-4b7f-b14f-702d2db7cd18`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getPendingEStampAgreements = async (requestData: any) => {
  const params = {
    filters: { ...requestData?.filters, clickedDownloadEStampingCert: false },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/e-stamping/pending`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getInProgressEStampAgreements = async (requestData: any) => {
  const params = {
    filters: { ...requestData?.filters, clickedDownloadEStampingCert: true },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/e-stamping/pending`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getUploadedEStampAgreements = async (requestData: any) => {
  const params = {
    filters: { ...requestData?.filters, clickedDownloadEStampingCert: true },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/e-stamping/completed`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getAgreementDetails = async (requestData: any) => {
  const { agreementId } = requestData;
  const url = `/redCash/admin/api/v1.0/agreement/${agreementId}/summary`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const createAgreement = async (requestData: any) => {
  const { productCode, payload } = requestData;
  const url = `/redCash/api/v1.0/agreement/product/${productCode}`;
  const res = await beSetupAxiosInstance.post(url, payload);
  return res.data;
};

const searchDisbursedAgreement = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountDetails`;
  const res = await collectionSetupAxiosInstance.get(url, requestData);
  return res.data;
};

const approveEStamping = async (requestData: any) => {
  const { agreementId, language } = requestData;
  const url = `/redCash/admin/api/v1.0/agreement/${agreementId}/${language}/approve-e-stamping`;
  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const clickedDownloadEStamping = async (requestData: any) => {
  const { agreementId , username } = requestData;
  const url = `/redCash/admin/api/v1.0/agreement/${agreementId}/clicked-download-e-stamping`;
  const res = await beSetupAxiosInstance.patch(url, {}, {
    params: { username: username },
  });
  return res.data;
};

const disableDirectDebit = async (requestData: any) => {
  const { agreementId, accountDetailsId } = requestData;
  const url = `/redCash/api/v1.0/agreement/disableDirectDebit/agreementId/${agreementId}/accountDetailsId/${accountDetailsId}`;
  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const clickedDownloadSmeEStamping = async (requestData: any) => {
  const { agreementId } = requestData;
  const url = `/redCash/api/v1.0/smeAgreement/${agreementId}/clicked-download-e-stamping`;
  const res = await beSetupAxiosInstance.patch(url, requestData);
  return res.data;
};

const agreementService = {
  getAgreements,
  getPendingSigningAgreements,
  getSignedAgreements,
  getPendingEStampAgreements,
  getInProgressEStampAgreements,
  getUploadedEStampAgreements,
  getAgreementDetails,
  createAgreement,
  searchDisbursedAgreement,
  approveEStamping,
  clickedDownloadEStamping,
  disableDirectDebit,
  clickedDownloadSmeEStamping,
  getAccountDetailsByAgreementNo,
};

export default agreementService;
