import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

const getSmePreRecordedVideoEN = async () => {
  const url = `/redCash/master/api/v1.0/smePreRecordedVideo/type/EN/files`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const getSmePreRecordedVideoBM = async () => {
  const url = `/redCash/master/api/v1.0/smePreRecordedVideo/type/BM/files`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const smePreRecordedVideoService = {
  getSmePreRecordedVideoEN,
  getSmePreRecordedVideoBM,
};

export default smePreRecordedVideoService;
