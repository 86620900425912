import { createSlice } from "@reduxjs/toolkit";
import { getTransactionHistoryThunk } from "../../services/transactionHistoryService/transactionHistoryThunk";
export type transactionHistoryData = {
  id: string;
  referenceNo: string;
  transactionType: string;
  transactionAmount: number;
  agreementId: string;
  instalmentNo: number;
  paymentDate: string;
  paymentUniqueId: string;
  createdAt?: string;
  updatedAt?: string;
};
export type transactionDataResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: transactionHistoryData[];
};
export type TransactionHistoryState = {
  transactionHistory: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: transactionDataResponse;
  };
};
const initialState: TransactionHistoryState = {
  transactionHistory: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
};


export const transactionHistory = createSlice({
  name: "transactionHistory",
  initialState,
  reducers: {
    transactionHistoryStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionHistoryThunk.pending, (state) => {
        state.transactionHistory.isLoading = true;
      })
      .addCase(getTransactionHistoryThunk.fulfilled, (state, action) => {
        state.transactionHistory.isLoading = false;
        state.transactionHistory.data = action.payload;
      })
      .addCase(getTransactionHistoryThunk.rejected, (state, action) => {
        state.transactionHistory.isLoading = false;
        state.transactionHistory.isError = true;
        state.transactionHistory.errorMessage = action.payload;
      });
  },
});

export const { transactionHistoryStateReset } = transactionHistory.actions;

export default transactionHistory.reducer;
