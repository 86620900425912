import "./index.less";

import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import startCase from "lodash.startcase";
import moment from "moment";
import React, { Key, useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createBreakpoint, useLifecycles } from "react-use";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-components";

import { Agreement } from "../../../../features/agreement/agreementSlice";
import { disbursementAgreementsStateReset } from "../../../../features/disbursement/disbursementSlice";
import { downloadLink } from "../../../../helpers/downloadLink";
import { numberWithCommas } from "../../../../helpers/number";
import {
  getDisbursementAgreementsByBatchIdTrunk,
  getDisbursementBatchDetailsTrunk,
  getDisbursementBatchListingThunk,
  getDisbursementListingSummaryThunk,
  getDisbursementListingSummaryTxtFileThunk,
  patchDisbursementBatchToInProcessTrunk,
  postAgreementsInBatchTrunk,
  unpostAgreeementByAgreementIdTrunk,
} from "../../../../services/disbursementService/disbursementTrunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { onCalculateNetDisbursementAmount } from "../../../../utils/calculation.util";
import { displayDate } from "../../../../utils/datetime.util";
import UpdateDisbursementModal from "./UpdateDisbursementModal";

import type { ColumnsType } from "antd/es/table";
import type { DatePickerProps } from "antd";
import type { TablePaginationConfig } from "antd/es/table";

const { Option } = Select;
const { Search } = Input;

const useBreakpoint = createBreakpoint();

const BatchDetailsPage = () => {
  const [tableLoading, setTableLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const { disbursementAgreements, disbursementBatchDetails } = useSelector(
    (state: RootState) => state.disbursement,
  );

  const [selectedInProcessDisbursement, setSelectedInProcessDisbursement] =
    useState<Agreement[]>([]);
  const [
    selectedInProcessDisbursementKey,
    setSelectedInProcessDisbursementKey,
  ] = useState<Key[]>([]);

  const { batchId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const { fetchDisbursementBatchListingParams } = useSelector(
    (state: RootState) => state.fetchParameter,
  );

  const [{ confirm }, contextHolder] = Modal.useModal();
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [postDate, setPostDate] = useState<string>();
  const [downloading, setDownloading] = useState(false);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");

  const tablePagination = {
    total: disbursementAgreements?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const openInProcessTableColumns: ColumnsType<any> = [
    {
      title: "Signed Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/agreement/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (_, record) =>
        record.application?.applicantFirstName
          ? record.application?.applicantFirstName
          : "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "user",
      key: "user",
      render: (_, record) =>
        record.application?.applicantNric
          ? record.application?.applicantNric
          : "-",
    },
    {
      title: "Approved Loan Amount",
      dataIndex: "systemLoanAmountSuggested",
      key: "systemLoanAmountSuggested",
      render: (_, record) =>
        record.application?.financeAmount != null
          ? `RM ${numberWithCommas(record.application?.financeAmount ?? 0)}`
          : "-",
    },
    {
      title: "Net Disbursement Amount",
      dataIndex: "disbursementAmount",
      key: "disbursementAmount",
      render: (text, record) =>
        `RM ${numberWithCommas(
          onCalculateNetDisbursementAmount(
            record.application?.financeAmount ?? 0,
            record.application?.productProcessingFee ?? 0,
            record.application?.productStampingDutyPercentage ?? 0,
            record.application?.productProcessingFeeToDiscount ?? 0,
            record.application?.loanTotalPremium ?? 0,
            record.application?.applicationStatus,
          ),
        )}`,
    },
  ];

  const completedTableColumns: ColumnsType<any> = [
    {
      title: "Signed Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/agreement/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (_, record) =>
        record.application?.applicantFirstName
          ? record.application?.applicantFirstName
          : "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "user",
      key: "user",
      render: (_, record) =>
        record.application?.applicantNric
          ? record.application?.applicantNric
          : "-",
    },
    {
      title: "Approved Loan Amount",
      dataIndex: "systemLoanAmountSuggested",
      key: "systemLoanAmountSuggested",
      render: (_, record) =>
        record.application?.financeAmount != null
          ? `RM ${numberWithCommas(record.application?.financeAmount ?? 0)}`
          : "-",
    },
    {
      title: "Net Disbursement Amount",
      dataIndex: "disbursementAmount",
      key: "disbursementAmount",
      render: (_, record) =>
        `RM ${numberWithCommas(
          onCalculateNetDisbursementAmount(
            record.application?.financeAmount ?? 0,
            record.application?.productProcessingFee ?? 0,
            record.application?.productStampingDutyPercentage ?? 0,
            record.application?.productProcessingFeeToDiscount ?? 0,
            record.application?.loanTotalPremium ?? 0,
            record.application?.applicationStatus,
          ),
        )}`,
    },
    {
      title: "Status",
      dataIndex: "agreementStatus",
      key: "agreementStatus",
    },
    {
      title: "Posted Date",
      dataIndex: "disbursementPostedDate",
      key: "postedDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record.agreementStatus === "PENDING_DISBURSEMENT" ? (
          <UpdateDisbursementModal
            key={record.id}
            batchId={batchId}
            agreement={record}
            callback={updateDisbursementCallback}
          />
        ) : (
          <Button
            ghost
            danger
            key={record.id}
            onClick={() => showUnpostConfirmation(record.id)}
          >
            Unpost
          </Button>
        ),
    },
  ];

  const incompleteTableColumns: ColumnsType<any> = [
    {
      title: "Signed Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/agreement/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (text, record) =>
        record.application?.applicantFirstName
          ? record.application?.applicantFirstName
          : "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "user",
      key: "user",
      render: (text, record) =>
        record.application?.applicantNric
          ? record.application?.applicantNric
          : "-",
    },
    {
      title: "Approved Loan Amount",
      dataIndex: "systemLoanAmountSuggested",
      key: "systemLoanAmountSuggested",
      render: (text, record) =>
        `RM ${numberWithCommas(record.application?.financeAmount)}`,
    },
    {
      title: "Net Disbursement Amount",
      dataIndex: "disbursementAmount",
      key: "disbursementAmount",
      render: (text, record) =>
        `RM ${numberWithCommas(
          onCalculateNetDisbursementAmount(
            record.application?.financeAmount ?? 0,
            record.application?.productProcessingFee ?? 0,
            record.application?.productStampingDutyPercentage ?? 0,
            record.application?.productProcessingFeeToDiscount ?? 0,
            record.application?.loanTotalPremium ?? 0,
            record.application?.applicationStatus,
          ),
        )}`,
    },
    {
      title: "Status",
      dataIndex: "agreementStatus",
      key: "agreementStatus",
    },
    {
      title: "Posted Date",
      dataIndex: "disbursementPostedDate",
      key: "postedDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record.agreementStatus === "PENDING_DISBURSEMENT" ? (
          <UpdateDisbursementModal
            key={record.id}
            batchId={batchId}
            agreement={record}
            callback={updateDisbursementCallback}
          />
        ) : (
          <Button
            ghost
            danger
            key={record.id}
            onClick={() => showUnpostConfirmation(record.id)}
          >
            Unpost
          </Button>
        ),
    },
  ];

  const unpostAgreeementByAgreementId = async (params?: any) => {
    setTableLoading(true);
    await dispatch(
      unpostAgreeementByAgreementIdTrunk({
        ...params,
        batchId: batchId,
      }),
    )
      .unwrap()
      .then(async (res) => {
        await fetchDisbursementAgreements({
          ...initialParams,
          ...filterState,
        });
        message.success("Unposted Successfully");
        fetchDisbursementBatchDetails(batchId);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const inProcessDisbursementSelection = {
    selectedRowKeys: selectedInProcessDisbursementKey,
    onChange: (selectedRowKeys: React.Key[], selectedRows: Agreement[]) => {
      setSelectedInProcessDisbursementKey(selectedRowKeys);
      setSelectedInProcessDisbursement(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
      );
    },
  };

  const fetchDisbursementAgreements = async (params?: any) => {
    await dispatch(
      getDisbursementAgreementsByBatchIdTrunk({
        params,
        batchId: batchId,
      }),
    );
  };

  const fetchDisbursementBatchListing = async (params?: any) => {
    await dispatch(getDisbursementBatchListingThunk({ params }));
  };

  const fetchDisbursementBatchDetails = async (batchId?: string) => {
    await dispatch(getDisbursementBatchDetailsTrunk({ batchId }));
  };

  const updateDisbursementCallback = async () => {
    await fetchDisbursementBatchDetails(batchId);
    await fetchDisbursementAgreements({
      ...initialParams,
      ...filterState,
    });
  };

  const onSearch = (value: string) => {
    fetchDisbursementAgreements({
      ...initialParams,
      filters: { ...filterState, [searchFiled]: value },
    });
    setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchDisbursementAgreements({
      ...initialParams,
      ...filterState,
      pagination,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const showUnpostConfirmation = useCallback(
    (agreementId: string) => {
      confirm({
        title: "Warning",
        icon: <ExclamationCircleFilled />,
        content:
          "Are you sure unpost this application? Please check the account details before unpost.",
        okText: "Continue",
        onOk: async () => {
          await unpostAgreeementByAgreementId({ agreementId });
        },
        onCancel() {},
      });
    },
    [confirm],
  );

  const onPostDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setPostDate(dateString);
  };

  const onReset = () => {
    setSelectedInProcessDisbursement([]);
    setSelectedInProcessDisbursementKey([]);
    setPostDate("");
  };

  const onDownloadBankFiles = async () => {
    setDownloading(true);

    if (disbursementBatchDetails?.data?.status === "OPEN") {
      await dispatch(patchDisbursementBatchToInProcessTrunk({ batchId }))
        .unwrap()
        .then(() => {
          message.success("Downloading");
          fetchDisbursementBatchDetails(batchId);

          dispatch(getDisbursementListingSummaryTxtFileThunk({ batchId }))
            .unwrap()
            .then(downloadLink)
            .finally(() => setDownloading(false));

          dispatch(
            getDisbursementListingSummaryThunk({
              batchNo: disbursementBatchDetails?.data?.batchNo,
            }),
          )
            .unwrap()
            .then(downloadLink)
            .finally(() => setDownloading(false));
        });
    } else {
      dispatch(getDisbursementListingSummaryTxtFileThunk({ batchId }))
        .unwrap()
        .then(downloadLink)
        .finally(() => setDownloading(false));

      dispatch(
        getDisbursementListingSummaryThunk({
          batchNo: disbursementBatchDetails?.data?.batchNo,
        }),
      )
        .unwrap()
        .then(downloadLink)
        .finally(() => setDownloading(false));
    }
  };

  const onPostAgreements = async () => {
    setTableLoading(true);
    await dispatch(
      postAgreementsInBatchTrunk({
        agreementList: selectedInProcessDisbursementKey,
        postDate: moment(postDate).toISOString(),
        batchId,
      }),
    )
      .unwrap()
      .then(async (res) => {
        fetchDisbursementBatchDetails(batchId);
        fetchDisbursementAgreements({
          ...initialParams,
          ...filterState,
        });
        fetchDisbursementBatchListing(fetchDisbursementBatchListingParams);
        message.success("Posted Successfully");
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  useLifecycles(
    () => {
      fetchDisbursementAgreements({
        ...initialParams,
        ...filterState,
      });
      fetchDisbursementBatchDetails(batchId);
    },
    () => {
      dispatch(disbursementAgreementsStateReset());
    },
  );

  return (
    <>
      {contextHolder}
      <Helmet>
        <title>Disbursement Details - redCASH CEP</title>
      </Helmet>

      <PageHeader
        className="px-0 mb-2"
        title="Disbursement Details"
        onBack={() => navigate(-1)}
        subTitle={
          <Space size="middle">
            <span>{`Batch No. ${disbursementBatchDetails?.data?.batchNo}`}</span>
            {disbursementBatchDetails?.data?.batchNo && (
              <>
                <Tag
                  color={
                    disbursementBatchDetails?.data?.status === "IN_COMPLETE"
                      ? "error"
                      : disbursementBatchDetails?.data?.status === "COMPLETED"
                      ? "success"
                      : "default"
                  }
                >
                  {startCase(
                    disbursementBatchDetails?.data?.status === "IN_COMPLETE"
                      ? "INCOMPLETE"
                      : disbursementBatchDetails?.data?.status,
                  )}
                </Tag>
              </>
            )}
          </Space>
        }
      />

      <div className="flex justify-between mb-4">
        <Input.Group compact>
          <Select
            defaultValue="agreementNo"
            style={{ width: 160 }}
            onChange={(value) => setSearchFiled(value)}
          >
            <Option key="agreementNo" value="agreementNo">
              Agreement No.
            </Option>
            <Option key="applicationName" value="applicationName">
              Application Name
            </Option>
            <Option key="applicantIdNo" value="applicantIdNo">
              Applicant NRIC
            </Option>
          </Select>
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            style={{ width: 240 }}
          />
        </Input.Group>
        {disbursementBatchDetails?.data?.status !== "COMPLETED" && (
          <Button loading={downloading} onClick={() => onDownloadBankFiles()}>
            Download
          </Button>
        )}
      </div>

      <ConfigProvider theme={{ token: { colorPrimary: "#8F8F8F" } }}>
        <Table
          rowKey="id"
          className="min-w-fit"
          columns={
            disbursementBatchDetails?.data?.status === "IN_COMPLETE"
              ? incompleteTableColumns
              : disbursementBatchDetails?.data?.status === "COMPLETED"
              ? completedTableColumns
              : openInProcessTableColumns
          }
          pagination={tablePagination}
          dataSource={disbursementAgreements?.data?.data}
          loading={disbursementAgreements?.isLoading || tableLoading}
          onChange={handleTableChange}
          rowSelection={
            disbursementBatchDetails?.data?.status === "IN_PROCESS"
              ? inProcessDisbursementSelection
              : undefined
          }
          rowClassName={(record, index) =>
            disbursementBatchDetails?.data?.status === "IN_COMPLETE" &&
            record?.agreementStatus === "PENDING_DISBURSEMENT"
              ? "bg-red-200"
              : ""
          }
        />
      </ConfigProvider>
      {disbursementBatchDetails?.data?.status === "IN_PROCESS" &&
        selectedInProcessDisbursement.length > 0 && (
          <div
            className={`
          ${isMobile ? "w-full" : "w-[calc(100%-340px)]"}
          bg-white 
            shadow-multi h-16  px-4 
            fixed bottom-0 right-0 
            flex items-center
            justify-between`}
          >
            <Space>
              <span>Post Date:</span>
              <DatePicker onChange={onPostDateChange} allowClear={false} />
            </Space>

            <Space>
              <h4 className="text-lg my-0 mr-4">
                {selectedInProcessDisbursement.length} Selected
              </h4>
              <ConfigProvider theme={{ token: { colorPrimary: "#e40308" } }}>
                <Space>
                  <Button danger onClick={onReset}>
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    disabled={postDate ? false : true}
                    onClick={onPostAgreements}
                  >
                    Post Date
                  </Button>
                </Space>
              </ConfigProvider>
            </Space>
          </div>
        )}
    </>
  );
};

export default BatchDetailsPage;
