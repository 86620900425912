import { createAsyncThunk } from "@reduxjs/toolkit";
import smeAgreementService from "../smeAgreementService/smeAgreementService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getSmeAgreementListingThunk = createAsyncThunk(
  "smeAgreement/getSmeAgreementListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeAgreementListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingAttestationSmeAgreementListingThunk = createAsyncThunk(
  "smeAgreement/getPendingAttestationSmeAgreementListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getPendingAttestationSmeAgreementListing(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRequestedLiveAttestationSmeAgreementListingThunk =
  createAsyncThunk(
    "smeAgreement/getRequestedLiveAttestationSmeAgreementListing",
    async (requestData: any, thunkAPI) => {
      try {
        return await smeAgreementService.getRequestedLiveAttestationSmeAgreementListing(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const getCompletedLiveAttestationSmeAgreementListingThunk =
  createAsyncThunk(
    "smeAgreement/getCompletedLiveAttestationSmeAgreementListing",
    async (requestData: any, thunkAPI) => {
      try {
        return await smeAgreementService.getCompletedLiveAttestationSmeAgreementListing(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const getCompletedVideoAttestationSmeAgreementListingThunk =
  createAsyncThunk(
    "smeAgreement/getCompletedVideoAttestationSmeAgreementListing",
    async (requestData: any, thunkAPI) => {
      try {
        return await smeAgreementService.getCompletedVideoAttestationSmeAgreementListing(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const getSmeAgreementDetailsThunk = createAsyncThunk(
  "smeAgreement/getSmeAgreementDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeAgreementDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingSmeEStampListingThunk = createAsyncThunk(
  "smeAgreement/getSmeEStampListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeEStampListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getInProgressSmeEStampListingThunk = createAsyncThunk(
  "smeAgreement/getInProgressSmeEStampListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeEStampListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getCompletedSmeEStampListingThunk = createAsyncThunk(
  "smeAgreement/getCompletedSmeEstampListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeEStampListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const approveSmeEStampingThunk = createAsyncThunk(
  "smeAgreement/approveSmeEStamping",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.approveSmeEStamp(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmePendingSigningAgreementsThunk = createAsyncThunk(
  "smeAgreement/getSmePendingSigningAgreements",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeAgreementSigningList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeCompletedSigningThunk = createAsyncThunk(
  "smeAgreement/getSmeCompletedSigningAgreements",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeAgreementSigningList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
