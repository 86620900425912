import { Button, Form, Input, message, Modal, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import type { RcFile, UploadProps } from "antd/es/upload/interface";
import {
  createPreRecordedVideoThunk,
  deletePreRecordedVideoThunk,
  getPreRecordedVideoFilesThunk,
  updatePreRecordedVideoThunk,
} from "../../../services/preRecordedVideoService/preRecordedVideoThunk";
import { masterFileDeleteThunk, masterSetupFileUploadThunk } from "../../../services/fileService/fileThunk";
import { SmePreRecordedVideo } from "../../../features/smePreRecordedVideo/smePreRecordedVideoSlice";

export const SmePreRecordedVideoFormModal: React.FC<{
  smePreRecordedVideo?: SmePreRecordedVideo;
  videoLanguageVersion?: "EN" | "BM";
  callback?: () => void;
}> = ({ smePreRecordedVideo, videoLanguageVersion, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [loadingFile, setLoadingFile] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    setFileList([]);
    form.resetFields();
  };

  const removeFileFromList = async (file: any) => {
    const result = fileList.find(
      (uploadedFile: any) => uploadedFile?.uid === file.uid,
    );

    if (result) {
      const index = fileList.indexOf(result);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileList(newFileList);
      if (!file.uid.startsWith("rc-upload")) {
        await dispatch(masterFileDeleteThunk({ fileId: file.uid }));
        await dispatch(
          deletePreRecordedVideoThunk({
            preRecordedVideoId: smePreRecordedVideo?.id,
          }),
        );
        if (callback) callback();
      }
    }
  };

  const uploadProps: UploadProps = {
    maxCount: 1,
    onRemove: removeFileFromList,
    beforeUpload: (file: RcFile) => {
      const correctFileType = file.type === "video/mp4";

      if (!correctFileType) {
        message.error("Only allowed mp4!");
        return Upload.LIST_IGNORE;
      }

      if (fileList.length > 1) {
        message.error("Maximum 1 video only");
        return Upload.LIST_IGNORE;
      }

      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  const onFinish = async () => {
    if (fileList.length < 1) {
      return message.error("No file selected");
    }
    if (fileList.length > 1) {
      return message.error("Maximum 1 video only");
    }

    let fileType = "SME_PRE_RECORDED_VIDEO_EN";
    if (videoLanguageVersion === "BM") {
      fileType = "SME_PRE_RECORDED_VIDEO_BM";
    }

    await form.validateFields().then(async (values) => {
      setLoading(true);
      let entityId = "";

      if (smePreRecordedVideo != null) {
        entityId = smePreRecordedVideo.id;
        await dispatch(
          updatePreRecordedVideoThunk({
            id: smePreRecordedVideo.id,
            title: values.title,
            companyId: process.env.REACT_APP_COMPANY_ID,
          }),
        )
          .unwrap()
          .then(() => {
            handleCancel();
          });
      } else {
        await dispatch(
          createPreRecordedVideoThunk({
            companyId: process.env.REACT_APP_COMPANY_ID,
            title: values.title,
          }),
        )
          .unwrap()
          .then((res) => {
            entityId = res.id;
          });
      }

      if (fileList.length > 0 && fileList[0].uid.startsWith("rc-upload")) {
        const data = new FormData();
        data.append("entityId", entityId);
        data.append("fileType", fileType);
        data.append("file", fileList[0] as RcFile);

        await dispatch(masterSetupFileUploadThunk(data)).then((res) => {
          if (callback) callback();
        });
      }

      setLoading(false);
      handleCancel();
    });
  };

  useEffect(() => {
    if (smePreRecordedVideo?.id != null && isModalOpen) {
      setLoadingFile(true);
      dispatch(getPreRecordedVideoFilesThunk({ entityId: smePreRecordedVideo.id }))
        .unwrap()
        .then((res) => {
          if (res?.length > 0) {
            setFileList(
              res.map((file: any) => ({
                uid: file.id,
                name: file.filename.substring(file.filename.indexOf("-") + 1),
                status: "done",
              })) ?? [],
            );
            //   dispatch(getFileByIdThunk({ fileId: res[0].id }))
            //     .unwrap()
            //     .then((res) => setVideoFile(res));
          }
        })
        .finally(() => setLoadingFile(false));
    }
  }, [smePreRecordedVideo?.id, isModalOpen]);

  return (
    <>
      <Button
        onClick={showModal}
        loading={loading}
      >
        {smePreRecordedVideo != null ? "Update" : "Create"}
      </Button>
      <Modal
        title={
          smePreRecordedVideo != null
            ? "Update SME Pre Recorded Video"
            : "Create SME Pre Recorded Video"
        }
        okText={smePreRecordedVideo != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form
          form={form}
          initialValues={{ ...smePreRecordedVideo }}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please enter the title!" }]}
          >
            <Input />
          </Form.Item>
        </Form>

        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Upload Video (MP4)</Button>
        </Upload>
      </Modal>
    </>
  );
};