import { caseAssignmentAxiosInstance } from "../../../axios/caseAssignmentAxios";

const getAllTeam = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/caseAssignment/api/v1/team`;
  const res = await caseAssignmentAxiosInstance.get(url, { params });
  return res.data;
};

const createTeam = async (requestData: any) => {
  const url = `/caseAssignment/api/v1/team`;
  const res = await caseAssignmentAxiosInstance.post(url, requestData);
  return res.data;
};

const updateTeam = async (requestData: any) => {
  const { teamId, payload } = requestData;
  const url = `/caseAssignment/api/v1/team/id/${teamId}`;
  const res = await caseAssignmentAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteTeam = async (requestData: any) => {
  const { teamId } = requestData;
  const url = `/caseAssignment/api/v1/team/id/${teamId}`;
  const res = await caseAssignmentAxiosInstance.delete(url);
  return res.data;
};

const teamService = {
  getAllTeam,
  createTeam,
  updateTeam,
  deleteTeam,
};

export default teamService;
