import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const getNoticesHistoryListing = async (requestData: any) => {
  const { accountDetailsID } = requestData;
  const params = {
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };

  const url = `redCash/api/v1.0/noticesGeneration/getNoticeHistoryByAccountDetailsID/accountDetailsID/${accountDetailsID}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const noticesHistoryService = {
  getNoticesHistoryListing,
};

export default noticesHistoryService;
