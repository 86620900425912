import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const updateSmeLiveAttestationComplete = async (requestData: any) => {
  const { payload } = requestData;
  const url = `/redCash/api/v1.0/smeAttestation/done-live-attestation`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const smeAttestationService = {
  updateSmeLiveAttestationComplete,
};

export default smeAttestationService;
