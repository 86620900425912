import React, { useEffect } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { SME_ORGANIZATION_TYPE } from "../../../../../../enum/smeApplication";
import {
  getSmeApplicationDetailsThunk,
  updateSmeCompanyDisbursementInfoByIdThunk,
  updateSmeIndividualDisbursementInfoByIdThunk,
  updateSmePartnershipDisbursementInfoByIdThunk,
  updateSmeSolePropsDisbursementInfoByIdThunk,
} from "../../../../../../services/smeApplicationService/smeApplicationThunk";
import { getBankListThunk } from "../../../../../../services/dropdownParameterService/dropdownParameterThunk";
import jwt_decode from "jwt-decode";

export const EditDisbursementDetailsModal = () => {

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = React.useState(false);

  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const { bankState } = useSelector((state: RootState) => state.dropdownParameter);
  const bankOptions = bankState?.data.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const data = smeApplicationDetails?.data?.smeIndividualApplication ||
    smeApplicationDetails?.data?.smeSolePropApplication ||
    smeApplicationDetails?.data?.smePartnershipApplication;

  const companyData = smeApplicationDetails?.data?.smeCompanyApplication;
  const isCompany = smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.COMPANY;

  React.useEffect(() => {
    if (isOpen) {
      if (isCompany && companyData) {
        form.setFieldsValue({
          bankAccountName: companyData.bankAccountName,
          bankName: companyData.bankName,
          bankAccountNo: companyData.bankAccountNo,
          businessRegistrationNo: companyData.businessOldRegistrationNo,
          businessContactNo: companyData.businessContactNo,
        });
      } else if (data) {
        form.setFieldsValue({
          personalBankAccountName: data.personalBankAccountName,
          personalBankName: data.personalBankName,
          personalBankAccountNo: data.personalBankAccountNo,
          nric: data.nric,
          applicantPhoneNo: data.applicantPhoneNo,
        });
      }
    }
  }, [isOpen, data, companyData, form, isCompany]);

  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const handleSubmit = async (values: any) => {
    try {
      // Transform data based on type
      const formattedValues = isCompany ? {
        bankAccountName: values.bankAccountName,
        bankName: values.bankName,
        bankAccountNo: values.bankAccountNo,
        businessOldRegistrationNo: values.businessRegistrationNo,
        businessContactNo: values.businessContactNo,
      } : {
        personalBankAccountName: values.personalBankAccountName,
        personalBankName: values.personalBankName,
        personalBankAccountNo: values.personalBankAccountNo,
        nric: values.nric,
        applicantPhoneNo: values.applicantPhoneNo,
      };

      if (smeApplicationDetails?.data?.smePartnershipApplication) {
        await dispatch(updateSmePartnershipDisbursementInfoByIdThunk({
          ...formattedValues,
          id: smeApplicationDetails?.data?.smePartnershipApplication?.id,
        }));
      }

      if (smeApplicationDetails?.data?.smeCompanyApplication) {
        await dispatch(updateSmeCompanyDisbursementInfoByIdThunk({
          ...formattedValues,
          id: smeApplicationDetails?.data?.smeCompanyApplication?.id,
        }));
      }

      if (smeApplicationDetails?.data?.smeIndividualApplication) {
        await dispatch(updateSmeIndividualDisbursementInfoByIdThunk({
          ...formattedValues,
          id: smeApplicationDetails?.data?.smeIndividualApplication?.id,
        }));
      }

      if (smeApplicationDetails?.data?.smeSolePropApplication) {
        await dispatch(updateSmeSolePropsDisbursementInfoByIdThunk({
          ...formattedValues,
          id: smeApplicationDetails?.data?.smeSolePropApplication?.id,
        }));
      }

      dispatch(getSmeApplicationDetailsThunk({
        applicationId: smeApplicationDetails?.data?.id,
      }));
      onClose();
    } catch (error) {
      console.error("Error updating disbursement info:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getBankListThunk());
    }
  }, [isOpen]);

  return (
    <>
      {
        (currentRole === 'VERIFIER' || currentRole === 'VERIFIER_2' || currentRole === 'SUPER_ADMIN') && (
          <Button onClick={() => setIsOpen(true)}>Edit</Button>
        )
      }
      <Modal
        title="Edit Disbursement Information"
        open={isOpen}
        onCancel={onClose}
        onOk={() => form.submit()}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="mt-4"
        >
          {isCompany ? (
            // Company Disbursement Form
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item
                name="bankAccountName"
                label="Company Account Name"
                rules={[{ required: true, message: "Please enter company account name" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="businessRegistrationNo"
                label="Business Registration No."
                rules={[{ required: true, message: "Please enter business registration number" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="bankName"
                label="Company Bank Name"
                rules={[{ required: true, message: "Please enter company bank name" }]}
              >
                <Select
                  loading={bankState.isLoading}
                  options={bankOptions}
                />
              </Form.Item>
              <Form.Item
                name="bankAccountNo"
                label="Company Account Number"
                rules={[{ required: true, message: "Please enter company account number" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="businessContactNo"
                label="Business Contact No"
                rules={[{ required: true, message: "Please enter business contact no" }]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : (
            // Personal Disbursement Form
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item
                name="personalBankAccountName"
                label="Personal Account Name"
                rules={[{ required: true, message: "Please enter personal account name" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="nric"
                label="NRIC"
                rules={[{ required: true, message: "Please enter NRIC" }]}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="personalBankName"
                label="Bank Name"
                rules={[{ required: true, message: "Please enter bank name" }]}
              >
                <Select
                  loading={bankState.isLoading}
                  options={bankOptions}
                />
              </Form.Item>
              <Form.Item
                name="personalBankAccountNo"
                label="Personal Account Number"
                rules={[{ required: true, message: "Please enter account number" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="applicantPhoneNo"
                label="Phone No"
                rules={[{ required: true, message: "Please enter phone no" }]}
              >
                <Input />
              </Form.Item>
            </div>
          )}
        </Form>
      </Modal>
    </>
  );
};