import { createSlice } from "@reduxjs/toolkit";
import { getListingNoticesHistoryThunk } from "../../services/noticesHistoryService/noticesHistoryThunk";

export type noticesHistoryData = {
  id: string;
  batchId: string;
  accountDetailsId: string;
  outstandingAmount: number;
  otherCharges: number;
  printedDate?: string;
  status: string;
  deleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type noticesHistoryResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: noticesHistoryData[];
};

export type noticesHistoryState = {
  noticesHistory: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: noticesHistoryResponse;
  };
};

const initialState: noticesHistoryState = {
  noticesHistory: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
};
export const noticesHistorySlice = createSlice({
  name: "noticesHistory",
  initialState,
  reducers: {
    noticesHistoryReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListingNoticesHistoryThunk.pending, (state) => {
        state.noticesHistory.isLoading = true;
      })
      .addCase(getListingNoticesHistoryThunk.fulfilled, (state, action) => {
        state.noticesHistory.isLoading = false;
        state.noticesHistory.data = action.payload;
      })
      .addCase(getListingNoticesHistoryThunk.rejected, (state, action) => {
        state.noticesHistory.isLoading = false;
        state.noticesHistory.isError = true;
        state.noticesHistory.errorMessage = action.payload;
      });
  },
});

export const { noticesHistoryReset } = noticesHistorySlice.actions;

export default noticesHistorySlice.reducer;
