import "./index.less";
import {
  Button,
  ConfigProvider,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  UploadFile,
} from "antd";
import { RcFile } from "antd/es/upload";
import startCase from "lodash.startcase";
import React, { Key, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLifecycles } from "react-use";

import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import RefundUploadedRefundDocuments from "../../../../components/RefundFileListModal";
import RequestRefund from "../../../../components/RequestRefund";
import {
  REFUND_FINANCE_BATCH_STATUS,
  REFUND_FINANCE_STATUS,
  REFUND_STATUS,
} from "../../../../enum/refund";
import { Refund, RefundFinance } from "../../../../features/refund/refundSlice";
import { numberWithCommas } from "../../../../helpers/number";
import { waiverFileUploadThunk } from "../../../../services/fileService/fileThunk";
import {
  createRefundFinanceBatchThunk,
  downloadRefundPaymentFileThunk,
  getRefundFinanceBatchByIdThunk,
  getRefundFinanceBatchThunk,
  getRefundFinanceListThunk,
  getRefundFinanceSummaryThunk,
  getUnmatchedRefundFinanceBatchByIdThunk,
  unPostRefundFinanceByIdThunk,
  updateRefundDetailsThunk,
  updateRefundFinanceBatchIsDownloadedThunk,
  updateRefundFinanceBatchThunk,
  updateRefundFinanceStatusThunk,
  updateRefundStatusThunk,
  updateUnmatchedRefundFinanceBatchThunk,
} from "../../../../services/refundService/refundThunk";
import {
  downloadUnmatchedRefundPaymentFileThunk,
  updateUnmatchedRefundDetailsThunk,
  updateUnmatchedRefundFinanceStatusThunk,
  updateUnmatchedRefundStatusThunk,
} from "../../../../services/unmatchedService/unmatchedThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";
import AccountEnquiryModal from "../../../AccountEnquiry/AccountEnquiryModal";
import RefundDetailsModal from "../refundDetailsModal";

import type { ColumnsType } from "antd/es/table";
import { downloadLink } from "../../../../helpers/downloadLink";
import { saveBlobAsPDF } from "../../../../utils/file";
import { createRefundFinanceBatch } from "../../../../services/refundService/refundService";
import SelectPostDateModal from "../../RefundFinanceListing/components/selectPostDateModal";
import { PageHeader } from "@ant-design/pro-components";
import UpdateRefundFinanceModal from "./updateRefundFinanceBatchDetails";
import jwt_decode from "jwt-decode";
import { Role } from "../../../../enum/roles";

const { Search } = Input;

type RefundDetailProps = {
  id?: any;
  dataSource?: any;
  status?: string;
  viewMode?: boolean;
  isUnmatched?: boolean;
  callback?: any;
};

const RefundFinanceBatchDetails: React.FC<RefundDetailProps> = (props) => {
  const { dataSource, status, isUnmatched, callback } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { batchId } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refundDetails, setRefundDetails] = useState<RefundFinance>();

  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const { refundFinanceBatchDetails, refundFinance } = useSelector(
    (state: RootState) => state.refund,
  );

  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [isPostDateModalVisible, setIsPostDateModalVisible] = useState(false);
  const [searchField, setSearchField] = useState("agreementNo");
  const [postDate, setPostDate] = useState<string>();
  const [{ confirm }, contextHolder] = Modal.useModal();
  const [unPostLoading, setUnPostLoading] = useState(false);
  const [selectedInProcessRefundCase, setSelectedInProcessRefundCase] =
    useState<any[]>([]);
  const [selectedInProcessRefundCaseKey, setSelectedInProcessRefundCaseKey] =
    useState<Key[]>([]);
  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const tablePagination = {
    total: dataSource?.data?.searchTotal ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };
  const [form] = Form.useForm();

  const openModal = (data: any) => {
    console.log(data);
    setRefundDetails(data);
    console.log(data);
    form.setFieldsValue({
      agreementNo:
        type === "unmatched" ? data.agreementNo : data.refund?.agreementNo,
      contactPhoneNo:
        type === "unmatched"
          ? data.unmatchedRefund.contactPhoneNo
          : data.refund?.contactPhoneNo,
      bank:
        type === "unmatched" ? data.unmatchedRefund.bank : data.refund?.bank,
      bankAccountNo:
        type === "unmatched"
          ? data.unmatchedRefund.bankAccountNo
          : data.refund?.bankAccountNo,
      extraPaymentAmount:
        type === "unmatched"
          ? data.unmatchedRefund.unmatchedAmount
          : data.refund?.extraPaymentAmount,
      refundAdminCharge:
        type === "unmatched"
          ? data.unmatchedRefund.refundAdminCharge
          : data.refund?.refundAdminCharge,
      refundAmount:
        type === "unmatched"
          ? data.unmatchedRefund.refundAmount
          : data.refund?.refundAmount,
      remark:
        type === "unmatched"
          ? data.unmatchedRefund.remark
          : data.refund?.remark,
      paymentMethod:
        type === "unmatched"
          ? data.unmatchedRefund.paymentMethod
          : data.refund?.paymentMethod,
      unmatchedAmount:
        type === "unmatched"
          ? data.unmatchedRefund.unmatchedAmount
          : data.refund?.extraPaymentAmount,
    });
    setIsModalOpen(true);
  };

  const fetchRefundFinanceBatch = async (params?: any) => {
    await dispatch(
      getRefundFinanceBatchThunk(
        params ?? {
          ...initialParams,
          type: type ?? "refund",
        },
      ),
    );
  };

  const onPostDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setPostDate(dateString);
  };

  const handleDownloadPaymentFile = async (data: any) => {
    setDownloading(true);
    setRefundDetails(data);

    message.loading("Downloading", 1);

    if (type === "unmatched") {
      // Download payment file
      await dispatch(
        downloadUnmatchedRefundPaymentFileThunk({
          id: refundFinanceBatchDetails.data.id,
        }),
      )
        .unwrap()
        .then(downloadLink);

      // Download summary file
      await dispatch(
        getRefundFinanceSummaryThunk({
          id: refundFinanceBatchDetails.data.id,
          type: type ?? "refund",
        }),
      )
        .unwrap()
        .then(async (response) => {
          const filename = `REFUND_FINANCE_SUMMARY_${data.agreementNo}.pdf`;
          saveBlobAsPDF(response, filename);
          await dispatch(
            updateUnmatchedRefundFinanceBatchThunk({
              id: refundFinanceBatchDetails.data.id,
              status: REFUND_FINANCE_BATCH_STATUS.INCOMPLETE,
            }),
          );
        })
        .finally(async () => {
          setDownloading(false);
          await fetchRefundFinanceBatch({
            type: type ?? "refund",
          });
        });

      await dispatch(
        getRefundFinanceBatchByIdThunk({
          id: batchId,
          type: type ?? "refund",
        }),
      ).then((res) => {
        console.log(res);
        message.success("Updated");
      });
    } else {
      // Download payment file
      await dispatch(
        downloadRefundPaymentFileThunk({
          id: refundFinanceBatchDetails.data.id,
        }),
      )
        .unwrap()
        .then(downloadLink);

      // Download summary file
      await dispatch(
        getRefundFinanceSummaryThunk({
          id: refundFinanceBatchDetails.data.id,
        }),
      )
        .unwrap()
        .then(async (response) => {
          const filename = `REFUND_FINANCE_SUMMARY_${refundFinanceBatchDetails.data.batchNo}.pdf`;
          saveBlobAsPDF(response, filename);
          await dispatch(
            updateRefundFinanceBatchIsDownloadedThunk({
              id: refundFinanceBatchDetails.data.id,
            }),
          );
        })
        .finally(async () => {
          setDownloading(false);
          await fetchRefundFinanceBatch({
            type: type ?? "refund",
          });
        });

      await dispatch(
        getRefundFinanceBatchByIdThunk({
          id: batchId,
          type: type ?? "refund",
        }),
      ).then((res) => {
        console.log(res);
        message.success("Updated");
      });
    }
  };

  const unPostRefundFinanceByRefundFinanceId = async (
    refundFinanceId: string,
  ) => {
    const payload: any = [];
    payload.push({
      id: refundFinanceId,
    });
    setUnPostLoading(true);
    await dispatch(unPostRefundFinanceByIdThunk(payload))
      .unwrap()
      .then(async (res) => {
        await fetchRefundFinanceBatch({
          type: type ?? "refund",
        });

        await fetchRefundFinanceBatchDetails();

        message.success("Refund finance unposted successfully");
      })
      .finally(() => {
        setUnPostLoading(false);
      });
  };

  const showUnPostConfirmation = useCallback(
    (refundFinanceId: string) => {
      confirm({
        title: "Warning",
        icon: <ExclamationCircleFilled />,
        content:
          "Are you sure unpost this refund finance? Please check the refund finance before unpost.",
        okText: "Continue",
        onOk: async () => {
          await unPostRefundFinanceByRefundFinanceId(refundFinanceId);
        },
        onCancel() {},
      });
    },
    [confirm],
  );

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Approval Date",
      dataIndex: "postDate",
      key: "postDate",
      render: (text: string) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No.",
      dataIndex: "agreementNo",
      key: "agreementNo",
    },
    {
      title: "Recipient NRIC",
      dataIndex: "applicantNric",
      key: "applicantNric",
    },
    {
      title: "Refund Amount",
      dataIndex: "payAmount",
      key: "payAmount",
      render: (text, record) => numberWithCommas(text) ?? "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => text ?? "-",
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            <Space>
              <Button onClick={() => openModal(record)}>View</Button>
              {record.status === "REFUNDED" ? (
                <Button
                  onClick={() => showUnPostConfirmation(record.id)}
                  type="primary"
                  danger
                  disabled={currentRole !== Role.SUPER_ADMIN}
                >
                  Unpost
                </Button>
              ) : (
                <UpdateRefundFinanceModal
                  refundFinanceId={record.id}
                  refundFinanceStatus={record.status}
                  actionType={"Referback"}
                  callback={(res: any) => {
                    fetchRefundFinanceBatchDetails();
                  }}
                />
              )}
            </Space>
          </>
        );
      },
    },
  ];

  const fetchRefundFinanceBatchDetails = async (params?: any) => {
    await dispatch(
      getRefundFinanceBatchByIdThunk(
        params
          ? {
              params: {
                ...params,
              },
              id: batchId,
              type: type ?? "refund",
            }
          : {
              id: batchId,
              type: type ?? "refund",
            },
      ),
    ).then((res) => {
      console.log(res);
    });
  };

  useEffect(() => {
    fetchRefundFinanceBatchDetails();
    console.log(batchId);
  }, [batchId]);

  const handleTableChange = (pagination: TablePaginationConfig) => {};

  const onSearch = (value: string) => {
    if (value === "") {
      fetchRefundFinanceBatchDetails();
      return;
    }

    const search = {
      [searchField]: value,
      ...initialParams.search,
    };

    const params = {
      ...initialParams,
      search,
      type: type ?? "refund",
    };

    fetchRefundFinanceBatchDetails(params);
  };

  const handlePostDateSubmit = async () => {
    setIsPostDateModalVisible(false);
    if (type === "unmatched") {
      await dispatch(
        updateUnmatchedRefundFinanceBatchThunk({
          id: refundFinanceBatchDetails.data.id,
          status: REFUND_FINANCE_BATCH_STATUS.COMPLETED,
          postDate,
        }),
      ).finally(async () => {
        await fetchRefundFinanceBatch({
          type: type ?? "refund",
        });

        navigate({
          pathname: `/refund/finance-listing`,
          search: `?type=${type}&tab=created`,
        });
      });
    } else {
      await dispatch(
        updateRefundFinanceBatchThunk({
          id: refundFinanceBatchDetails.data.id,
          status: REFUND_FINANCE_BATCH_STATUS.COMPLETED,
          postDate,
          body: selectedInProcessRefundCase,
        }),
      ).finally(async () => {
        await fetchRefundFinanceBatch({
          type: type ?? "refund",
        });

        navigate({
          pathname: `/refund/finance-listing`,
          search: `?type=${type}&tab=created`,
        });
      });
    }
  };

  const onReset = () => {
    setSelectedInProcessRefundCase([]);
    setSelectedInProcessRefundCaseKey([]);
    setPostDate("");
  };

  const inProcessRefundCaseSelection = {
    selectedRowKeys: selectedInProcessRefundCaseKey,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedInProcessRefundCaseKey(selectedRowKeys);
      setSelectedInProcessRefundCase(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
      );
    },

    getCheckboxProps: (record: any) => ({
      disabled: record.status === "REFUNDED", // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <>
      {contextHolder}
      <Helmet>
        <title>Refund Finance Batch Details - redCASH CEP</title>
      </Helmet>

      <PageHeader
        className="px-0 mb-2"
        title="Refund finance Batch Details"
        onBack={() => navigate(-1)}
        subTitle={
          <Space size="middle">
            <span>{`Batch No. ${refundFinanceBatchDetails?.data?.batchNo}`}</span>
            {refundFinanceBatchDetails?.data?.batchNo && (
              <>
                <Tag
                  color={
                    refundFinanceBatchDetails?.data?.status === "INCOMPLETE"
                      ? "error"
                      : refundFinanceBatchDetails?.data?.status === "COMPLETED"
                      ? "success"
                      : "default"
                  }
                >
                  {startCase(
                    refundFinanceBatchDetails?.data?.status === "INCOMPLETE"
                      ? "INCOMPLETE"
                      : refundFinanceBatchDetails?.data?.status,
                  )}
                </Tag>
              </>
            )}
          </Space>
        }
      />

      <div className="flex justify-between mb-4">
        <Input.Group compact>
          <Select
            defaultValue="agreementNo"
            style={{ width: 200 }}
            onChange={(value) => setSearchField(value)}
            options={[
              { value: "agreementNo", label: "Agreement No" },
              { value: "applicantNric", label: "Recipient NRIC" },
            ]}
          />
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Input.Group>

        {refundFinanceBatchDetails.data.status !==
          REFUND_FINANCE_BATCH_STATUS.COMPLETED && (
          <>
            <Button
              onClick={() =>
                handleDownloadPaymentFile(refundFinanceBatchDetails)
              }
              loading={downloading}
            >
              Download
            </Button>
          </>
        )}
      </div>
      <br />
      <ConfigProvider theme={{ token: { colorPrimary: "#8F8F8F" } }}>
        <Table
          rowKey="id"
          className="min-w-fit"
          columns={columns}
          pagination={tablePagination}
          dataSource={
            type === "unmatched"
              ? refundFinanceBatchDetails.data.unmatchedRefundFinance
              : refundFinanceBatchDetails.data.refundFinance ?? []
          }
          loading={refundFinanceBatchDetails?.isLoading}
          onChange={handleTableChange}
          rowSelection={
            refundFinanceBatchDetails.data.isDownloaded
              ? inProcessRefundCaseSelection
              : undefined
          }
          rowClassName={(record, index) =>
            refundFinanceBatchDetails?.data?.status === "INCOMPLETE" &&
            record.status === "INCOMPLETE"
              ? "bg-red-200"
              : ""
          }
        />
      </ConfigProvider>

      {refundFinanceBatchDetails.data.isDownloaded &&
        selectedInProcessRefundCase.length > 0 && (
          <div
            className={`
          ${"w-[calc(100%-340px)]"}
          bg-white 
            shadow-multi h-16  px-4 
            fixed bottom-0 right-0 
            flex items-center
            justify-between`}
          >
            <Space>
              <span>Post Date:</span>
              <DatePicker onChange={onPostDateChange} allowClear={false} />
            </Space>
            <Space>
              <ConfigProvider theme={{ token: { colorPrimary: "#e40308" } }}>
                <Space>
                  <Button danger onClick={onReset}>
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    disabled={postDate ? false : true}
                    onClick={handlePostDateSubmit}
                  >
                    Post Date
                  </Button>
                </Space>
              </ConfigProvider>
            </Space>
          </div>
        )}

      <RefundDetailsModal
        form={form}
        viewMode={true}
        loading={loading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        viewFileID={
          type === "unmatched"
            ? refundDetails?.unmatchedRefundId
            : refundDetails?.refund?.id
        }
        submitButtons={[<Space key={"space"}></Space>]}
        type={type ?? "refund"}
      />
    </>
  );
};

export default RefundFinanceBatchDetails;
