import "../index.less";

import { Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLifecycles } from "react-use";

import CustomerFormModal from "../../../components/CustomerFormModal";
import InfoGrid from "../../../components/InfoGrid";
import { applicationDetailsStateReset } from "../../../features/application/applicationSlice";
import { getApplicationCreditRecordThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import { getNricInfo } from "../../../utils/nric.util";
import { getAccountDetailsThunk } from "../../../services/collectionService/collectionThunk";
import {
  getGrossSalaryRangeListThunk,
  getRaceListThunk,
} from "../../../services/dropdownParameterService/dropdownParameterThunk";
import React, { useEffect, useState } from "react";
import { LANGUAGE } from "../../../features/config/configSlice";
import { trimPhoneNo } from "../../../utils/number.util";
import { getFormattedAddress } from "../../../utils/string.util";
import { SME_ORGANIZATION_TYPE } from "../../../enum/smeApplication";
import {
  EditApplicationInfoModal
} from "../../SmeApplication/ApplicationDetails/AdditionalDetailsPanel/ApplicationInfoDetailsTab/EditApplicationInfo";

const SmeCustomerDetails = () => {
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const data = accountEnquiry?.data?.accountDetails?.smeApplication

  const customerInfo1 = [
    {
      title: "Full Name",
      value: data?.name,
    },
    {
      title: "Nationality",
      value: data?.nationality,
    },
    {
      title: "Race",
      value: data?.applicantRace,
    },
  ];

  const customerInfo2 = [
    {
      title: "Identity Card Type",
      value: data?.icType,
    },
    {
      title: "NRIC",
      value: data?.nric,
    },
    {
      title: "Date Of Birth",
      value: displayDate(data?.dateOfBirth),
    },
    {
      title: "Age",
      value: data?.nric ? getNricInfo(data?.nric).age : "-",
    },
  ];

  const customerInfo3 = [
    {
      title: "Email Address",
      value: data?.emailAddress,
    },
    {
      title: "Contact No.",
      value: trimPhoneNo(data?.applicantPhoneNo),
    },
    {
      title: "Occupation Type",
      value: data?.occupationType,
    },
  ];

  const customerInfo4 = [
    {
      title: "NRIC Address",
      value: getFormattedAddress(data?.nricAddress1, data?.nricAddress2, data?.nricAddress3),
    },
    {
      title: "NRIC Postcode",
      value: data?.nricPostcode,
    },
    {
      title: "NRIC City",
      value: data?.nricCity,
    },
    {
      title: "NRIC State",
      value: data?.nricState,
    },
    {
      title: "Home Address",
      value: getFormattedAddress(data?.homeAddress1, data?.homeAddress2, data?.homeAddress3),
    },
    {
      title: "Home Postcode",
      value: data?.homePostcode,
    },
    {
      title: "Home City",
      value: data?.homeCity,
    },
    {
      title: "Home State",
      value: data?.homeState,
    },
    {
      title: "Mailing Address",
      value: getFormattedAddress(data?.mailingAddress1, data?.mailingAddress2, data?.mailingAddress3),
    },
    {
      title: "Mailing Postcode",
      value: data?.mailingPostcode,
    },
    {
      title: "Mailing City",
      value: data?.mailingCity,
    },
    {
      title: "Mailing State",
      value: data?.mailingState,
    }
  ];

  const employmentTypeInfo = [
    {
      title: "Employment Type",
      value: data?.applicantEmploymentType,
    },
  ]

  const employmentInfo = [
    ...(accountEnquiry?.data?.accountDetails?.organizationType === 'INDIVIDUAL' ? employmentTypeInfo : []),
    {
      title: "Name of Employer / Nature of Business",
      value: data?.employerName,
    },
  ];

  const emergencyInfo = [
    {
      title: "Emergency Name",
      value: data?.emergencyContactPersonName,
    },
    {
      title: "Emergency Contact Number",
      value: trimPhoneNo(data?.emergencyContactPersonPhoneNo),
    },
    {
      title: "Emergency Email",
      value: data?.emergencyContactEmail,
    },
    {
      title: "Relationship",
      value: data?.emergencyContactRelationship,
    },
  ];

  return (
    <div className="info-container">
      <InfoGrid
        title="Customer Information"
        data={customerInfo1}
      />
      <br /><br />
      <InfoGrid data={customerInfo2} />
      <br /><br />
      <InfoGrid data={customerInfo3} />
      <br /><br />
      <InfoGrid data={customerInfo4} />
      <br /><br />
      <InfoGrid title="Employment Information" data={employmentInfo} />
      {accountEnquiry?.data?.accountDetails?.organizationType !== SME_ORGANIZATION_TYPE.COMPANY && (
        <>
          <br /><br />
          <InfoGrid title="Emergency Information" data={emergencyInfo} />
        </>
      )}
    </div>
  );
};

export default SmeCustomerDetails;
