import { Button, Input, Select, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";
import { numberWithCommas } from "../../../helpers/number";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate, displayDateAndTime } from "../../../utils/datetime.util";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { getAdminsThunk } from "../../../services/adminService/adminThunk";
import { getSmeApplicationsListingThunk } from "../../../services/smeApplicationService/smeApplicationThunk";
import { SmeApplication, smeApplicationStateReset } from "../../../features/smeApplication/smeApplicationSlice";
import { SME_APPLICATION_STATUS } from "../../../enum/smeApplicationStatus";

const { Search } = Input;

const SmeFirstApprovalReviewPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { smeApplicationsList } = useSelector(
    (state: RootState) => state.smeApplication,
  );
  const { admins } = useSelector((state: RootState) => state.admin);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {
        status: SME_APPLICATION_STATUS.SUBMITTED_FOR_APPROVAL_1,
      },
      filters: {},
    }),
    [],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<SmeApplication>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("applicationNo");
  const [searchValue, setSearchValue] = useState("");

  const tablePagination = {
    total: smeApplicationsList?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Application Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortOrder:
        sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Submission Date & Time",
      dataIndex: "submissionDate",
      key: "submissionDate",
      width: 120,
      sorter: (a, b) => a.submissionDate - b.submissionDate,
      sortOrder:
        sortedInfo.columnKey === "submissionDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Submission For Approval Date & Time",
      dataIndex: "submissionDateToApprover1",
      key: "submissionDateToApprover1",
      width: 120,
      sorter: (a, b) => a.submissionDateToApprover1 - b.submissionDateToApprover1,
      sortOrder:
        sortedInfo.columnKey === "submissionDateToApprover1" ? sortedInfo.order : null,
      render: (text) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Application No.",
      dataIndex: "applicationNo",
      key: "applicationNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/sme/application/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Organization Type",
      dataIndex: "organizationType",
      key: "organizationType",
      render: (text: string) => text ?? "-",
    },
    {
      title: "Applicant Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        record?.smeIndividualApplication?.name ?? record?.smeSolePropApplication?.name ?? record?.smePartnershipApplication?.companyName ?? record?.smeCompanyApplication?.companyName ?? "-"
      ),
    },
    {
      title: "Applied Loan Amount",
      dataIndex: "appliedLoanAmount",
      key: "appliedLoanAmount",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Finance Amount",
      key: "financeAmount",
      dataIndex: "financeAmount",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Credit Bucket",
      dataIndex: "bucket",
      key: "bucket",
    },
    ...(process.env.REACT_APP_CASE_ASSIGNMENT_ENABLED === "YES"
      ? [
        {
          title: "Case Assignment",
          key: "caseAssignment",
          children: [
            {
              title: "Bucket",
              dataIndex: ["caseAssignment", "bucket", "bucketName"],
              key: "caseAssignmentBucketName",
              render: (bucketName: any) => {
                return bucketName ?? "-";
              },
            },
            {
              title: "Group",
              dataIndex: ["caseAssignment", "group", "groupCode"],
              key: "caseAssignmentBucketName",
              render: (groupCode: any) => {
                return groupCode ?? "-";
              },
            },
            {
              title: "User",
              dataIndex: ["caseAssignment", "user", "userId"],
              key: "caseAssignmentUserId",
              render: (adminId: string) => {
                const admin = admins.data.find(
                  (admin) => admin.id === adminId,
                );
                return admin?.username ?? "-";
              },
            },
          ],
        },
      ]
      : []),
  ];

  const fetchApplications = async (params?: any) => {
    await dispatch(getSmeApplicationsListingThunk(params));
  };

  const fetchAdmins = async (params?: any) => {
    await dispatch(getAdminsThunk({ params }));
  };

  const resetApplicationState = async () => {
    await dispatch(smeApplicationStateReset());
  };

  const onSearch = (value: string) => {
    fetchApplications({
      ...initialParams,
      filters: { ...filterState },
      ...(value ? { search: { ...initialParams.search, [searchFiled]: value.toUpperCase() } } : {}),
    });
    setFilterState({ ...initialParams.filters });
  };

  const handleTableChange: TableProps<SmeApplication>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
          ...initialParams,
          ...filterState,
          pagination,
          sortField: sorter?.columnKey,
          sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
        }
        : {
          ...initialParams,
          ...filterState,
          pagination,
          sortField: sorter?.columnKey,
          sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          search: {
            [searchFiled]: searchValue.toUpperCase(),
          },
        };

    fetchApplications(param);
    setSortedInfo(sorter as SorterResult<SmeApplication>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    resetApplicationState();
    fetchApplications(initialParams);
    fetchAdmins({
      pagination: {
        current: 1,
        pageSize: 10000, // get all admins
      },
    });
  }, [initialParams]);

  return (
    <>
      <Helmet>
        <title>Approval Review - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Approval Review" />
      <Input.Group compact>
        <Select
          defaultValue="applicationNo"
          style={{ width: 200 }}
          onChange={(value) => setSearchFiled(value)}
          options={[
            { value: "applicationNo", label: "Application No" },
            { value: "name", label: "Applicant Name" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={smeApplicationsList.data.data}
        loading={smeApplicationsList.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default SmeFirstApprovalReviewPage;
