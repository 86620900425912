import { createSlice } from "@reduxjs/toolkit";
import { getUsersCaseCountThunk } from "../../services/caseAssignmentService/caseAssignmentService/caseAssignmentThunk";

export type Case = {
  id: string;
  applicationId: string;
  assignedBucketId: string;
  assignedGroupId: string;
  assignedUserId: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Case_ = {
  assignedDateCount: {
    today: number;
    "1-3": number;
    "4-6": number;
    ">7": number;
  }[];
  productivity: { assignedUserId: string; decision: string; count: number }[];
  previousCompleteCount: number;
  totalCompleteCount: number;
};

export type UserListing = {
  id: string;
  usertId: string;
  count: number;
  Case: Case[];
  Case_?: Case_; // for overview dashboard
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type UserListingResponse = {
  data: UserListing[];
  total: number;
};

export type UserListingState = {
  userListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: UserListingResponse;
  };
};

const initialState: UserListingState = {
  userListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },
};

export const caseAssignmentSlice = createSlice({
  name: "caseAssignment",
  initialState,
  reducers: {
    caseAssignmentReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersCaseCountThunk.pending, (state) => {
        state.userListingState.isLoading = true;
      })
      .addCase(getUsersCaseCountThunk.fulfilled, (state, action) => {
        state.userListingState.isLoading = false;
        state.userListingState.data = action.payload;
      })
      .addCase(getUsersCaseCountThunk.rejected, (state, action) => {
        state.userListingState.isLoading = false;
        state.userListingState.isError = true;
        state.userListingState.errorMessage = action.payload;
      });
  },
});

export const { caseAssignmentReset } = caseAssignmentSlice.actions;
export default caseAssignmentSlice.reducer;
