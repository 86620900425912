import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../../helpers/errorMessage";
import ruleParameterService from "./ruleParameterService";

export const getAllRuleParameterThunk = createAsyncThunk(
  "ruleParameter/getAll",
  async (requestData: any, thunkAPI) => {
    try {
      return await ruleParameterService.getAllRuleParameter(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createRuleParameterThunk = createAsyncThunk(
  "ruleParameter/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await ruleParameterService.createRuleParameter(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRuleParameterThunk = createAsyncThunk(
  "ruleParameter/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await ruleParameterService.updateRuleParameter(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteRuleParameterThunk = createAsyncThunk(
  "ruleParameter/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await ruleParameterService.deleteRuleParameter(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
