import { createSlice } from "@reduxjs/toolkit";
import { getWiseAiResultListingThunk } from "../../services/ekycService/ekycThunk";

export type EkycDetailsType = {
  id: string;
  userId: string;
  name: string;
  icMatchWithDatabase: boolean;
  mykadVsFaceConfidence: number;
  faceConfidence: number;
  livenessDetected: boolean;
  sessionId: string;
  ekycSuccess: boolean;
  isPending: boolean;
  mykadFrontIsValid: boolean;
  mykadBackIsValid: boolean;
  mykadVsFaceIsMatch: boolean;
  faceIsMatch: boolean;
  frontIdFraudDetected: boolean;
  frontIsValid: boolean;
  frontIcNumber: string;
  backIdFraudDetected: boolean;
  backIsValid: boolean;
  backIcNumber: string;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  birthOriginalString: string;
  birthDateISO: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  fullAddress: string;
  religion: string;
  gender: string;
  wiseAiResponse: string | null;
  ekycCompanyId: string;
  wiseAiFrontLandmarkId: string;
  wiseAiBackLandmarkId: string;
  wiseAiFrontOcrScoreId: string;
  wiseAiBackOcrScoreId: string;
  wiseAiFrontGlareId: string;
  wiseAiBackGlareId: string;
  createdAt: string;
  updatedAt: string;
};

export type EkycReportState = {
  ekycReportListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: EkycDetailsType[];
    };
  };
};

const initialState: EkycReportState = {
  ekycReportListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
};

export const ekycSlice = createSlice({
  name: "ekyc",
  initialState,
  reducers: {
    ekycStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWiseAiResultListingThunk.pending, (state) => {
        state.ekycReportListingState.isLoading = true;
      })
      .addCase(getWiseAiResultListingThunk.fulfilled, (state, action) => {
        state.ekycReportListingState.isLoading = false;
        state.ekycReportListingState.data = action?.payload;
      })
      .addCase(getWiseAiResultListingThunk.rejected, (state, action) => {
        state.ekycReportListingState.isLoading = false;
        state.ekycReportListingState.isError = true;
        state.ekycReportListingState.errorMessage = action.payload;
      });
  },
});

export const { ekycStateReset } = ekycSlice.actions;

export default ekycSlice.reducer;
