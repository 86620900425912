import { createSlice } from "@reduxjs/toolkit";
import { UploadedFile } from "../application/applicationSlice";
import {
  createRescheduleCaseThunk,
  getApprovedRescheduleListingThunk,
  getOpenRescheduleListingThunk,
  getPendingRescheduleListingThunk,
  getReferRescheduleListingThunk,
  getRescheduleCaseThunk,
  getVoidRescheduleListingThunk,
} from "../../services/rescheduleService/rescheduleThunk";

export type RescheduleListing = {
  id: string;
  accountDetailsId: string;
  type: string;
  status: string;
  instalmentPeriod?: number;
  rescheduleDate?: string;
  rescheduleFee?: number;
  remark?: string;

  // values below will be retrieved from account details
  principalAmount?: number;
  rescheduleAmount?: number; //check
  applicantName?: string;
  agingType?: string;
  billingOutstanding?: number;
  netOustanding?: number;
  totalInterestAmount?: number;
  totalLoanOutstanding?: number;
  profitRate?: number;

  // reschedule calculation at backend
  instalmentBalance?: number;
  lastInstalmentAmount?: number;
  monthlyInstalmentAmount?: number;
  estimatedFirstDueDate?: number;
  totalFinancePayableAmount?: number;

  createdAt: string;
  updatedAt: string;

  dataNo: string;
};

export type RescheduleListingResponse = {
  total?: number;
  currentPage?: number;
  pageSize?: number;
  data: RescheduleListing[];
};

export type RescheduleListingState = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: any;
  data: RescheduleListingResponse;
};

export type rescheduleState = {
  openRescheduleListing: RescheduleListingState;
  pendingRescheduleListing: RescheduleListingState;
  referbackRescheduleListing: RescheduleListingState;
  voidRescheduleListing: RescheduleListingState;
  approvedRescheduleListing: RescheduleListingState;

  rescheduleDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: RescheduleListing;
  };
};

const initialState: rescheduleState = {
  openRescheduleListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },
  pendingRescheduleListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },
  referbackRescheduleListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },
  voidRescheduleListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },
  approvedRescheduleListing: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: { data: [], total: 0 },
  },

  rescheduleDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
};

const rescheduleSlice = createSlice({
  name: "reschedule",
  initialState,
  reducers: { rescheduleReset: () => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(createRescheduleCaseThunk.pending, (state) => {
        state.rescheduleDetails.isLoading = true;
      })
      .addCase(createRescheduleCaseThunk.fulfilled, (state, action) => {
        state.rescheduleDetails.isLoading = false;
        state.rescheduleDetails.data = action.payload.data;
      })
      .addCase(createRescheduleCaseThunk.rejected, (state, action) => {
        state.rescheduleDetails.isLoading = false;
        state.rescheduleDetails.isError = true;
        state.rescheduleDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getRescheduleCaseThunk.pending, (state) => {
        state.rescheduleDetails.isLoading = true;
      })
      .addCase(getRescheduleCaseThunk.fulfilled, (state, action) => {
        state.rescheduleDetails.isLoading = false;
        state.rescheduleDetails.data = action.payload.data;
      })
      .addCase(getRescheduleCaseThunk.rejected, (state, action) => {
        state.rescheduleDetails.isLoading = false;
        state.rescheduleDetails.isError = true;
        state.rescheduleDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getOpenRescheduleListingThunk.pending, (state) => {
        state.openRescheduleListing.isLoading = true;
      })
      .addCase(getOpenRescheduleListingThunk.fulfilled, (state, action) => {
        state.openRescheduleListing.isLoading = false;
        state.openRescheduleListing.data = action.payload;
      })
      .addCase(getOpenRescheduleListingThunk.rejected, (state, action) => {
        state.openRescheduleListing.isLoading = false;
        state.openRescheduleListing.isError = true;
        state.openRescheduleListing.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingRescheduleListingThunk.pending, (state) => {
        state.pendingRescheduleListing.isLoading = true;
      })
      .addCase(getPendingRescheduleListingThunk.fulfilled, (state, action) => {
        state.pendingRescheduleListing.isLoading = false;
        state.pendingRescheduleListing.data = action.payload;
      })
      .addCase(getPendingRescheduleListingThunk.rejected, (state, action) => {
        state.pendingRescheduleListing.isLoading = false;
        state.pendingRescheduleListing.isError = true;
        state.pendingRescheduleListing.errorMessage = action.payload;
      });

    builder
      .addCase(getVoidRescheduleListingThunk.pending, (state) => {
        state.voidRescheduleListing.isLoading = true;
      })
      .addCase(getVoidRescheduleListingThunk.fulfilled, (state, action) => {
        state.voidRescheduleListing.isLoading = false;
        state.voidRescheduleListing.data = action.payload;
      })
      .addCase(getVoidRescheduleListingThunk.rejected, (state, action) => {
        state.voidRescheduleListing.isLoading = false;
        state.voidRescheduleListing.isError = true;
        state.voidRescheduleListing.errorMessage = action.payload;
      });

    builder
      .addCase(getApprovedRescheduleListingThunk.pending, (state) => {
        state.approvedRescheduleListing.isLoading = true;
      })
      .addCase(getApprovedRescheduleListingThunk.fulfilled, (state, action) => {
        state.approvedRescheduleListing.isLoading = false;
        state.approvedRescheduleListing.data = action.payload;
      })
      .addCase(getApprovedRescheduleListingThunk.rejected, (state, action) => {
        state.approvedRescheduleListing.isLoading = false;
        state.approvedRescheduleListing.isError = true;
        state.approvedRescheduleListing.errorMessage = action.payload;
      });

    builder
      .addCase(getReferRescheduleListingThunk.pending, (state) => {
        state.referbackRescheduleListing.isLoading = true;
      })
      .addCase(getReferRescheduleListingThunk.fulfilled, (state, action) => {
        state.referbackRescheduleListing.isLoading = false;
        state.referbackRescheduleListing.data = action.payload;
      })
      .addCase(getReferRescheduleListingThunk.rejected, (state, action) => {
        state.referbackRescheduleListing.isLoading = false;
        state.referbackRescheduleListing.isError = true;
        state.referbackRescheduleListing.errorMessage = action.payload;
      });
  },
});

export const { rescheduleReset } = rescheduleSlice.actions;

export default rescheduleSlice.reducer;
