import "./index.less";

import { Tabs } from "antd";
import { useLocation } from "react-router-dom";

import ApplicationInfoTab from "./ApplicationInfoTab";
import AttachmentTab from "./AttachmentTab";
import DisbursementInfoTab from "./DisbursementInfoTab";
import HistoryLogTab from "./HistoryLogTab";

const ApplicationDetailsTab = ({ dataSource }: { dataSource?: any[] }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");

  const tabItems = [
    {
      label: "Application Info",
      key: "appInfo",
      children: <ApplicationInfoTab />,
    },
    {
      label: "Disbursement Info",
      key: "disbursementInfo",
      children: <DisbursementInfoTab />,
    },
    {
      label: "Attachment",
      key: "attachment",
      children: <AttachmentTab />,
    },
    {
      label: "History Log",
      key: "historyLog",
      children: <HistoryLogTab />,
    },
  ];

  return <Tabs defaultActiveKey={activeTab ?? "appInfo"} items={tabItems} />;
};

export default ApplicationDetailsTab;
