import { Button, Input, Select, Space, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { RescheduleListingState } from "../../../../features/reschedule/rescheduleSlice";
import { AppDispatch } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";
import type { TablePaginationConfig } from "antd/es/table";
import AccountEnquiryModal from "../../../AccountEnquiry/AccountEnquiryModal";
import { numberWithCommas } from "../../../../helpers/number";
import { RescheduleTypeEnum } from "../../../../enum/reschedule";

const { Search } = Input;

type RescheduleTableProps = {
  listingState: RescheduleListingState;
  callback: any;
  status: RescheduleTypeEnum;
};

const RescheduleTable: React.FC<RescheduleTableProps> = (props) => {
  const { listingState, callback, status } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const [searchField, setSearchField] = useState("agreementNo");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [activeTab, pageNum, pageSize],
  );

  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const tablePagination = {
    total: listingState?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  // Columns for other status except OPEN
  const columns1: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Reschedule Date",
      dataIndex: "rescheduleDate",
      key: "rescheduleDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Reschedule No",
      dataIndex: "dataNo",
      key: "dataNo",
      render: (text) => text ?? "-",
    },
    {
      title: "Agreement No",
      dataIndex: ["accountDetails", "agreementNo"],
      key: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal
          key={`accountEnquiryModal${record.id}`}
          agreementNo={record.accountDetails.agreementNo}
        />
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: ["accountDetails", "applicantFirstName"],
      key: "user",
      render: (_, record) => record?.accountDetails?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: ["accountDetails", "applicantNric"],
      key: "user",
      render: (_, record) => record?.accountDetails?.applicantNric ?? "-",
    },
    {
      title: "Aging",
      key: "agingType",
      dataIndex: "agingType",
      render: (text) => text ?? "-",
    },
    {
      title: "Reschedule Amount",
      key: "netOustanding",
      dataIndex: "netOustanding",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => {
              navigate(`/reschedule/details/${record.id}`);
            }}
          >
            {record.status === RescheduleTypeEnum.OPEN ? "Update" : "View"}
          </Button>
        </Space>
      ),
    },
  ];

  // Columns for OPEN status
  const columns2: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Reschedule Date",
      dataIndex: "rescheduleDate",
      key: "rescheduleDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => text ?? "-",
    },
    {
      title: "Agreement No",
      dataIndex: ["accountDetails", "agreementNo"],
      key: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal
          key={`accountEnquiryModal${record.id}`}
          agreementNo={record.accountDetails.agreementNo}
        />
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: ["accountDetails", "applicantFirstName"],
      key: "user",
      render: (_, record) => record?.accountDetails?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: ["accountDetails", "applicantNric"],
      key: "user",
      render: (_, record) => record?.accountDetails?.applicantNric ?? "-",
    },
    {
      title: "Aging",
      key: "agingType",
      dataIndex: "agingType",
      render: (text) => text ?? "-",
    },
    {
      title: "Reschedule Amount",
      key: "netOustanding",
      dataIndex: "netOustanding",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => {
              navigate(`/reschedule/details/${record.id}`);
            }}
          >
            {record.status === RescheduleTypeEnum.OPEN ? "Update" : "View"}
          </Button>
        </Space>
      ),
    },
  ];

  const onSearch = async (value: string) => {
    const initPagination = {
      current: 1,
      pageSize: 10,
    };
    if (value) {
      callback({
        ...initialParams,
        pagination: initPagination,
        search: { [searchField]: value },
      });
    } else {
      callback({
        ...initialParams,
        pagination: initPagination,
      });
    }
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    callback({ ...initialParams, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Input.Group compact>
        <Select
          className="w-[10rem]"
          value={searchField}
          onChange={(value) => {
            setSearchField(value);
          }}
          options={[
            { value: "agreementNo", label: "Agreement No" },
            { value: "applicantNric", label: "Applicant NRIC" },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantPhoneNo", label: "Phone No" },
            { value: "applicantEmail", label: "Email" },
          ]}
        />
        <Search
          className="w-[20rem]"
          placeholder="Input search text"
          enterButton="Search"
          loading={listingState?.isLoading}
          onSearch={onSearch}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={status === RescheduleTypeEnum.OPEN ? columns2 : columns1}
        pagination={tablePagination}
        dataSource={listingState?.data?.data ?? []}
        loading={listingState?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default RescheduleTable;
