import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import {
  createRefundCase,
  createRefundFinanceBatch,
  createUnmatchedRefundFinanceBatch,
  downloadRefundPaymentFile,
  getRefundFinanceBatch,
  getRefundFinanceBatchById,
  getRefundFinanceList,
  getRefundFinanceSummary,
  getRefundList,
  getUnmatchedRefundFinanceBatchById,
  unPostRefundFinanceById,
  updateRefundDetails,
  updateRefundFinanceBatch,
  updateRefundFinanceBatchIsDownloaded,
  updateRefundFinanceStatus,
  updateRefundStatus,
  updateUnmatchedRefundFinanceBatch,
} from "./refundService";
import { updateUnmatchedRefundFinanceStatus } from "../unmatchedService/unmatchedService";

export const getOpenRefundListThunk = createAsyncThunk(
  "refund/getOpen",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingRefundListThunk = createAsyncThunk(
  "refund/getPending",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferbackRefundListThunk = createAsyncThunk(
  "refund/getReferback",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVoidRefundListThunk = createAsyncThunk(
  "refund/getVoid",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApprovedRefundListThunk = createAsyncThunk(
  "refund/getApproved",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRefundedRefundThunk = createAsyncThunk(
  "refund/getRefunded",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createRefundCasesThunk = createAsyncThunk(
  "refund/createRefundCases",
  async (requestData: any, thunkAPI) => {
    try {
      return await createRefundCase(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRefundDetailsThunk = createAsyncThunk(
  "refund/updateRefundDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateRefundDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRefundStatusThunk = createAsyncThunk(
  "refund/updateRefundStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateRefundStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRefundFinanceListThunk = createAsyncThunk(
  "refund/getRefundFinanceList",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundFinanceList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRefundFinanceStatusThunk = createAsyncThunk(
  "refund/updateRefundFinanceStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateRefundFinanceStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadRefundPaymentFileThunk = createAsyncThunk(
  "refund/downloadRefundPaymentFile",
  async (requestData: any, thunkAPI) => {
    try {
      return await downloadRefundPaymentFile(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRefundFinanceSummaryThunk = createAsyncThunk(
  "refund/getRefundFinanceSummary",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundFinanceSummary(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createRefundFinanceBatchThunk = createAsyncThunk(
  "refund/createRefundFinanceBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await createRefundFinanceBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRefundFinanceBatchThunk = createAsyncThunk(
  "refund/getRefundFinanceBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundFinanceBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRefundFinanceBatchByIdThunk = createAsyncThunk(
  "refund/getRefundFinanceBatchById",
  async (requestData: any, thunkAPI) => {
    try {
      return await getRefundFinanceBatchById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRefundFinanceBatchThunk = createAsyncThunk(
  "refund/updateRefundFinanceBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateRefundFinanceBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createUnmatchedRefundFinanceBatchThunk = createAsyncThunk(
  "refund/createUnmatchedRefundFinanceBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await createUnmatchedRefundFinanceBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getUnmatchedRefundFinanceBatchByIdThunk = createAsyncThunk(
  "refund/getUnmatchedRefundFinanceBatchById",
  async (requestData: any, thunkAPI) => {
    try {
      return await getUnmatchedRefundFinanceBatchById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateUnmatchedRefundFinanceBatchThunk = createAsyncThunk(
  "refund/updateUnmatchedRefundFinanceBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateUnmatchedRefundFinanceBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRefundFinanceBatchIsDownloadedThunk = createAsyncThunk(
  "refund/updateRefundFinanceBatchIsDownloaded",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateRefundFinanceBatchIsDownloaded(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const unPostRefundFinanceByIdThunk = createAsyncThunk(
  "refund/unPostRefundFinanceById",
  async (requestData: any, thunkAPI) => {
    try {
      return await unPostRefundFinanceById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
