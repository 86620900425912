import "../index.less";

import { Divider } from "antd";
import { useSelector } from "react-redux";

import InfoGrid from "../../../components/InfoGrid";
import { numberWithCommas } from "../../../helpers/number";
import { RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import { trimPhoneNo } from "../../../utils/number.util";

const EmploymentInformation = () => {
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const application = accountEnquiry?.data?.agreementDetails?.application;

  const employmentInformation = [
    {
      title: "Employment Type",
      value:
        accountEnquiry.data.accountDetails?.employmentType ??
        application?.applicantEmploymentType,
    },
    {
      title: "Monthly Salary",
      value:
        application?.actualMonthlySalary != null
          ? `RM ${numberWithCommas(
              accountEnquiry.data.accountDetails?.actualMonthlySalary ??
                application?.actualMonthlySalary,
            )}`
          : "-",
    },
    {
      title: "Job Start Date",
      value: displayDate(
        accountEnquiry.data.accountDetails?.employmentStartingFrom ??
          application?.employmentStartingFrom,
      ),
    },
    {
      title: "Bank Name",
      value:
        accountEnquiry.data.accountDetails?.applicantBankName ??
        application?.applicantBankName,
    },
    {
      title: "Bank Account No.",
      value:
        accountEnquiry.data.accountDetails?.applicantPersonalBankNo ??
        application?.applicantPersonalBankNo,
    },
    {
      title: "Employer’s Name",
      value:
        accountEnquiry.data.accountDetails?.employerName ??
        application?.employerName,
    },
    {
      title: "Employer’s Contact No.",
      value: accountEnquiry.data.accountDetails?.employerContactNo
        ? trimPhoneNo(accountEnquiry.data.accountDetails?.employerContactNo)
        : application?.employerContactNo
        ? trimPhoneNo(application?.employerContactNo)
        : "-",
    },
    {
      title: "Job Title",
      value:
        accountEnquiry.data.accountDetails?.jobTitle ?? application?.jobTitle,
    },
  ];

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <h3 className="account-enquiry-section-subtitle">
          Employment Information
        </h3>
        <Divider style={{ margin: 0 }} />
        <div className="info-container">
          <InfoGrid data={employmentInformation} />
        </div>
      </div>
    </div>
  );
};

export default EmploymentInformation;
