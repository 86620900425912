import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  TimePicker,
} from "antd";
import startCase from "lodash.startcase";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionType } from "../../enum/actionType";
import { CONTACT_MODE, CONTACT_PERSON } from "../../enum/collectionActivities";
import {
  getAllActiveActionCodeThunk,
  getAvailableActionCodeThunk,
} from "../../services/actionCodeService/actionCodeThunk";
import {
  getDunningContactModeListingThunk,
  getDunningHistoryListingThunk,
  saveDunningInfoThunk,
  updateDunningInfoThunk,
} from "../../services/dunningService/dunningThunk";
import { AppDispatch, RootState } from "../../store/store";
import { dateFormat } from "../../utils/datetime.util";
import { trimPhoneNo } from "../../utils/number.util";
import { emailRegexPattern, phoneRegexPattern } from "../../utils/validation";
import { getAccountDetailsThunk } from "../../services/collectionService/collectionThunk";
import moment from "moment";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { numberWithCommas } from "../../helpers/number";

const { TextArea } = Input;

type UpdateDunningInfoModalProps = {
  visible?: boolean;
  hideButton?: boolean;
  onFinishCallback?: () => void;
  onCancelCallback?: () => void;
  accountDetailsId?: string;
  statusActivity?: string;
  agreementNo?: string;
  isUpdate?: boolean;
  id?: string;
  prevPtpAmount?: number;
};

const UpdateDunningInfoModal: React.FC<UpdateDunningInfoModalProps> = (
  props,
) => {
  const {
    visible,
    hideButton,
    onFinishCallback,
    onCancelCallback,
    accountDetailsId,
    statusActivity,
    agreementNo,
    isUpdate,
    id,
    prevPtpAmount,
  } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  const [ptpFieldVisible, setPTPFieldVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(visible ?? false);
  const [nextVisible, setNextVisible] = useState(false);
  const [firstDataPTP, setFirstDataPTP] = useState(false);
  const actionCode = Form.useWatch("actionCode", form);
  const [contactMode, setContactMode] = useState<any>(null);
  const [contactPerson, setContactPerson] = useState<any>(null);
  const [contactMethod, setContactMethod] = useState<any>("Contact No");
  const [letterType, setLetterType] = useState<any>([
    "Reminder Letter 1",
    "Reminder Letter 2",
    "Notice Of Demand",
  ]);

  const { actionCodeActiveListingState, actionCodeAvalaibleListingState } =
    useSelector((state: RootState) => state.actionCode);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const fetchAllActionCode = async () => {
    // statusActivity === ActionType.PTP
    //   ? await dispatch(getAvailableActionCodeThunk(ActionType.PTP))
    //   :
    statusActivity === ActionType.BP
      ? await dispatch(getAvailableActionCodeThunk(ActionType.BP))
      : await dispatch(getAllActiveActionCodeThunk());
  };

  const fetchAllDunningContactMode = async () => {
    await dispatch(getDunningContactModeListingThunk());
  };

  const fetchListingDunningHistory = async () => {
    await dispatch(
      getDunningHistoryListingThunk({
        accountDetailsId: accountDetailsId,
      }),
    );
  };

  const onCancel = () => {
    setModalVisible(false);
    setNextVisible(false);
    setPTPFieldVisible(false);
    setFirstDataPTP(false);
    form.resetFields();
    if (onCancelCallback) onCancelCallback();
  };

  const resetSectionVisible = () => {
    setNextVisible(false);
    setPTPFieldVisible(false);
    setFirstDataPTP(false);
  };

  const checkActionType = (value: any) => {
    // set both to invisible, later only choose what to show
    resetSectionVisible();

    // find the object with the selected code
    const actionCode = actionCodeActiveListingState.data.find(
      (ac) => ac.id === value,
    );

    const actionCodePTP = actionCodeAvalaibleListingState.data.find(
      (ac) => ac.id === value,
    );

    // check the actionType of the object, if match then set visible
    if (
      actionCode?.actionType === ActionType.CALL ||
      actionCodePTP?.actionType === ActionType.CALL
    ) {
      return setNextVisible(true);
    }

    if (
      actionCode?.code === ActionType.PTP ||
      actionCodePTP?.code === ActionType.PTP
    ) {
      const ptpAmount =
        Number(accountEnquiry.data.accountDetails?.totalCharges) +
        Number(accountEnquiry.data.accountDetails?.totalDueInstalment);

      form.setFieldsValue({
        ptpAmount: numberWithCommas(ptpAmount),
      });
      return setPTPFieldVisible(true);
    }
  };

  const onFinish = () => {
    let amount = 0;
    let previousPtpAmount: any = 0;
    const ptpAmount = form.getFieldValue("ptpAmount");

    amount =
      ptpAmount !== undefined ? ptpAmount.toString().replace(/,/g, "") : 0;
    previousPtpAmount =
      prevPtpAmount !== undefined
        ? prevPtpAmount.toString().replace(/,/g, "")
        : 0;
    console.log(amount, previousPtpAmount);
    form.validateFields().then((values) => {
      const param: any = {
        actionCodeId: values.actionCode,
        accountDetailsId: accountDetailsId,
        contactMode: values.contactMode,
        contactNo: trimPhoneNo(values.contactNo),
        remark: values.remarks,
        ptpDate: values?.ptpDate,
        ptpAmount: amount !== 0 ? Number(amount) : Number(previousPtpAmount),
        nextCallDate: values?.nextCallDate,
        nextCallTime: values?.nextCallTime,
        createdBy: currentUser?.data?.username,
        contactPerson: values.contactPerson,
      };

      if (isUpdate) {
        param.id = id;
        dispatch(updateDunningInfoThunk({ payload: param }))
          .unwrap()
          .then((res: any) => {
            callbackState.fetchListingDunningHistory();
            message.success(res.message);
            if (onFinishCallback) {
              onFinishCallback();
            }
            onCancel();
          });
      } else {
        dispatch(saveDunningInfoThunk({ payload: param }))
          .unwrap()
          .then((res: any) => {
            callbackState.fetchListingDunningHistory();
            message.success(res.message);
            if (onFinishCallback) {
              onFinishCallback();
            }
            onCancel();
          });
      }
    });
  };

  const initialCallbackState = {
    fetchAllActionCode,
    checkActionType,
    fetchListingDunningHistory,
    fetchAllDunningContactMode,
  };
  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    if (modalVisible) {
      console.log("fetching related apis");
      callbackState.fetchAllActionCode();
      callbackState.fetchListingDunningHistory();
      callbackState.fetchAllDunningContactMode();
    }
  }, [callbackState, modalVisible]);

  // use Form.useWatch to monitor the change of actionCode field,
  // if it got change, it will trigger this useEEfect to perform the checkActionType()
  useEffect(() => {
    if (actionCode) {
      callbackState.checkActionType(actionCode);
    }
  }, [callbackState, actionCode]);

  useEffect(() => {
    if (modalVisible) {
      console.log(agreementNo, modalVisible);
      dispatch(getAccountDetailsThunk({ agreementNo: agreementNo }));
    }
  }, [modalVisible]);

  const contactNoOnChange = (contactMode?: string, contactPerson?: string) => {
    if (
      contactPerson === CONTACT_PERSON.OTHERS ||
      contactMode === CONTACT_MODE.LETTER
    ) {
      form.setFieldsValue({
        contactNo: null,
      });
    } else if (contactPerson === CONTACT_PERSON.CUSTOMER) {
      if (contactMode === CONTACT_MODE.PHONE_NUMBER) {
        form.setFieldsValue({
          contactNo: trimPhoneNo(
            accountEnquiry.data.accountDetails?.applicantPhoneNo ??
              accountEnquiry.data.agreementDetails?.application
                ?.applicantPhoneNo,
          ),
        });
      } else if (contactMode === CONTACT_MODE.SMS) {
        form.setFieldsValue({
          contactNo: trimPhoneNo(
            accountEnquiry.data.accountDetails?.applicantPhoneNo ??
              accountEnquiry.data.agreementDetails?.application
                ?.applicantPhoneNo,
          ),
        });
      } else if (contactMode === CONTACT_MODE.EMAIL) {
        form.setFieldsValue({
          contactNo:
            accountEnquiry.data.accountDetails?.applicantEmail ??
            accountEnquiry.data.agreementDetails?.application?.applicantEmail,
        });
      }
    } else if (contactPerson === CONTACT_PERSON.EMERGENCY_CONTACT_PERSON) {
      if (contactMode === CONTACT_MODE.PHONE_NUMBER) {
        form.setFieldsValue({
          contactNo: trimPhoneNo(
            accountEnquiry.data.accountDetails?.emergencyContactNo ??
              accountEnquiry.data.agreementDetails?.application
                ?.emergencyContactNo,
          ),
        });
      } else if (contactMode === CONTACT_MODE.SMS) {
        form.setFieldsValue({
          contactNo: trimPhoneNo(
            accountEnquiry.data.accountDetails?.emergencyContactNo ??
              accountEnquiry.data.agreementDetails?.application
                ?.emergencyContactNo,
          ),
        });
      } else if (contactMode === CONTACT_MODE.EMAIL) {
        form.setFieldsValue({
          contactNo:
            accountEnquiry.data.accountDetails?.applicantEmail ??
            accountEnquiry.data.agreementDetails?.application
              ?.emergencyContactPersonEmail,
        });
      }
    } else if (contactPerson === CONTACT_PERSON.EMPLOYER) {
      if (contactMode === CONTACT_MODE.PHONE_NUMBER) {
        form.setFieldsValue({
          contactNo: trimPhoneNo(
            accountEnquiry.data.accountDetails?.employerContactNo ??
              accountEnquiry.data.agreementDetails?.application
                ?.employerContactNo,
          ),
        });
      } else if (contactMode === CONTACT_MODE.SMS) {
        form.setFieldsValue({
          contactNo: trimPhoneNo(
            accountEnquiry.data.accountDetails?.employerContactNo ??
              accountEnquiry.data.agreementDetails?.application
                ?.employerContactNo,
          ),
        });
      } else if (contactMode === CONTACT_MODE.EMAIL) {
        form.setFieldsValue({
          contactNo: null,
        });
      }
    }
  };

  const contactMethodHeader = (contactMode?: string) => {
    if (contactMode === CONTACT_MODE.EMAIL) {
      setContactMethod("Email");
    } else if (
      contactMode === CONTACT_MODE.PHONE_NUMBER ||
      contactMode === CONTACT_MODE.SMS
    ) {
      setContactMethod("Contact No");
    } else if (contactMode === CONTACT_MODE.LETTER) {
      setContactMethod("Letter");
    }
  };

  return (
    <>
      {!hideButton && (
        <Button onClick={() => setModalVisible(true)}>Update Dunning</Button>
      )}
      <Modal
        title="Update Dunning Info"
        okText="Update"
        open={modalVisible}
        onOk={onFinish}
        onCancel={onCancel}
        // confirmLoading={loading}
        // width={750}
        destroyOnClose
        centered
      >
        <div className="mt-6 w-full">
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark="optional"
          >
            <Form.Item
              label="Contact Mode"
              name="contactMode"
              rules={[{ required: true, message: "Contact Mode is required" }]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                // options={
                //   dunningContactModeListingState?.data?.map((dcm) => ({
                //     value: dcm.contactMode,
                //     label: `${dcm?.contactMode.replace(/[_]/g, " ")}`,
                //   })) ?? []
                // }
                onChange={(value) => {
                  setContactMode(value);
                  const person = contactPerson;

                  if (person) {
                    contactNoOnChange(value, person);
                    contactMethodHeader(value);
                  } else {
                    contactNoOnChange(value, "");
                    contactMethodHeader(value);
                  }
                }}
              >
                <Select.Option value={CONTACT_MODE.PHONE_NUMBER}>
                  {startCase(CONTACT_MODE.PHONE_NUMBER)}
                </Select.Option>
                <Select.Option value={CONTACT_MODE.SMS}>
                  {startCase(CONTACT_MODE.SMS.toUpperCase())}
                </Select.Option>
                <Select.Option value={CONTACT_MODE.EMAIL}>
                  {startCase(CONTACT_MODE.EMAIL)}
                </Select.Option>
                <Select.Option value={CONTACT_MODE.LETTER}>
                  {startCase(CONTACT_MODE.LETTER)}
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Contact Person"
              name="contactPerson"
              rules={[
                { required: true, message: "Contact person is required" },
              ]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                // options={
                //   dunningContactModeListingState?.data?.map((dcm) => ({
                //     value: dcm.contactMode,
                //     label: `${dcm?.contactMode.replace(/[_]/g, " ")}`,
                //   })) ?? []
                // }
                onChange={(value) => {
                  setContactPerson(value);
                  const mode = contactMode;

                  if (mode) {
                    contactNoOnChange(mode, value);
                  } else {
                    contactNoOnChange("", value);
                  }
                }}
                options={[
                  {
                    value: CONTACT_PERSON.CUSTOMER,
                    label: startCase(CONTACT_PERSON.CUSTOMER),
                  },
                  {
                    value: CONTACT_PERSON.EMERGENCY_CONTACT_PERSON,
                    label: startCase(CONTACT_PERSON.EMERGENCY_CONTACT_PERSON),
                  },
                  {
                    value: CONTACT_PERSON.EMPLOYER,
                    label: startCase(CONTACT_PERSON.EMPLOYER),
                  },
                  {
                    value: CONTACT_PERSON.OTHERS,
                    label: startCase(CONTACT_PERSON.OTHERS),
                  },
                ]}
              />
            </Form.Item>

            {contactMode === CONTACT_MODE.LETTER ? (
              <>
                <Form.Item
                  label={contactMethod}
                  name="contactNo"
                  rules={[
                    { required: true, message: "Letter is required" },
                    {
                      pattern:
                        contactMode === CONTACT_MODE.EMAIL
                          ? emailRegexPattern
                          : contactMode === CONTACT_MODE.PHONE_NUMBER ||
                            contactMode === CONTACT_MODE.SMS
                          ? phoneRegexPattern
                          : undefined,

                      message:
                        contactMode === CONTACT_MODE.EMAIL
                          ? "Invalid Email"
                          : contactMode === CONTACT_MODE.PHONE_NUMBER ||
                            contactMode === CONTACT_MODE.SMS
                          ? "Invalid Phone Number"
                          : undefined,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    onChange={(value) => {
                      form.setFieldsValue({
                        contactNo: value,
                      });
                    }}
                  >
                    {letterType.map((item: any) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>{" "}
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  label={contactMethod}
                  name="contactNo"
                  rules={[
                    { required: true, message: "Contact No is required" },
                    {
                      pattern:
                        contactMode === CONTACT_MODE.EMAIL
                          ? emailRegexPattern
                          : contactMode === CONTACT_MODE.PHONE_NUMBER ||
                            contactMode === CONTACT_MODE.SMS
                          ? phoneRegexPattern
                          : undefined,

                      message:
                        contactMode === CONTACT_MODE.EMAIL
                          ? "Invalid Email"
                          : contactMode === CONTACT_MODE.PHONE_NUMBER ||
                            contactMode === CONTACT_MODE.SMS
                          ? "Invalid Phone Number"
                          : undefined,
                    },
                  ]}
                >
                  <Input placeholder="Please Input" />
                </Form.Item>
              </>
            )}

            <Form.Item
              label="Action Code"
              name="actionCode"
              rules={[
                { required: true, message: "Action Code is required" },
                {
                  validator: async (field, value) => {
                    if (value) {
                      checkActionType(value);
                    }
                  },
                },
              ]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                options={
                  // statusActivity === ActionType.PTP ||
                  statusActivity === ActionType.BP
                    ? actionCodeAvalaibleListingState?.data?.map((av) => ({
                        value: av.id,
                        label: `${av?.code} - ${av?.description}`,
                      }))
                    : actionCodeActiveListingState?.data?.map((ac) => ({
                        value: ac.id,
                        label: `${ac?.code} - ${ac?.description}`,
                      })) ?? []
                }
              />
            </Form.Item>

            {ptpFieldVisible && (
              <>
                <Form.Item
                  label="PTP Amount (RM)"
                  name="ptpAmount"
                  rules={[
                    { required: true, message: "PTP Amount is required" },
                  ]}
                >
                  <InputNumber placeholder="Please Input" className="w-full" />
                </Form.Item>
                <Form.Item
                  label="PTP Date"
                  name="ptpDate"
                  rules={[{ required: true, message: "PTP Date is required" }]}
                >
                  <DatePicker
                    format={dateFormat}
                    placeholder="Select Date"
                    className="w-full"
                    disabledDate={(current: any) =>
                      current.isBefore(moment().subtract(1, "day"))
                    }
                  />
                </Form.Item>
              </>
            )}
            {nextVisible && (
              <>
                <Form.Item
                  label="Next Call Date"
                  name="nextCallDate"
                  rules={[
                    { required: true, message: "PTP Amount is required" },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    placeholder="Select Date"
                    className="w-full"
                    disabledDate={(current: any) =>
                      current.isBefore(moment().subtract(1, "day"))
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Next Call Time"
                  name="nextCallTime"
                  rules={[{ required: true, message: "PTP Date is required" }]}
                >
                  <TimePicker placeholder="Select Time" className="w-full" />
                </Form.Item>
              </>
            )}

            <Form.Item
              label="Remarks"
              name="remarks"
              rules={[{ required: true, message: "Remarks is required" }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

UpdateDunningInfoModal.defaultProps = {
  hideButton: false,
};

export default UpdateDunningInfoModal;
