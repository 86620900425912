export enum FIELD_VISIT_STATUS {
  OPEN = "OPEN",
  VISITED = "VISITED",
  CLOSED = "CLOSED",
  DRAFT = "DRAFT",
  PENDING = "PENDING",
}

export enum FIELD_VISIT_ACTION {
  FIELD_VISIT = "FIELD_VISIT",
}
