import React from "react";
import { Collapse, Tabs } from "antd";
import { useLocation } from "react-router-dom";
import { ApplicationInfoDetailsTab } from "./ApplicationInfoDetailsTab";
import { DisbursementInfoTab } from "./DisbursementInfoTab";
import { AttachmentTab } from "./AttachmentTab";
import { HistoryLogTab } from "./HistoryLogTab";
import { CompanyDetailsTab } from "./CompanyDetailsTab";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { SME_ORGANIZATION_TYPE } from "../../../../enum/smeApplication";
import { DirectorInfoTab } from "./DirectorInfoTab";

const { Panel } = Collapse;

export const AdditionalDetailsPanel = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const companyTab = [
    {
      label: "Company Info",
      key: "companyInfo",
      children: <CompanyDetailsTab />,
    },
  ]

  const directorTab = [
    {
      label: "Director Info",
      key: "directorInfo",
      children: <DirectorInfoTab />,
    },
  ]

  const applicationTab = [
    {
      label: "Application Info",
      key: "applicationInfo",
      children: <ApplicationInfoDetailsTab />,
    },
  ]

  const tabItems = [
    ...smeApplicationDetails?.data?.organizationType !== SME_ORGANIZATION_TYPE.COMPANY ? applicationTab : [],
    ...smeApplicationDetails?.data?.organizationType !== SME_ORGANIZATION_TYPE.INDIVIDUAL ? companyTab : [],
    ...(smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.PARTNERSHIP ||smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.COMPANY) ? directorTab : [],
    {
      label: "Disbursement Info",
      key: "disbursementInfo",
      children: <DisbursementInfoTab />,
    },
    {
      label: "Attachment",
      key: "attachment",
      children: <AttachmentTab />,
    },
    {
      label: "History Log",
      key: "historyLog",
      children: <HistoryLogTab />,
    },
  ];

  return (
    <Collapse ghost expandIconPosition={"end"} defaultActiveKey={"moreDetails"}>
      <Panel
        key="moreDetails"
        className="application-details-section-container"
        header={
          <h3 className="application-details-section-title">
            More Details
          </h3>
        }
      >
        <Tabs defaultActiveKey={activeTab ?? "applicationInfo"} items={tabItems} />
      </Panel>
    </Collapse>
  );
};