import { authSetupAxiosInstance } from "../../axios/authSetupAxios";

const getAdmins = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `redCash/admin/auth/api/v1.0/admin/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await authSetupAxiosInstance.get(url, { params });
  return res.data;
};

const createAdmin = async (requestData: any) => {
  const url = `/redCash/admin/auth/api/v1.0/admin/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await authSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updateAdmin = async (requestData: any) => {
  const { adminId, payload } = requestData;
  const url = `/redCash/admin/auth/api/v1.0/admin/${adminId}`;
  const res = await authSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteAdmin = async (requestData: any) => {
  const { adminId } = requestData;
  const url = `/redCash/admin/auth/api/v1.0/admin/${adminId}`;
  const res = await authSetupAxiosInstance.delete(url);
  return res.data;
};

const getAdminById = async (requestData: any) => {
  const { adminId } = requestData;
  const url = `/redCash/admin/auth/api/v1.0/admin/${adminId}`;
  const res = await authSetupAxiosInstance.get(url);
  return res.data;
};

const adminService = {
  getAdmins,
  createAdmin,
  updateAdmin,
  deleteAdmin,
  getAdminById,
};

export default adminService;
