import "./index.less";

import { Button, Form, Input, message, Modal, Select, Switch } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FieldVisitOfficer,
  OfficerLocation,
} from "../../../../../features/fieldVisit/fieldVisitSlice";
import { AppDispatch, RootState } from "../../../../../store/store";
import { STATE_ENUM } from "../../../../../enum/state";
import {
  createFieldVisitOfficerThunk,
  updateFieldVisitOfficerThunk,
} from "../../../../../services/fieldVisitService/fieldVisitThunk";
import { useParams } from "react-router-dom";
import lodash from "lodash";
import UpdatePasswordModal from "./UpdatePasswordModal";

const FieldVisitOfficerFormModal: React.FC<{
  data?: FieldVisitOfficer;
  callback?: () => void;
  viewMode?: boolean;
}> = ({ data, callback, viewMode }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [form] = Form.useForm();
  const updateMode = data != null;

  const labelClassName = "w-[140px]";

  const { currentUser } = useSelector((state: RootState) => state.user);
  const stateOptions = Object.values(STATE_ENUM).map((value) => ({
    label: `${value.replace(/[_]/g, " ")}`,
    value,
  }));
  const [newAddedLocation, setNewAddedLocation] = useState<any>([]);
  const [stateSelected, setSelectedState] = useState<any>([]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        const deletedLocation: { state: string }[] = [];
        const payload = {
          ...values,
          city: values.companyCity,
          state: values.companyState,
          isActive: values.isActive != null ? values.isActive : false,
          createdBy: data?.createdBy ?? currentUser?.data?.id,
          updatedBy: currentUser?.data?.id,
          fieldVisitCompanyId: data?.fieldVisitCompanyId ?? id,
          location: stateSelected,
        };

        if (updateMode) {
          data.officerLocation.map((item: any) => {
            if (
              !stateSelected
                .map((item2: any) => item2.state)
                .includes(item.state)
            ) {
              deletedLocation.push({
                state: item.state,
              });
            }
          });

          payload["newAddedLocation"] = newAddedLocation;
          payload["deletedLocation"] = deletedLocation;
          dispatch(
            updateFieldVisitOfficerThunk({
              id: data.id,
              payload,
            }),
          )
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Update Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        } else {
          dispatch(createFieldVisitOfficerThunk(payload))
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Create Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        }
      })
      .catch((error) => {
        message.error("Something went wrong. Please try again later.");
        setLoading(false);
      });
  };

  const selectedState = async (value: any, label: any) => {
    const state_code = label.value;
    // await dispatch(getPostcodeByStateThunk({ state_code })).then((res) => {});
    const result: any = [];
    const newAddedLocation: any = [];
    if (updateMode) {
      value.map((item: any) => {
        if (
          !data.officerLocation.map((item2: any) => item2.state).includes(item)
        ) {
          newAddedLocation.push({
            state: item,
          });
        }
      });
      await Promise.all(
        value.map(async (item: any) => result.push({ state: item })),
      );

      setNewAddedLocation(newAddedLocation);
      setSelectedState(result);
    } else {
      await Promise.all(
        value.map(async (item: any) => result.push({ state: item })),
      );
      setSelectedState(result);
    }
  };

  const selectedPostcode = async (value: any, label: any) => {
    const result: any = [];
    const newAddedLocation: any = [];
    if (updateMode) {
      await Promise.all(
        value.map(async (item: any) => {
          if (typeof item == "string") {
            const parts = item.split("(");
            const postcode = parts[0].trim();
            const state = parts[1].replace(")", "").trim();

            result.push({ postcode, state });
          }
        }),
      );

      await Promise.all(
        label.map(async (item: any) => {
          if (Object.keys(item).length > 0) {
            const startIndex = item.label.indexOf("(");
            const endIndex = item.label.indexOf(")", startIndex);
            const state_code = item.label.substring(startIndex + 1, endIndex);
            newAddedLocation.push({
              state: state_code,
              postcode: item.value.toString(),
            });
            result.push({
              state: state_code,
              postcode: item.value.toString(),
            });
          }
        }),
      );

      setNewAddedLocation(newAddedLocation);
    } else {
      await Promise.all(
        label.map(async (item: any) => {
          const startIndex = item.label.indexOf("(");
          const endIndex = item.label.indexOf(")", startIndex);
          const state_code = item.label.substring(startIndex + 1, endIndex);
          result.push({ state: state_code, postcode: item.value.toString() });
        }),
      );
    }
  };

  return (
    <>
      <Button onClick={showModal}>
        {data != null ? (viewMode ? "View" : "Update") : "Create Officer"}
      </Button>
      <Modal
        title={
          data != null
            ? viewMode
              ? "View Officer"
              : "Update Officer"
            : "Create Officer"
        }
        okText={data != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={500}
        {...(viewMode ? { footer: null } : {})}
        destroyOnClose
      >
        <Form
          form={form}
          initialValues={{
            ...data,
            postCodeCovered: data?.officerLocation?.map(
              (item: OfficerLocation) => `${item.postcode} (${item.state})`,
            ),
            // coveredState: data?.officerLocation.map((item) => {
            //   item.state;
            // }),
            // postCodeCovered: data?.officerLocation.map((item) => {
            //   item.postcode;
            // }),
            coveredState: lodash.uniq(
              data?.officerLocation?.map((item: OfficerLocation) => item.state),
            ),
          }}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
          disabled={viewMode}
        >
          {/* <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Officer ID</span>}
            name="officerNumber"
            rules={[
              { required: true, message: "Please enter the Officer ID." },
            ]}
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>First Name</span>}
            name="firstName"
            rules={[
              { required: true, message: "Please enter the First Name." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Last Name</span>}
            name="lastName"
            rules={[{ required: true, message: "Please enter the Last Name." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>NRIC</span>}
            name="nric"
            rules={[{ required: true, message: "Please enter the NRIC." }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={"address1"}
            label={<span className={labelClassName}>Address 1</span>}
            className="mb-2"
            validateFirst={true}
            rules={[{ required: true, message: "Address is required" }]}
          >
            <Input placeholder="Address line 1" />
          </Form.Item>

          <Form.Item
            name={"address2"}
            className="mb-2"
            label={<span className={labelClassName}>Address 2</span>}
          >
            <Input placeholder="Address line 2" />
          </Form.Item>

          <Form.Item
            name={"address3"}
            className="mb-2"
            label={<span className={labelClassName}>Address 3</span>}
          >
            <Input placeholder="Address line 3" />
          </Form.Item>

          <Form.Item
            name={"postCode"}
            className="mb-2"
            rules={[{ required: true, message: "Postcode is required" }]}
            label={<span className={labelClassName}>Postcode</span>}
          >
            <Input placeholder="Postcode" />
          </Form.Item>

          <Form.Item
            name={"officerCity"}
            className="mb-2"
            rules={[{ required: true, message: "City is required" }]}
            label={<span className={labelClassName}>Officer City</span>}
          >
            <Input placeholder="City" />
          </Form.Item>

          <Form.Item
            name={"officerState"}
            rules={[{ required: true, message: "State is required" }]}
            label={<span className={labelClassName}>Officer State</span>}
          >
            <Select
              placeholder="Please Select State"
              allowClear
              options={stateOptions}
            />
          </Form.Item>
          {!viewMode && (
            <Form.Item
              name={"coveredState"}
              // rules={[{ required: true, message: "State is required" }]}
              label={<span className={labelClassName}>Covered State</span>}
            >
              <Select
                placeholder="Please Select State"
                allowClear
                mode="multiple"
                options={stateOptions}
                onChange={selectedState}
              />
            </Form.Item>
          )}

          {/* {Postcode?.data?.length > 0 && (
            <Form.Item
              name={"postCodeCovered"}
              rules={[{ required: true, message: "Postcode is required" }]}
              label={<span className={labelClassName}>Postcode</span>}
            >
              <Select
                placeholder="Please Select Postcode"
                allowClear
                mode="multiple"
                options={Postcode.data.map((item) => ({
                  value: item.postcode,
                  label: `${item.postcode} (${item.state_code})`,
                }))}
                onChange={selectedPostcode}
                loading={Postcode.isLoading}
              />
            </Form.Item>
          )} */}
          {/* 
          <Form.Item
            label={<span className={labelClassName}>Address</span>}
            className="mb-4"
          >
            <Input.Group></Input.Group>
          </Form.Item> */}

          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Office Number</span>}
            name="officerNumber"
            rules={[
              { required: true, message: "Please enter the Officer Number." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Email</span>}
            name="officerEmail"
            rules={[
              { required: true, message: "Please enter the Email" },
              { type: "email", message: "Please insert valid email" },
            ]}
          >
            <Input
              onInput={(e) =>
                ((e.target as HTMLInputElement).value = (
                  e.target as HTMLInputElement
                ).value.toLowerCase())
              }
            />
          </Form.Item>

          {data != null ? (
            <>
              <Form.Item
                className="mb-4"
                label={<span className={labelClassName}>Password</span>}
                name="password"
              >
                <UpdatePasswordModal callback={onFinish} form={form} />
              </Form.Item>
              <Form.Item
                className="mb-4"
                label={<span className={labelClassName}>Status</span>}
                name="isActive"
                valuePropName="checked"
              >
                <Switch defaultChecked={false} />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              className="mb-4"
              label={<span className={labelClassName}>Password</span>}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input officer password.",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default FieldVisitOfficerFormModal;
