import { SME_ORGANIZATION_TYPE } from "../../../enum/smeApplication";
import InfoGrid from "../../../components/InfoGrid";
import {
  EditDisbursementDetailsModal
} from "../../SmeApplication/ApplicationDetails/AdditionalDetailsPanel/DisbursementInfoTab/EditDisbursementDetails";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export const SmeDisbursementDetails = () => {
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const data = accountEnquiry?.data?.accountDetails?.smeApplication;

  const disbursementData = [
    {
      title: "Personal Account Name",
      value: data?.personalBankAccountName,
    },
    {
      title: "NRIC",
      value: data?.nric,
    },
    {
      title: "Bank Name",
      value: data?.personalBankName,
    },
    {
      title: "Personal Account Number",
      value: data?.personalBankAccountNo,
    },
    {
      title: "Contact No.",
      value: data?.applicantPhoneNo,
    },
  ];

  const companyDisbursementData = [
    {
      title: "Company Account Name",
      value: data?.bankAccountName,
    },
    {
      title: "Business Registration No.",
      value: data?.businessOldRegistrationNo || data?.businessNewRegistrationNo,
    },
    {
      title: "Company Bank Name",
      value: data?.bankName,
    },
    {
      title: "Company Account Number",
      value: data?.bankAccountNo,
    },
    {
      title: "Business Contact No.",
      value: data?.businessContactNo,
    },
  ];

  return (
    <div className="info-container">
      {accountEnquiry?.data?.accountDetails?.organizationType !== SME_ORGANIZATION_TYPE.COMPANY ? (
        <InfoGrid
          title="Personal Disbursement Details"
          data={disbursementData}
        />
      ) : (
        <InfoGrid
          title="Company Disbursement Details"
          data={companyDisbursementData}
        />
      )}
    </div>
  );
}