import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../../helpers/errorMessage";
import caseAssignmentService from "./caseAssignmentService";

export const getUsersCaseCountThunk = createAsyncThunk(
  "caseAssignment/getUsersCaseCount",
  async (requestData: any, thunkAPI) => {
    try {
      return await caseAssignmentService.getUsersCaseCount(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
