import { createSlice } from "@reduxjs/toolkit";
import { getPaymentNoticeConfigThunk } from "../../services/paymentNoticeService/paymentNoticeThunk";

export enum SendMethod {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export enum LetterType {
  BEFORE_DUE_DATE = "BEFORE_DUE_DATE",
  AFTER_DUE_DATE_T_STAGE1 = "AFTER_DUE_DATE_T_STAGE1",
  AFTER_DUE_DATE_T_STAGE2 = "AFTER_DUE_DATE_T_STAGE2",
  RECURRING_2_DAYS_BEFORE_DUE_DATE = "RECURRING_2_DAYS_BEFORE_DUE_DATE",
  RECURRING_DUE_DATE = "RECURRING_DUE_DATE",
  RECURRING_2_DAYS_AFTER_DUE_DATE = "RECURRING_2_DAYS_AFTER_DUE_DATE",
}

export type PaymentNoticeConfigType = {
  id: string | number;
  DPD: number;
  letterType: LetterType;
  sendMethod: SendMethod;
  createdAt?: string;
  updatedAt?: string;
  deleted?: boolean;
};

export type PaymentNoticeConfigState = {
  paymentNoticeConfigState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: PaymentNoticeConfigType[];
  };
};

const initialState: PaymentNoticeConfigState = {
  paymentNoticeConfigState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const paymentNoticeConfigSlice = createSlice({
  name: "paymentNoticeConfig",
  initialState,
  reducers: {
    paymentNoticeConfigReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentNoticeConfigThunk.pending, (state) => {
        state.paymentNoticeConfigState.isLoading = true;
      })
      .addCase(getPaymentNoticeConfigThunk.fulfilled, (state, action) => {
        state.paymentNoticeConfigState.isLoading = false;
        state.paymentNoticeConfigState.data = action.payload.configList.sort(
          (a: PaymentNoticeConfigType, b: PaymentNoticeConfigType) =>
            +a.DPD - +b.DPD,
        );
      })
      .addCase(getPaymentNoticeConfigThunk.rejected, (state, action) => {
        state.paymentNoticeConfigState.isLoading = false;
        state.paymentNoticeConfigState.isError = true;
        state.paymentNoticeConfigState.errorMessage = action.payload;
      });
  },
});
export const { paymentNoticeConfigReset } = paymentNoticeConfigSlice.actions;

export default paymentNoticeConfigSlice.reducer;
