import "./style.less";

import { Result } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import InfoList from "../../../../components/InfoList";
import { AppDispatch, RootState } from "../../../../store/store";
import { numberWithCommas } from "../../../../helpers/number";
import { displayDate } from "../../../../utils/datetime.util";

const RescheduleCalculationContent = () => {
  const { rescheduleId } = useParams();
  const { rescheduleDetails } = useSelector(
    (state: RootState) => state.reschedule,
  );
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const accountDetails = accountEnquiry?.data?.accountDetails;
  const accountCharges = accountDetails?.accountCharges;

  const netOutstanding =
    Number(accountDetails?.principalOutstanding) +
    Number(accountDetails?.interestOutstanding) +
    Number(accountCharges?.latePaymentCharge) +
    Number(accountCharges?.reminderLetter1Charge) +
    Number(accountCharges?.reminderLetter2Charge) +
    Number(accountCharges?.reminderLetter3Charge) +
    Number(accountCharges?.earlySettlementCharge) +
    Number(accountCharges?.ecaCharge) +
    Number(accountCharges?.fieldCharge) +
    Number(accountCharges?.legalCharge) +
    Number(accountCharges?.lodCharge) +
    Number(accountCharges?.miscCharge) +
    Number(accountCharges?.refundCharge) +
    Number(accountCharges?.blacklistUpliftCharge) +
    Number(accountCharges?.adminCharge) +
    Number(accountCharges?.bankCharge);

  const data = [
    {
      title: "Reschedule Date",
      value:
        rescheduleDetails?.data?.rescheduleDate != null
          ? `${displayDate(rescheduleDetails?.data?.rescheduleDate)}`
          : "-",
    },
    {
      title: "Principie O/B",
      value:
        accountDetails?.principalOutstanding != null
          ? `RM ${numberWithCommas(accountDetails?.principalOutstanding)}`
          : "-",
    },
    {
      title: "Interest O/B",
      value:
        accountDetails?.interestOutstanding != null
          ? `RM ${numberWithCommas(accountDetails?.interestOutstanding)}`
          : "-",
    },
    {
      title: "Late Payment Charges O/B",
      value:
        accountCharges?.latePaymentCharge != null
          ? `RM ${numberWithCommas(accountCharges?.latePaymentCharge)}`
          : "-",
    },
    {
      title: "Reminder Letter 1 O/B",
      value:
        accountCharges?.reminderLetter1Charge != null
          ? `RM ${numberWithCommas(accountCharges?.reminderLetter1Charge)}`
          : "-",
    },
    {
      title: "Reminder Letter 2 O/B",
      value:
        accountCharges?.reminderLetter2Charge != null
          ? `RM ${numberWithCommas(accountCharges?.reminderLetter2Charge)}`
          : "-",
    },
    {
      title: "Reminder Letter 3 O/B",
      value:
        accountCharges?.reminderLetter3Charge != null
          ? `RM ${numberWithCommas(accountCharges?.reminderLetter3Charge)}`
          : "-",
    },
    {
      title: "Early Settlement Charges O/B",
      value:
        accountCharges?.earlySettlementCharge != null
          ? `RM ${numberWithCommas(accountCharges?.earlySettlementCharge)}`
          : "-",
    },
    {
      title: "External Collection Agency Charge O/B",
      value:
        accountCharges?.ecaCharge != null
          ? `RM ${numberWithCommas(accountCharges?.ecaCharge)}`
          : "-",
    },
    {
      title: "Field Visit Charge 0/B",
      value:
        accountCharges?.fieldCharge != null
          ? `RM ${numberWithCommas(accountCharges?.fieldCharge)}`
          : "-",
    },
    {
      title: "Legal Charge O/B",
      value:
        accountCharges?.legalCharge != null
          ? `RM ${numberWithCommas(accountCharges?.legalCharge)}`
          : "-",
    },
    {
      title: "LOD O/B",
      value:
        accountCharges?.lodCharge != null
          ? `RM ${numberWithCommas(accountCharges?.lodCharge)}`
          : "-",
    },
    {
      title: "Misc. Charge O/B",
      value:
        accountCharges?.miscCharge != null
          ? `RM ${numberWithCommas(accountCharges?.miscCharge)}`
          : "-",
    },
    {
      title: "Refund Charge",
      value:
        accountCharges?.refundCharge != null
          ? `RM ${numberWithCommas(accountCharges?.refundCharge)}`
          : "-",
    },
    {
      title: "Credit Info. Blacklist Uplift Charge O/B",
      value:
        accountCharges?.blacklistUpliftCharge != null
          ? `RM ${numberWithCommas(accountCharges?.blacklistUpliftCharge)}`
          : "-",
    },
    {
      title: "Admin Charge O/B",
      value:
        accountCharges?.adminCharge != null
          ? `RM ${numberWithCommas(accountCharges?.adminCharge)}`
          : "-",
    },
    {
      title: "Bank Charge O/B",
      value:
        accountCharges?.bankCharge != null
          ? `RM ${numberWithCommas(accountCharges?.bankCharge)}`
          : "-",
    },
    {
      title: "Net Outstanding",
      value:
        accountDetails?.principalOutstanding != null
          ? `RM ${numberWithCommas(netOutstanding)}`
          : "-",
    },
  ];

  return (
    <>
      {rescheduleId ? (
        <div className="reschedule-details-section-container">
          <InfoList data={data} />
        </div>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, reschedule not found."
        />
      )}
    </>
  );
};

export default RescheduleCalculationContent;
