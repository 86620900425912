import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import locationService from "./locationService";

export const getCityStateByPostcodeThunk = createAsyncThunk(
  "location/getCityStateByPostcode",
  async (requestData: any, thunkAPI) => {
    try {
      return await locationService.getCityStateByPostcode(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
