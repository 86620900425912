import React from "react";
import { Collapse } from "antd";
import InfoGrid from "../../../../components/InfoGrid";
import { displayDate } from "../../../../utils/datetime.util";
import { numberWithCommas } from "../../../../helpers/number";
import { onCalculateEstimatedMonthlyAmount } from "../../../../utils/calculation.util";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { SME_ORGANIZATION_TYPE } from "../../../../enum/smeApplication";
import { Role } from "../../../../enum/roles";
import jwt_decode from "jwt-decode";

const { Panel } = Collapse;

export const LoanApplicationPanel = () => {
  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const defaultLoanApplicationData = [
    {
      title: "Application Date",
      value: displayDate(smeApplicationDetails?.data?.createdAt),
    },
    {
      title: "Application No.",
      value: smeApplicationDetails?.data?.applicationNo ?? "-",
    },
    {
      title: "Campaign Code",
      value: smeApplicationDetails?.data?.promoCode ?? "-",
    },
    {
      title: "Discount Amount",
      value: smeApplicationDetails?.data?.processingFeeToDiscount
        ? `RM ${numberWithCommas(
          smeApplicationDetails?.data?.processingFeeToDiscount,
        )}`
        : "-",
    },
    {
      title: "Partner Type",
      value: smeApplicationDetails?.data?.smePartner?.agentTypeName,
    },
    {
      title: "Partner ID",
      value: smeApplicationDetails?.data?.redOneUsername,
    },
    {
      title: "Organization Type",
      value: smeApplicationDetails?.data?.organizationType,
    },
    {
      title: "Tax Identification Number (TIN)",
      value: smeApplicationDetails?.data?.smePartner?.tin,
    },
    {
      title: "Applied Loan Amount",
      value: `RM ${numberWithCommas(Number(smeApplicationDetails?.data?.appliedLoanAmount))}`,
    },
    {
      title: "Monthly Instalment",
      value: `RM ${numberWithCommas(
        onCalculateEstimatedMonthlyAmount(
          Number(smeApplicationDetails?.data?.appliedLoanAmount) || 0,
          Number(smeApplicationDetails?.data?.appliedLoanInterestRate) || 0,
          Number(smeApplicationDetails?.data?.appliedLoanTenure) || 0,
        ),
      )}`,
    },
    {
      title: "Instalment Period",
      value: Number(smeApplicationDetails?.data?.appliedLoanTenure) || "-",
    },
    {
      title: "Interest Rate",
      value: `${Number(smeApplicationDetails?.data?.appliedLoanInterestRate) || 0} %`,
    },
    {
      title: "Loan Reason",
      value: smeApplicationDetails?.data?.loanReason,
    },
    {
      title: "Marketing Consent",
      value: smeApplicationDetails?.data?.acceptMarketingConsent ? "Yes" : "No",
    },
    {
      title: "Promo Code",
      value: smeApplicationDetails?.data?.promoCode,
    },
    {
      title: "Current Existing Loan",
      value: "No", // TODO: get this info from collection service once it is available
    },
  ];

  const solePropLoanApplicationData = [
    {
      title: 'SSM Expiry Date',
      value: displayDate(smeApplicationDetails?.data?.smePartner?.ssmExpiryDate),
    }
  ]

  const loanApplicationData = [
    ...defaultLoanApplicationData,
    ...(smeApplicationDetails?.data?.organizationType !== SME_ORGANIZATION_TYPE.INDIVIDUAL ? solePropLoanApplicationData : []),
  ]


  return (
    <Collapse
      ghost
      expandIconPosition="end"
      defaultActiveKey={currentRole !== Role.APPROVER_2 ? ['loanApplication'] : []}
    >
      <Panel
        key="loanApplication"
        className="application-details-section-container"
        header={
          <h3 className="application-details-section-title">
            Loan Application
          </h3>
        }
      >
        <InfoGrid data={loanApplicationData} />
      </Panel>
    </Collapse>
  );
};