import "./index.less";

import type { DatePickerProps } from "antd";
import { Button, DatePicker, Input, message, Space, Table } from "antd";
import startCase from "lodash.startcase";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { dangerIconRed, successTextGreen } from "../../../assets/style/variables";
import { numberWithCommas } from "../../../helpers/number";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";

import type { ColumnsType, TablePaginationConfig, TableProps } from "antd/es/table";
import { SmeDisbursementBatch } from "../../../features/smeDisbursement/smeDisbursementSlice";
import {
  getDisbursementListingSummaryPdfThunk,
  getSmeDisbursementBatchBankFileThunk,
  getSmeDisbursementBatchListingThunk,
} from "../../../services/smeDisbursementService/smeDisbursementThunk";
import { downloadLink } from "../../../helpers/downloadLink";
import { SME_DISBURSEMENT_BATCH_STATUS } from "../../../enum/smeApplicationStatus";
import type { SorterResult } from "antd/es/table/interface";
import { SmeAgreement } from "../../../features/smeAgreement/smeAgreementSlice";

const { Search } = Input;

const CreatedDisbursementBatchListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { smeDisbursementBatchListing } = useSelector(
    (state: RootState) => state.smeDisbursement,
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [downloading, setDownloading] = useState(false);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("batchNo");
  const [searchValue, setSearchValue] = useState("");

  const tablePagination = {
    total: smeDisbursementBatchListing?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const fetchSmeDisbursementBatchListing = async (params?: any) => {
    dispatch(getSmeDisbursementBatchListingThunk({
      ...initialParams,
      ...params
    }));
  }

  const onDownloadBankFiles = async (batch: SmeDisbursementBatch) => {
    setDownloading(true);
    message.loading("Downloading");

    await dispatch(getSmeDisbursementBatchBankFileThunk(batch?.id))
      .unwrap()
      .then(downloadLink)
      .finally(() => {
        setDownloading(false);
      });

    await fetchSmeDisbursementBatchListing()

    await dispatch(getDisbursementListingSummaryPdfThunk(batch?.id)).unwrap().then(downloadLink).finally(() => setDownloading(false));

  };

  const createdBatchTableColumns: ColumnsType<any> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Completed Date",
      dataIndex: "completedDate",
      key: "completedDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
      key: "batchNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/sme-disbursement-details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Total Finance Amount",
      dataIndex: "totalApprovedLoanAmount",
      key: "totalApprovedLoanAmount",
      render: (text) => (text != null ? `RM ${numberWithCommas(+text)}` : "-"),
    },
    {
      title: "Total Disbursement Amount",
      dataIndex: "totalDisbursementAmount",
      key: "totalDisbursementAmount",
      render: (text) => `RM ${numberWithCommas(+text)}` ?? "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          style={{
            color:
              text === SME_DISBURSEMENT_BATCH_STATUS.INCOMPLETE
                ? dangerIconRed
                : text === SME_DISBURSEMENT_BATCH_STATUS.COMPLETED
                  ? successTextGreen
                  : "black",
          }}
        >
          {startCase(text === SME_DISBURSEMENT_BATCH_STATUS.INCOMPLETE ? "INCOMPLETE" : text ?? "-")}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {record?.status !== SME_DISBURSEMENT_BATCH_STATUS.COMPLETED && (
            <Button
              key={record.id}
              onClick={() => onDownloadBankFiles(record)}
            >
              Download
            </Button>
          )}
        </>
      ),
    },
  ];

  const onSearch = async (value: string) => {
    const filters = {
      ...filterState,
    };

    const params = {
      ...initialParams,
      filters,
      search: {
        batchNo: value.toUpperCase(),
      },
    };
    fetchSmeDisbursementBatchListing(params);
    setFilterState(filters);
  };

  const handleTableChange: TableProps<SmeDisbursementBatch>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const params =
      searchValue.length === 0
        ? {
          ...initialParams,
          pagination,
          sortField: sorter?.columnKey,
          sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
        }
        : {
          ...initialParams,
          pagination,
          sortField: sorter?.columnKey,
          sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          search: {
            [searchFiled]: searchValue.toUpperCase(),
          },
        };

    fetchSmeDisbursementBatchListing(params);
    setSortedInfo(sorter as SorterResult<SmeDisbursementBatch>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 20,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 20}`);
    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchSmeDisbursementBatchListing(params);
  });

  return (
    <>
      <Helmet>
        <title>Created Disbursement - redCASH CEP</title>
      </Helmet>

      <Space className="mb-4">
        <Search
          placeholder="Batch No."
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Space>

      <Table
        rowKey="id"
        className="min-w-fit"
        columns={createdBatchTableColumns}
        pagination={tablePagination}
        dataSource={smeDisbursementBatchListing?.data?.data}
        loading={smeDisbursementBatchListing?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default CreatedDisbursementBatchListingPage;
