import { WriteOffStatusEnum } from "../enum/writeOff";

export const WriteOffCategoryLabel = {
  DCE: "DECEASED",
  FRD: "Fraud",
  CMO: "Customer Migrated Oversea",
  BKC: "Customer Under Bankruptcy",
  DCL: "Deferred Charge Lost",
  OFL: "Overdue Fees & Charges Lost",
  CDP: "Customer Default Payment",
  CUT: "Customer Untraceable",
  OTH: "Others",
};

export const WriteOffSummaryDetailsPageLabel = {
  [WriteOffStatusEnum.DRAFT]: "Proposal",
  [WriteOffStatusEnum.NEW]: "Proposal",
  [WriteOffStatusEnum.REFERBACK]: "Details",
  [WriteOffStatusEnum.PROCESSING]: "Verifying",
  [WriteOffStatusEnum.PENDING]: "Approval",
  [WriteOffStatusEnum.VOID]: "Details",
  [WriteOffStatusEnum.APPROVED]: "Details",
};
