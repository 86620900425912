import { createSlice } from "@reduxjs/toolkit";
import { getAllTeamThunk } from "../../services/caseAssignmentService/teamService/teamThunk";

export type Team = {
  id: string;
  teamCode: string;
  createdAt?: string;
  updatedAt?: string;
};

export type TeamState = {
  teamsState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Team[];
  };
};

const initialState: TeamState = {
  teamsState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    teamReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTeamThunk.pending, (state) => {
        state.teamsState.isLoading = true;
      })
      .addCase(getAllTeamThunk.fulfilled, (state, action) => {
        state.teamsState.isLoading = false;
        state.teamsState.data = action.payload.data;
      })
      .addCase(getAllTeamThunk.rejected, (state, action) => {
        state.teamsState.isLoading = false;
        state.teamsState.isError = true;
        state.teamsState.errorMessage = action.payload;
      });
  },
});

export const { teamReset } = teamSlice.actions;
export default teamSlice.reducer;
