import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const saveDunningInfo = async (requestData: any) => {
  const { payload } = requestData;
  const url = `/redCash/api/v1.0/collectionActivity/create`;
  const res = await collectionSetupAxiosInstance.post(url, payload);
  return res.data;
};

const updateDunningInfo = async (requestData: any) => {
  const { payload } = requestData;
  const url = `/redCash/api/v1.0/collectionActivity/update`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const getAllDunningHistory = async (requestData: any) => {
  const { accountDetailsId } = requestData;
  const url = `/redCash/api/v1.0/collectionActivity/accountDetails/${accountDetailsId}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getAllDunningContactMode = async () => {
  const url = `/redCash/api/v1.0/collectionActivity/dunningContactMode/listing`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const dunningService = {
  saveDunningInfo,
  getAllDunningHistory,
  getAllDunningContactMode,
  updateDunningInfo,
};

export default dunningService;
