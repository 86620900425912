import React, { useEffect } from "react";
import { Modal, Form, Input, Select, DatePicker, Button } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { AppDispatch, RootState } from "../../../../../../store/store";
import { icTypeOptions, SME_ORGANIZATION_TYPE } from "../../../../../../enum/smeApplication";
import {
  getSmeApplicationDetailsThunk, patchSmeIndividualApplicationInfoByIdThunk,
  patchSmePartnershipApplicationInfoByIdThunk, patchSmeSolePropsApplicationInfoByIdThunk,
} from "../../../../../../services/smeApplicationService/smeApplicationThunk";
import moment from "moment";
import {
  getEmployeeTypeListThunk, getRaceListThunk,
  getRelationshipListThunk,
} from "../../../../../../services/dropdownParameterService/dropdownParameterThunk";
import { getNricInfo } from "../../../../../../utils/nric.util";
import { getCityStateByPostcodeThunk } from "../../../../../../services/locationService/locationThunk";
import jwt_decode from "jwt-decode";

export const EditApplicationInfoModal = () => {

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { smeApplicationDetails } = useSelector(
    (state: RootState) => state.smeApplication
  );

  const { raceState, employeeTypeState, relationshipState } = useSelector((state: RootState) => state.dropdownParameter)
  const raceOptions = raceState?.data.map((item) => ({
    label: item.name,
    value: item.name,
  }));
  const employeeTypeOptions = employeeTypeState?.data.map((item) => ({
    label: item.name,
    value: item.name,
  }));
  const relationshipOptions = relationshipState?.data.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const [isOpen, setIsOpen] = React.useState(false);

  const onClose = () => {
    setIsOpen(false);
  }

  const data: any = smeApplicationDetails?.data?.smeIndividualApplication ||
    smeApplicationDetails?.data?.smeSolePropApplication ||
    smeApplicationDetails?.data?.smePartnershipApplication;

  React.useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        ...data,
        dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth) : undefined
      });
    }
  }, [data, isOpen, form]);

  const handleSubmit = async (values: any) => {
    try {
      // Transform the data as needed
      const formattedValues = {
        id: smeApplicationDetails?.data?.id,
        ...values,
        dateOfBirth: moment(getNricInfo(values.nric).birthDate).toISOString(),
        occupationType: values.occupationType,
      };

      console.log('Formatted values:', formattedValues);

      if (smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.PARTNERSHIP) {
        await dispatch(patchSmePartnershipApplicationInfoByIdThunk(formattedValues)).unwrap()
      }

      if (smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.INDIVIDUAL) {
        await dispatch(patchSmeIndividualApplicationInfoByIdThunk(formattedValues)).unwrap()
      }

      if (smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.SOLE_PROP) {
        await dispatch(patchSmeSolePropsApplicationInfoByIdThunk(formattedValues)).unwrap()
      }

      dispatch(getSmeApplicationDetailsThunk({
        applicationId: smeApplicationDetails?.data?.id
      }));

      onClose();
      form.resetFields();
    } catch (error) {
      console.error('Error updating applicant info:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getRelationshipListThunk())
      dispatch(getEmployeeTypeListThunk())
      dispatch(getRaceListThunk())
    }
  }, [isOpen]);

  const handleHomePostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 5) {
      dispatch(getCityStateByPostcodeThunk({ postcode: e.target.value }))
        .unwrap()
        .then((res) => {
          form.setFieldsValue({
            homeCity: res[0].post_office,
            homeState: res[0].state_code,
          });
        });
    } else {
      form.setFieldsValue({
        homeCity: null,
        homeState: null,
      });
    }
  };

  const handleNricPostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 5) {
      dispatch(getCityStateByPostcodeThunk({ postcode: e.target.value }))
        .unwrap()
        .then((res) => {
          form.setFieldsValue({
            nricCity: res[0].post_office,
            nricState: res[0].state_code,
          });
        });
    } else {
      form.setFieldsValue({
        nricCity: null,
        nricState: null,
      });
    }
  };

  const handleMailingPostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 5) {
      dispatch(getCityStateByPostcodeThunk({ postcode: e.target.value }))
        .unwrap()
        .then((res) => {
          form.setFieldsValue({
            mailingCity: res[0].post_office,
            mailingState: res[0].state_code,
          });
        });
    } else {
      form.setFieldsValue({
        mailingCity: null,
        mailingState: null,
      });
    }
  };

  return (
    <>
      {
        (currentRole === 'VERIFIER' || currentRole === 'VERIFIER_2' || currentRole === 'SUPER_ADMIN') && (
          <Button onClick={() => setIsOpen(true)}>Edit</Button>
        )
      }
      <Modal
        title="Edit Applicant Information"
        open={isOpen}
        onCancel={onClose}
        onOk={() => form.submit()}
        width={1600}
        className="overflow-auto"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="mt-4"
        >
          {/* Customer Information Section */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Customer Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item name="name" label="Full Name" rules={[{ required: true, message: 'Full Name is Required' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="nationality" label="Nationality" rules={[{ required: true, message: 'Nationality is Required' }]}>
                <Select options={[{ value: "MALAYSIAN", label: "Malaysian" }]} />
              </Form.Item>
              <Form.Item name="applicantRace" label="Race" rules={[{ required: true, message: 'Race is Required' }]}>
                <Select
                  loading={raceState.isLoading}
                  options={raceOptions}
                />
              </Form.Item>
              <Form.Item name="icType" label="Identity Card Type" rules={[{ required: true, message: 'Identity Card Type is Required' }]}>
                <Select
                  placeholder={'Please Select'}
                  options={icTypeOptions}
                />
              </Form.Item>
              <Form.Item name="nric" label="NRIC" rules={[{ required: true, message: 'NRIC is Required' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="dateOfBirth" label="Date of Birth" rules={[{ required: true, message: 'Date of Birth is Required' }]}>
                <DatePicker className="w-full" disabled={true}/>
              </Form.Item>
              <Form.Item name="emailAddress" label="Email Address" rules={[{ required: true, message: 'Email Address is Required' }, { type: 'email', message: 'Email is Invalid.' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="applicantPhoneNo" label="Contact No." rules={[{ required: true, message: 'Contact No is Required' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="occupationType" label="Occupation Type" rules={[{ required: true, message: 'Occupation Type is Required' }]}>
                <Select options={[{ value: "DEALER", label: "Dealer" }]} />
              </Form.Item>
            </div>
          </div>

          {/* Address Information Section */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Address Information</h3>

            {/* NRIC Address */}
            <div className="mb-4">
              <h4 className="font-medium mb-2">NRIC Address</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Form.Item name="nricAddress1" label="Address Line 1" rules={[{ required: true, message: 'NRIC Address 1 is Required' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="nricAddress2" label="Address Line 2">
                  <Input />
                </Form.Item>
                <Form.Item name="nricAddress3" label="Address Line 3">
                  <Input />
                </Form.Item>
                <Form.Item name="nricPostcode" label="Postcode" rules={[{ required: true, message: 'Postcode is Required' }]}>
                  <Input onChange={handleNricPostcodeChange}/>
                </Form.Item>
                <Form.Item name="nricCity" label="City" rules={[{ required: true, message: 'City is Required' }]}>
                  <Input disabled={true}/>
                </Form.Item>
                <Form.Item name="nricState" label="State" rules={[{ required: true, message: 'State is Required' }]}>
                  <Input disabled={true}/>
                </Form.Item>
              </div>
            </div>

            {/* Home Address */}
            <div className="mb-4">
              <h4 className="font-medium mb-2">Home Address</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Form.Item name="homeAddress1" label="Address Line 1" rules={[{ required: true, message: 'Address 1 is Required' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="homeAddress2" label="Address Line 2">
                  <Input />
                </Form.Item>
                <Form.Item name="homeAddress3" label="Address Line 3">
                  <Input />
                </Form.Item>
                <Form.Item name="homePostcode" label="Postcode" rules={[{ required: true, message: 'Postcode is Required' }]}>
                  <Input onChange={handleHomePostcodeChange}/>
                </Form.Item>
                <Form.Item name="homeCity" label="City" rules={[{ required: true, message: 'City is Required' }]}>
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item name="homeState" label="State" rules={[{ required: true, message: 'State is Required' }]}>
                  <Input disabled={true} />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* Mailing Address */}
          <div className="mb-4">
            <h4 className="font-medium mb-2">Mailing Address</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item name="mailingAddress1" label="Address Line 1" rules={[{ required: true, message: 'Mailing Address 1 is Required' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="mailingAddress2" label="Address Line 2">
                <Input />
              </Form.Item>
              <Form.Item name="mailingAddress3" label="Address Line 3">
                <Input />
              </Form.Item>
              <Form.Item name="mailingPostcode" label="Postcode" rules={[{ required: true, message: 'Postcode is Required' }]}>
                <Input onChange={handleMailingPostcodeChange}/>
              </Form.Item>
              <Form.Item name="mailingCity" label="City" rules={[{ required: true, message: 'City is Required' }]}>
                <Input disabled={true}/>
              </Form.Item>
              <Form.Item name="mailingState" label="State" rules={[{ required: true, message: 'State is Required' }]}>
                <Input disabled={true}/>
              </Form.Item>
            </div>
          </div>

          {/* Employment Information */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Employment Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item name="employerName" label="Name of Employer / Nature of Business" rules={[{ required: true, message: 'Name of Employer / Nature of Business is Required' }]}>
                <Input />
              </Form.Item>
              {
                smeApplicationDetails?.data?.organizationType === SME_ORGANIZATION_TYPE.INDIVIDUAL && (
                  <Form.Item name="applicantEmploymentType" label="Employment Type" rules={[{ required: true, message: 'Employment Type is Required' }]}>
                    <Select
                      loading={employeeTypeState.isLoading}
                      options={employeeTypeOptions}
                    />
                  </Form.Item>
                )
              }
            </div>
          </div>

          {/* Emergency Information */}
          {smeApplicationDetails?.data?.organizationType !== SME_ORGANIZATION_TYPE.COMPANY && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-4">Emergency Information</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Form.Item name="emergencyContactPersonName" label="Emergency Name" rules={[{ required: true, message: 'Emergency Name is Required' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="emergencyContactPersonPhoneNo" label="Emergency Contact Number" rules={[{ required: true, message: 'Emergency Contact Number is Required' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="emergencyContactEmail" label="Emergency Email" rules={[{ required: true, message: 'Emergency Email is Required' }, { type: 'email', message: 'Email is Invalid' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="emergencyContactRelationship" label="Relationship" rules={[{ required: true, message: 'Relationship is Required' }]}>
                  <Select
                    loading={relationshipState.isLoading}
                    options={relationshipOptions}
                  />
                </Form.Item>
              </div>
            </div>
          )}
        </Form>
      </Modal>
    </>
  );
};