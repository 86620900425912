import { Button, Input, message, Popconfirm, Select, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Agreement } from "../../../features/agreement/agreementSlice";
import { numberWithCommas } from "../../../helpers/number";
import {
  approveEStampingThunk,
  getUploadedEStampAgreementsThunk,
} from "../../../services/agreementService/agreementThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { onCalculateNetDisbursementAmount } from "../../../utils/calculation.util";
import { displayDate } from "../../../utils/datetime.util";

import type { TableProps } from "antd/es/table";
import type { ColumnsType } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";

const { Search } = Input;

const UploadedEStampTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { uploadedEStampAgreements } = useSelector(
    (state: RootState) => state.agreement,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<Agreement>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");
  const [approveLoading, setApproveLoading] = useState(false);

  const tablePagination = {
    total: uploadedEStampAgreements?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };

  const fetchAgreements = async (params?: any) => {
    await dispatch(
      getUploadedEStampAgreementsThunk({
        ...params,
        companyId: process.env.REACT_APP_COMPANY_ID,
      }),
    );
  };

  const onApprove = async (agreementId: string, data: any) => {
    setApproveLoading(true);
    console.log(data.application.language);
    let language = "EN";
    if (data.application.language && data.application.language === "MS") {
      language = "BM";
    }
    await dispatch(approveEStampingThunk({ agreementId, language }))
      .unwrap()
      .then(() => {
        message.success("Approved");
        fetchAgreements(initialParams);
      })
      .finally(() => setApproveLoading(false));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchAgreements({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      fetchAgreements({
        ...initialParams,
        filters: { ...filterState },
      });
      setSearchValue("");
    }

    setFilterState({
      ...initialParams.filters,
    });
  };

  const handleTableChange: TableProps<Agreement>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    setSortedInfo(sorter as SorterResult<Agreement>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    const param = {
      ...initialParams,
      ...filterState,
      pagination,
      sortField: sorter?.order ? sorter?.columnKey : "createdAt",
      sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
      ...(searchValue.length > 0 && {
        search: {
          [searchFiled]: searchValue.toUpperCase(),
        },
      }),
    };

    fetchAgreements(param);

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      sorter: (a, b) => a.signDate - b.signDate,
      sortOrder: sortedInfo.columnKey === "signDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Completed Date",
      dataIndex: "approveEStampingCertDate",
      key: "approveEStampingCertDate",
      sorter: (a, b) => a.approveEStampingCertDate - b.approveEStampingCertDate,
      sortOrder:
        sortedInfo.columnKey === "approveEStampingCertDate"
          ? sortedInfo.order
          : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/agreement/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.application?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant Contact No.",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.application?.applicantPhoneNo ?? "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.application?.applicantNric ?? "-",
    },
    {
      title: "Finance Amount",
      key: "financeAmount",
      render: (text, record) =>
        record?.application?.financeAmount
          ? `RM ${numberWithCommas(record?.application?.financeAmount)}`
          : "-",
    },
    {
      title: "Status",
      dataIndex: "approveUploadedEStampingCert",
      key: "approveUploadedEStampingCert",
      render: (text, record) =>
        record?.approveUploadedEStampingCert &&
        record?.eStampingCertificateFileId != null
          ? "Approved"
          : "Uploaded",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const approved =
          record?.approveUploadedEStampingCert &&
          record?.eStampingCertificateFileId != null;
        return (
          <Popconfirm
            title="Approve this e-stamp?"
            onConfirm={() => onApprove(record.id, record)}
            okText="Yes"
            cancelText="No"
            disabled={approved}
          >
            <Button type="primary" disabled={approved}>
              {approved ? "Approved" : "Approve"}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Uploaded E-Stamp - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={uploadedEStampAgreements?.data?.data ?? []}
        loading={uploadedEStampAgreements?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default UploadedEStampTab;
