import { Skeleton } from "antd";
import React from "react";

export type LoadingParagraphProps = {
  size?: number;
};

const LoadingParagraph: React.FC<LoadingParagraphProps> = (props) => {
  return (
    <div className="p-8">
      <Skeleton active paragraph={{ rows: 5 }} />
      <div className="p-4" />
      <Skeleton active paragraph={{ rows: 2 }} />
      <div className="p-4" />
      <Skeleton active paragraph={{ rows: 4 }} />
    </div>
  );
};

LoadingParagraph.defaultProps = {
  size: 100,
};

export default LoadingParagraph;
