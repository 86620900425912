import { Tabs, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";

import { AOC_BATCH_STATUS } from "../../../enum/aocBatchStatus";
import {
  getPendingEStampAgreementsThunk,
  getUploadedEStampAgreementsThunk,
} from "../../../services/agreementService/agreementThunk";
import {
  getAllAocThunk,
  getApprovedAocThunk,
  getOpenAocThunk,
  getPendingAocThunk,
  getReferbackAocThunk,
  getVoidAocThunk,
} from "../../../services/aocService/aocThunk";
import { AppDispatch, RootState } from "../../../store/store";
import AocTable from "./AocTable";

import type { TabsProps } from "antd";

const AllAocListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const {
    allAocList,
    openAocList,
    pendingAocList,
    referbackAocList,
    voidAocList,
    approvedAocList,
  } = useSelector((state: RootState) => state.aoc);

  const items: TabsProps["items"] = [
    {
      key: "all",
      label: (
        <span>
          All{" "}
          {allAocList?.data?.data != null && (
            <Tag>{allAocList?.data?.total}</Tag>
          )}
        </span>
      ),
      children: <AocTable dataSource={allAocList} />,
    },
    {
      key: "open",
      label: (
        <span>
          Open{" "}
          {allAocList?.data?.data != null && (
            <Tag>{openAocList?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <AocTable dataSource={openAocList} status={AOC_BATCH_STATUS.OPEN} />
      ),
    },
    {
      key: "pending",
      label: (
        <span>
          Pending{" "}
          {pendingAocList?.data?.data != null && (
            <Tag>{pendingAocList?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <AocTable
          dataSource={pendingAocList}
          status={AOC_BATCH_STATUS.PENDING}
        />
      ),
    },
    {
      key: "referback",
      label: (
        <span>
          Referback{" "}
          {referbackAocList?.data?.data != null && (
            <Tag>{referbackAocList?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <AocTable
          dataSource={referbackAocList}
          status={AOC_BATCH_STATUS.REFERBACK}
        />
      ),
    },
    {
      key: "void",
      label: (
        <span>
          Void{" "}
          {voidAocList?.data?.data != null && (
            <Tag>{voidAocList?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <AocTable dataSource={voidAocList} status={AOC_BATCH_STATUS.VOID} />
      ),
    },
    {
      key: "approved",
      label: (
        <span>
          Approved{" "}
          {approvedAocList?.data?.data != null && (
            <Tag>{approvedAocList?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <AocTable
          dataSource={approvedAocList}
          status={AOC_BATCH_STATUS.APPROVED}
        />
      ),
    },
  ];

  const fetchPendingAgreements = async (params?: any) => {
    await dispatch(
      getPendingEStampAgreementsThunk({
        ...params,
        companyId: process.env.REACT_APP_COMPANY_ID,
      }),
    );
  };

  const fetchSignedAgreements = async (params?: any) => {
    await dispatch(
      getUploadedEStampAgreementsThunk({
        ...params,
        companyId: process.env.REACT_APP_COMPANY_ID,
      }),
    );
  };

  const fetchAllAocList = async (params?: any) => {
    await dispatch(getAllAocThunk(params));
  };

  const fetchOpenAocList = async (params?: any) => {
    await dispatch(getOpenAocThunk(params));
  };

  const fetchPendingAocList = async (params?: any) => {
    await dispatch(getPendingAocThunk(params));
  };

  const fetchReferbackAocList = async (params?: any) => {
    await dispatch(getReferbackAocThunk(params));
  };

  const fetchVoidAocList = async (params?: any) => {
    await dispatch(getVoidAocThunk(params));
  };

  const fetchApprovedAocList = async (params?: any) => {
    await dispatch(getApprovedAocThunk(params));
  };

  const initialCallbackState = {
    fetchPendingAgreements,
    fetchSignedAgreements,
    fetchAllAocList,
    fetchOpenAocList,
    fetchPendingAocList,
    fetchReferbackAocList,
    fetchVoidAocList,
    fetchApprovedAocList,
  };

  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    // callbackState.fetchPendingAgreements(initialParams);
    // callbackState.fetchSignedAgreements(initialParams);
    callbackState.fetchAllAocList(initialParams);
    callbackState.fetchOpenAocList(initialParams);
    callbackState.fetchPendingAocList(initialParams);
    callbackState.fetchPendingAocList(initialParams);
    callbackState.fetchReferbackAocList(initialParams);
    callbackState.fetchVoidAocList(initialParams);
    callbackState.fetchApprovedAocList(initialParams);
  }, [callbackState, initialParams]);

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Add-on Charges Listing" />
      <Tabs
        defaultActiveKey={activeTab ?? "all"}
        items={items}
        destroyInactiveTabPane
      />
    </>
  );
};

export default AllAocListing;
