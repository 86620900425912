import "./index.less";

import {
  Button,
  Dropdown,
  Input,
  message,
  Select,
  Skeleton,
  Space,
  Tabs,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-components";

import UpdateDunningInfoModal from "../../../components/UpdateDunningInfoModal";
import { collectionStateReset } from "../../../features/collection/collectionSlice";
import { searchDisbursedAgreementThunk } from "../../../services/agreementService/agreementThunk";
import {
  getAccountDetailsThunk,
  getAccountTenureDetailsThunk,
  getCollectionHistoryLogThunk,
  getDueAccountTenureDetailsThunk,
  getEnglishAccountStatementbyAgreementIdThunk,
  getMalayAccountStatementbyAgreementIdThunk,
} from "../../../services/collectionService/collectionThunk";
import { getDunningHistoryListingThunk } from "../../../services/dunningService/dunningThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { saveBlobAsPDF } from "../../../utils/file";
import AccountDetailsCard from "../AccountDetails";
import CustomerDetailsCard from "../CustomerDetails";
import DunningHistory from "../DunningHistory";
import EmergencyInformation from "../EmergencyInformation";
import EmploymentInformation from "../EmploymentInformation";
import InsertPaymentModal from "../InsertPaymentModal";
import LegalHistory from "../LegalHistory";
import LoanInformationCard from "../LoanInformationCard";
import NoticeHistory from "../NoticeHistory";
import PaymentHistory from "../PaymentHistory";
import SearchAgreementResult from "../SearchAgreementResult";
import SmsHistory from "../SmsHistory";
import SpecialTaggingHistory from "../SpecialTaggingHistory";
import TransactionHistoryCard from "../TransactionHistory";
import jwt_decode from "jwt-decode";

import type { MenuProps } from "antd";
import CollectionActivity from "../CollectionActivity";
import { ActionType } from "../../../enum/actionType";
import EmailHistory from "../EmailHistory";
import DirectDebit from "../DirectDebitCard";
import CollectionHistory from "../CollectionHistoryLog";
import CollectionSystemLog from "../CollectionSystemLog";
import { Role } from "../../../enum/roles";
import SelfBilledEInvoiceSubmissionHistory from "../SelfBilledEInvoiceSubmissionHistory";
import SmeCustomerDetails from "../SmeCustomerDetails";
import { SmeCompanyDetails } from "../SmeCompanyDetails";
import { SME_ORGANIZATION_TYPE } from "../../../enum/smeApplication";
import { SmeDisbursementDetails } from "../SmeDisbursementDetails";
import { SmeDirectorDetails } from "../SmeDirectorDetails";

const { Search } = Input;

export type AccountEnquiryContentProps = {
  searchAgreementNo?: string;
  modalView?: boolean;
  viewAccountDetailsOnly?: boolean;
  callback?: () => void;
};

const AccountEnquiryContent: React.FC<AccountEnquiryContentProps> = (
  props: AccountEnquiryContentProps,
) => {
  const { searchAgreementNo, viewAccountDetailsOnly, callback } = props;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const { currentUser } = useSelector((state: RootState) => state.user);

  const { historyLog } = useSelector((state: RootState) => state.collection);

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [resultNotFound, setResultNotFound] = useState(false);
  const [searchType, setSearchType] = useState("agreementNo");
  const [searchResultVisible, setSearchResultVisible] = useState(false);
  const [dunningModalVisible, setDunningModalVisible] = useState(false);
  const [searchResultData, setSearchResultData] = useState([]);
  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const onSearch = async (value: string) => {
    if (value) {
      setLoadingSearch(true);
      await dispatch( searchDisbursedAgreementThunk({ [searchType]: value.trim() }))
        .then((res) => {
          setSearchResultData(res.payload);
          if (res.payload && res.payload.length > 0) {
            setSearchResultVisible(true);
          } else {
            message.error("Result Not Found");
            setResultNotFound(true);
          }
        })
        .finally(() => {
          setLoadingSearch(false);
        });
    } else {
      dispatch(collectionStateReset());
    }
  };

  useEffect(() => {
    console.log('ent', accountEnquiry.data);
  }, []);

  const infoTabItems = [
    {
      label: "Loan Info",
      key: "1",
      children: <LoanInformationCard />
    }
  ].concat(
    accountEnquiry.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.COMPANY
      ? []
      : [{
        label: "Customer Info",
        key: "2",
        children: (
          <div className="bg-white rounded-lg">
            {accountEnquiry.data?.accountDetails?.organizationType ? (
              <SmeCustomerDetails />
            ) : (
              <>
                <CustomerDetailsCard />
                <EmploymentInformation />
                <EmergencyInformation />
                <DirectDebit searchAgreementNo={searchAgreementNo ?? ""} />
              </>
            )}
          </div>
        )
      }]
  ).concat(
    (accountEnquiry.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.COMPANY || accountEnquiry.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.PARTNERSHIP)
      ? [{
        label: "Company Info",
        key: "3",
        children: <SmeCompanyDetails />
      }]
      : []
  ).concat(
    (accountEnquiry.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.COMPANY || accountEnquiry.data?.accountDetails?.organizationType === SME_ORGANIZATION_TYPE.PARTNERSHIP)
      ? [{
        label: "Director Info",
        key: "4",
        children: <SmeDirectorDetails />
      }]
      : []
  ).concat(
    (accountEnquiry.data?.accountDetails?.organizationType)
      ?     [
        {
          label: "Disbursement Info",
          key: "5",
          children: <SmeDisbursementDetails />
        }
      ]
      : []
  )

  const historyTabItems = [
    {
      label: "Payment History",
      key: "payment",
      children: <PaymentHistory />,
    },
    {
      label: "Transaction History",
      key: "transaction",
      children: <TransactionHistoryCard />,
    },
    {
      label: "Dunning History",
      key: "dunning",
      children: <DunningHistory />,
    },
    {
      label: "SMS History",
      key: "sms",
      children: <SmsHistory />,
    },
    {
      label: "Email History",
      key: "email",
      children: <EmailHistory />,
    },
    {
      label: "Notice History",
      key: "notice",
      children: <NoticeHistory />,
    },
    {
      label: "Legal History",
      key: "legal",
      children: <LegalHistory />,
    },
    {
      label: "Special Tagging History",
      key: "specialTagging",
      children: <SpecialTaggingHistory />,
    },
    {
      label: "Field Visit History",
      key: "fieldVisitHistory",
      children: <CollectionActivity />,
    },
    {
      label: "Collection History Log",
      key: "collectionHistoryLog",
      children: <CollectionHistory />,
    },
    {
      label: "E-Invoice",
      key: "eInvoice",
      children: <SelfBilledEInvoiceSubmissionHistory />,
    },
  ];

  // This tab is for redCash super admin only
  const redCashSuperAdminHistoryTabItems = [
    {
      label: "Payment History",
      key: "payment",
      children: <PaymentHistory />,
    },
    {
      label: "Transaction History",
      key: "transaction",
      children: <TransactionHistoryCard />,
    },
    {
      label: "Dunning History",
      key: "dunning",
      children: <DunningHistory />,
    },
    {
      label: "SMS History",
      key: "sms",
      children: <SmsHistory />,
    },
    {
      label: "Email History",
      key: "email",
      children: <EmailHistory />,
    },
    {
      label: "Notice History",
      key: "notice",
      children: <NoticeHistory />,
    },
    {
      label: "Legal History",
      key: "legal",
      children: <LegalHistory />,
    },
    {
      label: "Special Tagging History",
      key: "specialTagging",
      children: <SpecialTaggingHistory />,
    },
    {
      label: "Field Visit History",
      key: "fieldVisitHistory",
      children: <CollectionActivity />,
    },
    {
      label: "Collection History Log",
      key: "collectionHistoryLog",
      children: <CollectionHistory />,
    },
    {
      label: "Collection System Log",
      key: "collectionSystemLog",
      children: <CollectionSystemLog />,
    },
    {
      label: "E-Invoice",
      key: "eInvoice",
      children: <SelfBilledEInvoiceSubmissionHistory />,
    },
  ];

  const handleDownloadAccountStatementClick: MenuProps["onClick"] = (e) => {
    setDownloading(true);
    if (e.key === "en") {
      dispatch(
        getEnglishAccountStatementbyAgreementIdThunk({
          agreementId: accountEnquiry.data?.agreementDetails?.id,
        }),
      )
        .unwrap()
        .then((response) => {
          const filename = `Statement_of_Account_${accountEnquiry.data?.agreementDetails?.agreementNo}_EN.pdf`;
          saveBlobAsPDF(response, filename);
        })
        .finally(() => setDownloading(false));
    } else {
      if (e.key === "bm") {
        dispatch(
          getMalayAccountStatementbyAgreementIdThunk({
            agreementId: accountEnquiry.data?.agreementDetails?.id,
          }),
        )
          .unwrap()
          .then((response) => {
            const filename = `Statement_of_Account_${accountEnquiry.data?.agreementDetails?.agreementNo}_BM.pdf`;
            saveBlobAsPDF(response, filename);
          })
          .finally(() => setDownloading(false));
      }
    }
  };

  const handleSearchResultSelect = async (agreementNo: string) => {
    setSearchResultVisible(false);
    await dispatch(getAccountDetailsThunk({ agreementNo }))
      .unwrap()
      .then((res) => {
        dispatch(
          getDunningHistoryListingThunk({
            accountDetailsId: res?.accountDetails?.id,
          }),
        );
        dispatch(
          getDueAccountTenureDetailsThunk({
            agreementNo: res?.accountDetails?.agreementNo,
          }),
        );
        dispatch(
          getAccountTenureDetailsThunk({
            agreementNo: res?.accountDetails?.agreementNo,
          }),
        );

        dispatch(
          getCollectionHistoryLogThunk({
            accountDetailsId: res?.accountDetails?.id,
          }),
        );

        if (!viewAccountDetailsOnly) {
          queryParams.set("searchAgreementNo", agreementNo);
          const newSearch = queryParams.toString();
          navigate({
            pathname: location.pathname,
            search: newSearch,
          });
        }
      })
      .finally(() => {
        setLoadingSearch(false);
      });
  };

  const handleSearchResultCancel = () => {
    setSearchResultVisible(false);
  };

  useLifecycles(() => {
    if (searchAgreementNo) {
      console.log(searchAgreementNo);
      setLoadingSearch(true);
      setSearchType("agreementNo");
      dispatch(getAccountDetailsThunk({ agreementNo: searchAgreementNo }))
        .unwrap()
        .then((res) => {
          dispatch(
            getDunningHistoryListingThunk({
              accountDetailsId: res?.accountDetails?.id,
            }),
          );
          dispatch(
            getDueAccountTenureDetailsThunk({
              agreementNo: searchAgreementNo,
            }),
          );
          dispatch(
            getAccountTenureDetailsThunk({
              agreementNo: searchAgreementNo,
            }),
          );

          dispatch(
            getCollectionHistoryLogThunk({
              accountDetailsId: res?.accountDetails?.id,
            }),
          );

          if (!viewAccountDetailsOnly) {
            queryParams.set("searchAgreementNo", searchAgreementNo);
            const newSearch = queryParams.toString();
            navigate({
              pathname: location.pathname,
              search: newSearch,
            });
          }
        })
        .finally(() => {
          setLoadingSearch(false);
        });
    }
  });

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Account Enquiry">
        {!viewAccountDetailsOnly &&
          "Search for disbursed agreement account details"}
      </PageHeader>

      {!viewAccountDetailsOnly && (
        <>
          <div className="mb-4">
            <div className="flex gap-1 mb-1">
              <Input.Group compact>
                <Select
                  className="w-[10rem]"
                  value={searchType}
                  onChange={(value) => {
                    setResultNotFound(false);
                    setSearchType(value);
                  }}
                  options={[
                    { value: "agreementNo", label: "Agreement No" },
                    { value: "applicantNric", label: "Applicant NRIC" },
                    { value: "applicantFirstName", label: "Applicant Name" },
                    { value: "applicantPhoneNo", label: "Phone No" },
                    { value: "applicantEmail", label: "Email" },
                  ]}
                />
                <Search
                  className="w-[20rem]"
                  placeholder="Input search text"
                  enterButton="Search"
                  loading={loadingSearch}
                  onSearch={onSearch}
                  defaultValue={searchAgreementNo}
                  status={resultNotFound ? "error" : ""}
                  onChange={() => setResultNotFound(false)}
                />
              </Input.Group>
              {accountEnquiry?.data.accountDetails && (
                <>
                  {currentRole !== Role.FINANCE && <InsertPaymentModal />}

                  <Button onClick={() => setDunningModalVisible(true)}>
                    <Space>Update Dunning History</Space>
                  </Button>

                  <Dropdown
                    menu={{
                      items: [
                        { label: "English", key: "en" },
                        { label: "Bahasa Malaysia", key: "bm" },
                      ],
                      onClick: handleDownloadAccountStatementClick,
                    }}
                  >
                    <Button loading={downloading}>
                      <Space>
                        Download Account Statement
                        <SaveOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </>
              )}
            </div>
          </div>

          {searchResultVisible && (
            <SearchAgreementResult
              visible={searchResultVisible}
              data={searchResultData}
              onOk={handleSearchResultSelect}
              onCancel={handleSearchResultCancel}
            />
          )}
        </>
      )}

      {accountEnquiry?.isLoading || loadingSearch ? (
        <Skeleton />
      ) : (
        <>
          {accountEnquiry?.data.accountDetails && (
            <div>
              <AccountDetailsCard />
              <br />
              <Tabs items={infoTabItems} />
              <br />
              <Tabs
                items={
                  currentUser.data?.username === "REDCASH_SUPER_ADMIN"
                    ? redCashSuperAdminHistoryTabItems
                    : historyTabItems
                }
              />
            </div>
          )}
        </>
      )}

      {dunningModalVisible && (
        <UpdateDunningInfoModal
          visible={dunningModalVisible}
          hideButton={true}
          onCancelCallback={() => setDunningModalVisible(false)}
          accountDetailsId={accountEnquiry.data?.accountDetails?.id}
          agreementNo={accountEnquiry.data.accountDetails!.agreementNo}
          statusActivity={ActionType.BP}
        />
      )}
    </>
  );
};

AccountEnquiryContent.defaultProps = {
  modalView: false,
  viewAccountDetailsOnly: false,
};

export default AccountEnquiryContent;
