import { SmeAttestation } from "../smeAttestation/smeAttestationSlice";
import { SmeApplication } from "../smeApplication/smeApplicationSlice";
import { createSlice } from "@reduxjs/toolkit";
import {
  getCompletedLiveAttestationSmeAgreementListingThunk,
  getCompletedVideoAttestationSmeAgreementListingThunk,
  getPendingAttestationSmeAgreementListingThunk,
  getRequestedLiveAttestationSmeAgreementListingThunk,
  getSmeAgreementDetailsThunk,
  getSmeAgreementListingThunk,
  getPendingSmeEStampListingThunk,
  getInProgressSmeEStampListingThunk,
  getCompletedSmeEStampListingThunk,
  getSmePendingSigningAgreementsThunk,
  getSmeCompletedSigningThunk,
} from "../../services/smeAgreementService/smeAgreementThunk";
import { getSmeFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import groupBy from "lodash.groupby";

export type UploadedFile = {
  id: string;
  entityId: string;
  filename: string;
  originalName: string;
  mimeType: string;
  fileTypeId: string;
  fileType: {
    id: string;
    name: string;
    description?: string;
    fileSizeLimit?: number;
    createdAt?: string;
    updatedAt?: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type SmeAgreement = {
  id: string;
  smeApplicationId: string;
  smeApplication: SmeApplication;
  smeDisbursementBatchId: string;
  agreementNo: string;
  status: string;
  loanTenure: number;
  loanInterestRate: number;
  firstInstalmentDate: string;
  lastInstalmentDate: string;
  monthlyInstalmentAmount: number;
  lastInstalmentAmount: number;
  approveUploadedEStampingCert: boolean;
  approveEStampingCertDate: string;
  clickedDownloadEStampingCert: boolean;
  disbursementAmount: number;
  signDate: string;
  zipFilePassword: string;
  disbursementPostedDate: string;
  stampingDate: string;
  eStampFileId: string;
  protectedSignedAgreementFileId: string;
  signedAgreementFileId: string;
  createdAt: string;
  updatedAt: string;
  smeAttestation: SmeAttestation;
  eStampingCertificateFileId?: string;
};

export type SmeAgreementSigning = {
  smeApplicationId: string;
  smeAgreementId: string;
  signDate: string;
  createdAt: string;
  financeAmount: number;
  applicantName: string;
  applicantNric: string;
};

export type SmeAgreementListing = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: SmeAgreement[];
};

export type SmeEStamp = {
  smeApplicationId: string;
  smeAgreementId: string;
  signDate: string;
  agreementNo: string;
  applicantName: string;
  applicantContactNo: string;
  financeAmount: number;
  eStampFileId?: string;
  approveUploadedEStampingCert?: boolean;
};

export type SmeEStampListing = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: SmeEStamp[];
};

export type SmeAgreementSigningListing = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: SmeAgreementSigning[];
};

export type SmeAgreementState = {
  smeAgreementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeAgreementListing;
  };
  pendingAttestationSmeAgreementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeAgreementListing;
  };
  requestedLiveAttestationSmeAgreementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeAgreementListing;
  };
  completedLiveAttestationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeAgreementListing;
  };
  completedVideoAttestationList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeAgreementListing;
  };
  agreementDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: SmeAgreement;
  };
  agreementFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      protectedsignedagreementpdf?: UploadedFile[];
      signedagreementpdf?: UploadedFile[];
      sme_e_stamping?: UploadedFile[];
    };
  };

  pendingSmeEStampList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeEStampListing;
  };

  inProgressSmeEStampList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeEStampListing;
  };

  completedSmeEStampList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeEStampListing;
  };
  pendingSmeAgreementSigningList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeAgreementSigningListing;
  };

  completedSmeAgreementSigningList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeAgreementSigningListing;
  };
};

const initialState: SmeAgreementState = {
  smeAgreementList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  pendingAttestationSmeAgreementList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  requestedLiveAttestationSmeAgreementList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  completedLiveAttestationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  completedVideoAttestationList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  agreementDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {} as SmeAgreement,
  },
  agreementFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      protectedsignedagreementpdf: [],
      signedagreementpdf: [],
      sme_e_stamping: [],
    },
  },
  pendingSmeEStampList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },

  inProgressSmeEStampList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },

  completedSmeEStampList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  pendingSmeAgreementSigningList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  completedSmeAgreementSigningList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
};

export const smeAgreementSlice = createSlice({
  name: "smeAgreement",
  initialState,
  reducers: {
    smeAgreementStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmeAgreementListingThunk.pending, (state) => {
        state.smeAgreementList.isLoading = true;
      })
      .addCase(getSmeAgreementListingThunk.fulfilled, (state, action) => {
        state.smeAgreementList.isLoading = false;
        state.smeAgreementList.data = action.payload;
      })
      .addCase(getSmeAgreementListingThunk.rejected, (state, action) => {
        state.smeAgreementList.isLoading = false;
        state.smeAgreementList.isError = true;
        state.smeAgreementList.errorMessage = action.payload;
      });

    builder
      .addCase(
        getPendingAttestationSmeAgreementListingThunk.pending,
        (state) => {
          state.pendingAttestationSmeAgreementList.isLoading = true;
        },
      )
      .addCase(
        getPendingAttestationSmeAgreementListingThunk.fulfilled,
        (state, action) => {
          state.pendingAttestationSmeAgreementList.isLoading = false;
          state.pendingAttestationSmeAgreementList.data = action.payload;
        },
      )
      .addCase(
        getPendingAttestationSmeAgreementListingThunk.rejected,
        (state, action) => {
          state.pendingAttestationSmeAgreementList.isLoading = false;
          state.pendingAttestationSmeAgreementList.isError = true;
          state.pendingAttestationSmeAgreementList.errorMessage =
            action.payload;
        },
      );

    builder
      .addCase(
        getRequestedLiveAttestationSmeAgreementListingThunk.pending,
        (state) => {
          state.requestedLiveAttestationSmeAgreementList.isLoading = true;
        },
      )
      .addCase(
        getRequestedLiveAttestationSmeAgreementListingThunk.fulfilled,
        (state, action) => {
          state.requestedLiveAttestationSmeAgreementList.isLoading = false;
          state.requestedLiveAttestationSmeAgreementList.data = action.payload;
        },
      )
      .addCase(
        getRequestedLiveAttestationSmeAgreementListingThunk.rejected,
        (state, action) => {
          state.requestedLiveAttestationSmeAgreementList.isLoading = false;
          state.requestedLiveAttestationSmeAgreementList.isError = true;
          state.requestedLiveAttestationSmeAgreementList.errorMessage =
            action.payload;
        },
      );

    builder
      .addCase(
        getCompletedLiveAttestationSmeAgreementListingThunk.pending,
        (state) => {
          state.completedLiveAttestationList.isLoading = true;
        },
      )
      .addCase(
        getCompletedLiveAttestationSmeAgreementListingThunk.fulfilled,
        (state, action) => {
          state.completedLiveAttestationList.isLoading = false;
          state.completedLiveAttestationList.data = action.payload;
        },
      )
      .addCase(
        getCompletedLiveAttestationSmeAgreementListingThunk.rejected,
        (state, action) => {
          state.completedLiveAttestationList.isLoading = false;
          state.completedLiveAttestationList.isError = true;
          state.completedLiveAttestationList.errorMessage = action.payload;
        },
      );

    builder
      .addCase(
        getCompletedVideoAttestationSmeAgreementListingThunk.pending,
        (state) => {
          state.completedVideoAttestationList.isLoading = true;
        },
      )
      .addCase(
        getCompletedVideoAttestationSmeAgreementListingThunk.fulfilled,
        (state, action) => {
          state.completedVideoAttestationList.isLoading = false;
          state.completedVideoAttestationList.data = action.payload;
        },
      )
      .addCase(
        getCompletedVideoAttestationSmeAgreementListingThunk.rejected,
        (state, action) => {
          state.completedVideoAttestationList.isLoading = false;
          state.completedVideoAttestationList.isError = true;
          state.completedVideoAttestationList.errorMessage = action.payload;
        },
      );

    // Need to have a case for getAgreementDetailsThunk
    builder
      .addCase(getSmeAgreementDetailsThunk.pending, (state) => {
        state.agreementDetails.isLoading = true;
      })
      .addCase(getSmeAgreementDetailsThunk.fulfilled, (state, action) => {
        state.agreementDetails.isLoading = false;
        state.agreementDetails.data = action.payload;
      })
      .addCase(getSmeAgreementDetailsThunk.rejected, (state, action) => {
        state.agreementDetails.isLoading = false;
        state.agreementDetails.isError = true;
        state.agreementDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getSmeFilesByEntityIdThunk.pending, (state) => {
        state.agreementFiles.isLoading = true;
      })
      .addCase(getSmeFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.agreementFiles.isLoading = false;
        state.agreementFiles.data = groupBy(
          action.payload,
          (file: UploadedFile) => file.fileType.name.toLowerCase(),
        );

        // only keep the latest file for fileType signedagreemetnpdf
        if (
          state?.agreementFiles?.data?.signedagreementpdf &&
          state.agreementFiles.data.signedagreementpdf.length > 0
        ) {
          state.agreementFiles.data.signedagreementpdf = [
            state.agreementFiles.data.signedagreementpdf[
              state.agreementFiles.data.signedagreementpdf.length - 1
            ],
          ];
        }
      })
      .addCase(getSmeFilesByEntityIdThunk.rejected, (state, action) => {
        state.agreementFiles.isLoading = false;
        state.agreementFiles.isError = true;
        state.agreementFiles.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingSmeEStampListingThunk.pending, (state) => {
        state.pendingSmeEStampList.isLoading = true;
      })
      .addCase(getPendingSmeEStampListingThunk.fulfilled, (state, action) => {
        state.pendingSmeEStampList.isLoading = false;
        state.pendingSmeEStampList.data = action.payload;
      })
      .addCase(getPendingSmeEStampListingThunk.rejected, (state, action) => {
        state.pendingSmeEStampList.isLoading = false;
        state.pendingSmeEStampList.isError = true;
        state.pendingSmeEStampList.errorMessage = action.payload;
      });

    builder
      .addCase(getInProgressSmeEStampListingThunk.pending, (state) => {
        state.inProgressSmeEStampList.isLoading = true;
      })
      .addCase(
        getInProgressSmeEStampListingThunk.fulfilled,
        (state, action) => {
          state.inProgressSmeEStampList.isLoading = false;
          state.inProgressSmeEStampList.data = action.payload;
        },
      )
      .addCase(getInProgressSmeEStampListingThunk.rejected, (state, action) => {
        state.inProgressSmeEStampList.isLoading = false;
        state.inProgressSmeEStampList.isError = true;
        state.inProgressSmeEStampList.errorMessage = action.payload;
      });

    builder
      .addCase(getCompletedSmeEStampListingThunk.pending, (state) => {
        state.completedSmeEStampList.isLoading = true;
      })
      .addCase(getCompletedSmeEStampListingThunk.fulfilled, (state, action) => {
        state.completedSmeEStampList.isLoading = false;
        state.completedSmeEStampList.data = action.payload;
      })
      .addCase(getCompletedSmeEStampListingThunk.rejected, (state, action) => {
        state.completedSmeEStampList.isLoading = false;
        state.completedSmeEStampList.isError = true;
        state.completedSmeEStampList.errorMessage = action.payload;
      });

    builder
      .addCase(getSmePendingSigningAgreementsThunk.pending, (state) => {
        state.pendingSmeAgreementSigningList.isLoading = true;
      })
      .addCase(
        getSmePendingSigningAgreementsThunk.fulfilled,
        (state, action) => {
          state.pendingSmeAgreementSigningList.isLoading = false;
          state.pendingSmeAgreementSigningList.data = action.payload;
        },
      )
      .addCase(
        getSmePendingSigningAgreementsThunk.rejected,
        (state, action) => {
          state.pendingSmeAgreementSigningList.isLoading = false;
          state.pendingSmeAgreementSigningList.isError = true;
          state.pendingSmeAgreementSigningList.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getSmeCompletedSigningThunk.pending, (state) => {
        state.completedSmeAgreementSigningList.isLoading = true;
      })
      .addCase(getSmeCompletedSigningThunk.fulfilled, (state, action) => {
        state.completedSmeAgreementSigningList.isLoading = false;
        state.completedSmeAgreementSigningList.data = action.payload;
      })
      .addCase(getSmeCompletedSigningThunk.rejected, (state, action) => {
        state.completedSmeAgreementSigningList.isLoading = false;
        state.completedSmeAgreementSigningList.isError = true;
        state.completedSmeAgreementSigningList.errorMessage = action.payload;
      });
  },
});

export const { smeAgreementStateReset } = smeAgreementSlice.actions;
export default smeAgreementSlice.reducer;
