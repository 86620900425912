import "./index.less";

import { Button, Form, Modal, Table } from "antd";
import startCase from "lodash.startcase";
import React, { useEffect, useState } from "react";

import { AccountCharges } from "../../../../features/collection/collectionSlice";
import { numberWithCommas } from "../../../../helpers/number";
import collectionService from "../../../../services/collectionService/collectionService";

type TotalChargesModalProps = {
  accountCharges: AccountCharges;
  accountDetailsId: string;
};

const TotalChargesModal: React.FC<TotalChargesModalProps> = (props) => {
  const { accountCharges, accountDetailsId } = props;

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chargesData, setChargesData] = useState([{}]);
  const [waivedCharges, setWaivedCharges] = useState<any>([{}]);

  const getAccountWaiverCharges = async () => {
    await collectionService
      .getAccountWaiverCharges(accountDetailsId)
      .then((res) => {
        setWaivedCharges(res.data);
      });
  };

  const totalCharges = Object.values(accountCharges).reduce(
    (accumulator: number, currentValue: any) =>
      typeof currentValue === "number"
        ? accumulator + currentValue
        : accumulator,
    0,
  );

  const dataSource = [
    {
      chargesType: "Late Payment Charge",
      amount: numberWithCommas(accountCharges?.latePaymentCharge),
    },
    {
      chargesType: "Reminder Letter 1 Charge",
      amount: numberWithCommas(accountCharges?.reminderLetter1Charge),
    },
    {
      chargesType: "Reminder Letter 2 Charge",
      amount: numberWithCommas(accountCharges?.reminderLetter2Charge),
    },
    {
      chargesType: "Reminder Letter 3 Charge",
      amount: numberWithCommas(accountCharges?.reminderLetter3Charge),
    },
    {
      chargesType: "Early Settlement Charge",
      amount: numberWithCommas(accountCharges?.earlySettlementCharge),
    },
    {
      chargesType: "External Collection Agency Charge",
      amount: numberWithCommas(accountCharges?.ecaCharge),
    },
    {
      chargesType: "Field Visit Charge",
      amount: numberWithCommas(accountCharges?.fieldCharge),
    },
    {
      chargesType: "Legal Charge",
      amount: numberWithCommas(accountCharges?.legalCharge),
    },
    {
      chargesType: "LOD Charge",
      amount: numberWithCommas(accountCharges?.lodCharge),
    },
    {
      chargesType: "Misc Charge",
      amount: numberWithCommas(accountCharges?.miscCharge),
    },
    {
      chargesType: "Refund Charge",
      amount: numberWithCommas(accountCharges?.refundCharge),
    },
    {
      chargesType: "Credit Info, Blacklist Uplift Charge",
      amount: numberWithCommas(accountCharges?.blacklistUpliftCharge),
    },
    {
      chargesType: "Admin Charge",
      amount: numberWithCommas(accountCharges?.adminCharge),
    },
    {
      chargesType: "Bank Charge",
      amount: numberWithCommas(accountCharges?.bankCharge),
    },
  ];

  const chargeData = [
    {
      chargesType: "Late Payment Charge",
      key: "latePaymentCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Reminder Letter 1 Charge",
      key: "reminderLetter1Charge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Reminder Letter 2 Charge",
      key: "reminderLetter2Charge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Reminder Letter 3 Charge",
      key: "reminderLetter3Charge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Early Settlement Charge",
      key: "earlySettlementCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "External Collection Agency Charge",
      key: "ecaCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Field Visit Charge",
      key: "fieldCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Legal Charge",
      key: "legalCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "LOD Charge",
      key: "lodCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Misc Charge",
      key: "miscCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Refund Charge",
      key: "refundCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Credit Info, Blacklist Uplift Charge",
      key: "blacklistUpliftCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Admin Charge",
      key: "adminCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
    {
      chargesType: "Bank Charge",
      key: "bankCharge",
      amount: 0,
      tax: 0,
      amountTotal: 0,
      waiverAmount: 0,
      waiverTax: 0,
      total: 0,
    },
  ];

  const columns = [
    {
      title: "Charges Type",
      dataIndex: "chargesType",
      width: 300,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Tax",
      dataIndex: "waiverTax",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Waiver",
      dataIndex: "waiverAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  useEffect(() => {
    Promise.all(
      chargesData.map((initialCharges: any, chargesIndex: any) => {
        Promise.all(
          waivedCharges.map((waivedCharge: any, waivedIndex: any) => {
            if (initialCharges.key === waivedCharge.chargesType) {
              initialCharges.waiverTax = waivedCharge.tax;
              initialCharges.waiverAmount = waivedCharge.amount;
            }
          }),
        );
      }),
    );

    setChargesData(chargesData);
  }, [waivedCharges]);

  useEffect(() => {
    getAccountWaiverCharges();

    const data = Object.entries(accountCharges)
      .filter(([, value]) => typeof value === "number")
      .map(([key, value]) => ({
        key: key,
        chargesType: startCase(key),
        amount: value,
        tax: 0,
        amountTotal: 0,
        waiverAmount: 0,
        waiverTax: 0,
        total: 0,
      }));

    Promise.all(
      data.map((initialCharges: any, chargesIndex: any) => {
        Promise.all(
          chargeData.map((waivedCharge: any, waivedIndex: any) => {
            if (initialCharges.key === waivedCharge.key) {
              waivedCharge.amount = initialCharges.amount;
            }
          }),
        );
      }),
    );
    setChargesData(chargeData);
  }, [accountDetailsId, accountCharges]);
  return (
    <>
      <div style={{ marginBottom: 16 }}>
        RM {numberWithCommas(totalCharges)}
        <Button
          type="primary"
          onClick={showModal}
          style={{ marginLeft: 16 }}
          ghost
        >
          View Charges
        </Button>
      </div>
      <Modal
        title="Charges"
        open={isModalOpen}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={null}
        destroyOnClose
        centered
      >
        <Table
          columns={columns}
          dataSource={chargesData}
          scroll={{ x: 800, y: 400 }}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default TotalChargesModal;
