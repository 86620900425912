import "../index.less";

import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useEffect, useMemo, useState } from "react";

import { numberWithCommas } from "../../../helpers/number";
import { displayDateAndTime } from "../../../utils/datetime.util";

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { getPaymentHistoryThunk } from "../../../services/paymentService/paymentThunk";

const PaymentHistory = () => {
  // const { accountDetails } = useSelector(
  //   (state: RootState) => state.accountDetails,
  // );
  const dispatch = useDispatch<AppDispatch>();
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const { paymentHistory } = useSelector((state: RootState) => state.payment);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: paymentHistory.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Receipt No.",
      dataIndex: "receiptNo",
      key: "receiptNo",
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      render: (text: string) => text ?? "-",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text: string) => text ?? "-",
    },
    {
      title: "Payment Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Instalment Amount",
      dataIndex: "instalmentAmount",
      key: "instalmentAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Other Charges",
      dataIndex: "totalOtherCharges",
      key: "totalOtherCharges",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Extra Payment",
      dataIndex: "extraPayment",
      key: "extraPayment",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Payer Bank",
      dataIndex: "paymentChannel",
      key: "paymentChannel",
      render: (text: string) => text ?? "-",
    },
  ];

  const fetchPaymentHistory = async (params?: any) => {
    await dispatch(getPaymentHistoryThunk({ ...params }));
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchPaymentHistory({
      ...initialParams,
      ...filterState,
      pagination,
      agreementId: accountEnquiry.data?.accountDetails?.agreementId,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    if (accountEnquiry.data?.accountDetails?.agreementId) {
      fetchPaymentHistory({
        ...initialParams,
        agreementId: accountEnquiry.data?.accountDetails?.agreementId,
      });
    }
  }, [initialParams, accountEnquiry.data?.accountDetails?.agreementId]);

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <Table
            columns={columns}
            dataSource={paymentHistory.data.data}
            pagination={tablePagination}
            loading={paymentHistory.isLoading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
