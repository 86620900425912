import "./index.less";

import { Tabs, TabsProps, Tag } from "antd";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";
import { AppDispatch, RootState } from "../../store/store";
import CreatedDisbursementBatchListingPage from "./CreatedDisbursementBatchListing";
import PendingDisbursementListingPage from "./PendingDisbursementListing";
import {
  getSmeDisbursementBatchListingThunk,
  getSmePendingDisbursementAgreementsListingThunk,
} from "../../services/smeDisbursementService/smeDisbursementThunk";

const SmeDisbursementListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { smeDisbursementBatchListing, pendingDisbursementSmeAgreements } =
    useSelector((state: RootState) => state.smeDisbursement);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const items: TabsProps["items"] = [
    {
      key: "pending",
      label: (
        <span>
          Pending Disbursement{" "}
          {pendingDisbursementSmeAgreements?.data?.total != null && (
            <Tag>{pendingDisbursementSmeAgreements.data.total}</Tag>
          )}
        </span>
      ),
      children: <PendingDisbursementListingPage />,
    },
    {
      key: "created",
      label: (
        <span>
          Created Disbursement Batch{" "}
          {smeDisbursementBatchListing?.data?.total != null && (
            <Tag>{smeDisbursementBatchListing.data.total}</Tag>
          )}
        </span>
      ),
      children: <CreatedDisbursementBatchListingPage />,
    },
  ];

  const fetchPendingDisbursementAgreements = async (params?: any) => {
    await dispatch(getSmePendingDisbursementAgreementsListingThunk(params));
  };

  const fetchDisbursementBatchListing = async (params?: any) => {
    await dispatch(getSmeDisbursementBatchListingThunk(params));
  };

  const onTabChange = (key: string) => {
    if (key === "pending") {
      fetchPendingDisbursementAgreements(initialParams);
    } else if (key === "created") {
      fetchDisbursementBatchListing(initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchPendingDisbursementAgreements(params);
    fetchDisbursementBatchListing(params);
  });

  return (
    <>
      <Helmet>
        <title>Disbursement - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Disbursement Listing" />
      <Tabs
        defaultActiveKey={activeTab ?? "pending"}
        items={items}
        onChange={onTabChange}
      />
    </>
  );
};

export default SmeDisbursementListingPage;
