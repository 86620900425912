import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../../helpers/errorMessage";
import bucketService from "./bucketService";

export const getAllBucketThunk = createAsyncThunk(
  "bucket/getAll",
  async (requestData: any, thunkAPI) => {
    try {
      return await bucketService.getAllBucket(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createBucketThunk = createAsyncThunk(
  "bucket/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await bucketService.createBucket(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateBucketThunk = createAsyncThunk(
  "bucket/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await bucketService.updateBucket(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteBucketThunk = createAsyncThunk(
  "bucket/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await bucketService.deleteBucket(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
