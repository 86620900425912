import React, { useState } from 'react';
import { Card, Alert, Button, Row, Col, Space, Typography } from 'antd';
import {
  SettingOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  WarningOutlined,
  UserOutlined,
  GlobalOutlined,
  BankOutlined
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const monitoringItems = [
  {
    id: 1,
    category: "Individual Customer Red Flags",
    title: "Consistency Checks",
    description: "Compare customer information with transaction data",
    status: "Implemented",
    parameters: ["Name", "IC", "Banking Detail"],
    implementation: "Cross check algorithm between application and payment data",
    sampleAlert: "Customer banking details mismatch detected"
  },
  {
    id: 2,
    category: "Legal Person Red Flags",
    title: "Third-Party Payments",
    description: "Track payments from unexpected sources",
    status: "Implemented",
    parameters: ["Payment detail", "Banking detail", "Name", "IC"],
    implementation: "Cross check algorithm for payment sources",
    sampleAlert: "Unexpected third-party payment received"
  },
  {
    id: 3,
    category: "Transaction Red Flags",
    title: "Transaction Amount Thresholds",
    description: "Monitor transactions against configured limits",
    status: "Implemented",
    parameters: ["History payment detail"],
    implementation: "Configurable upper and lower limits with alerts",
    sampleAlert: "Transaction exceeds upper threshold"
  },
  {
    id: 4,
    category: "Transaction Red Flags",
    title: "Transaction Frequency Rules",
    description: "Monitor unusual transaction patterns",
    status: "Implemented",
    parameters: ["History payment detail"],
    implementation: "Frequency tracking by daily/weekly/monthly periods",
    sampleAlert: "Unusual transaction frequency detected"
  },
  {
    id: 5,
    category: "Transaction Red Flags",
    title: "Rapid Repayment Detection",
    description: "Track unusually quick loan repayments",
    status: "Implemented",
    parameters: ["Payment history"],
    implementation: "Payment pattern analysis algorithm",
    sampleAlert: "Rapid loan repayment detected"
  },
  {
    id: 6,
    category: "Transaction Red Flags",
    title: "Unnecessary Fund Routing",
    description: "Monitor suspicious fund routing patterns",
    status: "Pending",
    parameters: ["Fund flow data"],
    implementation: "Pending implementation",
    sampleAlert: "Complex fund routing detected"
  },
  {
    id: 7,
    category: "Transaction Red Flags",
    title: "Fragmented Payments",
    description: "Track structured smaller payments",
    status: "Implemented",
    parameters: ["Payment history", "Configuration threshold"],
    implementation: "Pattern recognition for linked payments",
    sampleAlert: "Multiple linked small payments detected"
  },
  {
    id: 8,
    category: "Geographical Red Flags",
    title: "Cross-border Transaction Monitoring",
    description: "Monitor high-risk jurisdiction transactions",
    status: "Implemented",
    parameters: ["FATF configuration", "Payment details"],
    implementation: "FATF high-risk country list integration",
    sampleAlert: "High-risk jurisdiction transaction detected"
  },
  {
    id: 9,
    category: "Sector Specific Red Flags",
    title: "Frequent Defaults",
    description: "Track repeated loan defaults",
    status: "Implemented",
    parameters: ["Configuration setup", "Payment history"],
    implementation: "Default pattern monitoring system",
    sampleAlert: "Frequent default pattern detected"
  },
  {
    id: 10,
    category: "Sector Specific Red Flags",
    title: "Unusual Denomination of Payments",
    description: "Monitor unusual payment denominations",
    status: "Implemented",
    parameters: ["Payment history"],
    implementation: "Denomination pattern analysis",
    sampleAlert: "Unusual payment denomination detected"
  },
  {
    id: 11,
    category: "Sector Specific Red Flags",
    title: "Unusual Loan Request Monitoring",
    description: "Track inconsistent loan requests",
    status: "Implemented",
    parameters: ["Customer details", "Loan history"],
    implementation: "Loan request pattern analysis",
    sampleAlert: "Inconsistent loan request detected"
  }
];

const STRDashboard = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const categories = [...new Set(monitoringItems.map(item => item.category))];

  const filteredItems = selectedCategory === 'all'
    ? monitoringItems
    : monitoringItems.filter(item => item.category === selectedCategory);

  const stats = {
    total: monitoringItems.length,
    implemented: monitoringItems.filter(item => item.status === 'Implemented').length,
    pending: monitoringItems.filter(item => item.status === 'Pending').length
  };

  const getCategoryIcon = (category: any) => {
    switch (category) {
      case 'Individual Customer Red Flags':
        return <UserOutlined />;
      case 'Geographical Red Flags':
        return <GlobalOutlined />;
      case 'Sector Specific Red Flags':
        return <BankOutlined />;
      default:
        return <WarningOutlined />;
    }
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-6">
        <Title level={2} className="mb-2">STR Monitoring System</Title>
        <Text type="secondary">Comprehensive monitoring implementation status</Text>
      </div>

      {/* Statistics Cards */}
      <Row gutter={[16, 16]} className="mb-6">
        <Col xs={24} md={8}>
          <Card bordered={false} className="h-full shadow-sm">
            <Space align="start">
              <div className="p-2 bg-blue-100 rounded-lg">
                <SettingOutlined className="text-xl text-blue-600" />
              </div>
              <div>
                <Text type="secondary">Total Controls</Text>
                <Title level={3} className="mt-0 mb-0">{stats.total}</Title>
              </div>
            </Space>
          </Card>
        </Col>

        <Col xs={24} md={8}>
          <Card bordered={false} className="h-full shadow-sm">
            <Space align="start">
              <div className="p-2 bg-green-100 rounded-lg">
                <CheckCircleOutlined className="text-xl text-green-600" />
              </div>
              <div>
                <Text type="secondary">Implemented</Text>
                <Title level={3} className="mt-0 mb-0">{stats.implemented}</Title>
              </div>
            </Space>
          </Card>
        </Col>

        <Col xs={24} md={8}>
          <Card bordered={false} className="h-full shadow-sm">
            <Space align="start">
              <div className="p-2 bg-yellow-100 rounded-lg">
                <ClockCircleOutlined className="text-xl text-yellow-600" />
              </div>
              <div>
                <Text type="secondary">Pending</Text>
                <Title level={3} className="mt-0 mb-0">{stats.pending}</Title>
              </div>
            </Space>
          </Card>
        </Col>
      </Row>

      {/* Category Filter */}
      <Space wrap className="mb-6">
        <Button
          type={selectedCategory === 'all' ? 'primary' : 'default'}
          onClick={() => setSelectedCategory('all')}
        >
          All
        </Button>
        {categories.map(category => (
          <Button
            key={category}
            type={selectedCategory === category ? 'primary' : 'default'}
            onClick={() => setSelectedCategory(category)}
            icon={getCategoryIcon(category)}
          >
            {category}
          </Button>
        ))}
      </Space>

      {/* Monitoring Items Grid */}
      <Row gutter={[16, 16]}>
        {filteredItems.map(item => (
          <Col key={item.id} xs={24} md={12}>
            <Card
              title={
                <div className="flex justify-between items-center">
                  <Space>
                    {getCategoryIcon(item.category)}
                    <Text strong>{item.title}</Text>
                  </Space>
                  <Text
                    className={`px-3 py-1 rounded-full ${
                      item.status === 'Implemented'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}
                  >
                    {item.status}
                  </Text>
                </div>
              }
              className="h-full shadow-sm"
              bordered={false}
            >
              <Space direction="vertical" className="w-full" size="large">
                <Paragraph>{item.description}</Paragraph>

                <div>
                  <Text strong>Parameters:</Text>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {item.parameters.map((param, index) => (
                      <Text
                        key={index}
                        className="px-2 py-1 bg-gray-100 rounded-md"
                      >
                        {param}
                      </Text>
                    ))}
                  </div>
                </div>

                <div>
                  <Text strong>Implementation:</Text>
                  <Paragraph className="mt-2 mb-0">{item.implementation}</Paragraph>
                </div>

                <Alert
                  message={item.sampleAlert}
                  type="info"
                  showIcon
                  icon={<WarningOutlined />}
                  className="mb-0"
                />
              </Space>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default STRDashboard;