import { Button, message, Popconfirm, Space, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import {
  deleteAdminThunk,
  getAdminsThunk,
} from "../../services/adminService/adminThunk";
import { AppDispatch, RootState } from "../../store/store";
import AdminFormModal from "./AdminFormModal";

import type { ColumnsType } from "antd/es/table";
import type { TablePaginationConfig } from "antd/es/table";

const AdminManagementPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const [deleteLoading, setDeleteLoading] = useState(false);

  const { admins } = useSelector((state: RootState) => state.admin);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("username");

  const tablePagination = {
    total: admins?.data?.length,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space>
          <AdminFormModal
            key={record?.id}
            admin={record}
            callback={() => fetchAdmins(initialParams)}
          />
          <Popconfirm
            title="Do you want to delete this admin?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" loading={deleteLoading} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const fetchAdmins = async (params?: any) => {
    await dispatch(getAdminsThunk(params));
  };

  const onSearch = (value: string) => {
    fetchAdmins({
      ...initialParams,
      filters: { ...filterState, [searchFiled]: value },
    });
    setFilterState({ ...initialParams.filters, [searchFiled]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchAdmins({
      ...initialParams,
      ...filterState,
      pagination,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const onDelete = async (adminId: string) => {
    setDeleteLoading(true);
    await dispatch(deleteAdminThunk({ adminId }))
      .unwrap()
      .then(() => {
        message.success("Deleted");
        fetchAdmins(initialParams);
      })
      .finally(() => setDeleteLoading(false));
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchAdmins(params);
  });

  return (
    <>
      <Helmet>
        <title>Admin Config - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title="Admin Config"
        extra={
          <AdminFormModal
            key="newAdminForm"
            callback={() => fetchAdmins(initialParams)}
          />
        }
      />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={admins?.data}
        loading={admins?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AdminManagementPage;
