import "./index.less";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  message,
  Row,
  Space,
} from "antd";
import { useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CaretDownOutlined, FilePdfOutlined } from "@ant-design/icons";

import LoadingSpinner from "../../../../components/LoadingSpinner";
import { EARLY_SETTLEMENT_STATUS } from "../../../../enum/earlySettlement";
import { EarlySettlement } from "../../../../features/earlySettlement/earlySettlementSlice";
import {
  getEarlySettlementBmQuotationThunk,
  getEarlySettlementBmTaxInvoiceThunk,
  getEarlySettlementEnQuotationThunk,
  getEarlySettlementEnTaxInvoiceThunk,
  getEstimateLatePaymentChargeThunk,
  updateEarlySettlementThunk,
} from "../../../../services/earlySettlementService/earlySettlementThunk";
import { AppDispatch } from "../../../../store/store";
import { saveBlobAsPDF } from "../../../../utils/file";
import { roundToDecimal } from "../../../../utils/number.util";

import type { DatePickerProps } from "antd";
import { numberWithCommas } from "../../../../helpers/number";

const { TextArea } = Input;

export type EarlySettlementFormProps = {
  earlySettlement: EarlySettlement;
  formInitialValues: any;
  newRequest?: boolean;
  callback: any;
};

const EarlySettlementForm: React.FC<EarlySettlementFormProps> = (
  props: EarlySettlementFormProps,
) => {
  const { earlySettlement, formInitialValues, callback, newRequest } = props;
  const navigate = useNavigate();
  const earlySettlementTax = 6;
  const miscTax = 6;
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [downloading, setDownloading] = useState(false);

  const [viewChargesDetails, setViewChargesDetails] = useState(false);
  const [totalLoanOutstanding, setTotalLoanOutstanding] = useState(
    earlySettlement?.accountDetails?.totalLoanOutstanding ?? 0,
  );

  const fetchEarlySettlementEnQuotation = async (params?: any) => {
    message.loading("Generating Early Settlement Quotation (English)");
    await dispatch(getEarlySettlementEnQuotationThunk(params))
      .unwrap()
      .then((response) => {
        const filename = `EARLY_SETTLEMENT_QUOTATION_${params?.earlySettlementId}_EN`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => {
        message.success("File is ready");
        setDownloading(false);
      });
  };

  const fetchEarlySettlementBmQuotation = async (params?: any) => {
    message.loading("Generating Early Settlement Quotation (Bahasa Melayu)");
    await dispatch(getEarlySettlementBmQuotationThunk(params))
      .unwrap()
      .then((response) => {
        const filename = `EARLY_SETTLEMENT_QUOTATION_${params?.earlySettlementId}_BM`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => {
        message.success("File is ready");
        setDownloading(false);
      });
  };

  const fetchEarlySettlementEnTaxInvoice = async (params?: any) => {
    message.loading("Downloading Early Settlement English Tax Invoice");
    setDownloading(true);
    await dispatch(getEarlySettlementEnTaxInvoiceThunk(params))
      .unwrap()
      .then((response) => {
        const filename = `EARLY_SETTLEMENT_TAX_INVOICE_${params?.earlySettlementId}_EN`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => setDownloading(false));
  };

  const fetchEarlySettlementBmTaxInvoice = async (params?: any) => {
    message.loading("Downloading Early Settlement Bahasa Melayu Tax Invoice");
    setDownloading(true);
    await dispatch(getEarlySettlementBmTaxInvoiceThunk(params))
      .unwrap()
      .then((response) => {
        const filename = `EARLY_SETTLEMENT_TAX_INVOICE_${params?.earlySettlementId}_BM`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => setDownloading(false));
  };

  const handleDownloadQuotationClick = (e: any) => {
    if (e?.key === "bm")
      fetchEarlySettlementBmQuotation({
        earlySettlementId: earlySettlement?.id,
      });
    else {
      fetchEarlySettlementEnQuotation({
        earlySettlementId: earlySettlement?.id,
      });
    }
  };

  const handleDownloadTaxInvoiceClick = (e: any) => {
    if (e?.key === "bm")
      fetchEarlySettlementEnTaxInvoice({
        earlySettlementId: earlySettlement?.id,
      });
    else {
      fetchEarlySettlementBmTaxInvoice({
        earlySettlementId: earlySettlement?.id,
      });
    }
  };

  const onDateChange: DatePickerProps["onChange"] = async (
    date,
    dateString,
  ) => {
    if (dateString) {
      await dispatch(
        getEstimateLatePaymentChargeThunk({
          agreementId: earlySettlement?.accountDetails?.agreementId,
          settlementDate: dateString,
        }),
      )
        .unwrap()
        .then((res) => {
          const totalLoanOutstandingRounded = roundToDecimal(
            res?.totalLoanOutstanding?.amount ?? 0,
            2,
          );
          const latePaymentChargeRounded = roundToDecimal(
            res?.latePaymentCharge?.amount ?? 0,
            2,
          );

          setTotalLoanOutstanding(totalLoanOutstandingRounded);
          handleEarlySettlementChargeChange(totalLoanOutstandingRounded);
          handleMiscChargeChange(totalLoanOutstandingRounded);
          form.setFieldsValue({
            totalLoanOutstanding: numberWithCommas(totalLoanOutstandingRounded),
            latePaymentCharge: numberWithCommas(latePaymentChargeRounded),
          });
        });
    }
  };

  const handleEarlySettlementChargeChange = (currentTotal?: number) => {
    const earlySettlementCharge: number = +form
      .getFieldValue("earlySettlementCharge")
      .replaceAll(",", "");

    const tax: number = earlySettlementCharge * (earlySettlementTax / 100);
    const earlySettlementChargeWithTax: number = earlySettlementCharge + tax;
    const miscChargeWithTax: number = +form
      .getFieldValue("miscChargeWithTax")
      .replaceAll(",", "");

    const total: number =
      earlySettlementChargeWithTax +
      miscChargeWithTax +
      +(currentTotal ?? totalLoanOutstanding);

    form.setFieldsValue({
      earlySettlementChargeWithTax: numberWithCommas(
        earlySettlementChargeWithTax,
      ),
      total: numberWithCommas(total),
    });
  };

  const handleMiscChargeChange = (currentTotal?: number) => {
    const miscCharge: number = +form
      .getFieldValue("miscCharge")
      .replaceAll(",", "");
    const tax: number = miscCharge * (miscTax / 100);
    const miscChargeWithTax: number = miscCharge + tax;

    const earlySettlementChargeWithTax: number = +form
      .getFieldValue("earlySettlementChargeWithTax")
      .replaceAll(",", "");
    const total: number =
      earlySettlementChargeWithTax +
      miscChargeWithTax +
      +(currentTotal ?? totalLoanOutstanding);

    form.setFieldsValue({
      miscChargeWithTax: numberWithCommas(miscChargeWithTax),
      total: numberWithCommas(total),
    });
  };

  const onFinish = async (status?: EARLY_SETTLEMENT_STATUS) => {
    await form.validateFields().then(async (values) => {
      const payload = {
        accountDetailsId: earlySettlement?.accountDetailsId,
        earlySettlementDate: values.earlySettlementDate,
        earlySettlementNo: earlySettlement?.earlySettlementNo,
        status: status ?? EARLY_SETTLEMENT_STATUS.NEW,
        remark: values.remark,
        earlySettlementTaxAmount:
          +(values.earlySettlementCharge ?? 0) *
          (formInitialValues.earlySettlementTaxPercentage / 100),
        earlySettlementChargeWithTax: +(
          values.earlySettlementChargeWithTax ?? 0
        ),
        miscTaxAmount:
          +(values.miscCharge ?? 0) *
          (formInitialValues.miscTaxPercentage / 100),
        miscChargeWithTax: +(values.miscChargeWithTax ?? 0),
      };

      await dispatch(
        updateEarlySettlementThunk({
          earlySettlementId: earlySettlement?.id,
          payload,
        }),
      )
        .unwrap()
        .then((res) => {
          if (callback) callback();

          if (res?.status === EARLY_SETTLEMENT_STATUS.NEW) {
            message.success("Save Successfully");
            // navigate(`/early-settlement/listing`);
          }
          if (res?.status === EARLY_SETTLEMENT_STATUS.IN_PROCESS) {
            message.success("Submit Successfully");
            navigate(`/early-settlement/listing`);
          }
          if (res?.status === EARLY_SETTLEMENT_STATUS.APPROVED) {
            message.success("Approve Successfully");
            navigate(`/early-settlement/listing`);
          }
          if (res?.status === EARLY_SETTLEMENT_STATUS.VOID) {
            message.warning("Reject Early Settlement");
            navigate(`/early-settlement/listing`);
          }
        });
    });
  };
  const disabledDate = (current: any) => {
    const today = new Date();
    const currentHour: number = today.getHours();
    const currentMinutes: number = today.getMinutes();

    const passWorkingHourToday =
      currentHour >= 17 || (currentHour === 16 && currentMinutes > 45);

    const noPast = current.isBefore(
      moment().subtract(passWorkingHourToday ? 0 : 1, "day"),
    );
    return noPast;
  };
  return (
    <div className="account-enquiry-container">
      {downloading ? (
        <div className="py-40">
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          <h3 className="account-enquiry-section-title">Account Details</h3>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={formInitialValues}
            labelCol={{ flex: "180px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
          >
            <Card className="mb-4 w-full">
              <Form.Item
                name="earlySettlementDate"
                label={<span>Early Settlement Date</span>}
                rules={[
                  {
                    required: true,
                    message: "Early Settlement Date is required",
                  },
                ]}
              >
                <DatePicker
                  className={newRequest ? "" : "readOnlyInput"}
                  onChange={onDateChange}
                  disabledDate={disabledDate}
                  allowClear={false}
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>

              <Form.Item
                name="earlySettlementNo"
                label={<span>Early Settlement No.</span>}
              >
                <Input className="readOnlyInput" />
              </Form.Item>

              <Form.Item name="agreementNo" label={<span>Agreement No.</span>}>
                <Input className="readOnlyInput" />
              </Form.Item>

              <Form.Item
                name="earlySettlementStatus"
                label={<span>Early Settlement Status</span>}
              >
                <Input className="readOnlyInput" />
              </Form.Item>

              <Form.Item
                label={
                  <span className="font-bold">Total Loan Outstanding</span>
                }
              >
                <Space align="start">
                  <Form.Item
                    name="totalLoanOutstanding"
                    className="mb-0"
                    shouldUpdate
                  >
                    <Input
                      className="readOnlyInput w-full"
                      inputMode="numeric"
                      prefix="RM"
                    />
                  </Form.Item>
                  <Button
                    type={viewChargesDetails ? "primary" : "default"}
                    ghost={viewChargesDetails}
                    onClick={() => setViewChargesDetails(!viewChargesDetails)}
                  >
                    View more
                  </Button>
                </Space>
              </Form.Item>

              {viewChargesDetails && (
                <>
                  <Divider className="mt-0" />
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        name="principalOB"
                        label={<span>Principal</span>}
                        shouldUpdate
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="latePaymentCharge"
                        label={<span>Late Payment Charge</span>}
                        shouldUpdate
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="reminderLetter1Charge"
                        label={<span>Reminder Letter 1 Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="reminderLetter2Charge"
                        label={<span>Reminder Letter 2 Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="reminderLetter3Charge"
                        label={<span>Reminder Letter 3 Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="ecaCharge"
                        label={<span>External Collection Agency Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="fieldCharge"
                        label={<span>Field Visit Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>
                      <Form.Item
                        name="earlySettlementChargeDisplay"
                        label={<span>Early Settlement Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="interestOB"
                        label={<span>Interest</span>}
                        shouldUpdate
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="legalCharge"
                        label={<span>Legal Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="lodCharge"
                        label={<span>LOD Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="refundCharge"
                        label={<span>Refund Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="blacklistUpliftCharge"
                        label={
                          <span>Credit Info, Blacklist Uplift Charge</span>
                        }
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="adminCharge"
                        label={<span>Admin Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="bankCharge"
                        label={<span>Bank Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>

                      <Form.Item
                        name="miscChargeDisplay"
                        label={<span>Misc Charge</span>}
                      >
                        <Input
                          className="readOnlyInput inputTextAlignRight"
                          inputMode="numeric"
                          prefix="RM"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Row gutter={[16, 16]}>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={6} className="w-full text-center">
                  <span>Tax</span>
                </Col>
                <Col span={6} className="w-full text-center">
                  <span>Amount with Tax</span>
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="mb-2" wrap={false}>
                <Col flex="180px">
                  <h4>Early Settlement Charges</h4>
                </Col>
                <Col flex="auto">
                  <Form.Item name="earlySettlementCharge" shouldUpdate>
                    <Input
                      className={
                        newRequest
                          ? "inputTextAlignRight"
                          : "readOnlyInput inputTextAlignRight"
                      }
                      inputMode="numeric"
                      prefix="RM"
                      onChange={() => handleEarlySettlementChargeChange()}
                    />
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item name="earlySettlementTaxPercentage">
                    <Input
                      suffix="%"
                      inputMode="numeric"
                      className="readOnlyInput inputTextAlignRight"
                    />
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item name="earlySettlementChargeWithTax" shouldUpdate>
                    <Input
                      className="readOnlyInput inputTextAlignRight"
                      inputMode="numeric"
                      prefix="RM"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="mb-2" wrap={false}>
                <Col flex="180px">
                  <h4>Misc Charges</h4>
                </Col>
                <Col flex="auto">
                  <Form.Item name="miscCharge" shouldUpdate>
                    <Input
                      className={
                        newRequest
                          ? "inputTextAlignRight"
                          : "readOnlyInput inputTextAlignRight"
                      }
                      inputMode="numeric"
                      prefix="RM"
                      onChange={() => handleMiscChargeChange()}
                    />
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item name="miscTaxPercentage">
                    <Input
                      suffix="%"
                      inputMode="numeric"
                      className="readOnlyInput inputTextAlignRight"
                    />
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item name="miscChargeWithTax" shouldUpdate>
                    <Input
                      className="readOnlyInput inputTextAlignRight"
                      inputMode="numeric"
                      prefix="RM"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="mb-2" wrap={false}>
                <Col flex="180px">
                  <span className="font-bold">Total</span>
                </Col>
                <Col flex="auto">
                  <Input className="mx-2 invisible" />
                </Col>
                <Col flex="auto">
                  <Input className="mx-2 invisible" />
                </Col>
                <Col flex="auto">
                  <Form.Item name="total" shouldUpdate>
                    <Input
                      className="readOnlyInput inputTextAlignRight"
                      inputMode="numeric"
                      prefix="RM"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card className="mb-4">
              <h4>Add Remark</h4>
              <Form.Item name="remark">
                <TextArea
                  rows={4}
                  disabled={earlySettlement.status === "APPROVED"}
                />
              </Form.Item>
            </Card>
            <div className="w-full flex justify-end gap-x-1.5 mb-8">
              <Dropdown
                menu={{
                  items: [
                    {
                      label: "English",
                      key: "en",
                    },
                    {
                      label: "Bahasa Malaysia",
                      key: "bm",
                    },
                  ],
                  onClick: (e) => handleDownloadQuotationClick(e),
                }}
              >
                <Button>
                  <Space>
                    <FilePdfOutlined />
                    Print Quotation
                    <CaretDownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              {!newRequest && (
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: "English",
                        key: "en",
                      },
                      {
                        label: "Bahasa Malaysia",
                        key: "bm",
                      },
                    ],
                    onClick: (e) => handleDownloadTaxInvoiceClick(e),
                  }}
                >
                  <Button>
                    <Space>
                      <FilePdfOutlined />
                      Tax Invoice
                      <CaretDownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              )}
              {earlySettlement?.status === EARLY_SETTLEMENT_STATUS.NEW && (
                <>
                  <Button
                    type="primary"
                    onClick={() => onFinish(EARLY_SETTLEMENT_STATUS.NEW)}
                    disabled={
                      earlySettlement?.status !== EARLY_SETTLEMENT_STATUS.NEW
                    }
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => onFinish(EARLY_SETTLEMENT_STATUS.IN_PROCESS)}
                    disabled={
                      earlySettlement?.status !== EARLY_SETTLEMENT_STATUS.NEW
                    }
                  >
                    Submit
                  </Button>
                </>
              )}
              {earlySettlement?.status ===
                EARLY_SETTLEMENT_STATUS.IN_PROCESS && (
                <>
                  <Button
                    type="primary"
                    onClick={() => onFinish(EARLY_SETTLEMENT_STATUS.VOID)}
                    disabled={
                      earlySettlement?.status !==
                      EARLY_SETTLEMENT_STATUS.IN_PROCESS
                    }
                  >
                    Void
                  </Button>
                </>
              )}
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default EarlySettlementForm;
