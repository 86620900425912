import { Input, Select, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../../../store/store";

import { searchDisbursedAgreementThunk } from "../../../../services/agreementService/agreementThunk";
import { collectionStateReset } from "../../../../features/collection/collectionSlice";
import SearchAgreementResult from "../../../AccountEnquiry/SearchAgreementResult";
import { getAccountDetailsThunk } from "../../../../services/collectionService/collectionThunk";
import RestructureDateModal from "../../RestructureDetailsPage/components/restructure-date-modal";
import { useNavigate } from "react-router-dom";
import { Dayjs } from "dayjs";
import {
  createRestructureCaseThunk,
  getRestructureCaseThunk,
} from "../../../../services/restructureService/restructureThunk";

const { Search } = Input;

const RestructureRequestTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [resultNotFound, setResultNotFound] = useState(false);
  const [searchType, setSearchType] = useState("AGREEMENT_NO");
  const [searchResultVisible, setSearchResultVisible] = useState(false);
  const [searchResultData, setSearchResultData] = useState([]);
  const [agreementNoSelected, setAgreementNoSelected] = useState("");
  const [rescheduleDateVisible, setRestructureDateVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSearch = async (value: string) => {
    if (value) {
      setLoadingSearch(true);
      await dispatch(
        searchDisbursedAgreementThunk({ searchType: value })
      )
        .then((res) => {
          setSearchResultData(res.payload);
          if (res.payload && res.payload.length > 0) {
            setSearchResultVisible(true);
          } else {
            message.error("Result Not Found");
            setResultNotFound(true);
          }
        })
        .finally(() => {
          setLoadingSearch(false);
        });
    } else {
      dispatch(collectionStateReset());
    }
  };

  const handleSearchResultSelect = async (agreementNo: string) => {
    setSearchResultVisible(false);

    // prompt to select reschedule date (date request to reschedule by customer)
    setAgreementNoSelected(agreementNo);
    setRestructureDateVisible(true);
  };

  const handleRestructureDateSelect = async (rescheduleDate: Dayjs) => {
    setLoading(true);
    await dispatch(getAccountDetailsThunk({ agreementNo: agreementNoSelected }))
      .unwrap()
      .then(async (res) => {
        await dispatch(
          createRestructureCaseThunk({
            accountDetailsId: res.accountDetails.id,
            rescheduleDate,
          }),
        )
          .unwrap()
          .then((res: { data: { id: string } }) => {
            navigate("/restructure/details/" + res.data.id);
          })
          .finally(() => {
            setLoading(false);
            setRestructureDateVisible(false);
          });

        // await dispatch(
        //   getRestructureCaseThunk({
        //     id: "6aa983b7-1950-4d7b-8eeb-0d38d31ef3c6",
        //   }),
        // )
        //   .unwrap()
        //   .then((res) => {
        //     navigate("/restructure/details/" + res.data.id);
        //   });
      });
  };

  const handleSearchResultCancel = () => {
    setSearchResultVisible(false);
  };

  return (
    <>
      <Input.Group compact>
        <Select
          className="w-[10rem]"
          value={searchType}
          onChange={(value) => {
            setResultNotFound(false);
            setSearchType(value);
          }}
          options={[
            { value: "AGREEMENT_NO", label: "Agreement No" },
            { value: "CUSTOMER_NRIC", label: "Applicant NRIC" },
            { value: "CUSTOMER_NAME", label: "Applicant Name" },
            { value: "PHONE_NO", label: "Phone No" },
            { value: "EMAIL", label: "Email" },
          ]}
        />
        <Search
          className="w-[20rem]"
          placeholder="Input search text"
          enterButton="Search"
          loading={loadingSearch}
          onSearch={onSearch}
          status={resultNotFound ? "error" : ""}
          onChange={() => setResultNotFound(false)}
        />
      </Input.Group>
      <br />
      {searchResultVisible && (
        <SearchAgreementResult
          visible={searchResultVisible}
          data={searchResultData}
          onOk={handleSearchResultSelect}
          onCancel={handleSearchResultCancel}
        />
      )}
      <br />
      {rescheduleDateVisible && (
        <RestructureDateModal
          isModalVisible={rescheduleDateVisible}
          setIsModalVisible={setRestructureDateVisible}
          callback={handleRestructureDateSelect}
          loading={loading}
        />
      )}
    </>
  );
};

export default RestructureRequestTable;
