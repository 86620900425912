import "./index.less";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Tag,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import startCase from "lodash.startcase";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import UploadedFieldVisitDocument from "../../../components/FieldVisitFileListModal";
import FieldVisitFileModal from "../../../components/FieldVisitFileListModal/FieldVisitFileModal";
import FieldVisitRemarksTable from "../../../components/FieldVisitRemarks";
import InfoGrid from "../../../components/InfoGrid";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  FIELD_VISIT_ACTION,
  FIELD_VISIT_STATUS,
} from "../../../enum/fieldVisitStatus";
import { fieldVisitDetailsStateReset } from "../../../features/fieldVisit/fieldVisitSlice";
import { numberWithCommas } from "../../../helpers/number";
import { useDebounce } from "../../../hooks/useDebounceHook";
import {
  getFieldVisitByIdThunk,
  updateFieldVisitDetailsThunk,
  updateFieldVisitThunk,
} from "../../../services/fieldVisitService/fieldVisitThunk";
import { getWaiverFileByEntityIdThunk } from "../../../services/fileService/fileThunk";
import { getCityStateByPostcodeThunk } from "../../../services/locationService/locationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import {
  displayDate,
  displayTime,
  displayUctTime,
} from "../../../utils/datetime.util";
import { trimPhoneNo } from "../../../utils/number.util";
import {
  numberRegexPattern,
  phoneRegexPattern,
} from "../../../utils/validation";

import type { DatePickerProps } from "antd";
const timeFormat = "HH:mm";
const dateFormat = "YYYY-MM-DD";

const FieldVisitDetailsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { fieldVisitId } = useParams();
  const { fieldVisitDetailsState } = useSelector(
    (state: RootState) => state.fieldVisit,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldVisitLoading, setFieldVisitLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [saveAsDraftLoading, setSaveAsDraftLoading] = useState(false);
  const [form] = Form.useForm();
  const [dateVisited, setDateVisited] = useState<any>(null);
  const [arrivalTime, setArrivalTime] = useState<any>(null);
  const [leaveTime, setLeaveTime] = useState<any>(null);
  const [fileList, setFileList] = useState([]);
  const [isOpenFieldVisitModal, setOpenFieldVisitModal] = useState(false);
  const [isLetterServed, setIsLetterServed] = useState<any>(null);
  const navigate = useNavigate();

  const [postcode, setPostcode] = useState<string>("");
  const [validPostcode, setValidPostcode] = useState<boolean>(true);
  const [visitedRemark, setVisitedRemark] = useState<any>(null);

  const debouncedPostcode = useDebounce<string>(postcode, 500);
  const invalidPostcodeMsg = "Invalid postcode";

  const onPostcodeChange = () => {
    setValidPostcode(true);
    setPostcode(form.getFieldsValue().newPostCode);
  };

  useEffect(() => {
    if (debouncedPostcode) {
      dispatch(
        getCityStateByPostcodeThunk({
          postcode: form.getFieldsValue().newPostCode,
        }),
      )
        .unwrap()
        .then((res) => {
          setValidPostcode(res.length > 0);
          if (res.length > 0) {
            form.setFieldsValue({
              newTown: res[0].post_office,
              newState: res[0].state_code,
            });
          } else {
            form.setFieldsValue({
              newTown: undefined,
              newState: undefined,
            });
          }
        })
        .catch((error: Error) => {
          console.log(error);
          setValidPostcode(false);
        });
    }
  }, [debouncedPostcode, dispatch]);

  useEffect(() => {
    if (fieldVisitDetailsState.data?.fieldVisitCase?.id) {
      fetchFileList();
    }

    if (fieldVisitDetailsState.data?.fieldVisitCase?.dateVisited) {
      const date = dayjs(
        fieldVisitDetailsState.data?.fieldVisitCase?.dateVisited,
      ).format("YYYY-MM-DD");
      setDateVisited(date);
    }

    if (fieldVisitDetailsState.data?.fieldVisitCase?.arrivalTime) {
      const arrivalTime = moment
        .utc(fieldVisitDetailsState.data?.fieldVisitCase?.arrivalTime)
        .format("HH:mm");
      setArrivalTime(arrivalTime);
    }

    if (fieldVisitDetailsState.data?.fieldVisitCase?.leaveTime) {
      const leaveTime = moment
        .utc(fieldVisitDetailsState.data?.fieldVisitCase?.leaveTime)
        .format("HH:mm");
      setLeaveTime(leaveTime);
    }

    if (fieldVisitDetailsState.data?.fieldVisitCase?.letterServed === true) {
      setIsLetterServed("Yes");
    } else if (
      fieldVisitDetailsState.data?.fieldVisitCase?.letterServed === false
    ) {
      setIsLetterServed("No");
    }
  }, [fieldVisitDetailsState]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    setUpdateLoading(false);
    setSaveAsDraftLoading(false);
    form.resetFields();
  };

  const fvInfo = [
    {
      title: "Agreement No",
      value:
        fieldVisitDetailsState?.data?.fieldVisitCase?.accountDetails
          ?.agreementNo ?? "-",
    },
    {
      title: "Applicant Name",
      value:
        fieldVisitDetailsState.data?.fieldVisitCase?.accountDetails
          ?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      value:
        fieldVisitDetailsState.data?.fieldVisitCase?.accountDetails
          ?.applicantNric ?? "-",
    },
    {
      title: "Billing Outstanding",
      value:
        fieldVisitDetailsState?.data?.fieldVisitCase?.accountDetails
          ?.billingOutstanding != null
          ? `RM ${numberWithCommas(
              fieldVisitDetailsState?.data?.fieldVisitCase?.accountDetails
                ?.billingOutstanding,
            )}`
          : "-",
    },
    {
      title: "Address",
      value: [
        fieldVisitDetailsState?.data?.fieldVisitCase?.address1,
        fieldVisitDetailsState?.data?.fieldVisitCase?.address2,
        fieldVisitDetailsState?.data?.fieldVisitCase?.address3,
      ]
        .filter((el) => el)
        .join(", "),
    },
    {
      title: "Postcode",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.postCode ?? "-",
    },
    {
      title: "City",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.city ?? "-",
    },
    {
      title: "State",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.state ?? "-",
    },
    {
      title: "Field Visit Officer",
      value:
        `${fieldVisitDetailsState?.data?.fvOfficer?.firstName ?? "-"} ${
          fieldVisitDetailsState?.data?.fvOfficer?.lastName ?? "-"
        }` ?? "-",
    },
    {
      title: "Assigned Date",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.assignedDate
        ? displayDate(
            fieldVisitDetailsState?.data?.fieldVisitCase?.assignedDate,
          )
        : "-",
    },
  ];
  const afterFvInfo = [
    {
      title: "Date Visited",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.dateVisited
        ? displayDate(fieldVisitDetailsState?.data?.fieldVisitCase?.dateVisited)
        : "-",
    },
    {
      title: "Arrival Time",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.arrivalTime
        ? displayUctTime(
            fieldVisitDetailsState?.data?.fieldVisitCase?.arrivalTime,
          )
        : "-",
    },
    {
      title: "Leave Time",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.leaveTime
        ? displayUctTime(
            fieldVisitDetailsState?.data?.fieldVisitCase?.leaveTime,
          )
        : "-",
    },
    {
      title: "Mileage (km)",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.mileAge ?? "-",
    },
    {
      title: "Meetup Person",
      value:
        startCase(fieldVisitDetailsState?.data?.fieldVisitCase?.meetUpPerson) ??
        "-",
    },
    {
      title: "Job Position",
      value:
        startCase(fieldVisitDetailsState?.data?.fieldVisitCase?.jobPosition) ??
        "-",
    },
  ];

  const newInfo = [
    {
      title: "Contact No",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.newContactNo ?? "-",
    },
    {
      title: "Email",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.newEmail ?? "-",
    },
    {
      title: "Address 1",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.newAddress1 ?? "-",
    },
    {
      title: "Address 2",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.newAddress2 ?? "-",
    },
    {
      title: "Address 3",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.newAddress3 ?? "-",
    },
    {
      title: "Postcode",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.newPostCode ?? "-",
    },
    {
      title: "City",
      value: fieldVisitDetailsState.data?.fieldVisitCase?.newTown,
    },
    {
      title: "State",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.newState ?? "-",
    },
    {
      title: "Company Name",
      value:
        fieldVisitDetailsState?.data?.fieldVisitCase?.newCompanyName ?? "-",
    },
  ];

  const fvInfo2 = [
    {
      title: "Letter Served",
      value: isLetterServed ?? "-",
    },
    {
      title: "Type Of Property",
      value: startCase(
        fieldVisitDetailsState?.data?.fieldVisitCase?.typeOfProperty ?? "-",
      ),
    },
    {
      title: "Property Ownership",
      value: startCase(
        fieldVisitDetailsState?.data?.fieldVisitCase?.propertyOwnerShip ?? "-",
      ),
    },
    {
      title: "Property Condition",
      value: startCase(
        fieldVisitDetailsState?.data?.fieldVisitCase?.propertyCondition ?? "-",
      ),
    },
    {
      title: "Vehicle Sighted Model",
      value:
        fieldVisitDetailsState?.data?.fieldVisitCase?.vehicleSightedModel ??
        "-",
    },
    {
      title: "Vehicle Reg No",
      value: fieldVisitDetailsState?.data?.fieldVisitCase?.vehicleRegNo ?? "-",
    },
  ];

  const fetchFileList = async () => {
    setLoading(true);
    await dispatch(
      getWaiverFileByEntityIdThunk({
        entityId: fieldVisitDetailsState.data?.fieldVisitCase?.id,
      }),
    )
      .unwrap()
      .then((res) => {
        setFileList(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchFieldVisitById = (id: string) => {
    dispatch(getFieldVisitByIdThunk({ id }));
  };

  useEffect(() => {
    form.setFieldsValue({
      dateVisited:
        fieldVisitDetailsState.data?.fieldVisitCase?.dateVisited !== null &&
        dayjs(
          fieldVisitDetailsState.data?.fieldVisitCase?.dateVisited,
          dateFormat,
        ),
      arrivalTime:
        fieldVisitDetailsState.data?.fieldVisitCase?.arrivalTime !== null &&
        dayjs(
          moment
            .utc(fieldVisitDetailsState.data?.fieldVisitCase?.arrivalTime)
            .format("HH:mm"),
          timeFormat,
        ),
      leaveTime:
        fieldVisitDetailsState.data?.fieldVisitCase?.leaveTime !== null &&
        dayjs(
          moment
            .utc(fieldVisitDetailsState.data?.fieldVisitCase?.leaveTime)
            .format("HH:mm"),
          timeFormat,
        ),
      mileAge: fieldVisitDetailsState.data?.fieldVisitCase?.mileAge,
      meetUpPerson: fieldVisitDetailsState.data?.fieldVisitCase?.meetUpPerson,
      jobPosition: fieldVisitDetailsState.data?.fieldVisitCase?.jobPosition,
      newContactNo:
        fieldVisitDetailsState.data?.fieldVisitCase?.newContactNo ?? null,
      newAddress1:
        fieldVisitDetailsState.data?.fieldVisitCase?.newAddress1 ?? null,
      newAddress2:
        fieldVisitDetailsState.data?.fieldVisitCase?.newAddress2 ?? null,
      newAddress3:
        fieldVisitDetailsState.data?.fieldVisitCase?.newAddress3 ?? null,
      newPostCode:
        fieldVisitDetailsState.data?.fieldVisitCase?.newPostCode ?? null,
      newTown: fieldVisitDetailsState.data?.fieldVisitCase?.newTown ?? null,
      newState: fieldVisitDetailsState.data?.fieldVisitCase?.newState ?? null,
      typeOfProperty:
        fieldVisitDetailsState.data?.fieldVisitCase?.typeOfProperty ?? null,
      propertyOwnerShip:
        fieldVisitDetailsState.data?.fieldVisitCase?.propertyOwnerShip ?? null,
      propertyCondition:
        fieldVisitDetailsState.data?.fieldVisitCase?.propertyCondition ?? null,
      vehicleSightedModel:
        fieldVisitDetailsState.data?.fieldVisitCase?.vehicleSightedModel ??
        null,
      vehicleRegNo:
        fieldVisitDetailsState.data?.fieldVisitCase?.vehicleRegNo ?? null,
      newCompanyName:
        fieldVisitDetailsState.data?.fieldVisitCase?.newCompanyName ?? null,
      newEmail: fieldVisitDetailsState.data?.fieldVisitCase?.newEmail ?? null,
      letterServed: isLetterServed,
    });
  }, [fieldVisitDetailsState?.data?.fieldVisitCase]);

  const resetFieldVisitDetailsState = () =>
    dispatch(fieldVisitDetailsStateReset());

  // useLifecycles(mount, unmount);
  useLifecycles(
    () => fieldVisitId && fetchFieldVisitById(fieldVisitId),
    () => resetFieldVisitDetailsState(),
  );

  const dateOnChange: DatePickerProps["onChange"] = (date, dateString) => {
    setDateVisited(dateString);
  };

  const closedFieldVisit = async () => {
    const payload = {
      contactNo: trimPhoneNo(
        fieldVisitDetailsState.data?.fieldVisitCase?.accountDetails
          ?.applicantPhoneNo,
      ),
      contactMode: FIELD_VISIT_ACTION.FIELD_VISIT,
      accountDetailsId:
        fieldVisitDetailsState.data?.fieldVisitCase?.accountDetailsId,
      createdBy: localStorage.getItem("username"),
      status: FIELD_VISIT_STATUS.CLOSED,
    };

    await dispatch(updateFieldVisitThunk({ id: fieldVisitId, payload }))
      .then(() => {
        message.success(
          `Close Field Visit ${fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo}`,
        );
        setFieldVisitLoading(false);

        navigate(`/field-visit/listing`);
      })
      .catch(() => {
        message.error("Failed to update field visit status");
        setFieldVisitLoading(false);
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  const visitedFieldVisitConfirm = async () => {
    setOpenFieldVisitModal(true);
  };

  const visitedFieldVisit = async () => {
    if (visitedRemark.length == 0) {
      message.error("Please provide remark.");
      return;
    }

    if (
      !(
        fieldVisitDetailsState.data?.fieldVisitCase?.dateVisited &&
        fieldVisitDetailsState.data?.fieldVisitCase?.arrivalTime &&
        fieldVisitDetailsState.data?.fieldVisitCase?.leaveTime &&
        fieldVisitDetailsState.data.fieldVisitCase.mileAge
      )
    ) {
      message.error(
        "Please provide date visited, approval time, leave time, and mileage before proceed to visited.",
      );
      return;
    }

    if (fileList.length === 0) {
      message.error("Please upload at least one support document.");
      return;
    }

    const payload = {
      contactNo: trimPhoneNo(
        fieldVisitDetailsState.data?.fieldVisitCase?.accountDetails
          ?.applicantPhoneNo,
      ),
      contactMode: FIELD_VISIT_ACTION.FIELD_VISIT,
      accountDetailsId:
        fieldVisitDetailsState.data?.fieldVisitCase?.accountDetailsId,
      createdBy: localStorage.getItem("username"),
      status: FIELD_VISIT_STATUS.VISITED,
      remarks: {
        remarks: visitedRemark,
        fieldVisitCaseId: fieldVisitDetailsState.data?.fieldVisitCase?.id,
        username: localStorage.getItem("username"),
      },
    };

    await dispatch(updateFieldVisitThunk({ id: fieldVisitId, payload }))
      .then(() => {
        message.success(
          `Visited Field Visit ${fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo}`,
        );
        setVisitedRemark("");
        setFieldVisitLoading(false);

        navigate(`/field-visit/listing`);
      })
      .catch(() => {
        message.error("Failed to update field visit status");
        setFieldVisitLoading(false);
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  const onFinish = async (status?: string) => {
    if (
      status === FIELD_VISIT_STATUS.OPEN ||
      status === FIELD_VISIT_STATUS.PENDING
    ) {
      setUpdateLoading(true);
    }

    await form.validateFields().then(async (values) => {
      // Todo:
      // If the values are empty, do not update the field visit
      // If the values are not empty, update the field visit
      const payload: any = {};
      let arrivalTime = null;
      let leaveTime = null;
      let dateVisited = null;
      const toCompareValues: any = {};
      const remarksData: any = {};
      const fieldVisitId =
        fieldVisitDetailsState.data?.fieldVisitCase?.id === undefined
          ? ""
          : fieldVisitDetailsState.data?.fieldVisitCase?.id;
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          payload[key] = values[key];
          if (key === "newContactNo") {
            //Todo:
            // Convert the type of newContactNo to number
            payload[key] = values[key].toString();
          }
          if (key === "dateVisited") {
            dateVisited = new Date(values[key]);
          }
          if (key === "mileAge") {
            payload[key] = Number(values[key]);
          }
          if (key === "arrivalTime") {
            arrivalTime = new Date(values[key]);
          }
          if (key === "leaveTime") {
            leaveTime = new Date(values[key]);
          }

          if (key === "newAddress1") {
            toCompareValues["applicantAddress1"] = values[key];
          }

          if (key === "newAddress2") {
            toCompareValues["applicantAddress2"] = values[key];
          }

          if (key === "newAddress3") {
            toCompareValues["applicantAddress3"] = values[key];
          }

          if (key === "newPostCode") {
            toCompareValues["applicantPostcode"] = values[key];
          }

          if (key === "newState") {
            toCompareValues["applicantState"] = values[key];
          }

          if (key === "newEmail") {
            toCompareValues["applicantEmail"] = values[key];
          }

          if (key === "newContactNo") {
            toCompareValues["applicantPhoneNo"] = values[key].toString();
          }

          if (key === "remarks") {
            // remarks.push({
            //   remarks: values[key],
            //   createdBy: localStorage.getItem("username"),
            //   fieldVisitCaseId: fieldVisitDetailsState.data?.fieldVisitCase?.id,
            // });
            remarksData["remarks"] = values[key];
            remarksData["username"] = localStorage.getItem("username");
            remarksData["fieldVisitCaseId"] =
              fieldVisitDetailsState.data?.fieldVisitCase?.id;
          }

          if (key == "letterServed") {
            if (values[key] == "true") {
              payload[key] = true;
            } else if (values[key] == "false") {
              payload[key] = false;
            }
          }
        }
      });

      payload.toCompareValues = toCompareValues;
      payload.accountDetailsId =
        fieldVisitDetailsState.data?.fieldVisitCase?.accountDetailsId;
      if (remarksData.remarks != null) {
        payload.remarks = remarksData;
      }
      console.log(payload);
      console.log(status);
      if (status === FIELD_VISIT_STATUS.PENDING) {
        if (arrivalTime && leaveTime) {
          if (leaveTime < arrivalTime) {
            message.error("Leave time can't be smaller than arrival Time");
            setUpdateLoading(false);
          } else {
            payload.status = status;
            await dispatch(
              updateFieldVisitDetailsThunk({
                id: fieldVisitId,
                agreementNo:
                  fieldVisitDetailsState.data?.fieldVisitCase?.accountDetails
                    ?.agreementNo,
                payload,
              }),
            )
              .unwrap()
              .then(async (res) => {
                if (res?.error) {
                  message.error(res?.payload);
                } else {
                  message.success(
                    `Field Visit ${fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo} updated`,
                  );
                  setIsModalOpen(false);
                  form.resetFields();
                  fetchFieldVisitById(fieldVisitId);
                }
              })
              .finally(() => {
                fetchFieldVisitById(fieldVisitId);
                setSaveAsDraftLoading(false);
                setUpdateLoading(false);
                setFieldVisitLoading(false);
                setLoading(false);
                form.resetFields();
              });
            // .then(() => {
            //   message.success(
            //     `Field Visit ${fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo} updated`,
            //   );
            //   setFieldVisitLoading(false);
            //   setIsModalOpen(false);
            // })
            // .catch(() => {
            //   message.error("Failed to update field visit status");
            //   setIsModalOpen(false);
            // });
          }
        } else {
          payload.status = status;

          await dispatch(
            updateFieldVisitDetailsThunk({
              id: fieldVisitId,
              agreementNo:
                fieldVisitDetailsState.data?.fieldVisitCase?.accountDetails
                  ?.agreementNo,
              payload,
            }),
          )
            .unwrap()
            .then(async (res: any) => {
              if (res?.error) {
                message.error(res?.payload);
              } else {
                message.success(
                  `Field Visit ${fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo} updated`,
                );
                setIsModalOpen(false);
                form.resetFields();
                fetchFieldVisitById(fieldVisitId);
              }
            })
            .finally(() => {
              const fieldVisitId =
                fieldVisitDetailsState.data?.fieldVisitCase?.id === undefined
                  ? ""
                  : fieldVisitDetailsState.data?.fieldVisitCase?.id;
              fetchFieldVisitById(fieldVisitId);
              setFieldVisitLoading(false);
              setLoading(false);
              setSaveAsDraftLoading(false);
              setUpdateLoading(false);
              form.resetFields();
            });
        }
      } else {
        if (dateVisited) {
          if (!arrivalTime && !leaveTime) {
            message.error("Please select arrival time and leave time");
            setSaveAsDraftLoading(false);
            setUpdateLoading(false);
          } else {
            if (arrivalTime && leaveTime) {
              if (leaveTime < arrivalTime) {
                message.error("Leave time can't be smaller than arrival Time");
              } else {
                payload.status = status;
                await dispatch(
                  updateFieldVisitDetailsThunk({
                    id: fieldVisitId,
                    agreementNo:
                      fieldVisitDetailsState.data?.fieldVisitCase
                        ?.accountDetails?.agreementNo,
                    payload,
                  }),
                )
                  .unwrap()
                  .then(async (res: any) => {
                    if (res?.error) {
                      message.error(res?.payload);
                    } else {
                      message.success(
                        `Field Visit ${fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo} updated`,
                      );
                      setIsModalOpen(false);
                      form.resetFields();
                      fetchFieldVisitById(fieldVisitId);
                    }
                  })
                  .finally(() => {
                    fetchFieldVisitById(fieldVisitId);
                    setSaveAsDraftLoading(false);
                    setUpdateLoading(false);
                    setFieldVisitLoading(false);
                    setLoading(false);
                    form.resetFields();
                  });
                // .then(() => {
                //   message.success(
                //     `Field Visit ${fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo} updated`,
                //   );
                //   setFieldVisitLoading(false);
                //   setIsModalOpen(false);
                // })
                // .catch(() => {
                //   message.error("Failed to update field visit status");
                //   setIsModalOpen(false);
                // });
              }
            } else {
              payload.status = status;

              await dispatch(
                updateFieldVisitDetailsThunk({
                  id: fieldVisitId,
                  agreementNo:
                    fieldVisitDetailsState.data?.fieldVisitCase?.accountDetails
                      ?.agreementNo,
                  payload,
                }),
              )
                .unwrap()
                .then(async (res: any) => {
                  if (res?.error) {
                    message.error(res?.payload);
                  } else {
                    message.success(
                      `Field Visit ${fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo} updated`,
                    );
                    setIsModalOpen(false);
                    form.resetFields();
                    fetchFieldVisitById(fieldVisitId);
                  }
                })
                .finally(() => {
                  fetchFieldVisitById(fieldVisitId);
                  setFieldVisitLoading(false);
                  // setIsModalOpen(false);
                  setLoading(false);
                  setSaveAsDraftLoading(false);
                  setUpdateLoading(false);
                  form.resetFields();
                });
            }
          }
        } else {
          payload.status = status;

          await dispatch(
            updateFieldVisitDetailsThunk({
              id: fieldVisitId,
              agreementNo:
                fieldVisitDetailsState.data?.fieldVisitCase?.accountDetails
                  ?.agreementNo,
              payload,
            }),
          )
            .unwrap()
            .then(async (res: any) => {
              if (res?.error) {
                message.error(res?.payload);
              } else {
                message.success(
                  `Field Visit ${fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo} updated`,
                );
                setIsModalOpen(false);
                form.resetFields();
                fetchFieldVisitById(fieldVisitId);
              }
            })
            .finally(() => {
              fetchFieldVisitById(fieldVisitId);
              setFieldVisitLoading(false);
              // setIsModalOpen(false);
              setLoading(false);
              setSaveAsDraftLoading(false);
              setUpdateLoading(false);
              form.resetFields();
            });
        }
      }
    });
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Field Visit Details - redCASH CEP</title>
      </Helmet>

      {fieldVisitId ? (
        <div className="account-enquiry-container">
          {fieldVisitDetailsState.isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="account-enquiry-section-container">
              <PageHeader
                className="p-0 mb-2"
                onBack={onBack}
                title={
                  <Space align="center">
                    <h3 className="account-enquiry-section-title mb-0 mr-2">
                      Field Visit Details
                    </h3>
                    <Tag>
                      {startCase(
                        fieldVisitDetailsState.data?.fieldVisitCase?.status ===
                          "CLOSED"
                          ? "CANCEL"
                          : fieldVisitDetailsState.data?.fieldVisitCase?.status,
                      )}
                    </Tag>
                  </Space>
                }
              />
              <div className="info-container mb-8">
                <InfoGrid data={fvInfo} />
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <h3 className="account-enquiry-section-title mb-0 mr-2">
                    After Field Visit Details
                  </h3>
                  <Tag>
                    Field Visit No:{" "}
                    {fieldVisitDetailsState?.data?.fieldVisitCase?.fieldVisitNo}
                  </Tag>
                </div>
                <div>
                  {fieldVisitDetailsState?.data?.fieldVisitCase?.status ===
                    FIELD_VISIT_STATUS.OPEN && (
                    <Button type="primary" onClick={showModal}>
                      Update Info
                    </Button>
                  )}
                  {fieldVisitDetailsState?.data?.fieldVisitCase?.status ===
                    FIELD_VISIT_STATUS.PENDING && (
                    <Button type="primary" onClick={showModal}>
                      Update Info
                    </Button>
                  )}
                </div>
              </div>
              <div className="info-container">
                <InfoGrid data={afterFvInfo} />
                <div className="p-4" />
                <InfoGrid title="New Information" data={newInfo} />
                <div className="p-4" />
                <InfoGrid data={fvInfo2} />
              </div>
              <br />

              {fieldVisitDetailsState.data?.fieldVisitCase?.status ===
                FIELD_VISIT_STATUS.OPEN && (
                <div className="info-container">
                  <h3 className="account-enquiry-section-title mb-0 mr-2">
                    Support Documents
                  </h3>
                  <div className="p-4" />
                  <FieldVisitFileModal
                    entityId={fieldVisitDetailsState.data?.fieldVisitCase?.id}
                    callback={(res: any) => {
                      fetchFileList();
                    }}
                  />
                </div>
              )}

              {fieldVisitDetailsState.data?.fieldVisitCase?.status ===
                FIELD_VISIT_STATUS.PENDING && (
                <div className="info-container">
                  <h3 className="account-enquiry-section-title mb-0 mr-2">
                    Support Documents
                  </h3>
                  <div className="p-4" />
                  <FieldVisitFileModal
                    entityId={fieldVisitDetailsState.data?.fieldVisitCase?.id}
                    callback={(res: any) => {
                      fetchFileList();
                    }}
                  />
                </div>
              )}

              <br />
              <div className="info-container">
                <h3 className="account-enquiry-section-title mb-0 mr-2">
                  Uploaded Documents
                </h3>
                <UploadedFieldVisitDocument
                  entityId={fieldVisitDetailsState.data?.fieldVisitCase?.id}
                  status={fieldVisitDetailsState?.data?.fieldVisitCase?.status}
                  defaultFileList={fileList}
                />
              </div>

              <br />

              <div className="info-container">
                <h3 className="account-enquiry-section-title mb-0 mr-2">
                  Remarks
                </h3>
                <FieldVisitRemarksTable dataSource={fieldVisitDetailsState} />
              </div>
              <br />
              {fieldVisitDetailsState?.data?.fieldVisitCase?.status ===
                FIELD_VISIT_STATUS.OPEN && (
                <>
                  <Row justify="end" className="mb-2" align="bottom">
                    <Space>
                      <Button
                        type="primary"
                        onClick={closedFieldVisit}
                        loading={fieldVisitLoading}
                      >
                        Cancel
                      </Button>
                      {/* <UpdateFieldVisitModal
                        isOpen={isOpenFieldVisitModal}
                        callback={(res: any) => {
                          setOpenFieldVisitModal(false);
                          navigate(`/field-visit/listing`);
                        }}
                        dataSource={fieldVisitDetailsState}
                        fieldVisitId={fieldVisitId}
                      /> */}
                      <Modal
                        open={isOpenFieldVisitModal}
                        onCancel={() => {
                          setOpenFieldVisitModal(false);
                          setVisitedRemark("");
                        }}
                        onOk={visitedFieldVisit}
                        destroyOnClose
                      >
                        <Form>
                          <br />
                          <Form.Item label="Remark">
                            <Input
                              onChange={(e) => setVisitedRemark(e.target.value)}
                            />
                          </Form.Item>
                        </Form>
                      </Modal>
                      <Button
                        type="primary"
                        onClick={visitedFieldVisitConfirm}
                        loading={fieldVisitLoading}
                      >
                        Visited
                      </Button>
                    </Space>
                  </Row>
                </>
              )}

              {fieldVisitDetailsState?.data?.fieldVisitCase?.status ===
                FIELD_VISIT_STATUS.PENDING && (
                <>
                  <Row justify="end" className="mb-2" align="bottom">
                    <Space>
                      <Button
                        type="primary"
                        onClick={closedFieldVisit}
                        loading={fieldVisitLoading}
                      >
                        Cancel
                      </Button>
                      {/* <UpdateFieldVisitModal
                        isOpen={isOpenFieldVisitModal}
                        callback={(res: any) => {
                          setOpenFieldVisitModal(false);
                          navigate(`/field-visit/listing`);
                        }}
                        dataSource={fieldVisitDetailsState}
                        fieldVisitId={fieldVisitId}
                      /> */}
                      <Modal
                        open={isOpenFieldVisitModal}
                        onCancel={() => {
                          setOpenFieldVisitModal(false);
                          setVisitedRemark("");
                        }}
                        onOk={visitedFieldVisit}
                        destroyOnClose
                      >
                        <Form>
                          <br />

                          <Form.Item label="Remark">
                            <Input
                              onChange={(e) => setVisitedRemark(e.target.value)}
                            />
                          </Form.Item>
                        </Form>
                      </Modal>
                      <Button
                        type="primary"
                        onClick={visitedFieldVisitConfirm}
                        loading={fieldVisitLoading}
                      >
                        Visited
                      </Button>
                    </Space>
                  </Row>
                </>
              )}

              <Modal
                title="Update Field Visit Info"
                okText="Confirm"
                open={isModalOpen}
                // onOk={onFinish}
                onCancel={handleCancel}
                confirmLoading={loading}
                width={1000}
                style={{
                  top: 20,
                  height: "auto",
                  overflowY: "auto",
                }}
                destroyOnClose
                centered
                footer={[
                  <Button
                    key="update"
                    onClick={() =>
                      onFinish(
                        fieldVisitDetailsState.data?.fieldVisitCase?.status,
                      )
                    }
                    type="primary"
                    loading={updateLoading}
                  >
                    Update
                  </Button>,
                  <Button key="cancel" onClick={handleCancel} type="primary">
                    Cancel
                  </Button>,
                ]}
              >
                {fieldVisitDetailsState.isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    requiredMark={true}
                    initialValues={{
                      dateVisited:
                        dateVisited !== null && dayjs(dateVisited, dateFormat),
                      arrivalTime:
                        arrivalTime !== null && dayjs(arrivalTime, "HH:mm"),
                      leaveTime:
                        leaveTime !== null && dayjs(leaveTime, "HH:mm"),
                      mileAge:
                        fieldVisitDetailsState.data?.fieldVisitCase?.mileAge,
                      meetUpPerson:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.meetUpPerson,
                      jobPosition:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.jobPosition,
                      newContactNo:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.newContactNo ?? null,
                      newAddress1:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.newAddress1 ?? null,
                      newAddress2:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.newAddress2 ?? null,
                      newAddress3:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.newAddress3 ?? null,
                      newPostCode:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.newPostCode ?? null,
                      newTown:
                        fieldVisitDetailsState.data?.fieldVisitCase?.newTown ??
                        null,
                      newState:
                        fieldVisitDetailsState.data?.fieldVisitCase?.newState ??
                        null,
                      typeOfProperty:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.typeOfProperty ?? null,
                      propertyOwnerShip:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.propertyOwnerShip ?? null,
                      propertyCondition:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.propertyCondition ?? null,
                      vehicleSightedModel:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.vehicleSightedModel ?? null,
                      vehicleRegNo:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.vehicleRegNo ?? null,
                      newCompanyName:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.newCompanyName ?? null,
                      newEmail:
                        fieldVisitDetailsState.data?.fieldVisitCase?.newEmail ??
                        null,
                      letterServed:
                        fieldVisitDetailsState.data?.fieldVisitCase
                          ?.letterServed,

                      // newContactNo: null,
                      // newAddress1: null,
                      // newAddress2: null,
                      // newAddress3: null,
                      // mileAge: null,
                      // typeOfProperty: null,
                      // propertyOwnerShip: null,
                      // propertyCondition: null,
                      // vehicleSightedModel: null,
                      // vehicleRegNo: null,
                      // newCompanyName: null,
                      // newEmail: null,
                    }}
                  >
                    <Row gutter={[32, 16]}>
                      <Col span={8}>
                        <Card className="mb-4">
                          <Form.Item
                            label="Date Visited"
                            name="dateVisited"
                            required
                          >
                            <DatePicker
                              onChange={dateOnChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Arrival Time"
                            name="arrivalTime"
                            required
                          >
                            <TimePicker
                              format={timeFormat}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Leave Time"
                            name="leaveTime"
                            required
                          >
                            <TimePicker
                              format={timeFormat}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Mileage (km)"
                            name="mileAge"
                            required
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item label="Meet Up Person" name="meetUpPerson">
                            <Select
                              placeholder="Please Select"
                              allowClear
                              options={[
                                { value: "CUSTOMER", label: "Customer" },
                                { value: "SPOUSE", label: "Spouse" },
                                { value: "SIBLINGS", label: "Siblings" },
                                { value: "PARENTS", label: "Parents" },
                                { value: "NEIGHBOUR", label: "Neighbour" },
                                { value: "MAID", label: "Maid" },
                                { value: "TENANT", label: "Tenant" },
                                { value: "COLLEAGUES", label: "Colleagues" },
                                {
                                  value: "RECEPTIONIST",
                                  label: "Receptionist",
                                },
                                { value: "OTHERS", label: "Others" },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item label="Job Position" name="jobPosition">
                            <Input />
                          </Form.Item>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card className="mb-4">
                          <Form.Item
                            label="New Contact No."
                            name="newContactNo"
                            rules={[
                              {
                                pattern: phoneRegexPattern,
                                message: "Invalid phone number",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                          <Form.Item
                            name="newEmail"
                            label="New Email"
                            rules={[
                              {
                                type: "email",
                                message: "Invalid Email",
                              },
                            ]}
                          >
                            <Input
                              placeholder={"Enter Email"}
                              onInput={(e) =>
                                ((e.target as HTMLInputElement).value = (
                                  e.target as HTMLInputElement
                                ).value.toLowerCase())
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            label="New Address line 1"
                            name="newAddress1"
                          >
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                              minLength={5}
                            />
                          </Form.Item>
                          <Form.Item
                            label="New Address line 2"
                            name="newAddress2"
                          >
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                              minLength={5}
                            />
                          </Form.Item>
                          <Form.Item
                            label="New Address line 3"
                            name="newAddress3"
                          >
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                              minLength={5}
                            />
                          </Form.Item>
                          <Form.Item
                            label="New Postcode"
                            name="newPostCode"
                            rules={[
                              {
                                pattern: numberRegexPattern,
                                message: "Invalid postcode",
                              },
                            ]}
                            validateFirst={true}
                            validateStatus={validPostcode ? "success" : "error"}
                            help={!validPostcode && invalidPostcodeMsg}
                          >
                            <Input
                              inputMode="numeric"
                              placeholder="Please input"
                              onChange={onPostcodeChange}
                            />
                          </Form.Item>
                          <Form.Item label="New City" name="newTown">
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                              minLength={5}
                            />
                          </Form.Item>
                          <Form.Item label="New State" name="newState">
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                              minLength={5}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Employment Company Name"
                            name="newCompanyName"
                          >
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                              minLength={5}
                            />
                          </Form.Item>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card className="mb-4">
                          <Form.Item
                            label="Type of Visited Property"
                            name="typeOfProperty"
                          >
                            <Select
                              placeholder="Please Select"
                              allowClear
                              options={[
                                {
                                  value: "singleStoreyLanded",
                                  label: "Single Storey Landed",
                                },
                                {
                                  value: "doubleStoreyLanded",
                                  label: "Double Storey Landed",
                                },
                                { value: "semiD", label: "Semi-D" },
                                { value: "bungalow", label: "Bungalow" },
                                {
                                  value: "lowCostFlat",
                                  label: "Low Cost Flat",
                                },
                                { value: "apt", label: "Apt" },
                                { value: "condo", label: "Condo" },
                                { value: "wooden", label: "Wooden" },
                                { value: "townHouse", label: "Town House" },
                                { value: "officeLot", label: "Office Lot" },
                                { value: "shopLot", label: "Shop Lot" },
                                { value: "warehouse", label: "Warehouse" },
                                { value: "others", label: "Others" },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Property Ownership"
                            name="propertyOwnerShip"
                          >
                            <Select
                              placeholder="Please Select"
                              allowClear
                              options={[
                                { value: "rent", label: "Rent" },
                                { value: "own", label: "Owner" },
                                {
                                  value: "masterOfHouse",
                                  label: "Master of House",
                                },
                                { value: "others", label: "Others" },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Property Condition"
                            name="propertyCondition"
                          >
                            <Select
                              placeholder="Please Select"
                              allowClear
                              options={[
                                {
                                  value: "renovationInProgress",
                                  label: "Renovation in Progress",
                                },
                                {
                                  value: "someoneLivingThere",
                                  label: "Someone Living There",
                                },
                                {
                                  value: "extensivelyRenovated",
                                  label: "Extensively Renovated",
                                },
                                {
                                  value: "emptyOrAbandon",
                                  label: "Empty / Abandon",
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Vehicle Sighted Model"
                            name="vehicleSightedModel"
                          >
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                              minLength={5}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Vehicle Reg No."
                            name="vehicleRegNo"
                          >
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                              minLength={5}
                            />
                          </Form.Item>

                          <Form.Item label="Letter Served" name="letterServed">
                            <Select
                              placeholder="Please Select"
                              allowClear
                              options={[
                                { value: "true", label: "Yes" },
                                { value: "false", label: "No" },
                              ]}
                            />
                          </Form.Item>

                          <Form.Item label="Remark" name="remarks" required>
                            <Input
                              placeholder="Please input"
                              style={{ width: "100%" }}
                              minLength={5}
                            />
                          </Form.Item>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Modal>
            </div>
          )}
        </div>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, field visit not found."
        />
      )}
    </>
  );
};

export default FieldVisitDetailsPage;
