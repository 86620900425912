import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import disbursementService from "./disbursementService";

export const getPendingDisbursementAgreementsThunk = createAsyncThunk(
  "disbursement/getPendingDisbursementAgreementList",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.getPendingDisbursementAgreements(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDisbursementListingSummaryThunk = createAsyncThunk(
  "disbursement/getDisbursementListingSummary",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.getDisbursementListingSummary(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDisbursementListingSummaryTxtFileThunk = createAsyncThunk(
  "disbursement/getDisbursementListingSummaryTxtFile",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.getDisbursementListingSummaryTxtFile(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDisbursementBatchListingThunk = createAsyncThunk(
  "disbursement/getDisbursementBatchListing",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.getDisbursementBatchListing(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createDisbursementBatchThunk = createAsyncThunk(
  "disbursement/createDisbursementBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.createDisbursementBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDisbursementAgreementsByBatchIdTrunk = createAsyncThunk(
  "disbursement/getDisbursementAgreements",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.getDisbursementAgreementsByBatchId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const postAgreeementTrunk = createAsyncThunk(
  "disbursement/postAgreeement",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.postAgreement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const repostAgreementTrunk = createAsyncThunk(
  "disbursement/repostAgreement",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.repostAgreement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const unpostAgreeementByAgreementIdTrunk = createAsyncThunk(
  "disbursement/unpostAgreeementByAgreementId",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.unpostAgreementByAgreementId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getDisbursementBatchDetailsTrunk = createAsyncThunk(
  "disbursement/getDisbursementBatchDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.getDisbursementBatchDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchDisbursementBatchToInProcessTrunk = createAsyncThunk(
  "disbursement/patchDisbursementBatchToInProcess",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.patchDisbursementBatchToInProcess(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const postAgreementsInBatchTrunk = createAsyncThunk(
  "disbursement/postAgreementsInBatchTrunk",
  async (requestData: any, thunkAPI) => {
    try {
      return await disbursementService.postAgreementsInBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchPendingDisbursementAgreementToReferbackTrunk =
  createAsyncThunk(
    "disbursement/patchPendingDisbursementAgreementToReferback",
    async (requestData: any, thunkAPI) => {
      try {
        return await disbursementService.patchPendingDisbursementAgreementToReferback(
          requestData,
        );
      } catch (error) {
        const message = restructureErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    },
  );
