import { Tabs, Tag } from "antd";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";

import { AppDispatch, RootState } from "../../../store/store";

import type { TabsProps } from "antd";

import { useLifecycles } from "react-use";
import RestructureTable from "./components/restructureTable";
import {
  getApprovedRestructureListingThunk,
  getOpenRestructureListingThunk,
  getPendingRestructureListingThunk,
  getReferbackRestructureListingThunk,
  getVoidRestructureListingThunk,
} from "../../../services/restructureService/restructureThunk";
import { RestructureTypeEnum } from "../../../enum/restructure";
import { getReferRescheduleListingThunk } from "../../../services/rescheduleService/rescheduleThunk";

const RestructureListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const {
    openRestructureListing,
    pendingRestructureListing,
    voidRestructureListing,
    approvedRestructureListing,
    referbackRestructureListing,
  } = useSelector((state: RootState) => state.restructure);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [activeTab, pageNum, pageSize],
  );

  const fetchOpenRestructureListing = (params: any) => {
    dispatch(
      getOpenRestructureListingThunk({
        ...params,
        filters: { status: RestructureTypeEnum.OPEN },
      }),
    );
  };

  const fetchPendingRestructureListing = (params: any) => {
    dispatch(
      getPendingRestructureListingThunk({
        ...params,
        filters: { status: RestructureTypeEnum.PENDING },
      }),
    );
  };

  const fetchVoidRestructureListing = (params: any) => {
    dispatch(
      getVoidRestructureListingThunk({
        ...params,
        filters: { status: RestructureTypeEnum.VOID },
      }),
    );
  };

  const fetchApproveRestructureListing = (params: any) => {
    dispatch(
      getApprovedRestructureListingThunk({
        ...params,
        filters: { status: RestructureTypeEnum.APPROVED },
      }),
    );
  };

  const fetchReferbackRestructureListing = (params: any) => {
    dispatch(
      getReferbackRestructureListingThunk({
        ...params,
        filters: { status: RestructureTypeEnum.REFERBACK },
      }),
    );
  };

  const items: TabsProps["items"] = [
    {
      key: RestructureTypeEnum.OPEN,
      label: (
        <span>
          Open{" "}
          {openRestructureListing?.data?.data != null && (
            <Tag>{openRestructureListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RestructureTable
          key="restructure-open"
          listingState={openRestructureListing}
          callback={fetchOpenRestructureListing}
          status={RestructureTypeEnum.OPEN}
        />
      ),
    },
    {
      key: RestructureTypeEnum.PENDING,
      label: (
        <span>
          Pending{" "}
          {pendingRestructureListing?.data?.data != null && (
            <Tag>{pendingRestructureListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RestructureTable
          key="restructure-pending"
          listingState={pendingRestructureListing}
          callback={fetchPendingRestructureListing}
          status={RestructureTypeEnum.PENDING}
        />
      ),
    },
    {
      key: RestructureTypeEnum.REFERBACK,
      label: (
        <span>
          Referback{" "}
          {referbackRestructureListing?.data?.data != null && (
            <Tag>{referbackRestructureListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RestructureTable
          key="restructure-pending"
          listingState={referbackRestructureListing}
          callback={fetchReferbackRestructureListing}
          status={RestructureTypeEnum.REFERBACK}
        />
      ),
    },
    {
      key: RestructureTypeEnum.VOID,
      label: (
        <span>
          Void{" "}
          {voidRestructureListing?.data?.data != null && (
            <Tag>{voidRestructureListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RestructureTable
          key="restructure-void"
          listingState={voidRestructureListing}
          callback={fetchVoidRestructureListing}
          status={RestructureTypeEnum.VOID}
        />
      ),
    },
    {
      key: RestructureTypeEnum.APPROVED,
      label: (
        <span>
          Approved{" "}
          {approvedRestructureListing?.data?.data != null && (
            <Tag>{approvedRestructureListing?.data?.total}</Tag>
          )}
        </span>
      ),
      children: (
        <RestructureTable
          key="restructure-approved"
          listingState={approvedRestructureListing}
          callback={fetchApproveRestructureListing}
          status={RestructureTypeEnum.APPROVED}
        />
      ),
    },
  ];

  const onTabChange = (key: string) => {
    if (key === RestructureTypeEnum.OPEN) {
      fetchOpenRestructureListing(initialParams);
    } else if (key === RestructureTypeEnum.PENDING) {
      fetchPendingRestructureListing(initialParams);
    } else if (key === RestructureTypeEnum.VOID) {
      fetchVoidRestructureListing(initialParams);
    } else if (key === RestructureTypeEnum.APPROVED) {
      fetchApproveRestructureListing(initialParams);
    } else if (key === RestructureTypeEnum.REFERBACK) {
      fetchReferbackRestructureListing(initialParams);
    }

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useEffect(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };
    fetchOpenRestructureListing(params);
    fetchPendingRestructureListing(params);
    fetchVoidRestructureListing(params);
    fetchApproveRestructureListing(params);
    fetchReferbackRestructureListing(params);
  }, []);

  return (
    <>
      <Helmet>
        <title>Restructure Listing - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Restructure" />
      <Tabs
        defaultActiveKey={activeTab ?? RestructureTypeEnum.OPEN}
        items={items}
        onChange={onTabChange}
      />
    </>
  );
};

export default RestructureListingPage;
