import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import loanInsuranceService from "./loanInsuranceService";

export const getLoanInsuranceBatchThunk = createAsyncThunk(
  "loanInsurance/getLoanInsuranceFirstBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await loanInsuranceService.getLoanInsuranceBatch1(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getLoanInsuranceBatch2Thunk = createAsyncThunk(
  "loanInsurance/getLoanInsuranceSecondBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await loanInsuranceService.getLoanInsuranceBatch2(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchLoanInsuranceBatchTrunk = createAsyncThunk(
  "loanInsurance/patchLoanInsuranceBatch",
  async (requestData: any, thunkAPI) => {
    try {
      return await loanInsuranceService.patchLoanInsuranceBatch(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getLoanInsuranceReportThunk = createAsyncThunk(
  "loanInsurance/getLoanInsuranceReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await loanInsuranceService.getLoanInsuranceReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const reUploadLoanInsuranceReportThunk = createAsyncThunk(
  "loanInsurance/uploadReport",
  async (requestData: any, thunkAPI) => {
    try {
      return await loanInsuranceService.uploadReport(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
