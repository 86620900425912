import "./index.less";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ApplicationStatusEnum } from "../../../enum/applicationStepStatus";
import { numberWithCommas } from "../../../helpers/number";
import { getApplicationDetailsThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDateFromString } from "../../../utils/datetime.util";
import { getNricInfo } from "../../../utils/nric.util";
import { trimPhoneNo } from "../../../utils/number.util";
import CustomerFormModal from "../../CustomerFormModal";
import InfoGrid from "../../InfoGrid";

dayjs.extend(customParseFormat, {
  parseTwoDigitYear: (yearString: string) =>
    +yearString + (+yearString > +dayjs().format("YY") ? 1900 : 2000),
});

const ApplicationInfoTab = () => {
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const readOnly = queryParams.get("readOnly") === "true";

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );

  const editable =
    applicationDetails?.data?.applicationStatus ===
      ApplicationStatusEnum.PENDING ||
    applicationDetails?.data?.applicationStatus ===
      ApplicationStatusEnum.REFERBACK ||
    applicationDetails?.data?.applicationStatus ===
      ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT ||
    applicationDetails?.data?.applicationStatus ===
      ApplicationStatusEnum.FOLLOWED_UP;

  const customerInfo = [
    {
      title: "Full Name",
      value: applicationDetails?.data?.applicantFirstName,
    },
    {
      title: "Identity No.",
      value: applicationDetails?.data?.applicantNric,
    },
    {
      title: "Date Of Birth",
      value: applicationDetails?.data?.applicantNric
        ? getNricInfo(
            applicationDetails?.data?.applicantNric ?? "",
          ).birthDate.format("DD/MM/YYYY")
        : "-",
    },
    {
      title: "Age",
      value: applicationDetails?.data?.applicantNric
        ? getNricInfo(applicationDetails?.data?.applicantNric ?? "").age
        : "-",
    },
    {
      title: "Race",
      value: applicationDetails?.data?.applicantRace,
    },
    {
      title: "Phone No.",
      value: trimPhoneNo(applicationDetails?.data?.applicantPhoneNo),
    },
    {
      title: "Email Address",
      value: applicationDetails?.data?.applicantEmail,
    },
    {
      title: "Residential Address",
      value:
        applicationDetails?.data?.applicantAddress1 != null
          ? [
              applicationDetails?.data?.applicantAddress1 ??
                applicationDetails?.data?.user?.residentialAddress1,
              applicationDetails?.data?.applicantAddress2 ??
                applicationDetails?.data?.user?.residentialAddress2,
              applicationDetails?.data?.applicantAddress3 ??
                applicationDetails?.data?.user?.residentialAddress3,
            ]
              .filter((item) => item != null && item !== "")
              .join(", ")
          : "-",
    },
    {
      title: "Postcode",
      value: applicationDetails?.data?.applicantPostcode,
    },
    {
      title: "City",
      value: applicationDetails?.data?.applicantCity,
    },
    {
      title: "State",
      value: applicationDetails?.data?.applicantState,
    },
    {
      title: "Partner ID No",
      value: applicationDetails?.data?.applicantPartnerId,
    },
    {
      title: "IC Type",
      value: applicationDetails.data?.user?.icType ?? "-",
    },
  ];

  const employmentInfo = [
    {
      title: "Employment Type",
      value: applicationDetails?.data?.applicantEmploymentType,
    },
    {
      title: "Monthly Net Salary",
      value:
        applicationDetails?.data?.actualMonthlySalary != null
          ? `RM ${numberWithCommas(
              applicationDetails?.data?.actualMonthlySalary,
            )}`
          : "-",
    },
    {
      title: "Job Start Date",
      value: applicationDetails?.data?.employmentStartingFrom
        ? displayDateFromString(
            applicationDetails?.data?.employmentStartingFrom,
          )
        : "-",
    },
    {
      title: "Employer’s Name",
      value: applicationDetails?.data?.employerName,
    },
    {
      title: "Employer’s Contact No.",
      value:
        applicationDetails?.data?.employerContactNo != null
          ? applicationDetails?.data?.employerContactNo
          : "-",
    },
    {
      title: "Job Title",
      value: applicationDetails?.data?.jobTitle,
    },
    {
      title: "Bank Name",
      value: applicationDetails?.data?.applicantBankName,
    },
    {
      title: "Bank Account No.",
      value: applicationDetails?.data?.applicantPersonalBankNo,
    },
  ];

  const emergencyInfo = [
    {
      title: "First Emergency Contact Persons Name",
      value: applicationDetails?.data?.emergencyContactName,
    },
    {
      title: "First Relationship",
      value: applicationDetails?.data?.applicantEmergencyContactRelationship,
    },
    {
      title: "First Emergency Contact No.",
      value:
        applicationDetails?.data?.emergencyContactNo != null
          ? applicationDetails?.data?.emergencyContactNo
          : "-",
    },
    {
      title: "Second Emergency Contact Persons Name",
      value: applicationDetails?.data?.secondEmergencyContactName,
    },
    {
      title: "Second Relationship",
      value:
        applicationDetails?.data?.applicantSecondEmergencyContactRelationship,
    },

    {
      title: "Second Emergency Contact No.",
      value:
        applicationDetails?.data?.secondEmergencyContactNo != null
          ? applicationDetails?.data?.secondEmergencyContactNo
          : "-",
    },
  ];

  async function initProcess() {
    dispatch(
      getApplicationDetailsThunk({
        applicationId: applicationDetails?.data?.id,
      }),
    );
  }

  return (
    <div className="info-container">
      <InfoGrid
        title="Customer Information"
        data={customerInfo}
        extra={
          !readOnly &&
          editable && (
            <CustomerFormModal
              key={applicationDetails?.data?.id}
              applicationDetails={applicationDetails?.data}
              callback={initProcess}
              from="cep"
            />
          )
        }
      />
      <br />
      <br />
      <InfoGrid title="Employment Information" data={employmentInfo} />
      <br />
      <br />
      <InfoGrid title="Emergency Information" data={emergencyInfo} />
    </div>
  );
};

export default ApplicationInfoTab;
