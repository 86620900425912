import { Button, Input, Select, Table, TablePaginationConfig } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";

import { setGlobalSearchValue } from "../../features/application/applicationSlice";
import { numberWithCommas } from "../../helpers/number";
import { globalSearchThunk } from "../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../store/store";

import type { ColumnsType } from "antd/es/table";
import { AgreementStatusEnum } from "../../enum/agreementStepStatus";
import { showFinalStatus } from "../../utils/string.util";
import { onCalculateNetDisbursementAmount } from "../../utils/calculation.util";

const { Search } = Input;

const GlobalSearchPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");
  const [searchFiled, setSearchFiled] = useState("applicationNo");

  const { globalSearchApplicationList, globalSearchValue } = useSelector(
    (state: RootState) => state.application,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
    }),
    [pageNum, pageSize],
  );

  const [searchType, setSearchType] = useState(
    globalSearchValue?.searchType ?? "applicationNo",
  );

  const resetSearchType = (value: string) => {
    setSearchType(value);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };
  const [searchValue, setSearchValue] = useState(
    globalSearchValue?.value ?? "",
  );
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: globalSearchApplicationList?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Application No.",
      dataIndex: "applicationNo",
      key: "applicationNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            const queryParams = new URLSearchParams();
            queryParams.set("readOnly", "true");
            const newSearch = queryParams.toString();
            navigate({
              pathname: `/application/details/${record?.id}`,
              search: newSearch,
            });
          }}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Agreement No",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            const queryParams = new URLSearchParams();
            queryParams.set("readOnly", "true");
            const newSearch = queryParams.toString();
            navigate({
              pathname: `/agreement/details/${record?.agreement?.id}`,
              search: newSearch,
            });
          }}
        >
          <span style={{ userSelect: "text" }}>
            {record?.agreement?.agreementNo}
          </span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "applicantFirstName",
      key: "applicantFirstName",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "applicantNric",
      key: "applicantNric",
    },
    // {
    //   title: "Application Status",
    //   render: (text, record) => {
    //     return startCase(record?.applicationStatus)
    //   }
    // },
    // {
    //   title: "Agreement Status",
    //   render: (text, record) => {
    //     return startCase(record?.agreement?.agreementStatus)
    //   }
    // },
    {
      title: "Status",
      render: (_, record) => {
        return showFinalStatus(
          record?.applicationStatus,
          record?.agreement?.agreementStatus,
        );
      },
    },
    {
      title: "E-Stamp",
      dataIndex: "eStamp",
      key: "eStamp",
      render: (_, record) => {
        if (
          record?.agreement?.agreementStatus ===
            AgreementStatusEnum.PENDING_DISBURSEMENT ||
          record?.agreement?.agreementStatus === AgreementStatusEnum.DISBURSED
        ) {
          if (record?.agreement?.eStampingCertificateFileId === null) {
            return "Pending";
          }
          if (record?.agreement?.clickedDownloadEStampingCert === true) {
            if (record?.agreement?.approveUploadedEStampingCert === true) {
              return "Approved";
            }
            return "Uploaded";
          }
        } else {
          return "-";
        }
      },
    },
    {
      title: "Applied Loan Amount",
      dataIndex: "loanApplied",
      key: "loanApplied",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Approved Loan Amount",
      dataIndex: "financeAmount",
      key: "financeAmount",
      render: (text, record) =>
        record.applicationStatus === "APPROVED"
          ? text != null
            ? `RM ${numberWithCommas(text)}`
            : "-"
          : "-",
    },
  ];

  const globalSearch = (requestData?: any) => {
    dispatch(globalSearchThunk(requestData));
  };

  const saveGlobalSearchValue = async (payload: any) => {
    await dispatch(setGlobalSearchValue(payload));
  };

  const onSearch = async (value: string) => {
    if (value) {
      const payload = { searchType, value };
      setSearchValue(value);
      globalSearch({ params: initialParams, payload });
      saveGlobalSearchValue(payload);

      queryParams.set("searchApplicantNRIC", value);
      const newSearch = queryParams.toString();
      navigate({
        pathname: location.pathname,
        search: newSearch,
      });
    }
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    globalSearch({
      params: { ...initialParams, pagination },
      payload: { searchType, value: searchValue },
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const initialCallbackState = { globalSearch };
  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    if (searchValue) {
      callbackState.globalSearch({
        params: initialParams,
        payload: { searchType, value: searchValue },
      });
    }
  }, [callbackState, initialParams, searchType, searchValue]);

  return (
    <>
      <Helmet>
        <title>Global Search - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Global Search" />
      {/* <Input.Group compact> */}
      {/*  <Select
          defaultValue={searchType}
          style={{ width: 200 }}
          onChange={(value) => setSearchType(value)}
          options={[{ value: "CUSTOMER_NRIC", label: "Applicant NRIC" }]}
        /> */}
      <Input.Group compact>
        <Select
          defaultValue={searchFiled}
          style={{ width: 200 }}
          onChange={(value) => resetSearchType(value)}
          options={[
            { value: "applicationNo", label: "Application No" },
            { value: "agreementNo", label: "Agreement No" },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="Input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>

      {/* </Input.Group> */}
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={globalSearchApplicationList?.data?.data}
        loading={globalSearchApplicationList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default GlobalSearchPage;
