import { authSetupAxiosInstance } from "../../axios/authSetupAxios";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const getAllFieldVisitCompany = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };
  const url = `/redCash/admin/auth/api/v1.0/field-visit/company`;
  const res = await authSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getFieldVisitCompany = async (requestData: any) => {
  const url = `/redCash/admin/auth/api/v1.0/field-visit/company/id/${requestData.id}`;
  const res = await authSetupAxiosInstance.get(url);
  return res.data;
};

const getAllFieldVisitOfficer = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/admin/auth/api/v1.0/field-visit/officer/fieldVisitCompany/${requestData.id}`;
  const res = await authSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getFieldVisitOfficerByPostCode = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/admin/auth/api/v1.0/field-visit/officer/fieldVisitCompanyId/${requestData.fieldVisitCompanyId}/postCode/${requestData.postCode}`;
  const res = await authSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getOpenFieldVisits = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/field-visit/list`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getPendingFieldVisits = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/field-visit/list`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getCloseFieldVisits = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/field-visit/list`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getVisitedFieldVisits = async (requestData: any) => {
  console.log(requestData);
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/field-visit/list`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getFieldVisitHistories = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/field-visit/accountDetailsId/${requestData.accountDetailsId}`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getFieldVisitById = async (requestData: any) => {
  const url = `/redCash/api/v1.0/field-visit/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const createFieldVisit = async (requestData: any) => {
  const url = `/redCash/api/v1.0/field-visit`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updateFieldVisit = async (requestData: any) => {
  const { id, payload } = requestData;
  const url = `/redCash/api/v1.0/field-visit/fieldVisitId/${id}/status`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateFieldVisitDetails = async (requestData: any) => {
  const { id, agreementNo, payload } = requestData;
  const url = `/redCash/api/v1.0/field-visit/fieldVisitId/${id}/agreementNo/${agreementNo}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const createFieldVisitCompany = async (requestData: any) => {
  const url = `/redCash/admin/auth/api/v1.0/field-visit/company`;
  const res = await authSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const createFieldVisitOfficer = async (requestData: any) => {
  const url = `/redCash/admin/auth/api/v1.0/field-visit/officer`;
  const res = await authSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updateFieldVisitCompany = async (requestData: any) => {
  const { id, payload } = requestData;
  const url = `/redCash/admin/auth/api/v1.0/field-visit/company/${id}`;
  const res = await authSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateFieldVisitOfficer = async (requestData: any) => {
  const { id, payload } = requestData;
  const url = `/redCash/admin/auth/api/v1.0/field-visit/officer/${id}`;
  const res = await authSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const checkAccountDetailsForFieldVisit = async (requestData: any) => {
  const url = `/redCash/api/v1.0/field-visit/checkAccountDetails/accountDetailsId/${requestData.accountDetailsId}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getFieldVisitCompanyByState = async (requestData: any) => {
  const url = `/redCash/admin/auth/api/v1.0/field-visit/company/state/${requestData.state}`;
  const res = await authSetupAxiosInstance.get(url);
  return res.data;
};

const fieldVisitService = {
  getAllFieldVisitCompany,
  getAllFieldVisitOfficer,
  getFieldVisitOfficerByPostCode,
  getFieldVisitCompany,
  createFieldVisit,
  updateFieldVisit,
  createFieldVisitCompany,
  createFieldVisitOfficer,
  updateFieldVisitCompany,
  updateFieldVisitOfficer,
  getOpenFieldVisits,
  getVisitedFieldVisits,
  getFieldVisitHistories,
  getFieldVisitById,
  updateFieldVisitDetails,
  getDraftFieldVisits: getPendingFieldVisits,
  getCloseFieldVisits,
  checkAccountDetailsForFieldVisit,
  getFieldVisitCompanyByState,
};

export default fieldVisitService;
