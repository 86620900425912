import { Button, Input, Select, Table, TablePaginationConfig } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../store/store";

import type { ColumnsType } from "antd/es/table";
import {
  getCompletedVideoAttestationSmeAgreementListingThunk,
} from "../../../../services/smeAgreementService/smeAgreementThunk";
import { SmeAgreement } from "../../../../features/smeAgreement/smeAgreementSlice";

const { Option } = Select;
const { Search } = Input;

const SmeVideoAttestationPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { completedVideoAttestationList } = useSelector(
    (state: RootState) => state.smeAgreement,
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };

  const tablePagination = {
    total: completedVideoAttestationList?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<SmeAgreement> = [
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/sme/agreement/details/${record?.id}`)}
        >
          <span style={{ userSelect: "text" }}>
            {text}
          </span>
        </Button>
      ),
    },
    {
      title: "Name",
      render: (_, record) =>
        record?.smeApplication?.smeIndividualApplication?.name ??
        record?.smeApplication?.smeSolePropApplication?.name ??
        record?.smeApplication?.smePartnershipApplication?.name ??
        record?.smeApplication?.smeCompanyApplication?.companyName ??
        "-",
    },
  ];

  const fetchAttestations = async (params?: any) => {
    await dispatch(getCompletedVideoAttestationSmeAgreementListingThunk({ params }));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchAttestations({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      fetchAttestations({
        ...initialParams,
        // filters: filterState,
      });
      setSearchValue("");
    }

    setFilterState({
      ...initialParams.filters,
    });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    const param =
      searchValue.length === 0
        ? {
          ...initialParams,
          ...filterState,
          pagination,
          sortOrder: "desc",
          sortField: "createdAt",
        }
        : {
          ...initialParams,
          ...filterState,
          pagination,
          sortOrder: "desc",
          sortField: "createdAt",
          search: {
            [searchFiled]: searchValue,
          },
        };

    fetchAttestations(param);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>Video Attestation - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "agreementNo", label: "Agreement No." },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={completedVideoAttestationList?.data?.data ?? []}
        loading={completedVideoAttestationList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default SmeVideoAttestationPage;
