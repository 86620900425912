import { createSlice } from "@reduxjs/toolkit";
import {
  getAllAocThunk,
  getAocBatchByIdThunk,
  getAocBatchThunk,
  getApprovedAocThunk,
  getPendingAocThunk,
  getReferbackAocThunk,
  getOpenAocThunk,
  getVoidAocThunk,
} from "../../services/aocService/aocThunk";

export type AddOnCharges = {
  accountDetailsId: string;
  addOnChargesBatchId: string;
  amount: number;
  createdAt: string;
  id: string;
  type: string;
  updatedAt: string;
  name: string;
  tax: number;
  amountAfterTax: number;
};

export type BatchNo = {
  id: string;
  batchNo: string;
  status: string;
  totalNoOfAccount: number;
  totalAocTax: number;
  createdAt?: string;
  updatedAt?: string;
  createdById: string;
  decider: string;
  decidedAt?: string;
  verifier: string;
  remarks: string;
  totalAocAmountBeforeTax: number;
  addOnChargesAgreement: [];
  addOnCharges: AddOnCharges[];
  aocBatchRemarks: [];
};

export type BatchNoResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: BatchNo[];
};

export type AocState = {
  batchNo: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: BatchNoResponse;
  };
  allAocList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: BatchNo[];
      searchTotal: number;
    };
  };
  openAocList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: BatchNo[];
      searchTotal: number;
    };
  };
  pendingAocList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: BatchNo[];
      searchTotal: number;
    };
  };
  referbackAocList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: BatchNo[];
      searchTotal: number;
    };
  };
  voidAocList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: BatchNo[];
      searchTotal: number;
    };
  };
  approvedAocList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: BatchNo[];
      searchTotal: number;
    };
  };
  aocBatchById: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: BatchNo;
  };
};

const initialState: AocState = {
  batchNo: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  allAocList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
  openAocList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
  pendingAocList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
  referbackAocList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
  voidAocList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
  approvedAocList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
  aocBatchById: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
};

export const aocSlice = createSlice({
  name: "aoc",
  initialState,
  reducers: {
    AocStateReset: () => initialState,
    AocBatchNoStateReset: (state) => {
      state.batchNo = initialState.batchNo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAocBatchThunk.pending, (state) => {
        state.batchNo.isLoading = true;
      })
      .addCase(getAocBatchThunk.fulfilled, (state, action) => {
        state.batchNo.isLoading = false;
        state.batchNo.data = action.payload;
      })
      .addCase(getAocBatchThunk.rejected, (state, action) => {
        state.batchNo.isLoading = false;
        state.batchNo.isError = true;
        state.batchNo.errorMessage = action.payload;
      });

    builder
      .addCase(getAllAocThunk.pending, (state) => {
        state.allAocList.isLoading = true;
      })
      .addCase(getAllAocThunk.fulfilled, (state, action) => {
        state.allAocList.isLoading = false;
        state.allAocList.data = action.payload;
      })
      .addCase(getAllAocThunk.rejected, (state, action) => {
        state.allAocList.isLoading = false;
        state.allAocList.isError = true;
        state.allAocList.errorMessage = action.payload;
      });

    builder
      .addCase(getOpenAocThunk.pending, (state) => {
        state.openAocList.isLoading = true;
      })
      .addCase(getOpenAocThunk.fulfilled, (state, action) => {
        state.openAocList.isLoading = false;
        state.openAocList.data = action.payload;
      })
      .addCase(getOpenAocThunk.rejected, (state, action) => {
        state.openAocList.isLoading = false;
        state.openAocList.isError = true;
        state.openAocList.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingAocThunk.pending, (state) => {
        state.pendingAocList.isLoading = true;
      })
      .addCase(getPendingAocThunk.fulfilled, (state, action) => {
        state.pendingAocList.isLoading = false;
        state.pendingAocList.data = action.payload;
      })
      .addCase(getPendingAocThunk.rejected, (state, action) => {
        state.pendingAocList.isLoading = false;
        state.pendingAocList.isError = true;
        state.pendingAocList.errorMessage = action.payload;
      });

    builder
      .addCase(getReferbackAocThunk.pending, (state) => {
        state.referbackAocList.isLoading = true;
      })
      .addCase(getReferbackAocThunk.fulfilled, (state, action) => {
        state.referbackAocList.isLoading = false;
        state.referbackAocList.data = action.payload;
      })
      .addCase(getReferbackAocThunk.rejected, (state, action) => {
        state.referbackAocList.isLoading = false;
        state.referbackAocList.isError = true;
        state.referbackAocList.errorMessage = action.payload;
      });

    builder
      .addCase(getVoidAocThunk.pending, (state) => {
        state.voidAocList.isLoading = true;
      })
      .addCase(getVoidAocThunk.fulfilled, (state, action) => {
        state.voidAocList.isLoading = false;
        state.voidAocList.data = action.payload;
      })
      .addCase(getVoidAocThunk.rejected, (state, action) => {
        state.voidAocList.isLoading = false;
        state.voidAocList.isError = true;
        state.voidAocList.errorMessage = action.payload;
      });

    builder
      .addCase(getApprovedAocThunk.pending, (state) => {
        state.approvedAocList.isLoading = true;
      })
      .addCase(getApprovedAocThunk.fulfilled, (state, action) => {
        state.approvedAocList.isLoading = false;
        state.approvedAocList.data = action.payload;
      })
      .addCase(getApprovedAocThunk.rejected, (state, action) => {
        state.approvedAocList.isLoading = false;
        state.approvedAocList.isError = true;
        state.approvedAocList.errorMessage = action.payload;
      });

    builder
      .addCase(getAocBatchByIdThunk.pending, (state) => {
        state.aocBatchById.isLoading = true;
      })
      .addCase(getAocBatchByIdThunk.fulfilled, (state, action) => {
        state.aocBatchById.isLoading = false;
        state.aocBatchById.data = action.payload;
      })
      .addCase(getAocBatchByIdThunk.rejected, (state, action) => {
        state.aocBatchById.isLoading = false;
        state.aocBatchById.isError = true;
        state.aocBatchById.errorMessage = action.payload;
      });
  },
});

export const { AocStateReset, AocBatchNoStateReset } = aocSlice.actions;

export default aocSlice.reducer;
