import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import {
  createUnmatchedRefund,
  downloadUnmatchedRefundPaymentFile,
  getUnmatchedRefundFinanceList,
  getUnmatchedRefundList,
  updateUnmatchedRefundDetails,
  updateUnmatchedRefundFinanceStatus,
  updateUnmatchedRefundStatus,
} from "./unmatchedService";

export const getOpenUnmatchedRefundListThunk = createAsyncThunk(
  "unmatched/getOpen",
  async (requestData: any, thunkAPI) => {
    try {
      return await getUnmatchedRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPendingUnmatchedRefundListThunk = createAsyncThunk(
  "unmatched/getPending",
  async (requestData: any, thunkAPI) => {
    try {
      return await getUnmatchedRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getReferbackUnmatchedRefundListThunk = createAsyncThunk(
  "unmatched/getReferback",
  async (requestData: any, thunkAPI) => {
    try {
      return await getUnmatchedRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVoidUnmatchedRefundListThunk = createAsyncThunk(
  "unmatched/getVoid",
  async (requestData: any, thunkAPI) => {
    try {
      return await getUnmatchedRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApprovedUnmatchedRefundListThunk = createAsyncThunk(
  "unmatched/getApproved",
  async (requestData: any, thunkAPI) => {
    try {
      return await getUnmatchedRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getUnmatchedRefundedRefundThunk = createAsyncThunk(
  "unmatched/getUnmatchedRefunded",
  async (requestData: any, thunkAPI) => {
    try {
      return await getUnmatchedRefundList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createUnmatchedRefundThunk = createAsyncThunk(
  "unmatched/createUnmatchedRefund",
  async (requestData: any, thunkAPI) => {
    try {
      return await createUnmatchedRefund(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateUnmatchedRefundDetailsThunk = createAsyncThunk(
  "unmatched/updateUnmatchedRefundDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateUnmatchedRefundDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateUnmatchedRefundStatusThunk = createAsyncThunk(
  "unmatched/updateUnmatchedRefundStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateUnmatchedRefundStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getUnmatchedRefundFinanceListThunk = createAsyncThunk(
  "unmatched/getUnmatchedRefundFinanceList",
  async (requestData: any, thunkAPI) => {
    try {
      return await getUnmatchedRefundFinanceList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateUnmatchedRefundFinanceStatusThunk = createAsyncThunk(
  "unmatched/updateUnmatchedRefundFinanceStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await updateUnmatchedRefundFinanceStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const downloadUnmatchedRefundPaymentFileThunk = createAsyncThunk(
  "unmatched/downloadUnmatchedRefundPaymentFile",
  async (requestData: any, thunkAPI) => {
    try {
      return await downloadUnmatchedRefundPaymentFile(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
