import { Tabs, TabsProps, Tag } from "antd";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";

import { CollectionActivityStatusEnum } from "../../enum/applicationStepStatus";
import { getCollectionActivitiesFixTotalThunk } from "../../services/collectionService/collectionThunk";
import { AppDispatch, RootState } from "../../store/store";
import MIA1Listing from "./1MIAListing";
import MIA2Listing from "./2MIAListing";
import MIA3Listing from "./3MIAListing";
import BPListing from "./BPListing";
import NPLListing from "./NPLListing";
import PTPListing from "./PTPListing";

const CollectionListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { collectionActivitiesFixTotal } = useSelector(
    (state: RootState) => state.collection,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? pageNum : 1,
        pageSize: pageSize ? pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const items: TabsProps["items"] = [
    {
      key: "MIA1",
      label: (
        <span>
          1 MIA <Tag>{collectionActivitiesFixTotal.data.mia1}</Tag>
        </span>
      ),
      children: <MIA1Listing />,
    },
    {
      key: "MIA2",
      label: (
        <span>
          2 MIA <Tag>{collectionActivitiesFixTotal.data.mia2}</Tag>
        </span>
      ),
      children: <MIA2Listing />,
    },
    {
      key: "MIA3",
      label: (
        <span>
          3 MIA <Tag>{collectionActivitiesFixTotal.data.mia3}</Tag>
        </span>
      ),
      children: <MIA3Listing />,
    },
    {
      key: "NPL",
      label: (
        <span>
          NPL <Tag>{collectionActivitiesFixTotal.data.npl}</Tag>
        </span>
      ),
      children: <NPLListing />,
    },
    {
      key: "BP",
      label: (
        <span>
          BP <Tag>{collectionActivitiesFixTotal.data.bp}</Tag>
        </span>
      ),
      children: <BPListing />,
    },
    {
      key: "PTP",
      label: (
        <span>
          PTP <Tag>{collectionActivitiesFixTotal.data.ptp}</Tag>
        </span>
      ),
      children: <PTPListing />,
    },
  ];

  const onFetchCollectionActivitiesFixTotal = () => {
    dispatch(getCollectionActivitiesFixTotalThunk());
  };

  useEffect(() => {
    onFetchCollectionActivitiesFixTotal();
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Collection Listing - redCASH CEP`}</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Collection Listing" />
      <Tabs defaultActiveKey={activeTab ?? "1mia"} items={items} />
    </>
  );
};

export default CollectionListingPage;
