export enum AgreementStepStatusEnum {
  LOANAPPLICATIONAPPROVED = "LOANAPPLICATIONAPPROVED",
  ATTESTATIONOPTION = "ATTESTATIONOPTION",
  ATTESTATIONCOMPLETED = "ATTESTATIONCOMPLETED",
  AGREEMENTSIGNING = "AGREEMENTSIGNING",
  REPAYMENTOPTION = "REPAYMENTOPTION",
}

export enum AgreementStatusEnum {
  PENDING = "PENDING",
  DONE_VIDEO_ATTESTATION = "DONE_VIDEO_ATTESTATION",
  DONE_LIVE_ATTESTATION = "DONE_LIVE_ATTESTATION",
  REQUESTED_FOR_LIVE_ATTESTATION = "REQUESTED_FOR_LIVE_ATTESTATION",
  PENDING_SIGNING = "PENDING_SIGNING",
  PENDING_DISBURSEMENT = "PENDING_DISBURSEMENT",

  LOANAPPLICATIONAPPROVED = "LOANAPPLICATIONAPPROVED",
  ATTESTATIONOPTION = "ATTESTATIONOPTION",
  ATTESTATIONCOMPLETED = "ATTESTATIONCOMPLETED",
  AGREEMENTSIGNING = "AGREEMENTSIGNING",
  DISBURSED = "DISBURSED",

  PENDING_FOR_ATTESTATION = "PENDING_FOR_ATTESTATION",
  DIRECT_DEBIT_ENROLLMENT = "DIRECT_DEBIT_ENROLLMENT",
}

export enum SmeAgreementStatusEnum {
  PENDING_ATTESTATION = "PENDING_ATTESTATION",
  PENDING_SIGNING = "PENDING_SIGNING",
  REQUESTED_FOR_LIVE_ATTESTATION = "REQUESTED_FOR_LIVE_ATTESTATION",
  PENDING_DISBURSEMENT = "PENDING_DISBURSEMENT",
  DISBURSED = "DISBURSED",
}
