import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import manualPaymentService from "./manualPaymentService";

export const getManualPaymentThunk = createAsyncThunk(
  "manualPayment/getManualPayment",
  async (requestData: any, thunkAPI) => {
    try {
      return await manualPaymentService.getManualPayment(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
