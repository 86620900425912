import "./style.less";

import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Result,
  Select,
  Skeleton,
  Space,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  CheckCircleOutlined,
  CloseSquareOutlined,
  PrinterOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import InfoList from "../../../../components/InfoList";
import UploadedDocument from "../../../../components/UploadedDocument";
import {
  fileDeleteByTypeThunk,
  getFileByEntityIdByTypeThunk,
} from "../../../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";
import { numberWithCommas } from "../../../../helpers/number";
import {
  getRescheduleCaseThunk,
  getRescheduleQuotationThunk,
  updateRescheduleCaseStatusThunk,
  updateRescheduleCaseThunk,
} from "../../../../services/rescheduleService/rescheduleThunk";
import RescheduleDateModal from "./reschedule-date-modal";
import dayjs from "dayjs";
import { RescheduleTypeEnum } from "../../../../enum/reschedule";
import { FileTypeEnum } from "../../../../enum/fileType";
import UploadedDocumentByType from "../../../../components/UploadedDocumentByType";
import productService from "../../../../services/productService/productService";
import { saveBlobAsPDF } from "../../../../utils/file";

const { TextArea } = Input;

const RescheduleDetailsContent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const { rescheduleId } = useParams();

  const queryParams = new URLSearchParams(location.search);
  const readOnly = queryParams.get("readOnly") === "true";

  const { rescheduleDetails } = useSelector(
    (state: RootState) => state.reschedule,
  );
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const accountDetails = accountEnquiry?.data?.accountDetails;
  const viewMode =
    rescheduleDetails?.data?.status !== RescheduleTypeEnum.OPEN &&
    rescheduleDetails?.data?.status !== RescheduleTypeEnum.REFERBACK;

  const [rescheduleDateVisible, setRescheduleDateVisible] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [tenureRange, setTenureRage] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voidRemark, setVoidRemark] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [remark, setRemark] = useState("");

  const updateRescheduleDetails = async (rescheduleDate?: any) => {
    console.log(rescheduleDate);
    // triggered when new instalment period is inserted or reschedule date is edited
    // to run the calculation
    const instalmentPeriod = form.getFieldValue("instalmentPeriod");
    if (!instalmentPeriod) return;

    const payload: any = {
      id: rescheduleId,
      newInstalmentPeriod: instalmentPeriod,
      rescheduleDate: rescheduleDate
        ? dayjs(rescheduleDate).startOf("day")
        : rescheduleDetails?.data?.rescheduleDate,
      rescheduleFee: Number(form.getFieldValue("rescheduleFee")),
      remark: form.getFieldValue("remark"),
    };
    await dispatch(updateRescheduleCaseThunk(payload))
      .unwrap()
      .then(async () => {
        await dispatch(getRescheduleCaseThunk({ id: rescheduleId }));
      });
  };

  const saveRescheduleDetails = () => {
    form.validateFields().then((values) => {
      const payload: any = {
        id: rescheduleId,
        newInstalmentPeriod: values.instalmentPeriod,
        rescheduleDate: rescheduleDetails?.data?.rescheduleDate,
        rescheduleFee: Number(values.rescheduleFee),
        remark: values.remark,
      };

      dispatch(updateRescheduleCaseThunk(payload))
        .unwrap()
        .then(async () => {
          message.success("Reschedule details saved successfully");
          dispatch(getRescheduleCaseThunk({ id: rescheduleId }))
            .unwrap()
            .then((res) => {});
        });
    });
  };

  const onUpdateStatus = (status: RescheduleTypeEnum) => {
    if (remark.length == 0) {
      message.error("Please provide remark.");
      return;
    } else if (status === RescheduleTypeEnum.PENDING && fileList.length == 0) {
      message.error("Please upload the required documents");
      return;
    } else {
      dispatch(
        updateRescheduleCaseStatusThunk({
          id: rescheduleId,
          rescheduleStatus: status,
          type: "Reschedule",
          remark,
        }),
      )
        .unwrap()
        .then(() => {
          message.success(
            `Reschedule status updated to ${status} successfully`,
          );
          navigate(`/reschedule/listing?tab=${status}`);
        });
    }
  };

  const rescheduleDetailsInfo = [
    {
      title: "Reschedule Date",
      value: (
        <Space>
          {rescheduleDetails?.data?.rescheduleDate != null
            ? `${displayDate(rescheduleDetails?.data?.rescheduleDate)}`
            : "-"}
          <Button
            disabled={
              rescheduleDetails.data?.status !== RescheduleTypeEnum.REFERBACK
            }
            onClick={() => setRescheduleDateVisible(true)}
          >
            Update
          </Button>

          {rescheduleDateVisible && (
            <RescheduleDateModal
              isModalVisible={rescheduleDateVisible}
              setIsModalVisible={setRescheduleDateVisible}
              callback={(rescheduleDate: any) => {
                // setRescheduleDate(rescheduleDate);
                updateRescheduleDetails(rescheduleDate);
                setRescheduleDateVisible(false);
              }}
            />
          )}
        </Space>
      ),
    },
    {
      title: "Reschedule (Finance) Amount",
      value:
        accountDetails?.totalLoanOutstanding != null
          ? `RM ${numberWithCommas(accountDetails?.totalLoanOutstanding)}`
          : "-",
    },
    {
      title: "Instalment Period",
      value: viewMode ? (
        rescheduleDetails?.data?.instalmentPeriod != null ? (
          `${rescheduleDetails?.data?.instalmentPeriod}`
        ) : (
          "-"
        )
      ) : (
        <Form.Item name="instalmentPeriod" required style={{ marginBottom: 0 }}>
          <Select
            style={{ width: 150 }}
            placeholder="Select"
            disabled={viewMode}
            onChange={() => {
              updateRescheduleDetails();
            }}
          >
            {tenureRange.map((item: any) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Interest/Profit Rate",
      value:
        rescheduleDetails?.data?.profitRate != null
          ? `${rescheduleDetails?.data?.profitRate} %`
          : "-",
    },
    {
      title: "Total Finance Payable Amount",
      value:
        rescheduleDetails?.data?.totalFinancePayableAmount != null
          ? `RM ${numberWithCommas(
              rescheduleDetails?.data?.totalFinancePayableAmount,
            )}`
          : "-",
    },
    {
      title: "Monthly Instalment Amount",
      value:
        rescheduleDetails?.data?.monthlyInstalmentAmount != null
          ? `RM ${numberWithCommas(
              rescheduleDetails?.data?.monthlyInstalmentAmount,
            )}`
          : "-",
    },
    {
      title: "Last Instalment Amount",
      value:
        rescheduleDetails?.data?.lastInstalmentAmount != null
          ? `RM ${numberWithCommas(
              rescheduleDetails?.data?.lastInstalmentAmount,
            )}`
          : "-",
    },
    {
      title: "Misc Charges (Reschedule Fees)",
      value: viewMode ? (
        rescheduleDetails?.data?.rescheduleFee != null ? (
          `RM ${numberWithCommas(rescheduleDetails?.data?.rescheduleFee)}`
        ) : (
          "-"
        )
      ) : (
        <Form.Item
          name="rescheduleFee"
          rules={[
            {
              required: true,
              message: "Please input reschedule fee",
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <InputNumber
            precision={2}
            defaultValue={0}
            controls={false}
            style={{ width: 150 }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Estimated 1st Due Date",
      value: rescheduleDetails?.data?.estimatedFirstDueDate
        ? displayDate(rescheduleDetails?.data?.estimatedFirstDueDate)
        : "-",
    },
  ];

  const fetchFileList = async () => {
    setFileLoading(true);
    await dispatch(
      getFileByEntityIdByTypeThunk({
        type: "rescheduleAndRestructure",
        entityId: rescheduleId,
      }),
    )
      .unwrap()
      .then((res) => {
        setFileList(res);
      })
      .finally(() => {
        setFileLoading(false);
      });
  };

  const onVoid = async () => {
    if (voidRemark.length == 0) {
      message.error("Please provide remark.");
      return;
    }

    dispatch(
      updateRescheduleCaseStatusThunk({
        id: rescheduleId,
        rescheduleStatus: RescheduleTypeEnum.VOID,
        type: "Reschedule",
        remark: voidRemark,
      }),
    )
      .unwrap()
      .then(() => {
        message.success(
          `Reschedule status updated to ${RescheduleTypeEnum.VOID} successfully`,
        );
        navigate(`/reschedule/listing?tab=${RescheduleTypeEnum.VOID}`);
      });
  };

  useEffect(() => {
    fetchFileList();
    setRemark(rescheduleDetails?.data?.remark ?? "");
  }, [rescheduleId]);

  useEffect(() => {
    productService
      .getProductById(
        accountEnquiry.data?.agreementDetails?.application?.productId ?? "",
      )
      .then((res) => {
        const minTenure = res.minTenure;
        const maxTenure = res.maxTenure;

        // Create an array start from min tenure and end with max tenure
        const tenureArray = Array.from(
          { length: maxTenure - minTenure + 1 },
          (_, i) => minTenure + i,
        );

        setTenureRage(tenureArray);
      });
  }, [accountEnquiry]);

  const printRescheduleQuotation = () => {
    setDownloading(true);
    dispatch(
      getRescheduleQuotationThunk({
        rescheduleId,
      }),
    )
      .unwrap()
      .then((response) => {
        const filename = `Reschedule_Quotation_${rescheduleDetails.data?.dataNo}.pdf`;
        saveBlobAsPDF(response, filename);
      })
      .finally(() => setDownloading(false));
  };

  return (
    <>
      {rescheduleId ? (
        <Form
          form={form}
          initialValues={{
            rescheduleDate: rescheduleDetails?.data?.rescheduleDate,
            rescheduleFee: rescheduleDetails?.data?.rescheduleFee,
            instalmentPeriod: rescheduleDetails?.data?.instalmentPeriod,
            remark: rescheduleDetails?.data?.remark,
          }}
        >
          <div className="reschedule-details-section-container">
            <InfoList data={rescheduleDetailsInfo} />
          </div>
          <div className="reschedule-details-section-container">
            <h3 className="reschedule-details-section-title">
              Customer Documents
            </h3>
            {fileLoading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : (
              <UploadedDocumentByType
                key={`uploaded-document-reschedule-${rescheduleDetails?.data?.id}`}
                type="rescheduleAndRestructure"
                entityId={rescheduleDetails?.data?.id}
                fileType={FileTypeEnum.RESCHEDULE_AND_RESTRUCTURE}
                defaultFileList={fileList}
                handleDeleteFile={async (payload: any) =>
                  await dispatch(
                    fileDeleteByTypeThunk({
                      ...payload,
                      type: "rescheduleAndRestructure",
                    }),
                  )
                    .unwrap()
                    .then(() => {
                      message.success("File deleted successfully");
                    })
                }
                callback={fetchFileList}
                disabled={viewMode}
              />
            )}
          </div>
          <div className="reschedule-details-section-container">
            <h3 className="reschedule-details-section-title">Remarks</h3>
            <Form.Item name="remark" style={{ marginBottom: 0 }}>
              <TextArea
                rows={4}
                placeholder="Remarks"
                disabled={viewMode}
                onChange={(e) => setRemark(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="flex gap-2 justify-end mt-2">
            {!(
              rescheduleDetails?.data?.status === RescheduleTypeEnum.VOID ||
              rescheduleDetails?.data?.status === RescheduleTypeEnum.APPROVED ||
              rescheduleDetails?.data?.status === RescheduleTypeEnum.REFERBACK
            ) && (
              <>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  icon={<CloseSquareOutlined />}
                >
                  Void
                </Button>
              </>
            )}
            {rescheduleDetails?.data?.status === RescheduleTypeEnum.OPEN && (
              <>
                <Button
                  icon={<SaveOutlined />}
                  onClick={() => saveRescheduleDetails()}
                >
                  Save
                </Button>
                <Button
                  type="primary"
                  onClick={() => onUpdateStatus(RescheduleTypeEnum.PENDING)}
                  icon={<CheckCircleOutlined />}
                >
                  Submit
                </Button>
              </>
            )}
            {rescheduleDetails?.data?.status ===
              RescheduleTypeEnum.REFERBACK && (
              <>
                <Button
                  type="primary"
                  onClick={() => onUpdateStatus(RescheduleTypeEnum.PENDING)}
                  icon={<CheckCircleOutlined />}
                >
                  Submit
                </Button>
              </>
            )}
            {rescheduleDetails?.data?.status === RescheduleTypeEnum.PENDING && (
              <Button
                type="primary"
                onClick={() => onUpdateStatus(RescheduleTypeEnum.REFERBACK)}
                icon={<CheckCircleOutlined />}
              >
                Referback
              </Button>
            )}
            {rescheduleDetails?.data?.status === RescheduleTypeEnum.PENDING && (
              <Button
                type="primary"
                onClick={() => onUpdateStatus(RescheduleTypeEnum.APPROVED)}
                icon={<CheckCircleOutlined />}
              >
                Approve
              </Button>
            )}

            <Button
              type="primary"
              onClick={() => printRescheduleQuotation()}
              icon={<CheckCircleOutlined />}
              loading={downloading}
            >
              Print Quotation
            </Button>
          </div>
        </Form>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, reschedule not found."
        />
      )}

      <Modal
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setVoidRemark("");
        }}
        footer={null}
        width={800}
        onOk={onVoid}
        destroyOnClose
      >
        <Form>
          <br />
          <Form.Item label="Remark">
            <Input onChange={(e) => setVoidRemark(e.target.value)} />
          </Form.Item>
        </Form>

        <Button type="primary" onClick={onVoid}>
          Void
        </Button>
      </Modal>
    </>
  );
};

export default RescheduleDetailsContent;
