import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const getAllWriteOffConfig = async (params: any) => {
  const result = await collectionSetupAxiosInstance.get(
    `/redCash/api/v1.0/writeOffConfig/listing`,
  );

  return result.data;
};

const updateWriteOffConfig = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.patch(
    `/redCash/api/v1.0/writeOffConfig/update/id/${requestData.id}`,
    requestData,
  );
  return result.data;
};

const createWriteOffConfig = async (requestData: any) => {
  const result = await collectionSetupAxiosInstance.post(
    `/redCash/api/v1.0/writeOffConfig/create`,
    requestData,
  );
  return result.data;
};

const writeOffConfigService = {
  getAllWriteOffConfig,
  updateWriteOffConfig,
  createWriteOffConfig,
};

export default writeOffConfigService;
