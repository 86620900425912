// Create a modal which is allow user to upload 1 file

import React, { useState } from "react";
import { Button, Modal, Upload, Form, message } from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { reUploadLoanInsuranceReportThunk } from "../../../services/loanInsuranceService/loanInsuranceThunk";
import { LoanInsuranceBatch } from "../../../features/loanInsurance/loanInsuranceSlice";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";

interface UploadReportModalProps {
  batch: LoanInsuranceBatch;
  callback?: () => void;
}

const UploadReportModal: React.FC<UploadReportModalProps> = ({
  batch,
  callback,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const handleOk = async (file: any) => {
    if (fileList.length < 1) {
      return message.error("No file selected");
    }
    setIsUploading(true);
    await form
      .validateFields()
      .then(async (values) => {
        message.loading("Uploading...");
        const formData = new FormData();
        formData.append("batchId", batch.id);
        formData.append("file", fileList[0] as RcFile);
        formData.append("uploadBy", localStorage.getItem("username") ?? "-");
        await dispatch(reUploadLoanInsuranceReportThunk(formData))
          .unwrap()
          .then(async (res) => {
            if (callback) callback();
            message.success("Submit Successfully");
          })
          .catch((error) => {
            message.error(error);
          })
          .finally(() => {
            setIsOpenModal(false);
            setIsUploading(false);
            setFileList([]);
          });
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const openModal = () => {
    setIsOpenModal(true);
    setFileList([]);
  };

  const uploadProps: UploadProps = {
    multiple: true,
    fileList,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: RcFile) => {
      fileList.push(file);
      // setFileList([...fileList, file]);
      return false;
    },
  };

  return (
    <>
      <Button onClick={openModal} loading={isUploading}>
        Upload Report
      </Button>
      <Modal
        title="Upload Report"
        open={isOpenModal}
        onOk={handleOk}
        onCancel={() => setIsOpenModal(false)}
        confirmLoading={isUploading}
      >
        <Form
          form={form}
          name="uploadWaiverFile"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item label="Waiver File">
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UploadReportModal;
