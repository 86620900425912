export const inProcessCaseOrder = [
  "Registration",
  "Application",
  "Email Verification",
  "Document Upload",
  "e-KYC",
];

export const totalCasesStatusMapDict: { [key: string]: string } = {
  PENDING: "inProgress",
  FOLLOWED_UP: "inProgress",
  SUBMITTED_FOR_APPROVAL: "inProgress",
  REFERBACK: "inProgress",
  REJECTED: "rejected",
  APPROVED: "approved",
  CANCELED: "canceled",
  DISBURSED: "disbursed",
};

export const totalCasesStatusMap = (status: string | number) => {
  return totalCasesStatusMapDict[status];
};

export const performanceStatusMapDict: { [key: string]: string } = {
  NEW_APPLICATION: "NEW APPLICATION",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
  DISBURSED: "DISBURSED",
};

export const dateTypeMapDict: { [key: string]: string } = {
  date: "DAYS",
  week: "WEEK",
  month: "MONTH",
  year: "YEAR",
};

export const inProgressStatusList = [
  "VERIFYING",
  "FOLLOWED_UP",
  "REFERBACK",
  "REFERBACK_FROM_DISBURSEMENT",
  "APPROVAL",
  "APPROVAL_FROM_PENDING_DISBURSEMENT",
  "PENDING_FOR_ATTESTATION",
  "ATTESTATION",
  "PENDING_FOR_ESIGN",
  "PENDING_FOR_ESTAMP",
  "PENDING_FOR_DISBURSEMENT",
];

export const dataPerformanceTableOrder = [
  "totalLoanAmount",
  "totalInterestAmount",
];
