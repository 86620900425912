import { createSlice } from "@reduxjs/toolkit";
import {
  getDunningContactModeListingThunk,
  getDunningHistoryListingThunk,
  saveDunningInfoThunk,
  updateDunningInfoThunk,
} from "../../services/dunningService/dunningThunk";
import { AccountDetails } from "../collection/collectionSlice";
import { ActionCode } from "../actionCode/actionCodeSlice";

export type DunningInfo = {
  id?: string;
  actionCodeId: string;
  accountDetailsId: string;
  contactNo: string;
  contactMode: string;
  ptpDate?: string;
  ptpAmount?: number;
  nextCallDate?: string;
  nextCallTime?: string;
  remark: string;
  createdAt?: string;
  updatedAt?: string;
  accountDetails?: AccountDetails;
  actionCode?: ActionCode;
};
export type DunningListing = {
  id?: string;
  actionCodeId: string;
  accountDetailsId: string;
  contactNo: string;
  contactMode: string;
  ptpDate?: string;
  ptpAmount?: number;
  nextCallDate?: string;
  nextCallTime?: string;
  remark: string;
  createdAt?: string;
  updatedAt?: string;
  accountDetails?: AccountDetails;
  actionCode?: ActionCode;
};
export type DunningContactModeListing = {
  id?: string;
  contactMode: string;
  createdAt?: string;
  updatedAt?: string;
};
export type DunningHistoryState = {
  dunningInfoDetailsState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: DunningInfo;
  };
  dunningHistoryListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: DunningListing[];
  };
  dunningContactModeListingState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: DunningContactModeListing[];
  };
};

const initialState: DunningHistoryState = {
  dunningInfoDetailsState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  dunningHistoryListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  dunningContactModeListingState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const dunningHistorySlice = createSlice({
  name: "dunningHistory",
  initialState,
  reducers: {
    dunningHistoryReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveDunningInfoThunk.pending, (state) => {
        state.dunningInfoDetailsState.isLoading = true;
      })
      .addCase(saveDunningInfoThunk.fulfilled, (state, action) => {
        state.dunningInfoDetailsState.isLoading = false;
        state.dunningInfoDetailsState.data = action.payload.data;
      })
      .addCase(saveDunningInfoThunk.rejected, (state, action) => {
        state.dunningInfoDetailsState.isLoading = false;
        state.dunningInfoDetailsState.isError = true;
        state.dunningInfoDetailsState.errorMessage = action.payload;
      });
    builder
      .addCase(updateDunningInfoThunk.pending, (state) => {
        state.dunningInfoDetailsState.isLoading = true;
      })
      .addCase(updateDunningInfoThunk.fulfilled, (state, action) => {
        state.dunningInfoDetailsState.isLoading = false;
        state.dunningInfoDetailsState.data = action.payload.data;
      })
      .addCase(updateDunningInfoThunk.rejected, (state, action) => {
        state.dunningInfoDetailsState.isLoading = false;
        state.dunningInfoDetailsState.isError = true;
        state.dunningInfoDetailsState.errorMessage = action.payload;
      });

    builder
      .addCase(getDunningHistoryListingThunk.pending, (state) => {
        state.dunningHistoryListingState.isLoading = true;
      })
      .addCase(getDunningHistoryListingThunk.fulfilled, (state, action) => {
        state.dunningHistoryListingState.isLoading = false;
        state.dunningHistoryListingState.data = action.payload.data;
      })
      .addCase(getDunningHistoryListingThunk.rejected, (state, action) => {
        state.dunningHistoryListingState.isLoading = false;
        state.dunningHistoryListingState.isError = true;
        state.dunningHistoryListingState.errorMessage = action.payload;
      });
    builder
      .addCase(getDunningContactModeListingThunk.pending, (state) => {
        state.dunningContactModeListingState.isLoading = true;
      })
      .addCase(getDunningContactModeListingThunk.fulfilled, (state, action) => {
        state.dunningContactModeListingState.isLoading = false;
        state.dunningContactModeListingState.data = action.payload.data;
      })
      .addCase(getDunningContactModeListingThunk.rejected, (state, action) => {
        state.dunningContactModeListingState.isLoading = false;
        state.dunningContactModeListingState.isError = true;
        state.dunningContactModeListingState.errorMessage = action.payload;
      });
  },
});

export const { dunningHistoryReset } = dunningHistorySlice.actions;

export default dunningHistorySlice.reducer;
