import { caseAssignmentAxiosInstance } from "../../../axios/caseAssignmentAxios";

const getAllRegion = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/caseAssignment/api/v1/region/listing`;
  const res = await caseAssignmentAxiosInstance.get(url, { params });
  return res.data;
};

const createRegion = async (requestData: any) => {
  const url = `/caseAssignment/api/v1/region`;
  const res = await caseAssignmentAxiosInstance.post(url, requestData);
  return res.data;
};

const updateRegion = async (requestData: any) => {
  const { regionId, payload } = requestData;
  const url = `/caseAssignment/api/v1/region/id/${regionId}`;
  const res = await caseAssignmentAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteRegion = async (requestData: any) => {
  const { regionId } = requestData;
  const url = `/caseAssignment/api/v1/region/id/${regionId}`;
  const res = await caseAssignmentAxiosInstance.delete(url);
  return res.data;
};

const regionService = {
  getAllRegion,
  createRegion,
  updateRegion,
  deleteRegion,
};

export default regionService;
