import { Button, Input, Select, Table, TablePaginationConfig } from "antd";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { numberWithCommas } from "../../../../helpers/number";
import {
  getAllAocThunk,
  getApprovedAocThunk,
  getOpenAocThunk,
  getPendingAocThunk,
  getVoidAocThunk,
} from "../../../../services/aocService/aocThunk";
import { AppDispatch } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";
const { Search } = Input;

type AocTableProps = {
  dataSource: any;
  status?: any;
};

const AocTable: React.FC<AocTableProps> = (props) => {
  const { dataSource, status } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchData, setSearchData] = useState(initialParams.search);
  const [searchFiled, setSearchFiled] = useState("batchNo");

  const tablePagination = {
    total: dataSource?.data.searchTotal ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Date Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Batch No",
      dataIndex: "batchNo",
      key: "batchNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/add-on-charges/batch/details/${record.id}`)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Created By",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.createdById ?? "-",
    },
    {
      title: "Total No. of Account",
      dataIndex: "totalNoOfAccount",
      key: "totalNoOfAccount",
      render: (text, record) => record?.totalNoOfAccount ?? "-",
    },
    {
      title: "Total Add-On Charges Amount",
      render: (text, record) =>
        `RM ${numberWithCommas(record?.totalAocTax)}` ?? "-",
    },
  ];

  const fetchAgreements = async (params?: any) => {
    if (status === "APPROVED") {
      await dispatch(getApprovedAocThunk(params));
    } else if (status === "OPEN") {
      await dispatch(getOpenAocThunk(params));
    } else if (status === "PENDING") {
      await dispatch(getPendingAocThunk(params));
    } else if (status === "VOID") {
      await dispatch(getVoidAocThunk(params));
    } else {
      await dispatch(getAllAocThunk(params));
    }
  };

  const onSearch = (value: string) => {
    const filters = {
      ...filterState,
      // batchNo: value === "" ? undefined : value,
    };

    const params = {
      ...initialParams,
      filters,
      search: { [searchFiled]: value.toUpperCase() },
    };
    fetchAgreements(params);
    setFilterState({ ...initialParams.filters });
    setSearchData({ [searchFiled]: value.toUpperCase() });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchAgreements({
      ...initialParams,
      ...filterState,
      pagination,
      search: searchData,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  return (
    <>
      <Helmet>
        <title>Add-on Charges Listing - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="batchNo"
          style={{ width: 200 }}
          onChange={(value) => setSearchFiled(value)}
          options={[{ value: "batchNo", label: "Batch No" }]}
        />

        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={dataSource?.data?.data ?? []}
        loading={dataSource?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AocTable;
