import { Button, Input, Select, Table, Tag } from "antd";
import startCase from "lodash.startcase";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { FieldVisit } from "../../../../features/fieldVisit/fieldVisitSlice";
import { numberWithCommas } from "../../../../helpers/number";
import {
  getOpenFieldVisitsThunk,
  getPendingFieldVisitsThunk,
} from "../../../../services/fieldVisitService/fieldVisitThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import RequestOfficerVisitModal from "../RequestOfficerVisitModal";

import type { TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import type { ColumnsType } from "antd/es/table";
import { FIELD_VISIT_STATUS } from "../../../../enum/fieldVisitStatus";
import AccountEnquiryModal from "../../../AccountEnquiry/AccountEnquiryModal";

const { Search } = Input;

const OpenTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { openFieldVisitListingState } = useSelector(
    (state: RootState) => state.fieldVisit,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<FieldVisit>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");

  const tablePagination = {
    total: openFieldVisitListingState?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      render: (_: string, __, index: number) => index + 1,
    },
    {
      title: "Field Visit No.",
      dataIndex: "fieldVisitNo",
      key: "fieldVisitNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/field-visit/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Postcode",
      dataIndex: "postCode",
      render: (text, record) => text,
    },
    // {
    //   title: "Address",
    //   render: (_, record) =>
    //     [record.address1, record.address2, record.address3]
    //       .filter((item) => item != null && item !== "")
    //       .join(", ") ?? "-",
    // },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Aging Type",
      render: (_, record) => record?.accountDetails?.agingType ?? "-",
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal
          key={record.id}
          agreementNo={record.accountDetails.agreementNo}
        />
      ),
    },
    {
      title: "Total Loan Outstanding",
      render: (_, record) =>
        record?.accountDetails?.totalLoanOutstanding
          ? `RM ${numberWithCommas(
              record?.accountDetails?.totalLoanOutstanding,
            )}`
          : "-",
    },
    {
      title: "NPL Flag",
      render: (_, record) => (
        <Tag color={record?.accountDetails?.nplFlag ? "success" : "warning"}>
          {startCase(`${record?.accountDetails?.nplFlag}`)}
        </Tag>
      ),
    },
    {
      title: "Visit By",
      render: (_, record) =>
        record?.fieldVisitOfficerId === null
          ? `-`
          : `${record?.fvOfficer?.firstName} ${record?.fvOfficer?.lastName}`,
    },
    {
      title: "Action",
      render: (_, record) => (
        <RequestOfficerVisitModal
          key={record?.id}
          fieldVisit={record}
          callback={() => {
            fetchOpenFieldVisit(initialParams);
            fetchPendingFieldVisit(initialParams);
          }}
        />
      ),
    },
  ];

  const fetchOpenFieldVisit = async (params?: any) => {
    const parameters = {
      params: { ...params, filters: { status: FIELD_VISIT_STATUS.OPEN } },
    };
    await dispatch(getOpenFieldVisitsThunk(parameters));
  };

  const fetchPendingFieldVisit = async (params?: any) => {
    const parameters = {
      params: { ...params, filters: { status: FIELD_VISIT_STATUS.PENDING } },
    };
    await dispatch(getPendingFieldVisitsThunk(parameters));
  };

  const onSearch = (value: string) => {
    if (value) {
      fetchOpenFieldVisit({
        ...initialParams,
        filters: { ...filterState },
        search: { [searchFiled]: value.toUpperCase() },
      });
      // setFilterState({ ...initialParams.filters, [searchFiled]: value });
    } else {
      fetchOpenFieldVisit({
        ...initialParams,
        filters: { ...filterState },
      });
    }
  };

  const handleTableChange: TableProps<FieldVisit>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    setSortedInfo(sorter as SorterResult<FieldVisit>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    const param =
      sorter?.order == null
        ? {
            ...initialParams,
            ...filterState,
            pagination,
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          };

    fetchOpenFieldVisit(param);

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>Open Field Visit - redCASH CEP</title>
      </Helmet>
      <Input.Group className="mb-2" compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => setSearchFiled(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
            { value: "applicantName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
            { value: "fieldVisitNo", label: "Field Visit No." },
            { value: "visitBy", label: "Visit By" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={openFieldVisitListingState?.data ?? []}
        loading={openFieldVisitListingState?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default OpenTab;
