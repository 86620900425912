import { Button, Dropdown, Input, Select, Space, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppDispatch } from "../../../../store/store";
import type { ColumnsType } from "antd/es/table";
import { Helmet } from "react-helmet-async";
import type { TablePaginationConfig } from "antd/es/table";
import { displayDate } from "../../../../utils/datetime.util";
import { numberWithCommas } from "../../../../helpers/number";
import { setWriteOffBatch } from "../../../../features/writeOff/writeOffSlice";
import { useDispatch } from "react-redux";

const { Search } = Input;

type WriteOffTableProps = {
  dataSource: any;
  callback: any;
};

const WriteOffTable: React.FC<WriteOffTableProps> = (props) => {
  const { dataSource, callback } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchField, setSearchField] = useState("batchNumber");

  const tablePagination = {
    total: dataSource?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Batch No",
      dataIndex: "batchNumber",
      key: "batchNumber",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            navigate(`/write-off/proposal/${record.batchNumber}`);
            dispatch(setWriteOffBatch(record));
          }}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Total Records",
      dataIndex: "totalRecord",
      key: "totalRecord",
    },
    {
      title: "Total Write Off Amount (RM)",
      dataIndex: "totalWriteOffAmount", //totalWriteOffAmount
      key: "totalWriteOffAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Total Finance Amount (RM)",
      dataIndex: "totalFinanceAmount",
      key: "totalFinanceAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Total Loan Outstanding (RM)",
      dataIndex: "totalLoanOutstandingAmount",
      key: "totalLoanOutstandingAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDate(text) : "-"),
    },
  ];

  const onSearch = (value: string) => {
    callback({
      ...initialParams,
      filters: { ...filterState, [searchField]: value },
    });
    setFilterState({ ...initialParams.filters, [searchField]: value });
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    callback({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>Write Off Listing - redCASH CEP</title>
      </Helmet>

      <Input.Group compact>
        <Select
          defaultValue="batchNumber"
          style={{ width: 200 }}
          onChange={(value) => setSearchField(value)}
          options={[
            { value: "batchNumber", label: "Batch No" },
            { value: "status", label: "Status" },
            { value: "createdBy", label: "Created By" },
          ]}
        />

        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={dataSource?.data?.data ?? []}
        loading={dataSource?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default WriteOffTable;
