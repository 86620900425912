import { Button, Input, Select, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Agreement } from "../../../features/agreement/agreementSlice";
import { numberWithCommas } from "../../../helpers/number";
import { getInProgressEStampAgreementsThunk } from "../../../services/agreementService/agreementThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { onCalculateNetDisbursementAmount } from "../../../utils/calculation.util";
import { displayDate } from "../../../utils/datetime.util";

import type { TableProps } from "antd/es/table";
import type { ColumnsType } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { getInProgressSmeEStampListingThunk } from "../../../services/smeAgreementService/smeAgreementThunk";

const { Search } = Input;

const InProgressEStampTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { inProgressSmeEStampList } = useSelector(
    (state: RootState) => state.smeAgreement,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };

  const tablePagination = {
    total: inProgressSmeEStampList?.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      sorter: (a, b) => a.signDate - b.signDate,
      sortOrder: sortedInfo.columnKey === "signDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            queryParams.set("from", "estamp-pending");
            const newSearch = queryParams.toString();

            return navigate({
              pathname: `/sme/agreement/details/${record.smeAgreementId}`,
              search: newSearch,
            });
          }}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Name",
      dataIndex: "applicantName",
      key: "applicantName",
      render: (text, record) => record?.applicantName ?? "-",
    },
    {
      title: "Contact No.",
      dataIndex: "applicantContactNo",
      key: "applicantContactNo",
      render: (text, record) => record?.applicantContactNo ?? "-",
    },
    {
      title: "NRIC",
      dataIndex: "applicantNric",
      key: "applicantNric",
      render: (text, record) => record?.applicantNric ?? "-",
    },
    {
      title: "Finance Amount",
      key: "financeAmount",
      render: (text, record) =>
        record?.financeAmount != null
          ? `RM ${numberWithCommas(record?.financeAmount)}`
          : "-",
    },
  ];

  const fetchAgreements = async (params?: any) => {
    await dispatch(
      getInProgressSmeEStampListingThunk({
        query: params,
        status: "in_progress",
      }),
    );
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchAgreements({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      fetchAgreements({
        ...initialParams,
        filters: { ...filterState },
      });

      setSearchValue("");
    }
  };

  const handleTableChange: TableProps<any>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    setSortedInfo(sorter as SorterResult<any>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    const param = {
      ...initialParams,
      ...filterState,
      pagination,
      sortField: sorter?.order ? sorter?.columnKey : "createdAt",
      sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
      ...(searchValue.length > 0 && {
        search: {
          [searchFiled]: searchValue.toUpperCase(),
        },
      }),
    };
    fetchAgreements(param);

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 10}`);
    const newSearch = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <>
      <Helmet>
        <title>In Process E-Stamp - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
            { value: "name", label: "Applicant Name" },
            { value: "nric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={inProgressSmeEStampList?.data?.data ?? []}
        loading={inProgressSmeEStampList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default InProgressEStampTab;
