import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";
import {
  CreateManualPaymentRequest,
  PaymentHistoryDataResponse,
} from "../../features/payment/paymentSlice";

export const getPaymentHistory = async (params: any) => {
  const { agreementId, agreementNo, pagination, ...restParams } = params;
  const paramsData = {
    ...restParams,
    currentPage: pagination.current ?? 1,
    pageSize: pagination.pageSize ?? 20,
  };
  const result =
    await collectionSetupAxiosInstance.get<PaymentHistoryDataResponse>(
      `/redCash/admin/api/v1.0/transaction/getPaymentHistory/${agreementId}`,
      { params: paramsData },
    );
  return result.data;
};

export const createManualPayment = async (
  requestData: CreateManualPaymentRequest,
) => {
  const url = `/redCash/api/v1.0/payment/postManualPayment`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const uploadManualPayment = async (requestData: any) => {
  const url = "/redCash/api/v1.0/payment/postManualPayment/csv";

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const res = await beSetupAxiosInstance.post(url, requestData, config);

  return res.data;
};
