import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";
import { transactionDataResponse } from "../../features/transactionHistory/transactionHistorySlice";

export const getTransactionHistory = async (params: any) => {
  const { agreementId, pagination, ...restParams } = params;
  const paramsData = {
    ...restParams,
    currentPage: pagination.current ?? 1,
    pageSize: pagination.pageSize ?? 20,
  };
  const result =
    await collectionSetupAxiosInstance.get<transactionDataResponse>(
      `/redCash/admin/api/v1.0/transactionHistory/${agreementId}`,
      { params: paramsData },
    );
  return result.data;
};
