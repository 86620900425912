import { Helmet } from "react-helmet-async";

import { PageHeader } from "@ant-design/pro-components";

import ReminderLetterContent from "../ReminderLetterContent";

const ReminderLetter3Listing = () => {
  return (
    <>
      <Helmet>
        <title>Reminder Letter 3 - RedCash Collection</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Reminder Letter 3" />
      <ReminderLetterContent letterNumber="3" />
    </>
  );
};

export default ReminderLetter3Listing;
