import { createSlice } from "@reduxjs/toolkit";

import {
  getAgreementsByBatchIdThunk,
  getAllListingNoticesGenerationConfigThunk,
  getBatchListThunk,
  updateNoticesGenerationConfigThunk,
} from "../../services/noticesGenerationConfigService/noticesGenerationConfigThunk";
import { AccountDetails } from "../collection/collectionSlice";

export type NoticesConfig = {
  id: string;
  letterType: string;
  dpdToTrigger: number;
  charge?: number;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type SelectBatchNo = {
  id: string;
  batchNo: string;
  createdAt?: string;
  updatedAt?: string;
};

export interface AgreementsByBatchId {
  id: string;
  batchId: string;
  accountDetailsId: string;
  noticesGenerationBatch: noticesGenerationBatch;
  printedDate: string;
  outstandingAmount: number;
  otherCharges: any;
  status: string;
  createdAt: string;
  updatedAt: string;
  accountDetails: AccountDetails;
}

interface noticesGenerationBatch {
  DPD: number;
}

export type NoticesConfigState = {
  listingNoticesConfigState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: NoticesConfig[];
  };
  detailsNoticesConfigState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: NoticesConfig;
  };
  listingSelectBatchNoState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: SelectBatchNo[];
  };
  agreementsByBatchId: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: AgreementsByBatchId[];
    total: number;
    currentPage: number;
    pageSize: number;
  };
};

const initialState: NoticesConfigState = {
  listingNoticesConfigState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  detailsNoticesConfigState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  listingSelectBatchNoState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  agreementsByBatchId: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
    total: 0,
    currentPage: 1,
    pageSize: 10,
  },
};
export const noticesConfigSlice = createSlice({
  name: "noticesConfig",
  initialState,
  reducers: {
    noticesConfigReset: () => initialState,
    listingSelectBatchNoStateReset: (state) => {
      state.listingSelectBatchNoState = initialState.listingSelectBatchNoState;
    },
    agreementsByBatchIdReset: (state) => {
      state.agreementsByBatchId = initialState.agreementsByBatchId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllListingNoticesGenerationConfigThunk.pending, (state) => {
        state.listingNoticesConfigState.isLoading = true;
      })
      .addCase(
        getAllListingNoticesGenerationConfigThunk.fulfilled,
        (state, action) => {
          state.listingNoticesConfigState.isLoading = false;
          state.listingNoticesConfigState.data = action.payload.data;
        },
      )
      .addCase(
        getAllListingNoticesGenerationConfigThunk.rejected,
        (state, action) => {
          state.listingNoticesConfigState.isLoading = false;
          state.listingNoticesConfigState.isError = true;
          state.listingNoticesConfigState.errorMessage = action.payload;
        },
      );

    builder
      .addCase(updateNoticesGenerationConfigThunk.pending, (state) => {
        state.detailsNoticesConfigState.isLoading = true;
      })
      .addCase(
        updateNoticesGenerationConfigThunk.fulfilled,
        (state, action) => {
          state.detailsNoticesConfigState.isLoading = false;
          state.detailsNoticesConfigState.data = action.payload.data;
        },
      )
      .addCase(updateNoticesGenerationConfigThunk.rejected, (state, action) => {
        state.detailsNoticesConfigState.isLoading = false;
        state.detailsNoticesConfigState.isError = true;
        state.detailsNoticesConfigState.errorMessage = action.payload;
      });

    builder
      .addCase(getBatchListThunk.pending, (state) => {
        state.listingSelectBatchNoState.isLoading = true;
      })
      .addCase(getBatchListThunk.fulfilled, (state, action) => {
        state.listingSelectBatchNoState.isLoading = false;
        state.listingSelectBatchNoState.data = action.payload.data;
      })
      .addCase(getBatchListThunk.rejected, (state, action) => {
        state.listingSelectBatchNoState.isLoading = false;
        state.listingSelectBatchNoState.isError = true;
        state.listingSelectBatchNoState.errorMessage = action.payload;
      });

    builder
      .addCase(getAgreementsByBatchIdThunk.pending, (state) => {
        state.agreementsByBatchId.isLoading = true;
      })
      .addCase(getAgreementsByBatchIdThunk.fulfilled, (state, action) => {
        state.agreementsByBatchId.isLoading = false;
        state.agreementsByBatchId.data = action.payload.data;
        state.agreementsByBatchId.total = action.payload.total;
        state.agreementsByBatchId.currentPage = action.payload.currentPage;
        state.agreementsByBatchId.pageSize = action.payload.pageSize;
      })
      .addCase(getAgreementsByBatchIdThunk.rejected, (state, action) => {
        state.agreementsByBatchId.isLoading = false;
        state.agreementsByBatchId.isError = true;
        state.agreementsByBatchId.errorMessage = action.payload;
      });
  },
});
export const {
  noticesConfigReset,
  listingSelectBatchNoStateReset,
  agreementsByBatchIdReset,
} = noticesConfigSlice.actions;

export default noticesConfigSlice.reducer;
