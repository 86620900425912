import { createSlice } from "@reduxjs/toolkit";
import { getAllRuleThunk } from "../../services/caseAssignmentService/ruleService/ruleThunk";

export type Condition = {
  id: string;
  code: string;
  value: string | number;
  ruleId: string;
  ruleParameterId: string;
};

export type Rule = {
  id: string;
  ruleCode: string;
  ruleName: string;
  Condition?: Condition[];
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
};

export type RuleState = {
  rulesState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Rule[];
  };
};

const initialState: RuleState = {
  rulesState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const ruleSlice = createSlice({
  name: "rule",
  initialState,
  reducers: {
    ruleReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRuleThunk.pending, (state) => {
        state.rulesState.isLoading = true;
      })
      .addCase(getAllRuleThunk.fulfilled, (state, action) => {
        state.rulesState.isLoading = false;
        state.rulesState.data = action.payload.data;
      })
      .addCase(getAllRuleThunk.rejected, (state, action) => {
        state.rulesState.isLoading = false;
        state.rulesState.isError = true;
        state.rulesState.errorMessage = action.payload;
      });
  },
});

export const { ruleReset } = ruleSlice.actions;

export default ruleSlice.reducer;
