import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Modal,
  Select,
  Table,
  TablePaginationConfig,
  message,
} from "antd";
import React, { Key, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../../helpers/number";

import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";
import type { ColumnsType } from "antd/es/table";
import {
  clearPaymentSpecialTaggingThunk,
  requestRefundSpecialTaggingThunk,
} from "../../../services/specialTaggingService/specialTaggingThunk";
import { SPECIAL_TAGGING_STATUS } from "../../../enum/specialTagging";
import AccountEnquiryModal from "../../AccountEnquiry/AccountEnquiryModal";
import { request } from "http";
import { refundAdminCharge } from "../../../enum/refund";
import { getAccountDetailsThunk } from "../../../services/collectionService/collectionThunk";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";

const { Search } = Input;

type SpecialTaggingTableProps = {
  dataSource: any;
  status?: string;
  callback?: any;
};

const SpecialTaggingTable: React.FC<SpecialTaggingTableProps> = (props) => {
  const { dataSource, status, callback } = props;
  const dispatch = useDispatch<AppDispatch>();

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [searchField, setSearchField] = useState("agreementNo");
  const [searchData, setSearchData] = useState(initialParams.search);
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [id, setId] = useState("");
  const [selectedRow, setSelectedRow] = useState<Key[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [paymentDate, setPaymentDate] = useState<any>(null);
  const [paymentId, setPaymentId] = useState("");
  const [agreementId, setAgreementId] = useState<any>(null);
  const [paymentReferenceNo, setPaymentReferenceNo] = useState<any>(null);
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const tablePagination = {
    total: dataSource?.data?.searchTotal ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const onSearch = (value: string) => {
    if (value.length === 0) {
      setSearchData({});
      callback(initialParams, status);
      return;
    }

    const filters = {
      ...filterState,
      status,
    };

    const search = {
      ...initialParams.search,
      [searchField]: value.toUpperCase(),
    };

    setSearchData({ [searchField]: value.toUpperCase() });

    const params = {
      ...initialParams,
      filters,
      search,
    };
    callback(params, status);
    // setFilterState({ ...initialParams.filters, [searchField]: value });
  };

  const openModal = async (data: any) => {
    form.setFieldsValue({
      agreementNo: data.accountDetails.agreementNo,
      applicantPhoneNo: data.accountDetails.applicantPhoneNo,
      paymentChannel: data.paymentChannel,
      paymentDate: dayjs(data.paymentDate),
      paymentAmount: data.paymentAmount,
      bankName: data.bankName,
    });
    setId(data.id);
    setIsModalOpen(true);
    setPaymentDate(moment(data.paymentDate).format("YYYY-MM-DD"));
    setPaymentId(data.paymentId);
    setAgreementId(data.accountDetails.agreementId);
    setPaymentReferenceNo(data.paymentReferenceNo);
  };

  const requestRefund = async () => {
    setRequestLoading(true);

    const requestData = {
      id: selectedRow,
    };

    if (selectedRow.length === 0) {
      message.error(`Please select at least one record`);
      setLoading(false);
      return;
    }

    const requestRefundData: any = [];

    await Promise.all(
      requestData.id.map((id: any) => {
        dataSource.data.data.find((item: any) => {
          if (item.id === id) {
            const payload = {
              agreementNo: item.accountDetails.agreementNo,
              contactPhoneNo: item.accountDetails.applicantPhoneNo,
              applicantNric: item.accountDetails.applicantNric,
              unmatchedAmount: item.paymentAmount,
              refundAdminCharge: refundAdminCharge,
              accountDetailsId: item.accountDetails.id,
              specialTaggingMatchId: item.id,
            };

            requestRefundData.push(payload);
          }
        });
      }),
    );

    const payload = {
      unmatchedRefund: requestRefundData,
    };

    await dispatch(requestRefundSpecialTaggingThunk(payload))
      .unwrap()
      .then((res: any) => {
        message.success("Request Refund Successfully");
      })
      .catch((err: any) => {
        message.error("Request Refund Failed");
      })
      .finally(() => {
        callback(initialParams, SPECIAL_TAGGING_STATUS.UNCLEAR);
        callback(initialParams, SPECIAL_TAGGING_STATUS.CLEAR);
        setRequestLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Agreement No",
      dataIndex: ["accountDetails", "agreementNo"],
      key: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal
          key={record.id}
          agreementNo={record.accountDetails.agreementNo}
        />
      ),
    },
    {
      title: "Aging Type",
      dataIndex: ["accountDetails", "agingType"],
      key: "agingType",
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (text, _) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Payment Method",
      children: [
        {
          title: "Channel",
          dataIndex: "paymentChannel",
          key: "paymentChannel",
        },
        {
          title: "Bank Name",
          dataIndex: "bankName",
          key: "bankName",
        },
      ],
    },

    {
      title: "Payment Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      render: (text, record) => `RM${numberWithCommas(text)}` ?? "-",
    },
    {
      title: "Action",
      render: (tex, record) => {
        return (
          <>
            {record.status === "UNCLEAR" && (
              <Button
                type="primary"
                loading={loading}
                onClick={() => openModal(record)}
              >
                Clear Payment
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    callback({
      ...initialParams,
      ...filterState,
      pagination,
      search: searchData,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dateOnChange: DatePickerProps["onChange"] = (date, dateString) => {
    setPaymentDate(dateString);
  };

  const agreementNoOnChange = async (e: any) => {
    let agreementNo = null;
    let applicantPhoneNo = null;

    if (e.target.value.length !== 10) {
      return;
    }

    message.loading("Please wait", 1.2);

    await dispatch(
      getAccountDetailsThunk({ agreementNo: e.target.value }),
    ).then((res) => {
      if (res.payload.accountDetails.agreementNo == e.target.value) {
        agreementNo = res.payload.accountDetails.agreementNo;
        applicantPhoneNo = res.payload.accountDetails.applicantPhoneNo;
        setAgreementId(res.payload.accountDetails.agreementId);
      }
    });

    if (agreementNo !== null && applicantPhoneNo !== null) {
      message.success("Agreement number found");
      form.setFieldsValue({
        agreementNo: agreementNo,
        applicantPhoneNo: applicantPhoneNo,
      });
    } else {
      message.error("Agreement number not found");
    }
  };

  const clearPayment = async () => {
    setLoading(true);

    await form.validateFields().then(async (values) => {
      const payload = {
        id,
        agreementNo: values.agreementNo,
        applicantPhoneNo: values.applicantPhoneNo,
        paymentAmount: values.paymentAmount,
        paymentChannel: values.paymentChannel,
        paymentDate,
        bankName: values.bankName,
        paymentId,
        agreementId,
        paymentReferenceNo,
      };

      await dispatch(clearPaymentSpecialTaggingThunk(payload))
        .unwrap()
        .then((res: any) => {
          const success = res.filter(
            (item: { status: string }) => item.status === "SUCCESS",
          );
          const failed = res.filter(
            (item: { status: string }) => item.status === "FAIL",
          );

          if (failed.length > 0) {
            if (success.length > 0) {
              message.success(
                `Clear payment for ${success.length} records successfully`,
              );
            }
            message.error(`Clear payment failed for ${failed.length} records`);
            failed.forEach((item: { id: string; message: string }) => {
              message.error(
                `Agreement No ${
                  dataSource.data.data.find(
                    (i: { id: string }) => i.id === item.id,
                  ).accountDetails.agreementNo
                } error: ${item.message}`,
              );
            });
          } else {
            message.success(`Clear payment successfully`);
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setIsModalOpen(false);
          callback(initialParams, SPECIAL_TAGGING_STATUS.UNCLEAR);
          callback(initialParams, SPECIAL_TAGGING_STATUS.CLEAR);
        });
    });
  };

  return (
    <>
      <Helmet>
        <title>Special Tagging Listing - redCASH CEP</title>
      </Helmet>

      <div className="flex justify-between mb-4">
        <Input.Group compact>
          <Select
            defaultValue="agreementNo"
            style={{ width: 200 }}
            onChange={(value) => setSearchField(value)}
            options={[
              { value: "agreementNo", label: "Agreement No" },
              { value: "bankName", label: "Bank Name" },
            ]}
          />

          <Search
            placeholder="input search text"
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Input.Group>
      </div>

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={dataSource?.data?.data ?? []}
        loading={dataSource?.isLoading}
        onChange={handleTableChange}
        rowSelection={{
          selectedRowKeys: selectedRow.length > 0 ? selectedRow : [],
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRowKeys);
          },
          getCheckboxProps: (record) => ({
            disabled: status == SPECIAL_TAGGING_STATUS.CLEAR,
          }),
        }}
      />
      <br />
      {status === "UNCLEAR" && (
        <>
          <div className="flex flex-wrap justify-end mb-8 gap-1">
            <Button
              type="primary"
              loading={requestLoading}
              onClick={() => requestRefund()}
            >
              Request Refund
            </Button>
          </div>
        </>
      )}

      <Modal
        title={"Clear Payment"}
        open={isModalOpen}
        onCancel={handleCancel}
        destroyOnClose
        centered
        onOk={clearPayment}
        footer={[
          <Button
            key="Clear Payment"
            onClick={() => clearPayment()}
            type="primary"
            loading={loading}
          >
            Clear Payment
          </Button>,

          <Button key="Cancel" onClick={() => handleCancel()}>
            Cancel
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" autoComplete="off">
          <Form.Item label="Agreement No." name="agreementNo">
            <Input
              placeholder="Please input"
              name="agreementNo"
              style={{ width: "100%" }}
              onChange={agreementNoOnChange}
            />
          </Form.Item>

          <Form.Item label="Contact Phone No." name="applicantPhoneNo">
            <Input
              placeholder="Please input"
              name="applicantPhoneNo"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item label="Payment Date" name="paymentDate">
            <DatePicker
              onChange={dateOnChange}
              style={{ width: "100%" }}
              disabled
            />
          </Form.Item>

          <Form.Item label="Payment Channel" name="paymentChannel">
            <Input
              placeholder="Please input"
              name="paymentChannel"
              style={{ width: "100%" }}
              disabled
            />
          </Form.Item>

          <Form.Item label="Bank Name" name="bankName">
            <Input
              placeholder="Please input"
              name="bankName"
              style={{ width: "100%" }}
              disabled
            />
          </Form.Item>

          <Form.Item label="Payment Amount" name="paymentAmount">
            <Input
              placeholder="Please input"
              name="paymentAmount"
              style={{ width: "100%" }}
              disabled
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SpecialTaggingTable;
