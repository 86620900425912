import "../index.less";

import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useEffect, useMemo, useState } from "react";

import { numberWithCommas, roundTo } from "../../../helpers/number";
import { displayDateAndTime } from "../../../utils/datetime.util";

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { getPaymentHistoryThunk } from "../../../services/paymentService/paymentThunk";
import { getAccountDetailsCollectionSystemLogThunk } from "../../../services/collectionService/collectionThunk";
import startCase from "lodash.startcase";

const CollectionSystemLog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { collectionSystemLog, accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: collectionSystemLog.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Creation Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Action Type",
      dataIndex: "actionType",
      key: "actionType",
      render: (text: string) => (text ? startCase(text) : "-"),
    },
    {
      title: "Column Name",
      dataIndex: "columnName",
      key: "columnName",
      render: (text: string) => (text ? startCase(text) : "-"),
    },
    {
      title: "UPDATE FROM ACTION",
      dataIndex: "fromAction",
      key: "fromAction",
      render: (text: string) => (text ? startCase(text) : "-"),
    },
    {
      title: "Receipt No.",
      dataIndex: "referenceNo",
      key: "referenceNo",
    },
    {
      title: "Previous Amount",
      dataIndex: "prevAmount",
      key: "prevAmount",
      render: (text: number) => roundTo(text, 4) ?? "-",
    },
    {
      title: "Updated Amount",
      dataIndex: "newAmount",
      key: "newAmount",
      render: (text: number) => roundTo(text, 4) ?? "-",
    },
  ];

  const fetchCollectionSystemLog = async (params?: any) => {
    await dispatch(getAccountDetailsCollectionSystemLogThunk({ ...params }));
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchCollectionSystemLog({
      ...initialParams,
      ...filterState,
      pagination,
      accountDetailsId: accountEnquiry.data?.accountDetails?.id,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    if (accountEnquiry.data?.accountDetails?.id) {
      fetchCollectionSystemLog({
        ...initialParams,
        accountDetailsId: accountEnquiry.data?.accountDetails?.id,
      });
    }
  }, [initialParams, accountEnquiry.data?.accountDetails?.id]);

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <Table
            columns={columns}
            dataSource={collectionSystemLog.data?.data}
            pagination={tablePagination}
            loading={collectionSystemLog.isLoading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CollectionSystemLog;
