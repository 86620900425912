import "./index.less";

import { Button, DatePicker, Input, message, Space, Table } from "antd";
import startCase from "lodash.startcase";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import {
  dangerIconRed,
  successTextGreen,
} from "../../../assets/style/variables";
import { DisbursementBatch } from "../../../features/disbursement/disbursementSlice";
import { setFetchDisbursementBatchListingParams } from "../../../features/fetchParameter/fetchParameterSlice";
import { downloadLink } from "../../../helpers/downloadLink";
import { numberWithCommas } from "../../../helpers/number";
import {
  getDisbursementBatchListingThunk,
  getDisbursementListingSummaryThunk,
  getDisbursementListingSummaryTxtFileThunk,
  patchDisbursementBatchToInProcessTrunk,
} from "../../../services/disbursementService/disbursementTrunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";
import type { DatePickerProps } from "antd";
import type { TablePaginationConfig } from "antd/es/table";

const { Search } = Input;

const CreatedDisbursementBatchListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { disbursementBatchListing } = useSelector(
    (state: RootState) => state.disbursement,
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [downloading, setDownloading] = useState(false);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: disbursementBatchListing?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const onDownloadBankFiles = async (batch: DisbursementBatch) => {
    setDownloading(true);
    message.loading("Downloading");
    if (batch.status === "OPEN") {
      await dispatch(
        patchDisbursementBatchToInProcessTrunk({ batchId: batch?.id }),
      )
        .unwrap()
        .then(() => {
          message.loading("Downloading");
          fetchDisbursementBatchListing({
            ...initialParams,
            pagination: {
              current: pageNum ? +pageNum : 1,
              pageSize: pageSize ? +pageSize : 20,
            },
          });

          dispatch(
            getDisbursementListingSummaryTxtFileThunk({ batchId: batch?.id }),
          )
            .unwrap()
            .then(downloadLink)
            .finally(() => setDownloading(false));

          dispatch(
            getDisbursementListingSummaryThunk({ batchNo: batch?.batchNo }),
          )
            .unwrap()
            .then(downloadLink)
            .finally(() => setDownloading(false));
        });
    } else {
      dispatch(
        getDisbursementListingSummaryTxtFileThunk({ batchId: batch?.id }),
      )
        .unwrap()
        .then(downloadLink)
        .finally(() => setDownloading(false));

      dispatch(getDisbursementListingSummaryThunk({ batchNo: batch?.batchNo }))
        .unwrap()
        .then(downloadLink)
        .finally(() => setDownloading(false));
    }
  };

  const createdBatchTableColumns: ColumnsType<any> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Completed Date",
      dataIndex: "completedDate",
      key: "completedDate",
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
      key: "batchNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/disbursement-details/batch/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Total Finance Amount",
      dataIndex: "totalApprovedLoanAmount",
      key: "totalApprovedLoanAmount",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Total Disbursement Amount",
      dataIndex: "totalNetDisbursementAmount",
      key: "totalNetDisbursementAmount",
      render: (text) => `RM ${numberWithCommas(text)}` ?? "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          style={{
            color:
              text === "IN_COMPLETE"
                ? dangerIconRed
                : text === "COMPLETED"
                ? successTextGreen
                : "black",
          }}
        >
          {startCase(text === "IN_COMPLETE" ? "INCOMPLETE" : text ?? "???")}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {record?.status !== "COMPLETED" && (
            <Button
              key={record.id}
              onClick={() => onDownloadBankFiles(record)}
              // disabled={downloading}
            >
              Download
            </Button>
          )}
        </>
      ),
    },
  ];

  const fetchDisbursementBatchListing = async (params?: any) => {
    await dispatch(getDisbursementBatchListingThunk({ params }));
  };

  const onSearch = async (value: string) => {
    const filters = {
      ...filterState,
    };

    const params = {
      ...initialParams,
      filters,
      search: {
        batchNo: value.toUpperCase(),
      },
    };
    fetchDisbursementBatchListing(params);
    await dispatch(setFetchDisbursementBatchListingParams(params));
    setFilterState(filters);
  };

  const onFilterDateChange: DatePickerProps["onChange"] = (
    date,
    dateString,
  ) => {
    console.log(date, dateString);
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    const params = {
      ...initialParams,
      ...filterState,
      pagination,
    };
    fetchDisbursementBatchListing(params);
    await dispatch(setFetchDisbursementBatchListingParams(params));
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 20,
    });

    queryParams.set("pageNum", `${pagination.current ?? 1}`);
    queryParams.set("pageSize", `${pagination.pageSize ?? 20}`);
    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    fetchDisbursementBatchListing(params);
  });

  return (
    <>
      <Helmet>
        <title>Created Disbursement - redCASH CEP</title>
      </Helmet>

      <Space className="mb-4">
        <Search
          placeholder="Batch No."
          onSearch={onSearch}
          style={{ width: 200 }}
        />
        <Space className="ml-4">
          <span>Filter By Created Date:</span>
          <DatePicker onChange={onFilterDateChange} />
        </Space>
      </Space>

      <Table
        rowKey="id"
        className="min-w-fit"
        columns={createdBatchTableColumns}
        pagination={tablePagination}
        dataSource={disbursementBatchListing?.data?.data}
        loading={disbursementBatchListing?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default CreatedDisbursementBatchListingPage;
