import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getSmePendingDisbursementAgreementsListing = async (requestData: any) => {
  const params = {
    filters: requestData.filters,
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/smeAgreement/pending-disbursement`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getSmeDisbursementBatchListing = async (requestData: any) => {
  const params = {
    filters: requestData.filters,
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/smeDisbursement`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getSmeDisbursementBatchById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/smeDisbursement/${id}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const createSmeDisbursementBatch = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smeDisbursement`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const postSmeDisbursementBatchAgreements = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/smeDisbursement/${id}`;
  const res = await beSetupAxiosInstance.patch(url, requestData);
  return res.data;
};

const unpostSmeDisbursementBatchAgreementById = async (requestData: any) => {
  const { id, agreementId } = requestData;
  const url = `/redCash/api/v1.0/smeDisbursement/${id}/smeAgreement/${agreementId}`;
  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const repostSmeAgreement = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/smeDisbursement/${id}/repost`;
  const res = await beSetupAxiosInstance.patch(url, requestData);
  return res.data;
};

const getSmeDisbursementBatchBankFile = async (id: string) => {
  const url = `/redCash/api/v1.0/smeDisbursement/${id}/generateGiroFile`;
  const res = await beSetupAxiosInstance.get(url, {
    responseType: "blob",
  });
  return res.data;
};

const getSmeDisbursementBatchAgreements = async (requestData: any) => {
  const { id } = requestData;
  const params = {
    filters: requestData.filters,
    search: requestData.search,
    sortField: requestData.sortField,
    sortOrder: requestData.sortOrder,
    currentPage: requestData.pagination.current,
    pageSize: requestData.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/smeDisbursement/${id}/smeAgreement`;
  const res = await beSetupAxiosInstance.get(url, {params});
  return res.data;
};

const updatePendingDisbursementAgreementToReferback = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/smeDisbursement/${id}/referback`;
  const res = await beSetupAxiosInstance.patch(url, requestData);
  return res.data;
};

const getDisbursementListingSummary = async (id: string) => {
  const url = `/redCash/api/v1.0/pdf/smeDisbursement/${id}/summary`;
  const res = await beSetupAxiosInstance.get(url, {
    responseType: "blob",
  });
  return res.data;
};

const smeDisbursementService = {
  getSmePendingDisbursementAgreementsListing,
  getSmeDisbursementBatchListing,
  getSmeDisbursementBatchById,
  createSmeDisbursementBatch,
  postSmeDisbursementBatchAgreements,
  unpostSmeDisbursementBatchAgreementById,
  getSmeDisbursementBatchBankFile,
  getSmeDisbursementBatchAgreements,
  repostSmeAgreement,
  updatePendingDisbursementAgreementToReferback,
  getDisbursementListingSummary,
};
export default smeDisbursementService;