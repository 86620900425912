import "./index.less";

import { Button, Modal, UploadFile } from "antd";
import React, { useState } from "react";

import UploadedRefundDocuments from "./RefundFileList";

export type RefundFileListProps = {
  entityId?: string;
  status?: string;
};
const RefundFile: React.FC<RefundFileListProps> = (props) => {
  const { entityId, status } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinished = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button onClick={() => openModal()}>View Files</Button>
      <Modal
        title="Refund Documents"
        open={isModalOpen}
        onOk={onFinished}
        onCancel={handleCancel}
        width="700px"
        destroyOnClose
      >
        <UploadedRefundDocuments
          entityId={entityId}
          status={status}
          defaultFileList={fileList}
        />
      </Modal>
    </>
  );
};

export default RefundFile;
