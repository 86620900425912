import "./index.less";

import { Button, Col, message, Row, Space, Table, Upload } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { FileTypeEnum } from "../../enum/fileType";
import { clickedDownloadEStampingThunk } from "../../services/agreementService/agreementThunk";
import {
  fileDeleteThunk,
  getWaiverFileByEntityIdThunk,
  waiverFileDeleteThunk,
} from "../../services/fileService/fileThunk";
import { AppDispatch } from "../../store/store";
import { displayDate } from "../../utils/datetime.util";
import { handlePreview, handleDownload } from "../../utils/preview.utils";

import type { RcFile, UploadProps } from "antd/es/upload";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";
import { AOC_BATCH_STATUS } from "../../enum/aocBatchStatus";

export type UploadedDocumentProps = {
  entityId?: string;
  fileType?: FileTypeEnum;
  description?: string;
  loading?: boolean;
  defaultFileList?: any;
  callback?: any;
  readOnly?: boolean;
  extra?: ReactNode[];
  status?: string;
};

const UploadedAocDocument: React.FC<UploadedDocumentProps> = (props) => {
  const {
    entityId,
    fileType,
    description,
    defaultFileList,
    readOnly,
    callback,
    extra,
    status,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const fileTableColumns: ColumnsType<any> = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        record.filename.substring(record.filename.indexOf("-") + 1) ?? "-",
    },
    {
      title: "Uploaded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 160,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Action",
      key: "action",
      width: 260,
      render: (_, file) => (
        <Space wrap={false}>
          <Button
            loading={downloading}
            style={{ marginRight: 20 }}
            onClick={() => {
              handleDownload(
                file,
                setDownloading,
                dispatch,
                FileTypeEnum.AOC_CHARGE,
              );

              // handlePreview(
              //   file,
              //   setDownloading,
              //   dispatch,
              //   FileTypeEnum.AOC_CHARGE,
              // );
            }}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
          <Button
            disabled={
              status === AOC_BATCH_STATUS.APPROVED ||
              status === AOC_BATCH_STATUS.PENDING ||
              status === AOC_BATCH_STATUS.VOID
            }
            onClick={() => removeFileFromList(file)}
            icon={<DeleteOutlined style={{ color: "red" }} />}
            danger
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const addFileToList = (file: any) => {
    const newFileList = [...fileList];
    file["createdAt"] = Date.now();
    newFileList.push(file);
    setFileList(newFileList);
  };

  const updateFileInList = (file: RcFile) => {
    const result = fileList.find(
      (uploadedFile: RcFile) =>
        uploadedFile?.name?.substring(uploadedFile?.name?.indexOf("-") + 1) ===
        file.name,
    );

    if (result) {
      const index = fileList.indexOf(result);
      const newFileList = [...fileList];
      newFileList[index] = file;
      setFileList(newFileList);
    }
  };

  const removeFileFromList = async (file: any) => {
    const result = fileList.find(
      (uploadedFile: RcFile) => uploadedFile?.uid === file.uid,
    );

    console.log(result);
    if (result) {
      const index = fileList.indexOf(result);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      console.log(file);
      await dispatch(waiverFileDeleteThunk({ fileId: file.id }));

      // if (!file.uid.startsWith("rc-upload")) {
      //   await dispatch(waiverFileDeleteThunk({ fileId: file.uid }));
      //   await callback();
      // }
    }
  };

  const uploadProps: UploadProps = {
    maxCount: 3,
    fileList: [],
    // onPreview: handlePreview,
    beforeUpload: (file: RcFile) => {
      const isLt5M = file.size / 1024 / 1024 < 5;
      // const moreThan3 = fileList.length >= 3;
      const correctFileType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf";
      const sameFile = fileList.find(
        (uploadedFile: RcFile) =>
          uploadedFile?.name?.substring(
            uploadedFile?.name?.indexOf("-") + 1,
          ) === file.name,
      );
      if (!correctFileType) {
        message.error("Only allowed pdf, png & Jpeg file format!");
        return Upload.LIST_IGNORE;
      }
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
        return Upload.LIST_IGNORE;
      }
      if (sameFile) {
        message.error("Don't upload the same file");
        return Upload.LIST_IGNORE;
      }
      // if (moreThan3) {
      //   message.error("Limit 3 files only");
      //   return Upload.LIST_IGNORE;
      // }
    },

    customRequest: (options: any) => {
      if (!entityId) {
        return message.error("fileId not found");
      }

      if (!fileType) {
        return message.error("fileType not found");
      }

      const data = new FormData();
      data.append("entityId", entityId);
      data.append("fileType", fileType);
      data.append("file", options.file);

      const url = `/redCash/api/v1.0/waiver/file`;

      const config = {
        headers: {
          "content-Type": "multipart/form-data",
        },
      };
      setUploading(true);

      const file = options.file;
      file["status"] = "uploading";
      // addFileToList(file);

      collectionSetupAxiosInstance
        .post(url, data, config)
        .then((res: any) => {
          // options.onSuccess(res.data, options.file);
          const file = options.file;
          addFileToList(file);
          file["uid"] = res.data[0].id;
          file["status"] = "done";
          updateFileInList(file);
          callback();
        })
        .catch(() => {
          const file = options.file;
          file["status"] = "error";

          removeFileFromList(file);

          // message.error("File not supported");
        })
        .finally(() => setUploading(false));
    },

    onRemove: removeFileFromList,
  };

  const fetchFileList = async () => {
    setLoading(true);
    await dispatch(getWaiverFileByEntityIdThunk({ entityId }))
      .unwrap()
      .then((res) => {
        setFileList(
          res.map((file: any) => ({
            ...file,
            id: file.id,
            uid: file.id,
            name: file.filename.substring(file.filename.indexOf("-") + 1),
            status: "done",
          })) ?? [],
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFileList();
  }, [entityId, defaultFileList]);

  return (
    <div className="row-align-start">
      <div className="uploaded-document-container">
        {/* <Row align="bottom" justify="space-between">
          <Col>
            {description && <h4>{description}</h4>}
            {!readOnly && (
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />} loading={uploading}>
                  Upload Documents
                </Button>
              </Upload>
            )}
          </Col>
          {extra && <Col>{extra}</Col>}
        </Row> */}

        <Table
          rowKey="id"
          className="min-w-fit my-2"
          columns={fileTableColumns}
          dataSource={fileList}
          loading={loading}
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
        />
      </div>
    </div>
  );
};

export default UploadedAocDocument;
