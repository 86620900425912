import { createSlice } from "@reduxjs/toolkit";
import { getAllRuleParameterThunk } from "../../services/caseAssignmentService/ruleParameterService/ruleParameterThunk";

export type RuleParameter = {
  id: string;
  code: string;
  name: string;
  inputType: string;
  selectionInputValue?: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
};

export type RuleParameterState = {
  ruleParametersState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: RuleParameter[];
  };
};

const initialState: RuleParameterState = {
  ruleParametersState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const ruleParameterSlice = createSlice({
  name: "ruleParameter",
  initialState,
  reducers: {
    ruleParameterReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRuleParameterThunk.pending, (state) => {
        state.ruleParametersState.isLoading = true;
      })
      .addCase(getAllRuleParameterThunk.fulfilled, (state, action) => {
        state.ruleParametersState.isLoading = false;
        state.ruleParametersState.data = action.payload.data;
      })
      .addCase(getAllRuleParameterThunk.rejected, (state, action) => {
        state.ruleParametersState.isLoading = false;
        state.ruleParametersState.isError = true;
        state.ruleParametersState.errorMessage = action.payload;
      });
  },
});

export const { ruleParameterReset } = ruleParameterSlice.actions;

export default ruleParameterSlice.reducer;
