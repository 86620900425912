export enum CONTACT_PERSON {
  CUSTOMER = "customer",
  EMERGENCY_CONTACT_PERSON = "emergencyContactPerson",
  EMPLOYER = "employer",
  OTHERS = "others",
}

export enum CONTACT_MODE {
  PHONE_NUMBER = "phoneNumber",
  EMAIL = "email",
  SMS = "sms",
  LETTER = "letter",
}
