import "./index.less";

import {
  Button,
  Form,
  Input,
  message,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { ColumnsType } from "antd/es/table";
import { RcFile } from "antd/es/upload";
import startCase from "lodash.startcase";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { InboxOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-components";

import AgreementsTable from "../../../components/AgreementsTable";
import UpdateWaiverAmountModal from "../../../components/UpdateWaiverAmountModal";
import { WAIVER_BATCH_STATUS } from "../../../enum/waiverBatchStatus";
import { collectionStateReset } from "../../../features/collection/collectionSlice";
import {
  waiverBatchNoStateReset,
  waiverRemarkStateReset,
  waiverStateReset,
} from "../../../features/waiver/waiverSlice";
import { numberWithCommas } from "../../../helpers/number";
import { getAccountDetailsThunk } from "../../../services/collectionService/collectionThunk";
import { waiverFileUploadThunk } from "../../../services/fileService/fileThunk";
import {
  createBatchNoThunk,
  createWaiverRecordThunk,
  getAgreementListThunk,
  getBatchNoThunk,
  submitWaiverThunkForApprovalThunk,
} from "../../../services/waiverService/waiverThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { checkAgreement } from "../../../services/waiverService/waiverService";
import { getTaxThunk } from "../../../services/taxService/taxThunk";
import SearchAgreementResult from "../../AccountEnquiry/SearchAgreementResult";
import { searchDisbursedAgreementThunk } from "../../../services/agreementService/agreementThunk";
import { getAgreementByEmailThunk } from "../../../services/aocService/aocThunk";
import AccountEnquiryModal from "../../AccountEnquiry/AccountEnquiryModal";

const { Search } = Input;

const CreateWaiver: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const { batchNo } = useSelector((state: RootState) => state.waiver);
  const { tax } = useSelector((state: RootState) => state.tax);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchAgreementNo = queryParams.get("agreementNo")?.trim();
  const navigate = useNavigate();
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [remarks, setRemarks] = useState("");
  const [waiverRemarks, setWaiverRemarks] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitWaiverBatch, setSubmitWaiverBatchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchResultData, setSearchResultData] = useState<any>([]);
  const [searchResultVisible, setSearchResultVisible] = useState(false);
  const [resultNotFound, setResultNotFound] = useState(false);
  const [chargesResultData, setChargesResultData] = useState([{}]);
  const [creatingBatchNo, setCreatingBatchNo] = useState(false);
  const [generatedBatchNo, setBatchNo] = useState("");
  const [batchNoId, setBatchNoId] = useState("");
  const [totalNoOfAccount, setTotalNoOfAccount] = useState(0);
  const [agreementNo, setAgreementNo] = useState("");
  const [taxCharge, setTax] = useState(0);
  const [agreementData, setAgreementData] = useState<any>([]);
  const [searchType, setSearchType] = useState("agreementNo");
  const [waiverPageLoading, setWaiverPageLoading] = useState(false);
  const [waiverPageVisible, setWaiverPageVisible] = useState(false);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {
        status: WAIVER_BATCH_STATUS.OPEN,
      },
    }),
    [],
  );

  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [filterState, setFilterState] = useState(initialParams.filters);
  const { Dragger } = Upload;

  const uploadProps: UploadProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      fileList.push(file);
      // setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  // const tablePagination = {
  //     total: chargesResultData.length,
  //     showSizeChanger: true,
  //     showTotal: (total: number, range: number[]) =>
  //         `${range[0]}-${range[1]} of ${total} items`,
  //     currentPage: paginationState.current,
  //     pageSize: paginationState.pageSize,
  // };

  const waiverColumns: ColumnsType<any> = [
    {
      title: "NO.",
      key: "index",
      dataIndex: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Agreement No",
      key: "agreementNo",
      dataIndex: "agreementNo",
      render: (_, record) => (
        <AccountEnquiryModal key={record.id} agreementNo={record.agreementNo} />
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, record) => record.status ?? "-",
    },
    {
      title: "Applicant Name",
      key: "applicantFirstName",
      dataIndex: "applicantFirstName",
      render: (_, record) => record?.application?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      key: "applicantNric",
      dataIndex: "applicantNric",
      render: (_, record) => record?.application?.applicantNric ?? "-",
    },
    {
      title: "MIA",
      key: "mia",
      dataIndex: "mia",
      render: (_, record) => record.mia ?? "-",
    },
    {
      title: "Special Tagging",
    },
  ];

  const chargesColumn: ColumnsType<any> = [
    {
      title: "Charges Type",
      dataIndex: "chargesType",
      key: "chargesType",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Amount After Tax",
      dataIndex: "amountTotal",
      key: "amountTotal",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Waiver Amount",
      dataIndex: "waiverAmount",
      key: "waiverAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Waiver Tax",
      dataIndex: "waiverTax",
      key: "waiverTax",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Waiver After Tax",
      dataIndex: "total",
      key: "total",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
    {
      title: "Action",
      dataIndex: "waiverAmount",
      key: "waiverAmount",
      render: (text, record) => (
        <Space size="middle">
          <UpdateWaiverAmountModal
            chargeDetails={record}
            chargesType={record.chargesType}
            waiverAmount={record.waiverAmount}
            chargesArray={chargesResultData}
            tax={tax.data.tax}
            // callback={
            //     setPayload
            // }
          />
        </Space>
      ),
    },
  ];

  const fetchBatchNo = async (params?: any) => {
    await dispatch(getBatchNoThunk(params));
  };

  const fetchTax = async (params?: any) => {
    await dispatch(getTaxThunk(params));
  };

  useEffect(() => {
    fetchTax(initialParams);
    fetchBatchNo({
      ...initialParams,
      ...filterState,
    });
    dispatch(collectionStateReset());
  }, [initialParams]);

  const onSearch = async (value: string) => {
    if (value) {
      setLoading(true);

      await dispatch(
        searchDisbursedAgreementThunk({ [searchType]: value })
      )
        .then((result) => {
          setAgreementData(result.payload);
          if (result.payload && result.payload.length > 0) {
            setSearchResultVisible(true);
          } else {
            message.error("Result Not Found");
            setResultNotFound(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      message.error("Result Not Found");
      dispatch(collectionStateReset());
    }
  };

  const onSubmitToAddWaiverRecord = async () => {
    setSubmitLoading(true);
    setAgreementNo("");
    const charges = chargesResultData.filter((value: any) => {
      return value.total > 0;
    });

    if (charges.length > 0) {
      const payload = {
        charges: chargesResultData.filter((value: any) => {
          return value.total > 0;
        }),
        accountDetailsId: accountEnquiry.data!.accountDetails!.id,
        waiverBatchId: batchNoId,
        remarks: remarks,
        waiverRemarks: waiverRemarks,
        username: localStorage.getItem("username"),
      };
      let entityId = "";
      await dispatch(createWaiverRecordThunk({ payload }))
        .unwrap()
        .then((res) => {
          message.success("Successfully added waiver record");
          setSubmitLoading(false);
          entityId = res.data.id;
        })
        .catch((err) => {
          setSubmitLoading(false);
        });
      const filePayload = {
        entityId: entityId,
        fileType: "WAIVER_CHARGE",
      };

      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          const data = new FormData();
          data.append("entityId", entityId);
          data.append("fileType", "WAIVER_CHARGE");
          data.append("file", fileList[i] as RcFile);

          await dispatch(waiverFileUploadThunk(data));
        }
      }
      await dispatch(getAgreementListThunk(batchNoId));

      setTotalNoOfAccount(1);
      dispatch(collectionStateReset());
      setWaiverRemarks("");
      setRemarks("");
      setFileList([]);
    } else {
      message.error("Please add the amount to waive");
      setSubmitLoading(false);
    }
  };

  const onSubmitWaiverForApproval = async () => {
    setSubmitWaiverBatchLoading(true);

    const payload = {
      waiverId: batchNoId,
      remarks: waiverRemarks,
      username: localStorage.getItem("username"),
    };

    await dispatch(submitWaiverThunkForApprovalThunk({ payload }))
      .unwrap()
      .then(() => {
        message.success(
          `Submitted waiver batch ${generatedBatchNo} for approval`,
        );
        setSubmitWaiverBatchLoading(false);
        dispatch(waiverBatchNoStateReset());
        dispatch(waiverRemarkStateReset());
        dispatch(waiverStateReset());
        setRemarks("");
        setWaiverRemarks("");
        setSearchResultData([]);
        setChargesResultData([]);
        setBatchNo("");
        setBatchNoId("");

        setTotalNoOfAccount(totalNoOfAccount - 1);
        setAgreementNo("");
        setAgreementData([]);
        // navigate(`/waiver/waiver-listing`);
      })
      .catch(() => {
        message.error("Failed to submit waiver for approval");
        setSubmitWaiverBatchLoading(false);
      });
  };

  const handleSearchResultCancel = () => {
    setSearchResultVisible(false);
  };

  const createBatchNo = async (params?: any) => {
    setCreatingBatchNo(true);
    const username = localStorage.getItem("username");
    await dispatch(createBatchNoThunk({ ...params, username }))
      .unwrap()
      .then(async (res) => {
        message.success("Created Batch No");
        setBatchNo(res.data.batchNo);
        setBatchNoId(res.data.id);
        setTotalNoOfAccount(res.data.totalNoOfAccount);
        await dispatch(
          getBatchNoThunk({
            ...initialParams,
          }),
        );

        await dispatch(getAgreementListThunk(res.data.id));
      })
      .finally(() => setCreatingBatchNo(false));
  };

  const handleChange = async (value: any, label: any) => {
    setBatchNo(label.label);
    setBatchNoId(label.value);
    const data = Object.entries(batchNo.data.data).filter(
      ([, value]) => value.batchNo === label.label,
    );

    setTotalNoOfAccount(data[0][1].totalNoOfAccount);

    await dispatch(getAgreementListThunk(label.value));
  };

  const handleSearchResultSelect = async (selectedData: any) => {
    setSearchResultVisible(false);
    console.log(selectedData);

    const isAgreementApproved = await checkAgreement(selectedData);
    if (!isAgreementApproved) {
      message.error(
        `This agreement: ${selectedData} is still in progress under Waiver/Add-On Charges, please either approve or void it first`,
      );
    } else {
      setWaiverPageLoading(true);
      await dispatch(
        getAccountDetailsThunk({ agreementNo: selectedData }),
      ).then(async (result: any) => {
        console.log(result);
        if (Object.keys(result.payload.agreementDetails).length != 0) {
          const taxToDivided = 1 + tax.data.tax / 100;
          setResultNotFound(false);
          const data = Object.entries(
            result.payload.accountDetails?.accountCharges,
          )
            .filter(([, value]) => typeof value === "number" && value > 0)
            .map(([key, value]) => ({
              key: key,
              chargesType: startCase(key),
              amount:
                key === "latePaymentCharge"
                  ? Number(value)
                  : Number(value) / taxToDivided,
              tax:
                key === "latePaymentCharge"
                  ? 0
                  : Number(value) - Number(value) / taxToDivided,
              amountTotal: Number(value),
              waiverAmount: 0,
              waiverTax: 0,
              total: 0,
            }));
          setChargesResultData(data);
          console.log(data);

          if (data.length === 0) {
            message.error("This agreement does not has any charges to waive");
          } else {
            const arrayData: any = [];
            const agingType = result.payload.accountDetails?.agingType;
            const agreementStatus =
              result.payload.agreementDetails?.agreementStatus;
            const mia = result.payload.accountDetails.mia;
            arrayData.push({
              ...result.payload.agreementDetails,
              agingType,
              status: result.payload.accountDetails?.status,
              mia,
            });
            console.log(arrayData);
            setSearchResultData(arrayData);
            setWaiverPageVisible(true);
            setWaiverPageLoading(false);
          }
        } else {
          message.error("Result Not Found");
          setResultNotFound(true);
          setWaiverPageVisible(false);
          setWaiverPageLoading(false);
          dispatch(collectionStateReset());
        }
      });
    }
  };

  useEffect(() => {
    // check the queryParams got pass in agreementNo or not

    if (searchAgreementNo) {
      if (batchNo.data.data.length === 0) {
        createBatchNo();
        handleSearchResultSelect(searchAgreementNo);
      } else {
        setBatchNo(batchNo.data?.data[0]?.batchNo);
        setBatchNoId(batchNo.data.data[0].id);
        setTotalNoOfAccount(batchNo.data.data[0].totalNoOfAccount);
        handleSearchResultSelect(searchAgreementNo);
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Create Waiver - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Create Waiver Batch">
        Please select a batch No
      </PageHeader>

      {batchNo.data.total > 0 ? (
        <>
          <div className="flex justify-between mb-4">
            <Input.Group compact>
              <Select
                placeholder="Select batch No"
                style={{ width: 200 }}
                onChange={handleChange}
                defaultValue={searchAgreementNo && batchNo.data.data[0].batchNo}
                options={batchNo.data.data.map((item) => ({
                  value: item.id,
                  label: item.batchNo,
                }))}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Input.Group>
            <Button
              loading={creatingBatchNo}
              onClick={() => createBatchNo()}
              type="primary"
            >
              Create Batch No
            </Button>
          </div>
        </>
      ) : (
        <Button
          loading={creatingBatchNo}
          onClick={() => createBatchNo()}
          type="primary"
        >
          Create Batch No
        </Button>
      )}

      {searchResultVisible && (
        <SearchAgreementResult
          visible={searchResultVisible}
          data={agreementData}
          onOk={handleSearchResultSelect}
          onCancel={handleSearchResultCancel}
        />
      )}

      <br />
      {generatedBatchNo !== "" && (
        <>
          <h3 className="waiver-details-section-title">
            Batch No : <Tag>{generatedBatchNo}</Tag>
          </h3>
          <Input.Group compact>
            <Select
              defaultValue={searchFiled}
              style={{ width: 200 }}
              value={searchType}
              onChange={(value) => {
                setSearchType(value);
                setResultNotFound(false);
              }}
              options={[
                { value: "agreementNo", label: "Agreement No" },
                { value: "applicantNric", label: "Applicant NRIC" },
                { value: "applicantFirstName", label: "Applicant Name" },
                { value: "applicantPhoneNo", label: "Phone No" },
                { value: "applicantEmail", label: "Email" },
              ]}
            />
            <Search
              placeholder="search"
              status={resultNotFound ? "error" : ""}
              onSearch={onSearch}
              defaultValue={searchAgreementNo ?? ""}
              // value={agreementNo}
              loading={loading}
              onChange={(e) => setAgreementNo(e.target.value)}
              style={{ width: 200 }}
            />
          </Input.Group>
          <br />
        </>
      )}

      {waiverPageLoading && (
        <>
          <Skeleton />
        </>
      )}

      {waiverPageVisible && (
        <>
          <div className="create-waiver-section-container">
            <div className="info-container">
              <Table
                rowKey="id"
                className="min-w-fit"
                columns={waiverColumns}
                dataSource={searchResultData}
                //   pagination={tablePagination}
                //   loading={waiver.isLoading}
                //   onChange={handleTableChange}
              />
            </div>
          </div>

          <br />
          {chargesResultData.length > 0 && (
            <>
              <div className="waiver-details-section-container">
                <h3 className="waiver-details-section-title">Select Charges</h3>
                <div className="info-container">
                  <Table
                    rowKey="id"
                    className="min-w-fit"
                    columns={chargesColumn}
                    //   pagination={tablePagination}
                    dataSource={chargesResultData}
                    //   loading={waiver.isLoading}
                  />
                </div>
              </div>

              <br />

              <div className="waiver-details-section-container">
                <div className="info-container">
                  <h3>Add Remarks for Agreement No</h3>
                  <TextArea
                    rows={4}
                    placeholder="Remarks"
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>
              </div>
              <br />

              <h3 className="waiver-details-section-title">Waiver File</h3>
              <Form.Item>
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>

              <br />
              <div className="waiver-details-section-container">
                <div className="info-container">
                  <h3>Add Remarks for Waiver Batch</h3>
                  <TextArea
                    rows={4}
                    placeholder="Remarks"
                    defaultValue={waiverRemarks}
                    onChange={(e) => setWaiverRemarks(e.target.value)}
                  />
                </div>
              </div>

              <br />
              <div className="flex flex-wrap justify-end mb-8 gap-1">
                <Button
                  type="primary"
                  size="large"
                  loading={submitLoading}
                  onClick={onSubmitToAddWaiverRecord}
                >
                  Save to Batch
                </Button>
              </div>
              <br />
            </>
          )}
        </>
      )}
      {batchNoId && (
        <>
          <div className="waiver-details-section-container">
            <h3 className="waiver-details-section-title">List of Agreements</h3>
            <div className="info-container">
              <AgreementsTable value={batchNoId} />
            </div>
          </div>
          <br />

          {totalNoOfAccount > 0 && (
            <div className="flex flex-wrap justify-end mb-8 gap-1">
              <Button
                type="primary"
                size="large"
                loading={submitWaiverBatch}
                onClick={onSubmitWaiverForApproval}
              >
                Submit
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreateWaiver;
