import { createSlice } from "@reduxjs/toolkit";

import {
  getAccountDetailsByIdThunk,
  getAccountDetailsCollectionSystemLogThunk,
  getAccountDetailsThunk,
  getAccountEmailHistoryThunk,
  getAccountSmsHistoryThunk,
  getAccountStatementThunk,
  getAccountTenureDetailsThunk,
  getBPListingThunk,
  getCollectionActivitiesFixTotalThunk,
  getCollectionHistoryLogThunk,
  getConsolidatedCreditNoteListThunk,
  getConsolidatedEInvoiceListThunk,
  getDueAccountTenureDetailsThunk,
  getEInvoiceTotalThunk,
  getIndividualCreditNoteListThunk,
  getMIA1ListingThunk,
  getMIA2ListingThunk,
  getMIA3ListingThunk,
  getNPLListingThunk,
  getPTPListingThunk,
  getRefundChargeConfigThunk,
  getSelfBilledEInvoiceListThunk,
  getSelfBilledEInvoiceSubmissionHistoryLogThunk,
  getTaggingHistoryByAccountDetailsIdThunk,
} from "../../services/collectionService/collectionThunk";
import {
  Application,
  ApplicationListing,
} from "../application/applicationSlice";
import { SmeCompanyDirector, SmeDirectorType } from "../smeApplication/smeApplicationDirectorsSlice";

export interface AccountEnquiryResponse {
  accountDetails?: AccountDetails;
  agreementDetails?: AgreementDetails;
  tenureDetails?: TenureDetails[];
  totalTenure?: TotalTenureDetails[];
}

export interface CollectionListingResponse {
  total?: number;
  fixTotal?: number;
  currentPage: number;
  pageSize: number;
  data?: AccountDetails[];
}

export interface CollectionListingPTPBPResponse {
  total?: number;
  currentPage: number;
  pageSize: number;
  data?: CollectionActivityPTPBP[];
  fixTotal?: number;
}

export interface TaggingHistory {
  id: string;
  taggingName: string;
  createdAt: string;
  description: string;
}

export interface TaggingHistoryResponse {
  total: number;
  currentPage: number;
  pageSize: number;
  data: TaggingHistory[];
}

export interface AccountDetails {
  id: string;
  agreementId?: string;
  agreementNo?: string;
  accountCharges?: AccountCharges;
  mia?: number;
  dpd?: number;
  iia?: number;
  status?: string;
  agingType?: string;
  nplFlag?: boolean;
  paidInstalmentMonth?: number;
  totalInstalmentMonth?: number;
  adminFee?: number;
  lastPaymentDate?: string;
  lastPaymentAmount?: number;
  firstDueDate?: string;
  maturedDate?: string;
  totalPaidInstalment?: number;
  instalmentBalance?: number;
  nextInstalmentDate?: string;
  refundAmount?: number;
  extraPaymentAmount?: number;
  monthlyInstalmentAmount?: number;
  totalDueInstalment?: number;
  billingOutstanding?: number;
  totalLoanOutstanding?: number;
  totalFinanceAmount?: number;
  disbursementAmount?: number;
  totalInterestAmount?: number;
  lastInstalmentAmount?: number;
  currentMonthInsBalance?: number;
  stampingDutyFee?: number;
  accountChargesId?: string;
  currentTenureNo?: number;
  totalPayableAmount?: number;
  createdAt?: string;
  updatedAt?: string;
  specialTaggingManual?: string;
  specialTaggingAuto?: string;
  applicantFirstName?: string;
  applicantNric?: string;
  applicantEmail?: string;
  applicantPhoneNo?: string;
  applicantPartnerId?: string;
  applicantAddress1?: string;
  applicantAddress2?: string;
  applicantAddress3?: string;
  applicantCity?: string;
  applicantState?: string;
  applicantPostcode?: string;
  applicantBankName?: string;
  applicantPersonalBankNo?: string;
  applicantRace?: string;
  actualMonthlySalary?: string;
  employerName?: string;
  employerContactNo?: string;
  employmentTypeId?: string;
  employmentType?: string;
  employmentStartingFrom?: string;
  jobTitle?: string;
  emergencyContactName?: string;
  emergencyContactNo?: string;
  emergencyContactPersonEmail?: string;
  applicantEmergencyContactRelationship?: string;
  secondEmergencyContactName?: string;
  secondEmergencyContactNo?: string;
  secondEmergencyContactPersonEmail?: string;
  applicantSecondEmergencyContactRelationship?: string;
  CollectionActivities?: CollectionActivity;
  ptpDate?: string;
  user?: {
    iScore?: string;
  };
  lastPaymentChannel?: string;
  totalCharges?: number;
  principalOutstanding?: number;
  interestOutstanding?: number;
  actionCode?: string;
  latestActionCode?: string;
  latestActionCodeUpdatedDate?: string;
  latestActionCodeCreatedBy?: string;
  latestPtpDate?: string;
  paymentMethod?: string;
  organizationType?: string;
  smeApplicationId?: string;
  smeApplication?: SmeApplication;
  smeDirector?: SmeDirectorType[];
}

export interface SmeApplication {
  id?: string;
  redOneUsername?: string;
  oldAgreementNo?: string;
  signDate?: string;
  personalBankName?: string;
  personalBankAccountName?: string;
  personalBankAccountNo?: string;
  name?: string;
  nric?: string;
  icType?: string;
  dateOfBirth?: string;
  nationality?: string;
  occupationType?: string;
  employerName?: string;
  emailAddress?: string;
  homeAddress1: string;
  homeAddress2?: string;
  homeAddress3?: string;
  homePostcode?: string;
  homeCity?: string;
  homeState?: string;
  nricAddress1: string;
  nricAddress2?: string;
  nricAddress3?: string;
  nricPostcode?: string;
  nricCity?: string;
  nricState?: string;
  mailingAddress1: string;
  mailingAddress2?: string;
  mailingAddress3?: string;
  mailingPostcode?: string;
  mailingCity?: string;
  mailingState?: string;
  applicantPhoneNo?: string;
  applicantRace?: string;
  applicantEmploymentType?: string;
  emergencyContactPersonName?: string;
  emergencyContactPersonPhoneNo?: string;
  emergencyContactEmail?: string;
  emergencyContactRelationship?: string;
  createdAt?: string;
  updatedAt?: string;
  shareholdingStatus?: string;
  companyName?: string;
  companyEmail?: string;
  businessOldRegistrationNo?: string;
  businessNewRegistrationNo?: string;
  natureOfBusiness?: string;
  businessContactNo?: string;
  businessAuthorisedAddress1?: string;
  businessAuthorisedAddress2?: string;
  businessAuthorisedAddress3?: string;
  businessAuthorisedPostcode?: string;
  businessAuthorisedCity?: string;
  businessAuthorisedState?: string;
  registeredSsmAddress1?: string;
  registeredSsmAddress2?: string;
  registeredSsmAddress3?: string;
  registeredSsmPostcode?: string;
  registeredSsmCity?: string;
  registeredSsmState?: string;
  businessAddress1?: string;
  businessAddress2?: string;
  businessAddress3?: string;
  businessPostcode?: string;
  businessCity?: string;
  businessState?: string;
  countryOfIncorporation?: string;
  nameOfSeniorManagement?: string;
  bankName?: string;
  bankAccountName?: string;
  bankAccountNo?: string;
}

export interface AccountDetailsPTPBP {
  id: string;
  agreementId?: string;
  agreementNo?: string;
  accountCharges?: AccountCharges;
  mia?: number;
  dpd?: number;
  iia?: number;
  agingType?: string;
  nplFlag?: boolean;
  paidInstalmentMonth?: number;
  totalInstalmentMonth?: number;
  lastPaymentDate?: string;
  lastPaymentAmount?: number;
  firstDueDate?: string;
  maturedDate?: string;
  totalPaidInstalment?: number;
  instalmentBalance?: number;
  refundAmount?: number;
  extraPaymentAmount?: number;
  monthlyInstalmentAmount?: number;
  totalDueInstalment?: number;
  billingOutstanding?: number;
  totalLoanOutstanding?: number;
  totalFinanceAmount?: number;
  disbursementAmount?: number;
  totalInterestAmount?: number;
  lastInstalmentAmount?: number;
  currentMonthInsBalance?: number;
  stampingDutyFee?: number;
  accountChargesId?: string;
  currentTenureNo?: number;
  totalPayableAmount?: number;
  createdAt?: string;
  updatedAt?: string;
  specialTaggingManual?: string;
  specialTaggingAuto?: string;
  user?: {
    iScore?: string;
  };
}

export interface CollectionActivitiesFixTotal {
  mia1: number;
  mia2: number;
  mia3: number;
  npl: number;
  bp: number;
  ptp: number;
}

export interface AccountCharges {
  id: string;
  accountDetailsId?: string;
  legalCharge?: number;
  bankCharge?: number;
  ecaCharge?: number;
  lodCharge?: number;
  noticesCharge?: number;
  earlySettlementCharge?: number;
  miscCharge?: number;
  refundCharge?: number;
  adminCharge?: number;
  latePaymentCharge?: number;
  fieldCharge?: number;
  storageCharge?: number;
  repoCharge?: number;
  adjustmentCharge?: number;
  reminderLetter1Charge?: number;
  reminderLetter2Charge?: number;
  reminderLetter3Charge?: number;
  finalNoticeCharge?: number;
  thirdPartyCollectCharge?: number;
  towingCharge?: number;
  fourthScheduleCharge?: number;
  fifthScheduleCharge?: number;
  blacklistUpliftCharge?: number;
  createdAt: string;
  updatedAt: string;
}

export interface CollectionActivity {
  id: string;
  actionCodeId: string;
  accountDetailsId: string;
  contactNo: string;
  contactMode: string;
  ptpDate?: string;
  ptpAmount?: string;
  nextCallDate?: string;
  nextCallTime?: string;
  status: string;
  remark: string;
  createdAt?: string;
  updatedAt?: string;
  actionCode?: ActionCode;
}

export interface ActionCode {
  id: string;
  code: string;
  description: string;
}

export interface CollectionActivityPTPBP {
  id: string;
  actionCodeId: string;
  accountDetailsId: string;
  contactNo: string;
  contactMode: string;
  ptpDate?: string;
  ptpAmount?: string;
  nextCallDate?: string;
  nextCallTime?: string;
  status: string;
  remark: string;
  createdAt?: string;
  updatedAt?: string;
  accountDetails?: AccountDetailsPTPBP;
}

export interface AgreementDetails {
  id: string;
  companyId?: string;
  agreementNo?: string;
  agreementStatus?: string;
  loanTenure?: number;
  loanInterestRate?: number;
  firstInstalmentDate?: string;
  lastInstalmentDate?: string;
  monthlyInstalmentDay?: number;
  monthlyInstalmentAmount?: number;
  lastInstalmentAmount?: number;
  loanPayableAmount?: number;
  signDate?: string;
  repaymentOption?: string;
  zipFilePassword?: string;
  applicationId?: string;
  signedAgreementFileId?: null;
  protectedSignedAgreementFileId?: null;
  eStampingCertificateFileId?: null;
  disbursementPostedDate?: null;
  disbrusementStatus?: null;
  disbursementBatchId?: null;
  stampingDate?: null;
  createdAt?: string;
  updatedAt?: string;
  application?: Application;
  directDebitToken?: string;
  cardLastFourDigit?: string;
  cardBrand?: string;
  cardType?: string;
  policyStatus?: string;
  policyNumber?: string;
  policyIssuanceDate?: string;
}

export interface TotalTenureDetails {
  instalmentNo?: number;
  outstandingAmount?: number;
  dueDate?: string;
  instalmentAmount?: number;
  principalAmount?: number;
  interestAmount?: number;
}

export interface TenureDetails {
  instalmentNo?: number;
  outstandingAmount?: number;
}

export interface smsHistory {
  id: string;
  type: string;
  requestDate: string;
  requestor: string;
  deliveryStatus: string;
  sendDate: string;
  smsMessage: string;
}

export interface emailHistory {
  id: string;
  type: string;
  requestDate: string;
  requestor: string;
  deliveryStatus: string;
  sendDate: string;
  emailMessage: string;
}

export interface smsHistoryResponse {
  total: number;
  currentPage: number;
  pageSize: number;
  data: smsHistory[];
}

export interface emailHistoryResponse {
  total: number;
  currentPage: number;
  pageSize: number;
  data: emailHistory[];
}

export interface EInvoiceSubmissionHistory {
  id: string;
  invoiceCodeNumber: string;
  uuid: string;
  invoiceFileId: string;
  createdAt: string;
}

export interface EInvoiceSubmissionHistoryResponse {
  total: number;
  currentPage: number;
  pageSize: number;
  data: EInvoiceSubmissionHistory[];
}

export interface historyLog {
  id: string;
  userId: string;
  description?: string;
  adminId: string;
  accountDetailsId?: string;
  applicationId?: string;
  application?: Application;
  agreementId?: string;
  newValue?: string;
  oldValue?: string;
  createdAt: string;
  updatedAt: string;
}

export interface historyLogResponse {
  total: number;
  currentPage: number;
  pageSize: number;
  data: historyLog[];
}

export interface collectionSystemLog {
  id: string;
  referenceNo?: string;
  prevAmount: number;
  newAmount: number;
  actionType: string;
  createdAt: string;
  updatedAt: string;
  columnName: string;
  fromAction: string;
}

export interface collectionSystemLogResponse {
  total: number;
  searchTotal: number;
  currentPage: number;
  pageSize: number;
  data: collectionSystemLog[];
}

export interface RefundCharge {
  id: string;
  refundCharge: number;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

export interface RefundChargeResponse {
  total: number;
  currentPage: number;
  pageSize: number;
  data: RefundCharge[];
}

export interface EInvoiceTotalResponse {
  total: number;
  consolidate: {
    valid: number;
    invalid: number;
    total: number;
  };
  individual: {
    valid: number;
    invalid: number;
    total: number;
  };
  individualCreditNote: {
    valid: number;
    invalid: number;
    total: number;
  };
  consolidatedCreditNote: {
    valid: number;
    invalid: number;
    total: number;
  };
}

export interface CollectionState {
  accountEnquiry: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: AccountEnquiryResponse;
  };
  accountStatementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: ApplicationListing;
  };
  mia1List: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: CollectionListingResponse;
  };
  mia2List: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: CollectionListingResponse;
  };
  mia3List: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: CollectionListingResponse;
  };

  nplList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: CollectionListingResponse;
  };
  bpList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: CollectionListingPTPBPResponse;
  };
  ptpList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: CollectionListingPTPBPResponse;
  };
  taggingHistory: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: TaggingHistoryResponse;
  };
  smsHistory: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: smsHistoryResponse;
  };
  emailHistory: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: emailHistoryResponse;
  };
  selfBilledEInvoiceSubmissionHistory: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: EInvoiceSubmissionHistoryResponse;
  };
  selfBilledEInvoiceList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: EInvoiceSubmissionHistoryResponse;
  };
  consolidatedEInvoiceList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: EInvoiceSubmissionHistoryResponse;
  };
  individualCreditNoteList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: EInvoiceSubmissionHistoryResponse;
  };
  consolidatedCreditNoteList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: EInvoiceSubmissionHistoryResponse;
  };
  historyLog: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: historyLogResponse;
  };
  collectionSystemLog: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: collectionSystemLogResponse;
  };
  refundChargeConfig: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: RefundChargeResponse;
  };
  eInvoiceTotal: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: EInvoiceTotalResponse;
  };

  collectionActivitiesFixTotal: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: CollectionActivitiesFixTotal;
  };
}

const initialState: CollectionState = {
  accountEnquiry: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      accountDetails: undefined,
      agreementDetails: undefined,
      tenureDetails: undefined,
    },
  },
  accountStatementList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      searchTotal: 0,
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  mia1List: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      fixTotal: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  mia2List: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      fixTotal: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  mia3List: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      fixTotal: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  bpList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      fixTotal: 0,
    },
  },
  ptpList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      fixTotal: 0,
    },
  },
  nplList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      fixTotal: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  taggingHistory: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  smsHistory: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  emailHistory: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  historyLog: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  selfBilledEInvoiceSubmissionHistory: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  selfBilledEInvoiceList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  consolidatedEInvoiceList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  individualCreditNoteList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  consolidatedCreditNoteList: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  collectionSystemLog: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      searchTotal: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },

  refundChargeConfig: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  eInvoiceTotal: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      consolidate: {
        valid: 0,
        invalid: 0,
        total: 0,
      },
      individual: {
        valid: 0,
        invalid: 0,
        total: 0,
      },
      individualCreditNote: {
        valid: 0,
        invalid: 0,
        total: 0,
      },
      consolidatedCreditNote: {
        valid: 0,
        invalid: 0,
        total: 0,
      },
    },
  },

  collectionActivitiesFixTotal: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      mia1: 0,
      mia2: 0,
      mia3: 0,
      npl: 0,
      bp: 0,
      ptp: 0,
    },
  },
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    collectionStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountDetailsThunk.pending, (state) => {
        state.accountEnquiry.isLoading = true;
      })
      .addCase(getAccountDetailsThunk.fulfilled, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.data = action.payload;
      })
      .addCase(getAccountDetailsThunk.rejected, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.isError = true;
        state.accountEnquiry.errorMessage = action.payload;
      });

    builder
      .addCase(getAccountDetailsByIdThunk.pending, (state) => {
        state.accountEnquiry.isLoading = true;
      })
      .addCase(getAccountDetailsByIdThunk.fulfilled, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.data = action.payload;
      })
      .addCase(getAccountDetailsByIdThunk.rejected, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.isError = true;
        state.accountEnquiry.errorMessage = action.payload;
      });

    builder
      .addCase(getAccountStatementThunk.pending, (state) => {
        state.accountStatementList.isLoading = true;
      })
      .addCase(getAccountStatementThunk.fulfilled, (state, action) => {
        state.accountStatementList.isLoading = false;
        state.accountStatementList.data = action.payload;
      })
      .addCase(getAccountStatementThunk.rejected, (state, action) => {
        state.accountStatementList.isLoading = false;
        state.accountStatementList.isError = true;
        state.accountStatementList.errorMessage = action.payload;
      });

    builder
      .addCase(getMIA1ListingThunk.pending, (state) => {
        state.mia1List.isLoading = true;
      })
      .addCase(getMIA1ListingThunk.fulfilled, (state, action) => {
        state.mia1List.isLoading = false;
        state.mia1List.data = action.payload;
      })
      .addCase(getMIA1ListingThunk.rejected, (state, action) => {
        state.mia1List.isLoading = false;
        state.mia1List.isError = true;
        state.mia1List.errorMessage = action.payload;
      });
    builder
      .addCase(getMIA2ListingThunk.pending, (state) => {
        state.mia2List.isLoading = true;
      })
      .addCase(getMIA2ListingThunk.fulfilled, (state, action) => {
        state.mia2List.isLoading = false;
        state.mia2List.data = action.payload;
      })
      .addCase(getMIA2ListingThunk.rejected, (state, action) => {
        state.mia2List.isLoading = false;
        state.mia2List.isError = true;
        state.mia2List.errorMessage = action.payload;
      });
    builder
      .addCase(getMIA3ListingThunk.pending, (state) => {
        state.mia3List.isLoading = true;
      })
      .addCase(getMIA3ListingThunk.fulfilled, (state, action) => {
        state.mia3List.isLoading = false;
        state.mia3List.data = action.payload;
      })
      .addCase(getMIA3ListingThunk.rejected, (state, action) => {
        state.mia3List.isLoading = false;
        state.mia3List.isError = true;
        state.mia3List.errorMessage = action.payload;
      });
    builder
      .addCase(getNPLListingThunk.pending, (state) => {
        state.nplList.isLoading = true;
      })
      .addCase(getNPLListingThunk.fulfilled, (state, action) => {
        state.nplList.isLoading = false;
        state.nplList.data = action.payload;
      })
      .addCase(getNPLListingThunk.rejected, (state, action) => {
        state.nplList.isLoading = false;
        state.nplList.isError = true;
        state.nplList.errorMessage = action.payload;
      });
    builder
      .addCase(getBPListingThunk.pending, (state) => {
        state.bpList.isLoading = true;
      })
      .addCase(getBPListingThunk.fulfilled, (state, action) => {
        state.bpList.isLoading = false;
        state.bpList.data = action.payload;
      })
      .addCase(getBPListingThunk.rejected, (state, action) => {
        state.bpList.isLoading = false;
        state.bpList.isError = true;
        state.bpList.errorMessage = action.payload;
      });
    builder
      .addCase(getPTPListingThunk.pending, (state) => {
        state.ptpList.isLoading = true;
      })
      .addCase(getPTPListingThunk.fulfilled, (state, action) => {
        state.ptpList.isLoading = false;
        state.ptpList.data = action.payload;
      })
      .addCase(getPTPListingThunk.rejected, (state, action) => {
        state.ptpList.isLoading = false;
        state.ptpList.isError = true;
        state.ptpList.errorMessage = action.payload;
      });

    builder
      .addCase(getDueAccountTenureDetailsThunk.pending, (state) => {
        state.accountEnquiry.isLoading = true;
      })
      .addCase(getDueAccountTenureDetailsThunk.fulfilled, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.data.tenureDetails = action.payload?.tenureDetails;
      })
      .addCase(getDueAccountTenureDetailsThunk.rejected, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.isError = true;
        state.accountEnquiry.errorMessage = action.payload;
      });

    builder
      .addCase(getAccountTenureDetailsThunk.pending, (state) => {
        state.accountEnquiry.isLoading = true;
      })
      .addCase(getAccountTenureDetailsThunk.fulfilled, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.data.totalTenure = action.payload?.tenureDetails;
      })
      .addCase(getAccountTenureDetailsThunk.rejected, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.isError = true;
        state.accountEnquiry.errorMessage = action.payload;
      });

    builder
      .addCase(getTaggingHistoryByAccountDetailsIdThunk.pending, (state) => {
        state.taggingHistory.isLoading = true;
      })
      .addCase(
        getTaggingHistoryByAccountDetailsIdThunk.fulfilled,
        (state, action) => {
          state.taggingHistory.isLoading = false;
          state.taggingHistory.data = action.payload;
        },
      )
      .addCase(
        getTaggingHistoryByAccountDetailsIdThunk.rejected,
        (state, action) => {
          state.taggingHistory.isLoading = false;
          state.taggingHistory.isError = true;
          state.taggingHistory.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getAccountSmsHistoryThunk.pending, (state) => {
        state.smsHistory.isLoading = true;
      })
      .addCase(getAccountSmsHistoryThunk.fulfilled, (state, action) => {
        state.smsHistory.isLoading = false;
        state.smsHistory.data = action.payload;
      })
      .addCase(getAccountSmsHistoryThunk.rejected, (state, action) => {
        state.smsHistory.isLoading = false;
        state.smsHistory.isError = true;
        state.smsHistory.errorMessage = action.payload;
      });

    builder
      .addCase(getAccountEmailHistoryThunk.pending, (state) => {
        state.emailHistory.isLoading = true;
      })
      .addCase(getAccountEmailHistoryThunk.fulfilled, (state, action) => {
        state.emailHistory.isLoading = false;
        state.emailHistory.data = action.payload;
      })
      .addCase(getAccountEmailHistoryThunk.rejected, (state, action) => {
        state.emailHistory.isLoading = false;
        state.emailHistory.isError = true;
        state.emailHistory.errorMessage = action.payload;
      });

    builder
      .addCase(getCollectionHistoryLogThunk.pending, (state) => {
        state.historyLog.isLoading = true;
      })
      .addCase(getCollectionHistoryLogThunk.fulfilled, (state, action) => {
        state.historyLog.isLoading = false;
        state.historyLog.data = action.payload;
      })
      .addCase(getCollectionHistoryLogThunk.rejected, (state, action) => {
        state.historyLog.isLoading = false;
        state.historyLog.isError = true;
        state.historyLog.errorMessage = action.payload;
      });

    builder
      .addCase(getAccountDetailsCollectionSystemLogThunk.pending, (state) => {
        state.collectionSystemLog.isLoading = true;
      })
      .addCase(
        getAccountDetailsCollectionSystemLogThunk.fulfilled,
        (state, action) => {
          state.collectionSystemLog.isLoading = false;
          state.collectionSystemLog.data = action.payload;
        },
      )
      .addCase(
        getAccountDetailsCollectionSystemLogThunk.rejected,
        (state, action) => {
          state.collectionSystemLog.isLoading = false;
          state.collectionSystemLog.isError = true;
          state.collectionSystemLog.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getRefundChargeConfigThunk.pending, (state) => {
        state.refundChargeConfig.isLoading = true;
      })
      .addCase(getRefundChargeConfigThunk.fulfilled, (state, action) => {
        state.refundChargeConfig.isLoading = false;
        state.refundChargeConfig.data = action.payload;
      })
      .addCase(getRefundChargeConfigThunk.rejected, (state, action) => {
        state.refundChargeConfig.isLoading = false;
        state.refundChargeConfig.isError = true;
        state.refundChargeConfig.errorMessage = action.payload;
      });

    builder
      .addCase(
        getSelfBilledEInvoiceSubmissionHistoryLogThunk.pending,
        (state) => {
          state.selfBilledEInvoiceSubmissionHistory.isLoading = true;
        },
      )
      .addCase(
        getSelfBilledEInvoiceSubmissionHistoryLogThunk.fulfilled,
        (state, action) => {
          state.selfBilledEInvoiceSubmissionHistory.isLoading = false;
          state.selfBilledEInvoiceSubmissionHistory.data = action.payload;
        },
      )
      .addCase(
        getSelfBilledEInvoiceSubmissionHistoryLogThunk.rejected,
        (state, action) => {
          state.selfBilledEInvoiceSubmissionHistory.isLoading = false;
          state.selfBilledEInvoiceSubmissionHistory.isError = true;
          state.selfBilledEInvoiceSubmissionHistory.errorMessage =
            action.payload;
        },
      );

    builder
      .addCase(getSelfBilledEInvoiceListThunk.pending, (state) => {
        state.selfBilledEInvoiceList.isLoading = true;
      })
      .addCase(getSelfBilledEInvoiceListThunk.fulfilled, (state, action) => {
        state.selfBilledEInvoiceList.isLoading = false;
        state.selfBilledEInvoiceList.data = action.payload;
      })
      .addCase(getSelfBilledEInvoiceListThunk.rejected, (state, action) => {
        state.selfBilledEInvoiceList.isLoading = false;
        state.selfBilledEInvoiceList.isError = true;
        state.selfBilledEInvoiceList.errorMessage = action.payload;
      });

    builder
      .addCase(getConsolidatedEInvoiceListThunk.pending, (state) => {
        state.consolidatedEInvoiceList.isLoading = true;
      })
      .addCase(getConsolidatedEInvoiceListThunk.fulfilled, (state, action) => {
        state.consolidatedEInvoiceList.isLoading = false;
        state.consolidatedEInvoiceList.data = action.payload;
      })
      .addCase(getConsolidatedEInvoiceListThunk.rejected, (state, action) => {
        state.consolidatedEInvoiceList.isLoading = false;
        state.consolidatedEInvoiceList.isError = true;
        state.consolidatedEInvoiceList.errorMessage = action.payload;
      });

    builder
      .addCase(getEInvoiceTotalThunk.pending, (state) => {
        state.eInvoiceTotal.isLoading = true;
      })
      .addCase(getEInvoiceTotalThunk.fulfilled, (state, action) => {
        state.eInvoiceTotal.isLoading = false;
        state.eInvoiceTotal.data = action.payload.data;
      })
      .addCase(getEInvoiceTotalThunk.rejected, (state, action) => {
        state.eInvoiceTotal.isLoading = false;
        state.eInvoiceTotal.isError = true;
        state.eInvoiceTotal.errorMessage = action.payload;
      });

    builder
      .addCase(getIndividualCreditNoteListThunk.pending, (state) => {
        state.individualCreditNoteList.isLoading = true;
      })
      .addCase(getIndividualCreditNoteListThunk.fulfilled, (state, action) => {
        state.individualCreditNoteList.isLoading = false;
        state.individualCreditNoteList.data = action.payload;
      })
      .addCase(getIndividualCreditNoteListThunk.rejected, (state, action) => {
        state.individualCreditNoteList.isLoading = false;
        state.individualCreditNoteList.isError = true;
        state.individualCreditNoteList.errorMessage = action.payload;
      });

    builder
      .addCase(getConsolidatedCreditNoteListThunk.pending, (state) => {
        state.consolidatedCreditNoteList.isLoading = true;
      })
      .addCase(
        getConsolidatedCreditNoteListThunk.fulfilled,
        (state, action) => {
          state.consolidatedCreditNoteList.isLoading = false;
          state.consolidatedCreditNoteList.data = action.payload;
        },
      )
      .addCase(getConsolidatedCreditNoteListThunk.rejected, (state, action) => {
        state.consolidatedCreditNoteList.isLoading = false;
        state.consolidatedCreditNoteList.isError = true;
        state.consolidatedCreditNoteList.errorMessage = action.payload;
      });

    builder
      .addCase(getCollectionActivitiesFixTotalThunk.pending, (state) => {
        state.collectionActivitiesFixTotal.isLoading = true;
      })
      .addCase(
        getCollectionActivitiesFixTotalThunk.fulfilled,
        (state, action) => {
          state.collectionActivitiesFixTotal.isLoading = false;
          state.collectionActivitiesFixTotal.data = action.payload;
        },
      )
      .addCase(
        getCollectionActivitiesFixTotalThunk.rejected,
        (state, action) => {
          state.collectionActivitiesFixTotal.isLoading = false;
          state.collectionActivitiesFixTotal.isError = true;
          state.collectionActivitiesFixTotal.errorMessage = action.payload;
        },
      );
  },
});

export const { collectionStateReset } = collectionSlice.actions;

export default collectionSlice.reducer;
