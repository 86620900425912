import { Button, Input, Select, Table } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import { applicationStateReset } from "../../../features/application/applicationSlice";
import { numberWithCommas } from "../../../helpers/number";
import { getApprovedApplicationsThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDateAndTime } from "../../../utils/datetime.util";

import type { Application } from "../../../features/application/applicationSlice";
import type { ColumnsType } from "antd/es/table";
import type { TableProps } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { getAdminsThunk } from "../../../services/adminService/adminThunk";

const { Search } = Input;

const ApplicationApprovedReviewPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { approvedApplicationList } = useSelector(
    (state: RootState) => state.application,
  );
  const { admins } = useSelector((state: RootState) => state.admin);

  const [searchFiled, setSearchFiled] = useState("applicationNo");
  const [searchValue, setSearchValue] = useState("");
  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "submissionDate",
      search: {},
      filters: {},
    }),
    [],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<Application>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: approvedApplicationList.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "Application Date & Time",
      dataIndex: "submissionDate",
      key: "submissionDate",
      sorter: (a, b) => a.submissionDate - b.submissionDate,
      sortOrder:
        sortedInfo.columnKey === "submissionDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Approval Date & Time",

      render: (text, record: any) => {
        return record?.approvalDate
          ? displayDateAndTime(record?.approvalDate)
          : "-";
      },
    },
    {
      title: "Application No.",
      dataIndex: "applicationNo",
      key: "applicationNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/application/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record?.applicantFirstName ?? "-",
    },
    {
      title: "Applicant NRIC",
      render: (_, record) => record?.applicantNric ?? "-",
    },
    {
      title: "Applied Loan Amount",
      dataIndex: "loanApplied",
      key: "loanApplied",
      render: (text) => (text ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Finance Amount",
      key: "financeAmount",
      dataIndex: "financeAmount",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Credit Bucket",
      dataIndex: "bucket",
      key: "bucket",
    },
    ...(process.env.REACT_APP_CASE_ASSIGNMENT_ENABLED === "YES"
      ? [
          {
            title: "Case Assignment",
            key: "caseAssignment",
            children: [
              {
                title: "Bucket",
                dataIndex: ["caseAssignment", "bucket", "bucketName"],
                key: "caseAssignmentBucketName",
                render: (bucketName: any) => {
                  return bucketName ?? "-";
                },
              },
              {
                title: "Group",
                dataIndex: ["caseAssignment", "group", "groupCode"],
                key: "caseAssignmentBucketName",
                render: (groupCode: any) => {
                  return groupCode ?? "-";
                },
              },
              {
                title: "User",
                dataIndex: ["caseAssignment", "user", "userId"],
                key: "caseAssignmentUserId",
                render: (adminId: string) => {
                  const admin = admins.data.find(
                    (admin) => admin.id === adminId,
                  );
                  return admin?.username ?? "-";
                },
              },
            ],
          },
        ]
      : []),
  ];

  const fetchApplications = async (params?: any) => {
    await dispatch(getApprovedApplicationsThunk(params));
  };

  const fetchAdmins = async (params?: any) => {
    await dispatch(getAdminsThunk({ params }));
  };

  const resetApplicationState = async () => {
    await dispatch(applicationStateReset());
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value);
      fetchApplications({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value,
        },
      });
    } else {
      fetchApplications({
        ...initialParams,
        filters: { ...filterState },
      });
    }

    setFilterState({ ...initialParams.filters });
  };

  const handleTableChange: TableProps<Application>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
            search: {
              [searchFiled]: searchValue,
            },
          };

    fetchApplications(param);
    setSortedInfo(sorter as SorterResult<Application>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useLifecycles(
    () => {
      resetApplicationState();
      fetchApplications(initialParams);
      fetchAdmins({
        pagination: {
          current: 1,
          pageSize: 10000, // get all admins
        },
      });
    },
    () => {
      resetApplicationState();
    },
  );

  return (
    <>
      <Helmet>
        <title>Application Approved Review - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Application Approved Review " />
      {/* <Radio.Group defaultValue="a" buttonStyle="solid" optionType="button">
        <Radio.Button value="a">All</Radio.Button>
        <Radio.Button value="b">Credit Assessment (Approval)</Radio.Button>
        <Radio.Button value="c">Credit Assessment (KIV Approval)</Radio.Button>
        <Radio.Button value="d">System Rejected</Radio.Button>
      </Radio.Group> */}
      <Input.Group compact>
        <Select
          defaultValue="applicationNo"
          style={{ width: 200 }}
          onChange={(value) => setSearchFiled(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={approvedApplicationList?.data?.data ?? []}
        loading={approvedApplicationList.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ApplicationApprovedReviewPage;
