import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import { ColumnsType } from "antd/es/table";
import { displayDateAndTime } from "../../../../../utils/datetime.util";
import { HistoryLog } from "../../../../../features/application/applicationSlice";
import { Table } from "antd";
import { useEffect } from "react";
import {
  getHistoryLogsBySmeApplicationIdThunk,
} from "../../../../../services/smeApplicationService/smeApplicationThunk";

export const HistoryLogTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { smeApplicationDetails, smeHistoryLogs } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const columns: ColumnsType<HistoryLog> = [
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Old Values",
      dataIndex: "oldValueString",
      key: "oldValueString",
    },
    {
      title: "New Values",
      dataIndex: "newValueString",
      key: "newValueString",
    },
  ];

  const fetchHistoryLogs = () => {
    if (smeApplicationDetails.data?.id) {
      dispatch(
        getHistoryLogsBySmeApplicationIdThunk(smeApplicationDetails.data?.id),
      );
    }
  };

  useEffect(() => {
    fetchHistoryLogs();
  }, [smeApplicationDetails.data?.id, smeApplicationDetails.data?.smeAgreementId]);

  return (
    <Table
      className="min-w-fit"
      columns={columns}
      dataSource={smeHistoryLogs?.data}
      loading={smeHistoryLogs?.isLoading}
      bordered={true}
    />
  );
};