import "./index.less";

import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/es/table";
import startCase from "lodash.startcase";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";

import UploadedAocDocument from "../../../../components/AocFileListModal";
import AocFileModal from "../../../../components/AocFileListModal/AocFileModal";
import InfoListRow from "../../../../components/InfoList/InfoListRow";
import { AOC_BATCH_STATUS } from "../../../../enum/aocBatchStatus";
import { numberWithCommas } from "../../../../helpers/number";
import {
  getAocBatchByIdThunk,
  submitAocForApproveThunk,
  submitAocForPendingThunk,
  submitAocForReferbackThunk,
  submitAocForVoidThunk,
} from "../../../../services/aocService/aocThunk";
import { getWaiverFileByEntityIdThunk } from "../../../../services/fileService/fileThunk";
import { getTaxThunk } from "../../../../services/taxService/taxThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDateAndTime } from "../../../../utils/datetime.util";
import AccountEnquiryModal from "../../../AccountEnquiry/AccountEnquiryModal";

const { TextArea } = Input;

const AddOnChargesBatchDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { batchId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { aocBatchById } = useSelector((state: RootState) => state.aoc);
  const [grandTotal, setGrandTotal] = useState(0);
  const [batchRemarks, setBatchRemarks] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setBatchStatus] = useState("");
  const [fileList, setFileList] = useState([]);
  const { tax } = useSelector((state: RootState) => state.tax);
  const formattedDate = moment().toISOString();

  const chargesTableColumns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Charge Type",
      dataIndex: "type",
      key: "type",
      render: (text) => (text ? startCase(text) : "-"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 180,
      render: (text) => (text ? `RM ${numberWithCommas(text)}` : "-"),
    },
  ];

  const agreementTableColumns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Agreement No",
      render: (_, record) => (
        <AccountEnquiryModal
          key={record.id}
          agreementNo={record.accountdetails.agreementNo}
        />
      ),
    },
    // {
    //   title: "Batch No",
    //   dataIndex: "batchNo",
    //   key: "batchNo",
    //   render: (text, record) => (
    //     <Button
    //       type="link"
    //       onClick={() => navigate(`/batch/details/${record.id}`)}
    //     >
    //       {text}
    //     </Button>
    //   ),
    // }
    {
      title: "Applicant Name",
      render: (text, record) => record.accountdetails.applicantFirstName ?? "-",
    },
    {
      title: "Add-on Charges Amount",
      dataIndex: "aocAmountBeforeTax",
      key: "aocAmountBeforeTax",
      render: (text, record) =>
        `RM ${numberWithCommas(record.aocAmountBeforeTax)}`,
    },
    {
      title: "Tax Amount",
      render: (text, record) =>
        `${(record.aocAmountTax - record.aocAmountBeforeTax).toFixed(2)} `,
    },
    {
      title: "Add-On Charges Amount (After tax)",
      dataIndex: "aocAmountTax",
      key: "aocAmountTax",
      render: (text, record) => `RM ${numberWithCommas(record.aocAmountTax)}`,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Action",
      render: (text, record) => (
        <Button
          onClick={() => {
            navigate(
              `/add-on-charges/edit-aoc-batch/batchId/${aocBatchById.data?.id}/agreementNo/${record.accountdetails.agreementNo}/accountDetailsId/${record.accountdetails.id}/status/${aocBatchById.data?.status}`,
            );
          }}
          type="primary"
        >
          {aocBatchById.data?.status === AOC_BATCH_STATUS.OPEN ||
          aocBatchById.data?.status === AOC_BATCH_STATUS.REFERBACK
            ? "Update"
            : "View"}
        </Button>
      ),
    },
  ];

  const remarksTableColumns: ColumnsType<any> = [
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (text, record) => text ?? "-",
    },
    {
      title: "Created By",
      dataIndex: "username",
      key: "username",
    },
  ];

  const fetchTax = async (params?: any) => {
    await dispatch(getTaxThunk(params));
  };

  const onBack = () => {
    // dispatch(applicationDetailsStateReset());
    // dispatch(agreementStateReset());
    navigate(-1);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const fetchAocBatchDetails = async (batchId: string) => {
    await dispatch(getAocBatchByIdThunk(batchId)).then((res: any) => {
      let total = 0;
      for (const item of res.payload.addOnCharges) {
        total += parseFloat(item.amount);
      }

      setGrandTotal(total);
    });
  };

  // const onFinish = async (values: any) => {
  //   console.log(values);
  // };

  const submitAocForPending = async () => {
    if (aocBatchById.data?.addOnChargesAgreement.length === 0) {
      message.error("Please add at least 1 agreement to batch");
    } else {
      const payload = {
        addOnChargeBatchId: aocBatchById.data?.id,
        remarks: batchRemarks,
        username: localStorage.getItem("username"),
        decidedAt: formattedDate,
      };
      await dispatch(submitAocForPendingThunk(payload))
        .then((res: any) => {
          navigate(`/add-on-charges/listing`);
        })
        .catch(() => {
          message.error(
            `Fail to submit ${aocBatchById.data?.batchNo} for pending`,
          );
        });
    }
  };

  const approveAoc = async (status: AOC_BATCH_STATUS) => {
    if (status === AOC_BATCH_STATUS.APPROVED) {
      const payload = {
        batchId: aocBatchById.data?.id,
        username: localStorage.getItem("username"),
        decidedAt: formattedDate,
      };
      await dispatch(submitAocForApproveThunk(payload))
        .then((res: any) => {
          navigate(`/add-on-charges/listing`);
        })
        .catch(() => {
          message.error(`Fail to approve${aocBatchById.data?.batchNo}`);
        });
    }
  };

  const showModal = (status: AOC_BATCH_STATUS) => {
    setBatchStatus(status);
    setIsModalOpen(true);
  };

  useLifecycles(
    () => batchId && fetchAocBatchDetails(batchId),
    // () => resetApplicationDetailsState(),
  );

  const fetchFileList = async () => {
    setLoading(true);
    await dispatch(
      getWaiverFileByEntityIdThunk({ entityId: aocBatchById.data?.id }),
    )
      .unwrap()
      .then((res) => {
        setFileList(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTax();
    fetchFileList();
  }, [aocBatchById.data?.id]);

  const onFinish = async () => {
    if (batchRemarks) {
      await form
        .validateFields()
        .then(async (values) => {
          setLoading(true);

          // Callback method
          // if (callback) callback({...payload});

          if (status === AOC_BATCH_STATUS.REFERBACK) {
            message.success("Update aoc batch status to referback");
            const payload = {
              addOnChargeBatchId: aocBatchById.data?.id,
              remarks: batchRemarks,
              username: localStorage.getItem("username"),
              decidedAt: formattedDate,
            };
            await dispatch(submitAocForReferbackThunk(payload))
              .then((res: any) => {
                setIsModalOpen(false);
                navigate(`/add-on-charges/listing`);
              })
              .catch(() => {
                setIsModalOpen(false);
                message.error("Failed to update aoc batch for referback");
              });
          } else if (status === AOC_BATCH_STATUS.VOID) {
            message.success("Update aoc batch status to void");
            const payload = {
              addOnChargeBatchId: aocBatchById.data?.id,
              remarks: batchRemarks,
              username: localStorage.getItem("username"),
              decidedAt: formattedDate,
            };
            await dispatch(submitAocForVoidThunk(payload))
              .then((res: any) => {
                setIsModalOpen(false);
                navigate(`/add-on-charges/listing`);
              })
              .catch(() => {
                setIsModalOpen(false);
                message.error("Failed to update aoc batch for void");
              });
          } else if (status === AOC_BATCH_STATUS.RESUBMIT) {
            message.success("Update aoc batch status to pending");
            const payload = {
              addOnChargeBatchId: aocBatchById.data?.id,
              remarks: batchRemarks,
              username: localStorage.getItem("username"),
              decidedAt: formattedDate,
            };

            await dispatch(submitAocForPendingThunk(payload))
              .then((res: any) => {
                navigate(`/add-on-charges/listing`);
              })
              .catch(() => {
                message.error(
                  `Fail to submit ${aocBatchById.data?.batchNo} for pending`,
                );
              });
          }
        })
        .catch(() => {
          message.error("Failed to update aoc batch status");
        })
        .finally(() => setLoading(false));
    } else {
      message.error("Please enter remarks");
    }
  };

  return (
    <div>
      <Helmet>
        <title>Add On Charges Details - redCASH CEP</title>
      </Helmet>
      <PageHeader className="p-0 mb-4" onBack={onBack} title="Back" />

      {/* {applicationDetails.isLoading ? (
        <>
          <Skeleton active paragraph={{ rows: 9 }} />
          <Skeleton active paragraph={{ rows: 3 }} />
          <Skeleton active paragraph={{ rows: 6 }} />
          <Skeleton active paragraph={{ rows: 4 }} />
        </>
      )
      : applicationDetails.data ? ( */}
      <Space align="center" className="mb-4">
        <h2 className="m-0 font-bold">Add-on Charges Batch Details</h2>
        <Tag>{aocBatchById.data?.status ?? "Status"}</Tag>
      </Space>

      <div className="details-view-section-container">
        <div className="info-container flex flex-col gap-4">
          <InfoListRow
            title="Batch No."
            value={aocBatchById?.data?.batchNo ?? "-"}
          />
          <InfoListRow
            title="Created By"
            value={aocBatchById?.data?.createdById ?? "-"}
          />
          <InfoListRow
            title="Created Date"
            value={
              aocBatchById?.data?.createdAt
                ? displayDateAndTime(aocBatchById?.data?.createdAt)
                : "-"
            }
          />
          <InfoListRow
            title="Approved By"
            value={aocBatchById?.data?.decider ?? "-"}
          />
          <InfoListRow
            title="Approval Date"
            value={
              !aocBatchById?.data?.decidedAt
                ? "-"
                : displayDateAndTime(aocBatchById?.data?.decidedAt)
            }
          />
          {/* <InfoListRow
            title="Total Selected Amount"
            value={
              aocBatchById?.data?.data.totalAocTax
                ? `RM ${numberWithCommas(aocBatchById?.data?.data.totalAocTax)}`
                : "-"
            }
          /> */}
          <InfoListRow
            title="Total Add-on Charges Amount (Before Tax)"
            value={
              aocBatchById?.data?.totalAocAmountBeforeTax
                ? `RM ${numberWithCommas(
                    aocBatchById?.data?.totalAocAmountBeforeTax,
                  )}`
                : "-"
            }
          />
          <InfoListRow
            title="Total Tax"
            value={
              aocBatchById?.data?.totalAocTax
                ? `RM ${numberWithCommas(
                    aocBatchById.data.totalAocTax -
                      aocBatchById?.data?.totalAocAmountBeforeTax,
                  )}`
                : "-"
            }
          />
          <InfoListRow
            title="Total Add-on Charges Amount (After Tax)"
            value={
              aocBatchById?.data?.totalAocAmountBeforeTax
                ? `RM ${numberWithCommas(aocBatchById?.data?.totalAocTax)}`
                : "-"
            }
          />
          {/* <InfoListRow
            title="Add-on Charges Amount (After Tax)"
            value={
              applicationDetails?.data?.bucket
                ? `RM ${numberWithCommas(applicationDetails?.data?.bucket)}`
                : "-"
            }
          /> */}
        </div>
      </div>

      <div className="details-view-section-container">
        <h3 className="details-view-section-title">List of Charges</h3>
        <Table
          pagination={false}
          columns={chargesTableColumns}
          dataSource={aocBatchById?.data?.addOnCharges}
          footer={() => {
            return (
              <Row justify="space-between">
                <Col>
                  <strong>Grand Total</strong>
                </Col>
                <Col className="mr-[100px]">{`RM ${grandTotal.toFixed(
                  2,
                )}`}</Col>
              </Row>
            );
          }}
        />
      </div>

      <div className="details-view-section-container">
        <h3 className="details-view-section-title">List of Agreements</h3>
        <Table
          rowKey="id"
          className="min-w-fit mb-4"
          columns={agreementTableColumns}
          pagination={false}
          dataSource={aocBatchById?.data?.addOnChargesAgreement}
          // loading={agreementList?.isLoading}
        />
      </div>
      {aocBatchById.data?.status === "REFERBACK" && (
        <>
          <div className="section-container">
            <h3 className="details-view-section-title">Support Documents</h3>
            <AocFileModal
              entityId={aocBatchById.data?.id}
              callback={(res: any) => {
                fetchFileList();
              }}
            />
          </div>
        </>
      )}

      {aocBatchById.data?.status === "OPEN" && (
        <>
          <div className="section-container">
            <h3 className="details-view-section-title">Support Documents</h3>
            <AocFileModal
              entityId={aocBatchById.data?.id}
              callback={(res: any) => {
                fetchFileList();
              }}
            />
          </div>
        </>
      )}

      <div className="section-container">
        <h3 className="details-view-section-title">Uploaded Documents</h3>
        <UploadedAocDocument
          status={aocBatchById.data?.status}
          entityId={aocBatchById.data?.id}
          // fileType={FileTypeEnum.OTHERSRELEVANTDOCUMENTS}
          defaultFileList={fileList}
          // callback={fetchFileList}
        />
      </div>

      <br />

      <div className="details-view-section-container">
        <h3 className="details-view-section-title">Remarks History</h3>
        <Table
          rowKey="id"
          className="min-w-fit mb-4"
          columns={remarksTableColumns}
          pagination={false}
          dataSource={aocBatchById?.data?.aocBatchRemarks}
          // loading={agreementList?.isLoading}
        />
      </div>

      <div className="section-container">
        {/* <Row justify="space-between" className="mb-2" align="bottom">
          <Col>
            <h3 className="details-view-section-title mb-0">Remarks</h3>
          </Col>
          <Col>
            <Button>Remark History</Button>
          </Col>
        </Row>
        <Card>
          <h4>Add Remark</h4>
          <TextArea rows={4} />
        </Card> */}
        <br />

        {aocBatchById.data?.status === AOC_BATCH_STATUS.OPEN && (
          <Row justify="end" className="mb-2" align="bottom">
            <Space>
              {/* <Button
                onClick={() => { navigate(`/add-on-charges/editAocBatch/${aocBatchById.data?.id}`) }}
              >
                Update
              </Button> */}
              <Button type="primary" onClick={submitAocForPending}>
                Submit
              </Button>
            </Space>
          </Row>
        )}

        {aocBatchById.data?.status === AOC_BATCH_STATUS.PENDING && (
          <Row justify="end" className="mb-2" align="bottom">
            <Space>
              <Button
                onClick={() => {
                  showModal(AOC_BATCH_STATUS.REFERBACK);
                }}
              >
                Referback
              </Button>
              <Button
                onClick={() => {
                  showModal(AOC_BATCH_STATUS.VOID);
                }}
              >
                Void
              </Button>

              <Popconfirm
                title="Approve this aoc batch?"
                onConfirm={() => approveAoc(AOC_BATCH_STATUS.APPROVED)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  // disabled={
                  //     chargeDetails?.applicationStatus ===
                  //     ApplicationStatusEnum.APPROVED ||
                  //     chargeDetails?.applicationStatus ===
                  //     ApplicationStatusEnum.REJECTED
                  // }
                >
                  Approved
                </Button>
              </Popconfirm>
            </Space>
          </Row>
        )}

        {aocBatchById.data?.status === AOC_BATCH_STATUS.REFERBACK && (
          <>
            <Row justify="end" className="mb-2" align="bottom">
              <Button
                type="primary"
                // onClick={submitAocForPending}
                onClick={() => {
                  showModal(AOC_BATCH_STATUS.RESUBMIT);
                }}
              >
                Submit
              </Button>
            </Row>
          </>
        )}
        <Modal
          title="Please enter reason"
          okText="Confirm"
          open={isModalOpen}
          onOk={onFinish}
          onCancel={handleCancel}
          confirmLoading={loading}
          width={360}
          destroyOnClose
          centered
        >
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
            initialValues={{}}
          >
            <Form.Item
              label="Remarks"
              name="remarks"
              rules={[{ required: true, message: "Reason is required" }]}
            >
              <TextArea
                placeholder="Please input"
                style={{ width: "100%" }}
                onChange={(e) => setBatchRemarks(e.target.value)}
                minLength={5}
              />
            </Form.Item>
          </Form>
        </Modal>
        {/* <Space>
          <Button>
            Update
          </Button>

          <Button>
            Submit
          </Button>
        </Space> */}
      </div>
      {/* ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the application does not exist."
        />
      )} */}
    </div>
  );
};

export default AddOnChargesBatchDetails;
