import {
  Button,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "@ant-design/pro-components";

import {
  deletePaymentNoticeConfigThunk,
  getPaymentNoticeConfigThunk,
} from "../../services/paymentNoticeService/paymentNoticeThunk";
import { AppDispatch, RootState } from "../../store/store";

import type { ColumnsType } from "antd/es/table";
import {
  deleteLoanInsuranceByIdThunk,
  getLoanInsuranceListThunk,
} from "../../services/masterService/masterThunk";
import { useNavigate } from "react-router-dom";
import LoanInsuranceConfigModal from "./LoanInsuranceConfigModal";
import { LoanInsurance } from "../../features/loanInsurance/loanInsuranceSlice";

const { Search } = Input;

const LoanInsuranceConfig = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { loanInsuranceState } = useSelector(
    (state: RootState) => state.loanInsurance,
  );
  const [searchType, setSearchType] = useState("name");
  const [loadingSearch, setLoadingSearch] = useState(false);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const handleDelete = (record: any) => {
    dispatch(deletePaymentNoticeConfigThunk({ id: record.id }))
      .unwrap()
      .then(() => dispatch(getPaymentNoticeConfigThunk()))
      .catch(() =>
        message.error(`Error remove payment notice config ${record.DPD}`),
      );
  };

  const onSearch = async (value: string) => {
    if (value) {
      setLoadingSearch(true);

      const requestData = {
        params: {
          pagination: {
            current: 1,
            pageSize: 20,
          },
          sortOrder: "desc",
          sortField: "createdAt",
          search: {
            [searchType]: value,
          },
          filters: {},
        },
      };
      await dispatch(getLoanInsuranceListThunk(requestData))
        .unwrap()
        .then()
        .catch(() => message.error("Error get loan insurance config"))
        .finally(() => setLoadingSearch(false));
    } else {
      dispatch(getLoanInsuranceListThunk(initialParams));
    }
  };

  const deleteLoanInsuranceProduct = async (data: LoanInsurance) => {
    await dispatch(deleteLoanInsuranceByIdThunk({ id: data.id }))
      .unwrap()
      .then(() => {
        message.success("Delete Successfully");
      })
      .catch(() =>
        message.error(`Error delete loan insurance
    product`),
      );

    await dispatch(getLoanInsuranceListThunk(initialParams));
  };

  useEffect(() => {
    dispatch(getLoanInsuranceListThunk(initialParams));
  }, [dispatch]);

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "Option Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Option Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mandatory",
      dataIndex: "mandatory",
      key: "mandatory",
      render: (text, record) => {
        return record.mandatory ? "Yes" : "No";
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text, record) => {
        return record.isActive ? "Active" : "Inactive";
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space>
          {!loanInsuranceState.isLoading && (
            <>
              <Button
                onClick={() => {
                  navigate(`/loan-insurance-config-details/id/${record.id}`);
                }}
              >
                View
              </Button>

              <Button onClick={() => deleteLoanInsuranceProduct(record)}>
                Delete
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Loan Insurance Config - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Loan Insurance Config" />

      <br />
      <div className="flex justify-between mb-4">
        <Input.Group compact>
          <Select
            className="w-[10rem]"
            value={searchType}
            onChange={(value) => {
              setSearchType(value);
            }}
            options={[{ value: "name", label: "Option Name" }]}
          />
          <Search
            className="w-[20rem]"
            placeholder="Input search text"
            enterButton="Search"
            loading={loadingSearch}
            onSearch={onSearch}
          />
        </Input.Group>

        <Row justify="end" className="mb-2" align="bottom">
          <LoanInsuranceConfigModal
            callback={async () => {
              await dispatch(getLoanInsuranceListThunk(initialParams));
            }}
          />
        </Row>
      </div>

      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        dataSource={loanInsuranceState.data?.data}
        loading={loanInsuranceState.isLoading}
        rowClassName={() => "editable-row"}
        pagination={false}
      />
    </>
  );
};
export default LoanInsuranceConfig;
