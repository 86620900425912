import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Steps,
  Table,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllFieldVisitCompanyThunk,
  getFieldVisitCompanyByStateThunk,
  getFieldVisitCompanyThunk,
  getFieldVisitOfficerByPostCodeThunk,
  updateFieldVisitDetailsThunk,
  updateFieldVisitThunk,
} from "../../../../services/fieldVisitService/fieldVisitThunk";
import { AppDispatch, RootState } from "../../../../store/store";

import type { ColumnsType } from "antd/es/table";
import type {
  FieldVisit,
  FieldVisitOfficer,
} from "../../../../features/fieldVisit/fieldVisitSlice";
import { useStepsForm } from "sunflower-antd";
import { STATE_ENUM } from "../../../../enum/state";
import { FIELD_VISIT_STATUS } from "../../../../enum/fieldVisitStatus";
import LoadingSpinner from "../../../../components/LoadingSpinner";

type RequestOfficerVisitModalProps = {
  fieldVisit?: FieldVisit;
  callback?: () => void;
};
const { Step } = Steps;

const RequestOfficerVisitModal: React.FC<RequestOfficerVisitModalProps> = (
  props,
) => {
  const { fieldVisit, callback } = props;

  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [selectedFieldVisitCompany, setFieldVisitCompany] = useState<any>(null);
  const [selectedFieldVisitOfficer, setFieldVisitOfficer] = useState<any>(null);
  const [visitBy, setVisitBy] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const { fieldVisitCompanyState } = useSelector(
    (state: RootState) => state.fieldVisit,
  );
  const { fieldVisitOfficersByPostCodeState } = useSelector(
    (state: RootState) => state.fieldVisit,
  );
  const { fieldVisitOfficerState } = useSelector(
    (state: RootState) => state.fieldVisit,
  );
  const [fieldVisitOfficerList, setFieldVisitOfficerList] = useState<any>([]);
  const { companyState } = useSelector((state: RootState) => state.fieldVisit);

  const stateOptions = Object.values(STATE_ENUM).map((value) => ({
    label: `${value.replace(/[_]/g, " ")}`,
    value,
  }));

  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values: any) {
      const { fieldVisitCompany, fieldVisitOfficer } = values;
      await new Promise((r) => setTimeout(r, 1000));
      return "ok";
    },
    total: 3,
  });

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const stateOnChange = (value: any, label: any) => {
    if (label.value.length > 0) {
      setSelectedState(label.value);
    } else {
      setSelectedState(null);
    }
  };

  const fieldVisitCompanyOnChange = (value: any, label: any) => {
    if (label.value.length > 0) {
      setFieldVisitCompany(label.value);
    } else {
      setFieldVisitCompany(null);
    }
  };

  const fieldVisitOfficerOnChange = (value: any, label: any) => {
    if (label.value.length > 0) {
      setVisitBy(label.label);
      setFieldVisitOfficer(label.value);
    } else {
      setFieldVisitOfficer(null);
    }
  };

  const checkStep = async (dataType: any) => {
    if (dataType == "state") {
      form.setFieldsValue({ state: selectedState });
      await form.validateFields().then(async (values: any) => {
        const { state } = values;
        if (state) {
          await dispatch(
            getFieldVisitCompanyByStateThunk({
              state,
            }),
          );

          setCurrentStep(currentStep + 1);
          gotoStep(currentStep + 1);
        } else {
          message.error("Please select state");
        }
      });
    } else if (dataType == "fieldVisitCompany") {
      form.setFieldsValue({ fieldVisitCompany: selectedFieldVisitCompany });
      await form.validateFields().then(async (values: any) => {
        const { fieldVisitCompany } = values;
        if (fieldVisitCompany) {
          // await dispatch(
          //   getFieldVisitOfficerByPostCodeThunk({
          //     params: initialParams,
          //     postCode: fieldVisit?.postCode,
          //     fieldVisitCompanyId: fieldVisitCompany,
          //   }),
          // );
          await dispatch(
            getFieldVisitCompanyThunk({ id: fieldVisitCompany }),
          ).then((res) => {
            console.log(res.payload.fieldVisitOfficer);
            const fvOfficer = res.payload.fieldVisitOfficer;
            const formattedResult = fvOfficer.filter((res: any) => {
              return res.isActive == true;
            });
            setFieldVisitOfficerList(formattedResult);
            setCurrentStep(currentStep + 1);
            gotoStep(currentStep + 1);
          });
        } else {
          message.error("Please select field visit company");
        }
      });
    } else if (dataType == "fieldVisitOfficer") {
      form.setFieldsValue({ fieldVisitOfficer: selectedFieldVisitOfficer });
      await form.validateFields().then(async (values: any) => {
        const { fieldVisitOfficer } = values;
        if (fieldVisitOfficer) {
          onSelectOfficer(fieldVisitOfficer);
          setCurrentStep(currentStep + 1);
          gotoStep(currentStep + 1);
          setLoading(true);
        } else {
          message.error("Please select field visit officer");
        }
      });
    }
  };

  const prevStep = async (dataType: any) => {
    if (dataType == "fieldVisitCompany") {
      form.setFieldsValue({ fieldVisitCompany: selectedFieldVisitCompany });
      setCurrentStep(currentStep - 1);
      gotoStep(currentStep - 1);
    } else if (dataType == "fieldVisitOfficer") {
      form.setFieldsValue({ fieldVisitOfficer: selectedFieldVisitOfficer });
      setCurrentStep(currentStep - 1);
      gotoStep(currentStep - 1);
    }
  };

  const formList = [
    <>
      <Form.Item
        label="State"
        name="state"
        rules={[
          {
            required: true,
            message: "Please select state",
          },
        ]}
      >
        {" "}
        <Input.Group compact>
          <Select
            placeholder="Select State"
            style={{ width: 500 }}
            onChange={stateOnChange}
            options={stateOptions}
          />
        </Input.Group>
      </Form.Item>
      <Form.Item {...tailLayout}>
        {selectedState && (
          <Space>
            <Button onClick={() => checkStep("state")}>Next</Button>
          </Space>
        )}
      </Form.Item>
    </>,

    <>
      <Form.Item
        label="Field Visit Company"
        name="fieldVisitCompany"
        rules={[
          {
            required: true,
            message: "Please select field visit company",
          },
        ]}
      >
        {" "}
        <Input.Group compact>
          <Select
            placeholder="Select Field Visit Company"
            style={{ width: 500 }}
            onChange={fieldVisitCompanyOnChange}
            options={companyState.data.map((item) => ({
              value: item.fieldVisitCompany?.id,
              label: item.fieldVisitCompany?.companyName,
            }))}
          />
        </Input.Group>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Space>
          <Button onClick={() => prevStep("fieldVisitCompany")}>Prev</Button>
          <Button onClick={() => checkStep("fieldVisitCompany")}>Next</Button>
        </Space>
      </Form.Item>
    </>,

    <>
      <Form.Item
        label="Field Visit Officer"
        name="fieldVisitOfficer"
        rules={[
          {
            required: true,
            message: "Please select field visit officer",
          },
        ]}
      >
        {" "}
        <Input.Group compact>
          <Select
            placeholder="Select Field Visit Officer"
            style={{ width: 500 }}
            onChange={fieldVisitOfficerOnChange}
            options={
              fieldVisitOfficerList.length == 0
                ? []
                : fieldVisitOfficerList.map((item: any) => ({
                    value: item.id,
                    label: `${item.firstName} ${item.lastName}`,
                  }))
            }
          />
        </Input.Group>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Space>
          <>
            <Button onClick={() => prevStep("fieldVisitOfficer")}>Prev</Button>
            <Button
              onClick={() => checkStep("fieldVisitOfficer")}
              loading={loading}
            >
              Submit
            </Button>
          </>
        </Space>
      </Form.Item>
    </>,
  ];

  const columns: ColumnsType<FieldVisitOfficer> = [
    {
      title: "Officer No.",
      dataIndex: "officerNumber",
      width: 150,
      render: (text: string) => text ?? "-",
    },
    {
      title: "Name",
      dataIndex: "firstName",
      render: (text: string) => text ?? "-",
    },
    {
      title: "Action",
      width: 100,
      render: (_, record) => (
        <Button
          onClick={() =>
            record.id
              ? onSelectOfficer(record.id)
              : message.error("Officer not available!")
          }
        >
          Select
        </Button>
      ),
    },
  ];

  const showModal = async () => {
    setIsModalOpen(true);
    // await dispatch(getAllFieldVisitCompanyThunk(initialParams));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    setCurrentStep(0);
    form.resetFields();
  };

  const onSelectOfficer = async (fieldVisitOfficerId: string) => {
    setLoading(true);

    await dispatch(
      updateFieldVisitDetailsThunk({
        id: fieldVisit?.id,
        agreementNo: fieldVisit?.accountDetails?.agreementNo,
        payload: {
          fieldVisitOfficerId,
          status: FIELD_VISIT_STATUS.PENDING,
          visitBy,
          assignedDate: new Date(),
        },
      }),
    )
      .unwrap()
      .then(() => {
        if (callback) callback();
        message.success("Assigned Officer");
        setIsModalOpen(false);
      })
      .catch(() => {
        message.error("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   if (isModalOpen && fieldVisit?.postCode != null) {
  //     dispatch(
  //       getFieldVisitOfficerByPostCodeThunk({
  //         params: initialParams,
  //         postCode: fieldVisit?.postCode,
  //       }),
  //     );
  //   }
  // }, [dispatch, initialParams, isModalOpen, fieldVisit?.postCode]);

  return (
    <>
      <Button onClick={showModal}>Select Officer</Button>
      <Modal
        title="Request Officer Visit"
        okText="Confirm"
        open={isModalOpen}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={800}
        footer={false}
        centered
      >
        <div className="flex gap-2 mt-4 mb-2">
          <h4>Field Visit Number:</h4>
          <b>{fieldVisit?.fieldVisitNo ?? "-"}</b>
        </div>
        {/* <Table
          rowKey="id"
          className="min-w-fit min-h-fit"
          columns={columns}
          dataSource={fieldVisitOfficersByPostCodeState?.data ?? []}
          loading={fieldVisitOfficersByPostCodeState?.isLoading}
        /> */}

        <br />
        <Steps {...stepsProps}>
          <Step title="Select State" />
          <Step title="Select Field Visit Company" />
          <Step title="Select Field Visit Officer" />
        </Steps>

        <br />
        <Form {...layout} {...formProps} style={{ maxWidth: 600 }} form={form}>
          {formList[currentStep]}
        </Form>
        {loading && (
          <>
            <LoadingSpinner />
          </>
        )}
      </Modal>
    </>
  );
};

export default RequestOfficerVisitModal;
