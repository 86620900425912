import "./index.less";

import { Button, Form, InputNumber, message, Modal, Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import {
  LetterType,
  PaymentNoticeConfigType,
  SendMethod,
} from "../../../features/paymentNotice/paymentNoticeConfigSlice";
import {
  createPaymentNoticeConfigThunk,
  updatePaymentNoticeConfigThunk,
} from "../../../services/paymentNoticeService/paymentNoticeThunk";
import { AppDispatch } from "../../../store/store";

const PaymentNoticeConfigModal: React.FC<{
  data?: PaymentNoticeConfigType;
  callback?: () => void;
}> = ({ data, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const updateMode = data != null;

  const labelClassName = "w-[100px]";

  const sendMethodOptions = Object.values(SendMethod).map((value) => ({
    label: `${value.replace(/[_]/g, " ")}`,
    value,
  }));
  const letterTypeOptions = Object.values(LetterType).map((value) => ({
    label: `${value.replace(/[_]/g, " ")}`,
    value,
  }));

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        if (updateMode) {
          dispatch(
            updatePaymentNoticeConfigThunk({
              ...data,
              DPD: values.DPD,
              letterType: values.letterType,
              sendMethod: values.sendMethod,
            }),
          )
            .unwrap()
            .then(() => {
              form.resetFields();
              if (callback) callback();
              message.success("Update Successfully");
              handleCancel();
            })
            .catch(() =>
              message.error(`Error save payment notice config ${values.DPD}`),
            )
            .finally(() => setLoading(false));
        } else {
          dispatch(
            createPaymentNoticeConfigThunk({
              DPD: values.DPD,
              letterType: values.letterType,
              sendMethod: values.sendMethod,
            }),
          )
            .unwrap()
            .then(() => {
              form.resetFields();
              if (callback) callback();
              message.success("Create Successfully");
              handleCancel();
            })
            .catch(() =>
              message.error(
                `Error creating payment notice config ${values.DPD}`,
              ),
            )
            .finally(() => setLoading(false));
        }
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
        setLoading(false);
      });
  };

  return (
    <>
      {updateMode ? (
        <Button icon={<EditOutlined />} onClick={showModal}>
          Update
        </Button>
      ) : (
        <Button icon={<PlusOutlined />} onClick={showModal}>
          New
        </Button>
      )}

      <Modal
        title={
          data != null
            ? "Update Payment Notice Config"
            : "Create Payment Notice Config"
        }
        okText={data != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={450}
      >
        <Form
          className="pt-4 pb-2"
          form={form}
          initialValues={data}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>DPD</span>}
            name="DPD"
            rules={[{ required: true, message: "Please enter DPD." }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Please Input"
              controls={false}
              inputMode="numeric"
              type="number"
            />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Send Method</span>}
            name="sendMethod"
            rules={[
              { required: true, message: "Please select the send method." },
            ]}
          >
            <Select
              placeholder="Please Select"
              allowClear
              options={sendMethodOptions}
            />
          </Form.Item>

          <Form.Item
            className="mb-4"
            label={<span className={labelClassName}>Letter Type</span>}
            name="letterType"
            rules={[
              { required: true, message: "Please select the letter type." },
            ]}
          >
            <Select
              placeholder="Please Select"
              allowClear
              options={letterTypeOptions}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PaymentNoticeConfigModal;
