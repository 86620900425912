import { Input, Table } from "antd";

import type { ColumnsType } from "antd/es/table";
import { Helmet } from "react-helmet-async";
import React from "react";
import { WriteOffCategoryLabel } from "../../../../constant/writeOff";
import { numberWithCommas } from "../../../../helpers/number";

type WriteOffProposalSummaryProps = {
  dataSource: any;
  callback: any;
};

const WriteOffProposalSummary: React.FC<WriteOffProposalSummaryProps> = (
  props,
) => {
  const { dataSource } = props;

  const columns: ColumnsType<any> = [
    {
      title: "Write Off Type",
      dataIndex: "writeOffType",
      key: "writeOffType",
      render: (text) => (text == "CDP" ? "General Bad Debt" : "Other Charges"),
    },
    {
      title: "Write Off Account Category",
      dataIndex: "writeOffCategory",
      key: "writeOffCategory",
      render: (text) =>
        WriteOffCategoryLabel[text as keyof typeof WriteOffCategoryLabel],
    },
    {
      title: "Code",
      dataIndex: "writeOffCategory",
      key: "writeOffCategory",
    },
    {
      title: "No. Account",
      dataIndex: ["_count", "id"],
      key: "numberOfAccount",
    },
    {
      title: "Amount (RM)",
      dataIndex: ["_sum", "writeOffAmount"],
      key: "writeOffAmount",
      render: (text: number) =>
        text != null ? `RM ${numberWithCommas(text)}` : "-",
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={false}
        dataSource={dataSource?.data?.data ?? []}
        loading={dataSource?.isLoading}
      />
    </>
  );
};

export default WriteOffProposalSummary;
