import { Button, Input, Table, TablePaginationConfig } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { waiverStateReset } from "../../../../features/waiver/waiverSlice";
import { getAllWaiverThunk } from "../../../../services/waiverService/waiverThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { displayDate } from "../../../../utils/datetime.util";

import type { ColumnsType } from "antd/es/table";
import { numberWithCommas } from "../../../../helpers/number";

const { Search } = Input;

const AllWaiverListing = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { allWaiverListing } = useSelector((state: RootState) => state.waiver);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [searchFiled, setSearchFiled] = useState("batchNo");

  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: allWaiverListing.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (text: string, record: any, index: number) => index + 1,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => displayDate(record.createdAt),
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
      key: "batchNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/waiver/details/${record.id}`)}
        >
          <span style={{ userSelect: "text", textDecoration: "underline" }}>
            {text}
          </span>
        </Button>
      ),
    },
    {
      title: "Total No. of Account",
      dataIndex: "totalNoOfAccount",
      key: "totalNoOfAccount",
    },
    {
      title: "Total Waiver After Tax (RM)",
      dataIndex: "totalWaiverAfterTax",
      key: "totalWaiverAfterTax",
      render: (_, record) =>
        `RM ${numberWithCommas(record?.totalWaiverAfterTax)}` ?? "-",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
  ];

  const fetchAllWaiverListing = async (params?: any) => {
    await dispatch(getAllWaiverThunk(params));
  };

  const resetWaiverState = () => {
    dispatch(waiverStateReset());
  };

  const onSearch = (value: string) => {
    const filters = {
      ...filterState,
      batchNo: value === "" ? undefined : value,
    };

    const params = {
      ...initialParams,
      filters,
    };
    fetchAllWaiverListing(params);
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchAllWaiverListing({
      ...initialParams,
      pagination,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    fetchAllWaiverListing({
      ...initialParams,
    });
  }, [initialParams]);

  return (
    <>
      <Input.Group compact>
        <Search
          placeholder="Batch No."
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={allWaiverListing?.data?.data}
        loading={allWaiverListing.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AllWaiverListing;
