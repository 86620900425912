import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import earlySettlementService from "./earlySettlementService";

export const getEarlySettlementsThunk = createAsyncThunk(
  "earlySettlement/getEarlySettlements",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getEarlySettlements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getNewEarlySettlementsThunk = createAsyncThunk(
  "earlySettlement/getNewEarlySettlements",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getNewEarlySettlements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getVoidEarlySettlementsThunk = createAsyncThunk(
  "earlySettlement/getVoidEarlySettlements",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getVoidEarlySettlements(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApprovedEarlySettlementsThunk = createAsyncThunk(
  "earlySettlement/getApprovedEarlySettlements",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getApprovedEarlySettlements(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getInProcessEarlySettlementsThunk = createAsyncThunk(
  "earlySettlement/getInProcessEarlySettlements",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getInProcessEarlySettlements(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRunningEarlySettlementThunk = createAsyncThunk(
  "earlySettlement/getRunningEarlySettlement",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getRunningEarlySettlement(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRunningEarlySettlementByAgreementNoThunk = createAsyncThunk(
  "earlySettlement/getRunningEarlySettlementByAgreementNo",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getRunningEarlySettlementByAgreementNo(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEarlySettlementByIdThunk = createAsyncThunk(
  "earlySettlement/getById",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getEarlySettlementById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEarlySettlementEnQuotationThunk = createAsyncThunk(
  "earlySettlement/getEnQuotation",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getEarlySettlementEnQuotation(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEarlySettlementBmQuotationThunk = createAsyncThunk(
  "earlySettlement/getBmQuotation",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getEarlySettlementBmQuotation(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEarlySettlementEnTaxInvoiceThunk = createAsyncThunk(
  "earlySettlement/getEnTaxInvoice",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getEarlySettlementEnTaxInvoice(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEarlySettlementBmTaxInvoiceThunk = createAsyncThunk(
  "earlySettlement/getBmTaxInvoice",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getEarlySettlementBmTaxInvoice(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateEarlySettlementThunk = createAsyncThunk(
  "earlySettlement/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.updateEarlySettlement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEstimateLatePaymentChargeThunk = createAsyncThunk(
  "earlySettlement/getEstimateLatePaymentCharge",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.getEstimateLatePaymentCharge(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const approveEarlySettlementThunk = createAsyncThunk(
  "earlySettlement/approve",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.approveEarlySettlement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const rejectEarlySettlementThunk = createAsyncThunk(
  "earlySettlement/reject",
  async (requestData: any, thunkAPI) => {
    try {
      return await earlySettlementService.rejectEarlySettlement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
