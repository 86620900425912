import "../index.less";

import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../store/store";
import { displayDate } from "../../../utils/datetime.util";

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useEffect, useMemo, useState } from "react";
import { getListingNoticesHistoryThunk } from "../../../services/noticesHistoryService/noticesHistoryThunk";

const NoticeHistory = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const { noticesHistory } = useSelector(
    (state: RootState) => state.noticesHistory,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: noticesHistory?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Issued Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Notices / Letter",
      dataIndex: "notices",
      key: "notices",
      render: (text, record) => {
        return record?.noticesGenerationBatch?.batchNo;
      },
    },
  ];

  const fetchFieldVisitHistory = async (params?: any) => {
    await dispatch(getListingNoticesHistoryThunk({ ...params }));
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchFieldVisitHistory({
      ...initialParams,
      ...filterState,
      pagination,
      accountDetailsID: accountEnquiry.data?.accountDetails?.id,
    });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    if (accountEnquiry.data?.accountDetails?.id) {
      dispatch(
        getListingNoticesHistoryThunk({
          ...initialParams,
          accountDetailsID: accountEnquiry.data?.accountDetails?.id,
        }),
      );
    }
  }, [accountEnquiry.data?.accountDetails?.id]);

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <Table
            columns={columns}
            dataSource={noticesHistory?.data?.data}
            loading={noticesHistory.isLoading}
            pagination={tablePagination}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default NoticeHistory;
