import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { primaryColor } from "../../assets/style/variables";

export type LoadingSpinnerProps = {
  size?: number;
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  const { size } = props;
  return (
    <div className="h-full flex-center">
      <LoadingOutlined style={{ fontSize: size, color: primaryColor }} />
    </div>
  );
};

LoadingSpinner.defaultProps = {
  size: 100,
};

export default LoadingSpinner;
