import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const getAllActionCode = async (requestData: any) => {
  const params = {
    filters: requestData.params?.filters,
    search: requestData.params?.search,
    sortField: requestData.params?.sortField,
    sortOrder: requestData.params?.sortOrder,
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
  };

  const url = `/redCash/api/v1.0/actionCode/getAll`;
  const res = await collectionSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getAllActiveActionCode = async () => {
  const url = `/redCash/api/v1.0/actionCode/getAllActive`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getAvailableActionCode = async (code: string) => {
  console.log(code);
  const url = `/redCash/api/v1.0/actionCode/getAvailableActionCodes/${code}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const createActionCode = async (requestData: any) => {
  const url = `/redCash/api/v1.0/actionCode/create`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updateActionCode = async (requestData: any) => {
  const { actionCodeId, payload } = requestData;
  const url = `/redCash/api/v1.0/actionCode/update/${actionCodeId}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteActionCode = async (requestData: any) => {
  const { actionCodeId } = requestData;
  const url = `/redCash/api/v1.0/actionCode/delete/${actionCodeId}`;
  const res = await collectionSetupAxiosInstance.delete(url);
  return res.data;
};

const updateStatusActionCode = async (requestData: any) => {
  const { actionCodeId, payload } = requestData;
  const url = `/redCash/api/v1.0/actionCode/updateStatus/actionCodeId/${actionCodeId}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const actionCodeService = {
  getAllActionCode,
  createActionCode,
  updateActionCode,
  deleteActionCode,
  getAllActiveActionCode,
  updateStatusActionCode,
  getAvailableActionCode,
};

export default actionCodeService;
