import {
  getWriteOffDetailsByBatchIdThunk,
  getWriteOffRemarkListingByBatchIdThunk,
  getWriteOffSummaryByBatchIdThunk,
} from "../../services/writeOffService/writeOffThunk";

import { createSlice } from "@reduxjs/toolkit";

export type writeOffSummary = {
  id: string;
  writeOffType: string;
  writeOffAccountCategory: string;
  code: string;
  numberOfAccount: number;
  writeOffAmount: number;
};

export type writeOffDetails = {
  id: string;
  lastPaymentDate: string;
  agreementNumber: string;
  code: string;
  writeOffAccountCategory: string;
  financeAmount: number;
  totalLoanOutstanding: number;
  writeOffAmount: number;
  latePaymentCharge: number;
  otherCharges: number;
  principalAmount: number;
  interestAmount: number;
};

export type writeOffRemarkListing = {
  id: string;
  remarks: string;
  writeOffBatchId: string;
  username: string;
  stage: string;
  createdAt: string;
  updatedAt: string;
};

export type writeOffSummaryResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: writeOffSummary[];
};

export type writeOffDetailsResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: writeOffDetails[];
};

export type writeOffState = {
  writeOffSummary: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffSummaryResponse;
  };
  writeOffDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffDetailsResponse;
  };
  writeOffRemarkListing: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: writeOffRemarkListing[];
  };
};

const initialState: writeOffState = {
  writeOffSummary: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  writeOffDetails: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  writeOffRemarkListing: {
    isLoading: false,
    isError: false,
    errorMessage: undefined,
    data: [],
  },
};

export const writeOffProposalSlice = createSlice({
  name: "writeOffProposal",
  initialState,
  reducers: {
    writeOffProposalStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWriteOffSummaryByBatchIdThunk.pending, (state) => {
        state.writeOffSummary.isLoading = true;
      })
      .addCase(getWriteOffSummaryByBatchIdThunk.fulfilled, (state, action) => {
        state.writeOffSummary.isLoading = false;
        state.writeOffSummary.data = action.payload;
      })
      .addCase(getWriteOffSummaryByBatchIdThunk.rejected, (state, action) => {
        state.writeOffSummary.isLoading = false;
        state.writeOffSummary.isError = true;
        state.writeOffSummary.errorMessage = action.payload;
      });

    builder
      .addCase(getWriteOffDetailsByBatchIdThunk.pending, (state) => {
        state.writeOffDetails.isLoading = true;
      })
      .addCase(getWriteOffDetailsByBatchIdThunk.fulfilled, (state, action) => {
        state.writeOffDetails.isLoading = false;
        state.writeOffDetails.data = action.payload;
      })
      .addCase(getWriteOffDetailsByBatchIdThunk.rejected, (state, action) => {
        state.writeOffDetails.isLoading = false;
        state.writeOffDetails.isError = true;
        state.writeOffDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getWriteOffRemarkListingByBatchIdThunk.pending, (state) => {
        state.writeOffRemarkListing.isLoading = true;
      })
      .addCase(
        getWriteOffRemarkListingByBatchIdThunk.fulfilled,
        (state, action) => {
          state.writeOffRemarkListing.isLoading = false;
          state.writeOffRemarkListing.data = action.payload.data;
        },
      )
      .addCase(
        getWriteOffRemarkListingByBatchIdThunk.rejected,
        (state, action) => {
          state.writeOffRemarkListing.isLoading = false;
          state.writeOffRemarkListing.isError = true;
          state.writeOffRemarkListing.errorMessage = action.payload;
        },
      );
  },
});

export const { writeOffProposalStateReset } = writeOffProposalSlice.actions;

export default writeOffProposalSlice.reducer;
