import { AppDispatch, RootState } from "../../../store/store";
import React, { useMemo, useState } from "react";
import { Tabs, Tag } from "antd";
import {
  getAllWriteOffThunk,
  getApprovedWriteOffThunk,
  getInProcessWriteOffThunk,
  getOpenWriteOffThunk,
  getPendingWriteOffThunk,
  getReferbackWriteOffThunk,
  getVoidWriteOffThunk,
} from "../../../services/writeOffService/writeOffThunk";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PageHeader } from "@ant-design/pro-components";
import type { TabsProps } from "antd";
import WriteOffTable from "./WriteOffTable";
import { useLifecycles } from "react-use";
import jwt_decode from "jwt-decode";
import { Role } from "../../../enum/roles";

const WriteOffListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const token = localStorage.getItem("accessToken") ?? "";
  const decode: any = token ? jwt_decode(token) : null;
  const currentRole = decode?.role;

  const {
    allWriteOffListing,
    openWriteOffListing,
    referbackWriteOffListing,
    processingWriteOffListing,
    pendingWriteOffListing,
    voidWriteOffListing,
    approvedWriteOffListing,
  } = useSelector((state: RootState) => state.writeOff);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters:
        currentRole == Role.VERIFIER
          ? {}
          : currentRole == Role.APPROVER
          ? { batchType: "BATCH1" }
          : currentRole == Role.WRITE_OFF_BATCH_TWO_APPROVER
          ? { batchType: "BATCH2" }
          : {},
    }),
    [],
  );

  const fetchAllWriteOff = async (params?: any) => {
    await dispatch(getAllWriteOffThunk(params));
  };
  const fetchOpenWriteOff = async (params?: any) => {
    await dispatch(
      getOpenWriteOffThunk({
        ...params,
        filters: { ...initialParams.filters, status: "NEW" },
      }),
    );
  };

  const fetchPendingWriteOff = async (params?: any) => {
    await dispatch(
      getPendingWriteOffThunk({
        ...params,
        filters: { ...initialParams.filters, status: "PENDING" },
      }),
    );
  };
  const fetchReferbackWriteOff = async (params?: any) => {
    await dispatch(
      getReferbackWriteOffThunk({
        ...params,
        filters: { ...initialParams.filters, status: "REFERBACK" },
      }),
    );
  };
  const fetchVoidWriteOff = async (params?: any) => {
    await dispatch(
      getVoidWriteOffThunk({
        ...params,
        filters: { ...initialParams.filters, status: "VOID" },
      }),
    );
  };
  const fetchApprovedWriteOff = async (params?: any) => {
    await dispatch(
      getApprovedWriteOffThunk({
        ...params,
        filters: { ...initialParams.filters, status: "APPROVED" },
      }),
    );
  };

  const statusMapDict: {
    [key: string]: {
      label: string;
      callback: (params?: any) => Promise<void>;
      data: {
        data: {
          total: number;
        };
      };
    };
  } = {
    all: { label: "ALL", callback: fetchAllWriteOff, data: allWriteOffListing },
    open: {
      label: "Open",
      callback: fetchOpenWriteOff,
      data: openWriteOffListing,
    },
    pending: {
      label: "Pending",
      callback: fetchPendingWriteOff,
      data: pendingWriteOffListing,
    },
    referback: {
      label: "Referback",
      callback: fetchReferbackWriteOff,
      data: referbackWriteOffListing,
    },
    void: {
      label: "Void",
      callback: fetchVoidWriteOff,
      data: voidWriteOffListing,
    },
    approved: {
      label: "Approved",
      callback: fetchApprovedWriteOff,
      data: approvedWriteOffListing,
    },
  };

  const tabItemsMapStatus = (key: string) => {
    const item = statusMapDict[key];
    return {
      key,
      label: (
        <span>
          {item?.label}{" "}
          {item?.data?.data?.total != null && (
            <Tag>{item?.data?.data.total}</Tag>
          )}
        </span>
      ),
      children: (
        <WriteOffTable dataSource={item?.data} callback={item?.callback} />
      ),
    };
  };

  const items: TabsProps["items"] =
    currentRole == Role.APPROVER ||
    currentRole == Role.WRITE_OFF_BATCH_TWO_APPROVER
      ? [tabItemsMapStatus("processing")]
      : [
          tabItemsMapStatus("all"),
          tabItemsMapStatus("open"),
          tabItemsMapStatus("pending"),
          tabItemsMapStatus("referback"),
          tabItemsMapStatus("void"),
          tabItemsMapStatus("approved"),
        ];

  const onTabChange = (key: string) => {
    statusMapDict[key]?.callback(initialParams);
    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    // Get all status data and their total
    Object.entries(statusMapDict).map(([key, value]) => value.callback(params));
  });

  return (
    <>
      <PageHeader className="px-0 mb-2" title="Write Off Listing" />
      <Tabs
        defaultActiveKey={activeTab ?? "all"}
        onChange={(key) => onTabChange(key)}
        items={items}
        destroyInactiveTabPane
      />
    </>
  );
};

export default WriteOffListingPage;
