import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

export const getBankFromMaster = async () => {
  const url = `/redCash/master/api/v1.0/bank/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

export const getProductCodeByCompanyId = async () => {
  const url = `/redCash/master/api/v1.0/product/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url);

  return res.data;
};

// loan insurance
export const getLoanInsuranceList = async (requestData: any) => {
  const { params } = requestData;
  const url = `/redCash/master/api/v1.0/loanInsurance`;
  const res = await masterSetupAxiosInstance.get(url, { params });
  return res.data;
};

export const getLoanInsuranceById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/master/api/v1.0/loanInsurance/id/${id}`;

  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

export const createLoanInsurance = async (requestData: any) => {
  const url = `/redCash/master/api/v1.0/loanInsurance`;
  const res = await masterSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const updateLoanInsuranceById = async (requestData: any) => {
  const { id } = requestData;

  const url = `/redCash/master/api/v1.0/loanInsurance/id/${id}`;
  const res = await masterSetupAxiosInstance.patch(url, requestData);
  return res.data;
};

export const deleteLoanInsuranceById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/master/api/v1.0/loanInsurance/id/${id}`;
  const res = await masterSetupAxiosInstance.delete(url);
  return res.data;
};

// loan insurance plan

export const getLoanInsurancePlanById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/master/api/v1.0/loanInsurancePlan/id/${id}`;

  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

export const createLoanInsurancePlan = async (requestData: any) => {
  const url = `/redCash/master/api/v1.0/loanInsurancePlan`;
  const res = await masterSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const updateLoanInsurancePlanById = async (requestData: any) => {
  const { id } = requestData;

  const url = `/redCash/master/api/v1.0/loanInsurancePlan/id/${id}`;
  const res = await masterSetupAxiosInstance.patch(url, requestData);
  return res.data;
};

export const deleteLoanInsurancePlanById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/master/api/v1.0/loanInsurancePlan/id/${id}`;
  const res = await masterSetupAxiosInstance.delete(url);
  return res.data;
};

// loan insurance coverage
export const getLoanInsuranceCoverageById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/master/api/v1.0/loanInsuranceCoverage/id/${id}`;

  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

export const createLoanInsuranceCoverage = async (requestData: any) => {
  const url = `/redCash/master/api/v1.0/loanInsuranceCoverage`;
  const res = await masterSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const updateLoanInsuranceCoverageById = async (requestData: any) => {
  const { id } = requestData;

  const url = `/redCash/master/api/v1.0/loanInsuranceCoverage/id/${id}`;
  const res = await masterSetupAxiosInstance.patch(url, requestData);
  return res.data;
};

export const deleteLoanInsuranceCoverageById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/master/api/v1.0/loanInsuranceCoverage/id/${id}`;
  const res = await masterSetupAxiosInstance.delete(url);
  return res.data;
};
