import "./index.less";

import { Button, ConfigProvider, Input, message, Select, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { Agreement } from "../../../features/agreement/agreementSlice";
import { numberWithCommas } from "../../../helpers/number";
import {
  createDisbursementBatchThunk,
  getDisbursementBatchListingThunk,
  getPendingDisbursementAgreementsThunk,
} from "../../../services/disbursementService/disbursementTrunk";
import { AppDispatch, RootState } from "../../../store/store";
import { onCalculateNetDisbursementAmount } from "../../../utils/calculation.util";
import { displayDate } from "../../../utils/datetime.util";

import type { TableProps } from "antd/es/table";
import type { ColumnsType } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";

const { Search } = Input;
const useBreakpoint = createBreakpoint();

const PendingDisbursementListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const [selectedPendingDisbursement, setSelectedPendingDisbursement] =
    useState<string[]>([]);

  const { pendingDisbursementAgreements } = useSelector(
    (state: RootState) => state.disbursement,
  );

  const { fetchDisbursementBatchListingParams } = useSelector(
    (state: RootState) => state.fetchParameter,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "signDate",
      search: {},
      filters: {},
    }),
    [],
  );

  const [sortedInfo, setSortedInfo] = useState<SorterResult<Agreement>>({});
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchFiled, setSearchFiled] = useState("agreementNo");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const resetParams = (type: string) => {
    setSearchFiled(type);
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 20;
    setPaginationState(initialParams.pagination);
  };

  const tablePagination = {
    total: pendingDisbursementAgreements?.data?.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const pendingTableColumns: ColumnsType<any> = [
    {
      title: "Agreement Date",
      dataIndex: "signDate",
      key: "signDate",
      sorter: (a, b) => a.signDate - b.signDate,
      sortOrder: sortedInfo.columnKey === "signDate" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Agreement No",
      dataIndex: "agreementNo",
      key: "agreementNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/agreement/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "user",
      key: "user",
      render: (_, record) =>
        record.application?.applicantFirstName
          ? record.application?.applicantFirstName
          : "-",
    },
    {
      title: "Applicant NRIC",
      dataIndex: "user",
      key: "user",
      render: (_, record) =>
        record.application?.applicantNric
          ? record.application?.applicantNric
          : "-",
    },
    {
      title: "Finance Amount",
      key: "financeAmount",
      render: (_, record) =>
        record?.application?.financeAmount != null
          ? `RM ${numberWithCommas(record?.application?.financeAmount)}`
          : "-",
    },
    {
      title: "Disbursement Amount",
      dataIndex: "disbursementAmount",
      key: "disbursementAmount",
      render: (_, record) =>
        `RM ${numberWithCommas(
          onCalculateNetDisbursementAmount(
            record.application?.financeAmount ?? 0,
            record.application?.productProcessingFee ?? 0,
            record.application?.productStampingDutyPercentage ?? 0,
            record.application?.productProcessingFeeToDiscount ?? 0,
            record.application?.loanTotalPremium ?? 0,
            "APPROVED",
          ),
        )}`,
    },
  ];

  const pendingDisbursementSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Agreement[]) => {
      setSelectedPendingDisbursement(
        selectedRowKeys.length > 0 ? `${selectedRowKeys}`.split(",") : [],
      );
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows,
      // );
    },
  };

  const fetchDisbursementAgreements = async (params?: any) => {
    await dispatch(getPendingDisbursementAgreementsThunk({ params }));
  };

  const onSearch = (value: string) => {
    if (value) {
      setSearchValue(value.toUpperCase());
      fetchDisbursementAgreements({
        ...initialParams,
        filters: { ...filterState },
        search: {
          [searchFiled]: value.toUpperCase(),
        },
      });
    } else {
      setSearchValue("");
      fetchDisbursementAgreements({
        ...initialParams,
        filters: { ...filterState },
      });
    }
  };

  const handleTableChange: TableProps<Agreement>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    const param =
      searchValue.length === 0
        ? {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
          }
        : {
            ...initialParams,
            ...filterState,
            pagination,
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
            search: {
              [searchFiled]: searchValue.toUpperCase(),
            },
          };

    fetchDisbursementAgreements(param);

    setSortedInfo(sorter as SorterResult<Agreement>);
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const fetchDisbursementBatchListing = async (params?: any) => {
    await dispatch(getDisbursementBatchListingThunk({ params }));
  };

  const initialCallbackState = {
    fetchDisbursementAgreements,
  };
  const [callbackState] = useState(initialCallbackState);

  const onCreateBatch = () => {
    setIsLoading(true);
    dispatch(
      createDisbursementBatchThunk({
        agreementList: selectedPendingDisbursement,
      }),
    )
      .unwrap()
      .then((result) => {
        fetchDisbursementAgreements(initialParams);
        fetchDisbursementBatchListing(fetchDisbursementBatchListingParams);
        message.success("Batch created successfully");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    callbackState.fetchDisbursementAgreements(initialParams);
  }, [callbackState, initialParams]);

  return (
    <>
      <Helmet>
        <title>Pending Disbursement - redCASH CEP</title>
      </Helmet>
      <Input.Group compact>
        <Select
          defaultValue="agreementNo"
          style={{ width: 160 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No." },
            { value: "agreementNo", label: "Agreement No." },
            { value: "applicantFirstName", label: "Applicant Name" },
            { value: "applicantNric", label: "Applicant NRIC" },
          ]}
        />

        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 240 }}
        />
      </Input.Group>
      <br />
      <ConfigProvider theme={{ token: { colorPrimary: "#00b96b" } }}>
        <Table
          rowKey="id"
          className="min-w-fit"
          columns={pendingTableColumns}
          pagination={tablePagination}
          dataSource={pendingDisbursementAgreements?.data?.data}
          loading={pendingDisbursementAgreements?.isLoading}
          onChange={handleTableChange}
          rowSelection={pendingDisbursementSelection}
        />
        {selectedPendingDisbursement.length > 0 && (
          <div
            className={`
          ${isMobile ? "w-full" : "w-[calc(100%-340px)]"}
          bg-white 
            shadow-multi h-16  px-4 
            fixed bottom-0 right-0 
            flex items-center
            justify-end`}
          >
            <h4 className="text-lg my-0 mr-4">
              {selectedPendingDisbursement.length} Selected
            </h4>
            <ConfigProvider theme={{ token: { colorPrimary: "#e40308" } }}>
              <Button
                type="primary"
                onClick={() => onCreateBatch()}
                loading={isLoading}
              >
                Create batch
              </Button>
            </ConfigProvider>
          </div>
        )}
      </ConfigProvider>
    </>
  );
};

export default PendingDisbursementListingPage;
