import "../index.less";

import { Button, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getFieldVisitHistoriesThunk } from "../../../services/fieldVisitService/fieldVisitThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDate, displayTime } from "../../../utils/datetime.util";

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import RequestFieldVisitModal from "./RequestFieldVisitModal";
import FieldVisitAttachmentModal from "../../../components/FieldVisitFileListModal/FieldVisitAttachmentModal";
import { useLocation, useNavigate } from "react-router-dom";

const CollectionActivity = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const { fieldVisitHistoryListingState } = useSelector(
    (state: RootState) => state.fieldVisit,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 20,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: {},
    }),
    [],
  );

  const [filterState, setFilterState] = useState(initialParams.filters);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const tablePagination = {
    total: fieldVisitHistoryListingState.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    currentPage: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Field Visit No",
      dataIndex: "fieldVisitNo",
      render: (text, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => navigate(`/field-visit/details/${record.id}`)}
            >
              {text}
            </Button>
          </>
        );
      },
    },
    {
      title: "Visited Date",
      dataIndex: "dateVisited",
      key: "dateVisited",
      render: (text: string, record: any) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Arrival Time",
      dataIndex: "arrivalTime",
      key: "arrivalTime",
      render: (text: string, record: any) => (text ? displayTime(text) : "-"),
    },
    {
      title: "Applicant Name",
      dataIndex: "guarantor",
      render: (text: string, record: any) =>
        record.accountDetails.applicantFirstName ?? "-",
    },
    {
      title: "Meet Up Person",
      dataIndex: "meetUpPerson",
      render: (text: string, record: any) => record.meetUpPerson ?? "-",
    },
    {
      title: "History / Remark",
      dataIndex: "remark",
      render: (text: string, record: any) =>
        record.fieldVisitRemark[0].remarks ?? "-",
    },
    {
      title: "Visit By",
      dataIndex: "visitBy",
      render: (text: string, record: any) =>
        `${record.fvOfficer?.firstName ?? "-"} ${
          record.fvOfficer?.lastName ?? "-"
        }`,
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      render: (text: string, record) => (
        <FieldVisitAttachmentModal entityId={record.id} />
      ),
    },
  ];

  const fetchFieldVisitHistory = async (params?: any) => {
    await dispatch(getFieldVisitHistoriesThunk({ ...params }));

    console.log(fieldVisitHistoryListingState);
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    fetchFieldVisitHistory({ ...initialParams, ...filterState, pagination });
    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  useEffect(() => {
    if (accountEnquiry.data?.accountDetails?.agreementId) {
      fetchFieldVisitHistory({
        ...initialParams,
        accountDetailsId: accountEnquiry.data?.accountDetails?.id,
      });
    }
  }, [initialParams, accountEnquiry.data?.accountDetails?.agreementId]);

  return (
    <div className="account-enquiry-container">
      <div className="account-enquiry-section-container">
        <div className="info-container">
          <div className="flex justify-between items-center mb-2">
            {/* <h3 className="mb-0"></h3> */}
            <RequestFieldVisitModal
              accountDetailsId={accountEnquiry.data?.accountDetails?.id}
              callback={() => fetchFieldVisitHistory(initialParams)}
            />
          </div>
          <Table
            columns={columns}
            dataSource={fieldVisitHistoryListingState.data}
            pagination={tablePagination}
            loading={fieldVisitHistoryListingState.isLoading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CollectionActivity;
