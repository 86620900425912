import { createSlice } from "@reduxjs/toolkit";
import { getTaxThunk } from "../../services/taxService/taxThunk";

export type tax = {
  tax: number;
};

export type taxState = {
  tax: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: tax;
  };
};

const initialState: taxState = {
  tax: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      tax: 0,
    },
  },
};

const taxSlice = createSlice({
  name: "tax",
  initialState,
  reducers: {
    getTax: (state) => {
      state.tax.isLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaxThunk.pending, (state) => {
        state.tax.isLoading = true;
      })
      .addCase(getTaxThunk.fulfilled, (state, action) => {
        state.tax.isLoading = false;
        state.tax.data.tax = action.payload.data.tax;
      })
      .addCase(getTaxThunk.rejected, (state, action) => {
        state.tax.isLoading = false;
        state.tax.isError = true;
        state.tax.errorMessage = action.payload;
        state.tax.data.tax = 0;
      });
  },
});

export const { getTax } = taxSlice.actions;

export default taxSlice.reducer;
