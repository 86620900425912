import "./index.less";

import { AppDispatch, RootState } from "../../../store/store";
import { Button, Card, Form, Input, Select, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  createWriteOffConfig,
  getAllWriteOffConfigThunk,
  updateWriteOffConfigThunk,
} from "../../../services/writeOffConfigService/writeOffConfigThunk";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet-async";
import { PageHeader } from "@ant-design/pro-components";
import { getAllActionCodeThunk } from "../../../services/actionCodeService/actionCodeThunk";
import { WriteOffConfigTypeEnum } from "../../../enum/writeOff";

interface writeOffConfigForm {
  [WriteOffConfigTypeEnum.DPD]: string;
  [WriteOffConfigTypeEnum.BATCH_BOUNDARY]: string;
  [WriteOffConfigTypeEnum.DUNNING_ACTION_CODE]: string[];
}

const WriteOffConfigPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();

  const { writeOffConfigListing } = useSelector(
    (state: RootState) => state.writeOffConfig,
  );
  const { actionCodesState } = useSelector(
    (state: RootState) => state.actionCode,
  );

  const handleSubmit = (values: any) => {
    Object.keys(values).map(async (type: string) => {
      // check if type is not dunning action code
      if (type !== "DUNNING_ACTION_CODE") {
        // update config if exist
        const initialArray = writeOffConfigListing.data.data.find(
          (item) => item.configType == type,
        );
        if (initialArray?.configValue != values[type]) {
          dispatch(
            updateWriteOffConfigThunk({
              ...initialArray,
              configValue: values[type],
            }),
          )
            .unwrap()
            .then((res) =>
              message.success("Write off configuration is updated."),
            )
            .catch((error) => message.error("Write off config update failed."));
        }

        // create config if not exist
        if (!initialArray) {
          dispatch(
            createWriteOffConfig({
              configType: type,
              configValue: values[type],
              status: "ACTIVE",
            }),
          )
            .unwrap()
            .then((res) =>
              message.success("Write off configuration is updated."),
            )
            .catch((error) =>
              message.error(`Write off config update for ${type} failed.`),
            );
        }
      } else {
        // check if dunning action code created before
        const allInitialActionCode = writeOffConfigListing.data.data.filter(
          (item) =>
            item.configType == WriteOffConfigTypeEnum.DUNNING_ACTION_CODE,
        );
        // if dunning action code created before, update the status
        if (allInitialActionCode?.length > 0) {
          await Promise.all(
            actionCodesState.data.map(async (item) => {
              const initialArray = writeOffConfigListing.data.data.find(
                (i) => i.configValue == item.code,
              );
              dispatch(
                updateWriteOffConfigThunk({
                  ...initialArray,
                  configType: type,
                  configValue: item.code,
                  status: values[type].includes(item.code)
                    ? "ACTIVE"
                    : "INACTIVE",
                }),
              ).unwrap();
            }),
          )
            .then((res) =>
              message.success("Write off configuration is updated."),
            )
            .catch((error) =>
              message.error(`Write off config update for ${type} failed.`),
            );
        } else {
          // if dunning action code never created before, create the config
          await Promise.all(
            actionCodesState.data.map(async (item) => {
              dispatch(
                createWriteOffConfig({
                  configType: type,
                  configValue: item.code,
                  status: values[type].includes(item.code)
                    ? "ACTIVE"
                    : "INACTIVE",
                }),
              ).unwrap();
            }),
          )
            .then((res) =>
              message.success("Write off configuration is updated."),
            )
            .catch((error) =>
              message.error(`Write off config update for ${type} failed.`),
            );
        }
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 10,
    },
  };

  useEffect(() => {
    dispatch(getAllWriteOffConfigThunk({}))
      .unwrap()
      .catch((error) =>
        message.error("Failed to get write off configuration."),
      );
    dispatch(getAllActionCodeThunk({}));
  }, []);

  useEffect(() => {
    const initialFormValues: writeOffConfigForm = {
      [WriteOffConfigTypeEnum.DPD]: "",
      [WriteOffConfigTypeEnum.BATCH_BOUNDARY]: "",
      [WriteOffConfigTypeEnum.DUNNING_ACTION_CODE]: [],
    };

    Object.keys(initialFormValues).map((type: string) => {
      if (type !== WriteOffConfigTypeEnum.DUNNING_ACTION_CODE) {
        initialFormValues[type as keyof writeOffConfigForm] =
          writeOffConfigListing.data.data.find(
            (item) => item.configType == type,
          )?.configValue ?? ("" as any);
      } else {
        initialFormValues[type as keyof writeOffConfigForm] =
          (writeOffConfigListing.data.data
            .filter(
              (item) => item.configType == type && item.status == "ACTIVE",
            )
            ?.map((item) => item.configValue) as any) ?? [];
      }
    });
    form.setFieldsValue(initialFormValues);
  }, [writeOffConfigListing.data]);

  return (
    <>
      <Helmet>
        <title>Write Off Configuration- redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Write Off Configuration" />
      <Card className="xs={12} md={8} lg={6} xl={4} text-center">
        <Form
          name="writeOffConfig"
          form={form}
          {...formItemLayout}
          onFinish={handleSubmit}
          labelAlign="left"
        >
          <Form.Item name={WriteOffConfigTypeEnum.DPD} label="DPD">
            <Input />
          </Form.Item>

          <Form.Item
            name={WriteOffConfigTypeEnum.BATCH_BOUNDARY}
            label="Batch Boundary"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={WriteOffConfigTypeEnum.DUNNING_ACTION_CODE}
            label="Dunning Action Code"
          >
            <Select
              placeholder="Select action code"
              allowClear
              mode="multiple"
              options={actionCodesState.data.map((item) => ({
                value: item.code,
                label: item.description,
              }))}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default WriteOffConfigPage;
