import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getAttestations = async (requestData: any) => {
  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/attestation/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getPendingAttestations = async (requestData: any) => {
  const params = {
    filters: {
      ...requestData?.filters,
      attestationStatus: false,
      videoAttestation: false,
    },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/attestation/pending/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getRequestedLiveAttestations = async (requestData: any) => {
  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
    sortField: requestData?.sortField ?? "requestedLiveAttestationDate",
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/attestation/live-attestation-request/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await beSetupAxiosInstance.get(url, { params });

  return res.data;
};

const getCompletedLiveAttestations = async (requestData: any) => {
  const params = {
    filters: requestData?.filters,
    search: requestData?.search,
    sortField: requestData?.sortField ?? "requestedLiveAttestationDate",
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/attestation/live-attestation-completed/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await beSetupAxiosInstance.get(url, { params });

  return res.data;
};

const getVideoAttestations = async (requestData: any) => {
  const params = {
    filters: { ...requestData?.filters, videoAttestation: true },
    search: requestData?.search,
    sortField: requestData?.sortField,
    sortOrder: requestData?.sortOrder,
    currentPage: requestData?.pagination.current,
    pageSize: requestData?.pagination.pageSize,
  };

  const url = `/redCash/admin/api/v1.0/attestation/company/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await beSetupAxiosInstance.get(url, { params });
  return res.data;
};

const updateAttestation = async (requestData: any) => {
  const { attestationId, payload, language } = requestData;
  const url = `/redCash/admin/api/v1.0/attestation/status/${attestationId}/${language}`;
  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const attestationService = {
  getAttestations,
  getPendingAttestations,
  getRequestedLiveAttestations,
  getCompletedLiveAttestations,
  getVideoAttestations,
  updateAttestation,
};

export default attestationService;
