import "./index.less";

import { Button, Input, message, Radio, RadioChangeEvent, Select, Table } from "antd";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { AppDispatch, RootState } from "../../../store/store";
import { downloadEInvoiceThunk } from "../../../services/fileService/fileThunk";
import { downloadFile } from "../../../utils/file.util";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { displayDateAndTime } from "../../../utils/datetime.util";
import { getConsolidatedCreditNoteListThunk } from "../../../services/collectionService/collectionThunk";
import { EInvoiceSubmissionStatus } from "../../../enum/eInvoice";
import { numberWithCommas } from "../../../helpers/number";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { generateConsolidatedCreditNoteDetailsReportThunk } from "../../../services/reportService/reportThunk";
import moment from "moment";

const { Search } = Input;

const ConsolidateCreditNotePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const { consolidatedCreditNoteList } = useSelector(
    (state: RootState) => state.collection,
  );

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sortOrder: "desc",
      sortField: "createdAt",
      search: {},
      filters: { status: EInvoiceSubmissionStatus.PENDING },
    }),
    [],
  );
  const [filterState, setFilterState] = useState(initialParams.filters);
  const [type, setType] = useState(queryParams.get("type") ?? "PENDING");
  const [downloading, setDownloading] = useState(false);
  const [downloadingDetails, setDownloadingDetails] = useState(false);
  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );
  const [searchType, setSearchType] = useState("invoiceCodeNumber");
  const [resultNotFound, setResultNotFound] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const tablePagination = {
    total: consolidatedCreditNoteList.data?.total ?? 0,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const fetchPendingInvoice = async (params?: any) => {
    await dispatch(getConsolidatedCreditNoteListThunk({ params }));
  };

  const fetchValidInvoice = async (params?: any) => {
    await dispatch(getConsolidatedCreditNoteListThunk({ params }));
  };

  const fetchInvalidInvoice = async (params?: any) => {
    await dispatch(getConsolidatedCreditNoteListThunk({ params }));
  };

  const fetchCancelledInvoice = async (params?: any) => {
    await dispatch(getConsolidatedCreditNoteListThunk({ params }));
  };

  const onListTypeChange = (e: RadioChangeEvent) => {
    setType(e.target.value);

    setPaginationState({
      current: 1,
      pageSize: 10,
    });

    queryParams.set("pageNum", `1`);
    queryParams.set("pageSize", `10`);
    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });

    const params = {
      ...initialParams,
      filters: { status: e.target.value },
      pagination: {
        current: 1,
        pageSize: 10,
      },
    };

    if (e.target.value === "VALID") {
      fetchValidInvoice(params);
    } else if (e.target.value === "INVALID") {
      fetchInvalidInvoice(params);
    } else if (e.target.value === "PENDING") {
      fetchPendingInvoice(params);
    } else if (e.target.value === "CANCELLED") {
      fetchCancelledInvoice(params);
    }
  };

  const onFileListItemClick = (fileId: string) => {
    if (fileId) {
      window.open(
        `${process.env.REACT_APP_COLLECTION_URL}/redCash/api/v1.0/eInvoice/file/${fileId}`,
        "_blank",
      );
    } else {
      message.error("File not found. Please try again later.");
    }
  };

  const onDownloadCreditNote = async (fileId: string) => {
    setDownloading(true);
    await dispatch(downloadEInvoiceThunk({ fileId }))
      .unwrap()
      .then((res) => {
        console.log(res);
        downloadFile(res, `${res.name}`, "pdf");
      })
      .catch((error) => {
        console.log(error);
        message.error("Failed to download e-invoice. Please try again later.");
      })
      .finally(() => setDownloading(false));
  };

  const onDownloadCreditNoteDetails = async (id: string, documentDate: string) => {
    setDownloadingDetails(true);
    await dispatch(
      generateConsolidatedCreditNoteDetailsReportThunk({ id }),
    )
      .unwrap()
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download",
          `${moment(documentDate).format("YYYY_MM_DD")}_report_Consolidated_Credit_Note_Details.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        setTimeout(function() {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 100);
        message.success("Successfully download report.");
      })
      .catch(() =>
        message.error("Failed to download report. Please try again later."),
      )
      .finally(() => setDownloadingDetails(false));
  };

  const onSearch = async (value: string) => {
    const params = value
      ? {
        ...initialParams,
        filters: { status: type },
        search: { [searchType]: value },
        pagination: {
          current: pageNum ? +pageNum : initialParams.pagination.current,
          pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
        },
      }
      : {
        ...initialParams,
        filters: { status: type },
        pagination: {
          current: pageNum ? +pageNum : initialParams.pagination.current,
          pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
        },
      };

    if (type === "VALID") {
      fetchValidInvoice(params);
    } else if (type === "PENDING") {
      fetchPendingInvoice(params);
    } else if (type === "CANCELLED") {
      fetchCancelledInvoice(params);
    } else {
      fetchInvalidInvoice(params);
    }
  };

  const tableColumns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) =>
        (paginationState.current - 1) * paginationState.pageSize + index + 1,
    },
    {
      title: "Document Code",
      dataIndex: "documentCode",
      key: "documentCode",
      render: (text: string) => (text ? text : "-"),
    },
    {
      title: "Document No",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text: string, record: any) => {
        if(type === "VALID" && text){
          return (
            <Button
              type="link"
              onClick={() => onFileListItemClick(record.invoiceFileId)}
            >
              {text}
            </Button>
          )
        } else {
          return text ?? '-'
        }
      }
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => (text ? displayDateAndTime(text) : "-"),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmountInclusiveTax",
      key: "totalAmount",
      render: (value) => value != null ? `RM ${numberWithCommas(value)}` : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => text ?? "-",
    },
  ];

  const actionColumn: ColumnsType<any> = [
    {
      title: "Action",
      dataIndex: "download",
      key: "download",
      render: (_: any, record: any, index: number) => record.status === "VALID" ? (
        <>
          <Button style={{ marginBottom: "0.5rem" }}
                  onClick={() => onDownloadCreditNote(record.invoiceFileId)}
          >
            Download
          </Button>
          <Button loading={downloadingDetails}
                  onClick={() => onDownloadCreditNoteDetails(record.id, record.createdAt)}
          >
            Details
          </Button>
        </>
      ) : <></>,
    },
  ]

  const columns = type === "VALID" ? [...tableColumns, ...actionColumn] : tableColumns;
  const handleTableChange = async (pagination: TablePaginationConfig) => {
    if (type === "VALID") {
      fetchValidInvoice({
        ...initialParams,
        pagination,
        filters: { status: "VALID" },
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      });
    } else if (type === "INVALID") {
      fetchInvalidInvoice({
        ...initialParams,
        pagination,
        filters: { status: "INVALID" },
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      });
    } else if (type === "PENDING") {
      fetchPendingInvoice({
        ...initialParams,
        pagination,
        filters: { status: "PENDING" },
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      });
    } else if (type === "CANCELLED") {
      fetchCancelledInvoice({
        ...initialParams,
        pagination,
        filters: { status: "CANCELLED" },
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      });
    }

    setPaginationState({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });

    queryParams.set(
      "pageNum",
      `${pagination.current ?? initialParams.pagination.current}`,
    );
    queryParams.set(
      "pageSize",
      `${pagination.pageSize ?? initialParams.pagination.pageSize}`,
    );

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };

    if (type === "INVALID") {
      fetchInvalidInvoice(params);
    } else if (type === "PENDING") {
      fetchPendingInvoice(params);
    } else if (type === "CANCELLED") {
      fetchCancelledInvoice(params);
    } else {
      fetchValidInvoice(params);
    }
  });

  return (
    <>
      <Helmet>
        <title>e-Invoice - redCASH CEP</title>
      </Helmet>
      <Radio.Group onChange={onListTypeChange} defaultValue={type}>
        <Radio.Button value="PENDING">Pending</Radio.Button>
        <Radio.Button value="VALID">Valid</Radio.Button>
        <Radio.Button value="INVALID">Invalid</Radio.Button>
        <Radio.Button value="CANCELLED">Cancelled</Radio.Button>
      </Radio.Group>
      <br /> <br />
      <Input.Group compact>
        <Select
          className="w-[10rem]"
          value={searchType}
          onChange={(value) => {
            setResultNotFound(false);
            setSearchType(value);
          }}
          options={[{ value: "invoiceCodeNumber", label: "Document No" }]}
        />
        <Search
          className="w-[20rem]"
          placeholder="Input search text"
          enterButton="Search"
          loading={loadingSearch}
          onSearch={onSearch}
          status={resultNotFound ? "error" : ""}
          onChange={() => setResultNotFound(false)}
        />
      </Input.Group>
      <br />

      {downloading && (
        <div className="absolute w-full h-full z-50 backdrop-brightness-95">
          <LoadingSpinner />
        </div>
      )}

      <Table
        columns={columns}
        dataSource={consolidatedCreditNoteList.data?.data}
        pagination={tablePagination}
        loading={consolidatedCreditNoteList.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ConsolidateCreditNotePage;
