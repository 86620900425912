import { Button, Input, Select, Table, Tabs, TabsProps, Tag } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { PageHeader } from "@ant-design/pro-components";
import { AppDispatch, RootState } from "../../../store/store";
import {
  getSmeApplicationCountThunk,
  getSmeApplicationsListingThunk,
} from "../../../services/smeApplicationService/smeApplicationThunk";
import Search from "antd/es/input/Search";
import type { ColumnsType, TableProps } from "antd/es/table";
import { SmeApplication } from "../../../features/smeApplication/smeApplicationSlice";
import { displayDate, displayTime } from "../../../utils/datetime.util";
import { numberWithCommas } from "../../../helpers/number";
import { getAdminsThunk } from "../../../services/adminService/adminThunk";
import type { SorterResult } from "antd/es/table/interface";
import type { Application } from "../../../features/application/applicationSlice";

const SmeVerifyingApplicationListingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = queryParams.get("pageNum");
  const pageSize = queryParams.get("pageSize");

  const [sortedInfo, setSortedInfo] = useState<SorterResult<SmeApplication>>({});

  const [searchFiled, setSearchFiled] = useState("applicationNo");
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(queryParams.get("tab") || "PENDING");

  const { smeApplicationCount, smeApplicationsList } = useSelector((state: RootState) => state.smeApplication);
  const { admins } = useSelector((state: RootState) => state.admin);

  const initialParams = useMemo(
    () => ({
      pagination: {
        current: pageNum ? +pageNum : 1,
        pageSize: pageSize ? +pageSize : 20,
      },
      sortOrder: "desc",
      sortField: "submissionDate",
      search: {
        status: activeTab,
        [searchFiled]: searchValue,
      },
      filters: {},
    }),
    [pageNum, pageSize],
  );

  const [paginationState, setPaginationState] = useState(
    initialParams.pagination,
  );

  const fetchAdmins = async (params?: any) => {
    await dispatch(getAdminsThunk({ params }));
  };

  const tablePagination = {
    total: smeApplicationsList.data.total,
    showSizeChanger: true,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    current: paginationState.current,
    pageSize: paginationState.pageSize,
  };

  const resetParams = (type: string) => {
    setSearchFiled(type);
    setSearchValue("");
    initialParams.pagination.current = 1;
    initialParams.pagination.pageSize = 10;
    setPaginationState(initialParams.pagination);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Application Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortOrder:
        sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortOrder:
        sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      render: (text) => (text ? displayTime(text) : "-"),
    },
    {
      title: "Application No.",
      dataIndex: "applicationNo",
      key: "applicationNo",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/sme/application/details/${record.id}`)}
        >
          <span style={{ userSelect: "text" }}>{text}</span>
        </Button>
      ),
    },
    {
      title: "Organization Type",
      dataIndex: "organizationType",
      key: "organizationType",
      render: (text: string) => text ?? "-",
    },
    {
      title: "Applicant Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        record?.smeIndividualApplication?.name ?? record?.smeSolePropApplication?.name ?? record?.smePartnershipApplication?.name ?? record?.smeCompanyApplication?.companyName ?? "-"
      ),
    },
    {
      title: "Applied Loan Amount",
      dataIndex: "appliedLoanAmount",
      key: "appliedLoanAmount",
      render: (text) => (text != null ? `RM ${numberWithCommas(text)}` : "-"),
    },
    {
      title: "Credit Bucket",
      dataIndex: "bucket",
      key: "bucket",
    }
  ];

  const items: TabsProps["items"] = [
    {
      key: "PENDING",
      label: (
        <span>
          Pending{" "}
          {smeApplicationCount?.data?.pending != null && (
            <Tag>{smeApplicationCount.data.pending}</Tag>
          )}
        </span>
      ),
    },
    {
      key: "REFERBACK",
      label: (
        <span>
          Refer Back{" "}
          {smeApplicationCount?.data?.referBack != null && (
            <Tag>{smeApplicationCount.data.referBack}</Tag>
          )}
        </span>
      ),
    },
    {
      key: "REFERBACK_FROM_PENDING_DISBURSEMENT",
      label: (
        <span>
          Refer-back from Pending Disbursement{" "}
          {smeApplicationCount?.data?.referbackFromPendingDisbursement !=
            null && (
              <Tag>
                {smeApplicationCount.data.referbackFromPendingDisbursement}
              </Tag>
            )}
        </span>
      ),
    },
    {
      key: "FOLLOWED_UP",
      label: (
        <span>
          Follow Up{" "}
          {smeApplicationCount?.data?.followUp !=
            null && (
              <Tag>
                {smeApplicationCount.data.followUp}
              </Tag>
            )}
        </span>
      ),
    },
    {
      key: "REJECTED",
      label: (
        <span>
          Rejected{" "}
          {smeApplicationCount?.data?.rejected !=
            null && (
              <Tag>
                {smeApplicationCount.data.rejected}
              </Tag>
            )}
        </span>
      ),
    },
    {
      key: "CANCELED",
      label: (
        <span>
          Cancelled{" "}
          {smeApplicationCount?.data?.canceled !=
            null && (
              <Tag>
                {smeApplicationCount.data.canceled}
              </Tag>
            )}
        </span>
      ),
    },
  ];

  const fetchSmeApplicationCounts = () => {
    dispatch(getSmeApplicationCountThunk());
  }

  const fetchSmeApplicationListing = (newParams?: any) => {
    const params = { ...initialParams, ...newParams };
    dispatch(getSmeApplicationsListingThunk(params));
  };

  const onTabChange = (key: string) => {
    setActiveTab(key);
    fetchSmeApplicationListing({ search: { status: key } });

    queryParams.set("tab", key);
    queryParams.set("pageNum", `${initialParams.pagination.current}`);
    queryParams.set("pageSize", `${initialParams.pagination.pageSize}`);
    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const handleTableChange: TableProps<SmeApplication>["onChange"] = async (
    pagination,
    filters,
    sorter: any,
  ) => {
    fetchSmeApplicationListing({
      pagination,
      sortField: sorter?.columnKey,
      sortOrder: sorter?.order === "ascend" ? "asc" : "desc",
    });

    setPaginationState({
      current: pagination.current ?? initialParams.pagination.current,
      pageSize: pagination.pageSize ?? initialParams.pagination.pageSize,
    });

    setSortedInfo(sorter as SorterResult<SmeApplication>);

    queryParams.set(
      "pageNum",
      `${pagination.current ?? initialParams.pagination.current}`,
    );
    queryParams.set(
      "pageSize",
      `${pagination.pageSize ?? initialParams.pagination.pageSize}`,
    );

    const newSearch = queryParams.toString();

    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  useLifecycles(() => {
    fetchAdmins({
      pagination: {
        current: 1,
        pageSize: 10000, // get all admins
      },
    });

    const params = {
      ...initialParams,
      pagination: {
        current: pageNum ? +pageNum : initialParams.pagination.current,
        pageSize: pageSize ? +pageSize : initialParams.pagination.pageSize,
      },
    };
    fetchSmeApplicationCounts()
    fetchSmeApplicationListing(params);
  });

  const onSearch = (value: string) => {
    fetchSmeApplicationListing({
      search: {
        [searchFiled]: value,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{`Application Listing - redCASH CEP`}</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Verifying Application" />
      <Tabs
        defaultActiveKey={activeTab ?? "PENDING"}
        items={items}
        onChange={onTabChange}
      />
      <Input.Group compact>
        <Select
          defaultValue="applicationNo"
          style={{ width: 200 }}
          onChange={(value) => resetParams(value)}
          options={[
            { value: "applicationNo", label: "Application No" },
            { value: "name", label: "Applicant Name" },
          ]}
        />
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Input.Group>
      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        pagination={tablePagination}
        dataSource={smeApplicationsList?.data?.data}
        loading={smeApplicationsList?.isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default SmeVerifyingApplicationListingPage;
