import { Button, DatePicker, Form, message, Modal, Upload } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UploadOutlined } from "@ant-design/icons";

import { FileTypeEnum } from "../../../../../enum/fileType";
import { Attestation } from "../../../../../features/attestation/attestationSlice";
import { updateAttestationThunk } from "../../../../../services/attestationService/attestationThunk";
import { AppDispatch, RootState } from "../../../../../store/store";
import jwt_decode from "jwt-decode";

import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { fileUploadSetupAxiosInstance } from "../../../../../axios/fileUploadAxios";

const UpdateLiveAttestationStatusModal: React.FC<{
  attestation: Attestation;
  callback?: () => void;
}> = ({ attestation, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { currentUser } = useSelector((state: RootState) => state.user);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    setFileList([]);
    form.resetFields();
  };

  const onFinish = async () => {
    if (fileList.length < 1) {
      return message.error("No file selected");
    }
    if (fileList.length > 1) {
      return message.error("Maximum 1 video only");
    }

    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        if (fileList.length > 0) {
          await handleUpload();
        }

        let languageToPass = "EN";

        if (
          attestation.agreement.application.language &&
          attestation.agreement.application.language === "MS"
        ) {
          languageToPass = "BM";
        }
        dispatch(
          updateAttestationThunk({
            attestationId: attestation.id,
            language: languageToPass,
            payload: {
              ...values,
              attestationStatus: true,
              personInCharge: currentUser.data?.username,
            },
          }),
        )
          .unwrap()
          .then(async (res) => {
            if (callback) callback();
            message.success("Submit Successfully");
            handleCancel();
          })
          .finally(() => handleCancel());
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
      });
  };

  const handleUpload = async () => {
    if (attestation?.id == null)
      return message.error("Attestation ID not found");

    setUploading(true);

    const formData = new FormData();
    formData.append("entityId", attestation.id);
    formData.append("fileType", FileTypeEnum.LIVEATTESTATIONPROOF);
    formData.append("file", fileList[0] as RcFile);
    const url = `/redCash/api/v1.0/file`;
    const config = {
      headers: {
        "content-Type": "multipart/form-data",
      },
    };

    await fileUploadSetupAxiosInstance
      .post(url, formData, config)
      .then((res) => {
        setFileList([]);
        message.success("Upload successfully.");
      })
      .catch((err: Error) => {
        console.log(err);
      })
      .finally(() => setUploading(false));
  };

  const uploadProps: UploadProps = {
    maxCount: 1,
    accept: "video/*",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <>
      <Button onClick={showModal}>Update</Button>
      <Modal
        title="Update Status"
        okText="Confirm"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading || uploading}
      >
        <Form
          form={form}
          name="updateLiveAttestatonStatus"
          layout="vertical"
          autoComplete="off"
          initialValues={{ liveAttestationDateTime: dayjs() }}
        >
          <Form.Item
            label="Live Attestaton Completed Date"
            name="liveAttestationDateTime"
            rules={[{ required: true, message: "Please select date!" }]}
          >
            <DatePicker
              // format="DD/MM/YYYY"
              placeholder="Select Date"
            />
          </Form.Item>

          <Form.Item label="Recorded Video">
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Upload Video</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateLiveAttestationStatusModal;
