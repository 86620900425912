import "./index.less";

import { Button, Col, message, Row, Space, Table, Upload } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { FileTypeEnum } from "../../enum/fileType";
import {
  clickedDownloadEStampingThunk,
  clickedDownloadSmeEStampingThunk,
} from "../../services/agreementService/agreementThunk";
import { fileDeleteThunk } from "../../services/fileService/fileThunk";
import { AppDispatch } from "../../store/store";
import { displayDate } from "../../utils/datetime.util";
import { handleDownload, handlePreview } from "../../utils/preview.utils";

import type { RcFile, UploadProps } from "antd/es/upload";
import jwt_decode from "jwt-decode";
import { Role } from "../../enum/roles";
import { logoutThunk } from "../../services/userService/userThunk";

export type UploadedDocumentProps = {
  entityId?: string;
  fileType?: FileTypeEnum;
  description?: string;
  loading?: boolean;
  defaultFileList?: any;
  callback?: any;
  readOnly?: boolean;
  extra?: ReactNode[];
  customDownloadButton?: ReactNode;
  fileId?: string;
  newIc?: string;
  agreementType?: string;
  isSmeType?: boolean;
  extraActions?: ReactNode;
};

const UploadedDocument: React.FC<UploadedDocumentProps> = (props) => {
  const {
    entityId,
    fileType,
    description,
    defaultFileList,
    loading,
    readOnly,
    callback,
    extra,
    customDownloadButton,
    fileId,
    newIc,
    agreementType,
    isSmeType = false,
    extraActions,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [fileList, setFileList] = useState(
    defaultFileList?.map((file: any) => ({
      ...file,
      id: file?.id,
      uid: file?.id,
      name: file.filename.substring(file.filename.indexOf("-") + 1),
      status: "done",
    })) ?? [],
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const from = queryParams.get("from");

  const fileTableColumns: ColumnsType<any> = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (text, file) =>
        (
          <a
            className="text-red-500 hover:text-red-700"
            // href="uploaded-file"
            onClick={() =>
              handlePreview(
                file,
                setDownloading,
                dispatch,
                fileType,
                fileId,
                newIc,
                isSmeType,
              )
            }
          >
            {text}
          </a>
        ) ?? "-",
    },
    {
      title: "Uploaded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 160,
      render: (text) => (text ? displayDate(text) : "-"),
    },
    // {
    //   title: "File Size",
    //   dataIndex: "fs",
    //   key: "fs",
    //   render: (text, record) => <span>at least 1kb</span>,
    // },
    {
      title: "Action",
      key: "action",
      width: 260,
      render: (_, file) =>
        customDownloadButton ?? (
          <Space wrap={false}>
            <Button
              loading={downloading}
              onClick={() => {
                handleDownload(file, setDownloading, dispatch, fileType, isSmeType);
                if (
                  fileType === FileTypeEnum.SIGNEDAGREEMENTPDF &&
                  from === "estamp-pending"
                ) {
                  const accessToken = localStorage.getItem("accessToken");
                  if (!accessToken) {
                    message.error("Invalid access token");
                    dispatch(logoutThunk());
                    return navigate("/logout");
                  }
                  const decode: any = jwt_decode(accessToken);
                  const role = decode.role;
                  const username = localStorage.getItem("username");

                  if (role !== Role.FINANCE) {
                    if (agreementType === "SME") {
                      dispatch(
                        clickedDownloadSmeEStampingThunk({
                          agreementId: entityId,
                          username
                        }),
                      );
                    } else {
                      dispatch(
                        clickedDownloadEStampingThunk({
                          agreementId: entityId,
                          username,
                        }),
                      );
                    }
                  }
                }
              }}
              icon={<DownloadOutlined />}
            >
              Download
            </Button>
            {!readOnly && (
              <Button
                onClick={() => removeFileFromList(file)}
                icon={<DeleteOutlined style={{ color: "red" }} />}
                danger
              >
                Delete
              </Button>
            )}
            {extraActions && extraActions}
          </Space>
        ),
    },
  ];

  const addFileToList = (file: any) => {
    const newFileList = [...fileList];
    file["createdAt"] = Date.now();
    newFileList.push(file);
    setFileList(newFileList);
  };

  const updateFileInList = (file: RcFile) => {
    const result = fileList.find(
      (uploadedFile: RcFile) =>
        uploadedFile?.name?.substring(uploadedFile?.name?.indexOf("-") + 1) ===
        file.name,
    );

    if (result) {
      const index = fileList.indexOf(result);
      const newFileList = [...fileList];
      newFileList[index] = file;
      setFileList(newFileList);
    }
  };

  const removeFileFromList = async (file: any) => {
    const result = fileList.find(
      (uploadedFile: RcFile) => uploadedFile?.uid === file.uid,
    );

    if (result) {
      const index = fileList.indexOf(result);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);

      if (!file.uid.startsWith("rc-upload")) {
        await dispatch(fileDeleteThunk({ fileId: file.uid }));

        await callback();
      }
    }
  };

  const uploadProps: UploadProps = {
    maxCount: 3,
    fileList: [],
    // onPreview: handlePreview,
    beforeUpload: (file: RcFile) => {
      const isLt5M = file.size / 1024 / 1024 < 5;
      // const moreThan3 = fileList.length >= 3;
      const correctFileType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf";
      const sameFile = fileList.find(
        (uploadedFile: RcFile) =>
          uploadedFile?.name?.substring(
            uploadedFile?.name?.indexOf("-") + 1,
          ) === file.name,
      );
      if (!correctFileType) {
        message.error("Only allowed pdf, png & Jpeg file format!");
        return Upload.LIST_IGNORE;
      }
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
        return Upload.LIST_IGNORE;
      }
      if (sameFile) {
        message.error("Don't upload the same file");
        return Upload.LIST_IGNORE;
      }
      // if (moreThan3) {
      //   message.error("Limit 3 files only");
      //   return Upload.LIST_IGNORE;
      // }
    },

    customRequest: (options: any) => {
      if (!entityId) {
        return message.error("entityId not found");
      }

      if (!fileType) {
        return message.error("fileType not found");
      }

      const data = new FormData();
      data.append("entityId", entityId);
      data.append("fileType", fileType);
      data.append("file", options.file);

      if(fileType === FileTypeEnum.SME_E_STAMPING || fileType === FileTypeEnum.ESTAMPING){
        const username = localStorage.getItem("username");
        if (!username) {
          message.error("Invalid user");
          dispatch(logoutThunk());
          return navigate("/logout");
        }

        data.append('username', username)
      }

      const url = `/redCash/admin/api/v1.0/file`;

      const config = {
        headers: {
          "content-Type": "multipart/form-data",
        },
      };
      setUploading(true);

      const file = options.file;
      file["status"] = "uploading";
      // addFileToList(file);

      beSetupAxiosInstance
        .post(url, data, config)
        .then((res: any) => {
          // options.onSuccess(res.data, options.file);
          const file = options.file;
          addFileToList(file);
          file["uid"] = res.data[0].id;
          file["status"] = "done";
          updateFileInList(file);
          callback();
        })
        .catch(() => {
          const file = options.file;
          file["status"] = "error";

          removeFileFromList(file);

          // message.error("File not supported");
        })
        .finally(() => setUploading(false));
    },

    onRemove: removeFileFromList,
  };

  return (
    <div className="row-align-start">
      <div className="uploaded-document-container">
        <Row align="bottom" justify="space-between">
          <Col>
            {description && <h4>{description}</h4>}
            {!readOnly && (
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />} loading={uploading}>
                  Upload Documents
                </Button>
              </Upload>
            )}
          </Col>
          {extra && <Col>{extra}</Col>}
        </Row>

        <Table
          rowKey="id"
          className="min-w-fit my-2"
          columns={fileTableColumns}
          dataSource={fileList}
          loading={loading}
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
          bordered
        />

        {/* <Row
          className="uploaded-document-list-container"
          gutter={[16, 32]}
          wrap={true}
        >
          {loading ? (
            <div className="w-full">
              <LoadingSpinner />
            </div>
          ) : fileList != null && fileList?.length > 0 ? (
            Array.isArray(fileList) &&
            fileList?.map((file: any, index) => (
              <FileListItem
                key={`${index}`}
                file={file}
                handleDelete={() => removeFileFromList(file)}
                readOnly={readOnly}
                fileType={fileType}
              />
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ margin: "auto" }}
            />
          )}
        </Row> */}
      </div>
    </div>
  );
};

export default UploadedDocument;
