import { blob } from "stream/consumers";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";
import { AxiosRequestConfig } from "axios";

const blobConfig: AxiosRequestConfig = { responseType: "blob" };

export const getRefundList = async (requestData: any) => {
  const url = `/redCash/api/v1.0/refund/list`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData,
  });
  return res.data;
};

export const createRefundCase = async (requestData: any) => {
  const url = `/redCash/api/v1.0/refund/create-refund`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const updateRefundDetails = async (requestData: any) => {
  const { payload, id } = requestData;
  const url = `/redCash/api/v1.0/refund/update/id/${id}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

export const updateRefundStatus = async (requestData: any) => {
  const { id, status, payload } = requestData;
  const url = `/redCash/api/v1.0/refund/update/id/${id}/status/${status}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

export const getRefundFinanceList = async (requestData: any) => {
  const url =
    requestData.type === "refund"
      ? `/redCash/api/v1.0/refund-finance/list`
      : `/redCash/api/v1.0/unmatched/refund-finance/list`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData,
  });
  return res.data;
};

export const updateRefundFinanceStatus = async (requestData: any) => {
  const { id, status, postDate, payload } = requestData;
  const url = `/redCash/api/v1.0/refund-finance/update/id/${id}/status/${status}/postDate/${postDate}`;
  const res = await collectionSetupAxiosInstance.patch(url, payload);
  return res.data;
};

export const downloadRefundPaymentFile = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/refund/generatePaymentFile/id/${id}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    responseType: "blob",
  });
  return res.data;
};

export const getRefundFinanceSummary = async (requestData: any) => {
  const url =
    requestData.type === "unmatched"
      ? `/redCash/api/v1.0/unmatched/refund-finance/summary/id/${requestData.id}`
      : `/redCash/api/v1.0/refund-finance/summary/id/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);

  return res.data;
};

export const createRefundFinanceBatch = async (requestData: any) => {
  const url = `/redCash/api/v1.0/refund-finance-batch`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const getRefundFinanceBatch = async (requestData: any) => {
  const url =
    requestData.type === "unmatched"
      ? `/redCash/api/v1.0/unmatched/refund-finance-batch`
      : `/redCash/api/v1.0/refund-finance-batch`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData,
  });
  return res.data;
};

export const getRefundFinanceBatchById = async (requestData: any) => {
  const url =
    requestData.type === "unmatched"
      ? `/redCash/api/v1.0/unmatched/refund-finance-batch/id/${requestData.id}`
      : `/redCash/api/v1.0/refund-finance-batch/id/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData.params,
  });

  console.log(res);
  return res.data;
};

export const updateRefundFinanceBatch = async (requestData: any) => {
  const url = `/redCash/api/v1.0/refund-finance-batch/id/${requestData.id}/status/${requestData.status}/postDate/${requestData?.postDate}`;
  const res = await collectionSetupAxiosInstance.patch(url, requestData.body);
  return res.data;
};

export const createUnmatchedRefundFinanceBatch = async (requestData: any) => {
  const url = `/redCash/api/v1.0/unmatched/refund-finance-batch`;
  const res = await collectionSetupAxiosInstance.post(url, requestData);
  return res.data;
};

export const getUnmatchedRefundFinanceBatchById = async (requestData: any) => {
  const url = `/redCash/api/v1.0/unmatched/refund-finance-batch/id/${requestData.id}`;
  const res = await collectionSetupAxiosInstance.get(url, {
    params: requestData.params,
  });
  return res.data;
};

export const updateUnmatchedRefundFinanceBatch = async (requestData: any) => {
  const url = `/redCash/api/v1.0/unmatched/refund-finance-batch/id/${requestData.id}/status/${requestData.status}/postDate/${requestData?.postDate}`;
  const res = await collectionSetupAxiosInstance.patch(url);
  return res.data;
};

export const updateRefundFinanceBatchIsDownloaded = async (
  requestData: any,
) => {
  const url = `/redCash/api/v1.0/refund-finance-batch/id/${requestData.id}/isDownloaded`;
  const res = await collectionSetupAxiosInstance.patch(url);
  return res.data;
};

export const unPostRefundFinanceById = async (requestData: any) => {
  const url = `/redCash/api/v1.0/refund-finance-batch/unPost`;
  const res = await collectionSetupAxiosInstance.patch(url, requestData);
  return res.data;
};
