import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getWiseAiRawDataBySessionIdThunk } from "../../../../services/ekycService/ekycThunk";
export const EkycRawDataModal = ({
  isModalOpen,
  onCloseModal,
  sessionId,
}: {
  isModalOpen: boolean;
  onCloseModal: any;
  sessionId: string | null;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const [result, setResult] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getWiseAiRawDataBySessionIdThunk({ sessionId }))
      .unwrap()
      .then((res) => {
        setResult(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [sessionId]);

  return (
    <Modal
      title="Raw Data"
      open={isModalOpen}
      onOk={onCloseModal}
      onCancel={onCloseModal}
      width={1200}
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <pre>{JSON.stringify(result, null, 2)}</pre>
      )}
    </Modal>
  );
};
