export enum SME_ORGANIZATION_TYPE {
  INDIVIDUAL = "INDIVIDUAL",
  SOLE_PROP = "SOLE_PROP",
  PARTNERSHIP = "PARTNERSHIP",
  COMPANY = "COMPANY",
}

export const icTypeOptions = [
  { value: "MY_KAD", label: "MyKad" },
  { value: "MYS_TENTERA", label: "MyTentera" },
]