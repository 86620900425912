import { createSlice } from "@reduxjs/toolkit";
import {
  getSmePreRecordedVideoBMThunk,
  getSmePreRecordedVideoENThunk,
} from "../../services/smePreRecordedVideoService/smePreRecordedVideoThunk";

export type SmePreRecordedVideo = {
  id: string;
  companyId: string;
  title: string;
  createdAt?: string;
  updatedAt?: string;
};

export type PreRecordedVideoState = {
  smePreRecordedVideoEN: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmePreRecordedVideo[];
  };
  smePreRecordedVideoBM: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmePreRecordedVideo[];
  };
};

const initialState: PreRecordedVideoState = {
  smePreRecordedVideoEN: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  smePreRecordedVideoBM: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const smePreRecordedVideoSlice = createSlice({
  name: "smePreRecordedVideo",
  initialState,
  reducers: {
    smePreRecordedVideoStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmePreRecordedVideoENThunk.pending, (state) => {
        state.smePreRecordedVideoEN.isLoading = true;
      })
      .addCase(getSmePreRecordedVideoENThunk.fulfilled, (state, action) => {
        state.smePreRecordedVideoEN.isLoading = false;
        state.smePreRecordedVideoEN.data = action?.payload;
      })
      .addCase(getSmePreRecordedVideoENThunk.rejected, (state, action) => {
        state.smePreRecordedVideoEN.isLoading = false;
        state.smePreRecordedVideoEN.isError = true;
        state.smePreRecordedVideoEN.errorMessage = action.payload;
      });
    builder
      .addCase(getSmePreRecordedVideoBMThunk.pending, (state) => {
        state.smePreRecordedVideoBM.isLoading = true;
      })
      .addCase(getSmePreRecordedVideoBMThunk.fulfilled, (state, action) => {
        state.smePreRecordedVideoBM.isLoading = false;
        state.smePreRecordedVideoBM.data = action?.payload;
      })
      .addCase(getSmePreRecordedVideoBMThunk.rejected, (state, action) => {
        state.smePreRecordedVideoBM.isLoading = false;
        state.smePreRecordedVideoBM.isError = true;
        state.smePreRecordedVideoBM.errorMessage = action.payload;
      });
  },
});

export const { smePreRecordedVideoStateReset } = smePreRecordedVideoSlice.actions;

export default smePreRecordedVideoSlice.reducer;
