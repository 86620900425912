import "./index.less";

import { Button, Form, Input, message, Modal, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionType } from "../../../enum/actionType";
import { ActionCode } from "../../../features/actionCode/actionCodeSlice";
import {
  createActionCodeThunk,
  updateActionCodeThunk,
} from "../../../services/actionCodeService/actionCodeThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { displayDateAndTime } from "../../../utils/datetime.util";

const ActionCodeFormModal: React.FC<{
  actionCode?: ActionCode;
  callback?: () => void;
}> = ({ actionCode, callback }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const updateMode = actionCode != null;

  const labelClassName = "w-[80px]";
  const actionTypeOptions = Object.values(ActionType).map((value) => ({
    label: value,
    value,
  }));

  const { currentUser } = useSelector((state: RootState) => state.user);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);

        if (updateMode) {
          dispatch(
            updateActionCodeThunk({
              actionCodeId: actionCode.id,
              payload: {
                ...values,
                userId: currentUser?.data?.username,
                status: "ACTIVE",
              },
            }),
          )
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Update Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        } else {
          dispatch(
            createActionCodeThunk({
              ...values,
              userId: currentUser?.data?.username,
              status: "ACTIVE",
            }),
          )
            .unwrap()
            .then(() => {
              if (callback) callback();
              message.success("Create Successfully");
              handleCancel();
            })
            .finally(() => setLoading(false));
        }
      })
      .catch(() => {
        message.error("Something went wrong. Please try again later.");
      });
  };

  return (
    <>
      <Button onClick={showModal}>
        {actionCode != null ? "Update" : "Create Action Code"}
      </Button>
      <Modal
        title={actionCode != null ? "Update Action Code" : "Create Action Code"}
        okText={actionCode != null ? "Update" : "Create"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={500}
        destroyOnClose
      >
        <Form
          form={form}
          initialValues={actionCode}
          layout="horizontal"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            label={<span className={labelClassName}>Action Code</span>}
            name="code"
            rules={[{ required: true, message: "Please enter the code." }]}
          >
            <Input className={updateMode ? "readOnlyInput" : ""} />
          </Form.Item>
          <Form.Item
            label={<span className={labelClassName}>Description</span>}
            name="description"
            rules={[
              { required: true, message: "Please enter the description." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<span className={labelClassName}>Action Type</span>}
            name="actionType"
            rules={[
              { required: true, message: "Please select the action type." },
            ]}
          >
            {updateMode ? (
              <Input className={updateMode ? "readOnlyInput" : ""} />
            ) : (
              <Select
                placeholder="Please Select"
                allowClear
                options={actionTypeOptions}
              />
            )}
          </Form.Item>
          {updateMode && (
            <>
              <Form.Item
                label={<span className={labelClassName}>Created By</span>}
                name="createdBy"
              >
                {actionCode?.createdBy}
              </Form.Item>
              <Form.Item
                label={<span className={labelClassName}>Created At</span>}
                name="createdAt"
              >
                {displayDateAndTime(actionCode?.createdAt)}
              </Form.Item>
              <Form.Item
                label={<span className={labelClassName}>Updated By</span>}
                name="updatedBy"
              >
                {actionCode?.updatedBy}
              </Form.Item>
              <Form.Item
                label={<span className={labelClassName}>Updated At</span>}
                name="updatedAt"
              >
                {displayDateAndTime(actionCode?.updatedAt)}
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ActionCodeFormModal;
