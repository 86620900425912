import groupBy from "lodash.groupby";

import { createSlice } from "@reduxjs/toolkit";

import {
  getAgreementDetailsThunk,
  getAgreementsThunk,
  getUploadedEStampAgreementsThunk,
  getPendingEStampAgreementsThunk,
  getPendingSigningAgreementsThunk,
  getSignedAgreementsThunk,
  getInProgressEStampAgreementsThunk,
} from "../../services/agreementService/agreementThunk";
import { getFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import { Application, UploadedFile } from "../application/applicationSlice";
import { Attestation } from "../attestation/attestationSlice";

export type Agreement = {
  id: string;
  application?: Application;
  applicationid?: string;
  attestationid?: string;
  attestation?: Attestation;
  agreementStepStatusid?: string;
  agreementNo?: string;
  clickedDownloadEStampingCert?: boolean;
  agreementStatus:
    | "PENDING"
    | "DONE_VIDEO_ATTESTATION"
    | "DONE_LIVE_ATTESTATION"
    | "REQUESTED_FOR_LIVE_ATTESTATION"
    | "PENDING_SIGNING"
    | "PENDING_DISBURSEMENT"
    | string;
  loanTenure?: number;
  lastinstalmentAmount?: number;
  monthlyInstalmentAmount?: number;
  loanPayableAmount?: number;
  disbursementAmount?: number;
  signDate?: string;
  repaymentOption?: string;
  stampingFee?: number;
  attestationFee?: number;
  processingFee?: number;
  loanInterestRate?: number;
  firstInstalmentDate?: string;
  lastInstalmentDate?: string;
  monthlyInstalmentDay?: string;
  lastInstalmentAmount?: string;
  signedAgreementFileId?: string;
  protectedSignedAgreementFileId?: string;
  disbursementPostedDate?: string;
  eStampingCertificateFileId?: string;
  approveUploadedEStampingCert?: boolean;
  approveEStampingCertDate?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type AgreementState = {
  agreementList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      data: Agreement[];
    };
  };
  pendingSigningAgreements: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      searchTotal: number;
      data: Agreement[];
    };
  };
  signedAgreements: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      searchTotal: number;
      data: Agreement[];
    };
  };
  pendingEStampAgreements: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      searchTotal: number;
      data: Agreement[];
    };
  };
  inProgressEStampAgreements: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      searchTotal: number;
      data: Agreement[];
    };
  };
  uploadedEStampAgreements: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      total: number;
      currentPage: number;
      pageSize: number;
      searchTotal: number;
      data: Agreement[];
    };
  };
  agreementDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: Agreement;
  };
  agreementFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      protectedsignedagreementpdf?: UploadedFile[];
      signedagreementpdf?: UploadedFile[];
      estamping?: UploadedFile[];
    };
  };
};

const initialState: AgreementState = {
  agreementList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
    },
  },
  pendingSigningAgreements: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      searchTotal: 0,
      data: [],
    },
  },
  signedAgreements: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      searchTotal: 0,
      data: [],
    },
  },
  pendingEStampAgreements: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      searchTotal: 0,
      data: [],
    },
  },
  inProgressEStampAgreements: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      searchTotal: 0,
      data: [],
    },
  },
  uploadedEStampAgreements: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      searchTotal: 0,
      data: [],
    },
  },
  agreementDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  agreementFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      protectedsignedagreementpdf: [],
      signedagreementpdf: [],
      estamping: [],
    },
  },
};

export const agreementSlice = createSlice({
  name: "agreement",
  initialState,
  reducers: {
    setAgreementDetails: (state, action) => {
      state.agreementDetails.data = action.payload;
    },
    agreementDetailsStateReset: (state) => {
      state.agreementDetails = initialState.agreementDetails;
      state.agreementFiles = initialState.agreementFiles;
    },
    agreementStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgreementsThunk.pending, (state) => {
        state.agreementList.isLoading = true;
      })
      .addCase(getAgreementsThunk.fulfilled, (state, action) => {
        state.agreementList.isLoading = false;
        state.agreementList.data = {
          ...action.payload,
          data: action.payload.data.map((agreement: Agreement) => ({
            ...agreement,
            agreementStatus:
              agreement.agreementStatus === "PENDING"
                ? "PENDING_FOR_ATTESTATION"
                : agreement.agreementStatus,
          })),
        };
      })
      .addCase(getAgreementsThunk.rejected, (state, action) => {
        state.agreementList.isLoading = false;
        state.agreementList.isError = true;
        state.agreementList.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingSigningAgreementsThunk.pending, (state) => {
        state.pendingSigningAgreements.isLoading = true;
      })
      .addCase(getPendingSigningAgreementsThunk.fulfilled, (state, action) => {
        state.pendingSigningAgreements.isLoading = false;
        state.pendingSigningAgreements.data = {
          ...action.payload,
          data: action.payload.data.map((agreement: Agreement) => ({
            ...agreement,
            agreementStatus:
              agreement.agreementStatus === "PENDING"
                ? "PENDING_FOR_ATTESTATION"
                : agreement.agreementStatus,
          })),
        };
      })
      .addCase(getPendingSigningAgreementsThunk.rejected, (state, action) => {
        state.pendingSigningAgreements.isLoading = false;
        state.pendingSigningAgreements.isError = true;
        state.pendingSigningAgreements.errorMessage = action.payload;
      });

    builder
      .addCase(getSignedAgreementsThunk.pending, (state) => {
        state.signedAgreements.isLoading = true;
      })
      .addCase(getSignedAgreementsThunk.fulfilled, (state, action) => {
        state.signedAgreements.isLoading = false;
        state.signedAgreements.data = {
          ...action.payload,
          data: action.payload.data.map((agreement: Agreement) => ({
            ...agreement,
            agreementStatus:
              agreement.agreementStatus === "PENDING"
                ? "PENDING_FOR_ATTESTATION"
                : agreement.agreementStatus,
          })),
        };
      })
      .addCase(getSignedAgreementsThunk.rejected, (state, action) => {
        state.signedAgreements.isLoading = false;
        state.signedAgreements.isError = true;
        state.signedAgreements.errorMessage = action.payload;
      });

    builder
      .addCase(getPendingEStampAgreementsThunk.pending, (state) => {
        state.pendingEStampAgreements.isLoading = true;
      })
      .addCase(getPendingEStampAgreementsThunk.fulfilled, (state, action) => {
        state.pendingEStampAgreements.isLoading = false;
        state.pendingEStampAgreements.data = {
          ...action.payload,
          data: action.payload.data.map((agreement: Agreement) => ({
            ...agreement,
            agreementStatus:
              agreement.agreementStatus === "PENDING"
                ? "PENDING_FOR_ATTESTATION"
                : agreement.agreementStatus,
          })),
        };
      })
      .addCase(getPendingEStampAgreementsThunk.rejected, (state, action) => {
        state.pendingEStampAgreements.isLoading = false;
        state.pendingEStampAgreements.isError = true;
        state.pendingEStampAgreements.errorMessage = action.payload;
      });

    builder
      .addCase(getInProgressEStampAgreementsThunk.pending, (state) => {
        state.inProgressEStampAgreements.isLoading = true;
      })
      .addCase(
        getInProgressEStampAgreementsThunk.fulfilled,
        (state, action) => {
          state.inProgressEStampAgreements.isLoading = false;
          state.inProgressEStampAgreements.data = {
            ...action.payload,
            data: action.payload.data.map((agreement: Agreement) => ({
              ...agreement,
              agreementStatus:
                agreement.agreementStatus === "PENDING"
                  ? "PENDING_FOR_ATTESTATION"
                  : agreement.agreementStatus,
            })),
          };
        },
      )
      .addCase(getInProgressEStampAgreementsThunk.rejected, (state, action) => {
        state.inProgressEStampAgreements.isLoading = false;
        state.inProgressEStampAgreements.isError = true;
        state.inProgressEStampAgreements.errorMessage = action.payload;
      });

    builder
      .addCase(getUploadedEStampAgreementsThunk.pending, (state) => {
        state.uploadedEStampAgreements.isLoading = true;
      })
      .addCase(getUploadedEStampAgreementsThunk.fulfilled, (state, action) => {
        state.uploadedEStampAgreements.isLoading = false;
        state.uploadedEStampAgreements.data = {
          ...action.payload,
          data: action.payload.data.map((agreement: Agreement) => ({
            ...agreement,
            agreementStatus:
              agreement.agreementStatus === "PENDING"
                ? "PENDING_FOR_ATTESTATION"
                : agreement.agreementStatus,
          })),
        };
      })
      .addCase(getUploadedEStampAgreementsThunk.rejected, (state, action) => {
        state.uploadedEStampAgreements.isLoading = false;
        state.uploadedEStampAgreements.isError = true;
        state.uploadedEStampAgreements.errorMessage = action.payload;
      });

    builder
      .addCase(getAgreementDetailsThunk.pending, (state) => {
        state.agreementDetails.isLoading = true;
      })
      .addCase(getAgreementDetailsThunk.fulfilled, (state, action) => {
        state.agreementDetails.isLoading = false;
        state.agreementDetails.data = {
          ...action.payload,
          agreementStatus:
            action.payload.agreementStatus === "PENDING"
              ? "PENDING_FOR_ATTESTATION"
              : action.payload.agreementStatus,
        };
      })
      .addCase(getAgreementDetailsThunk.rejected, (state, action) => {
        state.agreementDetails.isLoading = false;
        state.agreementDetails.isError = true;
        state.agreementDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getFilesByEntityIdThunk.pending, (state) => {
        state.agreementFiles.isLoading = true;
      })
      .addCase(getFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.agreementFiles.isLoading = false;
        state.agreementFiles.data = groupBy(
          action.payload,
          (file: UploadedFile) => file.fileType.name.toLowerCase(),
        );

        // only keep the latest file for fileType signedagreemetnpdf
        if (state?.agreementFiles?.data?.signedagreementpdf && state.agreementFiles.data.signedagreementpdf.length > 0) {
          state.agreementFiles.data.signedagreementpdf = [
            state.agreementFiles.data.signedagreementpdf[
              state.agreementFiles.data.signedagreementpdf.length - 1
            ],
          ];
        }
      })
      .addCase(getFilesByEntityIdThunk.rejected, (state, action) => {
        state.agreementFiles.isLoading = false;
        state.agreementFiles.isError = true;
        state.agreementFiles.errorMessage = action.payload;
      });
  },
});

export const {
  agreementStateReset,
  agreementDetailsStateReset,
  setAgreementDetails,
} = agreementSlice.actions;

export default agreementSlice.reducer;
