import { createSlice } from "@reduxjs/toolkit";
import {
  getUnclearSpecialTaggingListThunk,
  getClearSpecialTaggingListThunk,
} from "../../services/specialTaggingService/specialTaggingThunk";

export type SpecialTaggingList = {
  id: string;
  agreementNo?: string;
  agingType?: string;
  paymentDate?: string;
  paymentChannel?: string;
  bankName?: string;
  paymentAmount?: number;
  status?: string;
  accountDetailsId?: string;
  createdAt: Date;
  updatedAt: Date;
};

export type specialTaggingDataResponse = {
  total: number;
  currentPage: number;
  pageSize: number;
  data: SpecialTaggingList[];
  searchTotal: number;
};

export type specialTaggingState = {
  unclearSpecialTaggingList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: specialTaggingDataResponse;
  };
  clearSpecialTaggingList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: specialTaggingDataResponse;
  };
};

const initialState: specialTaggingState = {
  unclearSpecialTaggingList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
  clearSpecialTaggingList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      total: 0,
      currentPage: 0,
      pageSize: 0,
      data: [],
      searchTotal: 0,
    },
  },
};

export const specialTaggingSlice = createSlice({
  name: "specialTagging",
  initialState,
  reducers: {
    specialTaggingStateReset: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUnclearSpecialTaggingListThunk.pending, (state) => {
        state.unclearSpecialTaggingList.isLoading = true;
      })
      .addCase(getUnclearSpecialTaggingListThunk.fulfilled, (state, action) => {
        state.unclearSpecialTaggingList.isLoading = false;
        state.unclearSpecialTaggingList.data = action.payload;
      })
      .addCase(getUnclearSpecialTaggingListThunk.rejected, (state, action) => {
        state.unclearSpecialTaggingList.isLoading = false;
        state.unclearSpecialTaggingList.isError = true;
        state.unclearSpecialTaggingList.errorMessage = action.payload;
      });

    builder
      .addCase(getClearSpecialTaggingListThunk.pending, (state) => {
        state.clearSpecialTaggingList.isLoading = true;
      })
      .addCase(getClearSpecialTaggingListThunk.fulfilled, (state, action) => {
        state.clearSpecialTaggingList.isLoading = false;
        state.clearSpecialTaggingList.data = action.payload;
      })
      .addCase(getClearSpecialTaggingListThunk.rejected, (state, action) => {
        state.clearSpecialTaggingList.isLoading = false;
        state.clearSpecialTaggingList.isError = true;
        state.clearSpecialTaggingList.errorMessage = action.payload;
      });
  },
});

export const { specialTaggingStateReset } = specialTaggingSlice.actions;
export default specialTaggingSlice.reducer;
