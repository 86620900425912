import { Button, message, Popconfirm, Space, Table } from "antd";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "@ant-design/pro-components";

import {
  deletePaymentNoticeConfigThunk,
  getPaymentNoticeConfigThunk,
} from "../../services/paymentNoticeService/paymentNoticeThunk";
import { AppDispatch, RootState } from "../../store/store";

import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";
import PaymentNoticeConfigModal from "./PaymentNoticeConfigModal";

const PaymentNoticeConfig = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { paymentNoticeConfigState } = useSelector(
    (state: RootState) => state.paymentNoticeConfig,
  );

  const handleDelete = (record: any) => {
    dispatch(deletePaymentNoticeConfigThunk({ id: record.id }))
      .unwrap()
      .then(() => dispatch(getPaymentNoticeConfigThunk()))
      .catch(() =>
        message.error(`Error remove payment notice config ${record.DPD}`),
      );
  };

  const columns: ColumnsType<any> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "DPD",
      dataIndex: "DPD",
      key: "DPD",
    },
    {
      title: "Letter Type",
      dataIndex: "letterType",
      key: "letterType",
    },
    {
      title: "Send Method",
      dataIndex: "sendMethod",
      key: "sendMethod",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space>
          {!paymentNoticeConfigState.isLoading && (
            <PaymentNoticeConfigModal
              key={record.id}
              data={record}
              callback={() => dispatch(getPaymentNoticeConfigThunk())}
            />
          )}
          <Popconfirm
            title={`Do you want to delete this payment notice config ${record.DPD}?`}
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getPaymentNoticeConfigThunk());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Payment Notice Config - redCASH CEP</title>
      </Helmet>
      <PageHeader
        className="px-0 mb-2"
        title="Payment Notice Config"
        extra={[
          <PaymentNoticeConfigModal
            key="createPNC"
            callback={() => dispatch(getPaymentNoticeConfigThunk())}
          />,
        ]}
      />

      <br />
      <Table
        rowKey="id"
        className="min-w-fit"
        columns={columns}
        dataSource={paymentNoticeConfigState?.data}
        loading={paymentNoticeConfigState.isLoading}
        rowClassName={() => "editable-row"}
        pagination={false}
      />
    </>
  );
};
export default PaymentNoticeConfig;
