import { Helmet } from "react-helmet-async";

import { PageHeader } from "@ant-design/pro-components";
import RestructureRequestTable from "./components/restructureRequestTable";

const RestructureListingPage = () => {
  return (
    <>
      <Helmet>
        <title>Restructure Request - redCASH CEP</title>
      </Helmet>
      <PageHeader className="px-0 mb-2" title="Restructure Request" />
      <RestructureRequestTable />
    </>
  );
};

export default RestructureListingPage;
